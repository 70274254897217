import React from "react";
import {
  H,
  bboxAll,
  bboxLast24,
  getMarkerPinMap,
  behaviorUi,
  LayerTypes,
  getPinData
} from "./utils";
import "./styles.scss";
import config from '../../config/appConfig';
import { isEqual } from "lodash-es";

const isValidCoord = function (value) {
  if (typeof value !== "number" || Math.floor(value) === value) return false;
  const decimals = value.toString().split(".")[1].length || 0;
  return decimals > 2;
}

class HereMap extends React.PureComponent {
  mapRef = React.createRef();
  state = {
    hMap: null,
    markersGroup: null,
    markersMap: {},
    layerChoices: null,
    bubble: null,
    clusteringLayerRef: null,
    platform: null,
    unitsOfMeasurement: "imperial",
    ui: null
  };

  resize = () => {
    const { hMap } = this.state;

    if (hMap) {
      hMap.getViewPort().resize();
    }
  };

  componentWillUnmount() {
    const { hMap } = this.state;

    if (hMap) {
      hMap.dispose();
    }
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    const platform = new H.service.Platform({
      apikey: config.mapApiKey,
    });
    const defaultLayers = platform.createDefaultLayers();

    const hMap = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 41.850033, lng: -87.6500523 }, // center of U.S. default
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    const markersGroup = new H.map.Group();

    window.addEventListener("resize", this.resize);
    const { ui } = behaviorUi(hMap, defaultLayers, this.state.unitsOfMeasurement);

    const bubble = new H.ui.InfoBubble(
      { lng: 13.4, lat: 52.51 },
      {
        content: "",
      }
    );
    bubble.addClass("info-bubble");
    ui.addBubble(bubble);

    ui.getControl("zoom").setVisibility(true);
    ui.getControl("mapsettings").setVisibility(false);

    const layerChoices = {
      [LayerTypes.NORMAL]: defaultLayers.vector.normal.map,
      [LayerTypes.SATELLITE]: defaultLayers.raster.satellite.map,
    };

    this.setState({ hMap, markersGroup, layerChoices, bubble, platform, ui });
    this.resize();
  }

  selectMarkers = () => {
    const { markersGroup } = this.state;
    const { selected } = this.props;
    if (!markersGroup) return;

    const markerIconMap = getMarkerPinMap(this.props.markerType);

    markersGroup.getObjects().forEach((marker) => {
      const { pinKey, zIndex } = getPinData(marker, selected)

      // Yes, zIndex here is bottom to top, so backwards of normal html!
      marker.setZIndex(zIndex);
      if (pinKey in markerIconMap) {
        marker.setIcon(markerIconMap[pinKey]);
      }
    });
  };

  addInfoBubble = (e) => {
    const { bubble } = this.state;

    if (!bubble) return;
    const data = e.target.getData();
    const labelColor = "green";
    bubble.setContent(`
      <div>
        <div class="alert-primary-location show" role="alert" aria-live="assertive" aria-atomic="true"" style="background-color: ${labelColor}; border-color: ${labelColor};">
          <div class="label-line"></div>
          <table class="table-responsive table-light table-sm m-0 p-0">
            <tbody>
              <tr>
                <th scope="row">Name: </th>
                <td colspan=2>${data.name}</td>
              </tr>
              <tr>
                <th scope="row">Gateways: </th>
                <td colspan=2>${data.gateways}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
   `);

    bubble.setPosition(e.target.getGeometry());
    bubble.open();

  };

  removeInfoBubble = () => {
    const { bubble } = this.state;
    if (!bubble) return;
    bubble.close();
  };

  updateMarkers = async (reset) => {
    const { hMap, markersMap, markersGroup } = this.state;
    const { locations, bboxLast24hrs, markerType } = this.props;

    if (!hMap || !Array.isArray(locations) || !markersGroup) return;

    if (reset) {
      markersGroup.removeAll();
    }

    const newMarkersMap = reset ? {} : { ...markersMap };
    const markersToAdd = [];
    locations.forEach((data, i) => {
      if (newMarkersMap[data.id]) return;
      newMarkersMap[data.id] = data;
      const markerIconMap = getMarkerPinMap(markerType);
      let icon;
      if ("ok" in markerIconMap) {
        icon = markerIconMap.ok;
      }
      // this check removes 0, 0 coords, which are almost ALWAYS bad data
      if (isValidCoord(data.latitude) && isValidCoord(data.longitude)) {
        const marker = new H.map.Marker(
          { lat: data.latitude, lng: data.longitude },
          { icon, data: { ...data } }
        );
        marker.addEventListener("pointerenter", this.addInfoBubble);
        marker.addEventListener("pointerleave", this.removeInfoBubble);
        markersToAdd.push(marker);
      }
    });
    // update the data that was not just added above.
    this.updateExistingData();

    if (markersToAdd.length) {
      this.setState({ markersMap: newMarkersMap });
      // add markers to the group

      markersGroup.addObjects(markersToAdd);

      const bbox = bboxLast24hrs
        ? bboxLast24(markersGroup.getObjects(), 0.5)
        : bboxAll(markersGroup.getBoundingBox(), 0.5);

      if (bbox) {
        // get geo bounding box for the group and set it to the map
        hMap.getViewModel().setLookAtData(
          {
            bounds: bbox,
            zoom: markersToAdd.length === 1 ? 16 : null
          },
          false
        );
      }
    }
  };

  startClustering = (isListNew) => {
    const { hMap, markersGroup, clusteringLayerRef } = this.state;
    const { locations } = this.props;

    if (!hMap || !Array.isArray(locations) || !isListNew) return;

    if (clusteringLayerRef) {
      //remove layer if it already exists
      hMap.removeLayer(clusteringLayerRef);
      this.setState({ clusteringLayerRef: null })
    }


    var dataPoints = new Array(markersGroup.length);
    markersGroup.getObjects().forEach((marker) => {
      let data = marker.getData();
      if (marker && data) {
        dataPoints.push(new H.clustering.DataPoint(data.latitude, data.longitude, 1, data));
      }
    });

    dataPoints.forEach((marker) => {
      if (!marker) {
        dataPoints.shift();
      }
    });

    var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 40, // Max radius of cluster
        minWeight: 2 // Min markers in a cluster
      }
    });

    let defaultTheme = clusteredDataProvider.getTheme();
    clusteredDataProvider.setTheme(this.clusterTheme(defaultTheme));

    var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    hMap.addLayer(clusteringLayer);
    this.setState({ clusteringLayerRef: clusteringLayer })
  }

  clusterTheme = (defaultTheme) => {
    const { markerType, selected } = this.props;
    const markerIconMap = getMarkerPinMap(markerType);
    const parent = this;
    return {
      getClusterPresentation: function (cluster) {
        let clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);
        const dataPoints = [];
        cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const radius = 38;

        canvas.width = radius * 2;
        canvas.height = radius * 2;

        const cursor = { end: 0, start: 0 };
        cursor.end = 2 * 1 * Math.PI;
        context.beginPath();
        context.moveTo(radius, radius);
        context.arc(radius, radius, radius, cursor.start, cursor.end + cursor.start, false);
        context.fillStyle = "green";
        context.globalAlpha = 0.8;
        cursor.start += cursor.end;
        context.fill();

        context.beginPath();
        context.arc(radius, radius, 25, 0, 2 * Math.PI);
        context.fill();
        context.fillStyle = 'white';
        context.font = 'bold 22px Arial';
        context.textAlign = "center";
        context.fillText(dataPoints.length, radius, 45);

        clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: new H.map.Icon(canvas, { size: { w: 38, h: 38 }, anchor: { x: 19, y: 19 } }),
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom()
        });

        return clusterMarker;
      },
      getNoisePresentation: function (noisePoint) {
        let data = noisePoint.getData();
        const { pinKey, zIndex } = getPinData(noisePoint, selected)

        var noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
          icon: markerIconMap[pinKey],
          data: { ...data },
          min: noisePoint.getMinZoom()
        });

        // Yes, zIndex here is bottom to top, so backwards of normal html!
        noiseMarker.setZIndex(zIndex);
        noiseMarker.setData(data);

        noiseMarker.addEventListener("pointerenter", parent.addInfoBubble);
        noiseMarker.addEventListener("pointerleave", parent.removeInfoBubble);

        return noiseMarker;
      }
    }
  };

  updateExistingData = () => {
    const { markersGroup, markersMap } = this.state;
    if (!markersGroup) return;

    markersGroup.getObjects().forEach((marker) => {
      const data = marker.getData();
      const id = data.id;
      const location = markersMap[id];
      if (location) {
        marker.setData(location);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldMarkersUpdate(prevProps, prevState)) {
      const shouldResetMarkers = isEqual(this.props.locations, prevProps.locations);
      this.updateMarkers(
        !shouldResetMarkers
      );
      this.startClustering(
        !shouldResetMarkers
      );
    }

    if (this.props.preferences && this.props.preferences.unitsOfMeasurement !== this.state.unitsOfMeasurement) {
      if (this.state.ui) {
        this.state.ui.setUnitSystem(this.props.preferences.unitsOfMeasurement === "metric" ? H.ui.UnitSystem.METRIC : H.ui.UnitSystem.IMPERIAL);
      }
    }
  }

  shouldMarkersUpdate = (pp, ps) => {
    const { markersGroup, markersMap } = this.state;
    const { locations, selectable } = this.props;

    const shouldUpdate = (
      selectable !== false ||
      locations !== pp.locations ||
      markersGroup !== ps.markersGroup ||
      markersMap !== ps.markersMap
    );

    return shouldUpdate;
  };

  render() {
    return (
      <div
        className="map"
        id="rivata-location-map"
        ref={this.mapRef}
        style={{ height: "400px", width: "100%" }}
      >
      </div>
    );
  }
}



export default HereMap;
