import React, { useMemo } from 'react'
import { Nav, NavItem, NavLink } from "reactstrap"
import { getCurrentEpoch } from '../../utils'

interface IuseProvisionTabs {
    (
        isSuperAdmin: boolean,
        activeTab: string,
        customerDefaults: any,
        toggleTab: (tab: string) => void
    ): React.ReactElement
}

export const useProvisionTabs: IuseProvisionTabs = (isSuperAdmin, activeTab, customerDefaults, toggleTab) => {
    return useMemo(() => {
        return (
            <Nav tabs className="ml-3 mr-3">
                <NavItem className="tab-item">
                    <NavLink
                        className={`assets_tab ${activeTab === 'assets' ? "active-table_tab" : ""}`}
                        onClick={() => { toggleTab('assets') }}
                    >
                        Assets
                    </NavLink>
                </NavItem>
                {(customerDefaults.subscriptions?.TIRE_SENSOR && customerDefaults.subscriptions.TIRE_SENSOR.valid_to >= getCurrentEpoch()) || isSuperAdmin ? (
                    <NavItem className="tab-item">
                        <NavLink
                            className={`assets_tab ${activeTab === 'tpmsAssets' ? "active-table_tab" : ""}`}
                            onClick={() => { 
                                toggleTab('tpmsAssets')
                            }}
                        >
                            TPMS Assets
                        </NavLink>
                    </NavItem>
                ) : null}
                <NavItem className="tab-item">
                    <NavLink
                        className={`assets_tab ${activeTab === 'users' ? "active-table_tab" : ""}`}
                        onClick={() => { toggleTab('users') }}
                    >
                        Users
                    </NavLink>
                </NavItem>
            </Nav>
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuperAdmin, activeTab, customerDefaults])
}