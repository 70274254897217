import {
    convertDataEpochToDate, 
    psiToBar,
    kmhToMph
} from '../../utils'
import { getDateRange, composeVertLineAnnotation } from '../../utils/chartUtils'
import { UnitsOfMeasurement } from "../../enums"

export const composeChartData = (data, unitsOfMeasurement) => {
    const parsedData = {
        datasets: [
            { label: "Line Pressure", data: [], fill: false, backgroundColor: "green", borderColor: "green", borderWidth: 2 }
        ]
    }

    const pressureUnitsOfMeasure = `(${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "PSI" : "Bar"})`
    const speedUnitsOfMeasure = `${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "Mph" : "Kmh"}`

    data.map((item) => {
        const value = unitsOfMeasurement === UnitsOfMeasurement.imperial 
            ? +item.min_value.toFixed(2) 
            : +parseFloat(psiToBar(item.min_value)).toFixed(2)

        const speed = item.avg_speed ? unitsOfMeasurement === UnitsOfMeasurement.imperial 
            ? kmhToMph(item.avg_speed).toFixed(2) 
            : item.avg_speed.toFixed(2) : "-"

        const speedWithUnits = `${speed} ${speedUnitsOfMeasure}`
        const obj = {
            x: convertDataEpochToDate(item.bucket_epoch, null, null, false),
            y: value,
            displayBeforeLabel: `Pressure: ${value} ${pressureUnitsOfMeasure}`,
            displayValue: `Speed: ${speed === "-" ? speed : speedWithUnits}`,
            displayAfterLabel: `Date: ${convertDataEpochToDate(item.bucket_epoch, null, null, true)}`
        }
        return parsedData.datasets[0].data.push(obj)
    })

    parsedData.datasets[0].data.sort((d1, d2) => +new Date(d1.displayDate) - +new Date(d2.displayDate))
    const range = getDateRange(parsedData.datasets)
    parsedData.xMin = range.min
    parsedData.xMax = range.max

    return parsedData
}

export const composeAnnotations = (id, threshold = 60, verticalLine, healthColors) => {
    const annotations = {}

    annotations.box1 = {
        type: "box",
        id: `box-plot-top-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: Infinity,
        yMin: threshold,
        backgroundColor: '#fff',
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    }

    annotations.box2 = {
        type: "box",
        id: `box-plot-bottom-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: threshold,
        yMin: -Infinity,
        backgroundColor: '#fffcdf',
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    }

    if (threshold) {
        annotations.box3 = {
            id: `threshold-${id}`,
            type: "line",
            mode: "horizontal",
            scaleID: "y",
            value: threshold,
            endValue: threshold,
            borderColor: "#FED925",
            borderWidth: 2,
            borderDash: [2, 2],
            borderDashOffset: 10,
            drawTime: "beforeDatasetsDraw",
        }
    }
    if (verticalLine && healthColors && healthColors[1]) {
        annotations.verticalLine = composeVertLineAnnotation(id, verticalLine, healthColors[1].color)
    }

    return annotations
}
