import { useEffect, useState } from "react";
import moment from "moment"
import { convertDataEpochToDate, kgToLbs } from '../../utils'
import { UnitsOfMeasurement } from '../../enums'

export const useColumns = () => {
    const [ columns, setColumns ] = useState([])

    useEffect(() => {
        setColumns([
            { id: "state", label: "State" },
            { id: "vehicle_weight", label: "Vehicle Weight" },
            { id: "datetime_from", label: "Datetime From" },
            { id: "datetime_to", label: "Datetime To" }
        ])
    }, [])

    return columns
}

export const useTableRows = (data, selectedUnitsOfMeasurement ) => {
    const [ rows, setRows ] = useState([])

    useEffect(() => {
        const parsedData =  data.reduce((prev, current, idx) => {
            const row = {
                columns: [
                    {
                        assetId: current.id,
                        columnId: "state",
                        type: "text",
                        label: current.is_loaded ? "Loaded" : "Empty"
                    },
                    {
                        assetId: current.id,
                        columnId: "vehicle_weight",
                        type: "text",
                        label: selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial ? kgToLbs(current.vehicle_weight).toFixed(2) + " lbs" : current.vehicle_weight.toFixed(2) + " kg"
                    },
                    {
                        assetId: current.id,
                        columnId: "datetime_from",
                        type: "text",
                        label: convertDataEpochToDate(current.timestamp_from, null, null, true)
                    },
                    {
                        assetId: current.id,
                        columnId: "datetime_to",
                        type: "text",
                        label: current.timestamp_till > moment().unix() ? "n/a" :convertDataEpochToDate(current.timestamp_till, null, null, true)
                    },
                ],
                id: current.id,
                data: current
            }
            return [ ...prev, row ]
        }, [])

        setRows(parsedData)
    }, [data, selectedUnitsOfMeasurement])

    return rows
}