
interface IgetErrorObj {
    (
        err?:  any,
        customMessage?: string
    ): IError
}

export const getStatusObj: IgetErrorObj = (err = { statusCode: 0, message: "" }, customMessage) => ({
    statusCode: err.statusCode,
    message: customMessage ? customMessage : err.message.toUpperCase()
})


interface IgetGpsDirectinFromDeg {
    (
        deg: number
    ): string
}

export const getGpsDirectinFromDeg: IgetGpsDirectinFromDeg = (deg) => {
    const defaultOffset = 11.25
    const directionMiddle = 360 / 16 // 22.5
    const arr = [{ min: 348.75, max: 11.25 }]
    const directions = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"]

    for (let i = directionMiddle; i < 360; i += directionMiddle) {
        const range = {
            min: i - defaultOffset,
            max: i + defaultOffset
        }

        arr.push(range)
    }

    if ((deg >= arr[0].min && deg <= 360) || (deg >= 0 && deg < arr[0].max)) {
        // N
        return directions[0]
    }

    for(let i = 1; i < arr.length; i++) {
        if (deg >= arr[i].min && deg < arr[i].max) {
            return directions[i]
        }
    }

    return ""
}