import React, { useState } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Row, Nav, NavItem, NavLink } from "reactstrap";
import SubNav from "../../components/SubNav";
import CustomersDropdown from "../../components/CustomersDropdown";
import GeofenceManager from "../../modules/Geofences/manager/GeofenceManager";
import GeofenceActivity from "../../modules/Geofences/activity/GeofenceActivity";
import { setSelectedCustomer } from "../../redux/common/action";
import {
  fetchGeofences,
  setGeofencesOffset,
  createGeofence,
  updateGeofence,
  deleteGeofence,
  fetchCustomerGeofences,
  fetchActivityEvents,
  setActivityEvents,
  setCustomerGeofences,
  setEventsOffset,
  setEventsFilter,
  setGeofencesLimit,
  setEventsLimit,
  setGeofencesSortOptions
} from "../../redux/geofences/action";

const Geofences = ({
  locale = {},
  fetchGeofences,
  fetchCustomerGeofences,
  createGeofence,
  updateGeofence,
  deleteGeofence,
  setGeofencesOffset,
  setEventsOffset,
  setSelectedCustomer,
  fetchActivityEvents,
  clearActivityEvents,
  clearCustomerGeofences,
  setEventsFilter,
  setGeofencesLimit,
  setGeofencesSortOptions,
  setEventsLimit,
  geofences: { isLoading, pagedGeofences, customerGeofences, activityEvents, limit, totalCount, eventsLimit, eventsFilter, eventsTotalCount },
  auth: { isAdmin = false },
  common: { customers }
}) => {
  const [activeTab, setActiveTab] = useState("activity");

  return (
    <>
      <SubNav title="Geofences" breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Geofences" }]}>
        {customers.selectedCustomer && customers.data.length > 1 ? (
          <CustomersDropdown
            locale={locale}
            label={locale.Customer || "Customer"}
            onChange={(customer) => {
              setSelectedCustomer(customer);
              setGeofencesOffset(0);
            }}
          />
        ) : null}
      </SubNav>
      {isAdmin ? (
        <div className="tabs-wrapper">
          <Nav tabs className="ml-3 mr-3">
            <NavItem className="tab-item">
              <NavLink
                className={`assets_tab ${activeTab === "activity" && "active-table_tab"}`}
                onClick={() => {
                  setActiveTab("activity");
                }}
              >
                Geofences Activity
              </NavLink>
            </NavItem>

            <NavItem className="tab-item">
              <NavLink
                className={`assets_tab ${activeTab === "manager" && "active-table_tab"}`}
                onClick={() => {
                  setActiveTab("manager");
                }}
              >
                Manage Geofences
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="ml-3 mr-3">

            <TabPane tabId="activity">
              <Row>
                {activeTab === "activity" && (
                  <GeofenceActivity
                    width={12}
                    locale={locale}
                    isAdmin={isAdmin}
                    isLoading={isLoading}
                    geofences={customerGeofences}
                    limit={eventsLimit}
                    totalCount={eventsTotalCount}
                    activityEvents={activityEvents}
                    selectedCustomerId={customers.selectedCustomer?.id}
                    fetchCustomerGeofences={fetchCustomerGeofences}
                    fetchActivityEvents={fetchActivityEvents}
                    clearActivityEvents={clearActivityEvents}
                    clearCustomerGeofences={clearCustomerGeofences}
                    setEventsOffset={setEventsOffset}
                    eventsFilter={eventsFilter}
                    setEventsFilter={setEventsFilter}
                    setEventsLimit={setEventsLimit}
                  />
                )}
              </Row>
            </TabPane>

            <TabPane tabId="manager">
              <Row>
                {activeTab === "manager" && (
                  <GeofenceManager
                    width={12}
                    locale={locale}
                    isLoading={isLoading}
                    geofences={pagedGeofences}
                    limit={limit}
                    totalCount={totalCount}
                    selectedCustomerId={customers.selectedCustomer?.id}
                    fetchGeofences={fetchGeofences}
                    createGeofence={createGeofence}
                    updateGeofence={updateGeofence}
                    deleteGeofence={deleteGeofence}
                    setGeofencesOffset={setGeofencesOffset}
                    setGeofencesLimit={setGeofencesLimit}
                    setGeofencesSortOptions={setGeofencesSortOptions}
                    setActiveTab={()=>setActiveTab("activity")}
                  />
                )}
              </Row>
            </TabPane>
          </TabContent>
        </div>
      ) : (
        <GeofenceActivity
          width={12}
          locale={locale}
          isAdmin={isAdmin}
          isLoading={isLoading}
          geofences={customerGeofences}
          limit={eventsLimit}
          totalCount={eventsTotalCount}
          activityEvents={activityEvents}
          selectedCustomerId={customers.selectedCustomer?.id}
          fetchCustomerGeofences={fetchCustomerGeofences}
          fetchActivityEvents={fetchActivityEvents}
          clearActivityEvents={clearActivityEvents}
          clearCustomerGeofences={clearCustomerGeofences}
          setEventsOffset={setEventsOffset}
          eventsFilter={eventsFilter}
          setEventsFilter={setEventsFilter}
          setEventsLimit={setEventsLimit}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  locale: state.whitelabel.locale,
  geofences: state.geofences,
  auth: state.auth,
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGeofences: () => dispatch(fetchGeofences()),
  fetchCustomerGeofences: () => dispatch(fetchCustomerGeofences()),
  createGeofence: (data) => dispatch(createGeofence(data)),
  updateGeofence: (id, data) => dispatch(updateGeofence(id, data)),
  deleteGeofence: (id) => dispatch(deleteGeofence(id)),
  setGeofencesOffset: (offset) => dispatch(setGeofencesOffset(offset)),
  setEventsOffset: (offset) => dispatch(setEventsOffset(offset)),
  setSelectedCustomer: (c) => dispatch(setSelectedCustomer(c)),
  fetchActivityEvents: () => dispatch(fetchActivityEvents()),
  clearActivityEvents: () => dispatch(setActivityEvents([])),
  clearCustomerGeofences: () => dispatch(setCustomerGeofences([])),
  setEventsFilter: (id) => dispatch(setEventsFilter(id)),
  setGeofencesLimit: (limit) => dispatch(setGeofencesLimit(limit)),
  setEventsLimit: (limit) => dispatch(setEventsLimit(limit)),
  setGeofencesSortOptions: (options) => dispatch(setGeofencesSortOptions(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(Geofences);
