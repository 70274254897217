import { ProvisionAction } from './../../redux/provision/types';
import { ICreateUserRequest, IUpdateUserRequest } from '../../redux/provision/action';
import { IUserFormValues } from './types';
import { Dispatch } from 'redux';
import { IgetState } from '../../redux/types';


interface IhandleCreateUser {
    (
        addUser: (values: ICreateUserRequest) => Promise<(dispatch: Dispatch<ProvisionAction>, getState: IgetState) => Promise<IError>>,
        values: IUserFormValues
    ): any
}

export const handleCreateUser: IhandleCreateUser = async (addUser, values) => {
    if (values && values.customerIds) {
        const createUserRequest: ICreateUserRequest = {
            email_address: values.email_address,
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            role: values.role,
            time_zone: values.timezone,
            username: values.username.toLowerCase(),
            customer_ids: values.customerIds
        }

        const res = await addUser(createUserRequest)
        return res
    }

    return null
}




interface IhandleUpdateUser {
    (
        updateUser: (reqValues: IUpdateUserRequest) => void,
        values: IUserFormValues
    ): void
}

export const handleUpdateUser: IhandleUpdateUser = (updateUser, values) => {
    if (values) {
        const updateUserRequest: IUpdateUserRequest = {
            email_address: values.email_address,
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            old_phone_number: values.old_phone_number,
            role: values.role,
            time_zone: values.timezone,
            username: values.username.toLowerCase(),
            notification_text: values.notification_text,
            notification_thresholds: values.notification_thresholds,
            customer_ids: values.customerIds
        }

        updateUser(updateUserRequest)
    }
}


interface IhandleDeleteUser {
    (
        deleteUser: (u: string) => void,
        username: string
    ): void
}

export const handleDeleteUser: IhandleDeleteUser = (deleteUser, username) => {
    if (username) {
        deleteUser(username)
    }
}


interface IensureUserHaveAccess {
    (
        parentCustomerIds: Array<number>,
        childCustomers: Array<{id: number, name: string}>
    ): boolean
}

export const ensureUserHaveAccess: IensureUserHaveAccess = (parentCustomerIds, childCustomers) => {
    const haveAccess: Array<boolean> = childCustomers.map((item) => parentCustomerIds.includes(item.id))

    return haveAccess.some(el => el === false) ? false : true
}