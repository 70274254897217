import { ICustomerOptions } from './types'
import { useMemo, useState } from "react"
import api from '../../services/api'
import { getClearLabel } from '../../utils'


type columns = "username" | "email" | "roles" | "name" | "family_name" | "phone_number" | "timezone" | "notification_text" | 
"notification_email" | "notification_thresholds" | "customers" | "email_verified" | "resend_temporary_password"

type IorderedFields = Array<{
    id: columns,
    control: "sort" | ""
}>

const orderedFields: IorderedFields = [
  { id: "username", control: "sort" },
  { id: "email", control: "" },
  { id: "roles", control: "" },
  { id: "name", control: "" },
  { id: "family_name", control: "" },
  { id: "phone_number", control: "" },
  { id: "timezone", control: "sort" },
  { id: "notification_text", control: "sort" },
  { id: "notification_email", control: "sort" },
  { id: "notification_thresholds", control: "sort" },
  { id: "customers", control: "" },
  { id: "email_verified", control: "" },
  { id: "resend_temporary_password", control: "" },
]



const labelMap: {[key: string]: string} = {
  username: "Username",
  email: "Email",
  roles: "Roles",
  name: "First Name",
  family_name: "Last Name",
  phone_number: "Phone Number",
  timezone: "Time Zone",
  notification_text: "Text Alerts",
  notification_email: "Email Alerts",
  notification_thresholds: "Alert Level",
  customers: "Customers",
  email_verified: "Email Verified",
  resend_temporary_password: "Resend Temporary Password"
}


export const resendTemporaryPassword = async (token: string, data: any) => {
    try {
        await api.postResendTomporaryPassword(token, data)

        return "ok"
    } catch (err) {
        console.log(err)
    }
}

interface IuseTableRows {
    (
        idToken: string,
        data: Array<IUser>
    ): Array<any>
}

export const useTableRows: IuseTableRows = (idToken, data) => {
    const [btnColorSuccess, setBtnColorSuccess] = useState<Array<string>>([])
    const [btnColorError, setBtnColorError] = useState<Array<string>>([])

    return useMemo(() => {
        return data.map((rowData: IUser, rowIndex) => {
            return {
                id: rowIndex,
                data: rowData,
                columns: orderedFields.map(({ id }) => {
                    const col: any = {
                        columnId: id,
                        label: rowData[id] || "-",
                        type: "text",
                    }

                    if (id === "customers" || id === "roles") {
                        const field = rowData[id]
                        
                        if (field) {
                            col.label = rowData[id].map(obj => {
                                return obj.name
                            }).join(", ") || "-"
                        }
                    } else if (id === "resend_temporary_password") {
                        const resendHandler = async () => {
                            const res = await resendTemporaryPassword(idToken, { username: rowData.username })
                            res === "ok" ? setBtnColorSuccess([ ...btnColorSuccess, rowData.username ]) : setBtnColorError([ ...btnColorError, rowData.username ])
                        }
                        col.label = "Send"
                        col.type = "button"
                        col.showButton = !rowData.email_verified || rowData.email_verified === "false" ? true : false
                        col.onClick = () => resendHandler()
                        col.btnColor = btnColorSuccess.includes(rowData.username) ? "success" : btnColorError.includes(rowData.username) ? "danger" : "secondary"
                    } else if (id === "notification_email" || id === "notification_text") {
                        rowData[id] === true ? col.label = "true" : col.label = "-"
                    } else if (id === "notification_thresholds") {
                        col.label = rowData[id] ? getClearLabel(rowData[id]) : "-"
                    }

                    return col
                })
            }
        })
  }, [data, idToken, btnColorSuccess, btnColorError])
}

export const useColumns = (locale: ILocale) => {
    return useMemo(() => {
        return orderedFields.map(({id, control}) => {
            const label: string = labelMap[id]
            return { label: locale[label] || label, id, control}
        })
    }, [locale])
}


interface IuseCustomerOptionsCreate {
    (
        currentCustomer: ICustomer,
        isSuperAdmin: boolean,
        isAdmin: boolean,
        userCustomerIds: Array<number>,
        open: boolean,
        customersList: Array<ICustomer>
    ): Array<ICustomerOptions>
}

export const useCustomerOptionsCreate: IuseCustomerOptionsCreate = (currentCustomer, isSuperAdmin, isAdmin, userCustomerIds, open, customersList) => {
    return useMemo(() => {
        if ((!userCustomerIds && !open) || !currentCustomer?.id) return []

        const { id, name, children, parent } = currentCustomer
        const options = id ? [{ id, name, disabled: !isSuperAdmin && !userCustomerIds.includes(id) && isAdmin  }] : []
        
        const setOption = (option: ICustomerOptions) => {
            if (options.findIndex(el => el.id === option.id) === -1) {
                const opt = id ? { ...option, disabled: !isSuperAdmin && !userCustomerIds.includes(id) && isAdmin } : null
                if (opt) options.push(opt)
            }
        }

        if (!isSuperAdmin && customersList) {
            customersList.forEach(customer => {
                if (customer.id) setOption({ id: customer.id, name: customer.name })
            })
        } else {
            if (children) {
                children.forEach((child) => {
                    if (child && child.id) {
                        setOption({ id: child.id, name: child.name })
                    }
                })
            }
    
            if (parent && parent.id) {
                setOption({ id: parent.id, name: parent.name })
        
                if (parent.children) {
                    parent.children.forEach((child) => {
                        if (child && child.id) {
                        setOption({ id: child.id, name: child.name })
                        }
                    })
                }
            }
        }

        const sortedOptions = options.sort((a, b) => a.id > b.id ? 1 : -1)
        
        return sortedOptions
    }, [currentCustomer, isSuperAdmin, isAdmin, userCustomerIds, open, customersList])
}


interface IuseCustomerOptionsUpdate {
    (
        isSuperAdmin: boolean,
        isAdmin: boolean,
        selectedCustomer: ICustomer,
        userCustomerIds: Array<number>,
        customerIds: Array<number>,
        refCustomers?: Array<ICustomerOptions>
    ): Array<ICustomerOptions>
}

export const useCustomerOptionsUpdate: IuseCustomerOptionsUpdate = (isSuperAdmin, isAdmin, selectedCustomer, userCustomerIds, customerIds, refCustomers) => {
    console.log(selectedCustomer, "ids")
    return useMemo(() => {
        if ((!userCustomerIds) || !selectedCustomer?.id) return []

        const { id, name, children, parent } = selectedCustomer
        const options: Array<ICustomerOptions> = [{ id, name, disabled: !isSuperAdmin && !userCustomerIds.includes(id) && isAdmin }]
        
        const setOption = (option: ICustomerOptions) => {
            if (options.findIndex(el => el.id === option.id) === -1) {
                const opt = { ...option, disabled: !isSuperAdmin && !userCustomerIds.includes(option.id) && isAdmin }
                options.push(opt)
            }
        }

        customerIds.forEach(item => {
            if (refCustomers) {
                const cName = refCustomers.find(el => el.id === item)?.name
                if (cName) setOption({ id: item, name: cName })
            }
        })

        if (children) {
            children.forEach((child) => {
                if (child && child.id) {
                    setOption({ id: child.id, name: child.name })
                }
            })
        }

        if (parent && parent.id) {
            setOption({ id: parent.id, name: parent.name })
    
            if (parent.children) {
                parent.children.forEach((child) => {
                    if (child && child.id) {
                    setOption({ id: child.id, name: child.name })
                    }
                })
            }
        }

        const sortedOptions = options.sort((a, b) => a.id > b.id ? 1 : -1)
        return sortedOptions
    }, [isSuperAdmin, isAdmin, selectedCustomer, userCustomerIds, customerIds, refCustomers])
}