import { Dispatch } from 'redux'

import api from '../../services/api'

import { ICustomerReqBody } from '../../pages/Provision/types';
import { IFilterOptions, IgetState, ISortOptions } from '../types'
import { ProvisionActionTypes, ProvisionAction } from './types'

import { getToken, sortByAlphabet, upperCaseFirstLetter } from '../../utils'
import { getStatusObj } from '../../utils/utils'

import { WARNING_TYPE } from '../../constants'

import {
    loadingCustomers,
    setCustomers,
    setSelectedCustomer,
    setCustomersError,
    fetchCustomers
} from '../common/action'
import { store } from '../store';
import {SET_HIDE_AUTOGENERATED_FILTER} from '../actionTypes';


export const setProvisionAssetsLimit = (payload: number) => ({
    type: ProvisionActionTypes.SET_ASSETS_LIMIT,
    payload: payload
})
export const setProvisionAssetsOffset = (payload: number) => ({
    type: ProvisionActionTypes.SET_ASSETS_OFFSET,
    payload: payload
})
export const setProvisionAssetsSortOptions = (payload: ISortOptions) => ({
    type: ProvisionActionTypes.SET_ASSETS_SORT_OPTIONS,
    payload: payload
})
export const setProvisionAssetsError = (payload: IError) => ({
    type: ProvisionActionTypes.SET_ASSETS_ERROR,
    payload: payload
})


export const setTpmsAssets = (payload: Array<IAsset>) => ({
    type: ProvisionActionTypes.SET_TPMS_ASSETS,
    payload: payload,
})
export const setTpmsPageLimit = (payload: number) => ({
    type: ProvisionActionTypes.SET_TPMS_ASSETS_LIMIT,
    payload: payload,
})
export const setTpmsPageOffset = (payload: number) => ({
    type: ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET,
    payload: payload,
})
export const setTpmsPageSortOptions = (payload: ISortOptions) => ({
    type: ProvisionActionTypes.SET_TPMS_ASSETS_SORT_OPTIONS,
    payload: payload,
})
export const setTpmsAssetsFilter = (payload: IFilterOptions) => ({
    type: ProvisionActionTypes.SET_TPMS_ASSETS_FILTER,
    payload: payload,
})


export const setUsersPageSortOptions = (payload: ISortOptions) => ({
    type: ProvisionActionTypes.SET_USERS_SORT_OPTIONS,
    payload: payload
})
export const setUsersPageOffset = (payload: number) => ({
    type: ProvisionActionTypes.SET_USERS_OFFSET,
    payload: payload
})
export const setUsersPageLimit = (payload: number) => ({
    type: ProvisionActionTypes.SET_USERS_LIMIT,
    payload: payload
})


export function fetchProvisionPage(customer_id?: number | null) {
    return function (dispatch: Dispatch<any>) {
      dispatch(fetchProvisionAssets(customer_id))
      dispatch(fetchPagedTpmsAssets(customer_id))
      dispatch(fetchProvisionTpmsProfiles(customer_id))
      dispatch(fetchUsers(customer_id))
    }
}


export const addCustomer = (data: ICustomerReqBody) => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch(loadingCustomers(true))

            const customer = await api.postCustomer(data, getToken(getState))
            await dispatch(fetchCustomers())
            const status = getStatusObj()

            dispatch(setSelectedCustomer(customer))
            dispatch(setCustomersError(status))

            return status
        } catch (err) {
            const errorObj = getStatusObj(err)
            let message = errorObj.message.split(":")
            let parsedMessage = message[1] + message[2]
            const status = getStatusObj(err, parsedMessage)

            dispatch(setCustomersError(status))

            return status
        } finally {
            dispatch(loadingCustomers(false))
        }
    }
}


export const updateCustomer = (data: ICustomerReqBody) => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch(loadingCustomers(true))

            const customer = await api.putCustomer(data, getToken(getState))
            const customers = [...getState().common.customers.data]

            const index = customers.findIndex((c) => c.id === customer.id)
            if (index > -1) {
                customers.splice(index, 1, customer)
            }
            dispatch(setCustomers(sortByAlphabet(customers, "name")))
            dispatch(setCustomersError(getStatusObj({ statusCode: 200 }, `Customer "${customer.name}" successfully updated`)))
            
            const status = getStatusObj()
            dispatch(setSelectedCustomer(customer))
            dispatch(setCustomersError(status))
        } catch (err) {
            dispatch(setCustomersError(getStatusObj(err)))
        } finally {
            dispatch(loadingCustomers(false))
        }
    }
}

export const deleteCustomer = (id: number) => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch(loadingCustomers(true))

            await api.deleteCustomer(id, getToken(getState))
            const customers = [...getState().common.customers.data]
            const index = customers.findIndex((c) => c.id === id)

            if (index > -1) {
                customers.splice(index, 1)
            }
            dispatch(setCustomers(sortByAlphabet(customers, "name")))

            const selectedCustomer = customers.length ? customers.find((c) => c.name === "Rivata") : customers[0]

            if (selectedCustomer) {
                dispatch(setSelectedCustomer(selectedCustomer))
                dispatch(fetchProvisionPage(selectedCustomer.id))
            }
            
            dispatch(setCustomersError(getStatusObj()))
        } catch (err) {
            dispatch(setCustomersError(getStatusObj(err)))
        } finally {
            dispatch(loadingCustomers(false))
        }
    }
}


export const fetchProvisionAssets = (customer_id?: number | null) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
            const selectedCustomer = getState().common.customers.selectedCustomer
            const { pageOffset, pageLimit, sortOptions: { column, direction } } = getState().provision.assets
            const id = customer_id || selectedCustomer?.id
            const { hideAutogeneratedAssets } = getState().common
            const assets = await api.getAssets(getToken(getState), id, pageLimit, pageOffset, null, null, null, column, direction, null, true, hideAutogeneratedAssets)
            const { paged_data, total_count } = assets

            dispatch({ type: ProvisionActionTypes.SET_ASSETS, payload: paged_data })
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_COUNT, payload: total_count })
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
        }
    }
}


export const addAsset = (data: any) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
            const asset = await api.postAsset(data, getToken(getState))

            const assets = getState().provision.assets.data
            const status = getStatusObj({ statusCode: 201 }, `Asset "${asset.name}" successfully created`)

            dispatch({ type: ProvisionActionTypes.SET_ASSETS, payload: [...assets, asset] })
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

            return status
        } catch (err) {
            const status = getStatusObj(err)
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

            return status
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
        }
    }
}


export const updateAsset = (data: any) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
            const userName = getState().auth.user.userName
            
            const asset = await api.putAsset(data, getToken(getState), userName)

            const assets = [...getState().provision.assets.data]
            const index = assets.findIndex((a) => a.id === asset.id)
            if (index > -1) {
                assets.splice(index, 1, asset)
            }

            const status = getStatusObj({ statusCode: 201 }, `Asset "${asset.name}" successfully updated`)

            dispatch({ type: ProvisionActionTypes.SET_ASSETS, payload: assets })
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

            return status
        } catch (err) {
            const status = getStatusObj(err)
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status})
            return status
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
        }
    }
}

export const deleteAsset = (id: number) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
            const asset = await api.deleteAsset(id, getToken(getState))

            const assets = [...getState().provision.assets.data]
            const index = assets.findIndex((a) => a.id === asset.id)
            if (index > -1) {
                assets.splice(index, 1)
            }

            dispatch({ type: ProvisionActionTypes.SET_ASSETS, payload: assets })
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: getStatusObj({ statusCode: 201 }, `Asset "${asset.name}" successfully deleted`) })
        } catch (err) {
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: getStatusObj(err, "Something went wrong, please try again later") })
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
        }
    }
}


export const fetchUsers = (customer_id?: number | null) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })
            const selectedCustomer = getState().common.customers.selectedCustomer
            const { sortOptions: { column, direction }, pageLimit, pageOffset } = getState().provision.users
            const id = customer_id || selectedCustomer?.id
            
            const res = await api.getUsers(getToken(getState), id, pageLimit, pageOffset, column, direction)
            const { total_count, paged_data } = res

            dispatch({ type: ProvisionActionTypes.SET_USERS, payload: paged_data })
            dispatch({ type: ProvisionActionTypes.SET_USERS_COUNT, payload: total_count })
            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
        }
    }
}
export interface ICreateUserRequest {
    email_address: string
    first_name: string
    last_name: string
    phone_number: string
    role: string
    time_zone: string
    username: string
    customer_ids: Array<number>
}

export const addUser = (reqBody: ICreateUserRequest) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })
            
            await api.postUser(reqBody, getToken(getState))
            const status = getStatusObj({ statusCode: 201, message: `User "${reqBody.username}" successfully created` })

            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: status })

            return status
        } catch (err) {
            const errorObj = getStatusObj(err)
            let str = errorObj.message.split(": ")
            let message = str[str.length - 1]
            const status = getStatusObj(err, message)
            
            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: status })

            return status
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
        }
    }
}


export interface IUpdateUserRequest extends ICreateUserRequest {
    old_phone_number: string | undefined
    notification_text: boolean | undefined
    notification_thresholds: string | undefined | null
}


export const updateUser = (reqBody: IUpdateUserRequest) => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })
            
            await api.putUser(reqBody, getToken(getState))
            dispatch(fetchUsers())
            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: getStatusObj({ statusCode: 200, message: `User "${reqBody.username}" successfully updated` }) })
        } catch (err) {
            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
        }
    }
}


export const deleteUser = (username: string) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: true })
            
            const user = await api.deleteUser(username, getToken(getState))
            const users = [...getState().provision.users.data]
            const index = users.findIndex((u) => u.username === user.username)
            if (index > -1) {
                users.splice(index, 1)
            }

            dispatch({ type: ProvisionActionTypes.SET_USERS, payload: users })
            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: getStatusObj({ statusCode: 200, message: `User "${username}" successfully updated` }) })
        } catch (err) {
            dispatch({ type: ProvisionActionTypes.SET_USERS_ERROR, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_USERS, payload: false })
        }
    }
}


export const fetchPagedTpmsAssets = (customerId?: number | null) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_TPMS_ASSETS, payload: true })
            const selectedCustomer = getState().common.customers.selectedCustomer
            const {
                pageLimit,
                pageOffset,
                sortOptions: { column, direction },
                filter
            } = getState().provision.tpmsAssets
            const id = customerId || selectedCustomer?.id
            let fType,fData

            if (filter) {
                const { filterType, filterData } = filter
                fType = filterType
                fData = filterData
            }
            
            const res = await api.getAssets(getToken(getState), id, pageLimit, pageOffset, null, fType, fData, column, direction, "tpms")

            const data = res.paged_data.map((a: IAsset) => ({
                id: a.id,
                customer: a.customer_name,
                type: a.asset_type ? upperCaseFirstLetter(a.asset_type) : "-",
                tpmsProfile: a.warning_setting_name || "Default",
                vin: a.vin,
                assetName: a.name
            }))

            dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS, payload: data })
            dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS_COUNT, payload: res.total_count })
            dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR, payload: getStatusObj() })
        } catch (err) {
            dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_TPMS_ASSETS, payload: false })
        }
    }
}

export const fetchProvisionTpmsProfiles = (customerId?: number | null) => {
    return async (dispatch: Dispatch<ProvisionAction>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_TPMS_ASSETS, payload: true })
            const selectedCustomer = getState().common.customers.selectedCustomer
            const id = customerId || selectedCustomer?.id

            const res = await api.getWarningsSettings(getToken(getState), WARNING_TYPE.TIRE_PRESSURE_SENSOR, id)

            let isThereDefaultProfile = false

            const profiles = Object.keys(res).map(id => {
                const profile = res[id]

                if (profile.name === "Default") {
                    isThereDefaultProfile = true
                }

                return {
                    id,
                    label: profile.name
                }
            })

            if (!isThereDefaultProfile) {
                profiles.push({ id: "systemDefaults", label: "Default" })
            }

            dispatch({ type: ProvisionActionTypes.SET_TPMS_PROFILES, payload: profiles })
        } catch (err) {
            console.log(err)
        }
    }
}


export const assignWarningSettingsToAssets = (data: any) => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_TPMS_ASSETS, payload: true })

            const res = await api.assignWarningSettingsToAssets(getToken(getState), data)
            dispatch(fetchPagedTpmsAssets())
            dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR, payload: getStatusObj() })

            return res
        } catch (err) {
            dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS_ERROR, payload: getStatusObj(err) })
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_TPMS_ASSETS, payload: false })
        }
    }
}

export const migrateAssetBetweenCustomers = (payload: any) => {
    return async (dispatch: Dispatch<any>, getState: IgetState) => {
        try {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: true })
            
            await api.migrateAssetBetweenCustomers(payload, getToken(getState));

            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: getStatusObj({ statusCode: 201 }, `Asset ${payload.asset_vin} migrated`) })
       
            return getStatusObj({ statusCode: 201, message: `Asset ${payload.asset_vin} migrated` });;
        } catch (err) {
            const status = getStatusObj(err)
            dispatch({ type: ProvisionActionTypes.SET_ASSETS_ERROR, payload: status })

            return status
        } finally {
            dispatch({ type: ProvisionActionTypes.LOADING_ASSETS, payload: false })
        }
    }
}


export const resetProvisionPageReducer = () => ({
    type: ProvisionActionTypes.RESET_PROVISION_PAGE_REDUCER,
    payload: true
})


store.subscribe(() => {
    const lastAction = store.getState().lastAction
    
    if (
        lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET ||
        lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_SORT_OPTIONS ||
        lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_FILTER 
    ) {
        store.dispatch(fetchPagedTpmsAssets())
    }

    if (lastAction.type === ProvisionActionTypes.SET_USERS_SORT_OPTIONS ||
        lastAction.type === ProvisionActionTypes.SET_USERS_OFFSET
    ) {
        store.dispatch(fetchUsers())
    }

    if (lastAction.type === ProvisionActionTypes.SET_ASSETS_SORT_OPTIONS ||
        lastAction.type === ProvisionActionTypes.SET_ASSETS_OFFSET
    ) {
        store.dispatch(fetchProvisionAssets())
    }

    if (lastAction.type === ProvisionActionTypes.SET_TPMS_ASSETS_LIMIT) {
        store.dispatch({ type: ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET, payload: 0 })
    }

    if (lastAction.type === ProvisionActionTypes.SET_ASSETS_LIMIT) {
        store.dispatch({ type: ProvisionActionTypes.SET_ASSETS_OFFSET, payload: 0 })
    }
})