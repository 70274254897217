import React from "react";

import { connect } from "react-redux";

import { useDiagramData } from "./hooks";

import TrailerDiagram from "./trailer/TrailerDiagram";

import TractorDiagram from "./tractor/TractorDiagram";

const TpmsStatusDiagram = ({ ...props }) => {
  const { pressureUnit, timezone, getTireData } = useDiagramData({ ...props });
  const { assetTpmsStatus, asset, locale } = props;
  return (
    <>
      {!assetTpmsStatus.isLoading && !assetTpmsStatus.data?.sensors?.length ? null : (
        <>
          {asset.asset_type === "trailer" ? (
            <TrailerDiagram assetTpmsStatus={assetTpmsStatus} pressureUnit={pressureUnit} timezone={timezone} locale={locale} getTireData={getTireData} />
          ) : (
            <TractorDiagram assetTpmsStatus={assetTpmsStatus} pressureUnit={pressureUnit} timezone={timezone} locale={locale} getTireData={getTireData} />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  locale: state.whitelabel.locale,
});

export default connect(mapStateToProps)(TpmsStatusDiagram);
