import React, { useEffect } from "react"
import indexRoutes from "./routes"
import { match, Route, Switch, withRouter } from "react-router-dom"
import { History, Location } from "history"
import PrivateRoute from "./routes/PrivateRoutes"
import AdminRoute from "./routes/AdminRoute"
import Callback from './routes/authCallback'
import Blanklayout from "./layouts/blanklayout"
import RivataPage from "./components/RivataPage/index"

import { useActions } from "./hooks/useActions"

// white label overrides
import "./app.scss"
import "./theme.css"

interface IAppProps {
    location: Location
    history: History
    match: match
}

const App: React.FC<IAppProps> = ({
    ...props
}) => {
    const { location } = props
    const { handleAppLocationChange } = useActions()
    // add or remove "All" option from customer dropdown based on location
    useEffect(() => {
        handleAppLocationChange(location.pathname)
    }, [location, handleAppLocationChange])

    return (
        <RivataPage
            {...props}
        >
            <Switch>
                {/** main route for user who is not authorized */}
                <Route path="/authentication" component={(props: any) => <Blanklayout {...props} />} />
                {/** callback handles the response from the aws login flow */}
                <Route path="/callback" component={(props: any) => <Callback {...props} />} />
                <AdminRoute path="/whitelabel" componentName="WhiteLabel" checkForSuperAdmin />
                <AdminRoute path="/provision" componentName="Provision" />
                <AdminRoute path="/tpms-profiles" componentName="TpmsProfiles" />
                <AdminRoute path="/thresholds" componentName="Thresholds" checkForSuperAdmin />
                <AdminRoute path="/asset-admin-info/:vin" componentName="AssetAdminInfo" checkForSuperAdmin />
                <AdminRoute path="/calibrate-axle-load" componentName="CalibrateAxleLoad" />
                <AdminRoute path="/manage-support" componentName="ManageSupport" checkForSuperAdmin/>

                <AdminRoute path="/geolocations/tags/:mac?" componentName="TagDetails" checkForSuperAdmin disabled={(process.env.REACT_APP_STAGE !== "dev" && process.env.REACT_APP_STAGE !== "staging" ) && !!process.env.REACT_APP_STAGE} />
                <AdminRoute path="/geolocations/:id" componentName="GeoLocationsDetails" checkForSuperAdmin disabled={(process.env.REACT_APP_STAGE !== "dev" && process.env.REACT_APP_STAGE !== "staging" ) && !!process.env.REACT_APP_STAGE} />
                <AdminRoute path="/geolocations" componentName="GeoLocations" checkForSuperAdmin disabled={(process.env.REACT_APP_STAGE !== "dev" && process.env.REACT_APP_STAGE !== "staging" ) && !!process.env.REACT_APP_STAGE} />

                {indexRoutes.map((prop, key) => {
                    return (
                        <PrivateRoute
                            path={prop.path}
                            key={key}
                            name={prop.name}
                            component={prop.component}
                        />
                    )
                })}
            </Switch>
        </RivataPage>
    )
}

export default withRouter(App)
