import React, { useState } from "react";
import { Input, FormGroup, Label, FormText } from "reactstrap";
import RivataLoader from '../../components/RivataLoader';
import RivataModule from "../../components/RivataModule";
import placeholder from "../../assets/images/placeholder.png";
import { baseUrl } from "../../services/api/base";

import './style.scss'


const ImageUploaderItem = ({ filename, locale, text, id, accept, whiteLabel, onUpdate, uploadImage }) => {
  const [imageHash, setImageHash] = useState(+new Date());
  const [loading, setLoading] = useState(false);
  const [showWrongExtMessage, setShowWrongExtMessage] = useState(false)
  const whiteLabelUrl = `${baseUrl}/config/${whiteLabel}/`;
  return (
    <div className="d-flex my-3">
      <div className="mr-2 col-3 d-flex align-items-center justify-content-center">
        <img
          src={filename && whiteLabelUrl ? `${whiteLabelUrl}${filename}?h=${imageHash}` : placeholder}
          alt={filename}
          className="border shadow"
          style={{
            width: "auto",
            height: "auto",
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100px",
          }}
        />
      </div>
        {loading && <RivataLoader/>}
        <FormGroup className="col-9">
          <Label for="exampleFile">{locale[id] || id}</Label>
          <Input
            type="file"
            name={filename}
            id={id}
            onChange={(e) => {
              if (e.target.files[0].type === accept) {
                setLoading(true);
                setShowWrongExtMessage(false)
                onUpdate(e.target.files[0], filename);
                uploadImage().then((r) => {
                  setTimeout(() => {
                    setImageHash(+new Date());
                    setLoading(false);
                  }, 2500);
                });
              } else {
                setShowWrongExtMessage(true)
              }
            }}
            onClick={(e) => (e.target.value = null)}
            accept={accept}
          />
          { showWrongExtMessage ? (
            <p className="error-message">Wrong file extension please select file with extension: {accept}</p>
          ) : null }
          <FormText color="muted">{locale[text] || text}</FormText>
        </FormGroup>
    </div>
  );
};

const ImageUploader = ({ items, title, locale, whiteLabel }) => {
  return (
    <RivataModule title={title} width={6} locale={locale}>
      {items.map((item) => (
        <ImageUploaderItem key={item.id} {...item} whiteLabel={whiteLabel} locale={locale} />
      ))}
    </RivataModule>
  );
};

export default ImageUploader;
