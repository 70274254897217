import { useMemo } from 'react'
import { IColumn, IRow } from '../../components/RivataGrid/index'
import { ReportTypes } from '../../enums'

interface IuseCsvData {
    (columns: Array<IColumn>, rows: Array<IRow>, selectedReport: string): Array<Array<string | number>>
}

const useCsvData: IuseCsvData = (columns, rows, selectedReport) => {
    return useMemo(() => {
        const columnsList = columns.map((col) => {
            return col.name
        })

        if (!columnsList.length) return []

        const csv: Array<Array<string | number>> = [columnsList]

        switch (selectedReport) {
            case ReportTypes.DEVICE_HEALTH_REPORT:
            case ReportTypes.TPMS_REPORT:
                rows.forEach((r) => {
                    const row = []
                    for (let i = 0; i < columns.length; i++) {
                        const key = columns[i].key
                        row.push(r[key])
                    }

                    csv.push(row)
                })
                break
            default:
                rows.forEach((r) => {
                    const values: Array<string | number> = Object.values(r)
                    csv.push(values)
                })
                break
        }
        
        return csv
    }, [columns, rows, selectedReport])
}

export default useCsvData