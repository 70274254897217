import React from "react";
import { Link } from 'react-router-dom'
import { Row, Col } from "reactstrap";
//import img1a from '../../assets/images/logo-icon.png';
//import img2a from '../../assets/images/background/login-register.jpg';
import brandLogo from "../../assets/images/conmet_brand/conmet_digital.png";
import brandBackground from "../../assets/images/riv_brand/image--013.png";
import { Formik, Form } from "formik";
import authService from "../../services/auth";
import './style.scss'

const sidebarBackground = {
  backgroundImage: "url(" + brandBackground + ")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
};
const welcomeStyle = {
  fontFamily: "myriad-pro",
  fontWeight: "400",
  fontSize: "30px",
  color: "#000",
  opacity: 0.9,
};
const buttonStyle = {
  fontFamily: "myriad-pro",
  fontWeight: "400",
  textAlign: "left",
  color: "#fff",
  textTransform: "uppercase",
  borderRadius: "5px",
  backgroundColor: "#33afc9",
}
const reqAccountBtnStyle = {
  fontFamily: "myriad-pro",
  fontWeight: "400",
  textAlign: "left",
  textTransform: "uppercase",
  border: "1px solid silver",
  borderRadius: "5px",
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    var elem = document.getElementById("loginform");
    elem.style.transition = "all 2s ease-in-out";
    elem.style.display = "none";
    document.getElementById("recoverform").style.display = "block";
  }
  render() {
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Login Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div
          className="auth-wrapper d-flex no-block justify-content-center align-items-center"
          style={sidebarBackground}
        >
          <div className="auth-box on-sidebar">
            <div id="loginform">
              <div className="logo">
                <h5 className="font-medium mb-3" style={welcomeStyle}>Welcome To</h5>
                <span className="db mb-3">
                  <img src={brandLogo} alt="logo" />
                </span>
                {/* <div className="alert alert-success">Username: test & Password: test</div> */}
              </div>
              <Row>
                <Col xs="12">
                  <Formik
                    render={() => (
                      <Form className="mt-3" id="loginform">
                        <Row className="mb-3">
                          <Col xs="12">
                            <a
                              href={authService.getSignInUri()}
                              className="btn btn-block"
                              style={buttonStyle}
                            >
                              Sign In
                            </a>
                            <Link 
                              to="/authentication/request-access"
                              className="btn btn-block"
                              style={reqAccountBtnStyle}
                            >
                              Request Account
                            </Link>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="position-absolute sidebar-footer" style={{ bottom: "1rem", right: "1rem", fontSize: "1rem" }}>
              <a href="https://conmet.com/innovation/digital/" target="_blank" rel="noreferrer noopener">Learn More About Our Products</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
