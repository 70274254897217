interface IAxlesGroups {
    [key: string]: Array<string>
}

export const AxlesGroups: IAxlesGroups = {
    "Steer": ["Steer Left", "Steer Right"],
    "Drive 1": ["Drive Left 1 Outer", "Drive Left 1 Inner", "Drive Right 1 Outer", "Drive Right 1 Inner", "Drive Right 1", "Drive Left 1"],
    "Drive 2": ["Drive Left 2 Outer", "Drive Left 2 Inner", "Drive Right 2 Outer", "Drive Right 2 Inner", "Drive Right 2", "Drive Left 2"],
    "Drive 3": ["Drive Left 3 Outer", "Drive Left 3 Inner", "Drive Right 3 Outer", "Drive Right 3 Inner", "Drive Right 3", "Drive Left 3"],
    "Drive 4": ["Drive Left 4 Outer", "Drive Left 4 Inner", "Drive Right 4 Outer", "Drive Right 4 Inner", "Drive Right 4", "Drive Left 4"],
    "Trailer 1": ["Trailer Left 1 Outer", "Trailer Left 1 Inner", "Trailer Right 1 Outer", "Trailer Right 1 Inner", "Trailer Left 1", "Trailer Right 1"],
    "Trailer 2": ["Trailer Left 2 Outer", "Trailer Left 2 Inner", "Trailer Right 2 Outer", "Trailer Right 2 Inner", "Trailer Left 2", "Trailer Right 2"],
    "Trailer 3": ["Trailer Left 3 Outer", "Trailer Left 3 Inner", "Trailer Right 3 Outer", "Trailer Right 3 Inner", "Trailer Left 3", "Trailer Right 3"],
    "Trailer 4": ["Trailer Left 4 Outer", "Trailer Left 4 Inner", "Trailer Right 4 Outer", "Trailer Right 4 Inner", "Trailer Left 4", "Trailer Right 4"],
    "Trailer 5": ["Trailer Left 5 Outer", "Trailer Left 5 Inner", "Trailer Right 5 Outer", "Trailer Right 5 Inner", "Trailer Left 5", "Trailer Right 5"],
    "Trailer 6": ["Trailer Left 6 Outer", "Trailer Left 6 Inner", "Trailer Right 6 Outer", "Trailer Right 6 Inner", "Trailer Left 6", "Trailer Right 6"]
}