import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import AxleLoadCalibrationModule from '../../modules/AxleLoadCalibrationModule'
import AxleLoadCalibrationTable from '../../modules/AxleLoadCalibrationTable'
import SubNav from '../../components/SubNav';
import CustomersDropdown from '../../components/CustomersDropdown';
import {
    fetchAxleLoadCalibrationPage,
    setSelectedCalibrationAsset,
    postAxleLoadCalibrationData,
    fetchAxleLoadCalibrationData,
    setCalibrationDataOffset,
    setCalibrationDataLimit,
    putAxleLoadCalibrationData,
    deleteAxleLoadCalibrationData,
    fetchAxleLoadAssets,
} from '../../redux/axleLoadCalibration/action'
import {
    setSelectedCustomer
} from '../../redux/common/action'
import { UnitsOfMeasurement } from '../../enums'

const CalibrateAxleLoad = ({
    axleLoadCalibration,
    common: { customers, customerDefaults: { subscriptions }, isDefaultsLoading },
    locale = {},
    fetchPage,
    setSelectedCalibrationAsset,
    postAxleLoadCalibrationData,
    fetchAxleLoadCalibrationData,
    fetchAxleLoadAssets,
    setSelectedCustomer,
    setCalibrationDataOffset,
    setCalibrationDataLimit,
    putAxleLoadCalibrationData,
    deleteAxleLoadCalibrationData,
    auth: { isSuperAdmin = false, isAdmin = false, preferences: { unitsOfMeasurement = UnitsOfMeasurement.imperial } }
}) => {
    const [ selectedUnitsOfMeasurement, setSelectedUnitsOfMeasurement ] = useState(null)

    useEffect(() => {
        setSelectedUnitsOfMeasurement(unitsOfMeasurement)
    }, [unitsOfMeasurement])

    return (
        <>
            <SubNav
                title="Calibrate Axle Load"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Calibrate Axle Load" }
                ]}
            >
                {customers.data.length > 1 ? (
                    <CustomersDropdown
                        locale={locale}
                        label={locale.Customer || "Customer"}
                        onChange={(customer) => {
                            setSelectedCustomer(customer)
                            fetchAxleLoadAssets()
                        }}
                    />
                ) : null}
            </SubNav>
            <AxleLoadCalibrationModule
                isSuperAdmin={isSuperAdmin}
                subscriptions={subscriptions}
                isDefaultsLoading={isDefaultsLoading}
                axleLoadAssets={axleLoadCalibration.axleLoadAssets}
                existingCalibrationData={axleLoadCalibration.calibrationData.data}
                locale={locale}
                onSubmit={postAxleLoadCalibrationData}
                setSelectedAsset={(asset) => {
                    setSelectedCalibrationAsset(asset)
                    if (asset?.id) {
                        fetchAxleLoadCalibrationData(asset.id)
                    }
                }}
                selectedAsset={axleLoadCalibration.selectedAsset}
                selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                setSelectedUnitsOfMeasurement={(value) => setSelectedUnitsOfMeasurement(value)}
            />
            <AxleLoadCalibrationTable
                {...axleLoadCalibration.calibrationData}
                selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                selectedAsset={axleLoadCalibration.selectedAsset}
                locale={locale}
                onEdit={putAxleLoadCalibrationData}
                onDelete={deleteAxleLoadCalibrationData}
                setCalibrationDataOffset={setCalibrationDataOffset}
                setCalibrationDataLimit={setCalibrationDataLimit}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    axleLoadCalibration: state.axleLoadCalibration,
    auth: state.auth,
    common: state.common,
    locale: state.whitelabel.locale,
})

const mapDispatchToProps = (dispatch) => ({
    fetchPage: () => dispatch(fetchAxleLoadCalibrationPage()),
    postAxleLoadCalibrationData: (data) => dispatch(postAxleLoadCalibrationData(data)),
    fetchAxleLoadCalibrationData: (assetId) => dispatch(fetchAxleLoadCalibrationData(assetId)),
    putAxleLoadCalibrationData: (data) => dispatch(putAxleLoadCalibrationData(data)),
    deleteAxleLoadCalibrationData: (assetId, cDataId) => dispatch(deleteAxleLoadCalibrationData(assetId, cDataId)),
    setCalibrationDataOffset: (offset) => dispatch(setCalibrationDataOffset(offset)),
    setCalibrationDataLimit: (limit) => dispatch(setCalibrationDataLimit(limit)),
    setSelectedCalibrationAsset: (asset) => dispatch(setSelectedCalibrationAsset(asset)),
    setSelectedCustomer: (c) => dispatch(setSelectedCustomer(c)),
    fetchAxleLoadAssets: () => dispatch(fetchAxleLoadAssets())
})

export default connect(mapStateToProps, mapDispatchToProps)(CalibrateAxleLoad)