import React from "react";
import classes from "./styles.module.scss";
import { formatTimestamp, formatInfoString } from "./utils";
import RivatePagination from "../../components/RivatePagination";
import LimitDropdown from "../../components/LimitDropdown";

export const SensorLogsTable = ({ logs }) => {
          return logs.map((log, i) => {
            log.epoch = formatTimestamp(log.epoch);
            log.module = formatInfoString(log.module);
            log.method = formatInfoString(log.method);
            const module = log["module"] ? log["module"] : "";
            const method = log["method"] ? log["method"] : "";
            return (
              <li key={log.epoch + i} className={classes.logItem}>
                <div className={classes.logInfo}>
                <div className={classes.dateWrapper}>
                  <span className={classes.epoch}>{log["epoch"]}</span>
                  <div className={classes.module}>
                    <span>{`${module}/${method}`}</span>
                  </div>
                </div>
                <div className={classes.message}>
                  <span className={classes.id}>
                    {log[3] ? `${log[3]}: ` : ""}
                  </span>
                  <div style={{ whiteSpace: "pre-line" }}>{log["logline"]}</div>
                </div>
                </div>

              </li>
            );
          });
}

export const SensorLogsPagination = ({ logs,length, logsCount, isLoading, onLogsPageChange, onSelectLimit}) => {
const limitDropdownItems = [{ item: 10 }, { item: 20 }, { item: 40 }];
  return (
    <div className={classes.pagination}>
      <RivatePagination
        assetsCount={length}
        pageLimit={logsCount}
        onPageChange={(offset) => onLogsPageChange(offset)}
      />
      <div>
        {!isLoading && logs.length ? (
          <LimitDropdown
            setPageLimit={onSelectLimit}
            pageLimit={logsCount}
            limitDropdownItems={limitDropdownItems}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}