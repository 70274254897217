import React, { useCallback, useMemo, useState } from "react";
import {
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import RivataLoader from "../../components/RivataLoader";
import StatusAlert from "../../components/StatusAlert";
import classnames from "classnames";
import RivataModule from "../../components/RivataModule";
import AssetRow from "./AssetRow";
import { WheelOrder, SubscriptionTypes } from "../../enums";
import { useTablesConfig } from "./hooks";

const SensorNodeInfo = ({
  data,
  isSuperAdmin,
  isLoading,
  width,
  locale,
  error,
  preferences,
  subscription = {},
  setAdminLogs,
  assetType
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [onlyIgnoredSensors, setOnlyIgnoredSensors] = useState(true);
 

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab]
  );

  const wheelPositionNames = Object.keys(WheelOrder)

  const isInList = (arr, el) => {
    return arr.some((pos)=>pos === el)
  }


  const sensorsInList = useMemo(() => {
    return data.filter((el) => {
      const isIn = isInList(wheelPositionNames, el.position)
      if (isIn) return el
    })
  }, [data])
  

  const sensorsOutsideList = useMemo(() => {
    return data.filter((el) => {
      const isIn = isInList(wheelPositionNames, el.position)
      if (!isIn) return el
    })
  },[data])



  const sorted = useMemo(() => {
    if (Array.isArray(data)) {
      return [...sensorsInList].sort((d1, d2) => {
        return WheelOrder[d1.position] - WheelOrder[d2.position];
      });
    }
    return data;
  }, [data]);

  const sortedData = useMemo(() => {
    return [...sorted, ...sensorsOutsideList]
  },[sorted, sensorsOutsideList])



  const tablesConfig = useTablesConfig(sortedData, setOnlyIgnoredSensors, assetType)

  const renderTabs = useMemo(() => {
    if (!subscription && !isSuperAdmin) return;
    const subKeys = isSuperAdmin ? Object.values(SubscriptionTypes) : Object.keys(subscription ? subscription : {})

    return tablesConfig.map((tab, index) => {
      if (!tab || !subKeys.find(el => tab.hardwareTypes.includes(el.toLowerCase()))) return null

      return (
        <NavItem className="nav__item mt-2" key={index}>
          <NavLink
            className={classnames({ active: activeTab === index })}
            style={{cursor: "pointer"}}
            onClick={() => toggle(index)}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      );
    });
  }, [activeTab, tablesConfig, toggle, subscription, isSuperAdmin]);

  const renderTables = useMemo(() => {
    if (!subscription && !isSuperAdmin) return;
    const subKeys = isSuperAdmin ? Object.values(SubscriptionTypes) : Object.keys(subscription ? subscription : {})

    return tablesConfig.map((tab, index) => {
      if (!tab || !subKeys.find(el => tab.hardwareTypes.includes(el.toLowerCase()))) return null

      return (
        <TabPane tabId={index} key={index}>
          <Row>
            <Col sm="12">
              <Table hover size="sm" responsive>
                <thead>
                  <tr className="sensor-tables">
                    <th>{locale["Position"]}</th>
                    <th>{locale["Sensor ID"]}</th>
                    <th>{locale["Last Update"]}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((datum, i) => {
                    if (tab && tab.hardwareTypes.includes(datum.hardware_type)) {

                      return (
                        <AssetRow
                          key={`sensor_info_table_rows_${i}`}
                          {...datum}
                          lastUpdated={datum.last_updated_at_epoch}
                          isSuperAdmin={isSuperAdmin}
                          onAdminLogs={setAdminLogs}
                          position={datum.position}
                          locale={locale}
                          timezone={preferences.timezone}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </TabPane>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortedData,
    tablesConfig,
    locale,
    preferences.timezone,
    subscription,
    isSuperAdmin,
  ]);

  return (
    <>
      <RivataModule
        title="Sensor Info"
        width={width}
        locale={locale}
        error={error}
        collapsible
      >
        {isLoading ? (
          <RivataLoader />
        ) : Object.keys(subscription).length < 1 && !isSuperAdmin ? (
          <StatusAlert
            customText="No Active Subscription Found"
            color="success"
          />
        ) : onlyIgnoredSensors ? (
          <StatusAlert customText="No Data" color="success" />
        ) : (
          <>
            <Nav tabs>{renderTabs}</Nav>
            <TabContent activeTab={activeTab}>{renderTables}</TabContent>
          </>
        )}
      </RivataModule>
    </>
  );
};

export default SensorNodeInfo;
