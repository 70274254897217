import React from "react";
import { Alert } from "reactstrap";

const StatusAlert = ({ statusCode, statusText, customText, color = "danger" }) => {
  return (
    <Alert color={color}>
      {statusText && <h5 className="alert-heading">{statusText}</h5>}
      {statusCode && <p className="lead">{statusCode}</p>}
      {customText && <p className="lead">{customText}</p>}
    </Alert>
  );
};

export default StatusAlert;
