import React, { useState, useCallback, useEffect } from "react";
import RivataModule from "../../components/RivataModule";
import RivataTable from "../../components/RivataTable";
import RivataDropdown from '../../components/RivataDropdown';
import ConfirmModal from '../../components/ConfirmModal';
import RivataSearch from '../../components/RivataSearch'
import {
  useColumns,
  useTableRows,
  useAssetNames,
  useSearchFields
} from "./hooks";
import InfoModal from "../../components/InfoModal";
import { getLabel } from './utils'
import { TpmsColumnsIds } from '../../enums'
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import StatusAlert from "../../components/StatusAlert";

const limitDropdownItems = [
  { item: 30 },
  { item: 90 },
  { item: 180 }
]
/*
  tpms assets are fetched on mount itself because of filter reset on mount
  check provision store subscriptions
*/
const AssetTpmsTable = ({
  width,
  locale,
  selectedCustomer
}) => {
  const {
    loading,
    data,
    error,
    totalCount,
    pageLimit,
    tpmsProfiles
  } = useTypedSelector(state => ({
    ...state.provision.tpmsAssets,
    tpmsProfiles: state.provision.tpmsProfiles
  }))

  const {
    fetchProvisionTpmsProfiles,
    setTpmsAssets,
    setTpmsPageLimit,
    setTpmsPageOffset,
    setTpmsPageSortOptions,
    setTpmsAssetsFilter,
    assignWarningSettingsToAssets
  } = useActions()


  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState("");
  const [infoModalMessage, setInfoModalMessage] = useState("");
  const [selectedSearchFiled, setSelectedSearchFiled] = useState(TpmsColumnsIds.ASSET_NAME);


  useEffect(() => {
    if (selectedCustomer?.id) {
      fetchProvisionTpmsProfiles(selectedCustomer.id)
    }
  }, [selectedCustomer, fetchProvisionTpmsProfiles])


  const onSelectProfile = useCallback((id, label) => {
    let assetsArray = [ ...data ]
    let idx
    assetsArray.forEach((item, index) => {
      if (item.id === id) {
        idx = index
      }
      return null
    })

    assetsArray[idx].tpmsProfile = label
    setTpmsAssets(assetsArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // custom hooks
  const columns = useColumns(locale, selectAll);
  const assetNames = useAssetNames(selectedRows, data)
  const searchFields = useSearchFields()
  const tableRows = useTableRows(
    data,
    selectAll,
    selectedRows,
    onSelectProfile,
    selectedProfileId,
  )

  const checkRowColumnHandler = (_columnId, checked) => {
    setSelectAll(checked);
    setSelectedRows(() => {
      return data.map((asset) => {
        return asset.id
      })
    })
    if (!checked) {
      setSelectedRows([])
    }
  }

  const checkRowItemHandler = (data, checked) => {
    if (selectedRows.includes(data.assetId)) {
      let array = [...selectedRows]
      let index = array.indexOf(data.assetId)
      array.splice(index, 1)
      setSelectedRows(array)
    } else if (selectAll) {
      setSelectedRows(() => {
        return data.map((asset) => {
          if (asset.id === data.assetId) return null
          return asset.id
        })
      })
    } else {
      let array = [...selectedRows]
      array.push(data.assetId)
      setSelectedRows(array)
    }
    setSelectAll(false);
  }

  const selectProfileHandler = (profileId) => {
    if (profileId !== "") {
      setSelectedProfileId(profileId)
    }
  }

  const saveChangesHandler = async () => {
    setSelectedProfileId("")
    setSelectedRows([])
    setSelectAll(false)

    const data = [];

    const warning_settings_id = selectedProfileId === "systemDefaults" ? null : parseInt(selectedProfileId);

    selectedRows.forEach(asset_id => {
      data.push({
        asset_id,
        warning_settings_id
      })
    });

    const result = await assignWarningSettingsToAssets(data, selectedCustomer.id);

    if (result && result.status === "unsuitable_assets") {
      const errorStack = result.errors.map((error) => {
        return error.message
      }) 
      setInfoModalMessage(`${errorStack.join("\n")}`);
    } else if (result?.status === "ok") {
      setInfoModalMessage(`Profile successfully assigned`);
    }
  }
  return (
    <RivataModule
      title="TPMS Assets"
      width={width}
      locale={locale}
      marginTop={0}
      error={error}
      filters={
        <>
          {data.length ? (
            <>
              <RivataDropdown
                items={tpmsProfiles}
                selected={selectedProfileId}
                onSelect={selectProfileHandler}
                disabled={selectedRows.length ? false : true}
                buttonLabel={"Assign Profile"}
              />
            </>
          ) : null}
          
          <RivataSearch
            locale={locale}
            searchFields={searchFields}
            onFilter={setTpmsAssetsFilter}
            searchColumn={selectedSearchFiled}
            onSelectSearchField={setSelectedSearchFiled}
            clearFilter={selectedCustomer?.id}
            preventFilterClear={true}
          />
        </>
      }
    >
      {!selectedCustomer?.id ? (
        <StatusAlert
          customText={"TPMS Assets can not be shown when All customers are selected. Please select a customer in dropdown."}
          color={"success"}
        />
      ) : (
        <>
          {selectedProfileId !== "" ? (
            <ConfirmModal
              open={true}
              onClose={() => setSelectedProfileId("")}
              modalButtons={[
                { id: 1, label: "Save Changes", color: "success", onClick: saveChangesHandler },
              ]}
            >
              <div>
                <h5>Confirm Changes?</h5>
                <p>You are going to assign profile "{getLabel(tpmsProfiles, selectedProfileId)}" to these assets:</p>
                <p>{assetNames}</p>
              </div>
            </ConfirmModal>
          ) : null}
          <InfoModal open={!!infoModalMessage} header={`Profile info`} message={infoModalMessage} onConfirm={() => setInfoModalMessage("")} />
          <RivataTable
            title="TPMS Assets"
            width={width}
            locale={locale}
            isLoading={loading}
            columns={columns}
            rows={tableRows}
            onCheckRowColumn={checkRowColumnHandler}
            onCheckRowItem={checkRowItemHandler}
            isShowingLimit={true}
            assetsCount={totalCount}
            onPageChange={setTpmsPageOffset}
            onSelectLimit={setTpmsPageLimit}
            pageLimit={pageLimit}
            limitDropdownItems={limitDropdownItems}
            tpmsProfiles={tpmsProfiles}
            showPagination={true}
            setSortOptions={setTpmsPageSortOptions}
          />
        </>
      )}
    </RivataModule>
  );
};

export default AssetTpmsTable;