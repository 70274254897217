import {
  GET_ADMIN_SENSOR_LOGS,
  SET_LOGS_LOADING,
  SET_GATEWAY_LOGS,
  SET_LOGS_FETCH_ERROR,
  RESET_LOGS_TABLE
} from "../actionTypes";

const initialState = {
  error: null,
  isLoading: false,
  sensorLogs: {
    logs: [],
    length: 0,
  },
  gatewayLogs: {
    gateLogs: [],
  },
};

const getAdminLogs = (state = initialState, action) => {
  switch (action.type) {

    case  SET_LOGS_LOADING:
      return { ...state, isLoading: action.payload, sensorLogs: { ...state.sensorLogs } }
    case GET_ADMIN_SENSOR_LOGS:
      return {
        ...state,
        sensorLogs: { ...state.sensorLogs, logs: action.payload.logs, length: action.payload.length },
        gatewayLogs: { ...state.gatewayLogs, gateLogs: [] },
      };
    case SET_GATEWAY_LOGS:
      return {
        ...state,
        gatewayLogs: { ...state.gatewayLogs, gateLogs: action.payload },
        sensorLogs: { ...state.sensorLogs, logs: [], length: 0},
      };
    case SET_LOGS_FETCH_ERROR:
      return {
        ...state, error: action.payload
      }
    case RESET_LOGS_TABLE:
      return {
        ...initialState
      }
    default:
      return state;
  }
};

export default getAdminLogs;