import { TractorBubblePositions, TrailerBubblePositions } from "../../enums";
import { sortBy } from "lodash";

export const getPositions = (prefixes, positions = []) => {
  for (let i = 1; i <= 5; i++) {
    prefixes.forEach((item) => {
      positions.push({ key: `${item}_${i}`, axle: i });
    });

    prefixes.forEach((item) => {
      positions.push({ key: `${item}_${i}_INNER`, axle: i });
    });

    prefixes.forEach((item) => {
      positions.push({ key: `${item}_${i}_OUTER`, axle: i });
    });
  }

  return positions;
};

export const indexAjustment = (index, order, arr, arr2)=>{
  if(arr.length < arr2.length){
    if(index !== order){
      return order
    }
    return index
  }
  return index
}

const intervalsAjustment = (arr)=>{
  const maxOrder = Math.max.apply(Math, arr.map((el)=> el.order))
  const isNullOrder = arr.some((el)=>el.order === 0)
  if(arr.length){
    return Array(isNullOrder ? maxOrder+1 : maxOrder).fill(0)
  }
  else return []
}

export const getAxles = (sensors, positions, assetType) => {
  let bubblePositions = [];

  if (assetType === "tractor") bubblePositions = TractorBubblePositions;

  if (assetType === "trailer") bubblePositions = TrailerBubblePositions;

  const axles = sortBy(
    sensors.reduce((acc, sensor) => {
      const position = positions.find((p) => p.key === sensor.key);

      if (position) {
        const existingAxle = acc.find((axle) => axle.order === position.axle);

        if (!existingAxle) {
          acc.push({ order: position.axle, sensors: [sensor] });
        } else {
          existingAxle.sensors.push(sensor);
        }
      }

      return acc;
    }, []),
    (a) => a.order
  );

  axles.forEach((axle, index) => {
    const ajustedIntervals = intervalsAjustment(axles)
    const i = indexAjustment(index, axle.order-1, axles, ajustedIntervals)
    axle.positionBubble = bubblePositions[i];
  });
  return axles;
};

export const getIntervals = (axles, axleInterval, axleLeftMargin) => {
  const maxOrderTotal = intervalsAjustment(axles)

  return maxOrderTotal.reduce(
      (acc, _, index) => {
        if (index + 1 !== maxOrderTotal.length) acc.push(acc[acc.length - 1] + axleInterval);
        return acc;
      },
      [axleLeftMargin]
    ).reverse();
};
