import React, { useEffect, useState } from "react";
import RivataModule from "../../../components/RivataModule";
import GeofenceMap from "../map/GeofenceMap";
import GeofenceEditor from "../editor/GeofenceEditor";
import RivataTable from "../../../components/RivataTable";
import DeleteModal from "../../../components/DeleteModal";
import LayersDropdown from "../../../components/LayersDropdown";
import { LayerTypes } from "../../../components/RivataMapCluster/utils";
import { useColumns, useTableRows } from "./hooks";

import "../styles.scss";
import { GeofencesSortPayload } from "../../../redux/geofences/types";
import { useDispatch } from "react-redux";
import { setPagedGeofences } from "../../../redux/geofences/action";

interface Props {
  width: number;
  locale: Record<string, string>;
  isLoading: boolean;
  geofences: Array<IGeofence>;
  limit: number;
  totalCount: number;
  selectedCustomerId: number | null;
  fetchGeofences: () => Promise<void>;
  createGeofence: (geofence: IGeofence) => Promise<ApiResult>;
  updateGeofence: (id: number, geofence: IGeofence) => Promise<ApiResult>;
  deleteGeofence: (id: number) => Promise<ApiResult>;
  setGeofencesOffset: (offset: number) => void;
  setGeofencesLimit: (limit: number) => void;
  setGeofencesSortOptions: (sort: GeofencesSortPayload) => void
  setActiveTab: ()=>void
}

const GeofenceManager: React.FC<Props> = ({
  width,
  locale,
  isLoading,
  geofences,
  limit,
  totalCount,
  selectedCustomerId,
  fetchGeofences,
  createGeofence,
  updateGeofence,
  deleteGeofence,
  setGeofencesOffset,
  setGeofencesLimit,
  setGeofencesSortOptions,
  setActiveTab
}) => {
  const [map, setMap] = useState<H.Map | null>(null);
  const [ui, setUi] = useState<H.ui.UI | null>(null);
  const [selectedLayerOption, setSelectedLayerOption] = useState(LayerTypes.NORMAL);

  const [deleteData, setDeleteData] = useState<IGeofence | null>(null);
  const [selectedGeofence, setSelectedGeofence] = useState<IGeofence | null>(null);
  const [zoomTo, setZoomTo] = useState<number | null>(null);

  const [resetEditorState, setResetEditorState] = useState(0);
  const [error, setError] = useState("");
  
  const dispatch = useDispatch();

  const columns = useColumns();
  const rows = useTableRows(geofences);

  const onDelete = async (id: number) => {
    const result: ApiResult = await deleteGeofence(id);

    if (result.ok) {
      setDeleteData(null);
    } else {
      setError(result.message);
    }
  };

  const onEdit = (geofence: IGeofence) => {
    setZoomTo(null);
    setSelectedGeofence(geofence);
     window.scrollTo({
     top: 0,
       behavior: "smooth",
     });
  };

  useEffect(() => {
    fetchGeofences();
  }, [fetchGeofences]);

  return (
    <RivataModule
      fullScreenModalModeEnabled
      customFullScreenAction={() => {
        // TODO hack to rerender map in full screen
        setTimeout(() => {
          dispatch(setPagedGeofences([...geofences]))
        }, 100)
      }}
      title="Manage Geofences"
      width={width}
      marginTop={0}
      locale={locale}
      filters={<LayersDropdown selected={selectedLayerOption} onSelect={(type: string) => setSelectedLayerOption(type)} locale={locale} />}
    >
      <GeofenceEditor
        map={map}
        ui={ui}
        geofences={geofences}
        selectedGeofence={selectedGeofence}
        zoomTo={zoomTo}
        selectedCustomerId={selectedCustomerId}
        resetEditorState={resetEditorState}
        setSelectedGeofence={setSelectedGeofence}
        createGeofence={createGeofence}
        updateGeofence={updateGeofence}
        setActiveTab={setActiveTab}
      />

      <GeofenceMap map={map} selectedLayerOption={selectedLayerOption} setMap={setMap} setUi={setUi} />

      <RivataTable
        showPagination={true}
        assetsCount={totalCount}
        // @ts-ignore component expect undefined cause of default value
        onPageChange={(offset: number) => {
          if (map) map.getViewModel().setLookAtData({ zoom: 4 }, true);
          setZoomTo(null);
          setResetEditorState((prev) => (prev += 1));
          setGeofencesOffset(offset);
        }}
        // @ts-ignore component expect undefined cause of default value
        onSelectLimit={(limit: number) => {
          setZoomTo(null);
          setResetEditorState((prev) => (prev += 1));
          setGeofencesLimit(limit);
        }}
        // @ts-ignore component expect undefined cause of default value
        onRowClick={(geofence: IGeofence) => {
          setResetEditorState((prev) => (prev += 1));
          if (geofence.id) setZoomTo(geofence.id);
        }}
        isShowingLimit={true}
        pageLimit={limit}
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        onDelete={(geofence: IGeofence) => {
          setDeleteData(geofence);
        }}
        onEdit={(geofence: IGeofence) => {
          onEdit(geofence);
        }}
        filter={JSON.stringify(selectedCustomerId)}
        customDeleteStyle={{ width: "50px" }}
        customEditStyle={{ width: "50px" }}
        onCustomAction={undefined}
        onSelectedRow={undefined}
        editDisabled={undefined}
        deleteDisabled={undefined}
        daysCount={undefined}
        limitDropdownItems={undefined}
        tpmsProfiles={undefined}
        setSortOptions={setGeofencesSortOptions}
      />

      {deleteData && (
        <DeleteModal
          open={true}
          disabled={false}
          header={`Delete geofence ${deleteData?.name}?`}
          message={"This cannot be undone!"}
          onDelete={() => {
            setError("");
            if (deleteData.id) onDelete(deleteData.id);
            setResetEditorState((prev) => (prev += 1));
          }}
          onCancel={() => {
            setError("");
            setDeleteData(null);
          }}
          locale={locale}
          error={error}
        />
      )}
    </RivataModule>
  );
};

export default GeofenceManager;
