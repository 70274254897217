import {
  NotificationThresholds,
  Timezones,
  Languages,
  UnitsOfMeasurement,
} from "../../enums";

const defaultConfig = {
  preferenceDefaults: {
    notifications: {
      receiveEmail: false,
      receiveText: false,
      threshold: NotificationThresholds.WARNING,
    },
    locale: {
      language: Languages.EN,
      timezone: Timezones.AMERICA_LOS_ANGELES,
    },
    unitsOfMeasurement: UnitsOfMeasurement.imperial,
  },
  dash: [
    {
      id: "FleetMap",
      data: {
        title: "Fleet Map",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "Warnings",
      data: {
        title: "Warnings",
        width: 4,
        enabled: false,
      },
    },
    {
      id: "AssetList",
      data: {
        title: "Asset List",
        width: 12,
        enabled: true,
      },
    },
  ],
  details: [
    {
      id: "VehicleInfo",
      data: {
        title: "VehicleInfo",
        width: 4,
        enabled: true,
      },
    },
    {
      id: "SensorInfo",
      data: {
        title: "SensorInfo",
        width: 8,
        enabled: true,
      },
    },
    {
      id: "TpmsStatusDiagram",
      data: {
        title: "TpmsStatusDiagram",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "RecentWarnings",
      data: {
        title: "RecentWarnings",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "DetailsMap",
      data: {
        title: "Details Map",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "TemperatureHistory",
      data: {
        title: "Temperature History",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "HubVibration",
      data: {
        title: "Hub Vibration",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "TirePressure",
      data: {
        title: "Tire Pressure",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "AxleLoad",
      data: {
        title: "Axle Load",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "LinePressure",
      data: {
        title: "Line Pressure",
        width: 12,
        enabled: true,
      },
    },
  ],
  warnings: [
    {
      id: "RecentWarnings",
      data: {
        title: "RecentWarnings",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "DetailsMap",
      data: {
        title: "Details Map",
        width: 12,
        enabled: true,
      },
    },
    {
      id: "TemperatureChart",
      data: {
        title: "TemperatureChart",
        width: 6,
        enabled: true,
      },
    },
    {
      id: "HubVibration",
      data: {
        title: "Hub Vibration",
        width: 6,
        enabled: true,
      },
    },
    {
      id: "TirePressure",
      data: {
        title: "Tire Pressure",
        width: 6,
        enabled: true,
      },
    },
    {
      id: "LinePressure",
      data: {
        title: "Line Pressure",
        width: 6,
        enabled: true,
      },
    },
    {
      id: "LocationChart",
      data: {
        title: "LocationChart",
        width: 6,
        enabled: true,
      },
    },
  ],
  themeColors: [
    {
      id: "Primary1",
      label: "Primary 1",
      color: "#ba0c2f",
    },
    {
      id: "Primary2",
      label: "Primary 2",
      color: "#6d6e71",
    },
    {
      id: "Secondary1",
      label: "Secondary 1",
      color: "#bcbcbc",
    },
    {
      id: "Secondary2",
      label: "Secondary 2",
      color: "#dcddde",
    },
    {
      id: "Secondary3",
      label: "Secondary 3",
      color: "#414042",
    },
  ],
  healthColors: [
    {
      id: "HealthGood",
      label: "Health Good",
      color: "#6ABF4B",
    },
    {
      id: "HealthWarning",
      label: "Health Warning",
      color: "#FED925",
    },
    {
      id: "HealthCriticalWarning",
      label: "Health Critical Warning",
      color: "#CE5500",
    },
  ],
  graphColors: [
    {
      id: "GraphPalette1",
      label: "Graph Palette 1",
      color: "#7400b8",
    },
    {
      id: "GraphPalette2",
      label: "Graph Palette 2",
      color: "#6930c3",
    },
    {
      id: "GraphPalette3",
      label: "Graph Palette 3",
      color: "#5e60ce",
    },
    {
      id: "GraphPalette4",
      label: "Graph Palette 4",
      color: "#5390d9",
    },
    {
      id: "GraphPalette5",
      label: "Graph Palette 5",
      color: "#4ea8de",
    },
    {
      id: "GraphPalette6",
      label: "Graph Palette 6",
      color: "#48bfe3",
    },
    {
      id: "GraphPalette7",
      label: "Graph Palette 7",
      color: "#56cfe1",
    },
    {
      id: "GraphPalette8",
      label: "Graph Palette 8",
      color: "#64dfdf",
    },
    {
      id: "GraphPalette9",
      label: "Graph Palette 9",
      color: "#72efdd",
    },
    {
      id: "GraphPalette10",
      label: "Graph Palette 10",
      color: "#80ffdb",
    },
  ],
  wheelColors: [
    {
      id: "SteerLeft",
      label: "Steer Left",
      color: "#297568",
    },
    {
      id: "SteerRight",
      label: "Steer Right",
      color: "#40beac",
    },
    {
      id: "DriveLeft1",
      label: "Drive Left 1 Outer OR Drive Left 1",
      color: "#12284b",
    },
    {
      id: "DriveLeft1Inner",
      label: "Drive Left 1 Inner",
      color: "#3ab0c7",
    },
    {
      id: "DriveRight1",
      label: "Drive Right 1 Outer OR Drive Right 1",
      color: "#066c8b",
    },
    {
      id: "DriveRight1Inner",
      label: "Drive Right 1 Inner",
      color: "#85d4ec",
    },
    {
      id: "DriveLeft2",
      label: "Drive Left 2 Outer OR Drive Left 2",
      color: "#749799",
    },
    {
      id: "DriveLeft2Inner",
      label: "Drive Left 2 Inner",
      color: "#914921",
    },
    {
      id: "DriveRight2",
      label: "Drive Right 2 Outer OR Drive Right 2",
      color: "#aa1e54",
    },
    {
      id: "DriveRight2Inner",
      label: "Drive Right 2 Inner",
      color: "#54575a",
    },

    {
      id: "DriveLeft3",
      label: "Drive Left 3 Outer OR Drive Left 3",
      color: "#63EB12",
    },
    {
      id: "DriveLeft3Inner",
      label: "Drive Left 3 Inner",
      color: "#EA15ED",
    },
    {
      id: "DriveRight3",
      label: "Drive Right 3 Outer OR Drive Right 3",
      color: "#123045",
    },
    {
      id: "DriveRight3Inner",
      label: "Drive Right 3 Inner",
      color: "#CE8C30",
    },

    {
      id: "DriveLeft4",
      label: "Drive Left 4 Outer OR Drive Left 4",
      color: "#35545C",
    },
    {
      id: "DriveLeft4Inner",
      label: "Drive Left 4 Inner",
      color: "#48351A",
    },
    {
      id: "DriveRight4",
      label: "Drive Right 4 Outer OR Drive Right 4",
      color: "#21CD4A",
    },
    {
      id: "DriveRight4Inner",
      label: "Drive Right 4 Inner",
      color: "#D1605A",
    },

    {
      id: "TrailerLeft1",
      label: "Trailer Left 1 Outer OR Trailer Left 1",
      color: "#663117",
    },
    {
      id: "TrailerLeft1Inner",
      label: "Trailer Left 1 Inner",
      color: "#600f34",
    },
    {
      id: "TrailerRight1",
      label: "Trailer Right 1 Outer OR Trailer Right 1",
      color: "#b9dde1",
    },
    {
      id: "TrailerRight1Inner",
      label: "Trailer Right 1 Inner",
      color: "#ed7624",
    },
    {
      id: "TrailerLeft2",
      label: "Trailer Left 2 Outer OR Trailer Left 2",
      color: "#f27d9c",
    },
    {
      id: "TrailerLeft2Inner",
      label: "Trailer Left 2 Inner",
      color: "#878a8c",
    },
    {
      id: "TrailerRight2",
      label: "Trailer Right 2 Outer OR Trailer Right 2",
      color: "#f1b48b",
    },
    {
      id: "TrailerRight2Inner",
      label: "Trailer Right 2 Inner",
      color: "#eeb4c7",
    },
    {
      id: "TrailerLeft3",
      label: "Trailer Left 3 Outer OR Trailer Left 3",
      color: "#E683B4",
    },
    {
      id: "TrailerLeft3Inner",
      label: "Trailer Left 3 Inner",
      color: "#E04731",
    },
    {
      id: "TrailerRight3",
      label: "Trailer Right 3 Outer OR Trailer Right 3",
      color: "#818A38",
    },
    {
      id: "TrailerRight3Inner",
      label: "Trailer Right 3 Inner",
      color: "#FAE4A7",
    },

    {
      id: "TrailerLeft4",
      label: "Trailer Left 4 Outer OR Trailer Left 4",
      color: "#D248D8",
    },
    {
      id: "TrailerLeft4Inner",
      label: "Trailer Left 4 Inner",
      color: "#733E83",
    },
    {
      id: "TrailerRight4",
      label: "Trailer Right 4 Outer OR Trailer Right 4",
      color: "#55DDED",
    },
    {
      id: "TrailerRight4Inner",
      label: "Trailer Right 4 Inner",
      color: "#0145AD",
    },

    {
      id: "TrailerLeft5",
      label: "Trailer Left 5 Outer OR Trailer Left 5",
      color: "#98FD66",
    },
    {
      id: "TrailerLeft5Inner",
      label: "Trailer Left 5 Inner",
      color: "#A940EE",
    },
    {
      id: "TrailerRight5",
      label: "Trailer Right 5 Outer OR Trailer Right 5",
      color: "#7F8788",
    },
    {
      id: "TrailerRight5Inner",
      label: "Trailer Right 5 Inner",
      color: "#A5D957",
    },
  ],
  gpsColors: [
    {
      id: "GpsSpeed",
      label: "Speed",
      color: "#12284b",
    },
    {
      id: "GpsElevation",
      label: "Elevation",
      color: "#ed7624",
    },
  ],
};
export default defaultConfig;
