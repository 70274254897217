import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SubNav from "../../components/SubNav";
import SupportInfo from "../../modules/SupportInfo";
import RivataLoader from "../../components/RivataLoader";
import { getFileTitle } from "../../modules/ManageSupportModule/utils";
import { useActions } from "../../hooks/useActions";

const Support = ({ locale = {}, documents: { isLoading, documents }  }) => {
  const { fetchDocuments } = useActions()
  
  useEffect(() => {
    fetchDocuments()
  }, [fetchDocuments])

  return (
    <>
      <SubNav title="Support" breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Support" }]} />
      <SupportInfo locale={locale}>
        {isLoading ? (
          <div className="d-flex" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <RivataLoader />
          </div>
        ) : (
          <div className="d-flex" style={{ minHeight: "150px" }}>
            <div>
              <h3 className="mb-4">Contact Information</h3>
              <p className="mb-0" style={{ marginTop: "1.6rem", padding: "5px 0" }}>
                Phone: 800-547-9473
              </p>
              <p className="mb-0" style={{ marginTop: "0.6rem", padding: "5px 0" }}>
                Email: <a href="mailto:digital@conmet.com">digital@conmet.com</a>
              </p>
            </div>
            <div style={{ marginLeft: "6rem" }}>
              <h3 className="mb-4">Resources</h3>
              <div className="mt-1">
                <Link to="/faqs" className="btn btn-secondary" style={{ width: "250px", textAlign: "left" }}>
                  <i className="fa fa-question-circle font-16 mr-1" />
                  FAQs
                </Link>
              </div>
            </div>

            {documents.length ? (
              <div style={{ marginLeft: "6rem" }}>
                <h3 className="mb-4">Legal</h3>

                <div className="d-flex flex-column">
                  {documents.map((document, index) => (
                    <Link
                      key={index}
                      to={{ pathname: "/legal", url: document.url, fileName: document.file_name }}
                      className="btn btn-secondary"
                      style={{ textAlign: "left", marginBottom: "10px" }}
                    >
                      {getFileTitle(document.file_name)}
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </SupportInfo>
    </>
  );
};

const mapStateToProps = (state) => ({
  documents: state.documents,
});

export default connect(mapStateToProps)(Support);
