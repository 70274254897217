import React from 'react'
import logo from '../../assets/images/conmet_brand/conmet_digital.png'
import './style.scss'

const InitialLoader: React.FC = () => {
    return (
        <div className="loader__wrapper">
            <div className="brand-logo">
            <div className="animated-loader">
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
                <div className="loading__item"></div>
            </div>
            <img
                src={logo}
                alt="logo"
            />
            </div>
        </div>
    )
}

export default InitialLoader