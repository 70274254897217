import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button } from "reactstrap";

const pdfjs = require("pdfjs-dist");
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

const ReactPdfViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pageNumber]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-between" style={{ width: "100%" }}>
      <div style={{ height: "1200px" }}>
        <Document file={{ url: file }} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} height={1200} />
        </Document>
      </div>

      <div className="d-flex" style={{ marginTop: "20px" }}>
        <Button
          disabled={pageNumber === 1}
          onClick={() => {
            if (pageNumber > 1) setPageNumber(pageNumber - 1);
          }}
        >
          &#8592;
        </Button>

        <div className="d-flex align-items-center justify-content-center" style={{ width: "100px" }}>
          <span>
            Page {pageNumber} of {numPages}
          </span>
        </div>

        <Button
          disabled={pageNumber === numPages}
          onClick={() => {
            if (pageNumber < numPages) setPageNumber(pageNumber + 1);
          }}
        >
          &#8594;
        </Button>
      </div>
    </div>
  );
};

export default ReactPdfViewer;
