import { useEffect, useState } from "react";

const ColumnsIds = {
    Name: "name",
    Latitude: "latitude",
    Longitude: "longitude",
    CreatedAt: "created_at",
    CustomerName: "customer_name",
    Gateways: "gateways"
}

export const useColumns = () => {
    const [columns, setColumns] = useState([])

    useEffect(() => {
        setColumns([
            {
                label: "Name",
                id: ColumnsIds.Name,
                control: "sort"
            },
            {
                label: "Latitude",
                id: ColumnsIds.Latitude
            },
            {
                label: "Longitude",
                id: ColumnsIds.Longitude
            },
            {
                label: "Created At",
                id: ColumnsIds.CreatedAt,
                control: "sort"
            },
            {
                label: "Customer Name",
                id: ColumnsIds.CustomerName,
                control: "sort"
            },
            {
                label: 'Gateways',
                id: ColumnsIds.Gateways
            }
        ])
    }, [])

    return columns;

};


export const useTableRows = (data) => {
    const [rows, setRows] = useState([])

    useEffect(() => {
        const dataRows = []
        data.forEach(d => {
            const row = {
                data: d,
                columns: [
                    {
                        columnId: ColumnsIds.Name,
                        type: "link",
                        label: d.name,
                        link: `/geolocations/${d.id}`
                    },
                    {
                        columnId: ColumnsIds.Latitude,
                        type: "link",
                        label: d.lat,
                        link: `/geolocations/${d.id}`
                    },
                    {
                        columnId: ColumnsIds.Longitude,
                        type: "link",
                        label: d.lon,
                        link: `/geolocations/${d.id}`
                    },
                    {
                        columnId: ColumnsIds.CreatedAt,
                        type: "link",
                        label: d.formatted_created_at_datetime,
                        link: `/geolocations/${d.id}`

                    },
                    {
                        columnId: ColumnsIds.CustomerName,
                        type: "link",
                        label: d.customer_name,
                        link: `/geolocations/${d.id}`

                    },
                    {
                        columnId: ColumnsIds.Gateways,
                        type: "link",
                        label: d.gateways.join(", ") || "-",
                        link: `/geolocations/${d.id}`
                    }
                ]
            }
            dataRows.push(row)
        })
        setRows(dataRows);

    }, [data]);
    return rows
}

export const useMapData = (data) => {
    const [mapData, setMapData] = useState([]);

    useEffect(() => {
        const mD = [];
        let id = 0;
        data.forEach(d => {
            mD.push({
                id: id,
                latitude: Number(d.lat),
                longitude: Number(d.lon),
                name: d.name,
                gateways: d.gateways.join(", ") || "-"
            })
            id++;
        });
        setMapData(mD);
    }, [data])

    return mapData;
}