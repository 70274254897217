import React, { useEffect } from "react";
import SubNav from "../../components/SubNav";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ManageSupportModule from "../../modules/ManageSupportModule";

const ManageSupport = () => {
  const {
    locale = {}, 
    documents: { isLoading, documents }
  } = useTypedSelector(state => ({
    locale: state.whitelabel.locale,
    documents: state.documents,
  }))

  const {
    fetchDocuments,
    addNewDocument,
    deleteDocument
  } = useActions()

  useEffect(() => {
    fetchDocuments()
  }, [fetchDocuments])

  return (
    <>
      <SubNav title="Manage Support" breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Manage Support" }]}/>
      <ManageSupportModule
        width={12}
        locale={locale}
        isLoading={isLoading}
        documents={documents}
        addDocument={addNewDocument}
        removeDocument={deleteDocument}
      />
    </>
  );
};

export default ManageSupport;
