import { useMemo } from "react"
import { IGpsData } from "../../components/RivataMap/types"
import { getElemIdxByKeyInArr, hasCriticalWarning } from './utils'


interface IuseParsedData {
    (
        data: Array<IGpsData>,
        vehicleInfo: { id: number, name: string, vin: string },
        selectedWarning: any,
        setForceMapRerender: (shouldUpdate: boolean) => void
    ): { data: Array<IGpsData> }
}

interface IParsedData {
    data: Array<IGpsData>
}

type ClosestPoint = { data: IGpsData | null, diff: number }

export const useParsedData: IuseParsedData = (
    data,
    vehicleInfo,
    selectedWarning,
    setForceMapRerender,
) => {
    const parsedData: IParsedData = useMemo(() => {
        if (data.length) {
            const { id, name, vin } = vehicleInfo
            let hasAtleast1WarningPoint = false

            const gpsData: Array<IGpsData> = data.map((location, i) => {
                const { has_warning, details } = location.warning_info
                const gpsPoint = {
                    ...location,
                    heading: location.heading === 0 ? 360 : location.heading,
                    id: i,
                    asset_id: id,
                    name,
                    vin,
                    has_critical_warning: false,
                    showAsWarning: false,
                }
                if (has_warning) {
					if (selectedWarning && getElemIdxByKeyInArr(details, "key", selectedWarning.key) > -1) {
						hasAtleast1WarningPoint = true
						gpsPoint.showAsWarning = true
                        gpsPoint.has_critical_warning = hasCriticalWarning(details, "key", selectedWarning.key)
					}
                }

                return gpsPoint
            })

            setForceMapRerender(true)

            if (!hasAtleast1WarningPoint && selectedWarning) {
                const closestPointByEpoch: ClosestPoint = { data: null, diff: selectedWarning.epoch }
                gpsData.forEach(el => {
                    const diff = Math.abs(el.epoch - selectedWarning.epoch)
                    if (diff < closestPointByEpoch.diff) {
                        closestPointByEpoch.data = el
                        closestPointByEpoch.diff = diff
                    }
                })
                const idx = gpsData.findIndex(el => el.id === closestPointByEpoch.data?.id)

                if (idx > -1) {
                    gpsData[idx].showAsWarning = true
                    gpsData[idx].has_critical_warning = selectedWarning.warning_subtype?.split("_").includes("critical")
                }
            }

            return { data: gpsData }
        }
        return { data: [] }
    }, [data, vehicleInfo, selectedWarning, setForceMapRerender])

    return parsedData
}
