import React, { useState } from 'react'
import { Location } from 'history'
import PerfectScrollbar from "react-perfect-scrollbar"
import { Nav } from "reactstrap"

import { INavRoute } from '../RivataPage'
import { useNavList } from './hooks'

import './styles.scss'

interface ISubscriptionData {
    valid_to: number
}

export interface ISubscriptions {
    HUB_SENSOR?: ISubscriptionData
    TIRE_SENSOR?: ISubscriptionData
    AXLE_LOAD?: ISubscriptionData
    LINE_PRESSURE?: ISubscriptionData
}

interface ISidebarProps {
    isSuperAdmin: boolean
    navRoutes: Array<INavRoute>
    location: Location
    subscriptions: ISubscriptions
    versionInfo: {
        branch: string
        commit_uid: string
        build_branch: string
        build_fullhash: string
    }
    settings: any
}

const Sidebar: React.FC<ISidebarProps> = ({
    isSuperAdmin = false,
    navRoutes,
    subscriptions,
    versionInfo,
    location,
    settings
}) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const navList = useNavList(isSuperAdmin, navRoutes, location, subscriptions)

    const handleMenuExpand = (isExpanded: boolean) => {
        const logo = document.getElementById("logobg")
        if (logo && isExpanded) {
            logo.classList.add("expand-logo")
            setIsExpanded(true)
        } else if (logo) {
            logo.classList.remove("expand-logo")
            setIsExpanded(false)
        }
    }

    return (
        <aside
            className="left-sidebar"
            id="sidebarbg"
            data-sidebarbg={settings.activeSidebarBg}
            onMouseOver={() => handleMenuExpand(true)}
            onMouseLeave={() => handleMenuExpand(false)}
            style={{ zIndex: 1000 }}
        >
            <div className="scroll-sidebar">
                <PerfectScrollbar className="sidebar-nav"> 
                    <Nav id="sidebarnav" >
                        {navList}
                    </Nav>
                    {isExpanded &&
                        <div style={{ position: 'absolute', bottom: 0, left: 15, fontSize: 12, color: 'grey' }}>
                            {(versionInfo.build_branch || versionInfo.build_fullhash) && <div>f: {versionInfo.build_branch} {versionInfo.build_fullhash}</div>}
                            {(versionInfo.branch || versionInfo.commit_uid) && <div>b: {versionInfo.branch} {versionInfo.commit_uid}</div>}
                        </div>
                    }
                </PerfectScrollbar>
            </div>
        </aside>
    )
}

export default Sidebar
