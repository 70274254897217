import React from 'react';
import { Button, Row } from 'reactstrap';
import * as Yup from "yup";
import { FormField } from "../../components/CreateModal";
import CreateModal from "../../components/CreateModal/children";
import RivataLoader from '../../components/RivataLoader';
import { containsOnlyNumbers, removeSpecialCharacters } from '../../utils';
import './styles.scss';

const validateGatewayFormat = (value) => {
    value = removeSpecialCharacters(value);
    const digitsOnly = containsOnlyNumbers(value);
    if (!digitsOnly || value.length !== 10) {
        return false;
    }
    return true;
}

const LocationGatewaySchema = Yup.object().shape({
    gateway_esn: Yup.string().notRequired().test("format", "Incorrect gateway format", validateGatewayFormat)
});

const LocationGatewayModal = ({
    locale = {},
    onCreate,
    disabled,
    initialValues,
    open,
    onClose,
    data,
    onDelete,
    isLoading
}) => {
    let gateways = data.gateways;
    return (
        <CreateModal
            variant="primary"
            disabled={disabled}
            className="ml-3"
            btnClassName="ml-3"
            onCreate={onCreate}
            createBtnLabel={"Save"}
            header={`Gateways`}
            locale={locale}
            schema={LocationGatewaySchema}
            initialValues={initialValues}
            onClose={onClose}
            open={open}
        >
            {isLoading && <RivataLoader />}
            {gateways && <>
                {gateways.map(gw => {
                    return (<div key={gw} className="assigned-gateway">
                        <span>{gw}</span>
                        <Button
                            className="pl-2 pr-1"
                            close
                            disabled={false}
                            onClick={async () => {
                                const result = await onDelete(gw)
                                if (result.ok) {
                                    const index = gateways.indexOf(gw);
                                    if (index > -1) {
                                        gateways.splice(index, 1);
                                    }
                                }

                            }}
                        />
                    </div>)
                })}
            </>}
            <Row className="p-t-0">
                <FormField name="gateway_esn" label="Assign Gateway" />
            </Row>
        </CreateModal>
    );
};

export default LocationGatewayModal;