import React, { useEffect, useState } from "react";
// import { Tooltip, Button } from 'reactstrap'

import RivataModule from "../../components/RivataModule";
import RivataTable from "../../components/RivataTable";
import InfoModal from "../../components/InfoModal";
import AssetModal from "./AssetModal";
import DeleteModal from "../../components/DeleteModal";
import AssetMigrateModal from "./AssetMigrateModal";
import { CustomInput } from 'reactstrap'
import { AssetTypes } from "../../enums";

import { useTableRows, useColumns } from "./hooks";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

import { assetRequest } from "./utils";
import { getStatusObj } from "../../utils/utils";
import { lastReportWasToday } from "../../pages/Provision/utils"

const makeMigrationPayload = (new_customer_key, vin) => {
  return {
    "customer_key": new_customer_key,
    "asset_vin": vin
  };
}

const AssetsTable = ({
  width,
  locale = {},
  selectedCustomer
}) => {
  const {
    auth: { isSuperAdmin, isAdmin, whiteLabelUrl, preferences },
    assets: { loading, data, totalCount, error, pageLimit },
    common: {hideAutogeneratedAssets}
  } = useTypedSelector(state => ({
    auth: state.auth,
    assets: state.provision.assets,
    common: state.common,
  }))
  const {
    fetchProvisionAssets,
    setProvisionAssetsLimit,
    setProvisionAssetsOffset,
    setProvisionAssetsSortOptions,
    setProvisionAssetsError,
    addAsset,
    updateAsset,
    deleteAsset,
    migrateAssetBetweenCustomers,
    setHideAutogeneratedAssets,
  } = useActions()


  const columns = useColumns(locale, preferences.customer_ids, isSuperAdmin);
  const rows = useTableRows(data, columns, whiteLabelUrl, preferences.customer_ids, isSuperAdmin);
  const [modalUpdate, setModalUpdate] = useState(null);
  const [modalDelete, setModalDelete] = useState(null);
  const [assetMigrateModalData, setAssetMigrateModalData] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  useEffect(() => {
    if (selectedCustomer) fetchProvisionAssets()
  }, [fetchProvisionAssets, selectedCustomer])


  useEffect(() => {
    setButtonsDisabled(loading || !selectedCustomer)
  }, [loading, selectedCustomer])

  const getWarningMessage = () => {
    if (modalDelete?.last_gps_update_epoch) {
      if (lastReportWasToday(modalDelete.last_gps_update_epoch)) {
        return "This asset is reporting data. Are you sure you want to delete this asset?"
      }
    }
  }

  const handleCreateAsset = async (values) => {
    const reqBody = assetRequest(values, selectedCustomer.id)
    const res = await addAsset(reqBody)
    return res
  }

  const handleUpdateAsset = async (values) => {
    const reqBody = assetRequest(values, selectedCustomer.id)
    const res =  await updateAsset(reqBody)
    return res
  }

  const handleDeleteAsset = async (id) => {
    if (id) {
      deleteAsset(id)
    }
  }

  const handleAssetMigration = async (values) => {
    const payload = makeMigrationPayload(values.new_customer, assetMigrateModalData.vin);  
    
    const res = await migrateAssetBetweenCustomers(payload);
    if (res.statusCode <= 201) {
      fetchProvisionAssets()
    }
    return res;
  }

  const onChangeAutogenerated = () => {
    setHideAutogeneratedAssets(!hideAutogeneratedAssets)
    fetchProvisionAssets()
  }

  return (
    <RivataModule
      title="Assets"
      width={width}
      locale={locale}
      marginTop={0}
      filters={
        <>
          {isSuperAdmin || isAdmin ? (
            <>
              <CustomInput
              id="hideAutogenerated"
              className="d-flex align-items-center mr-3"
              type="checkbox"
              label="Hide Autogenerated Assets"
              checked={hideAutogeneratedAssets}
              onChange={() => onChangeAutogenerated() }
            />
              <AssetModal
                disabled={!!modalUpdate || loading || !selectedCustomer?.id}
                onCreate={handleCreateAsset}
                title="Create"
                locale={locale}
                isUpdate={false}
                initialValues={{
                  name: "",
                  vin: "",
                  year: "",
                  make: "",
                  model: "",
                  odometer_start: "",
                  asset_type: AssetTypes.TRACTOR,
                }}
              />
            </>
          ) : null}
        </>
      }
    >
      <InfoModal
        onConfirm={() => setProvisionAssetsError(getStatusObj())}
        open={error.statusCode <= 201 && error.statusCode !== 0}
        message={error.message}
        header="Assets Info"
      />
      {(isAdmin || isSuperAdmin) && (
        <DeleteModal
          open={!!modalDelete}
          disabled={false}
          header={`Delete asset "${modalDelete?.name}"?`}
          warningMessage={getWarningMessage()}
          message={"This cannot be undone!"}
          onDelete={() => {
            handleDeleteAsset(modalDelete.id);
            setModalDelete(null);
          }}
          onCancel={() => setModalDelete(null)}
          locale={locale}
        />
      )}
      {modalUpdate && ( // rerender so new initialValues show up
        <AssetModal
          disabled={buttonsDisabled}
          onCreate={handleUpdateAsset}
          onClose={() => setModalUpdate(null)}
          title="Update"
          locale={locale}
          isUpdate={true}
          open={!!modalUpdate}
          initialValues={modalUpdate}
        />
      )}
      <RivataTable
        columns={columns}
        rows={rows}
        assetsCount={totalCount}
        showPagination={rows.length >= pageLimit}
        isShowingLimit={true}
        isLoading={loading}
        setSortOptions={setProvisionAssetsSortOptions}
        onPageChange={(offset) => setProvisionAssetsOffset(offset)}
        onSelectLimit={(limit) => {
          setProvisionAssetsLimit(limit)
        }}
        pageLimit={pageLimit}
        editDisabled={buttonsDisabled}
        deleteDisabled={buttonsDisabled}
        onEdit={(data) =>
          setModalUpdate( // change null and undefined values to "" for input
            Object.keys(data).reduce((a, c) => {
              a[c] = data[c] === null || data[c] === undefined ? "" : data[c];
              return a;
            }, {})
          )
        }
        onDelete={(isAdmin || isSuperAdmin) ? (data) => setModalDelete(data) : null}
        onCustomAction={(isAdmin || isSuperAdmin) ? (data) => { setAssetMigrateModalData(data) } : null}
        customActionIcon={"fas fa-arrows-alt-h"}
        customActionTooltipText="Migrate Asset"
        editTooltipText="Edit Asset"
        deleteTooltipText="Delete Asset"
      />
      {assetMigrateModalData &&
        <AssetMigrateModal
          assetName={assetMigrateModalData.name}
          currentCustomerName={assetMigrateModalData.customer_name}
          locale={locale}
          onCreate={handleAssetMigration}
          onClose={() => setAssetMigrateModalData(null)}
          disabled={buttonsDisabled}
          open={!!assetMigrateModalData}
          initialValues={{
            "new_customer": assetMigrateModalData.customer_name
          }}
          isLoading={loading}
        />}
    </RivataModule>
  );
};

export default AssetsTable;
