import React, { useState, useMemo } from "react";
import { connect } from 'react-redux'
import RivataDropdown from "../RivataDropdown";
import { DatesEnum, DateLabels } from "../../enums";
import { DEFAULT_WINDOW } from "../../constants";

const DatesDropdown = ({ isSuperAdmin = false, onSelect, initial = DEFAULT_WINDOW, locale }) => {
  const [selected, setSelected] = useState(initial);

  const dropdownItems = useMemo(() => {
    const data = []
    Object.keys(DatesEnum).map((id) => {
      if (id === "SIX_MONTHS") {
        return null
      }

      return data.push({ label: DateLabels[id], id: DatesEnum[id] })
    })

    return data
  }, [])

  return (
    <RivataDropdown
      items={dropdownItems}
      onSelect={(window) => {
        setSelected(window);
        onSelect(window);
      }}
      selected={selected}
      locale={locale}
    />
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.auth.isSuperAdmin
})

export default connect(mapStateToProps)(DatesDropdown);
