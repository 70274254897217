import React from "react";
import RivataModule from "../../components/RivataModule";
import RivataLoader from "../../components/RivataLoader";
import DocumentsUploader from "./DocumentsUploader";
import DocumentsList from "./DocumentsList";
import "./styles.scss";

interface Props {
  width: number;
  locale: Record<string, string>;
  documents: Array<IDocument>;
  isLoading: boolean;
  addDocument: (title: string) => void;
  removeDocument: (title: string) => void;
}

const ManageSupportModule: React.FC<Props> = ({ width, locale, documents, isLoading, addDocument, removeDocument }) => {
  return (
    <RivataModule title="Manage Documents" width={width} locale={locale} filters={null}>
      {isLoading ? (
        <div className="d-flex loader">
          <RivataLoader />
        </div>
      ) : (
        <>
          <DocumentsUploader documents={documents} addDocument={addDocument} />
          <DocumentsList documents={documents} locale={locale} removeDocument={removeDocument} />
        </>
      )}
    </RivataModule>
  );
};

export default ManageSupportModule;
