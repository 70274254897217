import React from "react";
import { connect } from "react-redux";
import SubNav from '../../components/SubNav'
import CustomersDropdown from '../../components/CustomersDropdown'
import { postTresholds, setIsLoading } from "../../redux/tresholds/action";
import ThresholdsModule from "../../modules/Thresholds";
import { setSelectedCustomer } from '../../redux/common/action'

const Thresholds = ({
    locale, 
    isLoading, 
    postTresholds, 
    unitsOfMeasurement,
    setSelectedCustomer,
    common: { customerDefaults, systemDefaults, isDefaultsLoading, customers: { selectedCustomer, data } }
}) => {
    return (
        <>
            <SubNav
                title="Thresholds"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Thresholds" }
                ]}
            >
                {selectedCustomer && data.length > 1 ? (
                    <CustomersDropdown
                        locale={locale}
                        label={locale.Customer || "Customer"}
                        onChange={async (customer) => {
                            setSelectedCustomer(customer)
                        }}
                    />
                ) : null}
            </SubNav>
            <ThresholdsModule 
                width={12} 
                locale={locale} 
                vibrationTreshold={customerDefaults?.smarthub?.vibration_treshold || systemDefaults?.smarthub?.vibration_treshold} 
                temperatureTreshold={customerDefaults?.smarthub?.temperature_treshold || systemDefaults?.smarthub?.temperature_treshold} 
                onSave={postTresholds} 
                isLoading={isLoading || isDefaultsLoading} 
                unitsOfMeasurement={unitsOfMeasurement}
                customerId={selectedCustomer?.id}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    locale: state.whitelabel.locale,
    unitsOfMeasurement: state.auth.preferences.unitsOfMeasurement,
    common: state.common,
    ...state.tresholds,
});

const mapDispatchToProps = (dispatch) => ({
    postTresholds: (thresholds) => dispatch(postTresholds(thresholds)),
    setIsLoading: (loading) => dispatch(setIsLoading(loading)),
    setSelectedCustomer: (customer) => dispatch(setSelectedCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Thresholds);
