import React, { useState } from "react";
import FleetMap from "../../modules/FleetMap";
import WarningsChart from "../../modules/WarningsChart";
import TablesWrapper from "../../components/TablesWrapper";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

// The future is now
const FleetMapType = "FleetMap";
const WarningsChartType = "Warnings";
const AssetTableType = "AssetList";

// TODO: too many pass through props, connect all modules to redux
const DashboardBody = ({
  assets,
  tpmsStatusTractorAssets,
  tpmsStatusTrailerAssets,
  fetchPagedTpmsStatusTractorAssets,
  fetchPagedTpmsStatusTrailerAssets,
  isLoadingMap,
  isLoadingStatus,
  isLoadingAcknowledgements,
  gps,
  daysCount,
  warnings,
  components,
  locale,
  locations,
  hideAutogeneratedAssets
}) => {
  const [selected, setSelected] = useState(null);

  const {
    setFilter,
    setDaysCount,
    setAssetsLimit,
    setTpmsStatusTrailerAssetsSortOptions,
    setAssetsOffset,
    setAssetsSortOptions,
    setTpmsStatusTractorAssetsSortOptions,
    setTpmsStatusTractorAssetsFilter,
    setTpmsStatusTrailerAssetsFilter,
    setTpmsStatusTractorAssetsLimit,
    setTpmsStatusTrailerAssetsLimit,
    setTpmsStatusTractorAssetsOffset,
    setTpmsStatusTrailerAssetsOffset,
    setCustomerGeofences,
    postAcknowledgements,
    setHideAutogeneratedAssets,
  } = useActions();

  const {geofences, auth, healthColors, preferences, customerDefaults} = useTypedSelector(state =>({
    geofences: state.geofences.customerGeofences,
    auth: state.auth,
    healthColors: state.whitelabel.healthColors,
    preferences: state.auth.preferences,
    customerDefaults: state.common.customerDefaults,
  }))

  return components.map(({ component, width }) => {
    switch (component) {
      case WarningsChartType:
        return (
          <WarningsChart
            key={component}
            isLoading={isLoadingStatus}
            width={width}
            healthColors={healthColors}
            whiteLabelUrl={auth.whiteLabelUrl}
            locale={locale}
            preferences={preferences}
            locations={locations}
          />
        );
      case FleetMapType:
        return (
          <FleetMap
            key={component}
            selected={selected}
            isLoading={isLoadingMap}
            width={width}
            locale={locale}
            preferences={preferences}
            locations={locations}
            geofences={geofences}
            clearGeofences={setCustomerGeofences}
          />
        );
      case AssetTableType:
        return (
          <TablesWrapper
            isSuperAdmin={auth.isSuperAdmin}
            key={component}
            assets={assets.data}
            tpmsStatusTractorAssets={tpmsStatusTractorAssets}
            tpmsStatusTrailerAssets={tpmsStatusTrailerAssets}
            customerDefaults={customerDefaults}
            fetchPagedTpmsStatusTractorAssets={
              fetchPagedTpmsStatusTractorAssets
            }
            fetchPagedTpmsStatusTrailerAssets={
              fetchPagedTpmsStatusTrailerAssets
            }
            assetsError={assets.error}
            warnings={warnings}
            gps={gps}
            isLoading={assets.isLoading}
            isLoadingMap={isLoadingMap}
            isLoadingStatus={isLoadingStatus}
            onSelected={(selected) => setSelected(selected)}
            width={width}
            whiteLabelUrl={auth.whiteLabelUrl}
            locale={locale}
            isLoadingAcknowledgements={isLoadingAcknowledgements}
            postAcknowledgements={postAcknowledgements}
            preferences={preferences}
            locations={locations}
            assetsCount={assets.totalCount}
            onFilterChange={setFilter}
            pageLimit={assets.limit}
            onSelectDays={setDaysCount}
            onSelectLimit={setAssetsLimit}
            onPageChange={setAssetsOffset}
            daysCount={daysCount}
            setAssetsSortOptions={setAssetsSortOptions}
            setTpmsStatusTractorAssetsSortOptions={
              setTpmsStatusTractorAssetsSortOptions
            }
            setTpmsStatusTrailerAssetsSortOptions={
              setTpmsStatusTrailerAssetsSortOptions
            }
            setTpmsStatusTractorAssetsFilter={setTpmsStatusTractorAssetsFilter}
            setTpmsStatusTrailerAssetsFilter={setTpmsStatusTrailerAssetsFilter}
            setTpmsStatusTractorAssetsLimit={setTpmsStatusTractorAssetsLimit}
            setTpmsStatusTrailerAssetsLimit={setTpmsStatusTrailerAssetsLimit}
            setTpmsStatusTractorAssetsOffset={setTpmsStatusTractorAssetsOffset}
            setTpmsStatusTrailerAssetsOffset={setTpmsStatusTrailerAssetsOffset}
            setHideAutogeneratedAssets={setHideAutogeneratedAssets}
            hideAutogeneratedAssets={hideAutogeneratedAssets}
          />
        );
      default:
        return null;
    }
  });
};

export default DashboardBody;
