import React, { useState, useEffect } from "react";
import { CustomInput } from "reactstrap";
import RivataModule from "../../components/RivataModule";
import HEREMap from "../../components/RivataMapCluster";
import { LayerTypes } from "../../components/RivataMapCluster/utils";
import RivataLoader from '../../components/RivataLoader'
import LayersDropdown from "../../components/LayersDropdown";
import Legend from "./Legend";
import { useLocations } from "./hooks";
import { useDispatch } from "react-redux";
import { setLocations } from "../../redux/dashboard/action";

const FleetMap = ({
  selected,
  isLoading,
  width,
  locale,
  preferences,
  locations,
  geofences,
  clearGeofences,
}) => {
  const dispatch = useDispatch();
  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL
  );
  const [geofencesVisible, setGeofencesVisible] = useState(false);
  const filteredLocations = useLocations(locations, isLoading);

  useEffect(() => {
    clearGeofences([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RivataModule
      customFullScreenAction={() => {
        // TODO hack to rerender map in full screen
        setTimeout(() => {
          dispatch(setLocations([...locations, {}]))
        }, 100)
      }}
      fullScreenModalModeEnabled
      title="Fleet Map"
      width={width}
      locale={locale}
      paddingMobile={false}
      error={!window.H ? { statusCode: 500, statusText: "Here Maps in unavailable" } : false}
      filters={
        <>
          {geofences?.length ? (
            <div className="d-flex align-items-center mr-3">
              <CustomInput
                type="switch"
                id="geofencesSwitch"
                label={"Show Geofences"}
                checked={geofencesVisible}
                onChange={(e) => setGeofencesVisible(e.target.checked)}
              />
            </div>
          ) : null}
          <LayersDropdown
            left={0}
            top={0}
            zIndex={1}
            selected={selectedLayerOption}
            onSelect={(type) => setSelectedLayerOption(type)}
            locale={locale}
          />
        </>
      }
    >
      {window.H && <Legend locale={locale} />}
      {window.H && <HEREMap
        locations={filteredLocations}
        selected={selected}
        layerType={selectedLayerOption}
        locale={locale}
        preferences={preferences}
        geofences={geofences}
        geofencesVisible={geofencesVisible}
      />}
      {isLoading && (
        <RivataLoader />
      )}
    </RivataModule>
  );
};

export default FleetMap;
