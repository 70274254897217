import polyCircle from "./common/polyCircle";

type GeometryType = "circle" | "rectangle";

class SimpleGeometry {
  map: H.Map | null = null;
  geometryType: GeometryType;
  verticeGroup: H.map.Group | null = null;
  mainGroup: H.map.Group | null = null;
  drawing: H.map.Circle | H.map.Rect | null = null;
  onDrawingStarted: () => void;
  onDrawingFinished: (geofenceWKT: string) => void;

  constructor(map: H.Map, geometryType: GeometryType, onDrawingStarted: () => void, onDrawingFinished: (geofenceWKT: string) => void) {
    this.map = map;
    this.geometryType = geometryType;
    this.onDrawingStarted = onDrawingStarted;
    this.onDrawingFinished = onDrawingFinished;
    this.init();
  }

  save(geofenceWKT: string): void {
    this.onDrawingFinished(geofenceWKT);
  }

  init() {
    this.verticeGroup = new H.map.Group({
      visibility: true,
    });

    this.mainGroup = new H.map.Group({
      objects: [this.verticeGroup],
    });

    if (this.map) this.map.addObject(this.mainGroup);

    this.mainGroup.setZIndex(10000);

    this.addMapListener();
  }

  removeAll() {
    if (this.verticeGroup) {
      this.verticeGroup.removeAll();
      this.verticeGroup.dispose();
    }

    if (this.mainGroup) {
      this.mainGroup.removeAll();
      this.mainGroup.dispose();
    }

    if (this.map) {
      this.removeMapListener();
    }
  }

  removeDrawing() {
    this.drawing?.dispose();
  }

  reset() {
    this.removeAll();
    this.init();
  }

  dispose(removeDrawing?: boolean) {
    this.removeAll();
    this.map = null;
    this.verticeGroup = null;
    this.mainGroup = null;
    if (removeDrawing) this.removeDrawing();
  }

  addMapListener() {
    if (this.map) this.map.addEventListener("tap", this.onMapClicked);
  }

  removeMapListener() {
    if (this.map) this.map.removeEventListener("tap", this.onMapClicked);
  }

  geometryToWKT(geometry: H.map.Circle | H.map.Rect) {
    return geometry.getGeometry().toString();
  }

  draw() {
    if (!this.map || !this.verticeGroup) return;

    const vertices = this.verticeGroup.getObjects();
    const verticeOne = vertices[0].getData();
    const verticeTwo = vertices[1].getData();

    let firstVertice = verticeOne;
    let secondVertice = verticeTwo;

    if (this.geometryType === "circle") {
      const firstPoint = new H.geo.Point(firstVertice.lat, firstVertice.lng);
      const secondPoint = new H.geo.Point(secondVertice.lat, secondVertice.lng);

      this.drawing = new H.map.Circle({ lat: firstVertice.lat, lng: firstVertice.lng }, firstPoint.distance(secondPoint));
    }

    if (this.geometryType === "rectangle") {
      if (firstVertice.lng >= secondVertice.lng) {
        firstVertice = verticeTwo;
        secondVertice = verticeOne;
      }

      this.drawing = new H.map.Rect(new H.geo.Rect(firstVertice.lat, firstVertice.lng, secondVertice.lat, secondVertice.lng));
    }

    if (this.drawing) {
      this.map.addObject(this.drawing);
      this.save(this.geometryToWKT(this.drawing));
    }

    this.reset();
  }

  onMapClicked = (event: any) => {
    event.stopPropagation();

    if (!this.map) return;

    const pointer = event.currentPointer;

    const geoPoint = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);

    if (!this.verticeGroup) return;

    const index = this.verticeGroup.getObjects().length;

    this.addVertice(geoPoint.lat, geoPoint.lng, index);

    if (index === 0) this.onDrawingStarted();

    if (index === 1) this.draw();
  };

  addVertice = (lat: number, lng: number, index: number) => {
    const options: H.map.Icon.Options = { anchor: { x: 10, y: 10 }, crossOrigin: false };

    const vertice = new H.map.Marker(
      { lat, lng },
      {
        icon: new H.map.Icon(polyCircle, options),
      }
    );

    vertice.setData({ verticeIndex: index, lat, lng });

    if (this.verticeGroup) this.verticeGroup.addObject(vertice);
  };
}

export default SimpleGeometry;
