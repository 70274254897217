import api from "../../services/api";
import { getToken } from "../../utils";
import {
  GET_ADMIN_SENSOR_LOGS,
  SET_LOGS_LOADING,
  SET_GATEWAY_LOGS,
  SET_LOGS_FETCH_ERROR,
  RESET_LOGS_TABLE
} from "../actionTypes";

const setLogsLoading = (payload) => ({
  type: SET_LOGS_LOADING,
  payload,
});

const getAdminSensorLogs = (payload) => {
  return {
    type: GET_ADMIN_SENSOR_LOGS,
    payload: {
      logs: payload["data"],
      length: payload["length"],
    },
  };
};

const setAdminGatewayLogs = (payload) => {
  return {
    type: SET_GATEWAY_LOGS,
    payload,
  };
};

const setLogsFetchError = (payload) => {
  return {
    type: SET_LOGS_FETCH_ERROR,
    payload,
  };
};
export const resetTable=()=>({type: RESET_LOGS_TABLE})

export const fetchAdminLogs = (
  hardwareId,
  type,
  epoch,
  from,
  to,
  epoch_to = null
) => {
  return function (dispatch, getState) {
    dispatch(setLogsLoading(true))
    dispatch(setLogsFetchError(null))
    const token = getToken(getState)
    return api
      .getLogsByKey(hardwareId, type, epoch, from, to, token, epoch_to)
      .then((res) => {
        if (type === "sensors") {
          dispatch(getAdminSensorLogs(res));
        }
        if (type === "gateway_translator" || type === "gateway_rivata") {
          dispatch(setAdminGatewayLogs(res["data"]));
        }
      })
      .catch((e) => {
        dispatch(setLogsFetchError(`${e.name}. Status code: ${e.statusCode}`));
      })
      .finally(() => {
        dispatch(setLogsLoading(false));
      });
  };
};
