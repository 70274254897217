import {
  LOADING_ASSETS,
  LOADING_TPMS_STATUS_TRACTOR_ASSETS,
  LOADING_TPMS_STATUS_TRAILER_ASSETS,
  LOADING_GPS,
  LOADING_WARNINGS,
  SET_ASSETS,
  SET_TPMS_STATUS_TRACTOR_ASSETS,
  SET_TPMS_STATUS_TRAILER_ASSETS,
  SET_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT,
  SET_TPMS_STATUS_TRAILER_TOTAL_COUNT,
  SET_TPMS_STATUS_TRACTOR_COLUMNS,
  SET_TPMS_STATUS_TRAILER_COLUMNS,
  SET_GPS,
  SET_WARNINGS,
  SET_GPS_ERROR,
  SET_ASSETS_ERROR,
  SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR,
  SET_TPMS_STATUS_TRAILER_ASSETS_ERROR,
  SET_WARNINGS_ERROR,
  LOADING_MAP_DATA,
  LOADING_STATUS,
  SET_FILTER_MATCHES,
  SET_ACKNOWLEDGEMENTS,
  LOADING_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS_ERROR,
  SET_LOCATIONS,
  SET_ASSETS_COUNT,
  SET_FILTER,
  SET_TPMS_STATUS_TRACTOR_FILTER,
  SET_TPMS_STATUS_TRAILER_FILTER,
  SET_DAYS_COUNT,
  SET_ASSETS_LIMIT,
  SET_TPMS_STATUS_TRACTOR_LIMIT,
  SET_TPMS_STATUS_TRAILER_LIMIT,
  SET_ASSETS_OFFSET,
  SET_TPMS_STATUS_TRACTOR_OFFSET,
  SET_TPMS_STATUS_TRAILER_OFFSET,
  SET_HIDE_AUTOGENERATED_FILTER
} from "../actionTypes";
import { DEFAULT_WINDOW } from "../../constants";
import api from "../../services/api";
import {
  handleTimestampsRoot,
  getToken,
  convertDataEpochToDate,
  getErrorObj
} from "../../utils";
import { store } from "../store";
import { fetchCustomerGeofences } from "../geofences/action";

export const loadingAssets = (isLoading) => ({
  type: LOADING_ASSETS,
  payload: isLoading,
});

export const setAssets = (assets) => ({
  type: SET_ASSETS,
  payload: assets,
});

export const setAssetsSortOptions = (options) => ({
  type: SET_ASSETS_SORT_OPTIONS,
  payload: options,
});

export const setTpmsStatusTractorAssetsSortOptions = (options) => ({
  type: SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS,
  payload: options,
});

export const setTpmsStatusTrailerAssetsSortOptions = (options) => ({
  type: SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS,
  payload: options,
});

export const setFilterMatches = (assetIds) => ({
  type: SET_FILTER_MATCHES,
  payload: assetIds,
});

export const setAssetsError = (error) => ({
  type: SET_ASSETS_ERROR,
  payload: error,
});

export const loadingTpmsStatusTractorAssets = (isLoading) => ({
  type: LOADING_TPMS_STATUS_TRACTOR_ASSETS,
  payload: isLoading,
});

export const loadingTpmsStatusTrailerAssets = (isLoading) => ({
  type: LOADING_TPMS_STATUS_TRAILER_ASSETS,
  payload: isLoading,
});

export const setTpmsStatusTractorAssets = (assets) => ({
  type: SET_TPMS_STATUS_TRACTOR_ASSETS,
  payload: assets,
});

export const setTpmsStatusTrailerAssets = (assets) => ({
  type: SET_TPMS_STATUS_TRAILER_ASSETS,
  payload: assets,
});

export const setTpmsStatusTractorTotalCount = (count) => ({
  type: SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT,
  payload: count,
});

export const setTpmsStatusTrailerTotalCount = (count) => ({
  type: SET_TPMS_STATUS_TRAILER_TOTAL_COUNT,
  payload: count,
});

export const setTpmsStatusTractorColumns = (columns) => ({
  type: SET_TPMS_STATUS_TRACTOR_COLUMNS,
  payload: columns,
});

export const setTpmsStatusTrailerColumns = (columns) => ({
  type: SET_TPMS_STATUS_TRAILER_COLUMNS,
  payload: columns,
});

export const setTpmsStatusTractorAssetsError = (error) => ({
  type: SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR,
  payload: error,
});

export const setTpmsStatusTrailerAssetsError = (error) => ({
  type: SET_TPMS_STATUS_TRAILER_ASSETS_ERROR,
  payload: error,
});

export const loadingWarnings = (id, isLoading) => ({
  type: LOADING_WARNINGS,
  payload: { id, isLoading },
});

export const setWarnings = (id, data) => ({
  type: SET_WARNINGS,
  payload: { id, data },
});

export const setWarningsError = (id, error) => ({
  type: SET_WARNINGS_ERROR,
  payload: { id, error },
});

export const loadingMapData = (isLoading) => ({
  type: LOADING_MAP_DATA,
  payload: isLoading,
});

export const loadingStatus = (isLoading) => ({
  type: LOADING_STATUS,
  payload: isLoading,
});

export const loadingGps = (id, isLoading) => ({
  type: LOADING_GPS,
  payload: { id, isLoading },
});

export const setGps = (id, data) => ({
  type: SET_GPS,
  payload: { id, data },
});

export const setGpsError = (id, error) => ({
  type: SET_GPS_ERROR,
  payload: { id, error },
});

export const loadingAcknowledgements = (isPosting) => ({
  type: LOADING_ACKNOWLEDGEMENTS,
  payload: isPosting,
});

export const setAcknowledgementsError = (error) => ({
  type: SET_ACKNOWLEDGEMENTS_ERROR,
  payload: error,
});

export const setAcknowledgements = (acknowledgements) => ({
  type: SET_ACKNOWLEDGEMENTS,
  payload: acknowledgements,
});

export const setLocations = (locations) => ({
  type: SET_LOCATIONS,
  payload: locations
})

export const setAssetsCount = (assetsCount) => ({
  type: SET_ASSETS_COUNT,
  payload: assetsCount
})

export const setFilter = (filter) => ({
  type: SET_FILTER,
  payload: filter
})

export const setTpmsStatusTractorAssetsFilter = (filter) => ({
  type: SET_TPMS_STATUS_TRACTOR_FILTER,
  payload: filter
})

export const setTpmsStatusTrailerAssetsFilter = (filter) => ({
  type: SET_TPMS_STATUS_TRAILER_FILTER,
  payload: filter
})

export const setDaysCount = (days) => ({
  type: SET_DAYS_COUNT,
  payload: days
})

export const setAssetsLimit = (limit) => ({
  type: SET_ASSETS_LIMIT,
  payload: limit
})

export const setTpmsStatusTractorAssetsLimit = (limit) => ({
  type: SET_TPMS_STATUS_TRACTOR_LIMIT,
  payload: limit
})

export const setTpmsStatusTrailerAssetsLimit = (limit) => ({
  type: SET_TPMS_STATUS_TRAILER_LIMIT,
  payload: limit
})

export const setAssetsOffset = (offset) => ({
  type: SET_ASSETS_OFFSET,
  payload: offset
})

export const setTpmsStatusTractorAssetsOffset = (offset) => ({
  type: SET_TPMS_STATUS_TRACTOR_OFFSET,
  payload: offset
})

export const setTpmsStatusTrailerAssetsOffset = (offset) => ({
  type: SET_TPMS_STATUS_TRAILER_OFFSET,
  payload: offset
})

export function fetchAssetsPage() {
  return function (dispatch) {
    dispatch(fetchPagedAssets())
    dispatch(fetchLocations())
    dispatch(fetchCustomerGeofences())
  };
}

export function fetchPagedAssets() {
  return function (dispatch, getState) {
    const { 
      filter, 
      assets: { offset, limit, sortOptions: { column, direction } } 
    } = getState().dash;
    const { customers: { selectedCustomer }, hideAutogeneratedAssets } = getState().common
    dispatch(loadingAssets(true));
    return api.getAssets(getToken(getState), selectedCustomer?.id, limit, offset, null, filter.filterType, filter.filterData, column, direction, null, false, hideAutogeneratedAssets)
      .then(assets => {

        const pagedAssets = convertDataEpochToDate(assets.paged_data, "last_gps_update_epoch", "datetime");
        dispatch(setAssets(pagedAssets));
        dispatch(setAssetsCount(assets.total_count));
        dispatch(setAssetsError(getErrorObj()))
      }).catch((error) => dispatch(setAssetsError(getErrorObj(error))))
      .finally(() => dispatch(loadingAssets(false)))
  }
}

const tpmsStatusMessage = "TPMS status can not be shown when All customers are selected. Please select a customer in dropdown."
export function fetchPagedTpmsStatusTractorAssets() {
  return function (dispatch, getState) {
    const { selectedCustomer } = getState().common.customers
    const { isSuperAdmin } = getState().auth
    const type = "tractor"
    const { limit, offset, filter: { filterType, filterData }, sortOptions: { column, direction } } = getState().dash.tpmsStatusTractorAssets

    if (isSuperAdmin) {
      if (!selectedCustomer || !selectedCustomer.id) {
        dispatch(setTpmsStatusTractorAssets([]))
        return dispatch(setTpmsStatusTractorAssetsError(getErrorObj({ statusCode: 200 }, tpmsStatusMessage)))
      }
    }

    dispatch(loadingTpmsStatusTractorAssets(true));
    return api.getStatusAssets(getToken(getState), selectedCustomer?.id, limit, offset, type, filterType, filterData, column, direction)
      .then(res => {
        dispatch(setTpmsStatusTractorAssets(res.paged_data.data));
        dispatch(setTpmsStatusTractorTotalCount(res.total_count));
        dispatch(setTpmsStatusTractorColumns(res.paged_data.columns));
        dispatch(setTpmsStatusTractorAssetsError(getErrorObj()))
      }).catch((error) => dispatch(setTpmsStatusTractorAssetsError(getErrorObj(error))))
      .finally(() => dispatch(loadingTpmsStatusTractorAssets(false)))
  }
}

export function fetchPagedTpmsStatusTrailerAssets() {
  return function (dispatch, getState) {
    const { selectedCustomer } = getState().common.customers
    const { isSuperAdmin } = getState().auth
    const type = "trailer"
    const { limit, offset, filter: { filterType, filterData }, sortOptions: { column, direction } } = getState().dash.tpmsStatusTrailerAssets

    if (isSuperAdmin) {
      if (!selectedCustomer || !selectedCustomer.id) {
        dispatch(setTpmsStatusTrailerAssets([]))
        return dispatch(setTpmsStatusTrailerAssetsError(getErrorObj({ statusCode: 200 }, tpmsStatusMessage)))
      }
    }

    dispatch(loadingTpmsStatusTrailerAssets(true));
    return api.getStatusAssets(getToken(getState), selectedCustomer?.id, limit, offset, type, filterType, filterData, column, direction)
      .then(data => {
        dispatch(setTpmsStatusTrailerAssets(data.paged_data.data));
        dispatch(setTpmsStatusTrailerTotalCount(data.total_count));
        dispatch(setTpmsStatusTrailerColumns(data.paged_data.columns));
        dispatch(setTpmsStatusTrailerAssetsError(getErrorObj()))
      }).catch((error) => dispatch(setTpmsStatusTrailerAssetsError(getErrorObj(error))))
      .finally(() => dispatch(loadingTpmsStatusTrailerAssets(false)))
  }
}

const composeLocationsWarnings = (data) => {
  return data.map((item) => {
    let hasCriticalWarning = false
    let hasWarning = false

    if (item.warning_type) {
      item.warning_subtypes.split(",").forEach((wst) => {
        // ignore no_status warnings
        if (wst.startsWith("no_status")){
          return
        }

        let subType = wst.split("_")

        if (subType[0] === "critical") {
          hasCriticalWarning = true
        } else if (subType[0] !== "critical") {
          hasWarning = true
        }
      })
    }

    item.hasCriticalWarning = hasCriticalWarning
    item.hasWarning = hasWarning

    return item
  })
}

export function fetchLocations(customerId) {
  return async function (dispatch, getState) {
    dispatch(loadingMapData(true));
    const { filter, daysCount } = getState().dash;
    const { customers: { selectedCustomer }, hideAutogeneratedAssets } = getState().common
    const id = customerId ? customerId : selectedCustomer?.id
    return api
      .getLatestGpsBulk(getToken(getState), id, daysCount, filter.filterType, filter.filterData, hideAutogeneratedAssets)
      .then((locations) => {
        locations = convertDataEpochToDate(locations, "epoch", "datetime");
        const locationsWithWarnings = composeLocationsWarnings(locations)
        dispatch(setLocations(locationsWithWarnings));
      })
      .catch((error) => {

      })
      .finally(() => dispatch(loadingMapData(false)));
  };
}

export function fetchAllGpsChunky() {
  return async function (dispatch, getState) {
    const assets = getState().dash.assets;
    dispatch(loadingMapData(true));
    let i = 0;
    const size = assets.length;
    while (i < size) {
      let last = i + 10 < size ? i + 10 : size;
      const ids = []
      for (; i < last; i++) {
        ids.push(assets[i].id)
      }
      try {
        await Promise.allSettled(ids.map(id => dispatch(fetchGps(id))));
      } catch (e) {

      }
    }
    dispatch(loadingMapData(false));
  };
}

export function postAcknowledgements(acknowledgements) {
  return function (dispatch, getState) {
    dispatch(loadingAcknowledgements(true));
    dispatch(setAcknowledgementsError(null));
    return api
      .postAcknowledgements(acknowledgements, getToken(getState))
      .then((acknowledgements) => {
        acknowledgements = handleTimestampsRoot(getState().auth.preferences, acknowledgements)
        dispatch(setAcknowledgements(acknowledgements));
      })
      .catch((error) => {
        dispatch(setAcknowledgementsError(error));
      })
      .finally(() => dispatch(loadingAcknowledgements(false)));
  };
}

export function fetchAllAcknowledgements() {
  return async function (dispatch, getState) {
    const assetIds = getState().dash.assets.map(({ id }) => id);
    dispatch(loadingAcknowledgements(true));
    dispatch(setAcknowledgementsError(null));
    return api
      .getAcknowledgements(assetIds, DEFAULT_WINDOW, getToken(getState))
      .then((acknowledgements) => {
        acknowledgements = handleTimestampsRoot(getState().auth.preferences, acknowledgements);
        dispatch(setAcknowledgements(acknowledgements));
      })
      .catch((error) => setAcknowledgementsError(error))
      .finally(() => dispatch(loadingAcknowledgements(false)));
  };
}

export function fetchGps(id) {
  console.log("fetchDashboard")
  return function (dispatch, getState) {
    dispatch(loadingGps(id, true));
    return api
      .getLatestGps(id, DEFAULT_WINDOW, getToken(getState))
      .then((gps) => {
        dispatch(setGpsError(id, null));
        dispatch(setGps(id, gps[0]));
      })
      .catch((error) => dispatch(setGpsError(id, error)))
      .finally(() => dispatch(loadingGps(id, false)));
  };
}

export function fetchWarnings(id) {
  return function (dispatch, getState) {
    dispatch(loadingWarnings(id, true));
    return api
      .getRecentWarnings(id, DEFAULT_WINDOW, getToken(getState))
      .then((data) => {
        data = handleTimestampsRoot(getState().auth.preferences, data);
        dispatch(setWarningsError(id, null));
        dispatch(
          setWarnings(id, data)
        );
      })
      .catch((error) => dispatch(setWarningsError(id, error)))
      .finally(() => dispatch(loadingWarnings(id, false)));
  };
}

store.subscribe(() => {
  const lastAction = store.getState().lastAction;
  if (lastAction.type === SET_FILTER || 
      lastAction.type === SET_HIDE_AUTOGENERATED_FILTER || 
      lastAction.type === SET_DAYS_COUNT
  ) {
    store.dispatch(setAssetsOffset(0)); // reset pageOffset if filter applied (watch below req is send after set new offset)
    store.dispatch(fetchLocations())
  }
  if (lastAction.type === SET_ASSETS_LIMIT) {
    store.dispatch(setAssetsOffset(0)); // reset offset if select new limit
  }
  if (lastAction.type === SET_ASSETS_OFFSET || lastAction.type === SET_ASSETS_SORT_OPTIONS) {
    store.dispatch(fetchPagedAssets());
  }
  if (lastAction.type === SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS
    || lastAction.type === SET_TPMS_STATUS_TRACTOR_OFFSET) {
    store.dispatch(fetchPagedTpmsStatusTractorAssets())
  }
  if (lastAction.type === SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS
    || lastAction.type === SET_TPMS_STATUS_TRAILER_OFFSET) {
    store.dispatch(fetchPagedTpmsStatusTrailerAssets())
  }
})