import { store } from '../store'
import {
    LOADING_AXLE_LOAD_ASSETS,
    SET_AXLE_LOAD_ASSETS,
    SET_AXLE_LOAD_ASSETS_ERROR,
    SET_SELECTED_CALIBRATION_ASSET,
    LOADING_AXLE_LOAD_CALIBRATION_DATA,
    SET_AXLE_LOAD_CALIBRATION_DATA,
    SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT,
    SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET,
    SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT,
    SET_AXLE_LOAD_CALIBRATION_DATA_ERROR
} from '../actionTypes'
import api from '../../services/api'
import { getToken, getErrorObj } from '../../utils'

const action = (type, payload) =>({ type, payload })

export const setLoadingAxleLoadAssets = (isLoading) => ({
    type: LOADING_AXLE_LOAD_ASSETS,
    payload: isLoading
})

export const setAxleLoadAssets = (data) => ({
    type: SET_AXLE_LOAD_ASSETS,
    payload: data
})

export const setAxleLoadAssetsError = (err) => ({
    type: SET_AXLE_LOAD_ASSETS_ERROR,
    payload: err
})

export const setCalibrationDataOffset = (offset) => ({
    type: SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET,
    payload: offset
})

export const setCalibrationDataLimit = (limit) => ({
    type: SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT,
    payload: limit
})

export const setSelectedCalibrationAsset = (asset) => ({
    type: SET_SELECTED_CALIBRATION_ASSET,
    payload: asset
})

export const fetchAxleLoadCalibrationPage = () => {
    return (dispatch) => {
        dispatch(fetchAxleLoadAssets())
    }
}

export const fetchAxleLoadAssets = () => {
    return (dispatch, getState) => {
        dispatch(setLoadingAxleLoadAssets(true))
        const { selectedCustomer } = getState().common.customers
        const id = selectedCustomer?.id

        return api
            .getAssets(getToken(getState), id, 10000, 0, 7, "sensor_info", "manifold")
            .then((res) => {
                dispatch(setAxleLoadAssets(res.paged_data))
                dispatch(setAxleLoadAssetsError(getErrorObj()))
            })
            .catch(err => dispatch(setAxleLoadAssetsError(getErrorObj(err))) ) 
            .finally(() => dispatch(setLoadingAxleLoadAssets(false)))
    }
}

export const postAxleLoadCalibrationData = (data) => {
    return (dispatch, getState) => {
        dispatch(setLoadingAxleLoadAssets(true))

        return api
            .postAxleLoadCalibrationData(getToken(getState), data)
            .then(() => {
                dispatch(fetchAxleLoadCalibrationData(data.asset_id))
                return getErrorObj({ statusCode: 201 }, "Calibration Data has been added")
            })
            .catch(err => {
                const message = err.message.split(":")[1]
                dispatch(setAxleLoadAssetsError(getErrorObj(err, message)))
                return getErrorObj(err, message)
            }) 
            .finally(() => dispatch(setLoadingAxleLoadAssets(false)))
    }
}

export const fetchAxleLoadCalibrationData = (assetId) => {
    return (dispatch, getState) => {
        dispatch(action(LOADING_AXLE_LOAD_CALIBRATION_DATA, true))
        const { offset, limit } = getState().axleLoadCalibration.calibrationData
        return api
            .getAxleLoadCalibrationData(getToken(getState), assetId, offset, limit)
            .then((res) => {
                dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA, res.paged_data))
                dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT, res.total_count))
                dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_ERROR, getErrorObj()))
            })
            .catch(err => dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_ERROR, getErrorObj(err))) ) 
            .finally(() => dispatch(action(LOADING_AXLE_LOAD_CALIBRATION_DATA, false)))
    }
}

export const putAxleLoadCalibrationData = (data) => {
    return (dispatch, getState) => {
        dispatch(action(LOADING_AXLE_LOAD_CALIBRATION_DATA, true))

        return api
            .putAxleLoadCalibrationData(getToken(getState), data)
            .then(() => {
                dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_ERROR, getErrorObj()))
                dispatch(fetchAxleLoadCalibrationData(data.asset_id))
                return getErrorObj({ statusCode: 200 }, "Updated successfully!")
            })
            .catch(err => {
                dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_ERROR, getErrorObj(err)))
                return getErrorObj(err, err.message)
            }) 
            .finally(() => dispatch(action(LOADING_AXLE_LOAD_CALIBRATION_DATA, false)))
    }
}

export const deleteAxleLoadCalibrationData = (assetId, cDataId) => {
    return (dispatch, getState) => {
        dispatch(action(LOADING_AXLE_LOAD_CALIBRATION_DATA, true))

        return api
            .deleteAxleLoadCalibrationData(getToken(getState), assetId, cDataId)
            .then(() => {
                dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_ERROR, getErrorObj()))
                dispatch(fetchAxleLoadCalibrationData(assetId))
                return getErrorObj({ statusCode: 204 }, "Deleted successfully!")
            })
            .catch(err => {
                dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_ERROR, getErrorObj(err)))
                return getErrorObj(err, err.message)
            }) 
            .finally(() => dispatch(action(LOADING_AXLE_LOAD_CALIBRATION_DATA, false)))
    }
}

store.subscribe(() => {
    const lastAction = store.getState().lastAction

    if (lastAction.type === SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET) {
        const { selectedAsset } = store.getState().axleLoadCalibration

        store.dispatch(fetchAxleLoadCalibrationData(selectedAsset.id))
    }

    // reset offset after select limit
    if (lastAction.type === SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT) {
        store.dispatch(action(SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET, 0))
    }
})