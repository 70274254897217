import { useMemo } from 'react'
import { WarningSearchColumnIds, WheelOrder } from '../../enums'
import { getClearLabel } from '../../utils'

export const useSearchFields = (locale) => {
    return useMemo(() => [
        {
            label: locale["Sensor Type"],
            id: WarningSearchColumnIds.SENSOR_TYPE,
        },
        {
            label: locale["Position"],
            id: WarningSearchColumnIds.LOCATION,
        },
        {
            label: locale["Warning Type"],
            id: WarningSearchColumnIds.WARNING_TYPE,
        }
    ],[locale]);
};

export const useVehicleSensorPositions = (sensorInfo) => {
    let filteredData = []
    sensorInfo.forEach((item) => {
        if (filteredData.findIndex(el => el.position === item.position ? true : false) === -1) filteredData.push(item)
    })

    filteredData.sort((d1, d2) => WheelOrder[d1.position] - WheelOrder[d2.position])

    return useMemo(() => {
        return filteredData.reduce((prev, current) => {
            return [ ...prev, { value: current.position, label: getClearLabel(current.position) } ]
        }, [ { value: "", label: "All" } ])
    }, [filteredData])
}
