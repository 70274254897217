import React, { useEffect } from "react";
import SubNav from "../../components/SubNav"
import CustomersDropdown from "../../components/CustomersDropdown";
import TpmsConfigurationModule from "../../modules/TpmsConfigurationModule";
import { useActions } from "../../hooks/useActions";
import {useTypedSelector} from "../../hooks/useTypedSelector";


const TpmsProfiles = () => {
    const { fetchTpmsProfilesPage, fetchTpmsProfiles, setSelectedCustomer} = useActions()
    const{isLoading, preferences, locale, common} = useTypedSelector((state)=>({
      isLoading:   state.tpmsProfiles.isLoading,
      preferences: state.auth.preferences,
      locale: state.whitelabel.locale,
      common: state.common,
    }))

    const { systemDefaults, customers, customerDefaults: { subscriptions }, isDefaultsLoading } = common
    useEffect(() => {
        if (customers.selectedCustomer?.id) {
            fetchTpmsProfilesPage()
        }
    }, [customers.selectedCustomer, fetchTpmsProfilesPage])

    return (
        <>
            <SubNav
                title="TPMS Profiles"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "TPMS Profiles" }
                ]}
            >
                {customers.data.length > 1 && customers.selectedCustomer ? (
                    <CustomersDropdown
                        label={"Customers"}
                        locale={locale}
                        onChange={(customer) => {
                            fetchTpmsProfiles(true, null, customer.id)
                            setSelectedCustomer(customer)
                        }}
                    />
                ) : null}
            </SubNav>
            <TpmsConfigurationModule
                isLoading={isLoading || customers.loading || isDefaultsLoading}
                warningSettingsDefaults={systemDefaults.tpms}
                preferences={preferences}
                locale={locale}
                subscriptions={subscriptions}
            />
        </>
    );
};



export default TpmsProfiles
