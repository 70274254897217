import { SET_IS_LOADING_FILTERS, SET_IS_LOADING_TAG_GPS_DATA, SET_IS_LOADING_TAG_GPS_DATA_DETAILED, SET_TAG_GPS_DATA, SET_TAG_GPS_DATA_DETAILED, SET_TAG_GPS_DATA_DETAILED_OFFSET, SET_TAG_GPS_DATA_DETAILED_TOTAL_COUNT, SET_TAG_GPS_FILTERS } from "../actionTypes";
import api from "../../services/api";
import { convertDataEpochToDate, getToken } from "../../utils";
import { setSelectedCustomer } from "../common/action";

const setTagGpsData = (data) => ({
    type: SET_TAG_GPS_DATA,
    payload: data,
});

const setIsLoadingTagGpsData = (isLoading) => ({
    type: SET_IS_LOADING_TAG_GPS_DATA,
    payload: isLoading
});

const setTagGpsDataDetailed = (data) => ({
    type: SET_TAG_GPS_DATA_DETAILED,
    payload: data
});

const setIsLoadingTagGpsDataDetailed = (isLoading) => ({
    type: SET_IS_LOADING_TAG_GPS_DATA_DETAILED,
    payload: isLoading
});

const setTagGpsDataDetailedTotalCount = (totalCount) => ({
    type: SET_TAG_GPS_DATA_DETAILED_TOTAL_COUNT,
    payload: totalCount
});

const setTagGpsFilters = (filters) => ({
    type: SET_TAG_GPS_FILTERS,
    payload: filters
});

const setIsLoadingFilters = (isLoading) => ({
    type: SET_IS_LOADING_FILTERS,
    payload: isLoading
});

export const setTagGpsDataDetailedOffset = (offset) => ({
    type: SET_TAG_GPS_DATA_DETAILED_OFFSET,
    payload: offset
});

export function fetchTagDataPage(tagMac, startDate, endDate) {
    return async function (dispatch, getState) {
        if (tagMac) {
            const tagDetails = await api.getTagDetails(getToken(getState), tagMac);
            if (tagDetails) {
                const { data } = getState().common.customers;
                const customer = data.find(c => c.id === tagDetails.customer_id);
                dispatch(setSelectedCustomer(customer));
            }
        }
        dispatch(fetchTagGpsFilters());
        dispatch(fetchTagGpsData(tagMac, null, null, startDate, endDate));
        dispatch(fetchTagGpsDataDetailed(tagMac, null, null, startDate, endDate));
    }
}

export function fetchTagGpsFilters() {
    return function (dispatch, getState) {
        dispatch(setIsLoadingFilters(true));
        const id = getState().common.customers.selectedCustomer?.id;
        return api.getTagGpsFilters(getToken(getState), id).then(filters => {
            dispatch(setTagGpsFilters(filters));
        }).catch((err) => { })
            .finally(() => {
                dispatch(setIsLoadingFilters(false));
            })
    }
}

export function fetchTagGpsData(tagMac, locationId, assetId, startDate, endDate) {
    return function (dispatch, getState) {
        dispatch(setIsLoadingTagGpsData(true));
        const state = getState();
        const id = state.common.customers.selectedCustomer?.id;
        return api.getTagGpsDetails(getToken(getState), tagMac, locationId, assetId, id, startDate, endDate).then(data => {
            data = convertDataEpochToDate(data, 'epoch');
            dispatch(setTagGpsData(data));
        }).catch((err) => {

        }).finally(() => {
            dispatch(setIsLoadingTagGpsData(false));
        })
    }
}

export function fetchTagGpsDataDetailed(tagMac, locationId, assetId, startDate, endDate) {
    return function (dispatch, getState) {
        dispatch(setIsLoadingTagGpsDataDetailed(true));
        const state = getState();
        const id = state.common.customers.selectedCustomer?.id;
        const { limit, offset } = state.tagDetails;
        return api.getTagGpsDetailsDetailed(getToken(getState), tagMac, locationId, assetId, id, limit, offset, startDate, endDate).then(data => {
            data.paged_data = convertDataEpochToDate(data.paged_data, 'epoch');
            dispatch(setTagGpsDataDetailed(data.paged_data));
            dispatch(setTagGpsDataDetailedTotalCount(data.total_count));
        }).catch((err) => {

        }).finally(() => {
            dispatch(setIsLoadingTagGpsDataDetailed(false));
        })
    }
}
