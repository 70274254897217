import { useMemo, useState, useEffect } from "react";
import { TpmsColumnsIds } from "../../enums";

export const useColumns = (locale, selected) => {
    return useMemo(
      () => [
        {
          label: locale["All"] || "All",
          id: TpmsColumnsIds.ALL,
          control: "checkbox",
          checked: selected
        },
        {
          label: locale["Asset Name"] || "Asset Name",
          id: TpmsColumnsIds.ASSET_NAME,
          control: "sort",
        },
        {
          label: locale["VIN"] || "VIN",
          id: TpmsColumnsIds.VIN,
          control: "sort",
        },
        {
          label: locale["Asset Type"] || "Asset Type",
          id: TpmsColumnsIds.ASSET_TYPE,
          control: "sort",
        },
        {
          label: locale["TPMS Profile"] || "TPMS Profile",
          id: TpmsColumnsIds.TPMS_PROFILE,
          control: "sort",
        },
      ],
      [locale, selected]
    );
  };

export const useTableRows = (
    assets, 
    selectAll, 
    selectedRows, 
    onSelectProfile, 
    selectedProfile,
) => {
    const [tableRows, setTableRows] = useState([]);
    
    // assets.forEach((el)=> {
    //   if(el.type === "Tractor"){
    //     el.type = "Tractor"
    //     return el
    //   }
    //   return el
    // })

    useEffect(() => {
      const _tableBody = assets.map((asset) => {
        return {
          columns: [
            {
              columnId: TpmsColumnsIds.ALL,
              assetId: asset.id,
              control: "checkbox",
              checked: selectAll ? true : selectedRows.indexOf(asset.id) > -1 ? true : false,
            },
            {
              type: "text",
              label: asset.assetName,
              columnId: TpmsColumnsIds.ASSET_NAME,
              data: asset.assetName,
              assetId: asset.id,
            },
            {
              type: "text",
              label: asset.vin,
              columnId: TpmsColumnsIds.VIN,
              data: asset.vin,
              assetId: asset.id,
            },
            {
              type: "text",
              label: asset.type,
              columnId: TpmsColumnsIds.ASSET_TYPE,
              assetId: asset.id,
            },
            {
              type: "text",
              label: asset.tpmsProfile,
              columnId: TpmsColumnsIds.TPMS_PROFILE,
              assetId: asset.id,
            },
          ],
          id: asset.id,
          selectedRow: selectedRows.includes(asset.id) ? true : false,
        };
      });
      setTableRows(_tableBody);
    }, [
      assets, 
      selectAll, 
      selectedRows, 
      onSelectProfile, 
      selectedProfile,
    ]);
    return tableRows;
};

export const useAssetNames = (selectedRows = [], assets) => {
  return useMemo(() => {
    let assetsString = selectedRows.slice(0, 15).map((id) => {
        const asset = assets.find((el) => {
            if (el.id === id) {
                return true
            }
            return false
        })

        return asset.assetName
    }).join(", ")

    if (selectedRows.length > 15) assetsString += ", and more...";

    return assetsString;
  }, [selectedRows, assets])
}

export const useSearchFields = () => {
  const filterKeys = ["ASSET_NAME", "VIN", "ASSET_TYPE", "TPMS_PROFILE"]

  const labels = {
    ASSET_NAME: "Asset Name",
    VIN: "VIN",
    ASSET_TYPE: "Asset Type",
    TPMS_PROFILE: "TPMS Profile"
  }

  return useMemo(() => {
    return filterKeys.reduce((prev, curr) => {
      return [...prev, { id: TpmsColumnsIds[curr], label: labels[curr] }]
    }, [])
  }, [filterKeys, labels])
}