import {
  celsiusToFahr,
  getColor,
  getClearLabel,
  sortByAlphabet,
} from "../../utils";
import { getDateRange } from "../../utils/chartUtils";
import { UnitsOfMeasurement } from "../../enums";

export const composeLineChartData = (data, graphColors, unitsOfMeasurement) => {
  const datasets = [];
  const tempUnits =
    unitsOfMeasurement === UnitsOfMeasurement.imperial ? "°F" : "°C";

  data.forEach((item) => {
    let row = {
      data: [],
    };
    item.data.forEach((itemData) => {
      const level = +(
        unitsOfMeasurement === UnitsOfMeasurement.imperial
          ? celsiusToFahr(itemData.avg)
          : itemData.avg
      ).toFixed(1);

      let dataRow = {
        y: level,
        x: itemData.chartTAxis,
        displayValue: `Temperature: ${level + tempUnits}`,
        displayFooter: itemData.displayDate,
      };
      row.data.push(dataRow);
    });

    row.data = row.data.sort(
      (d1, d2) => +new Date(d1.displayDate) - +new Date(d2.displayDate)
    );
    row.label =
      item.label !== "NONE" && item.label !== "UNKNOWN"
        ? getClearLabel(item.label)
        : `Sensor: ${item.id}`;
    const currentColor = getColor(row.label, graphColors);
    row.fill = false;
    row.backgroundColor = currentColor;
    row.borderColor = currentColor;
    row.borderWidth = 2;
    datasets.push(row);
  });

  const range = getDateRange(datasets);

  return { datasets, xMax: range.max, xMin: range.min };
};

export const getDropdownItems = (data, assetType) => {
  if (!data.dropdownIntems?.length) return [];

  if (assetType === "trailer")
    return sortByAlphabet(data.dropdownIntems, "label");

  return data.dropdownIntems;
};
