import React from "react";
import {
  FormFeedback,
} from "reactstrap";
import { Col, Label, FormGroup, Input } from "reactstrap";
import { Field, useField } from "formik";

export const FormField = ({
  className = "",
  md = "6",
  type = "text",
  children = null,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <Col md={md} className={className}>
      <Label>{label}</Label>
      <FormGroup className="w-100">
        <Input 
          type={type}
          {...field}
          {...props}
          tag={Field}
          invalid={!!(meta.touched && meta.error)}
        >
          {children}
        </Input>
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
};

