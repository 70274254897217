import React from 'react'
import TagsInput from '../../../components/TagsInput'
import RivataDropdown from '../../../components/RivataDropdown'

interface ISensorReportsFormProps {
    setVinList: (list: string[]) => void,
    selected: string,
    disabled: boolean,
    setSelected: (id: string) => void,
}

const SensorReportsForm: React.FC<ISensorReportsFormProps> = ({ setVinList, selected, setSelected, disabled}) => {
    return (
        <div className="d-flex">
            <TagsInput 
                title="VINs"
                md="6"
                disabled={disabled}
                setList={setVinList}
                text="Enter multiple VINs by hitting (Enter, Comma, Space) key. If no VINs entered, all available assets will be returned"
            />
            <div className="d-flex ml-3 align-items-start" style={{ minWidth: "300px"}}>
                <label className="mr-2 mb-0 mt-2" style={{ minWidth: "120px" }}>Select Asset Type:</label>
                <RivataDropdown
                    items={[
                        { id: "all", label: "All", disabled: false },
                        { id: "tractor", label: "Tractor", disabled: false },
                        { id: "trailer", label: "Trailer", disabled: false },
                    ]}
                    selected={selected}
                    onSelect={setSelected}
                    buttonLabel={null}
                    filters={null}
                    onRemove={null}
                    disabled={disabled}
                    color={"secondary"}
                />
            </div>
        </div>
    )
}

export default SensorReportsForm