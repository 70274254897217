import moment from 'moment'
import {
  CLEAR_SESSION,
  SET_SESSION,
  SET_TOKEN_LIFE_TIME,
  SET_REFRESHED_TOKEN,
  SAVING_SESSION,
  SET_SESSION_ERROR,
  CHECK_SESSION,
  SET_PREFERENCES,
} from "../actionTypes";
import authService from "../../services/auth";
import api from "../../services/api";
import { getToken } from "../../utils";
import { store } from "../store";
import { fetchCustomers, fetchDefaults, fetchVersionInfo } from '../common/action'
import { fetchWhitelabel } from '../whitelabel/action'

export const setTokenLifeTime = (payload) => ({
  type: SET_TOKEN_LIFE_TIME,
  payload,
})

export const setRefreshedToken = (payload) => ({
  type: SET_REFRESHED_TOKEN,
  payload,
})

export const setSavingSession = (payload) => {
  return {
    type: SAVING_SESSION,
    payload,
  };
};

export const setSessionError = (payload) => {
  return {
    type: SET_SESSION_ERROR,
    payload,
  };
};

export const clearSession = () => ({
  type: CLEAR_SESSION,
});

export const setPreferences = (payload) => ({
  type: SET_PREFERENCES,
  payload,
})

export function initSessionFromCallbackURI(callbackUri) {
  return function (dispatch) {
    return authService
      .parseCallbackURI(callbackUri) // parse the callback URL
      .then(() => authService.getUser()) // get a new session
      .then((session) => {
        dispatch({ type: SET_SESSION, payload: session });
      });
  };
}

export function updatePreferences(newPreferences, changePhone) {
  return async function (dispatch, getState) {
    try {
      const token = getToken(getState);
      const body = {
        units_of_measurement: newPreferences.unitsOfMeasurement,
        notification_text: newPreferences.notifications.receiveText,
        notification_email: newPreferences.notifications.receiveEmail,
        notification_thresholds: newPreferences.notifications.threshold,
        timezone: newPreferences.timezone,
        phone: newPreferences.phone,
        old_phone: newPreferences.oldPhone
      }
      await api.putUserAttributes(token, JSON.stringify(body))

      if (!changePhone) {
        const data = {
          subscriptions: {
            sms: newPreferences.notifications.receiveText,
            email: newPreferences.notifications.receiveEmail,
            level: newPreferences.notifications.threshold
          }
        }
        
        await api.subscribeToAlerts(token, data)
      }

      const session = await authService.getUser()

      dispatch({ type: SET_SESSION, payload: session })

      return { status: "ok" }
    } catch (err) {
      return { status: "not ok" }
    }
  };
}

export function initSession() {
  return function (dispatch) {
    return authService
      .getUser()
      .then((session) => {
        dispatch({ type: SET_SESSION, payload: { ...session, expiresIn: null } });
        dispatch(setTokenLifeTime(session.expiresIn)) // set token life time
      })
      .catch(() => dispatch({ type: CHECK_SESSION }));
  };
}

export const setSession = (session) => ({
  type: SET_SESSION,
  session,
});

export function getUserAttributes() {
  return (dispatch, getState) => {
    return api
      .getUserAttributes(getToken(getState))
      .then((res) => {
        dispatch(setPreferences(res))
      })
      .catch((err) => console.log(err))
  }
}

store.subscribe(() => {
  const lastAction = store.getState().lastAction
  let interval
  
  if (lastAction.type === SET_SESSION) {
    clearInterval(interval)
    store.dispatch(fetchCustomers())
    store.dispatch(fetchDefaults())
    store.dispatch(getUserAttributes())
    store.dispatch(fetchWhitelabel())
    store.dispatch(fetchVersionInfo())
  } else if (lastAction.type === SET_TOKEN_LIFE_TIME) {
    const timestamp = lastAction.payload
    interval = setInterval(() => {
      if (moment().unix() + 90 >= timestamp) { // refresh token before it is expired 
        authService.refreshToken()
          .then((refreshedSession) => {
            store.dispatch(setRefreshedToken(refreshedSession.idToken.jwtToken))
            store.dispatch(setTokenLifeTime(refreshedSession.idToken.payload.exp))
          })
          .catch(err => {
            console.log(`Error during refreshing token: ${err}`)
          })
          .finally(() => {
            clearInterval(interval)
          })
      }
    }, 60000) // every 1 min check is token expired or not
  }
})