import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

export const LogModal = ({ isOpen, text, onLogModalClose }) => {
    return <Modal size="lg" centered isOpen={isOpen}>
        <ModalHeader toggle={onLogModalClose}>
            Log
        </ModalHeader>
        <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: text}}></div>
        </ModalBody>
    </Modal>
}