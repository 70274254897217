import React from "react";
import RivataDropdown from "../RivataDropdown";
import { LayerTypes } from "../RivataMap/utils";

const dropdownItems = [
  {
    id: LayerTypes.NORMAL,
    label: "Map",
  },
  {
    id: LayerTypes.SATELLITE,
    label: "Satellite View",
  }
];

// probably connected: select makes call to add, remove makes call to remove
const LayersDropdown = ({ onSelect, selected, locale }) => {
  return (
    <RivataDropdown
      items={dropdownItems}
      locale={locale}
      onSelect={onSelect}
      selected={selected}
    />
  );
};

export default LayersDropdown;
