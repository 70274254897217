import {
  LOADING_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_ERROR,
  POSTING_WHITELABEL_ADMIN,
  POSTED_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_DATA_DIRTY,
  SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LOGO_ICON_PNG,
  SET_WHITELABEL_ADMIN_LOGO_FULL_PNG,
  SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG,
} from "../actionTypes";
import {
  fetchCustomers
} from '../common/action';
import api from "../../services/api";
import defaultConfig from "../whitelabel/defaultConfig";
import { WhiteLabelFilenames } from "../../enums";
import { getToken, getErrorObj, getActualConfig } from "../../utils";

export const setSmarthubHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG,
  payload: isPosting,
});

export const setTpmsHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG,
  payload: isPosting,
});

export const setLinePressureHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG,
  payload: isPosting,
});

export const setAxleLoadHealthSvg = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG,
  payload: isPosting,
});

export const setLogoIconPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_LOGO_ICON_PNG,
  payload: isPosting,
});

export const setLogoFullPng = (isPosting) => ({
  type: SET_WHITELABEL_ADMIN_LOGO_FULL_PNG,
  payload: isPosting,
});

export const loadingWhitelabel = (isLoading) => ({
  type: LOADING_WHITELABEL_ADMIN,
  payload: isLoading,
});

export const postingWhitelabel = (isPosting) => ({
  type: POSTING_WHITELABEL_ADMIN,
  payload: isPosting,
});

export const postedWhitelabel = (hasPosted) => ({
  type: POSTED_WHITELABEL_ADMIN,
  payload: hasPosted,
});

export const setWhitelabel = (config, isUpdate = false) => ({
  type: SET_WHITELABEL_ADMIN,
  payload: { config, isUpdate },
});

export const setWhitelabelDirty = (isDirty) => ({
  type: SET_WHITELABEL_ADMIN_DATA_DIRTY,
  payload: isDirty,
});

export const setWhitelabelError = (statusCode, message) => ({
  type: SET_WHITELABEL_ADMIN_ERROR,
  payload: { statusCode, message },
});

const compareConfigParametersCount = (config, newConfig) => {
  const keys = Object.keys(newConfig)

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    
    if (Array.isArray(newConfig[key])) {
      if (newConfig[key].length !== config[key].length) {
        return false
      }
    }
  }

  return true
}

export function fetchWhitelabel(customerId) {
  return function (dispatch, getState) {
    dispatch(loadingWhitelabel(true));
    return api
      .getConfig(getToken(getState), customerId)
      .then((config) => {
        const newConfig = getActualConfig(config)

        // check parameters count if it is not equal then it means that we have been added something new in local config and need to save it on server for old customer
        const isEqual = compareConfigParametersCount(config, newConfig)

        // compare default attributes from s3 with attributes from db if it is not equal then update config in s3
        const { units_of_measurement, timezone } = getState().common.customers.selectedCustomer
        const { unitsOfMeasurement, locale } = config.preferenceDefaults
    
        if (!unitsOfMeasurement || units_of_measurement !== unitsOfMeasurement || timezone !== locale.timezone || !isEqual) {
          config.preferenceDefaults.unitsOfMeasurement = units_of_measurement
          config.preferenceDefaults.locale.timezone = timezone
          
          dispatch(setWhitelabel(newConfig))
          dispatch(postWhitelabel(customerId)) 
        } else {
          dispatch(setWhitelabel(newConfig))
        }
      })
      .catch((err) => {
        dispatch(setWhitelabel(defaultConfig));
        dispatch(setWhitelabelError(getErrorObj(err)));
      })
      .finally(() => dispatch(loadingWhitelabel(false)));
  };
}

export function postWhitelabelImage(file, filename, token, customerId) {
  return function (_dispatch, _getState) {
    return api.uploadImage(file, filename, token, customerId);
  };
}
export function postWhitelabel(customerId) {
  return function (dispatch, getState) {
    dispatch(postingWhitelabel(true));
    const token = getToken(getState);
    const whitelabelAdmin = getState().whitelabelAdmin;
    const {
      config,
      smarthubHealthSvg,
      tpmsHealthSvg,
      linePressureHealthSvg,
      logoIconPng,
      logoFullPng,
      axleLoadHealthSvg,
    } = whitelabelAdmin;

    const savedConfig = { ...config, customer_id: customerId };

    if (smarthubHealthSvg)
      dispatch(
        postWhitelabelImage(
          smarthubHealthSvg,
          WhiteLabelFilenames.smarthubHealthSvg,
          token,
          customerId
        )
      );
    if (tpmsHealthSvg)
      dispatch(
        postWhitelabelImage(
          tpmsHealthSvg,
          WhiteLabelFilenames.tpmsHealthSvg,
          token,
          customerId
        )
      );
    if (linePressureHealthSvg)
      dispatch(
        postWhitelabelImage(
          linePressureHealthSvg,
          WhiteLabelFilenames.linePressureHealthSvg,
          token,
          customerId
        )
      );
    if (axleLoadHealthSvg)
      dispatch(
        postWhitelabelImage(
          axleLoadHealthSvg,
          WhiteLabelFilenames.axleLoadHealthSvg,
          token,
          customerId
        )
      );
    if (logoIconPng)
      dispatch(
        postWhitelabelImage(
          logoIconPng,
          WhiteLabelFilenames.logoIconPng,
          token,
          customerId
        )
      );
    if (logoFullPng)
      dispatch(
        postWhitelabelImage(
          logoFullPng,
          WhiteLabelFilenames.logoFullPng,
          token,
          customerId
        )
      );

    return api.postConfig(JSON.stringify(savedConfig), getToken(getState), customerId).then(r => {
      dispatch(fetchCustomers());
    })
      .catch((err) => dispatch(setWhitelabelError(getErrorObj(err))))
      .finally(() => dispatch(postingWhitelabel(false)));
  };
}

export const resetWhitelabelColorsToDefault = () => {
  return (dispatch, getState) => {
    const token = getToken(getState);
    const { config } = getState().whitelabelAdmin
    const { id } = getState().common.customers.selectedCustomer
    const body = {
      customer_id: id,
      config
    }
    return api.putWhitelabelColors(token, body)
      .then(() => {
        dispatch(fetchWhitelabel(id))
      })
      .catch((err) => console.log(err))
  }
}