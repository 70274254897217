import Dashboard from "../pages/Dashboard";
import WarningDetails from "../pages/WarningDetails";
import AssetDetails from "../pages/AssetDetails";
import Settings from "../pages/Settings";
import Support from "../pages/Support";
import Reports from "../pages/Reports";
import FAQs from "../pages/FAQs";
import DocumentPage from "../pages/DocumentPage";
import Geofences from "../pages/Geofences";
var indexRoutes = [
  {
    path: "/details/:vin/warnings/:timestamp/:days/:warningKey",
    name: "WarningDetails",
    component: WarningDetails,
  },
  { path: "/details/:vin", name: "AssetDetails", component: AssetDetails },
  { path: "/settings", name: "Settings", component: Settings },
  { path: "/support", name: "Support", component: Support },
  { path: "/legal", name: "Legal", component: DocumentPage },
  { path: "/faqs", name: "FAQs", component: FAQs },
  { path: "/reports", name: "Reports", component: Reports },
  { path: "/geofences/:id?/:vin?", name: "Geofences", component: Geofences },
  { path: "/", name: "Dashboard", component: Dashboard },
];

export default indexRoutes;
