import React, { useState } from "react";

import { Spinner, Fade } from "reactstrap";

import Tractor from "./Tractor";

import LatestTirePressure from "../common/LatestTirePressure";

import "./style.scss";

const TractorDiagram = ({ assetTpmsStatus, pressureUnit, getTireData, timezone, locale }) => {
  const width = 1200;
  const height = 300;
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="d-flex justify-content-center align-items-center diagram-container">
      {assetTpmsStatus.isLoading && !imageLoaded ? (
        <Spinner />
      ) : (
        <Fade in={!assetTpmsStatus.isLoading && imageLoaded} timeout={500}>
          <div className="d-flex align-items-center justify-content-center" style={{ width: `${width}px`, height: `${height}px`, marginBottom: "90px" }}>
            <Tractor
              height={height}
              sensors={assetTpmsStatus.data?.sensors}
              pressureUnit={pressureUnit}
              getTireData={getTireData}
              setImageLoaded={setImageLoaded}
              timezone={timezone}
              locale={locale}
            />
          </div>

          <LatestTirePressure pressureUnit={pressureUnit} timezone={timezone} />
        </Fade>
      )}
    </div>
  );
};

export default TractorDiagram;
