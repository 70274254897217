import { useMemo, useState, useEffect } from "react";
import { ColumnsIds, SortOrder, WhiteLabelFilenames, WarningType, PressureWarningLevel } from "../../enums";
import { compare, getCurrentEpoch} from "../../utils";

const dataCompare = (d1, d2) => compare(d1.data, d2.data);
const labelCompare = (d1, d2) => compare(d1.label, d2.label);
const timestampCompare = (d1, d2) => {
  const timestamp1 = d1.data && d1.data.timestamp ? +new Date(d1.data.timestamp) : 0;
  const timestamp2 = d2.data && d2.data.timestamp ? +new Date(d2.data.timestamp) : 0;
  return compare(timestamp1, timestamp2)
};
export const useColumns = (locale, selected, customerIds, isSuperAdmin) => {
  return useMemo(() => {
    const cols = [
      {
        label: locale["Status"],
        id: ColumnsIds.STATUS,
        control: "sort",
        compare: labelCompare,
      },
      {
        label: locale["VIN"],
        id: ColumnsIds.VIN,
        control: "sort",
        compare: dataCompare,
      },
      {
        label: locale["Customer"],
        id: ColumnsIds.CUSTOMER_NAME,
        control: "sort",
        compare: dataCompare,
      },
      {
        label: locale["Asset Name"],
        id: ColumnsIds.ASSET_NAME,
        control: "sort",
        compare: dataCompare,
      },
      {
        label: locale["Last GPS Update"],
        id: ColumnsIds.LAST_GPS_UPDATE,
        control: "sort",
        compare: timestampCompare,
      }
    ]

    if (customerIds?.length < 2 && !isSuperAdmin) {
      const idx = cols.findIndex(el => el.id === ColumnsIds.CUSTOMER_NAME)
      cols.splice(idx, 1)
    }

    return cols
  },[locale, customerIds, isSuperAdmin]);
};

export const useSearchFields = (locale) => {
  return useMemo(
    () => [
      {
        label: locale["Status"],
        id: ColumnsIds.STATUS,
      },
      {
        label: locale["VIN"],
        id: ColumnsIds.VIN,
      },
      {
        label: locale["Asset Name"],
        id: ColumnsIds.ASSET_NAME,
      },
      {
        label: locale["Last GPS Update"],
        id: ColumnsIds.LAST_GPS_UPDATE,
      },
      {
        label: locale["Sensor Type"],
        id: ColumnsIds.SENSOR_INFO,
      },
      {
        label: locale["Sensor ID"],
        id: ColumnsIds.SENSOR_ID,
      },
    ],
    [locale]
  );
};

export const useIsLoaded = (isLoadingStatus, isLoadingMap, isLoading) => {
  return useMemo(() => {
    return !isLoadingStatus && !isLoadingMap && !isLoading;
  }, [isLoadingStatus, isLoadingMap, isLoading]);
};

export const useTableRows = (assets, warnings, locations, whiteLabelUrl, timezone, isSuperAdmin = false, customerIds) => {
  const [tableRows, setTableRows] = useState([]);
  useEffect(() => {
    const _tableBody = assets.map((asset) => {
      const warningTypes = []
      if (asset.warning_type) {
        let array = asset.warning_type.split(',')
        array = array.map((type) => {
          return type.split("|")
        })
        warningTypes.push([ ...array ])
      }
      const sensorInfo = asset.sensor_info
      const subscription = asset.subscription

      const smartHubSrc = `${whiteLabelUrl}${WhiteLabelFilenames.smarthubHealthSvg}?r=${+new Date()}`;
      const tpmsSrc = `${whiteLabelUrl}${WhiteLabelFilenames.tpmsHealthSvg}?r=${+new Date()}`;
      const linePressureSrc = `${whiteLabelUrl}${WhiteLabelFilenames.linePressureHealthSvg}?r=${+new Date()}`;
      const axleLoadSrc = `${whiteLabelUrl}${WhiteLabelFilenames.axleLoadHealthSvg}?r=${+new Date()}`;

      const currentDate = getCurrentEpoch()
      const alt = []
      const src = []
      const styles = []
      
      let isPressureWarning = false
      let isPressureCriticalWarning = false
      let isSmarthubWarning = false
      let isLinePressureWarning = false

      const pushIcon = (iconType, source, color) => {
        src.push([iconType, source])
        alt.push(iconType)
        styles.push([iconType, color])
      }

      const noStatus = {
        "smarthub": asset.warning_type?.includes("no_status|no_status_smarthub"),
        "tpms": asset.warning_type?.includes("no_status|no_status_tpms"),
        "line_pressure": asset.warning_type?.includes("no_status|no_status_line_pressure")
      }


      warningTypes.map((type) => {
        return type.map((item, i) => {
          if (item[0] === WarningType.hubVibration || item[0] === WarningType.hubTemperature) {
            if (isSmarthubWarning || noStatus.smarthub) return item // skip if there already is icon or no status warning
            if (sensorInfo?.smarthub_sensors && ((subscription?.HUB_SENSOR && currentDate <= subscription.HUB_SENSOR.valid_to) || isSuperAdmin)) {
              pushIcon("smarthub", smartHubSrc, "filter-yellow");
              isSmarthubWarning = true
            }
          } else if (item[0] === WarningType.tirePressure) {
            if (item[1] === PressureWarningLevel.criticalLowPressure || item[1] === PressureWarningLevel.criticalOverPressure) {
              if (isPressureCriticalWarning) return item // skip if there already is icon
              if (sensorInfo?.tpms && ((subscription?.TIRE_SENSOR && currentDate <= subscription.TIRE_SENSOR.valid_to) || isSuperAdmin)) {
                pushIcon("tpms", tpmsSrc, "filter-orange")
                isPressureCriticalWarning = true
              }
            }
            if (item[1] === PressureWarningLevel.lowPressure || item[1] === PressureWarningLevel.overPressure) {
              if (isPressureWarning || isPressureCriticalWarning || noStatus.tpms) return item // skip if there already is icon or no status warning
              if (sensorInfo?.tpms && ((subscription?.TIRE_SENSOR && currentDate <= subscription.TIRE_SENSOR.valid_to) || isSuperAdmin)) {
                pushIcon("tpms", tpmsSrc, "filter-yellow")
                isPressureWarning = true
              }
            }     
          } 
          else if (item[0] === WarningType.linePressure) {
            if(isLinePressureWarning) return item // skip if there already is icon
            if (sensorInfo?.line_pressure && ((subscription?.LINE_PRESSURE && currentDate <= subscription.LINE_PRESSURE.valid_to) || isSuperAdmin)) {
              pushIcon("line_pressure", linePressureSrc, "filter-orange")
              isLinePressureWarning = true
            }
          }
          return item
        })
      })

      if (sensorInfo?.smarthub_sensors && subscription?.HUB_SENSOR && currentDate <= subscription.HUB_SENSOR.valid_to && noStatus.smarthub && !isSmarthubWarning) {
        pushIcon("smarthub", smartHubSrc, "filter-grey")
        isSmarthubWarning = true;
      }
      
      if (sensorInfo?.tpms && subscription?.TIRE_SENSOR && currentDate <= subscription.TIRE_SENSOR.valid_to && noStatus.tpms && !isPressureCriticalWarning) {
        pushIcon("tpms", tpmsSrc, "filter-grey")
        isPressureCriticalWarning = true;
      }
      if (sensorInfo?.line_pressure && subscription?.LINE_PRESSURE && currentDate <= subscription.LINE_PRESSURE.valid_to && noStatus.line_pressure && !isLinePressureWarning) {
        pushIcon("line_pressure", linePressureSrc, "filter-grey")
        isLinePressureWarning = true;
      }

      if (sensorInfo?.smarthub_sensors && !isSmarthubWarning) {
        if ((subscription?.HUB_SENSOR && currentDate <= subscription.HUB_SENSOR.valid_to) || isSuperAdmin) {
          pushIcon("smarthub", smartHubSrc, "filter-green")
        }
      }
      if (sensorInfo?.tpms && !isPressureWarning && !isPressureCriticalWarning) {
        if ((subscription?.TIRE_SENSOR && currentDate <= subscription.TIRE_SENSOR.valid_to) || isSuperAdmin) {
          pushIcon("tpms", tpmsSrc, "filter-green")
        }
      }
      if (sensorInfo?.line_pressure && asset.asset_type === "trailer" && !isLinePressureWarning) {
        if ((subscription?.LINE_PRESSURE && currentDate <= subscription.LINE_PRESSURE.valid_to) || isSuperAdmin) {
          pushIcon("line_pressure", linePressureSrc, "filter-green")
        }
      }

      if (sensorInfo?.axle_load) {
        if ((subscription?.AXLE_LOAD && currentDate <= subscription.AXLE_LOAD.valid_to) || isSuperAdmin) {
          pushIcon("axle_load", axleLoadSrc, "filter-green")
        }
      }

      const icons_order = {
        "smarthub": 0,
        "tpms": 1,
        "line_pressure": 2,
        "axle_load": 3
      }

      alt.sort((s1, s2) => icons_order[s1] - icons_order[s2])
      src.sort((s1, s2) => icons_order[s1[0]] - icons_order[s2[0]])
      styles.sort((s1, s2) => icons_order[s1[0]] - icons_order[s2[0]])
      const sortedSrc = src.map((item) => item[1])
      const sortedStyles = styles.map((item) => item[1])

      const lastGpsUpdate = asset.formatted_datetime;
      const row = {
        columns: [
          {
            type: "icon",
            label: alt,
            src: sortedSrc,
            classNames: sortedStyles,
            columnId: ColumnsIds.STATUS,
            assetId: asset.id,
            showIconTooltip: true
          },
          {
            type: "link",
            label: asset.vin,
            link: `/details/${asset.vin}`,
            columnId: ColumnsIds.VIN,
            data: asset.vin,
            assetId: asset.id,
          },
          {
            type: "link",
            label: asset.customer_name,
            link: `/details/${asset.vin}`,
            columnId: ColumnsIds.CUSTOMER_NAME,
            data: asset.customer_name,
            assetId: asset.id,
          },
          {
            type: "link",
            label: asset.name || "-",
            link: `/details/${asset.vin}`,
            columnId: ColumnsIds.ASSET_NAME,
            data: asset.name,
            assetId: asset.id,
          },
          {
            type: "link",
            label: lastGpsUpdate,
            link: `/details/${asset.vin}`,
            columnId: ColumnsIds.LAST_GPS_UPDATE,
            assetId: asset.id,
          }
        ],
        id: asset.id,
      };

      if (customerIds?.length < 2 && !isSuperAdmin) {
        const idx = row.columns.findIndex(el => el.columnId === ColumnsIds.CUSTOMER_NAME)
        row.columns.splice(idx, 1)
      }

      return row
    });
    setTableRows(_tableBody);
  }, [assets, warnings, locations, whiteLabelUrl, timezone, isSuperAdmin, customerIds]);
  return tableRows;
};


export const useSorting = (
  filteredRows,
  sortColumn,
  sortDirection,
  columns,
  allLoaded
) => {
  const [sortedRows, setSortedRows] = useState([]);

  useEffect(() => {
    const sorted = [...filteredRows];
    if (allLoaded) {
      const columnIndex = columns.findIndex((h) => h.id === sortColumn);

      if (columnIndex > -1) {
        const column = columns[columnIndex];
        const sortFunction =
          sortDirection === SortOrder.ASC
            ? (a, b) => column.compare(a, b)
            : (a, b) => column.compare(b, a);
        sorted.sort((row1, row2) => {
          const c1 = row1.columns[columnIndex];
          const c2 = row2.columns[columnIndex];
          return sortFunction(c1, c2);
        });
      }
    }
    setSortedRows(sorted);
  }, [filteredRows, sortColumn, sortDirection, columns, allLoaded]);

  return sortedRows;
};

export const useAcknowledgements = (sortedRows, acknowledgements) => {
  const [checkedRows, setCheckedRows] = useState([]);

  useEffect(() => {
    // This is a little weird, but it gets the first acknowledgment from the asset and sets the check
    // status based on that.
    // The reason being that some warnings are derived from many data points.
    const checked = sortedRows.map((asset) => {
      if (acknowledgements[asset.id]) {
        asset.columns[0].checked = acknowledgements[asset.id].checked;
      } else {
        asset.columns[0].checked = false;
      }
      return asset;
    });
    setCheckedRows(checked);
  }, [sortedRows, acknowledgements]);

  return checkedRows;
};

export const useSearchOptions = (filteredRows, searchColumn, columns) => {
  return useMemo(() => {
    if (!Array.isArray(filteredRows)) {
      return [];
    }

    const rowBucket = filteredRows.reduce((accum, curr) => {
      const columnIndex = columns.findIndex((h) => h.id === searchColumn);
      if (
        columnIndex > -1 &&
        curr &&
        curr.columns &&
        curr.columns[columnIndex]
      ) {
        const { columnId, label, assetId } = curr.columns[columnIndex];
        if (label === "-") {
          return accum;
        }

        const item = { id: columnId, label, assetId };
        // add to map to so we have unique vals for typeahead
        if (label in accum) {
          accum[label].count++;
          accum[label].items.push(item);
        } else {
          accum[label] = { ...item, count: 1 };
          accum[label].items = [item];
        }
      }
      return accum;
    }, {});

    return Object.keys(rowBucket).map((k) => rowBucket[k]);
  }, [filteredRows, searchColumn, columns]);
};

