import moment from "moment";
import { ChartData, ChartDataset } from "chart.js";
import { AnnotationOptions } from "chartjs-plugin-annotation";
import { convertDataEpochToDate } from "./index";
import { ITooltipItem } from "../components/RivataLineChart/interfaces";

export interface IAnnotationOptions {
    [key: string]: AnnotationOptions
}
export interface IChartData extends ChartData {
    x: moment.Moment;
}

export type DatasetsList = Array<ChartDataset<"line", Array<IChartData>>>

interface IgetDateRange {
    (datasets: DatasetsList): {
        min: number;
        max: number;
    };
}

export const getDateRange: IgetDateRange = (datasets) => {
    let min = Infinity;
    let max = -Infinity;
    if (!datasets.length) return { min, max };
    datasets.forEach((item) => {
        item.data.forEach((point) => {
            const pointEpoch = point?.x?.unix();
            if (pointEpoch < min) {
                min = pointEpoch;
            }
            if (pointEpoch > max) {
                max = pointEpoch;
            }
        });
    });
    let minMoment: number = convertDataEpochToDate(
        min,
        null,
        null,
        false
    ).unix();
    let maxMoment: number = convertDataEpochToDate(
        max,
        null,
        null,
        false
    ).unix();

    return { min: minMoment, max: maxMoment };
};

interface composeVertLineAnnotation {
    (
        id: string,
        value: number,
        color: string
    ): AnnotationOptions
}

export const composeVertLineAnnotation: composeVertLineAnnotation = (id = "chart", value, color = "rgb(226,178,63)") => ({
  id: `threshold-vline-${id}`,
  type: "line",
  scaleID: "x",
  value,
  endValue: value,
  borderColor: color,
  borderWidth: 4,
  drawTime: "beforeDatasetsDraw"
})

type annotationsList = { [key: string]: AnnotationOptions };
export type healthColor = { id: string; color: string; label: string };
interface IchartThresholdAnnotations {
    (
        id: string,
        threshold: number,
        verticalLine: number,
        healthColors: Array<healthColor>
    ): annotationsList;
}

export const chartThresholdAnnotations: IchartThresholdAnnotations = (
    id = "chart",
    threshold,
    verticalLine,
    healthColors
) => {
    const annotations: annotationsList = {}
    const xMin = -Infinity
    const xMax = Infinity

    annotations.box1 = {
        type: "box",
        id: `box-plot-top-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: Infinity,
        yMin: threshold,
        backgroundColor: "#fffcdf",
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    };

    annotations.box2 = {
        type: "box",
        id: `box-plot-bottom-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: threshold,
        yMin: -Infinity,
        xMin,
        xMax,
        backgroundColor: "#fff",
        borderWidth: 0,
        drawTime: "beforeDatasetsDraw",
    };

    if (threshold) {
        annotations.thresholdLine = {
            id: `threshold-line-${id}`,
            type: "line",
            xScaleID: "x",
            yScaleID: "y",
            yMax: threshold,
            yMin: threshold,
            xMin,
            xMax,
            value: threshold,
            borderColor: "#fed925",
            borderWidth: 2,
            borderDash: [2, 2],
            borderDashOffset: 10,
            drawTime: "beforeDatasetsDraw",
        };
    }

    if (verticalLine && healthColors && healthColors[1]) {
        annotations.verticalLine = composeVertLineAnnotation(id, verticalLine, healthColors[1].color)
    }

    return annotations;
};

type speedData = {
    isLoading: boolean;
    error: Error;
    data: Array<{ start: number; stop: number }>;
};
interface IgetMotionAnnotations {
    (
        speedData: speedData, 
        id: string
    ): { [key: string]: AnnotationOptions<"box"> };
}

export const getMotionAnnotations: IgetMotionAnnotations = (speedData, id) => {
    return speedData && !speedData.isLoading && speedData.data.length
        ? speedData.data.reduce((acc, data, index) => {
            const annotation: AnnotationOptions<"box"> = {
                backgroundColor: "rgba(220, 221, 222, 0.25)",
                id: `box-plot-top-motion-annotation-${id}-${index}`,
                type: "box",
                xScaleID: "x",
                yScaleID: "y",
                yMax: Infinity, // We set these values after render, otherwise glitches may appear
                yMin: -Infinity,
                xMin: data.start * 1000,
                xMax: data.stop * 1000,
                borderWidth: 1,
                borderColor: "rgba(220, 221, 222, 0.25)",
            }
            return {
                ...acc,
                [`box-${index}`]: annotation
            };
        }, {})
        : {};
};

type ChartTooltip = {
    displayColors: boolean
    borderWidth: number
    backgroundColor?: (item: ITooltipItem) => any
    borderColor?: (item: ITooltipItem) => any
    callbacks: {
        title: (items: Array<ITooltipItem>) => any
        beforeLabel?: (item: ITooltipItem) => any
        afterLabel?: (item: ITooltipItem) => any
        label: (item: ITooltipItem) => any
        footer?: (items: Array<ITooltipItem>) => any
    }
}
interface IcomposeCustomTooltip {
    (
        config: {
            displayColors: boolean
            bgColor?: boolean
            borderColor?: boolean
            beforeLabel?: boolean
            afterLabel?: boolean
            footer?: boolean
        }
    ): any
}

export const composeCustomTooltip: IcomposeCustomTooltip = (config) => {
    const tooltip: ChartTooltip =  {
        displayColors: config.displayColors,
        borderWidth: 2,
        callbacks: {
            title: (tooltipItem: Array<ITooltipItem>) => tooltipItem[0].dataset.label || "",
            label: (tooltipItem: ITooltipItem) => tooltipItem.raw.displayValue,
        }
    }

    if (config.bgColor) {
        tooltip.backgroundColor = (tooltipItem) => tooltipItem.tooltip.labelColors[0].borderColor
    }
    if (config.borderColor) {
        tooltip.borderColor = (tooltipItem) => tooltipItem.tooltip.labelColors[0].borderColor
    }
    if (config.beforeLabel) {
        tooltip.callbacks.beforeLabel = (tooltipItem) => tooltipItem.raw.displayBeforeLabel
    }
    if (config.afterLabel) {
        tooltip.callbacks.afterLabel = (tooltipItem) => tooltipItem.raw.displayAfterLabel
    }
    if (config.footer) {
        tooltip.callbacks.footer = (tooltipItem) => tooltipItem[0].raw.displayFooter
    }
    

    return tooltip;
}