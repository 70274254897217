import React, { useState } from "react";

import { Spinner, Fade } from "reactstrap";

import Trailer from "./Trailer";

import LatestTirePressure from "../common/LatestTirePressure";

import tractor from "../../../assets/images/tpms_status_diagram/Tractor.svg";

import "./style.scss";

const TrailerDiagram = ({ assetTpmsStatus, pressureUnit, getTireData, timezone, locale }) => {
  const width = 1200;
  const height = 300;
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="d-flex justify-content-center align-items-center diagram-container">
      {assetTpmsStatus.isLoading && !imageLoaded ? (
        <Spinner />
      ) : (
        <Fade in={!assetTpmsStatus.isLoading && imageLoaded} timeout={500}>
          <div className="d-flex align-items-center justify-content-center" style={{ width: `${width}px`, height: `${height}px`, marginBottom: "90px" }}>
            <Trailer
              height={height}
              sensors={assetTpmsStatus.data?.sensors}
              pressureUnit={pressureUnit}
              getTireData={getTireData}
              timezone={timezone}
              locale={locale}
            />

            <div className="tractor-rear" style={{ width: "70px", height: "70px" }}></div>
            <img className="tractor" src={tractor} alt="tractor" height={height} onLoad={() => setImageLoaded(true)} />
          </div>

          <LatestTirePressure pressureUnit={pressureUnit} timezone={timezone} />
        </Fade>
      )}
    </div>
  );
};

export default TrailerDiagram;
