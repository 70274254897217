import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import RivataModule from "../../../components/RivataModule";
import GeofenceMap from "../map/GeofenceMap";
import GeofenceRenderer from "../renderer/GeofenceRenderer";
import RivataTable from "../../../components/RivataTable";
import LayersDropdown from "../../../components/LayersDropdown";
import StatusAlert from "../../../components/StatusAlert";
import DayPicker from "../DayPicker";
import { LayerTypes } from "../../../components/RivataMapCluster/utils";
import { useColumns, useTableRows } from "./hooks";
import { ActivityEvent } from "../types";
import { EventsFilter } from "../../../redux/geofences/types";
import "../styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { setTimeout } from "timers";
import { useActions } from "../../../hooks/useActions";
import { setCustomerGeofences } from "../../../redux/geofences/action";
import { useDispatch } from "react-redux";

interface Props {
  width: number;
  locale: Record<string, string>;
  isLoading: boolean;
  geofences: Array<IGeofence>;
  activityEvents: Array<ActivityEvent>;
  limit: number;
  totalCount: number;
  selectedCustomerId: number | null;
  eventsFilter: EventsFilter;
  isAdmin: boolean;
  fetchCustomerGeofences: () => Promise<void>;
  fetchActivityEvents: () => Promise<void>;
  clearActivityEvents: () => void;
  clearCustomerGeofences: () => void;
  setEventsOffset: (offset: number) => void;
  setEventsFilter: (filter: EventsFilter) => void;
  setEventsLimit: (limit: number) => void;
}

const GeofenceActivity: React.FC<Props> = ({
  width,
  locale,
  isLoading,
  geofences,
  activityEvents,
  limit,
  totalCount,
  selectedCustomerId,
  eventsFilter,
  isAdmin,
  fetchCustomerGeofences,
  fetchActivityEvents,
  clearActivityEvents,
  clearCustomerGeofences,
  setEventsOffset,
  setEventsFilter,
  setEventsLimit,
}) => {
  const [map, setMap] = useState<H.Map | null>(null);
  const [ui, setUi] = useState<H.ui.UI | null>(null);
  const [selectedLayerOption, setSelectedLayerOption] = useState(LayerTypes.NORMAL);
  const [selectedGeofence, setSelectedGeofence] = useState<IGeofence | null>(null);
  const [assetVin, setAssetVin] = useState("");
  const [date, setDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const dispatch = useDispatch();

  const columns = useColumns();
  const rows = useTableRows(activityEvents);

  const params = useParams<{ id?: string, vin?: string }>()
  const history = useHistory()
  const [activity, setActivity] = useState<string>("lastActivityEvent")
  const[toggle, setToggle] = useState<boolean>(false)
  const { customerGeofences} = useTypedSelector((state) => ({
    customerGeofences: state.geofences.customerGeofences,
  }));
  const { fetchGeofenceGredentials } = useActions()
  
  const fetchEvents =() => {
    if (!selectedGeofence?.id) return;
    setActivity("customerChoice")
    setToggle(!toggle)
    history.replace(`/geofences/${selectedGeofence.id}/${assetVin}`)
  };

  const getAlertText = () => {
    if (isAdmin && !selectedCustomerId) return "Please select a customer in dropdown.";
    if (((isAdmin && selectedCustomerId) || !isAdmin) && geofences.length) return "Please select a geofence";
    return "No Data";
  };

  useEffect(() => {
    clearActivityEvents();
    clearCustomerGeofences();
    fetchCustomerGeofences();
  }, [selectedCustomerId, fetchCustomerGeofences, clearActivityEvents, clearCustomerGeofences]);

  useEffect(() => {
    if (customerGeofences.length) {
    const id = params.id && +params.id
    const geofence = customerGeofences.find((el: IGeofence) => el.id === id);
    geofence && setSelectedGeofence(geofence) 
  }
},[customerGeofences, params.id])
  
  useEffect(() => {
    if (params.id) {
      const id = +params.id
      const vin = params.vin ? params.vin : ""
      if (activity === "customerChoice") {
        fetchGeofenceGredentials(id, date.start, date.end, vin)
      }
      else {
        fetchGeofenceGredentials(id, null, null, vin);
      }
      const geofence = customerGeofences.find((el: IGeofence) => el.id === id)
      geofence && setSelectedGeofence(geofence)
      setAssetVin(vin)
      setTimeout(() => {
        setActivity("lastActivityEvent");
      }, 500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.vin, toggle])

  return (
    <>
      <RivataModule
        fullScreenModalModeEnabled
        customFullScreenAction={() => {
          // TODO hack to rerender map in full screen
          setTimeout(() => {
            dispatch(setCustomerGeofences([...geofences]))
          }, 100)
        }}
        title="Geofences Activity"
        width={width}
        marginTop={0}
        locale={locale}
        filters={<LayersDropdown selected={selectedLayerOption} onSelect={(type: string) => setSelectedLayerOption(type)} locale={locale} />}
      >
        <GeofenceMap map={map} selectedLayerOption={selectedLayerOption} setMap={setMap} setUi={setUi} />

        <GeofenceRenderer map={map} ui={ui} geofences={selectedGeofence ? [selectedGeofence] : geofences} setBounds={true} />

      </RivataModule>
      <RivataModule
        title="Assets In Geofence"
        customClass={"activity-module"}
        locale={locale}
        filters={
          geofences.length ? (
            <>
              <Typeahead
                id="geofences_search"
                clearButton
                labelKey="name"
                className="geofences-search mr-3"
                onChange={(selected) => {
                  if (!selected.length) clearActivityEvents();
                  setSelectedGeofence(selected[0]);
                }}
                options={geofences}
                placeholder={"Select Geofence"}
                disabled={isLoading}
                selected={selectedGeofence ? [selectedGeofence] : []}
              />

              <Input
                className="asset-vin-input mr-3"
                placeholder={"Asset VIN"}
                value={assetVin}
                onChange={(e: { target: HTMLInputElement }) => {
                  setAssetVin(e.target.value);
                }}
                disabled={isLoading}
              />

              <DayPicker
                onDateSelected={(startDate: Date, endDate: Date) => {
                  setDate({ start: startDate, end: endDate });
                }}
                start={eventsFilter.startDate}
                end={eventsFilter.endDate}
              />

              <Button
                className="ml-3"
                disabled={isLoading}
                onClick={() => {
                  setAssetVin("");
                  setSelectedGeofence(null);
                  clearActivityEvents();
                  setEventsFilter({ selectedGeofenceId: null, startDate: null, endDate: null, assetVin: "" });
                }}
              >
                Clear
              </Button>

              <Button className="ml-3" disabled={isLoading || !selectedGeofence} onClick={fetchEvents}>
                Submit
              </Button>
            </>
          ) : null
        }
      >
        {!selectedGeofence ? (
          <StatusAlert customText={getAlertText()} color={"success"} statusCode={undefined} statusText={undefined} />
        ) : (
          <RivataTable
            columns={columns}
            rows={rows}
            isLoading={selectedGeofence && isLoading}
            showPagination={true}
            isShowingLimit={true}
            pageLimit={limit}
            // @ts-ignore component expect undefined cause of default value
            onSelectLimit={setEventsLimit}
            assetsCount={totalCount}
            // @ts-ignore component expect undefined cause of default value
            onPageChange={(offset: number) => {
              setEventsOffset(offset);
            }}
            filter={JSON.stringify(eventsFilter)}
            onEdit={undefined}
            onDelete={undefined}
            editDisabled={undefined}
            deleteDisabled={undefined}
            setSortOptions={undefined}
          />
        )}
      </RivataModule>
    </>
  );
};

export default GeofenceActivity;
