import { useMemo, useState } from 'react'
import { ChartData, ChartDataset } from 'chart.js'

import { DetailsComponents } from "../enums"
import { AxlesGroups } from '../constants/constants'
import { debouncer } from "../utils"

interface IChartData extends ChartData {
    yMax: string,
    yMin: string
}

interface IDropdownItem {
    id: string,
    label: string,
    disabled: boolean
}

interface IChartDataFilter {
    dropdownIntems: Array<IDropdownItem>,
    chartData: {
        datasets: Array<ChartDataset<any>>,
        xMax: string,
        xMin: string,
        yMax: string,
        yMin: string
    }
}

interface IChartDataset {
    label: string
}

const isInsideArrayById = (id: string, array: Array<IDropdownItem>): boolean => {
    const idx = array.findIndex((el) => el.id === id ? true : false)
    
    if (idx > -1) return true
    else return false
}

const positions = Object.keys(AxlesGroups)

const sortAxlesGroups = (items: Array<IDropdownItem>): Array<IDropdownItem> => {
    return items.sort((a, b) => positions.indexOf(a.id) - positions.indexOf(b.id))
}

export const useChartDataWithAxlesFilter = (chartData: IChartData, xMax: string, xMin: string, selectedAxle: string) => {
    return useMemo(() => {
        const dataWithAxlesFilter: IChartDataFilter = {
            dropdownIntems: [ {id: "all", label: "All", disabled: false} ],
            chartData: {
                datasets: [],
                xMax: xMax,
                xMin: xMin,
                yMax: chartData?.yMax,
                yMin: chartData?.yMin
            }
        }

        if (!chartData || !chartData.datasets.length) return dataWithAxlesFilter
        const { datasets } = chartData
        const axlesGroupsKeys = Object.keys(AxlesGroups)

        datasets.forEach((line: ChartDataset) => {
            for (let i = 0; i < axlesGroupsKeys.length; i++) {
                const currentKey = axlesGroupsKeys[i]

                if (AxlesGroups[currentKey].includes(line.label || "")) {
                    if (selectedAxle === "all" || selectedAxle === currentKey) {
                        dataWithAxlesFilter.chartData.datasets.push(line)
                    }

                    if (!isInsideArrayById(currentKey, dataWithAxlesFilter.dropdownIntems)) {
                        dataWithAxlesFilter.dropdownIntems.push({
                            id: currentKey,
                            label: currentKey,
                            disabled: false,
                        })

                        break
                    }
                }
            }
        })

        dataWithAxlesFilter.dropdownIntems = sortAxlesGroups(dataWithAxlesFilter.dropdownIntems)

        return dataWithAxlesFilter
    }, [chartData, xMax, xMin, selectedAxle])
}



type Range = { min: number | null, max: number | null }

interface IchartComponentsData {
	key: DetailsComponents
	width: number
	isCustomTooltip?: boolean
	graphColors?: any
	locale?: ILocale
	preferences?: any
	speedData?: any
	xRange?: Range
	ref?: any
	ref2?: any
	setRef?: (r: any) => void
	setRef2?: (r: any) => void
	setRange?: (range: Range) => void
	onZoomUpdate?: () => void
	onZoomUpdate2?: () => void
	onDoubleClick?: () => void
	onDoubleClick2?: () => void
}

const updateChart = (id: number, chartsData: Array<IchartComponentsData>, min: number, max: number) => {
	chartsData.forEach((c) => {
		if (c.ref && id !== c.ref.id) {
			c.ref.zoomScale("x", { min, max }, "zoom")
		}
		if (c.ref2 && id !== c.ref2.id) {
			c.ref2.zoomScale("x", { min, max }, "zoom")
		}
	});
}

const onUpdate = (chart: any, chartsData: Array<IchartComponentsData>) => {
	const { min, max } = chart.scales.x
	
	debouncer(() => {
		updateChart(chart.id, chartsData, min, max)
	})
}

const resetZoomHandler = (chart: any, chartsData: Array<IchartComponentsData>) => {
	if (chart) {
		chart.resetZoom()
		setTimeout(() => {
			onUpdate(chart, chartsData)
		}, 50)
	}
}


export const useChartComponentsData = (
	graphColors: any, 
	locale: ILocale, 
	preferences: any, 
	speedData: any
) => {
    return useMemo(() => {
        const data: Array<IchartComponentsData> = [
            {
              key: DetailsComponents.HubVibration,
              width: 12
            },
            {
              key: DetailsComponents.TirePressure,
              width: 12
            },
            {
              key: DetailsComponents.AxleLoad,
              width: 12,
              isCustomTooltip: true
            },
            {
              key: DetailsComponents.LinePressure,
              width: 12
            },
            {
              key: DetailsComponents.TemperatureHistory,
              width: 12
            },
			{
				key: DetailsComponents.TemperatureChart,
				width: 12
			},
			{
				key: DetailsComponents.LocationChart,
				width: 12,
				setRef2: (r) => {
					const elem = data.find(el => el.key === DetailsComponents.LocationChart)
					if (elem) elem.ref2 = r
				},
				onDoubleClick2: () => {
					const elem = data.find(el => el.key === DetailsComponents.LocationChart)
					if (elem) resetZoomHandler(elem.ref2, data)
				},
				onZoomUpdate2: () => {
					const elem = data.find(el => el.key === DetailsComponents.LocationChart)
					if (elem) onUpdate(elem.ref2, data)
				}
			}
        ]

		data.forEach(el => {
			el.graphColors = graphColors
			el.locale = locale
			el.preferences = preferences
			el.speedData = speedData
			el.setRef = (r) => el.ref = r
			el.setRange = (range) => el.xRange = range
			el.onZoomUpdate = () => onUpdate(el.ref, data)
			el.onDoubleClick = () => resetZoomHandler(el.ref, data)
		})

		return data
    }, [graphColors, locale, preferences, speedData])
}

export const useChartsCommonRange = (chartData: Array<IchartComponentsData>) => {
	const [chartsRanges, setChartsRanges] = useState<any>({})

	chartData.forEach((el: IchartComponentsData) => {
		const chartRange = el.xRange
		
		if (chartRange) {
			if (chartRange !== chartsRanges[el.key]) setChartsRanges({ ...chartsRanges, [el.key]: chartRange })
		}
	})
	
	return useMemo(() => {
		const range: Range = { min: null, max: null }
		const values: Array<Range> = Object.values(chartsRanges)

		values.forEach((val) => {
			if (!val.min || !val.max) return 

			if (!range.min || range.min > val.min) {
				range.min = val.min
			}

			if (!range.max || range.max < val.max) {
				range.max = val.max
			}
		})

		// min - 1 hour and max + 1 hour or return null
		// convert to milliseconds
		return {
			xMin: range.min ? (range.min - 60*60) * 1000 : range.min,
			xMax: range.max ? (range.max + 60*60) * 1000 : range.max
		}
	}, [chartsRanges])
}