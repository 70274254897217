import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { CountryCodes } from '../../../enums'

export interface ICountryCode { label: string, code: string }

interface ICountryCodeDropdown {
    countryCode: ICountryCode,
    setCountryCode: (code: ICountryCode) => void
}

const stage = process.env.REACT_APP_STAGE || "dev" // get env

const CountryCodeDropdown: React.FC<ICountryCodeDropdown> = ({
    countryCode,
    setCountryCode
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    return (
      <div>
          <label htmlFor="country-codes">Select Country Code:</label>
          <Dropdown id="country-codes" className="country-code-dropdown" setActiveFromChild={true} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <DropdownToggle color="#525f7f" className="country-code-dropdown__label" caret>
                {`${countryCode.label} ${countryCode.code}`}
            </DropdownToggle>
            <DropdownMenu right>
                {CountryCodes.map((item) => {
                    if (item.label === "Latvia" && !["dev", "staging"].includes(stage)) return null
                    return <DropdownItem key={item.label} onClick={() => setCountryCode(item)}>{`${item.label} ${item.code}`}</DropdownItem>
                })}  
            </DropdownMenu>
          </Dropdown>
        </div>
    )
}

export default CountryCodeDropdown