import React, { useState, useEffect } from "react";
import VehicleInfo from "../../modules/VehicleInfo";
import SensorNodeInfo from "../../modules/SensorNodeInfo";
import RecentWarnings from "../../modules/RecentWarnings";
import TemperatureChart from "../../modules/TemperatureChart";
import DetailsMap from "../../modules/DetailsMap";
import TirePressureChart from '../../modules/TirePressureChart'
import HubVibration from '../../modules/HubVibration'
import AxleLoadChart from '../../modules/AxleLoadChart'
import ChartsControl from '../../modules/ChartsControl'
import LinePressureChart from "../../modules/LinePressureChart";
import TpmsStatusDiagram from "../../modules/TpmsStatusDiagram";
import PowerInputsChart from "../../modules/PowerInputsChart";

import { composeLocationFilterOptions } from '../../utils';
import {useActions} from '../../hooks/useActions'
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { useChartComponentsData, useChartsCommonRange } from "../../hooks/useChartHooks";
import { DetailsComponents } from "../../enums";

const DetailsBody = ({
  components,
  graphColors,
  locale,
  setAdminLogs,
  onGatewayLogsGet,
  customer_name,
}) => {
  const {
    fetchVibrations,
    fetchDetailsTemperatures,
    fetchTirePressure,
    fetchAxleLoad,
    fetchLinePressure,
    fetchSpeedData,
    postAcknowledgements,
    setRecentDetailsLimit,
    fetchDetailsGps,
    setRecentDetailsOffset,
    setRecentDetailsShowAll,
    fetchAssetTmpsStatus,
    setRecentDetailsFilter,
		fetchRecentDetails,
		fetchCustomerGeofences,
		setSelectedCustomer,
    resetDetailsReducer,
  } = useActions();

  const {
    details: {
      recentDetails,
      assetTpmsStatus,
      speedData,
      acknowledgementWarningCount,
    },
    details: { vibrations, tirePressure, axleLoad, linePressure, temperature },
    details: {
      detailsMap,
      vehicleInfo,
      assignedTpmsProfile,
      axlesCount,
      sensorNodeInfo,
      powerInputs
    },
    auth: { isSuperAdmin, isAdmin },
    preferences,
    healthColors,
    customerDefaults,
		systemDefaults,
		customers,
    geofences,
  } = useTypedSelector((state) => ({
    details: state.details,
    auth: state.auth,
    preferences: state.auth.preferences,
    healthColors: state.whitelabel.healthColors,
    graphColors: state.whitelabel.wheelColors,
    whiteLabelUrl: state.auth.whiteLabelUrl,
    locale: state.whitelabel.locale,
    customerDefaults: state.common.customerDefaults,
    systemDefaults: state.common.systemDefaults,
		adminInfo: state.getAdminLogs,
		customers: state.common.customers.data,
    geofences: state.geofences.customerGeofences,
  }));

  const { id, asset_type } = vehicleInfo.data;
  const [componentsWithChartsControl, setComponentsWithChartsControl] =
    useState([]);

  const chartsData = useChartComponentsData(
    graphColors,
    locale,
    preferences,
    speedData
  );
	const chartsCommonRange = useChartsCommonRange(chartsData);
	
	useEffect(() => {
		if (customer_name) {
			const customer = customers.find((el) => el.name === customer_name);
			setSelectedCustomer(customer)
			fetchCustomerGeofences()
		}
	},[customer_name, customers, fetchCustomerGeofences, setSelectedCustomer]);

  useEffect(() => {
    let array = [];
    components.map((c) => {
      if (c.component === "DetailsMap") {
        array.push(c);
        return array.push({ component: "ChartsControl", width: 12 });
      }
      return array.push(c);
    });

    array.push({ component: "PowerInputsChart", width: 12 })

    setComponentsWithChartsControl(array);
  }, [components]);

  useEffect(() => {
    if (vehicleInfo.data.id) {
      fetchSpeedData(7);
    }
  }, [vehicleInfo.data.id, fetchSpeedData]);

  useEffect(() => {
    // reset details reducer data on unmount (issue with common x range, tpms profile)
    return () => {
      resetDetailsReducer();
    };
  }, [resetDetailsReducer]);

  return componentsWithChartsControl.map(({ component, width }) => {
    switch (component) {
      case DetailsComponents.HubVibration:
        if (!vehicleInfo.data.subscription?.HUB_SENSOR && !isSuperAdmin)
          return null;
        const vibChart = chartsData.find((el) => el.key === component);

        return (
          <HubVibration
            {...vibChart}
            {...vibrations}
            {...chartsCommonRange}
            width={width}
            vibrationThreshold={customerDefaults?.smarthub?.vibration_treshold}
            healthColors={healthColors}
          />
        );

      case DetailsComponents.TirePressure:
        if (!vehicleInfo.data.subscription?.TIRE_SENSOR && !isSuperAdmin)
          return null;
        const pressureChart = chartsData.find((el) => el.key === component);

        return (
          <TirePressureChart
            {...pressureChart}
            {...tirePressure}
            {...chartsCommonRange}
            width={width}
            thresholds={customerDefaults?.tpms || systemDefaults?.tpms}
            assignedTpmsProfile={assignedTpmsProfile}
            healthColors={healthColors}
            assetType={asset_type}
          />
        );

      case DetailsComponents.TemperatureHistory:
        if (!vehicleInfo.data.subscription?.HUB_SENSOR && !isSuperAdmin)
          return null;
        const temperatureChart = chartsData.find((el) => el.key === component);

        return (
          <TemperatureChart
            {...temperatureChart}
            {...temperature}
            {...chartsCommonRange}
            width={width}
            temperatureTreshold={
              customerDefaults?.smarthub?.temperature_treshold ||
              systemDefaults?.smarthub?.temperature_treshold
            }
            healthColors={healthColors}
            assetType={asset_type}
          />
        );

      case DetailsComponents.AxleLoad:
        if (!vehicleInfo.data.subscription?.AXLE_LOAD && !isSuperAdmin)
          return null;
        const axleLoadChart = chartsData.find((el) => el.key === component);

        return (
          <AxleLoadChart
            {...axleLoadChart}
            {...axleLoad}
            {...chartsCommonRange}
            width={width}
            axlesCount={axlesCount}
          />
        );

      case DetailsComponents.LinePressure:
        if (
          (!vehicleInfo.data.subscription?.LINE_PRESSURE && !isSuperAdmin) ||
          asset_type !== "trailer"
        )
          return null;
        const linePChart = chartsData.find((el) => el.key === component);

        return (
          <LinePressureChart
            {...linePChart}
            {...linePressure}
            {...chartsCommonRange}
            width={width}
          />
        );
      case DetailsComponents.PowerInputsChart:
        if (!isSuperAdmin) return null;
        
        return (
          <PowerInputsChart
            key={component}
            locale={locale}
            width={width}
            speedData={speedData}
            timezone={preferences.timezone}
            {...powerInputs}
          />
        );
      case DetailsComponents.DetailsMap:
        if (!id) return null;
        return (
          <DetailsMap
            key={component}
            recentWarnings={recentDetails.data}
            {...detailsMap}
            width={width}
            locale={locale}
            geofences={geofences}
            preferences={preferences}
            vehicleInfo={vehicleInfo.data}
            onSelectDateRange={fetchDetailsGps}
          />
        );

      case DetailsComponents.VehicleInfo:
        return (
          <VehicleInfo
            key={component}
            {...vehicleInfo}
            width={width}
            locale={locale}
            preferences={preferences}
            isSuperAdmin={isSuperAdmin}
            gatewaysData={sensorNodeInfo.data.filter(
              (el) => el.hardware_type === "gateway"
            )}
            tpmsProfile={assignedTpmsProfile.name}
            onGatewayLogsGet={onGatewayLogsGet}
          />
        );
      case DetailsComponents.SensorNode:
        if (!id) return null;
        return (
          <SensorNodeInfo
            key={component}
            {...sensorNodeInfo}
            isSuperAdmin={isSuperAdmin}
            isAdmin={isAdmin}
            assetId={vehicleInfo.data && vehicleInfo.data.id}
            width={width}
            locale={locale}
            preferences={preferences}
            subscription={vehicleInfo.data.subscription}
            setAdminLogs={setAdminLogs}
            assetType={asset_type}
          />
        );
      case DetailsComponents.RecentWarnings:
        if (!id) return null;

        let sensorInfo = vehicleInfo.data?.sensor_info
          ? composeLocationFilterOptions(vehicleInfo.data?.sensor_info)
          : [];

        return (
          <RecentWarnings
            key={component}
            isAdmin={isAdmin}
            {...recentDetails}
            width={width}
            locale={locale}
            vin={vehicleInfo.data && vehicleInfo.data.vin}
            assetId={vehicleInfo.data && vehicleInfo.data.id}
            preferences={preferences}
            isSuperAdmin={isSuperAdmin}
            fetchRecentWarnings={fetchRecentDetails}
            warningsCount={recentDetails.totalCount}
            warningsLimit={recentDetails.limit}
            warningsOffset={recentDetails.offset}
            warningsShowAll={recentDetails.showAllWarnings}
            setRecentWarningsLimit={setRecentDetailsLimit}
            setRecentWarningsOffset={setRecentDetailsOffset}
            setRecentWarningsShowAll={setRecentDetailsShowAll}
            postAcknowledgements={postAcknowledgements}
            customerDefaults={customerDefaults}
            sensorInfo={sensorInfo}
            setRecentWarningsFilter={setRecentDetailsFilter}
            acknowledgementWarningCount={acknowledgementWarningCount}
          />
        );
      case DetailsComponents.TpmsStatusDiagram:
        if (!id) return null;

        const isTpmsAsset = !!vehicleInfo.data.sensor_info?.tpms?.length;

        if (!isTpmsAsset) return null;

        return (
          <TpmsStatusDiagram
            key={component}
            asset={vehicleInfo.data}
            fetchAssetTmpsStatus={fetchAssetTmpsStatus}
            assetTpmsStatus={assetTpmsStatus}
            preferences={preferences}
            customerDefaults={customerDefaults}
          />
        );
      case DetailsComponents.ChartsControl:
        if (!id) return null;
        return (
          <ChartsControl
            key={component}
            width={width}
            onSelectDate={(window) => {
              fetchSpeedData(window);
              fetchTirePressure(window);
              fetchVibrations(window);
              fetchDetailsTemperatures(window);

              if (asset_type === "tractor") {
                fetchAxleLoad(window);
              } else {
                fetchLinePressure(window);
                fetchAxleLoad(window);
              }
            }}
          />
        );
      default:
        return null;
    }
  });
};

export default DetailsBody
