import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Table, Button } from "reactstrap";
import RivataLoader from '../../components/RivataLoader'
import RivataModule from "../../components/RivataModule";
import WarningRow from "./WarningRow";
import SortTh from "../../components/RivataTable/Th";
import DatesDropdown from "../../components/DatesDropdown";
import AcknowledgeButton from "../../components/AcknowledgeButton";
import RivatePagination from '../../components/RivatePagination';
import LimitDropdown from '../../components/LimitDropdown';
import StatusAlert from '../../components/StatusAlert'
import RivataSearch from '../../components/RivataSearch'
import { useSearchFields, useVehicleSensorPositions } from './hooks'
import { SortOrder, WarningSearchColumnIds } from "../../enums";
import { compare} from "../../utils";
import { DEFAULT_WINDOW } from "../../constants";

const ColumnIds = {
  CHECK: "CHECK",
  TIME: "TIME",
  LOCATION: "LOCATION",
  TYPE: "TYPE",
  DESCRIPTION: "DESCRIPTION",
  SENSORTYPE: "SENSORTYPE"
};

const SortSelectionFnAsc = {
  TIME: (d1, d2) => compare(+new Date(d1.timestamp), +new Date(d2.timestamp)),
  LOCATION: (d1, d2) => compare(d1.position, d2.position),
  TYPE: (d1, d2) => compare(d1.type, d2.type),
  DESCRIPTION: (d1, d2) => compare(d2.type, d1.type),
};

const SortSelectionFnDesc = {
  TIME: (d1, d2) => compare(+new Date(d2.timestamp), +new Date(d1.timestamp)),
  LOCATION: (d1, d2) => compare(d2.position, d1.position),
  TYPE: (d1, d2) => compare(d2.type, d1.type),
  DESCRIPTION: (d1, d2) => compare(d1.type, d2.type),
};

// TODO: use the RivataTable
const RecentWarnings = ({
  isAdmin = false,
  isSuperAdmin,
  data,
  assetId,
  error,
  vin,
  intervals,
  isLoading,
  width,
  locale,
  isLoadingAcknowledgements,
  fetchRecentWarnings,
  warningsCount,
  warningsLimit,
  warningsOffset,
  postAcknowledgements,
  setRecentWarningsLimit,
  setRecentWarningsOffset,
  setRecentWarningsShowAll,
  warningsShowAll,
  preferences,
  showDatesDropdown = true,
  days = DEFAULT_WINDOW,
  healthColors,
  customerDefaults: { subscriptions },
  sensorInfo,
  setRecentWarningsFilter,
  acknowledgementWarningCount
}) => {
  const [sortOrder, setSortOrder] = useState(SortOrder.DESC);
  const [dateWindow, setDateWindow] = useState(days);
  const [currentSort, setCurrentSort] = useState(ColumnIds.TIME);
  const [checked, setChecked] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLastWarningAknowledged, setIsLastWarningAknowledged] = useState(false)
  const [sortedData, setSortedData] = useState(data);
  const [searchColumn, setSearchColumn] = useState(WarningSearchColumnIds.SENSOR_TYPE);
  const searchFields = useSearchFields(locale);

  const warningsData = useMemo(() => {
    return data.map((datum) => {
      datum.checked = false;
      datum.rowId = datum.key;
      return datum;
    });
  }, [data]);
  const warningPositions = useVehicleSensorPositions(sensorInfo)
  
  useEffect(() => {
    if (!assetId) return
    fetchRecentWarnings(days, warningsShowAll)// default
    
  }, [assetId, days,warningsShowAll,fetchRecentWarnings])

  useEffect(() => {
    const data = warningsData.map((datum) => {
      const { rowId } = datum;
      if (rowId in checked) {
        datum.checked = checked[rowId];
      } else {
        datum.checked = false;
      }
      // TO-DO Warning type: RIVS-643
      return datum;
    });

    let sortFn =
      sortOrder === SortOrder.ASC
        ? SortSelectionFnAsc[currentSort]
        : SortSelectionFnDesc[currentSort];

    setSortedData([...data].sort(sortFn));
  }, [warningsData, sortOrder, currentSort, checked]);

  const handleSort = (order, key) => {
    setCurrentSort(key);
    setSortOrder(order);
  };

  const resetPageStateHandler = useCallback(() => {
    setIsLastWarningAknowledged(false)
  }, [])

  return (
    <RivataModule
      title="Recent Warnings"
      width={width}
      locale={locale}
      error={error}
      filters={
        <>
          {showDatesDropdown && <DatesDropdown
            initial={parseInt(days)}
            onSelect={(date) => {
              if (dateWindow !== date) {
                setRecentWarningsOffset(0)
                setDateWindow(date);
                fetchRecentWarnings(date, warningsShowAll);
              }
            }}
            locale={locale}
          />}
          <RivataSearch
              onFilter={(filter) => {
                setRecentWarningsFilter(filter)
                fetchRecentWarnings(dateWindow, warningsShowAll)
              }}
              onSelectSearchField={(id) => setSearchColumn(id)}
              setRecentWarningsFilter={setRecentWarningsFilter}
              searchFields={searchFields}
              locale={locale}
              subscriptions={subscriptions}
              searchColumn={searchColumn}
              warningPositions={warningPositions}
              preventFilterClear={true}
            />
        </>
      }
      collapsible
    >
        <div className="d-flex">
          {isAdmin ? (
            <>
              {data.length ? <AcknowledgeButton
                btnClassName="mr-2 border mb-2"
                disabled={
                  isLoadingAcknowledgements || checked.length === 0
                }
                onAcknowledge={() => {
                  setSelectAll(false);
                  const postData = [...checked];
                  setChecked([]);
                  if (data.length === postData.length) {
                    setIsLastWarningAknowledged(true)
                    let newOffset = warningsOffset - warningsLimit

                    if (newOffset < 0) newOffset = 0

                    setRecentWarningsOffset(newOffset)
                  }
                  postAcknowledgements(postData, dateWindow)
                }}
              /> : null}
              {acknowledgementWarningCount !== 0 && <div>
              <Button
                size="md"
                className="mr-2 border mb-2"
                onClick={() => {
                  setRecentWarningsShowAll(!warningsShowAll);
                  fetchRecentWarnings(dateWindow, !warningsShowAll);
                }}
              >
                {warningsShowAll ? "Hide Acknowledged Warnings" : "Show Acknowledged Warnings"}
              </Button>
              </div>}
            </>
          ) : null}

          { data.length ?
            <>
            <RivatePagination
            assetsCount={warningsCount}
            pageLimit={warningsLimit}
            days={dateWindow}
            initialPage={warningsOffset / warningsLimit}
            onPageChange={(num) => {
              setRecentWarningsOffset(num)
              fetchRecentWarnings(dateWindow, warningsShowAll);
            }}
            decreaseCurrentPageNumber={isLastWarningAknowledged}
            setDecreaseCurrentPageNumber={resetPageStateHandler}
          />
          <LimitDropdown
            dateWindow={dateWindow}
            updateTable={(window, offset, limit) => {
              setRecentWarningsLimit(limit)
              setRecentWarningsOffset(offset)
              fetchRecentWarnings(window, warningsShowAll)
            }}
            pageLimit={warningsLimit}
            setPageLimit={(num) => {
              setRecentWarningsLimit(num) 
              setRecentWarningsOffset(0)
            }}
          />
          </>
           : null}
        </div>

      {isLoading ? (
        <RivataLoader/>
      ) : data.length ? (
          <>
            <Table hover size="sm" responsive>
              <thead>
                <tr>
                  {isAdmin ? (
                    <SortTh
                      id={ColumnIds.CHECK}
                      onCheck={(_id, checked) => {
                        const checkedAll = sortedData.reduce((accum, datum) => {
                          const { rowId, acknowledged } = datum;
                          let array = [...accum]

                          if (rowId && !Boolean(acknowledged)) {
                            if (checked) {
                              array.push(rowId)
                            }
                          }
                          return array;
                        }, []);
                        setChecked(checkedAll);
                        setSelectAll(checked);
                      }}
                      label={locale["All"] || "All"}
                      control="checkbox"
                      checked={selectAll}
                    />
                  ) : null}
                  <SortTh
                    id={ColumnIds.SENSORTYPE}
                    onSort={handleSort}
                    label={locale["SensorType"]}
                  />
                  <SortTh
                    id={ColumnIds.TIME}
                    onSort={handleSort}
                    label={locale["Time"]}
                  />
                  <SortTh
                    id={ColumnIds.LOCATION}
                    onSort={handleSort}
                    label={locale["Position"]}
                  />
                  <SortTh
                    id={ColumnIds.TYPE}
                    onSort={handleSort}
                    label={locale["WarningType"]}
                  />
                  <SortTh
                    id={ColumnIds.DESCRIPTION}
                    onSort={handleSort}
                    label={locale["Description"]}
                  />
                </tr>
              </thead>
              <tbody>
                {sortedData &&
                  sortedData.map((datum, i) => (
                    <WarningRow
                      key={`recent_warnings_table_rows_${i}`}
                      vin={vin}
                      isAdmin={isAdmin}
                      isSuperAdmin={isSuperAdmin}
                      days={dateWindow}
                      timezone={preferences.timezone}
                      unitsOfMeasurement={preferences.unitsOfMeasurement}
                      onCheckRowItem={(data) => {
                        const { rowId } = data;
                        let array = [...checked]
                        let rows = [...sortedData]
                        const idx = array.findIndex((el) => el === rowId ? true : false)
                        const index = rows.findIndex(el => el.key === rowId ? true : false)

                        if (idx > -1) {
                          array.splice(idx, 1)
                          
                          
                          rows[index].checked = true
                          
                        } else {
                          array.push(rowId)
                          rows[index].checked = false
                        }
                        setSortedData(rows)

                        setChecked(array);
                        setSelectAll(false);
                      }}
                      {...datum}
                      checkedItemsList={checked}
                      locale={locale}
                      healthColors={healthColors}
                    />
                  ))}
              </tbody>
            </Table>
          </>
        ) : (
          <StatusAlert customText="No Data" color="success" />
        )}
    </RivataModule>
  );
};

export default RecentWarnings;
