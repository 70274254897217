import moment from "moment";
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Form, Input } from "reactstrap";
import { DateRangeSelector } from "../../components/DateRangeCalendar";
import RivataLoader from "../../components/RivataLoader";
import HereMap from "../../components/RivataMap";
import RivataModule from "../../components/RivataModule";
import RivataTable from "../../components/RivataTable";
import { dateToEpoch } from "../../utils";
import { useColumns, useMapData, useTableRows } from "./hooks";
import PickTime from "../../components/TimePicker";
import "./styles.scss";

const TagDetails = ({
  locale,
  gpsData,
  gpsDataDetailed,
  isLoadingGpsData,
  isLoadingGpsDataDetailed,
  totalCount,
  limit,
  onPageChange,
  mac,
  filters,
  onFiltersSubmit,
  isLoadingFilters,
}) => {
  const [selectedDateRangeObj, setSelectedDateRangeObj] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedMac, setSelectedMac] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const isButtonDisabled =
    isLoadingGpsData || isLoadingGpsDataDetailed || isLoadingFilters;
  const columns = useColumns();
  const rows = useTableRows(gpsDataDetailed);
  const mapData = useMapData(gpsData);

  // preselect if mac was provided in query params
  useEffect(() => {
    if (mac && !selectedMac) {
      setSelectedMac(mac);
    }
  }, [mac, selectedMac]);

  useEffect(() => {
    const { startDate, endDate } = dateToEpoch();
    const dayEnd = new Date(0).setSeconds(endDate);
    const dayStart = new Date(0).setSeconds(startDate);

    setSelectedDateRangeObj({
      inputValue: `${moment(dayStart).format("MM/DD/YYYY")} ${
        dayEnd ? "- " + moment(dayEnd).format("MM/DD/YYYY") : ""
      }`,
      dayStart,
      dayEnd,
      startTime: "00:00",
      endTime: "23:59",
    });
  }, []);

  const toggleCalendar = (value = !showCalendar) => {
    setShowCalendar(value);
  };
const startPickerTime = selectedDateRangeObj.startTime ? selectedDateRangeObj.startTime : "00:00"
const endPickerTime = selectedDateRangeObj.endTime ? selectedDateRangeObj.endTime : "23:59"

  const dateRangeHandler = (date) => {
    const { startDate, endDate } = date.selection;
    setSelectedDateRangeObj({
      ...selectedDateRangeObj,
      inputValue: `${moment(startDate).format("MM/DD/YYYY")} ${
        endDate ? "- " + moment(endDate).format("MM/DD/YYYY") : ""
      }`,
      dayStart: startDate,
      dayEnd: endDate,
    });
  };

  const onFilterClearButtonPress = () => {
    setSelectedMac(null);
    setSelectedAsset(null);
    setSelectedLocation(null);
  };

  const getActualFilters = () => {
    const { startDate, endDate } = dateToEpoch(
      selectedDateRangeObj.dayStart,
      selectedDateRangeObj.dayEnd,
      selectedDateRangeObj.startTime,
      selectedDateRangeObj.endTime
    );
    let mac = null;
    let locationId = null;
    let assetId = null;

    if (selectedMac) {
      mac = selectedMac;
    }
    if (selectedAsset) {
      assetId = selectedAsset.id;
    }
    if (selectedLocation) {
      locationId = selectedLocation.id;
    }
    return {
      mac,
      locationId,
      assetId,
      startDate,
      endDate,
    };
  };

  const onFiltersSubmitButtonPress = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const actualFilters = getActualFilters();

    onFiltersSubmit(
      actualFilters.mac,
      actualFilters.locationId,
      actualFilters.assetId,
      actualFilters.startDate,
      actualFilters.endDate,
      false
    );
  };

  const onPageChangeClick = (offset) => {
    onPageChange(offset);
    const actualFilters = getActualFilters();

    onFiltersSubmit(
      actualFilters.mac,
      actualFilters.locationId,
      actualFilters.assetId,
      actualFilters.startDate,
      actualFilters.endDate,
      true
    );
  };


  return (
    <>
      <RivataModule title={`Tags gps data`} width={12} locale={locale}>
        {isLoadingGpsData && <RivataLoader />}
        <HereMap
          locations={mapData}
          locale={locale}
          hideInfoBubble={true}
          markerType="circle"
          showLastLocationAsPin={true}
        />
      </RivataModule>
      <RivataModule
        title={`Tags detailed gps data`}
        width={12}
        locale={locale}
        filters={
          <Form
            className="d-inline-flex align-items-start time_input_style"
            onSubmit={onFiltersSubmitButtonPress}
          >
            <div className="position-relative d-flex range-selector__wrapper">
              <Input
                className="map-date-range"
                id="range_input"
                onClick={(e) => {
                  e.persist();
                  toggleCalendar();
                }}
                value={selectedDateRangeObj.inputValue}
                readOnly
              />
              <DateRangeSelector
                selectionRange={{
                  startDate: selectedDateRangeObj.dayStart,
                  endDate: selectedDateRangeObj.dayEnd,
                  key: "selection",
                }}
                handleSelect={dateRangeHandler}
                toggleCalendar={toggleCalendar}
                visible={showCalendar}
              />
              <label>From:</label>
              <PickTime
                t={startPickerTime}
                onChange={(val) =>
                    setSelectedDateRangeObj({ ...selectedDateRangeObj, startTime: val })
                }
              />
              <label>To:</label>
              <PickTime
                t={endPickerTime}
                onChange={(val) =>
                    setSelectedDateRangeObj({ ...selectedDateRangeObj, endTime: val })
                }
              />
            </div>
            <Input
              className="mac_input"
              disabled={isButtonDisabled}
              placeholder="Select Mac"
              value={selectedMac}
              onChange={(ev) => setSelectedMac(ev.target.value)}
            />
            <Typeahead
              className="typeahead"
              id="asset_search"
              onChange={(selected) => {
                if (selected.length) {
                  setSelectedAsset(selected[0]);
                }
              }}
              selected={selectedAsset ? [selectedAsset] : []}
              labelKey="vin"
              options={filters.asset_filter_data}
              onInputChange={() => setSelectedAsset(null)}
              placeholder={"Select Asset VIN"}
              disabled={isButtonDisabled}
            />
              <Typeahead
              className="typeahead"
              id="asset_search"
              onChange={(selected) => {
                if (selected.length) {
                  setSelectedAsset(selected[0]);
                }
              }}
              selected={selectedAsset ? [selectedAsset] : []}
              labelKey="name"
              options={filters.asset_filter_data}
              onInputChange={() => setSelectedAsset(null)}
              placeholder={"Select Asset Name"}
              disabled={isButtonDisabled}
            />
            <Typeahead
              className="typeahead"
              id="location_search"
              onChange={(selected) => {
                if (selected.length) {
                  setSelectedLocation(selected[0]);
                }
              }}
              selected={selectedLocation ? [selectedLocation] : []}
              labelKey="name"
              options={filters.location_filter_data}
              onInputChange={() => setSelectedLocation(null)}
              placeholder={"Select Location"}
              disabled={isButtonDisabled}
            />
            <Button
              type="reset"
              className="btn btn-clear"
              onClick={() => onFilterClearButtonPress()}
              disabled={isButtonDisabled}
            >
              Clear
            </Button>
            <Button disabled={isButtonDisabled}>{"Submit"}</Button>
          </Form>
        }
      >
        <RivataTable
          showPagination={true}
          assetsCount={totalCount}
          onPageChange={onPageChangeClick}
          pageLimit={limit}
          columns={columns}
          rows={rows}
          isLoading={isLoadingGpsDataDetailed}
        />
      </RivataModule>
    </>
  );
};

export default TagDetails;
