import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnyObject } from "yup/lib/types";
import { UnitsOfMeasurement } from "../../enums";

import GeofenceRenderer from "../../modules/Geofences/renderer/GeofenceRenderer";

import { IGpsData } from "./types";
import {
  initMap,
  addLocationButton,
  startMarkersClustering,
  updateBaseLayer,
  composeBubble,
} from "./mapUtils";

import "./styles.scss";

interface IRivataMap {
  vin: string;
  locations: Array<IGpsData>;
  layerType: string;
  unitsOfMeasurement?: string;
  geofencesVisible: boolean;
  geofences: Array<IGeofence>;
  isWarningDetailsPage?: boolean;
}

const RivataMap: React.FC<IRivataMap> = ({
  vin,
  locations,
  layerType,
  unitsOfMeasurement = UnitsOfMeasurement.imperial,
  geofencesVisible,
  geofences,
  isWarningDetailsPage = false,
}) => {
  const mapRef = useRef<any>();

  const [hMap, setHMap] = useState<any>(null);
  const [ui, setUi] = useState(null);
  const [bubble, setBubble] = useState<any>(null);
  const [defaultLayers, setDefaultLayers] = useState<AnyObject>({});
  const [markersLayer, setMarkersLayer] = useState<any>(null);

  const addInfoBubble = useCallback(
    (e: any) => {
      if (!bubble) return;
      const data: IGpsData = e.target.getData().latestPoint;

      composeBubble(e, data, bubble, unitsOfMeasurement);
    },
    [bubble, unitsOfMeasurement]
  );

  useEffect(() => {
    // map initialization
    const { defaultLayers, hMap, ui, bubble } = initMap(
      mapRef.current,
      unitsOfMeasurement
    );

    setHMap(hMap);
    setDefaultLayers(defaultLayers);
    setUi(ui);
    setBubble(bubble);
  }, [unitsOfMeasurement]);

  useEffect(() => {
    if (!ui) return;
    addLocationButton(hMap, ui, locations[locations.length - 1]);
  }, [hMap, ui, locations]);

  useEffect(() => {
    updateBaseLayer(layerType, hMap, defaultLayers);
  }, [layerType, hMap, defaultLayers]);

  useEffect(() => {
    if (markersLayer) hMap.removeLayer(markersLayer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, hMap]);

  useEffect(() => {
    if (!hMap || !locations.length) return;

    const clusteringLayer = startMarkersClustering(
      locations,
      hMap,
      isWarningDetailsPage,
      addInfoBubble
    );

    setMarkersLayer(clusteringLayer);
  }, [locations, hMap, isWarningDetailsPage, addInfoBubble]);

  return (
    <div
      className="map"
      id="rivata-map"
      ref={mapRef}
      style={{ height: "400px", width: "100%" }}
    >
      <GeofenceRenderer
        map={hMap}
        ui={ui}
        geofences={geofences}
        geofencesVisible={geofencesVisible}
        vin={vin}
      />
    </div>
  );
};

export default RivataMap;
