import React, { useMemo, useEffect } from "react";
import RivataLoader from '../../components/RivataLoader'
import RivataModule from "../../components/RivataModule";
import { composeLineChartData, composeAnnotations } from "./utils";
import { hasData } from "../../utils";
import LineChartGroup from "../../components/LineChartGroup";
import StatusAlert from "../../components/StatusAlert";
import { UnitsOfMeasurement } from '../../enums'

const LocationChart = ({
  isLoading,
  data,
  error,
  width,
  locale,
  preferences: { unitsOfMeasurement = UnitsOfMeasurement.imperial, timezone },
  legendPostion,
  gpsColors,
  timestamp,
  xMax,
  xMin,
  setRef,
  setRef2,
  onZoomUpdate,
  onZoomUpdate2,
  onDoubleClick,
  onDoubleClick2,
  setRange,
  healthColors
}) => {
  const chartData = useMemo(() => {
    if (data && data.length && gpsColors) {
      return composeLineChartData(data, unitsOfMeasurement, gpsColors)
    }
    return null
  }, [data, unitsOfMeasurement, gpsColors])

  useEffect(() => {
    if (chartData) setRange({ min: chartData.elevation.xMin, max: chartData.elevation.xMax })
    else setRange({ min: null, max: null })
  }, [chartData, setRange])

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title="Speed, Elevation"
      width={width}
      locale={locale}
      error={error}
      collapsible
    >
      {isLoading ? (
        <RivataLoader/>
      ) : (
        (hasData(data)) ?
          <LineChartGroup chartsData={[
            {
              legendPosition: legendPostion,
              timezone,
              id: "speed",
              label: `Speed (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "mph" : "kmh"})`,
              legendLabel:  `Speed (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "mph" : "kmh"})`,
              data: chartData.speed,
              height: 300,
              displayX: false,
              annotations: composeAnnotations("speed", +timestamp, healthColors),
              xMax,
              xMin,
              setRef,
              onZoomUpdate,
              onDoubleClick,
            },
            {
              legendPosition: legendPostion,
              timezone,
              id: "elevation",
              label: `Elevation (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "ft" : "m"})`,
              legendLabel: `Elevation (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "ft" : "m"})`,
              data: chartData.elevation,
              height: 300,
              displayX: true,
              annotations: composeAnnotations("elevation", +timestamp, healthColors),
              xMax,
              xMin,
              setRef: setRef2,
              onZoomUpdate: () => onZoomUpdate2(),
              onDoubleClick: () => onDoubleClick2(),
            }
          ]}
          /> : <StatusAlert customText="No Data" color="success"/>
      )}
    </RivataModule>
  );
};

export default LocationChart;
