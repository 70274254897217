import { ProvisionActionTypes, ProvisionReducer, ProvisionAction } from './types'

  
const initialState: ProvisionReducer = {
    assets: {
        loading: false,
        data: [],
        totalCount: 0,
        pageLimit: 30,
        pageOffset: 0,
        sortOptions: {column: "asset_name", direction: "asc"},
        error: {
            statusCode: 0,
            message: '',
        }
    },
    tpmsAssets: {
        loading: false,
        data: [],
        totalCount: 0,
        pageLimit: 30, 
        pageOffset: 0,
        sortOptions: {},
        filter: {},
        error: {
            statusCode: 0,
            message: '',
        }
    },
    users: {
        loading: false,
        data: [],
        totalCount: 0,
        pageLimit: 30, 
        pageOffset: 0,
        sortOptions: {},
        error: {
            statusCode: 0,
            message: '',
        }
    },
    tpmsProfiles: []
}

  
const provision = (state = initialState, action: ProvisionAction) => {
    switch (action.type) {
        // assets
        case ProvisionActionTypes.LOADING_ASSETS:
            return { ...state, assets: { ...state.assets, loading: action.payload } }
        case ProvisionActionTypes.SET_ASSETS:
            return { ...state, assets: { ...state.assets, data: action.payload } }
        case ProvisionActionTypes.SET_ASSETS_COUNT:
            return { ...state, assets: { ...state.assets, totalCount: action.payload } }
        case ProvisionActionTypes.SET_ASSETS_LIMIT:
            return { ...state, assets: { ...state.assets, pageLimit: action.payload } }
        case ProvisionActionTypes.SET_ASSETS_OFFSET:
            return { ...state, assets: { ...state.assets, pageOffset: action.payload } }
        case ProvisionActionTypes.SET_ASSETS_SORT_OPTIONS:
            return { ...state, assets: { ...state.assets, sortOptions: action.payload } }
        case ProvisionActionTypes.SET_ASSETS_ERROR:
            return { ...state, assets: { ...state.assets, error: action.payload } }

        
        // tpms assets
        case ProvisionActionTypes.LOADING_TPMS_ASSETS:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, loading: action.payload } }
        case ProvisionActionTypes.SET_TPMS_ASSETS:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, data: action.payload } }
        case ProvisionActionTypes.SET_TPMS_ASSETS_COUNT:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, totalCount: action.payload } }
        case ProvisionActionTypes.SET_TPMS_ASSETS_LIMIT:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, pageLimit: action.payload } }
        case ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, pageOffset: action.payload } }
        case ProvisionActionTypes.SET_TPMS_ASSETS_SORT_OPTIONS:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, sortOptions: action.payload } }
        case ProvisionActionTypes.SET_TPMS_ASSETS_FILTER:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, filter: action.payload } }
        case ProvisionActionTypes.SET_TPMS_ASSETS_ERROR:
            return { ...state, tpmsAssets: { ...state.tpmsAssets, error: action.payload } }


        // users
        case ProvisionActionTypes.LOADING_USERS:
            return { ...state, users: { ...state.users, loading: action.payload } }
        case ProvisionActionTypes.SET_USERS:
            return { ...state, users: { ...state.users, data: action.payload } }
        case ProvisionActionTypes.SET_USERS_COUNT:
            return { ...state, users: { ...state.users, totalCount: action.payload } }
        case ProvisionActionTypes.SET_USERS_LIMIT:
            return { ...state, users: { ...state.users, pageLimit: action.payload } }
        case ProvisionActionTypes.SET_USERS_OFFSET:
            return { ...state, users: { ...state.users, pageOffset: action.payload } }
        case ProvisionActionTypes.SET_USERS_SORT_OPTIONS:
            return { ...state, users: { ...state.users, sortOptions: action.payload } }
        case ProvisionActionTypes.SET_USERS_ERROR:
            return { ...state, users: { ...state.users, error: action.payload } }

        
        // tpms profiles
        case ProvisionActionTypes.SET_TPMS_PROFILES:
            return { ...state, tpmsProfiles: action.payload }

        case ProvisionActionTypes.RESET_PROVISION_PAGE_REDUCER:
            return { ...initialState }
        
        default:
            return state
    }
}


export default provision
  