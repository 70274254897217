import { getColor, getClearLabel } from "../../utils"
import { getDateRange } from '../../utils/chartUtils'

export const composeLineChartData = (data, graphColors, locale, isCustomLabelFormat) => {
  const datasets = []

  data.forEach((item, i) => {
    const line = { label: item.label, data: [] }
    item.data.forEach((itemData) => {
      const level = itemData.value;
      const y = +level.toFixed(2)

      const obj = {
        x: itemData.chartTAxis,
        y,
        displayDate: itemData.displayDate,
        displayValue: isCustomLabelFormat ? `Level: ${y}` : `Health: ${y}`,
      }

      line.data.push(obj)
    })
    
    line.data = line.data.sort((d1, d2) => +new Date(d1.displayDate) - +new Date(d2.displayDate))
    line.label = line.label !== "NONE" && line.label !== "UNKNOWN" ? getClearLabel(line.label) : `Sensor: ${line.id}`
    const currentColor = getColor(line.label, graphColors)
    line.fill = false
    line.backgroundColor = currentColor
    line.borderColor = currentColor
    line.borderWidth = 2

    datasets.push(line)
  })

  const range = getDateRange(datasets)

  return { datasets, xMax: range.max, xMin: range.min }
}