import { convertDataEpochToDate } from "../../utils"

export const generateCommandDetails = (commands) => {
  const getStatus = (command) => {
    const { ready_to_be_sent, processed, delivery_attempts_count, delivery_confirmed_at_epoch } = command

    if (processed && !delivery_confirmed_at_epoch && delivery_attempts_count && delivery_attempts_count > 0) return "command_sending_in_progress"

    if (processed) return "processed"

    if (!ready_to_be_sent && !processed) return "generated"

    if (ready_to_be_sent && !processed) return "ready to be sent"

    return "-"
  }

  const getPayloadDetails = (command) => {
    let parameters = []

    if (command.payload.request?.parameters) {
      parameters = command.payload?.request?.parameters
    } else if (Array.isArray(command.payload)) {
      parameters = command.payload[0]?.jvalue?.request?.parameters
    }

    return parameters?.length
      ? parameters.map((parameter) => ({
        ...parameter,
        value: decodeURIComponent(escape(window.atob(parameter.value)))
      }))
      : []
  }

  commands.forEach((command) => {
    command.status = getStatus(command)
    command.payloadDetails = getPayloadDetails(command)
  })
}

export const parseLog = (log) => {
  let message = "";
  if (log && log.lines) {
    const lines = log.lines.slice().sort(c => c.epoch).reverse();
    lines.forEach((line) => {
      message += `<b>${convertDataEpochToDate(line.epoch, null, null, true, false, true)}</b>: ${line.message}`;
      message += "<br/><br/>";
    })
  }
  return message;
}