import React, { useMemo, useEffect } from 'react'
import moment from 'moment-timezone'

import RivataModule from '../../components/RivataModule'
import RivataLineChart from '../../components/RivataLineChart'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'

import { UnitsOfMeasurement } from "../../enums"

import { psiToBar } from '../../utils'
import { composeChartData, composeAnnotations } from './utils'
import { getMotionAnnotations, composeCustomTooltip } from '../../utils/chartUtils'

const customTooltip = composeCustomTooltip({
    displayColors: false,
    bgColor: true,
    beforeLabel: true,
    afterLabel: true
})

const LinePressureChart = ({
    data,
    error,
    isLoading,
    width,
    locale,
    preferences: { unitsOfMeasurement = UnitsOfMeasurement.imperial, timezone },
    xMax,
    xMin,
    setRange,
    setRef,
    timestamp,
    onZoomUpdate,
    onDoubleClick,
    speedData,
    healthColors
}) => {
    const threshold = unitsOfMeasurement === UnitsOfMeasurement.imperial ? 60 : psiToBar(60) // for this chart always should be 60 psi - desc in jira RIVS-858
    const id = "line pressure"

    const chartData = useMemo(() => {
        if (!data.length) return null
        return composeChartData(data, unitsOfMeasurement)
    }, [data, unitsOfMeasurement])

    useEffect(() => {
        if (chartData) setRange({ min: chartData.xMin, max: chartData.xMax })
        else setRange({ min: null, max: null })
    }, [chartData, setRange])

    const assetMotionAnnotations = useMemo(() => {
        return getMotionAnnotations(speedData, id);
    }, [speedData]);

    return (
        <RivataModule
            fullScreenModalModeEnabled
            title="Line Pressure"
            width={width}
            locale={locale}
            error={error}
            collapsible
        >
            {isLoading ? (
                <RivataLoader/>
            ) : chartData ? (
                <RivataLineChart
                    id={id}
                    data={chartData}
                    height={400}
                    legendLabel={`Pressure (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "psi" : "bar"})`}
                    customTooltip={customTooltip}
                    annotations={composeAnnotations(id, threshold, timestamp, healthColors)}
                    setRef={setRef}
                    onZoomUpdate={onZoomUpdate}
                    onDoubleClick={onDoubleClick}
                    xMax={xMax}
                    xMin={xMin}
                    threshold={threshold}
                    xAxisLabel={`Date (${moment().tz(timezone).format("z")})`}
                    assetMotionAnnotations={assetMotionAnnotations}
                />
            ) : (
                <StatusAlert customText="No Data" color="success"/>
            )}
        </RivataModule>
    )
}

export default LinePressureChart