interface ISensor {
  mac: string;
  position?: string;
}

interface IHardware {
  gateways?: Array<{ esn: number }>;
  smarthub_sensors?: Array<ISensor>;
  tpms?: Array<ISensor>;
  pneumatics?: {
    axle_load?: ISensor;
    line_pressure?: ISensor;
  };
}

const getShortMac = (mac: string) => mac?.substring(12) || "";

const validShortMac = (mac: string) => mac?.length === 5;

export const findShortMacDuplicates = (hardware: IHardware) => {
  const shortMacError = {
    value: false,
    text: "",
  };

  let sensors: Array<ISensor> = [];

  if (hardware.tpms?.length) sensors = [...hardware.tpms];

  if (hardware.pneumatics?.axle_load) {
    sensors.push({
      mac: hardware.pneumatics?.axle_load.mac,
      position: "AXLE_LOAD",
    });
  }

  if (hardware.pneumatics?.line_pressure) {
    sensors.push({
      mac: hardware.pneumatics?.line_pressure.mac,
      position: "LINE_PRESSURE",
    });
  }

  for (let i = 0; i < sensors.length; i++) {
    const firstSensor = sensors[i];
    const firstShortMac = getShortMac(firstSensor.mac);

    for (let k = 0; k < sensors.length; k++) {
      const secondSensor = sensors[k];
      const secondShortMac = getShortMac(secondSensor.mac);

      if (i !== k) {
        if (validShortMac(firstShortMac) && validShortMac(secondShortMac) && firstShortMac === secondShortMac) {
          shortMacError.value = true;
          shortMacError.text = `You installed two sensors with the same short MAC on positions 
          "${firstSensor.position}" and "${secondSensor.position}".\n Please use different sensor.`;
          break;
        }
      }
    }

    if (shortMacError.value) break;
  }

  return shortMacError;
};

export const checkSensorsCount = (hardware: IHardware) => {
  const maxSensorCountPerGateway = 10;
  const gatewaysCount = hardware.gateways?.length || 0;
  let sensorsCount = 0;

  if (hardware.tpms) sensorsCount += hardware.tpms.length;

  if (hardware.pneumatics?.axle_load) sensorsCount += 1;

  if (hardware.pneumatics?.line_pressure) sensorsCount += 1;

  if (!gatewaysCount) return "Please go back and provision a gateway first";

  if (gatewaysCount === 1 && sensorsCount > maxSensorCountPerGateway) {
    return `Only 10 TPMS sensors can be provisioned per gateway, please either remove ${sensorsCount - maxSensorCountPerGateway} sensors, or provision an additional Gateway first and then add the additional TPMS sensors`;
  }

  if (gatewaysCount === 2 && sensorsCount > maxSensorCountPerGateway * 2) {
    return `The maximum number of TPMS sensors that are supported in this configuration is 20. Please limit provisioning to 20 or fewer TPMS sensor`;
  }
};
