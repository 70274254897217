import { LOADING_DOCUMENTS, SET_DOCUMENTS, SET_DOCUMENTS_ERROR } from "../actionTypes";
import api from "../../services/api";
import { getToken } from "../../utils";

export const loadingDocuments = (isLoading) => ({
  type: LOADING_DOCUMENTS,
  payload: isLoading,
});

export const setDocuments = (documents) => ({
  type: SET_DOCUMENTS,
  payload: documents,
});

export const setDocumentsError = (error) => ({
  type: SET_DOCUMENTS_ERROR,
  payload: error,
});

export function fetchDocuments(params) {
  return function (dispatch, getState) {
    if (!params?.refetch) dispatch(loadingDocuments(true));

    return api
      .getDocuments(getToken(getState))
      .then((documents) => {
        dispatch(setDocuments(documents));
      })
      .catch(() => dispatch(setDocumentsError(true)))
      .finally(() => {
        if (!params?.refetch) dispatch(loadingDocuments(false));
      });
  };
}

export function addNewDocument(title) {
  return function (dispatch, getState) {
    const documentsStore = getState().documents;

    if (documentsStore?.documents) {
      documentsStore.documents.push({ file_name: title, url: "" });
      dispatch(setDocuments(documentsStore.documents));
    }
  };
}

export function deleteDocument(title) {
  return function (dispatch, getState) {
    return api.deleteDocument(title, getToken(getState)).then(() => {
      const documentsStore = getState().documents;

      if (documentsStore?.documents) {
        dispatch(setDocuments(documentsStore.documents.filter((document) => document.file_name !== title)));
      }
    });
  };
}
