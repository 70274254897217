import React, { useMemo } from "react";

import tractor from "../../../assets/images/tpms_status_diagram/Tractor.svg";

import { getPositions, getAxles, getIntervals } from "../utils";

import Axle from "../axle/Axle";

import PositionBubble from "../common/PositionBubble";

const Tractor = ({ height, sensors = [], pressureUnit, getTireData, setImageLoaded, timezone, locale }) => {
  const axleHeight = 250;
  const axleInterval = 100;
  const axleLeftMargin = 20;

  const tractorPositions = useMemo(
    () =>
      getPositions(
        ["DRIVE_LEFT", "DRIVE_RIGHT"],
        [
          { key: "STEER_LEFT", axle: 0 },
          { key: "STEER_RIGHT", axle: 0 },
        ]
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sensors]
  );

  const axles = useMemo(() => getAxles(sensors, tractorPositions, "tractor"), [sensors, tractorPositions]);

  const intervals = useMemo(() => getIntervals(axles, axleInterval, axleLeftMargin), [axles]);

  const rearWidth = useMemo(() => {
    let width = axles.filter((axle) => axle.order).length * 100;

    if (width < 215) width = 215;

    return width;
  }, [axles]);

  const steerAxle = axles.find((axle) => axle.order === 0);

  return (
    <div className="d-flex">
      <div className="d-flex align-items-center" style={{ position: "relative", height: `${height}px` }}>
        <div className="tractor-rear" style={{ width: `${rearWidth + 15}px`, height: "70px" }}></div>

        {axles.map((axle, index) => {
          if (axle.order === 0) return null;

          return (
            <Axle
              key={axle.order}
              axle={axle}
              axleType="doubleDriveAxle"
              style={{ position: "absolute", left: `${intervals[index]}px` }}
              height={axleHeight}
              pressureUnit={pressureUnit}
              getTireData={getTireData}
              timezone={timezone}
              locale={locale}
            />
          );
        })}

        {axles.map((axle, index) => {
          if (axle.order === 0) return null;

          return <PositionBubble key={axle.positionBubble} label={axle.positionBubble} style={{ left: `${intervals[index] + 3.5}px` }} />;
        })}
      </div>

      <div className="d-flex align-items-center" style={{ position: "relative", height: `${height}px` }}>
        <img src={tractor} alt="tractor" height={height} onLoad={() => setImageLoaded(true)} />

        {steerAxle && (
          <>
            <Axle
              key={steerAxle.order}
              axle={steerAxle}
              axleType="singleSteerAxle"
              style={{ position: "absolute", left: "250px" }}
              height={axleHeight}
              pressureUnit={pressureUnit}
              getTireData={getTireData}
              timezone={timezone}
              locale={locale}
            />
            <PositionBubble label={steerAxle.positionBubble} style={{ left: "253.5px" }} />
          </>
        )}
      </div>
    </div>
  );
};

export default Tractor;
