import React, { useState } from "react";

import moment from "moment-timezone";

import { Tooltip } from "reactstrap";

import "./style.scss";

const borderColor = {
  criticalWarning: { background: "#ed7624" },
  warning: { background: "#fed925" },
  normal: { background: "#6bbe4c" },
  noStatus: { background: "#6D6E71"},
  unknown: { background: "transparent"}
};

const getLayerPosition = (tirePosition, axleType) => {
  const driveAxle = axleType === "doubleDriveAxle";

  if (tirePosition.includes("STEER_LEFT")) {
    return { top: 0 };
  }

  if (tirePosition.includes("STEER_RIGHT")) {
    return { bottom: 0 };
  }

  if (tirePosition.includes("LEFT") && tirePosition.includes("OUTER")) {
    return { top: 0 };
  }

  if (tirePosition.includes("LEFT") && tirePosition.includes("INNER")) {
    return { top: driveAxle ? "41px" : "39px" };
  }

  if (tirePosition.includes("RIGHT") && tirePosition.includes("OUTER")) {
    return { bottom: 0 };
  }

  if (tirePosition.includes("RIGHT") && tirePosition.includes("INNER")) {
    return { bottom: driveAxle ? "41px" : "39px" };
  }
};

const TireLayer = ({
  tirePosition,
  tirePositionLabel,
  tireStatus,
  tirePressure,
  pressureUnit,
  description,
  coldInflationPressure,
  latestTirePressureMeasurement,
  axleType,
  timezone,
  isNoStatusSensor
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const layerPosition = getLayerPosition(tirePosition, axleType);

  const getTextWithUnits = (value) => {
    if (isNoStatusSensor){
      return value;
    }
    return `${value} ${pressureUnit}`
  }

  return (
    <div
      id={tirePosition}
      className={`tire-layer ${tireStatus} ${axleType}`}
      style={{ ...layerPosition, boxShadow: `0 0 2px ${borderColor[tireStatus].background}` }}
    >
      <span>{tirePressure}</span>
      {tireStatus !== 'unknown' && <Tooltip
        innerClassName={`tire-tooltip ${tireStatus}`}
        placement="top"
        toggle={toggle}
        isOpen={tooltipOpen}
        target={tirePosition}
        autohide={false}
        hideArrow={true}
      >
        {description} <br />
        {tirePositionLabel} <br />
        Pressure: {getTextWithUnits(tirePressure)} <br />
        CIP: {getTextWithUnits(coldInflationPressure)}  <br />
        Latest Measurement: {latestTirePressureMeasurement} ({moment().tz(timezone).format("z")})
      </Tooltip>}
    </div>
  );
};

export default TireLayer;
