import moment from 'moment'
import React, { useState, useRef } from 'react'
import { Input, Form, Button } from 'reactstrap';
import { DateRangeSelector } from '../../components/DateRangeCalendar'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import { dateToEpoch } from '../../utils'
import { useTableColumns, useTableRows } from './hooks'
import classes from "./style.module.scss";

const ProvisionHistory = ({
    isLoading,
    data,
    error,
    locale = {},
    fetchProvisionHistory
}) => {
    const [selectedDateRangeObj, setSelectedDateRangeObj] = useState({});
    const [showCalendar, setShowCalendar] = useState(false);
    const [isBtnsDisabled, setIsBtnsDisabled] = useState(true);
    const columns = useTableColumns();
    const rows = useTableRows(data);
    const formRef = useRef();


    const dateRangeHandler = (date) => {
        setIsBtnsDisabled(false);
        const { startDate, endDate } = date.selection
        setSelectedDateRangeObj({
            ...selectedDateRangeObj,
            inputValue: `${moment(startDate).format("MM/DD/YYYY")} ${endDate ? "- " + moment(endDate).format("MM/DD/YYYY") : ""}`,
            dayStart: startDate,
            dayEnd: endDate
        })
    }

    const toggleCalendar = (value = !showCalendar) => {
        setShowCalendar(value)
    }


    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { startDate, endDate } = dateToEpoch(selectedDateRangeObj.dayStart, selectedDateRangeObj.dayEnd);
        fetchProvisionHistory(startDate, endDate);
    }


    const onDefaultProvisionList = () => {
        onResetForm()
        fetchProvisionHistory()
    }

    const onResetForm = () => {
        setSelectedDateRangeObj({});
        formRef.current.reset();
        setIsBtnsDisabled(true);
    };

    return (
        <RivataModule
            title="Provision History"
            locale={locale}
            marginTop={0}
            error={error}
            filters={<Form
                className="d-inline-flex align-items-start time_input_style"
                onSubmit={onSubmit}
                innerRef={formRef}
            >
                <div className="position-relative d-flex range-selector__wrapper">
                    <Input
                        className={classes.mapDateRange}
                        id="range_input"
                        placeholder="Pleases choose your dates"
                        onClick={(e) => {
                            e.persist();
                            toggleCalendar();
                        }}
                        value={selectedDateRangeObj.inputValue}
                        readOnly

                    />
                    <DateRangeSelector
                        selectionRange={{ startDate: selectedDateRangeObj.dayStart, endDate: selectedDateRangeObj.dayEnd, key: 'selection' }}
                        handleSelect={dateRangeHandler}
                        toggleCalendar={toggleCalendar}
                        visible={showCalendar}
                    />
                </div>
                <Button disabled={isLoading || isBtnsDisabled}>{"Submit"}</Button>
                <Button
                    type="button"
                    color="danger"
                    disabled={isBtnsDisabled || isLoading}
                    onClick={onDefaultProvisionList}
                >
                    {"Clear"}
                </Button>
            </Form>
            }
        >
            <RivataTable
                isLoading={isLoading}
                columns={columns}
                rows={rows}
            />

        </RivataModule>
    )
}

export default ProvisionHistory