import React from "react";
import RivataDropdown from "../RivataDropdown";

const dropdownItems = [
  {
    id: 4,
    label: "One Third",
  },
  {
    id: 6,
    label: "One Half",
  },
  {
    id: 8,
    label: "Two Thirds",
  },
  {
    id: 12,
    label: "Full Width",
  },
];

// probably connected: select makes call to add, remove makes call to remove
const WidthDropdown = ({ onSelect, selected }) => {
  return (
    <RivataDropdown
      size="sm"
      items={dropdownItems}
      onSelect={onSelect}
      selected={selected}
    />
  );
};

export default WidthDropdown;
