import { useMemo } from 'react'

import { IRow } from '../../components/RivataGrid/index'
import { ReportTypes, UnitsOfMeasurement } from '../../enums'
import { convertDataEpochToDate } from '../../utils'
import { dvHealthReportRows } from './parsers/deviceHealthReport'
import { tpmsReportRows } from './parsers/tpmsReport'
import { dailyReportRows } from './parsers/dailyReport'
import {geofencesReportRows} from './parsers/geofencesReports'
import { distanceReportRows } from './parsers/distanceTravelReport'

interface IuseReportRows {
    (
        reportType: string, 
        columns: Array<string>, 
        rows: Array<IRow>,
        timezone?: string,
        unitsOfMeasurement?: string
    ): Array<IRow>
}

interface IAccum {
    [key: string]: string | number | null
}

const useReportRows: IuseReportRows = (reportType, columns, rows, timezone = "---", unitsOfMeasurement = UnitsOfMeasurement.imperial) => {
    return useMemo(() => {
        let parsedRows: Array<IRow> = []
        
        if (!columns.length || !rows.length) return parsedRows
        switch (reportType) {
            case ReportTypes.USER_LOGINS:
                rows.forEach(item => {
                    parsedRows.push({
                        ...item,
                        last_login: convertDataEpochToDate(item.last_login, null, null, true)
                    })
                })
                
                break   
            case ReportTypes.HARDWARE_COMMANDS_HISTORY:
                parsedRows = rows.map(item => {
                    const confirmed_at = item.delivery_confirmed_at
                    const timestamp = item.timestamp
                    const count = item.delivery_attempts_count
                    return { 
                        ...item, 
                        delivery_confirmed_at: confirmed_at ? convertDataEpochToDate(confirmed_at, null, null, true) : "---",
                        timestamp: timestamp ? convertDataEpochToDate(timestamp, null, null, true) : "---",
                        delivery_attempts_count: count ? count : "---"
                    }
                })
                break
            case ReportTypes.TPMS_REPORT:
                parsedRows = tpmsReportRows(columns, rows, timezone, unitsOfMeasurement)
                break
            case ReportTypes.DEVICE_HEALTH_REPORT:
                parsedRows = dvHealthReportRows(columns, rows, timezone)
                break
            case ReportTypes.DAILY_MILEAGE_REPORT:
                const sorted = rows.sort((a, b)=> (a.timestamp > b.timestamp) ? 1 : -1)
                parsedRows = dailyReportRows(sorted.reverse(), unitsOfMeasurement)
                break
            case ReportTypes.DISTANCE_TRAVELED_REPORT:
                parsedRows = distanceReportRows(rows, unitsOfMeasurement )
                break
            case ReportTypes.GEOFENCES_REPORT:
                parsedRows = geofencesReportRows(columns, rows, timezone)
                break
            default:
                parsedRows = rows
                break
        }

        return parsedRows
    }, [reportType, columns, rows, timezone, unitsOfMeasurement])
} 

export default useReportRows