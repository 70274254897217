import React, { useState, useCallback } from "react";
import RivataModule from "../../components/RivataModule";
import RivataSearch from "../../components/RivataSearch";
import RivataTpmsTable from "../../components/RivataTpmsTable";
import { ColumnsIds, TpmsColumnsIds } from '../../enums'
import './styles.scss'

const TpmsStatusTable = ({
  title,
  tpmsStatusAssets,
  defaults,
  preferences,
  width,
  locale,
  isShowingLimit,
  setSortOptions,
  setFilter,
  setLimit,
  setOffset,
}) => {
  const [ searchColumn, setSearchColumn ] = useState("asset_name")

  const searchFields = [
    { id: ColumnsIds.ASSET_NAME, label: "Asset Name" },
    { id: ColumnsIds.VIN, label: "VIN" },
    { id: TpmsColumnsIds.TPMS_PROFILE, label: "TPMS Profile" },
    { id: ColumnsIds.LAST_GPS_UPDATE, label: "Last Gateway Msg" },
    { id: TpmsColumnsIds.WARNINGS_SUBTYPE, label: "Pressure Status" }
  ]

  const sortHandler = useCallback((sortColumn, sortDirection) => {
    setSortOptions({ column: sortColumn, direction: sortDirection })
  }, [setSortOptions])

  return (
    <RivataModule
      title={title}
      width={width}
      marginTop={0}
      locale={locale}
      error={tpmsStatusAssets.error}
      filters={
        <RivataSearch 
          searchFields={searchFields}
          onFilter={(filter) => {
            setFilter(filter)
            setOffset(0)
          }}
          onSelectSearchField={(id) => setSearchColumn(id)}
          locale={locale}
          searchColumn={searchColumn}
        />
      }
    >
      <RivataTpmsTable
        tpmsStatusAssets={tpmsStatusAssets}
        defaults={defaults}
        preferences={preferences}
        isShowingLimit={isShowingLimit}
        onPageChange={(offset) => setOffset(offset)}
        pageLimit={tpmsStatusAssets.limit}
        onSelectLimit={(limit) => {
          setLimit(limit)
          setOffset(0)
        }}
        onSort={sortHandler}
      />
    </RivataModule>
  );
};

export default TpmsStatusTable;