import React from 'react'
import momentTz from 'moment-timezone'
import * as Yup from "yup"
import { Row } from 'reactstrap'

import CreateModal, { SelectFormField } from '../../../components/CreateModal/children'
import CountryCodeDropdown, { ICountryCode } from '../components/CountryCodeDropdown'
import CompanySelector from '../components/CompanySelector'

import { Timezones, RolePermission } from '../../../enums'
import { IUserFormValues, IValidate } from '../types'

import { useCustomerOptionsCreate } from '../hooks'
import { FormField } from '../../../components/CreateModal'

interface IDefaultList {
    [key: string]: string
}

const phoneRegExp = /^\+[0-9]{11,}$/;
const phoneNumberMessage = 'Phone number must begin with "+" and the country code, followed only by area code, prefix, and number(no dashes or spaces).'
const UserSchema = Yup.object().shape({
    username: Yup.string().required("Required").trim().lowercase(),
    email_address: Yup.string()
      .email("Must supply valid email")
      .required("Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, phoneNumberMessage)
      .required("Required"),
    first_name: Yup.string(),
    last_name: Yup.string(),
    license_state: Yup.string(),
    timezone: Yup.string(),
    role: Yup.string(),
})

const tz: IDefaultList = Timezones
const tzOptions = Object.keys(tz).map(k => ({
    value: tz[k],
    label: `${momentTz.tz(tz[k]).format("Z")} ${tz[k]}`
}))

const roles: IDefaultList = RolePermission
const roleOptions = Object.keys(roles).map(k => ({
    value: roles[k],
    label: roles[k]
}))

interface ICreateUserModal {
    locale: ILocale,
    title: string,
    initialValues: IUserFormValues,
    countryCode: ICountryCode,
    selectedCustomer: ICustomer,
    isSuperAdmin: boolean,
    isAdmin: boolean,
    userCustomerIds: Array<number>,
    open: boolean,
    customersList: Array<ICustomer>,
    onClose: () => void,
    onSubmit: (data: IUserFormValues) => IError | void,
    validate: IValidate | null,
    resetFormValues: () => void,
    setCountryCode: (code: ICountryCode) => void,
    setFormValues: (data: IUserFormValues) => void
}

const CreateUserModal: React.FC<ICreateUserModal> = ({
    locale,
    title,
    initialValues,
    countryCode,
    selectedCustomer,
    isSuperAdmin,
    isAdmin,
    userCustomerIds,
    open,
    customersList,
    onClose,
    onSubmit,
    validate,
    resetFormValues,
    setCountryCode,
    setFormValues
}) => {
    const customerOption = useCustomerOptionsCreate(selectedCustomer, isSuperAdmin, isAdmin, userCustomerIds, open, customersList)

    const handleSetPermissions = (ids: Array<number> | null) => {
        const formData = { ...initialValues }
        
        if (ids) {
            ids.forEach((id) => {
                const idx = formData.customerIds.findIndex(el => el === id ? true : false)
                if (idx > -1) {
                    formData.customerIds.splice(idx, 1)
                } else {
                    formData.customerIds.push(id)
                }
            })
        }
        console.log('set: ', formData);
        
        setFormValues(formData)
    }

    return (
        <CreateModal
            disabled={false}
            btnClassName="ml-3"
            onCreate={onSubmit}
            createBtnLabel={title}
            openBtnLabel={`${title} User`}
            header={`${title} User`}
            locale={locale}
            schema={UserSchema}
            initialValues={initialValues}
            onClose={onClose}
            open={open}
            validate={validate}
            resetFormValues={resetFormValues}
            footerBtnDisabled={!initialValues.customerIds.length}
        >
            <Row>
                <FormField name="username" label="Username" />
                <FormField name="email_address" type="email" label="Email" />
                <FormField name="first_name" label="First Name" />
                <FormField name="last_name" label="Last Name" />
                <div className="d-flex col-md-12 country-code__wrapper">
                    <CountryCodeDropdown
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                    />
                    <FormField className="pr-0 phone__input" name="phone_number" label="Phone Number" />
                </div>
                <SelectFormField name="timezone" label="Time Zone" options={tzOptions} />
                <SelectFormField name="role" label="Role" options={roleOptions}/>
                {(userCustomerIds && userCustomerIds.length > 1) || isSuperAdmin ? (
                    <CompanySelector
                        options={customerOption}
                        setPermissions={handleSetPermissions}
                        customerIds={initialValues.customerIds}
                    />
                ) : null}
            </Row>
        </CreateModal>
    )
}

export default CreateUserModal