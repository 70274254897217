import { useEffect, useState } from "react";

const ColumnsIds = {
    TagMac: "tagmac",
    Latitude: "latitude",
    Longitude: "longitude",
    Voltage: "voltage",
    Temperature: "temperature",
    GatewayName: "gatewayname",
    LastCommunicationDate: "lastcommunicationdate"
}

export const useColumns = () => {
    const [columns, setColumns] = useState([])

    useEffect(() => {
        setColumns([
            {
                label: "Tag Mac",
                id: ColumnsIds.TagMac
            },
            {
                label: "Latitude",
                id: ColumnsIds.Latitude
            },
            {
                label: "Longitude",
                id: ColumnsIds.Longitude
            },
            {
                label: "Voltage",
                id: ColumnsIds.Voltage
            },
            {
                label: "Temperature",
                id: ColumnsIds.Temperature
            },
            {
                label: "Gateway Name",
                id: ColumnsIds.GatewayName
            },
            {
                label: "Last Communcation Date",
                id: ColumnsIds.LastCommunicationDate
            }
        ])
    }, [])

    return columns;
};


export const useTableRows = (data, locationId) => {
    const [rows, setRows] = useState([])

    useEffect(() => {
        const dataRows = []
        data.forEach(d => {
            const row = {
                data: d,
                columns: [
                    {
                        
                        columnId: ColumnsIds.TagMac,
                        type: "link",
                        label: d.mac,
                        link: `/geolocations/tags/${d.mac}`
                    },
                    {
                        columnId: ColumnsIds.Latitude,
                        type: "text",
                        label: d.lat
                    },
                    {
                        columnId: ColumnsIds.Longitude,
                        type: "text",
                        label: d.lon
                    },
                    {
                        columnId: ColumnsIds.Voltage,
                        type: "text",
                        label: d.voltage
                    },
                    {
                        columnId: ColumnsIds.Temperature,
                        type: "text",
                        label: d.temperature
                    },
                    {
                        columnId: ColumnsIds.GatewayName,
                        type: "text",
                        label: d.gateway_name
                    },
                    {
                        columnId: ColumnsIds.LastCommunicationDate,
                        type: "text",
                        label: d.formatted_last_communication_at_datetime

                    }
                ]
            }
            dataRows.push(row)
        })
        setRows(dataRows);

    }, [data]);
    return rows
}



export const useMapData = (data) => {
    const [mapData, setMapData] = useState([]);
    useEffect(() => {
        if (data.id) {
            const mD = [{
                id: data.id,
                latitude: Number(data.lat),
                longitude: Number(data.lon),
                name: data.name,
                gateways: data.gateways_str
            }];
            setMapData(mD);
        }
    }, [data])

    return mapData;
}