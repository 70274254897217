import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, FormGroup, Label, Button, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { ColumnsIds, TpmsColumnsIds, WarningSearchColumnIds, WarningSensorTypes, PressureWarningLevel } from '../../enums';
import RivataDropdown from "../RivataDropdown";
import DateRangeCalendar from '../DateRangeCalendar';
import { dateToEpoch } from '../../utils';
import { cpmposeMac } from './utils'
import './rivata-search.scss';

const RivataSearch = ({
  isSuperAdmin,
  searchFields,
  onFilter,
  onSelectSearchField,
  locale = {},
  searchColumn,
  subscriptions,
  clearFilter,
  warningPositions,
  preventFilterClear
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [dayStart, setDayStart] = useState(new Date());
  const [dayEnd, setDayEnd] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getInputValue = useCallback(() => {
    if (dayStart && dayEnd) {
      const dateFrom = `${(dayStart.getMonth()) + 1}/${dayStart.getDate()}/${dayStart.getFullYear()}`;
      const dateTo = dayEnd
        ? ` - ${(dayEnd.getMonth()) + 1}/${dayEnd.getDate()}/${dayEnd.getFullYear()}`
        : '';
      setInputValue(`${dateFrom + dateTo}`);
    }
  }, [dayStart, dayEnd])

  useEffect(() => {
    getInputValue();
  }, [dayStart, dayEnd, getInputValue])

  useEffect(() => {
    return () => {
      if (preventFilterClear) return null
      onFilter({ filterType: '', filterData: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectionRange = {
    startDate: dayStart,
    endDate: dayEnd,
    key: 'selection',
  };

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setDayStart(startDate);
    setDayEnd(endDate);
  };

  const onInputClear = () => {
    setInputValue("");
    setDayStart(null);
    setDayEnd(null);
    onFilter({ filterType: '', filterData: '' });
  }

  const toggleCalendar = (value = !showCalendar) => {
    setShowCalendar(value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let filter = {}
    if (searchColumn === ColumnsIds.LAST_GPS_UPDATE) {
      let startDate = null;
      let endDate = null;

      if (dayStart && dayEnd) {
        const date = dateToEpoch(dayStart, dayEnd);
        startDate = date.startDate;
        endDate = date.endDate;
      }

      filter = {
        filterType: searchColumn,
        filterData: (startDate && endDate) ? `${startDate}_${endDate}` : null
      };
    } else if (searchColumn === ColumnsIds.SENSOR_ID) {
      filter = {
        filterType: searchColumn,
        filterData: cpmposeMac(inputValue)
      };
    } else if (searchColumn === WarningSearchColumnIds.LOCATION) {
      filter = {
        filterType: searchColumn,
        filterData: inputValue
      };
    } else {
      let value = inputValue
      if(inputValue === "No status"){
        value = "no_status"
      }
      filter = {
        filterType: searchColumn,
        filterData: value.toLowerCase()
      };
    }
    onFilter(filter);
  }

  const sensorTypes = {
    axle_load: "Axle Load",
    line_pressure: "Line Pressure",
    smarthub_sensors: "SmartHub",
    tpms: "TPMS"
  }
  const warningTypes = {
    hub_vibration: "Hub Vibration",
    hub_temperature: "Hub Temperature",
    no_status: "No Status",
    tire_pressure: "Tire Pressure",
    line_pressure: "Line Pressure"
  }

  const warningSubTypes = {
    "critical_low_pressure": "Critical Low Pressure",
    "low_pressure": "Low Pressure",
    "over_pressure": "Over Pressure",
    "critical_over_pressure": "Critical Over Pressure",
  }

  const getToggleValue = () => {
    if (inputValue === "") return "All"

    if (inputValue === "Ok") return "Normal"

    if (searchColumn === ColumnsIds.SENSOR_INFO) return sensorTypes[inputValue]
    if (searchColumn === WarningSearchColumnIds.WARNING_TYPE) return warningTypes[inputValue] || inputValue
    if (searchColumn === WarningSearchColumnIds.SENSOR_TYPE) return WarningSensorTypes[inputValue] || inputValue
    if (searchColumn === WarningSearchColumnIds.LOCATION) return warningPositions.find((el) => el.value === inputValue ? true : false).label || inputValue
    if (searchColumn === TpmsColumnsIds.WARNINGS_SUBTYPE) return warningSubTypes[inputValue] || inputValue
    return inputValue
  }

  useEffect(() => {
    if (clearFilter) {
      onSelectSearchField(TpmsColumnsIds.ASSET_NAME);
      setInputValue("");
      onFilter({ filterType: '', filterData: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilter])

  const renderFilterSelect = (items = []) => {
    return <Dropdown className="filter-select" setActiveFromChild={true} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle color="#525f7f" className="filter-select__label" caret>{getToggleValue()}</DropdownToggle>
      <DropdownMenu right className="filter-select__menu">
        {items.map((row, i) => {
          return <DropdownItem key={row.label + i} onClick={() => setInputValue(row.value)}>{ row.label }</DropdownItem>
        })}  
      </DropdownMenu>
    </Dropdown>
  }

  const renderInputs = () => {
    if (searchColumn === ColumnsIds.LAST_GPS_UPDATE) {
      return (
        <Input
          className="date-filter-input"
          onClick={(e) => {
            e.persist()
            toggleCalendar()
          }}
          placeholder={locale.filter || "Filter"}
          value={inputValue}
          readOnly
        />
      );
    }
    else if (searchColumn === ColumnsIds.STATUS) {
      const items = [
        { value: "", label: "All" },
        { value: "Ok", label: "Normal" },
        { value: "Warning", label: "Warning" },
        { value: "No status", label: "No status" },
      ]
      return renderFilterSelect(items)
    }
    else if (searchColumn === ColumnsIds.SENSOR_INFO) {
      const items = [ { value: "", label: "All" } ]
      Object.keys(isSuperAdmin ? { HUB_SENSOR: {}, TIRE_SENSOR: {}, AXLE_LOAD: {}, LINE_PRESSURE: {} } : subscriptions ? subscriptions : {}).forEach((subKey) => {
        switch (subKey) {
          case "HUB_SENSOR":
            items.push({ value: "smarthub_sensors", label: "SmartHub" })
            break;
          case "TIRE_SENSOR":
            items.push({ value: "tpms", label: "TPMS" })
            break;
          case "AXLE_LOAD":
            items.push({ value: "axle_load", label: "Axle Load" })
            break;
          case "LINE_PRESSURE":
            items.push({ value: "line_pressure", label: "Line Pressure" })
            break;
          default:
            break;
        }
      })

      return renderFilterSelect(items)
    } else if (searchColumn === WarningSearchColumnIds.WARNING_TYPE) {
      const items = [ { value: "", label: "All" }, { value: "no_status", label: "No Status" }  ]

      Object.keys(isSuperAdmin ? { HUB_SENSOR: {}, TIRE_SENSOR: {}, LINE_PRESSURE : {} } : subscriptions ? subscriptions : {}).forEach((subKey) => {
        switch (subKey) {
          case "HUB_SENSOR":
            items.push({ value: "hub_vibration", label: "Hub Vibration" })
            items.push({ value: "hub_temperature", label: "Hub Temperature" })
            break;
          case "TIRE_SENSOR":
            items.push({ value: "tire_pressure", label: "Tire Pressure" })
            break;
          case "LINE_PRESSURE":
            items.push({ value: "line_pressure", label: "Line Pressure" })
            break;
          default:
            break;
        }
      })

      return renderFilterSelect(items)
    } else if (searchColumn === WarningSearchColumnIds.SENSOR_TYPE) {
      const items = [ { value: "", label: "All" } ]
      Object.keys(isSuperAdmin ? { HUB_SENSOR: {}, TIRE_SENSOR: {}, LINE_PRESSURE: {} } : subscriptions ? subscriptions : {}).forEach((subKey) => {
        switch (subKey) {
          case "HUB_SENSOR":
            items.push({ value: "hub_sensor", label: "SmartHub" })
            break;
          case "TIRE_SENSOR":
            items.push({ value: "tire_sensor", label: "TPMS" })
            break;
          case "LINE_PRESSURE":
            items.push({ value: "line_pressure", label: "Line Pressure" })
            break;
          default:
            break;
        }
      })

      return renderFilterSelect(items)
    } else if (searchColumn === ColumnsIds.ASSET_TYPE) {
      const items = [
        { value: "", label: "All" },
        { value: "Tractor", label: "Tractor" },
        { value: "Trailer", label: "Trailer" },
      ]

      return renderFilterSelect(items)
    } else if (searchColumn === WarningSearchColumnIds.LOCATION) {
      return renderFilterSelect(warningPositions)
    } else if (searchColumn === TpmsColumnsIds.WARNINGS_SUBTYPE) {
      return renderFilterSelect([
        { value: "", label: "All" },
        { value: PressureWarningLevel.lowPressure, label: warningSubTypes[PressureWarningLevel.lowPressure] },
        { value: PressureWarningLevel.criticalLowPressure, label: warningSubTypes[PressureWarningLevel.criticalLowPressure] },
        { value: PressureWarningLevel.overPressure, label: warningSubTypes[PressureWarningLevel.overPressure] },
        { value: PressureWarningLevel.criticalOverPressure, label: warningSubTypes[PressureWarningLevel.criticalOverPressure] },
      ])
    } else {
      return (
        <FormGroup className="mb-0">
          <Label hidden>{locale.search}</Label>
          <Input placeholder="Filter" value={inputValue} onChange={(ev) => setInputValue(ev.target.value)} />
        </FormGroup>
      );
    }
  };
  return (
    <Form
      className="d-inline-flex align-items-start"
      onSubmit={onSubmit}
    >
      <RivataDropdown
        items={searchFields}
        onSelect={(id) => {
          onSelectSearchField(id);
          setInputValue("");
        }}
        selected={searchColumn}
      />
      <div className="form_wrapper">
        <FormGroup className="mb-0">
          <Label hidden>{locale.search}</Label>
          {renderInputs()}
          <DateRangeCalendar
            visible={showCalendar}
            selectionRange={selectionRange}
            handleSelect={handleSelect}
            toggleCalendar={toggleCalendar}
          />
        </FormGroup>
      </div>
      <Button
        type="reset"
        className="btn btn-clear"
        onClick={onInputClear}>
        Clear
      </Button>
      <Button>{'Submit'}</Button>
    </Form>
  );
};

export const searchType = {
  id: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  onSelectSearchField: PropTypes.func.isRequired,
  locale: PropTypes.objectOf(PropTypes.string),
  searchFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  warningPositions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

RivataSearch.propTypes = searchType;

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(RivataSearch);
