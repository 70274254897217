import {
  LOADING_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_ERROR,
  POSTING_WHITELABEL_ADMIN,
  POSTED_WHITELABEL_ADMIN,
  SET_WHITELABEL_ADMIN_DATA_DIRTY,
  SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG,
  SET_WHITELABEL_ADMIN_LOGO_ICON_PNG,
  SET_WHITELABEL_ADMIN_LOGO_FULL_PNG,
  SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG,
} from "../actionTypes";
import { localeText } from "../../locale";
import { Languages } from "../../enums";
import defaultConfig from "../whitelabel/defaultConfig";
import { getActualConfig } from '../../utils'

const languagechoice = (data) => {
  switch (data.preferenceDefaults.locale.language) {
    case Languages.FR:
      return localeText.fr;
    case Languages.EN:
      return localeText.en;
    case Languages.GR:
      return localeText.gr;
    default:
      return null;
  }
};

const initialState = {
  config: defaultConfig,
  locale: languagechoice(defaultConfig),
  error: {
    statusCode: 0,
    message: "",
  },
  isLoading: false,
  isPosting: false,
  hasCheckedForConfig: false,
  dirty: false,
  postedConfig: false,
  smarthubHealthSvg: null,
  tpmsHealthSvg: null,
  linePressureHealthSvg: null,
  axleLoadHealthSvg: null,
  logoIconPng: null,
  logoFullPng: null,
};

// const validate = (data) => (Array.isArray(data) ? data : null);

const setWhiteLabel = (state, data) => {
  const { config, isUpdate } = data
  if (!config) return state;

  const newConfig = isUpdate ? config : getActualConfig(config)

  return {
    ...state,
    config: {
      ...newConfig
    },
    hasCheckedForConfig: true,
    locale:
      config.preferenceDefaults &&
      config.preferenceDefaults.locale &&
      languagechoice(config),
  }
};

const whitelabel = (state = initialState, action) => {
  switch (action.type) {
    case SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG:
      return { ...state, smarthubHealthSvg: action.payload };
    case SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG:
      return { ...state, tpmsHealthSvg: action.payload };
    case SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG:
      return { ...state, linePressureHealthSvg: action.payload };
    case SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG:
      return { ...state, axleLoadHealthSvg: action.payload };
    case SET_WHITELABEL_ADMIN_LOGO_ICON_PNG:
      return { ...state, logoIconPng: action.payload };
    case SET_WHITELABEL_ADMIN_LOGO_FULL_PNG:
      return { ...state, logoFullPng: action.payload };
    case POSTING_WHITELABEL_ADMIN:
      return { ...state, isPosting: action.payload };
    case POSTED_WHITELABEL_ADMIN:
      return { ...state, postedConfig: action.payload };
    case SET_WHITELABEL_ADMIN_DATA_DIRTY:
      return { ...state, dirty: action.payload };
    case LOADING_WHITELABEL_ADMIN:
      return { ...state, isLoading: action.payload };
    case SET_WHITELABEL_ADMIN:
      return setWhiteLabel(state, action.payload);
    case SET_WHITELABEL_ADMIN_ERROR:
      return { ...state, error: { ...action.payload }, hasCheckedForConfig: true };
    default:
      return state;
  }
};

export default whitelabel;
