import React, { useEffect } from "react";
import CustomersDropdown from "../../components/CustomersDropdown";
import SubNav from "../../components/SubNav";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector} from "../../hooks/useTypedSelector";
import TagDetails from "../../modules/TagDetails";
import { dateToEpoch } from "../../utils";
import {useParams} from 'react-router-dom'

const TagDetailsPage = () => {
    const { fetchTagDataPage, fetchTagGpsDataDetailed, fetchTagGpsData, setTagGpsDataDetailedOffset, setSelectedCustomer, fetchTagGpsFilters } = useActions()
    const {locale={}, common:{customers}, tagDetails} = useTypedSelector((state)=>({
        locale: state.whitelabel.locale,
        common: state.common,
        tagDetails: state.tagDetails,
    }))

    const { mac } = useParams()
    const { startDate, endDate } = dateToEpoch();

    useEffect(() => {
        fetchTagDataPage(mac, startDate, endDate)
    }, [fetchTagDataPage, mac, startDate, endDate])
    
    return (
        <>
            <SubNav
                title="Tag Locations Details"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Tag Locations", path: "/geolocations" },
                    { label: "Tag Details" }
                ]}
            >
                {customers.selectedCustomer && customers.data.length > 1 ? (
                    <CustomersDropdown
                        isLoading={customers.loading}
                        customers={customers.data}
                        locale={locale}
                        initial={customers.selectedCustomer}
                        label={locale.Customer || "Customer"}
                        onChange={(customer) => {
                            setSelectedCustomer(customer)
                            fetchTagGpsFilters();
                            fetchTagGpsData(mac, null, null, startDate, endDate)
                            fetchTagGpsDataDetailed(mac, null, null, startDate, endDate)
                            setTagGpsDataDetailedOffset(0)
                        }}
                    />
                ) : null}
            </SubNav>
            <TagDetails
                locale={locale}
                gpsData={tagDetails.gpsData}
                gpsDataDetailed={tagDetails.gpsDataDetailed}
                isLoadingGpsData={tagDetails.isLoadingGpsData}
                isLoadingGpsDataDetailed={tagDetails.isLoadingGpsDataDetailed}
                totalCount={tagDetails.totalCount}
                limit={tagDetails.limit}
                onPageChange={(offset) => {
                    setTagGpsDataDetailedOffset(offset);
                }}
                mac={mac}
                filters={tagDetails.filters}
                onFiltersSubmit={(mac, locationId, assetId, startDate, endDate, fetchDetailedOnly) => {
                    if (!fetchDetailedOnly) {
                        fetchTagGpsData(mac, locationId, assetId, startDate, endDate)
                    }
                    fetchTagGpsDataDetailed(mac, locationId, assetId, startDate, endDate);
                }}
                isLoadingFilters={tagDetails.isLoadingFilters}
            />
        </>
    );
};


export default TagDetailsPage

