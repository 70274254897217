
import { GeofencesActionTypes } from "./types";

const initialState = {
  isLoading: false,
  pagedGeofences: [],
  customerGeofences: [],
  activityEvents: [],
  limit: 10,
  customerId: null,
  offset: 0,
  totalCount: 0,
  eventsLimit: 10,
  eventsOffset: 0,
  eventsTotalCount: 0,
  sortOptions: {
    column: null,
    direction: null,
  },
  eventsFilter: {
    selectedGeofenceId: null,
    startDate: null,
    endDate: null,
    assetVin: "",
  },
  error: false,
};

const geofences = (
  state = initialState,
  action: {
    type: GeofencesActionTypes;
    payload: any;
  }
) => {
  switch (action.type) {
    case GeofencesActionTypes.LOADING_GEOFENCES:
      return { ...state, isLoading: action.payload };
    case GeofencesActionTypes.SET_PAGED_GEOFENCES:
      return { ...state, pagedGeofences: action.payload };
    case GeofencesActionTypes.SET_CUSTOMER_GEOFENCES:
      return { ...state, customerGeofences: action.payload };
    case GeofencesActionTypes.SET_ACTIVITY_EVENTS:
      return { ...state, activityEvents: action.payload };
    case GeofencesActionTypes.SET_GEOFENCES_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case GeofencesActionTypes.SET_GEOFENCES_OFFSET:
      return { ...state, offset: action.payload };
    case GeofencesActionTypes.SET_GEOFENCES_EVENTS_TOTAL_COUNT:
      return { ...state, eventsTotalCount: action.payload };
    case GeofencesActionTypes.SET_EVENTS_OFFSET:
      return { ...state, eventsOffset: action.payload };
    case GeofencesActionTypes.SET_RESET_EVENTS_OFFSET:
      return { ...state, eventsOffset: 0 };
    case GeofencesActionTypes.SET_EVENTS_FILTER:
      return { ...state, eventsFilter: action.payload };
    case GeofencesActionTypes.SET_GEOFENCES_ERROR:
      return { ...state, error: action.payload };
    case GeofencesActionTypes.SET_GEOFENCES_LIMIT:
      return { ...state, limit: action.payload };
    case GeofencesActionTypes.SET_EVENTS_LIMIT:
      return { ...state, eventsLimit: action.payload };
    case GeofencesActionTypes.SET_GEOFENCES_SORT_OPTIONS:
      return { ...state, sortOptions: action.payload };
    case GeofencesActionTypes.SET_CUSTOMER_ID:
      return { ...state, customerId: action.payload };
    default:
      return state;
  }
};

export default geofences;
