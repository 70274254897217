import React, { useEffect, useState, useRef } from "react";
import config from "../../../config/appConfig";
import { behaviorUi } from "../utils";
import { LayerTypes } from "../../../components/RivataMapCluster/utils";

interface Props {
  map: H.Map | null;
  selectedLayerOption: string;
  setMap: (map: H.Map) => void;
  setUi: (ui: H.ui.UI) => void;
}

const GeofenceMap: React.FC<Props> = ({ map, selectedLayerOption, setMap, setUi}) => {
  const mapRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [defaultLayers, setDefaultLayers] = useState<H.service.DefaultLayers>();

  useEffect(() => {
    const platform = new H.service.Platform({
      apikey: config.mapApiKey,
    });

    

    const defaultLayers = platform.createDefaultLayers();

    setDefaultLayers(defaultLayers)

    setMap(
      new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        center: { lat: 41.850033, lng: -87.6500523 },
        zoom: 4,
        pixelRatio: window.devicePixelRatio || 1,
      })
    );
  }, [mapRef, setMap, setDefaultLayers]);

  useEffect(() => {
    const resize = () => {
      if (map) map.getViewPort().resize();
    };

    if (map) {
      window.addEventListener("resize", resize);

      if (defaultLayers) {
        const { ui } = behaviorUi(map, defaultLayers);
        ui.getControl("zoom").setVisibility(true);
        ui.getControl("mapsettings").setVisibility(false);

        setUi(ui);
      }

      resize();
    }

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [map, defaultLayers, setUi]);

  useEffect(() => {
    return () => {
      if (map) map.dispose();

    }
  }, [])

  useEffect(() => {
    if (map && defaultLayers && selectedLayerOption) {
      const layerChoices = {
        [LayerTypes.NORMAL]: defaultLayers.vector.normal.map,
        [LayerTypes.SATELLITE]: defaultLayers.raster.satellite.map,
      };

      const baseLayer = layerChoices[selectedLayerOption];

      if (baseLayer) map.setBaseLayer(baseLayer);
    }
  }, [map, defaultLayers, selectedLayerOption]);

  return <div className="geofence-map mb-3" ref={mapRef} />;
};

export default GeofenceMap;
