import React from 'react';
import { Row } from 'reactstrap';
import * as Yup from "yup";
import { FormField } from "../../components/CreateModal";
import CreateModal from "../../components/CreateModal/children";
import RivataLoader from '../../components/RivataLoader';

const LocationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    lat: Yup.number().moreThan(-90).lessThan(90).required("Required"),
    lon: Yup.number().moreThan(-180).lessThan(180).required("Required")
});

const LocationModal = ({
    locale = {},
    onCreate,
    disabled,
    initialValues,
    title,
    open,
    onClose,
    isLoading
}) => {
    return (
        <CreateModal
            variant="primary"
            disabled={disabled}
            className="ml-3"
            btnClassName="ml-3"
            onCreate={onCreate}
            createBtnLabel={title}
            openBtnLabel={`${title} Location`}
            header={`${title} Location`}
            locale={locale}
            schema={LocationSchema}
            initialValues={initialValues}
            onClose={onClose}
            open={open}
        >
            {isLoading && <RivataLoader />}
            <Row className="p-t-0">
                <FormField name="name" label="Name" />
                <FormField name="lat" label="Latitude" type="number" step="0.01" />
                <FormField name="lon" label="Longitude" type="number" step="0.01" />
            </Row>
        </CreateModal>
    );
};

export default LocationModal;