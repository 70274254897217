import { useEffect, useState } from "react";

export const useMapData = (data) => {
    const [mapData, setMapData] = useState([]);

    useEffect(() => {
        const mD = [];
        let id = 0;
        data.forEach(d => {
            mD.push({
                id: id,
                latitude: Number(d.lat),
                longitude: Number(d.lon)
            })
            id++;
        });
        setMapData(mD);
    }, [data])

    return mapData;
}
const ColumnsIds = {
    Latitude: "latitude",
    Longitude: "longitude",
    Voltage: "voltage",
    Temperature: "temperature",
    LocationName: "locationname",
    LocationLatitude: "locationlatitude",
    LocationLongitude: "locationlongitude",
    Date: "date",
    TagMac: "tagmac",
    AssetName: "assetname"
}

export const useColumns = () => {
    const [columns, setColumns] = useState([])

    useEffect(() => {
        setColumns([
            {
                label: "Mac",
                id: ColumnsIds.TagMac
            },
            {
                label: "Latitude",
                id: ColumnsIds.Latitude
            },
            {
                label: "Longitude",
                id: ColumnsIds.Longitude
            },
            {
                label: "Voltage",
                id: ColumnsIds.Voltage
            },
            {
                label: "Temperature",
                id: ColumnsIds.Temperature
            },
            {
                label: "Asset Name",
                id: ColumnsIds.AssetName
            },
            {
                label: "Location Name",
                id: ColumnsIds.LocationName
            },
            {
                label: "Date",
                id: ColumnsIds.Date
            }
        ])
    }, [])

    return columns;
};


export const useTableRows = (data) => {
    const [rows, setRows] = useState([])

    useEffect(() => {
        const dataRows = []
        data.forEach(d => {
            const row = {
                data: d,
                columns: [
                    {
                        columnId: ColumnsIds.TagMac,
                        type: "text",
                        label: d.tag_mac
                    },
                    {
                        columnId: ColumnsIds.Latitude,
                        type: "text",
                        label: d.lat
                    },
                    {
                        columnId: ColumnsIds.Longitude,
                        type: "text",
                        label: d.lon
                    },
                    {
                        columnId: ColumnsIds.Voltage,
                        type: "text",
                        label: d.voltage
                    },
                    {
                        columnId: ColumnsIds.Temperature,
                        type: "text",
                        label: d.temperature
                    },
                    {
                        columnId: ColumnsIds.AssetName,
                        type: "text",
                        label: d.asset_name || "-"
                    },
                    {
                        columnId: ColumnsIds.LocationName,
                        type: "text",
                        label: d.location_name || "-"
                    },
                    {
                        columnId: ColumnsIds.Date,
                        type: "text",
                        label: d.formatted_datetime

                    }
                ]
            }
            dataRows.push(row)
        })
        setRows(dataRows);

    }, [data]);
    return rows
}