import { WARNING_TYPE } from '../../constants';
import api from '../../services/api';
import { getToken, composeTpmsProfiles } from '../../utils';
import {
    SET_TPMS_PROFILES,
    SET_TPMS_SELECTED_PROFILE,
    LOADING_TPMS_PROFILES,
} from '../actionTypes';

export const setLoadingTpmsProfiles = (isLoading) => ({
    type: LOADING_TPMS_PROFILES,
    payload: isLoading,
})

export const setTpmsProfiles = (profiles) => ({
    type: SET_TPMS_PROFILES,
    payload: profiles,
})

export const setSelectedTpmsProfile = (profile) => ({
    type: SET_TPMS_SELECTED_PROFILE,
    payload: profile,
})

export const deleteTpmsSettings = (settings_id) => {
    return function (dispatch, getState) {
        dispatch(setLoadingTpmsProfiles(true));
        return api.deleteWarningSettings(getToken(getState), settings_id).then(data => {
            dispatch(fetchTpmsProfiles())
            return data;
        }).catch(err => {
        }).finally(() => {
            dispatch(setLoadingTpmsProfiles(false));
        })
    }
}

export const addUpdateTpmsSettings = (settings) => {
    return function (dispatch, getState) {
        dispatch(setLoadingTpmsProfiles(true));
        const selectedProfile = getState().tpmsProfiles.selectedTpmsProfile
        const { id } = getState().common.customers.selectedCustomer
        if (settings.settings_id) {
            // update
            return api
                .putWarningSettings(getToken(getState), { ...settings, customer_id: id })
                .then((r) => {
                    dispatch(fetchTpmsProfiles(true, selectedProfile.id, id)) // (set prev profile, profile id, selected custmer id)
                    return { statusCode: 200, message: `Profile "${selectedProfile.name}" successfully updated` }
                })
                .catch((err) => {
                    let message = err.message.split("[")
                    message = message[1].split("]")
                    message = message[0].split(", ")
                    dispatch(setLoadingTpmsProfiles(false));
                    return { statusCode: 400, errorAssets: message }
                })
        }
        else {
            // save
            return api
                .postWarningSettings(getToken(getState), { ...settings, customer_id: id })
                .then((r) => {
                    dispatch(fetchTpmsProfiles(true, selectedProfile.id, id)) // (set prev profile, profile id, selected custmer id)
                    return { statusCode: 200, message: `Profile "${selectedProfile.name}" successfully saved` }
                })
                .catch((err) => {
                    return { statusCode: err.statusCode, errorAssets: err.message }
                })
        }
    };
}

export const fetchTpmsProfilesPage = () => {
    return function (dispatch, getState) {
        dispatch(setLoadingTpmsProfiles(true))
        const { id } = getState().common.customers.selectedCustomer
        dispatch(fetchTpmsProfiles(true, null, id))
    }
}

export const fetchTpmsProfiles = (setSelectedProfile = true, selectedProfileId, customerId) => {
    return function (dispatch, getState) {
        dispatch(setLoadingTpmsProfiles(true))
        const token = getToken(getState);
        return api.getWarningsSettings(token, WARNING_TYPE.TIRE_PRESSURE_SENSOR, customerId).then(warningSettings => {
            const profiles = composeTpmsProfiles(warningSettings)
            dispatch(setTpmsProfiles(profiles))
            if (setSelectedProfile) {
                const dflt = profiles.findIndex((el)=>el.name.toLowerCase() === "default") 
                if (profiles.length && dflt !== -1) {  
                    const selectedProfileIndex = selectedProfileId ? profiles.findIndex((el) => el.id === selectedProfileId ? true : false) : dflt
                    dispatch(setSelectedTpmsProfile(profiles[selectedProfileIndex])) // set edited profile as selected if It is or last profile in array
                }
            }
        }).catch(er => { }).finally(() => dispatch(setLoadingTpmsProfiles(false)))
    };
}
