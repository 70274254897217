interface IPositionColor {
    id: string,
    label: string,
    color: string
}

interface IPositions {
    tractor: Array<IPositionColor>,
    trailer: Array<IPositionColor>,
}

export const getAxlesColors = (colorsConfig: Array<IPositionColor>): IPositions => {
    const positions: IPositions = {
      tractor: [],
      trailer: []
    }

    colorsConfig.forEach(color => {
      if (color.id.indexOf("Steer") > -1 || color.id.indexOf("Drive") > -1) {
        positions.tractor.push(color)
      } else {
        positions.trailer.push(color)
      }
    })

    return positions
}

export const getCurrentAxlesCount = (positionColors: Array<IPositionColor>): number => {
    const item: IPositionColor = positionColors[positionColors.length - 2]
    const label: Array<string> = item.label.split(" ")
    return parseInt(label[2])
}