import React, { useState } from 'react';
import RivataModule from '../../components/RivataModule';
import RivataTable from '../../components/RivataTable';
import { useColumns, useMapData, useTableRows } from './hooks';
import LocationMap from '../LocationMap/LocationMap';
import LocationModal from './LocationModal';
import DeleteModal from '../../components/DeleteModal';
import { Button } from 'reactstrap';
import LocationGatewayModal from './LocationGatewayModal';
import ConfirmModal from '../../components/ConfirmModal';

const formatLocationData = (data) => {
    return {
        "lat": Number(data.lat),
        "lon": Number(data.lon),
        "name": data.name
    }
}

const formatGatewayAssignData = (formData, selectedGeolocationData) => {
    return {
        "location_id": selectedGeolocationData.id,
        "gateway_esn": `00${formData.gateway_esn}`,
        "notified_asset_unnasigning": false
    }
}

const GeoLocations = ({ data, setLocationsOrder, locale, width, onCreate, onDelete, onUpdate, onGatewayUnnasign, onGatewayAssign, locationsCount, limit, setLocationsOffset, geolocationsCoordinatesData, isLoading, selectedCustomer, preferences }) => {
    const [deleteModalData, setDeleteModalData] = useState(null);
    const [locationModalData, setLocationModalData] = useState(null);
    const [gatewayModalData, setGatewayModalData] = useState(null);
    const [createMode, setCreateMode] = useState(false);
    const [gatewayAssigningData, setGatewayAssigningData] = useState(null);
    const columns = useColumns();
    const rows = useTableRows(data);
    const mapData = useMapData(geolocationsCoordinatesData);

    const onCreateUpdateModalClose = () => {
        setLocationModalData(null);
        setCreateMode(false);
    }

    return <RivataModule
        title="Locations"
        width={width}
        locale={locale}
        filters={
            <Button
                size="md"
                onClick={() => {
                    setCreateMode(true);
                }}
                disabled={!selectedCustomer || !selectedCustomer.id}
            >
                <i className="mr-2 fa fa-plus-circle"></i>
                Create Location
            </Button>
        }

    >
        <LocationMap
            locations={mapData}
            locale={locale}
            preferences={preferences}
        />
        <br />
        <RivataTable
            showPagination={true}
            assetsCount={locationsCount}
            onPageChange={setLocationsOffset}
            pageLimit={limit}
            setSortOptions={setLocationsOrder}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            onDelete={(data) => {
                setDeleteModalData(data);
            }}
            onEdit={(data) => {
                setLocationModalData(data);
            }}
            onCustomAction={(data) => {
                setGatewayModalData(data);
            }}
        />
        {deleteModalData && <DeleteModal
            open={true}
            disabled={false}
            header={`Delete location ${deleteModalData?.name}?`}
            message={"This cannot be undone!"}
            onDelete={() => {
                onDelete(deleteModalData.id);
                setDeleteModalData(null);
            }}
            onCancel={() => setDeleteModalData(null)}
            locale={locale}
        />}

        {(locationModalData || createMode) &&
            <LocationModal
                open={true}
                title={createMode ? "Create" : "Update"}
                locale={locale}
                disabled={false}
                initialValues={createMode ? {
                    "name": "",
                    "lat": null,
                    "lon": null
                } : locationModalData}
                onCreate={async (data) => {
                    const formattedData = formatLocationData(data);
                    let result;
                    if (data.id && !createMode) {
                        result = await onUpdate(data.id, formattedData);
                    }
                    else {
                        result = await onCreate(formattedData);
                    }

                    if (result.ok) {
                        onCreateUpdateModalClose();
                    }
                    return result;
                }}
                onClose={onCreateUpdateModalClose}
                isLoading={isLoading}
            />}
        {(gatewayModalData) &&
            <LocationGatewayModal
                open={true}
                locale={locale}
                disabled={false}
                initialValues={{
                    "gateway_esn": ""
                }}
                onDelete={onGatewayUnnasign}
                onCreate={async (formData) => {
                    const formattedData = formatGatewayAssignData(formData, gatewayModalData);
                    const result = await onGatewayAssign(formattedData);
                    if (result.ok) {
                        setGatewayModalData(null);
                    }

                    else if (!result.ok && result.status === "already_assigned_to_asset") {
                        setGatewayAssigningData({
                            onConfirmPress: async () => {
                                formattedData.notified_asset_unnasigning = true;
                                await onGatewayAssign(formattedData);
                                setGatewayAssigningData(null);
                                setGatewayModalData(null);
                            },
                            message: result.message
                        });
                    }

                    return result;

                }}
                onClose={() => {
                    setGatewayModalData(null);
                }}
                data={gatewayModalData}
                isLoading={isLoading}
            />}
        {(gatewayAssigningData && <ConfirmModal
            open={true}
            header={"Are you sure you want to unnasign gateway?"}
            onClose={() => setGatewayAssigningData(null)}
            locale={locale}
            modalButtons={[
                {
                    id: 1, label: "Yes", color: "success", onClick: () => gatewayAssigningData.onConfirmPress()
                }
            ]}
        >
            {gatewayAssigningData.message}
        </ConfirmModal>)}
    </RivataModule>
}

export default GeoLocations;
