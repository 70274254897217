import { combineReducers, Action } from "redux";
import settings from "./settings/reducer";
import auth from "./auth/reducer";
import dash from "./dashboard/reducer";
import details from "./details/reducer";
import warnings from "./warnings/reducer";
import whitelabel from "./whitelabel/reducer";
import whitelabelAdmin from "./whitelabelAdmin/reducer";
import reports from "./reports/reducer";
import tpmsProfiles from "./tpmsProfiles/reducer";
import provision from "./provision/reducer";
import common from "./common/reducer";
import tresholds from "./tresholds/reducer";
import assetAdminInfo from './assetAdminInfo/reducer'
import axleLoadCalibration from './axleLoadCalibration/reducer'
import documents from "./documents/reducer"
import geolocations from "./geolocations/reducer"
import geolocationDetails from "./geolocationDetails/reducer"
import tagDetails from "./tagDetails/reducer"
import geofences from "./geofences/reducer"
import getAdminLogs from './adminLogs/reducer'

const reducers = combineReducers({
  settings,
  auth,
  dash,
  details,
  warnings,
  whitelabel,
  whitelabelAdmin,
  reports,
  tpmsProfiles,
  provision,
  common,
  tresholds,
  assetAdminInfo,
  axleLoadCalibration,
  lastAction,
  documents,
  geolocations,
  geolocationDetails,
  tagDetails,
  getAdminLogs,
  geofences
});

function lastAction(state = null, action: Action) {
  return action;
}

export default reducers;

export type RootState = ReturnType<typeof reducers>