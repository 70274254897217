import React from 'react'
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation'
import { Line } from 'react-chartjs-2'
import zoom from 'chartjs-plugin-zoom'
import 'chartjs-adapter-moment'
import { IChartOptions, ILineChartProps, IAnnotationOptions, ITooltipItem } from './interfaces'
Chart.register(annotationPlugin, zoom)

const composeChartOptions: IChartOptions = (
    xMin, 
    xMax, 
    legendLabel, 
    displayX, 
    onZoomUpdate,  
    customTooltip,
    displayColors,
    xAxisLabel,
    annotations = {},
    assetMotionAnnotations = {}
) => {
    const allAnnotations: IAnnotationOptions = { ...annotations, ...assetMotionAnnotations }
    
    return {
        maintainAspectRatio: false,
        animation: {
            duration: 500
        },
        elements: {
            line: {
                tension: 0
            },
            point: {
                radius: 1.5,
                hitRadius: 4,
                hoverRadius: 3
            }
        },
        scales: {
            y: {
                id: "y",
                beginAtZero: true,
                grace: "20%",
                title: {
                    display: true,
                    text: legendLabel
                }
            },
            x: {
                id: "x",
                type: "time",
                bound: "data",
                display: displayX,
                title: {
                    display: true,
                    text: xAxisLabel
                },
                min: xMin,
                max: xMax,
                time: {
                    minUnit: "minute",
                    displayFormats: {
                        millisecond: "MMM DD, h:mm A",
                        second: "MMM DD, h:mm A",
                        minute: "MMM DD, h:mm A",
                        hour: "MMM DD, h A",
                        day: "MMM DD",
                        week: "MMM DD",
                        month: "MMM DD",
                        quarter: "MMM DD",
                        year: "MMM DD",
                    }
                },
                ticks: {
                    maxRotation: 10
                }
            }
        },
        plugins: {
            zoom: {
                limits: {
                    x: {min: xMin, max: xMax, minRange: 1000},
                },
                pan: {
                    mode: "x",
                    enabled: true,
                    threshold: 1,
                    onPan: () => {
                        if (onZoomUpdate) onZoomUpdate()
                    }
                },
                zoom: {
                    mode: "x",
                    drag: {
                        mode: "x",
                        enabled: false
                    },
                    wheel: {
                        enabled: true,
                        speed: 0.2,
                    },
                    onZoom: () => {
                        if (onZoomUpdate) onZoomUpdate()
                    }
                }
            },
            annotation: {
                annotations: allAnnotations
            },
            tooltip: customTooltip ? customTooltip : {
                displayColors: displayColors,
                backgroundColor: (tooltipItem) => tooltipItem.tooltip.labelColors[0].borderColor,
                callbacks: {
                    title: (tooltipItem) => tooltipItem[0].dataset.label || "",
                    label: (tooltipItem: ITooltipItem) => tooltipItem.raw.displayValue,
                    afterLabel: (tooltipItem: ITooltipItem) => `Date: ${tooltipItem.raw.displayDate}`,
                }
            }
        },
    }
}

const LineChart: React.FC<ILineChartProps> = ({
    id,
    data,
    annotations,
    xMin,
    xMax,
    legendLabel,
    height = 200,
    displayX,
    setRef,
    onZoomUpdate,
    customTooltip,
    onDoubleClick,
    displayColors = false,
    xAxisLabel = "Date",
    assetMotionAnnotations
}) => {
    return (
        <div id="line_chart" onDoubleClick={onDoubleClick} style={{ maxHeight: `${height}px` }}>
            <Line
                id={id}
                ref={(ref) => {
                    if (ref) {
                        setRef(ref)
                    }
                }}
                data={data}
                height={height}
                options={
                    composeChartOptions(
                        +xMin, 
                        +xMax, 
                        legendLabel, 
                        displayX, 
                        onZoomUpdate, 
                        customTooltip,
                        displayColors,
                        xAxisLabel,
                        annotations,
                        assetMotionAnnotations
                    )
                }
            />
        </div>
    )
}

export default LineChart