import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {Formik, Form} from "formik";
import {FormField} from './index'

export const SelectFormField = ({ options, ...props }) => {
    return (
      <FormField {...props} type="select" as="select">
        {options.map(({ value, label }, i) => (
          <option key={label + i} value={value} label={label} />
        ))}
      </FormField>
    );
  };
  
  let formControls = null
  
  const CreateModal = ({
    disabled,
    btnClassName,
    onCreate,
    createBtnLabel,
    openBtnLabel,
    header,
    children,
    initialValues,
    schema,
    onClose, // passing this means it is controlled
    open, // if controlled required
    locale = {},
    validate,
    resetFormValues,
    footerBtnDisabled
  }) => {
    const [modal, setModal] = useState(false);
    const [error, setError] = useState({ statusCode: 0, message: "" });
  
    const toggle = () => {
      if (open && onClose) onClose();
      else setModal(!modal);
    };
  
    const SubmitHandler = (resetForm, res, values) => {
      setError({ statusCode: res.statusCode, message: res.message })
      if (res.statusCode < 400) {
        typeof resetFormValues === "function" && resetFormValues()
        resetForm()
        toggle()
      }
    }
  
    return (
      <>
        {!onClose && (
          <Button
            disabled={disabled}
            size="md"
            className={btnClassName}
            onClick={toggle}
          >
            <i className="mr-2 fa fa-plus-circle"></i>
            {openBtnLabel}
          </Button>
        )}
        <Modal isOpen={(modal || open) && !disabled} unmountOnClose={false} variant="bla">
          <Formik
            validate={(values) => typeof validate === "function" ? validate(values, formControls) : null} // set correct value if user use wrong symbols without errors
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              Object.keys(values).forEach(value => { 
                if (typeof values[value] === "string") {
                  values[value] = values[value].trim() 
                }
              })
              const res = await onCreate(values);
              if (res) {
                SubmitHandler(resetForm, res, values)
              }
            }}
          >
            {(formProps) => {
              formControls = formProps
              return (
                <Form>
                  {header && <ModalHeader toggle={() => {
                    toggle()
                    formProps.resetForm()
                    setError({ statusCode: 0, message: "" })
                    typeof resetFormValues === "function" && resetFormValues()
                  }}>{header}</ModalHeader>}
  
                  <ModalBody>{children}</ModalBody>
                  
                  <ModalFooter>
                    {<div className="error mr-3">{error.statusCode >= 400 ? error.message : null}</div>}
                    <Button color="primary" type="submit" disabled={footerBtnDisabled}>
                      {createBtnLabel}
                    </Button>
                    <Button 
                      color="secondary" 
                      onClick={() => {
                        formProps.resetForm()
                        setError({ statusCode: 0, message: "" })
                        typeof resetFormValues === "function" && resetFormValues()
                        toggle()
                      }}>
                      {locale["cancel"] || "Cancel"}
                    </Button>
                  </ModalFooter>
                </Form>
              )
            }}
          </Formik>
        </Modal>
      </>
    );
  };
  
  export default CreateModal;
  