import React, { useEffect, useState } from "react";
import { Button, Input, Fade, Spinner } from "reactstrap";
import api from "../../services/api";
import { store } from "../../redux/store";
import { getFileTitle, isValidFileName } from "./utils";
import "./styles.scss";

interface Props {
  documents: Array<IDocument>;
  addDocument: (title: string) => void;
}

const DocumentsUploader: React.FC<Props> = ({ documents, addDocument }) => {
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileTitle, setFileTitle] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [key, setKey] = useState(0);

  const setFileData = (file: File | null, title: string) => {
    setSelectedFile(file);
    setFileTitle(title.trim());
  };

  const clearFileData = () => {
    setFileData(null, "");
    setKey((prev) => (prev += 1));
  };

  const onFileChange = (e: { target: HTMLInputElement }) => {
    setMessage("");

    const files = e.target.files;

    if (!files?.length) {
      clearFileData();
      return;
    }

    const file = files[0];
    setFileData(file, getFileTitle(file.name));
  };

  const onFileUpload = async () => {
    if (!selectedFile) return;

    if (selectedFile.type !== "application/pdf") {
      setError("Only PDF documents can be uploaded");
      return;
    }

    if (!isValidFileName(fileTitle)) {
      setError("Title contains illegal characters");
      return;
    }

    if (documents.some((document) => getFileTitle(document.file_name) === fileTitle)) {
      setError("Document with the same title already exists");
      return;
    }

    if (selectedFile.size > 4194304) {
      setError("The file size limit is 4 MB");
      return;
    }

    setUploading(true);

    const title = `${fileTitle}.pdf`;
    const blob: Blob = selectedFile.slice(0, selectedFile.size, "application/pdf");
    const newFile = new File([blob], title, { type: "application/pdf" });

    const reader = new FileReader();

    reader.addEventListener(
      "load",
      async function () {
        if (reader.result) {
          const base64 = reader.result.toString().split("base64,")[1];

          if (base64) {
            await api
              .postDocument(base64, title, store.getState().auth.credentials.idToken)
              .then(() => {
                clearFileData();
                addDocument(title);
                setMessage("The document upload is completed");
              })
              .catch(() => {
                setError("Failed to upload document");
              })
              .finally(() => {
                setUploading(false);
              });
          } else {
            setUploading(false);
            setError("Failed to upload document");
          }
        }
      },
      false
    );

    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };

  useEffect(() => {
    if (selectedFile && !fileTitle) {
      setError("Please enter a title!");
    } else {
      setError("");
    }
  }, [selectedFile, fileTitle]);

  return (
    <div className="d-flex flex-column document-uploader">
      <h4>Upload Document</h4>

      <div className="d-flex flex-column mt-1">
        <div className="d-flex align-items-center">
          <Input key={key} type="file" accept="application/pdf" className="file-input" onChange={onFileChange} />
        </div>

        {selectedFile && (
          <Fade className="mt-3">
            <span className="edit-title">Edit a title</span>

            <div className="d-flex mb-1">
              <Input
                className="title-input"
                value={fileTitle}
                onChange={(e: { target: HTMLInputElement }) => {
                  setFileTitle(e.target.value);
                }}
                disabled={uploading}
              />

              <Button className="ml-3" onClick={onFileUpload} disabled={!!error || uploading}>
                {uploading ? <Spinner size="sm" color="light" /> : "Upload"}
              </Button>
            </div>

            {error && <span className="error-message">{error}</span>}
          </Fade>
        )}

        {message && <span className="mt-3">{message}</span>}
      </div>
    </div>
  );
};

export default DocumentsUploader;
