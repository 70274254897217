import React, { useEffect } from 'react'
import SubNav from "../../components/SubNav"
import CustomersDropdown from '../../components/CustomersDropdown'
import ReportControls from '../../modules/ReportControls'
import ReportGrid from '../../modules/ReportGrid'

import { useCustomers } from './hooks.ts'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'

import { ReportTypes } from '../../enums'


const Reports = () => {
    const {
        reports: { report: { isLoading, selectedReportType } },
        locale,
        auth,
        customers: { selectedCustomer, data },
        subscriptions,
        hideAutogeneratedAssets
    } = useTypedSelector(state => ({
        reports: state.reports,
        locale: state.whitelabel.locale,
        auth: state.auth,
        customers: state.common.customers,
        hideAutogeneratedAssets: state.common.hideAutogeneratedAssets,
        subscriptions: state.common.customerDefaults.subscriptions
    }))
    
    const {
        setSelectedReportType,
        fetchReport,
        setSelectedCustomer,
        cancelReportAction,
        setHideAutogeneratedAssets
    } = useActions()

    const customersList = useCustomers(data, selectedReportType)

    useEffect(() => {
        if (!auth.isSuperAdmin) return
        if (selectedReportType === ReportTypes.TRIP_REPORT && selectedCustomer?.id === null) {
            setSelectedCustomer(customersList.find(el => el.name === "Rivata" ? true : false))
        } 
    }, [auth.isSuperAdmin, selectedReportType, customersList, setSelectedCustomer, selectedCustomer])

    return (
        <>
            <SubNav
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Reports" }
                ]}
            >
                {customersList.length > 1 && selectedCustomer?.name ? (
                    <CustomersDropdown
                        locale={locale}
                        label={locale.Customer || "Customer"}
                        onChange={(customer) => {
                            setSelectedCustomer(customer);
                        }}
                    />
                ) : null}
            </SubNav>
            <ReportControls
                isSuperAdmin={auth.isSuperAdmin}
                locale={locale} 
                fetchReport={fetchReport}
                selectedReport={selectedReportType}
                setSelectedReport={(report) => setSelectedReportType(report)}
                isLoading={isLoading}
                cancelReportAction={cancelReportAction}
                subscriptions={subscriptions}
                hideAutogeneratedAssets={hideAutogeneratedAssets}
                setHideAutogeneratedAssets={setHideAutogeneratedAssets}
            />
            <ReportGrid
                selectedReport={selectedReportType}
                locale={locale}
            />
        </>
    )
}
  
export default Reports