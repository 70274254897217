import { useEffect, useState } from "react";
import { ActivityEvent } from "../types";
import { convertDataEpochToDate, getTimeLabelFromSeconds } from "../../../utils";

const ColumnsIds = {
  Vin: "vin",
  AssetName: "assetname",
  Customer: "customer",
  EntryTime: "entrytime",
  ExitTime: "exittime",
  DwellTime: "dwelltime",
};

interface Row {
  data: ActivityEvent;
  columns: Array<{ columnId: string; label: string }>;
}

interface Column {
  label: string;
  id: string;
}

export const useColumns = () => {
  const [columns, setColumns] = useState<Array<Column>>([]);

  useEffect(() => {
    setColumns([
      {
        label: "Vin",
        id: ColumnsIds.Vin,
      },
      {
        label: "Asset Name",
        id: ColumnsIds.AssetName,
      },
      {
        label: "Customer",
        id: ColumnsIds.Customer,
      },
      {
        label: "Entry Time",
        id: ColumnsIds.EntryTime,
      },
      {
        label: "Exit Time",
        id: ColumnsIds.ExitTime,
      },
      {
        label: "Dwell Time",
        id: ColumnsIds.DwellTime,
      },
    ]);
  }, []);

  return columns;
};

export const useTableRows = (activityEvents: Array<ActivityEvent>) => {
  const [rows, setRows] = useState<Array<any>>([]);

  useEffect(() => {
    const dataRows: Array<Row> = [];

    activityEvents.forEach((e) => {
      const row = {
        data: e,
        columns: [
          {
            columnId: ColumnsIds.Vin,
            label: e.asset_vin,
          },
          {
            columnId: ColumnsIds.AssetName,
            label: e.asset_name,
          },
          {
            columnId: ColumnsIds.Customer,
            label: e.customer_name,
          },
          {
            columnId: ColumnsIds.EntryTime,
            label: e.entry_time ? convertDataEpochToDate(e.entry_time, null, null, false).format("MM/DD/YYYY hh:mm:ss A") : "-",
          },
          {
            columnId: ColumnsIds.ExitTime,
            label: e.exit_time ? convertDataEpochToDate(e.exit_time, null, null, false).format("MM/DD/YYYY hh:mm:ss A") : "-",
          },
          {
            columnId: ColumnsIds.DwellTime,
            label: getTimeLabelFromSeconds(e.seconds_in_geofence),
          },
        ],
      };

      dataRows.push(row);
    });

    setRows(dataRows);
  }, [activityEvents]);

  return rows;
};
