import React from 'react'
import momentTz from 'moment-timezone'
import * as Yup from "yup"
import { Row } from 'reactstrap'

import CountryCodeDropdown, { ICountryCode } from '../components/CountryCodeDropdown'
import CompanySelector from '../components/CompanySelector'

import { Timezones, RolePermission } from '../../../enums'
import { IUserFormValues, IValidate } from '../types'

import { useCustomerOptionsUpdate } from '../hooks'
import { FormField } from '../../../components/CreateModal'
import CreateModal, { SelectFormField } from '../../../components/CreateModal/children'

interface IDefaultList {
    [key: string]: string
}

const phoneRegExp = /^\+[0-9]{11,}$/;
const phoneNumberMessage = 'Phone number must begin with "+" and the country code, followed only by area code, prefix, and number(no dashes or spaces).'
const UserSchema = Yup.object().shape({
    username: Yup.string().required("Required").trim().lowercase(),
    email_address: Yup.string()
      .email("Must supply valid email")
      .required("Required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, phoneNumberMessage)
      .required("Required"),
    first_name: Yup.string(),
    last_name: Yup.string(),
    license_state: Yup.string(),
    timezone: Yup.string(),
    role: Yup.string(),
})

const tz: IDefaultList = Timezones
const tzOptions = Object.keys(tz).map(k => ({
    value: tz[k],
    label: `${momentTz.tz(tz[k]).format("Z")} ${tz[k]}`
}))

const roles: IDefaultList = RolePermission
const roleOptions = Object.keys(roles).map(k => ({
    value: roles[k],
    label: roles[k]
}))

interface IUpdateUserModal {
    locale: ILocale
    title: string
    formValues: IUserFormValues
    open?: boolean
    countryCode: ICountryCode
    isSuperAdmin: boolean
    isAdmin: boolean
    userCustomerIds: Array<number>
    selectedCustomer: ICustomer
    onSubmit: (data: IUserFormValues) => IError | void
    onClose?: () => void
    validate: IValidate | null
    resetFormValues: () => void
    setCountryCode: (code: ICountryCode) => void
    setFormValues: (data: IUserFormValues) => void
}

const UpdateUserModal: React.FC<IUpdateUserModal> = ({
    locale,
    title,
    formValues,
    open,
    countryCode,
    isSuperAdmin,
    isAdmin,
    userCustomerIds,
    selectedCustomer,
    onSubmit,
    onClose,
    validate,
    resetFormValues,
    setCountryCode,
    setFormValues
}) => {
    const { customerIds, refCustomers } = formValues
    const customerOption = useCustomerOptionsUpdate(isSuperAdmin, isAdmin, selectedCustomer, userCustomerIds, customerIds, refCustomers)

    const handleSetPermissions = (ids: Array<number> | null) => {
        const formData = { ...formValues }
        
        if (ids) {
            ids.forEach((id) => {
                const idx = formData.customerIds.findIndex(el => el === id ? true : false)
                if (idx > -1) {
                    formData.customerIds.splice(idx, 1)
                } else {
                    formData.customerIds.push(id)
                }
            })
        }
        
        setFormValues(formData)
    }

    return (
        <CreateModal
            disabled={false}
            btnClassName="ml-3"
            onCreate={onSubmit}
            createBtnLabel={title}
            openBtnLabel={`${title} User`}
            header={`${title} User`}
            locale={locale}
            schema={UserSchema}
            initialValues={formValues}
            onClose={onClose}
            open={open}
            validate={validate}
            resetFormValues={resetFormValues}
            footerBtnDisabled={!formValues.customerIds.length}
        >
            <Row>
                <FormField name="username" disabled={true} label="User Name" />
                <FormField name="email_address" disabled={true} type="email" label="Email" />
                <FormField name="first_name" label="First Name" />
                <FormField name="last_name" label="Last Name" />
                <div className="d-flex col-md-12 country-code__wrapper">
                    <CountryCodeDropdown
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                    />
                    <FormField className="pr-0 phone__input" name="phone_number" label="Phone Number" />
                </div>
                <SelectFormField name="timezone" label="Time Zone" options={tzOptions} />
                <SelectFormField name="role" label="Role" options={formValues.role === "" ? [ ...roleOptions, { value: "", label: "---" } ] : roleOptions}/>
                <CompanySelector
                    options={customerOption}
                    setPermissions={handleSetPermissions}
                    customerIds={formValues.customerIds}
                />
            </Row>
        </CreateModal>
    )
}

export default UpdateUserModal