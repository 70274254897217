import { kmhToMph, metersToFt } from "../../utils";
import { getDateRange, composeVertLineAnnotation } from '../../utils/chartUtils'
import { UnitsOfMeasurement } from '../../enums'

export const composeLineChartData = (data, unitsOfMeasurement, gpsColors) => {
  const charts = {
    elevation: {
      datasets: [
        {
          label: "Elevation",
          data: [],
          fill: false,
          backgroundColor: gpsColors.find((el) => el.id === "GpsElevation" ? true : false).color,
          borderColor: gpsColors.find((el) => el.id === "GpsElevation" ? true : false).color,
          borderWidth: 2,
        }
      ],
    },
    speed: {
      datasets: [
        {
          label: "Speed",
          data: [],
          fill: false,
          backgroundColor: gpsColors.find((el) => el.id === "GpsSpeed" ? true : false).color,
          borderColor: gpsColors.find((el) => el.id === "GpsSpeed" ? true : false).color,
          borderWidth: 2,
        }
      ],
    }
  }

  data.map((item) => {
    const elevation = unitsOfMeasurement === UnitsOfMeasurement.imperial ? metersToFt(item.elevation) : item.elevation;
    const speed = unitsOfMeasurement === UnitsOfMeasurement.imperial ? kmhToMph(item.speed) : item.speed;
    
    const elevationData = {
      x: item.datetime,
      y: elevation,
      displayValue: `Elevation: ${elevation.toFixed(1) + (unitsOfMeasurement === UnitsOfMeasurement.imperial ? "ft" : "m")}`,
      displayDate: item.formatted_datetime
    }
    const speedData = {
      x: item.datetime,
      y: speed,
      displayValue: `Speed: ${speed.toFixed(2) + (unitsOfMeasurement === UnitsOfMeasurement.imperial ? "mph" : "kmh")}`,
      displayDate: item.formatted_datetime
    }

    charts.elevation.datasets[0].data.push(elevationData)
    return charts.speed.datasets[0].data.push(speedData)
  })

  charts.elevation.datasets[0].data = charts.elevation.datasets[0].data.sort((d1, d2) => +new Date(d1.displayDate) - +new Date(d2.displayDate))
  charts.speed.datasets[0].data = charts.speed.datasets[0].data.sort((d1, d2) => +new Date(d1.displayDate) - +new Date(d2.displayDate))

  // x axis range
  const range = getDateRange(charts.elevation.datasets)

  charts.elevation.xMax = range.max
  charts.elevation.xMin = range.min
  charts.speed.xMax = range.max
  charts.speed.xMin = range.min

  return charts
}

export const composeAnnotations = (id, timestamp, healthColors) => {
  const annotations = {}

  if (timestamp) {
    annotations.verticalLine = composeVertLineAnnotation(id, timestamp, healthColors[1].color)
  }
  
  return annotations
}