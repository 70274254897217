import React from "react";
import RivataLoader from '../../components/RivataLoader'
import RivataModule from "../../components/RivataModule";
import { Doughnut } from "react-chartjs-2";
import { useChartData, useChartColors } from "./hooks";

const WarningsChart = ({
  isLoading,
  width,
  healthColors,
  locale,
  locations
}) => {
  const { healthGood, healthWarning } = useChartColors(healthColors);
  const chartData = useChartData(
    healthGood,
    healthWarning,
    locale,
    isLoading,
    locations
  );

  return (
    <RivataModule title="Warnings" width={width} locale={locale}>
      {isLoading || !chartData ? (
        <RivataLoader/>
      ) : (
        <Doughnut
          height={200}
          data={chartData}
          options={{ maintainAspectRatio: false }}
        />
      )}
    </RivataModule>
  );
};

export default WarningsChart;
