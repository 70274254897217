import { removeAllListeners } from "process";
import React, { useEffect, useState } from "react";
import { history } from "../../../jwt/_helpers";
import { getTooltipCoordinates, getTooltipContent, wktToGeometry } from "../utils";

interface Props {
  map: H.Map | null;
  ui?: H.ui.UI | null;
  edit?: boolean,
  zoomTo?: number | null;
  geofences: Array<IGeofence>;
  geofencesVisible?: boolean;
  setBounds?: boolean;
  vin?: string;
  setActiveTab?: ()=>void
}

const GeofenceRenderer: React.FC<Props> = ({ map, ui, zoomTo, geofences, geofencesVisible, setBounds, vin, edit = false, setActiveTab }) => {
  const [polygons, setPolygons] = useState<Array<H.map.Polygon>>([]);
  const [counter, setCounter]=useState<number>(0)
  const [polIndexes, setPolIndexes] = useState<Array<number>>([])
  const [targetId, setTargetId] = useState<number | null>(null)
  const [newPsn, setNewPsn] = useState<any>(null)

  const removeBubbles = () => {
    if (!ui) return;

    const bubbles = ui.getBubbles();

    bubbles.forEach((bubble) => {
      const data = bubble.getData();
      if (data?.type === "geofence") ui.removeBubble(bubble);
    });
  };
  
  const onPointerEnter =(e: any)=>{
    if (!map || !ui) return;
    removeBubbles();
    const id = e.target.getId()
    setPolIndexes((prev:any) => {
      return [...prev, id]
    })
    setTargetId(id)
    const data = e.target.getData();
    const viewPort = map.getViewPort();
    const mouseCoordinates = map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY);
    const pixelCoordinates = map.geoToScreen({ lat: mouseCoordinates.lat, lng: mouseCoordinates.lng });

    const { x, y } = getTooltipCoordinates(pixelCoordinates.x, pixelCoordinates.y, viewPort.width, data);

    const bubble = new H.ui.InfoBubble(map.screenToGeo(x, y), {
      content: getTooltipContent(data),
    });

    bubble.setData(data);
    bubble.addClass("geofence-info-bubble");
    ui.addBubble(bubble);
    document.getElementById("details_geofence")?.addEventListener("click", () => {
      let isVin = vin ? vin : "";
      
      history.push(`/geofences/${data.id}/${isVin}`);
      //window.location.reload()
     setActiveTab && setActiveTab()
    });
    setCounter(prev => prev + 1)
  }
  
//// add zIndexes for internal geofences
  useEffect(()=>{
    let ids = new Set(polIndexes)
    const targetPolygon = polygons.find((pol)=> pol.getId() === targetId)

    if(!targetPolygon?.getZIndex()){
      targetPolygon?.setZIndex(0)
    }

    polygons.forEach((p)=>{
      const pId = p.getId()
      const isInSet = ids.has(pId)
      if(!isInSet){
        const parentZIndex  = targetPolygon?.getZIndex() as number
        p.setZIndex(parentZIndex + 10)
      }
    })
  },[counter, polIndexes, polygons, targetId])

  ///clear indexes array if move from geofence to normal map
  useEffect(()=>{
    const onIndexesClear = ()=> {
      setPolIndexes([])
    }
    map?.addEventListener('pointerenter', onIndexesClear)
    return(()=> map?.removeEventListener('pointerenter', ()=>{}))
  },[map])

  const onPointerLeave = () => {
    removeBubbles();
  };

  useEffect(() => {
    const dispose = () => {
      polygons.forEach((polygon) => {
        polygon.removeEventListener("pointerenter", onPointerEnter);
        polygon.removeEventListener("pointerleave", onPointerLeave);
        polygon.dispose();
      });
    };

    if (map && geofences) {
      dispose();
      const listenersHandler = (id: number, e: any) => {

        polygons.forEach((p) => {
          if (p.getData().id === id) {
            onPointerEnter(e);
            p.removeEventListener("pointerleave", onPointerLeave);
            p.removeEventListener("pointerenter", onPointerEnter);
          } else if (p.getData().id !== id) {
            p.addEventListener("pointerleave", onPointerLeave);
          }
          p.addEventListener("pointerenter", onPointerEnter);
        });
      };

      const group = new H.map.Group();
      const polygons = geofences
        .filter((g) => g.geofence && g.geofence.includes("POLYGON"))
        .map(({ geofence }) => new H.map.Polygon(wktToGeometry(geofence!)));

      polygons.forEach((polygon, index) => {
        const data = geofences[index];
        polygon.setData({
          id: data.id,
          name: data.name,
          customerName: data.customer_name,
          created: data.formatted_created_at_datetime,
          type: "geofence",
        });
        polygon.addEventListener("pointerenter", onPointerEnter);
        polygon.addEventListener("pointerleave", onPointerLeave);
       
        polygon.addEventListener("tap", (e) => {
          let id = polygon.getData().id;
          listenersHandler(id, e);
        });
      });
      group.addObjects(polygons);
      map.addObject(group);

      if (setBounds) {
        const boundingBox = group.getBoundingBox();
        map.addEventListener('dragend', () => {
          let bounds = map.getViewModel().getLookAtData().bounds;
          setNewPsn(bounds)
        });
        map.addEventListener('pointermove', () => {
          let bounds = map.getViewModel().getLookAtData().bounds;
          setNewPsn(bounds)
        });
        
        if (boundingBox) {
          let bounds = boundingBox
          if (edit && newPsn) {
            bounds = newPsn
          }
          
          map.getViewModel().setLookAtData(
            {
              bounds,
            },
            false
          );
        }
      }

      setPolygons(polygons);
    }

    return () => {
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, geofences]);

  useEffect(() => {
    const setVisible = (value: boolean) => {
      polygons.forEach((polygon) => polygon.setVisibility(value));
    };

    if (geofencesVisible === true) setVisible(true);

    if (geofencesVisible === false) setVisible(false);
  }, [geofencesVisible, polygons]);

  useEffect(() => {
    if (!map || !zoomTo) return;

    for (const polygon of polygons) {
      if (polygon.getData()?.id === zoomTo) {
        map.getViewModel().setLookAtData(
          {
            bounds: polygon.getBoundingBox(),
          },
          false
        );
        break;
      }
    }
  }, [map, zoomTo, polygons]);


  return <></>;
};

export default GeofenceRenderer;
