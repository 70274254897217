import React, { useEffect, useMemo } from "react";
import SubNav from "../../components/SubNav";
import WarningsBody from "./WarningsBody";
import { useActions } from "../../hooks/useActions";
import { getClearWheelPosition } from "../../utils";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const WarningDetails = ({ tpmsEnabled, ...pageProps }) => {
  const { fetchWarningDetails } = useActions();
  const { assetInfo, whiteLabelConfig, graphColors, locale } = useTypedSelector(
    (state) => ({
      assetInfo: state.warnings.assetInfo.data,
      whiteLabelConfig: state.whitelabel.warnings,
      graphColors: state.whitelabel.wheelColors,
      locale: state.whitelabel.locale,
    })
  );

  let { vin, timestamp, days, warningKey } = pageProps.match.params;

  useEffect(() => {
    fetchWarningDetails(timestamp, vin)
  }, [fetchWarningDetails, timestamp, vin, warningKey])

  const components = useMemo(() => {
    if (Array.isArray(whiteLabelConfig)) {
      return whiteLabelConfig
        .filter((component) => component.data.enabled)
        .map(({ id, data }) => ({ component: id, width: data.width }));
    }
    return [];
  }, [whiteLabelConfig]);
  const { customer_name, name } = assetInfo;

  const colors = useMemo(() => {
    return getClearWheelPosition(graphColors);
  }, [graphColors]);

  return (
    <>
      <SubNav
        title={`Warning Details For Asset: ${customer_name}, ${
          name ? name : vin ? vin : "-"
        }`}
        breadcrumbs={[
          { label: "Home", path: "/dashboard" },
          { label: "Asset Details", path: `/details/${vin}` },
          { label: "Warning Details" },
        ]}
      />
      <WarningsBody
        vin={vin}
        timestamp={timestamp}
        days={days}
        components={components}
        graphColors={colors}
        locale={locale}
        tpmsEnabled={tpmsEnabled}
        warningKey={warningKey}
      />
    </>
  );
};

export default WarningDetails;
