import React from "react";

import "./style.scss";

const PositionBubble = ({ label, style }) => {
  return (
    <div className="position-bubble" style={style}>
      <span>{label}</span>
    </div>
  );
};

export default PositionBubble;
