import {
    LOADING_REPORTS_ASSETS,
    SET_REPORTS_ASSETS,
    SET_REPORTS_ASSETS_ERROR,
    LOADING_REPORT,
    SET_REPORT_COLUMNS,
    SET_REPORT_ROWS,
    SET_REPORT_ERROR,
    SET_SELECTED_REPORT_TYPE,
    CANCEL_REPORT
} from "../actionTypes"
import api from "../../services/api";
import { store } from '../store'
import { getToken, getErrorObj } from "../../utils";

export const setSelectedReportType = (type) => ({
    type: SET_SELECTED_REPORT_TYPE,
    payload: type,
})

export const loadingAssets = (isLoading) => ({
    type: LOADING_REPORTS_ASSETS,
    payload: isLoading,
})
  
export const setAssets = (assets) => ({
    type: SET_REPORTS_ASSETS,
    payload: assets,
})
  
export const setAssetsError = (error) => ({
    type: SET_REPORTS_ASSETS_ERROR,
    payload: error,
})
  
export const loadingReport = (isLoading) => ({
    type: LOADING_REPORT,
    payload: isLoading,
})
  
export const setReportColumns = (columns) => ({
    type: SET_REPORT_COLUMNS,
    payload: columns,
})

export const setReportRows = (rows) => ({
    type: SET_REPORT_ROWS,
    payload: rows,
})
  
export const setReportError = (error) => ({
    type: SET_REPORT_ERROR,
    payload: error,
})

export const cancelReportAction = () => ({
    type: CANCEL_REPORT
})
  
export function fetchAssets() {
    return function (dispatch, getState) {
        dispatch(loadingAssets(true))
        return api
            .getAssets(getToken(getState))
            .then((assets) => {
                dispatch(setAssets(assets.paged_data))
                dispatch(setAssetsError(getErrorObj()))
            })
            .catch((error) => dispatch( setAssetsError( getErrorObj(error) ) ))
            .finally(() => dispatch(loadingAssets(false)))
    }
}

let interval // send request every 5 seconds until you get report data
const defaultErrorMessage = "Something went wrong, please try again later"
export function fetchReport(link) {
    return function (dispatch, getState) {
        dispatch(loadingReport(true))
        const token = getToken(getState)
        const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets
        if (hideAutogeneratedAssets) link += "&hide_autogenerated=true"
        
        let isFetching = false

        return api
            .getReport(link, token)
            .then((s3Key) => {
                const checkStatus = async () => {
                    if (isFetching) return clearInterval(interval)
                    const res = await api.getReportStatusAndUrl(token, s3Key)
                    if (res && res.isReady) {
                        isFetching = true
                        const data = await api.getReportFromUrl(res.url)
                        dispatch(setReportColumns(data.columns || []))
                        const rows = data.rows.map((row)=>{
                            if(row.asset_type){
                                return {...row, asset_type: row.asset_type.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}
                            }
                            if(row["Asset Type"]){
                                // eslint-disable-next-line
                                return {...row, ["Asset Type"]: row["Asset Type"].replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}
                            }
                            return row
                        })
                        dispatch(setReportRows(rows || []))
                        dispatch(setReportError( data.error ? getErrorObj({ statusCode: 400, message: "" }, defaultErrorMessage) :  getErrorObj() ))
                        dispatch(loadingReport(false))
                        clearInterval(interval)
                    }
                }

                interval = setInterval(checkStatus, 5000)
            })
            .catch((error) => dispatch( setReportError( getErrorObj(error) ) ))
    }
}

store.subscribe(() => {
    const lastAction = store.getState().lastAction

    if (lastAction.type === SET_SELECTED_REPORT_TYPE || lastAction.type === CANCEL_REPORT) {
        store.dispatch(setReportColumns([]))
        store.dispatch(setReportRows([]))
        store.dispatch(setReportError({ statusCode: 200, message: "Use Report Controls To Get Data" }))
        store.dispatch(loadingReport(false))
        clearInterval(interval)
    }
})