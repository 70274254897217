import {
  LOADING_WHITELABEL,
  SET_WHITELABEL,
  SET_WHITELABEL_ERROR,
} from "../actionTypes";
import api from "../../services/api";
import { getToken } from "../../utils";

export const loadingWhitelabel = (isLoading) => ({
  type: LOADING_WHITELABEL,
  payload: isLoading,
});

export const setWhitelabel = (assets) => ({
  type: SET_WHITELABEL,
  payload: assets,
});

export const setWhitelabelError = (error) => ({
  type: SET_WHITELABEL_ERROR,
  payload: error,
});

export function fetchWhitelabel() {
  return function (dispatch, getState) {
    dispatch(loadingWhitelabel(true));
    return api
      .getConfig(getToken(getState))
      .then((config) => {
        dispatch(setWhitelabel(config));
        dispatch(setWhitelabelError(false));
      })
      .catch(() => dispatch(setWhitelabelError(true)))
      .finally(() => dispatch(loadingWhitelabel(false)));
  };
}
