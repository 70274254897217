import React, { useMemo } from "react";

import { getPositions, getAxles, getIntervals, indexAjustment } from "../utils";

import Axle from "../axle/Axle";

import PositionBubble from "../common/PositionBubble";

const Trailer = ({ height, sensors = [], pressureUnit, getTireData, timezone, locale }) => {
  const axleHeight = 250;
  const axleInterval = 100;
  const axleLeftMargin = 20;

  const trailerPositions = useMemo(
    () => getPositions(["TRAILER_LEFT", "TRAILER_RIGHT"]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sensors]
  );

  const axles = useMemo(() => getAxles(sensors, trailerPositions, "trailer"), [sensors, trailerPositions]);
  const intervals = useMemo(() => getIntervals(axles, axleInterval, axleLeftMargin), [axles]);
  

  const trailerWidth = useMemo(() => {
    let width = intervals.length * 100 + 70;
    if (width < 270) width = 270;
    return width;
  }, [intervals.length]);

  return (
    <div className="d-flex align-items-center" style={{ position: "relative", height: `${height}px` }}>
      <div className="trailer-rear" style={{ width: `${trailerWidth}px`, height: "220px" }}></div>

      {axles.map((axle, index) => {
        let i = indexAjustment(index, axle.order-1, axles, intervals)
        return (
          <Axle
            key={axle.order}
            axle={axle}
            axleType="doubleTrailerAxle"
            style={{ position: "absolute", left: `${intervals[i]}px` }}
            height={axleHeight}
            pressureUnit={pressureUnit}
            getTireData={getTireData}
            timezone={timezone}
            locale={locale}
          />
        );
      })}

      {axles.map((axle, index) => {
        let i = indexAjustment(index, axle.order-1, axles, intervals)
        return(
          <PositionBubble key={axle.positionBubble} label={axle.positionBubble} style={{ left: `${intervals[i] + 2.5}px` }} />
        )
      }
        
      )}
    </div>
  );
};

export default Trailer;
