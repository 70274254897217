import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'

import RivataDropdown from '../../components/RivataDropdown'
import LineChart from '../../components/RivataLineChart'
import RivataLoader from '../../components/RivataLoader'
import RivataModule from '../../components/RivataModule'
import StatusAlert from '../../components/StatusAlert'

import { getMotionAnnotations, composeCustomTooltip } from '../../utils/chartUtils'
import { IComposedChartData, IPowerInputsChart } from './types'
import { composeChartData } from './utils'


const customTooltip = composeCustomTooltip({
    displayColors: true,
    footer: true,
})

let chart: any = {}

const doubleClickHandler = () => {
    if (chart.resetZoom) {
        chart.resetZoom()
    }
}

const PowerInputsChart: React.FC<IPowerInputsChart> = ({
    locale,
    data,
    error,
    isLoading,
    speedData,
    timezone
}) => {
    const [selectedDataset, setSelectedDataset] = useState("all")
    const [chartData, setChartData] = useState<IComposedChartData | null>(null)

    useEffect(() => {
        if (!data) return
        
        setChartData(composeChartData(data))
    }, [data])

    const datasetsWithFIlter: any = useMemo(() => {
        if (!chartData) return {}

        const datasets = chartData.datasets

        if (selectedDataset === "all") return { datasets }

        const elem = datasets.find(el => el.id === selectedDataset)
        if (elem) {
            return {
                datasets: [elem]
            }
        }

        return {}
    }, [chartData, selectedDataset])

    const assetMotionAnnotations = useMemo(() => {
        return getMotionAnnotations(speedData, "power-input");
    }, [speedData]);
    
    
    return (
        <RivataModule
            fullScreenModalModeEnabled
            title={"Power / Inputs"}
            locale={locale}
            error={error}
            collapsible
            filters={
                <>
                    {chartData ? (
                        <RivataDropdown
                            items={chartData.dropdownItems}
                            selected={selectedDataset}
                            onSelect={(id: string) => {
                                if (id !== selectedDataset) {
                                    setSelectedDataset(id)
                                    doubleClickHandler() // reset zoom
                                }
                            }}
                            disabled={false}
                            buttonLabel={null}
                            onRemove={null}
                            locale={locale}
                            color={"secondary"}
                            filters={null}
                        />
                    ) : null}
                </>
            }
        >
            {isLoading ? (
                <RivataLoader/>
            ) : chartData ? (
                <LineChart
                    id={"power-input"}
                    data={datasetsWithFIlter}
                    xMax={chartData.xMax * 1000}
                    xMin={chartData.xMin * 1000}
                    legendLabel={'Voltage'}
                    height={400}
                    setRef={(r) => chart = r}
                    onZoomUpdate={() => {}}
                    onDoubleClick={doubleClickHandler}
                    displayColors={false}
                    xAxisLabel={`Date (${moment().tz(timezone).format("z")})`}
                    displayX={true} 
                    customTooltip={customTooltip}
                    assetMotionAnnotations={assetMotionAnnotations}
                />
            ) : <StatusAlert customText="No Data" color="success" statusCode={null} statusText={null}/>}
        </RivataModule>
    )
}

export default PowerInputsChart