import React from 'react'
import { Col, Label, Input } from 'reactstrap'
import { ICustomerOptions } from '../types'

interface ICompanySelectorProps {
    customerIds: Array<number>
    options: Array<ICustomerOptions>
    setPermissions: (id: Array<number> | null) => void
}

const CompanySelector: React.FC<ICompanySelectorProps> = ({
    customerIds,
    options,
    setPermissions
}) => {
    const labelCls:Array<string> = ["mb-0"]
    const checkboxCls:Array<string> = ["mb-1"]
    return (
        <Col md="12">
            <h6>Customers</h6>
            <div>
                {options.map((customer, i) => {
                    i > 0 && labelCls.push("ml-4") && checkboxCls.push("ml-1")
                    return(
                        <Col key={customer.id} className="d-flex align-items-center ml-2">
                        <Label className={labelCls.join(" ")}>{customer.name}</Label>
                        <Input 
                            type="checkbox" 
                            className={checkboxCls.join(" ")}
                            disabled={customer.disabled}
                            checked={customer.id ? customerIds.includes(customer.id) : false}
                            onChange={() => {
                                setPermissions([customer.id])
                            }} 
                        />
                    </Col>
                    )
                }

                )}
            </div>
        </Col>
    )
}

export default CompanySelector