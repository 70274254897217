import React, { useEffect, useMemo, useState } from "react";
import { dateToEpoch } from "../../utils";
import moment from "moment";
import SubNav from "../../components/SubNav";
import DetailsBody from "./DetailsBody";
import ModalLogInfo from "../../components/LogsModal";
import { getClearWheelPosition } from "../../utils";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const AssetDetails = ({
  match: { params }
}) => {
  const {
    vehicleInfo: { name, vin, customer_name },
    whiteLabelConfig,
    graphColors,
    locale,
    adminInfo } = useTypedSelector(
    (state) => ({
      vehicleInfo: state.details.vehicleInfo.data,
      whiteLabelConfig: state.whitelabel.details,
      graphColors: state.whitelabel.wheelColors,
      locale: state.whitelabel.locale,
      adminInfo: state.getAdminLogs,
    })
  );

  const {
    fetchDetails,
    fetchAdminLogs,
    resetTable
  } = useActions()

  useEffect(() => {
    fetchDetails(params.vin)
  }, [fetchDetails, params.vin])

  const components = useMemo(() => {
    if (Array.isArray(whiteLabelConfig)) {
      return whiteLabelConfig
        .filter((component) => component.data.enabled)
        .map(({ id, data }) => ({ component: id, width: data.width }));
    }
    return [];
  }, [whiteLabelConfig]);

  const colors = useMemo(() => {
    return getClearWheelPosition(graphColors);
  }, [graphColors]);

  
  const [logModal, setLogModal] = useState(false);
  const defaultData = {
    hardware_id: "",
    mac: "",
    date: {
      fromDate: moment().format(),
      toDate: moment().format(),
    },
    logsType: "",
    time: { from: "00:00", to: "23:59" },
    offset: 0,
    logsCount: 10,
  };
  const [fetchData, setFetchData] = useState(defaultData);

  const { hardware_id, mac, date, logsType, time, offset, logsCount } =
    fetchData;
  const onModalClose = () => {
    setLogModal(false);
  };
  const setTime = (time, fromTo) => {
    fromTo === "from"
      ? setFetchData({
          ...fetchData,
          offset: 0,
          time: { ...fetchData.time, from: time },
        })
      : setFetchData({
          ...fetchData,
          offset: 0,
          time: { ...fetchData.time, to: time },
        });
  };

  const setUserEpoch = (dates) => {
    const { from, to } = fetchData.time;
    const { fromDate, toDate } = dates;
    return dateToEpoch(fromDate, toDate, from, to);
  };

  const fetchLogs = (epoch, fromPage, toPage) => {
    const { startDate, endDate } = setUserEpoch(epoch);
    const gatewaysType =
      logsType === "gateway_translator" || logsType === "gateway_rivata";
    logsType === "sensors" &&
      fetchAdminLogs(hardware_id, logsType, startDate, fromPage, toPage);
    const isMac = logsType === "gateway_rivata" ? hardware_id : mac;
    gatewaysType &&
      fetchAdminLogs(isMac, logsType, startDate, fromPage, 20, endDate);
  };

  const onLogsPageChange = (step) => {
    setFetchData({ ...fetchData, offset: step });
    let start = step-logsCount
    let end = step <= 0 ? logsCount : step;
    if (step <= 0 && logsType ==='sensors') {
      start = 0
    }
    if(step !== 0 && logsType ==='sensors') {
      start=step
      end=step+logsCount
    }
    if (logsType !== "sensors") {
      start = step;
    }
    fetchLogs(date, start, end);
  };

  const onSelectLimit = (num) => {
    setFetchData({ ...fetchData, offset: 0, logsCount: num });
    fetchLogs(date, 0, num);
  };
  const onSubmit = (data) => {
    setFetchData({ ...fetchData, offset: 0, logsCount: 10 });
    fetchLogs(data, offset, logsCount);
  };

  const onSelectBase = (baseType) => {
    const type = baseType.toLowerCase().replace(" ", "_");
    setFetchData({
      ...fetchData,
      logsType: type,
      time: { from: "00:00", to: "23:59" },
      offset: 0,
      logsCount: 20,
    });
    const { startDate, endDate } = setUserEpoch(date);
    const isMac = type === "gateway_rivata" ? hardware_id : mac;
    fetchAdminLogs(isMac, type, startDate, 0, 20, endDate);
  };

  const setAdminLogs = (id, updatedDate, type, mac = "") => {
    const gatewaysType =
      type === "gateway_rivata" || type === "gateway_translator";
    const { startDate, endDate } = dateToEpoch(
      updatedDate,
      updatedDate,
      time.from,
      time.to
    );
    setFetchData({
      ...fetchData,
      hardware_id: id,
      mac,
      date: {
        fromDate: moment(startDate * 1000).format(),
        toDate: moment(startDate * 1000).format(),
      },
      logsType: type,
      logsCount: type !== "sensors" ? 20 : 10,
      time: { from: "00:00", to: "23:59" },
    });
    type === "sensors" && fetchAdminLogs(id, type, startDate, 0, 9);
    gatewaysType && fetchAdminLogs(mac, type, startDate, 0, 20, endDate);
    setLogModal(true);
  };

  const formReset = (formData) => {
    const { fromDate, toDate, from, to } = formData;
    setFetchData({
      ...fetchData,
      date: {
        fromDate,
        toDate,
      },
      time: { from, to },
    });
  };

  return (
    <>
      <SubNav
        title={`Asset Details For Asset: ${customer_name}, ${
          name ? name : vin ? vin : "-"
        }`}
        breadcrumbs={[
          { label: "Home", path: "/dashboard" },
          { label: `Asset Details` },
        ]}
      />
      <DetailsBody
        components={components}
        whiteLabelConfig={whiteLabelConfig}
        graphColors={colors}
        locale={locale}
        adminLogs={adminInfo}
        getAdminLogs={fetchAdminLogs}
        setAdminLogs={setAdminLogs}
        onGatewayLogsGet={setAdminLogs}
        customer_name={customer_name}
      />
      {logModal ? (
        <ModalLogInfo
          onClose={onModalClose}
          logsData={adminInfo}
          isOpen={logModal}
          type={logsType}
          setType={(type) => onSelectBase(type)}
          onSelectLimit={onSelectLimit}
          date={date}
          offset={offset}
          onSubmit={onSubmit}
          logsCount={logsCount}
          resetTable={resetTable}
          formReset={formReset}
          onLogsPageChange={onLogsPageChange}
          setDate={(dateFromCalendar) =>
            setFetchData({
              ...fetchData,
              date: dateFromCalendar,
              offset: 0,
              time: { from: "00:00", to: "23:59" },
            })
          }
          time={time}
          setTime={setTime}
          onSetDefaultData={() => setFetchData(defaultData)}
        />
      ) : null}
    </>
  );
}
export default AssetDetails

