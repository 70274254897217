import {
    SetLoading, 
    SetError, 
    ISortOptions, 
    SetSortOptions, 
    SetFilter
} from './../types'


export enum ProvisionActionTypes {
    LOADING_ASSETS = "LOADING_PROVISION_ADMIN_ASSETS",
    SET_ASSETS = "SET_PROVISION_ADMIN_ASSETS",
    SET_ASSETS_COUNT = "SET_PROVISION_ADMIN_ASSETS_COUNT",
    SET_ASSETS_SORT_OPTIONS = "SET_PROVISION_ADMIN_ASSETS_SORT_OPTIONS",
    SET_ASSETS_LIMIT = "SET_PROVISION_ADMIN_ASSETS_LIMIT",
    SET_ASSETS_OFFSET = "SET_PROVISION_ADMIN_ASSETS_OFFSET",
    SET_ASSETS_ERROR = "SET_PROVISION_ADMIN_ASSETS_ERROR",

    LOADING_TPMS_ASSETS = "LOADING_PROVISION_ADMIN_TPMS_ASSETS",
    SET_TPMS_ASSETS = "SET_PROVISION_ADMIN_TPMS_ASSETS",
    SET_TPMS_ASSETS_COUNT = "SET_PROVISION_ADMIN_TPMS_ASSETS_COUNT",
    SET_TPMS_ASSETS_LIMIT = "SET_PROVISION_ADMIN_TPMS_ASSETS_LIMIT",
    SET_TPMS_ASSETS_OFFSET = "SET_PROVISION_ADMIN_TPMS_ASSETS_OFFSET",
    SET_TPMS_ASSETS_SORT_OPTIONS = "SET_PROVISION_ADMIN_TPMS_ASSETS_SORT_OPTIONS",
    SET_TPMS_ASSETS_FILTER = "SET_PROVISION_ADMIN_TPMS_ASSETS_FILTER",
    SET_TPMS_ASSETS_ERROR = "SET_PROVISION_ADMIN_TPMS_ASSETS_ERROR",

    LOADING_USERS = "LOADING_PROVISION_ADMIN_USERS",
    SET_USERS = "SET_PROVISION_ADMIN_USERS",
    SET_USERS_SORT_OPTIONS = "SET_PROVISION_ADMIN_USERS_SORT_OPTIONS",
    SET_USERS_COUNT = "SET_PROVISION_ADMIN_USERS_COUNT",
    SET_USERS_LIMIT = "SET_PROVISION_ADMIN_USERS_LIMIT",
    SET_USERS_OFFSET = "SET_PROVISION_ADMIN_USERS_OFFSET",
    SET_USERS_ERROR = "SET_PROVISION_ADMIN_USERS_ERROR",

    SET_TPMS_PROFILES = "SET_PROVISION_ADMIN_TPMS_PROFILES",

    RESET_PROVISION_PAGE_REDUCER = "RESET_PROVISION_PAGE_REDUCER"
}


interface IStateCluster<T> {
    data: Array<T>
    totalCount: number
    pageLimit: number
    pageOffset: number
    sortOptions: ISortOptions
    filter?: { filterType?: string, filterData?: string }
    error: IError
    loading: boolean
}

interface ProvisionTpmsProfile { id: number | string, label: string }
  

export interface ProvisionReducer {
    assets: IStateCluster<IAsset>,
    tpmsAssets: IStateCluster<any>,
    users: IStateCluster<any>,
    tpmsProfiles: Array<ProvisionTpmsProfile>
}


// ASSETS ACTION TYPES 
type LoadingAssets = SetLoading<ProvisionActionTypes.LOADING_ASSETS> 
type SetAssetsError = SetError<ProvisionActionTypes.SET_ASSETS_ERROR>
type SetAssetsSortOptions = SetSortOptions<ProvisionActionTypes.SET_ASSETS_SORT_OPTIONS>
interface SetAssets {
    type: ProvisionActionTypes.SET_ASSETS,
    payload: Array<IAsset>
}
interface SetAssetsCount {
    type: ProvisionActionTypes.SET_ASSETS_COUNT,
    payload: number
}
interface SetAssetsLimit {
    type: ProvisionActionTypes.SET_ASSETS_LIMIT,
    payload: number
}
interface SetAssetsOffset {
    type: ProvisionActionTypes.SET_ASSETS_OFFSET,
    payload: number
}


// TPMS ASSETS ACTION TYPES
type LoadingTpmsAssets = SetLoading<ProvisionActionTypes.LOADING_TPMS_ASSETS> 
type SetTpmsAssetsError = SetError<ProvisionActionTypes.SET_TPMS_ASSETS_ERROR>
type SetTpmsAssetsSortOptions = SetSortOptions<ProvisionActionTypes.SET_TPMS_ASSETS_SORT_OPTIONS>
type SetTpmsAssetsFilter = SetFilter<ProvisionActionTypes.SET_TPMS_ASSETS_FILTER>
interface SetTpmsAssets {
    type: ProvisionActionTypes.SET_TPMS_ASSETS,
    payload: Array<any>
}
interface SetTpmsAssetsCount {
    type: ProvisionActionTypes.SET_TPMS_ASSETS_COUNT,
    payload: number
}
interface SetTpmsAssetsLimit {
    type: ProvisionActionTypes.SET_TPMS_ASSETS_LIMIT,
    payload: number
}
interface SetTpmsAssetsOffset {
    type: ProvisionActionTypes.SET_TPMS_ASSETS_OFFSET,
    payload: number
}


// USERS ACTION TYPES
type LoadingUsers = SetLoading<ProvisionActionTypes.LOADING_USERS> 
type SetUsersError = SetError<ProvisionActionTypes.SET_USERS_ERROR>
type SetUsersSortOprions = SetSortOptions<ProvisionActionTypes.SET_USERS_SORT_OPTIONS>
interface SetUsers {
    type: ProvisionActionTypes.SET_USERS,
    payload: Array<any>
}
interface SetUsersCount {
    type: ProvisionActionTypes.SET_USERS_COUNT,
    payload: number
}

interface SetUsersLimit {
    type: ProvisionActionTypes.SET_USERS_LIMIT,
    payload: number
}
interface SetUsersOffset {
    type: ProvisionActionTypes.SET_USERS_OFFSET,
    payload: number
}

// TPMS PROFILES
interface SetTpmsProfiles {
    type: ProvisionActionTypes.SET_TPMS_PROFILES,
    payload: Array<ProvisionTpmsProfile>
}


// reset provision page reducer
interface ResetProvisionPage {
    type: ProvisionActionTypes.RESET_PROVISION_PAGE_REDUCER,
    payload: boolean
}

// combine types beacause of large str size
type ProvisionAssetsActions = LoadingAssets | SetAssets | SetAssetsCount | SetAssetsLimit | SetAssetsOffset | SetAssetsSortOptions | SetAssetsError
type ProvisionTpmsAssetsActions = LoadingTpmsAssets | SetTpmsAssets | SetTpmsAssetsCount | SetTpmsAssetsLimit | SetTpmsAssetsOffset | SetTpmsAssetsSortOptions | SetTpmsAssetsFilter | SetTpmsAssetsError 
type ProvisionUsersActions = LoadingUsers | SetUsers | SetUsersSortOprions | SetUsersCount | SetUsersLimit | SetUsersOffset | SetUsersError

// compose 1 type for export
export type ProvisionAction =  ProvisionAssetsActions | ProvisionTpmsAssetsActions | ProvisionUsersActions | SetTpmsProfiles | ResetProvisionPage
