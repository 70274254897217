import { useMemo } from 'react'

import { IColumn } from '../../components/RivataGrid/index'
import { ReportTypes } from '../../enums'
import { dvHealthReportColumns } from './parsers/deviceHealthReport'
import { tpmsReportColumns } from './parsers/tpmsReport'
import { dailyReportColumns } from './parsers/dailyReport'
import { getClearLabel } from '../../utils';
import { geofencesReportColumns } from './parsers/geofencesReports'
import {distanceReportColumns} from './parsers/distanceTravelReport'

interface IuseReportColumns {
    (reportType: string, columns: Array<string>, unitsOfMeasurement: string):  Array<IColumn>
}

const useReportColumns: IuseReportColumns = (reportType, columns, unitsOfMeasurement) => {
    return useMemo(() => {
        let parsedColumns: Array<IColumn> = []

        if (!columns.length) return parsedColumns

        switch (reportType) {
            case ReportTypes.USER_LOGINS:
                parsedColumns.push({ key: columns[0], name: "Username" })
                parsedColumns.push({ key: columns[1], name: "Last Login" })
                break
            case ReportTypes.HARDWARE_COMMANDS_HISTORY:
                const labels = ["Asset Name", "VIN", "ESN", "Delivery Confirmed At", "Number of Retries", "Command Type", "Timestamp"]

                columns.forEach((key, i) => {
                    parsedColumns.push({ key, name: labels[i], width: 210, frozen: i === 0 ? true : false, minWidth: 70 })
                })
                break
            case ReportTypes.TPMS_REPORT:
                parsedColumns = tpmsReportColumns(columns)
                break
            case ReportTypes.DEVICE_HEALTH_REPORT:
                parsedColumns = dvHealthReportColumns(columns)
                break
            case ReportTypes.DAILY_MILEAGE_REPORT:
                parsedColumns = dailyReportColumns(columns, unitsOfMeasurement)
                break
            case ReportTypes.GEOFENCES_REPORT:
                parsedColumns = geofencesReportColumns(columns)
                break
            case ReportTypes.DISTANCE_TRAVELED_REPORT:
                parsedColumns = distanceReportColumns(columns, unitsOfMeasurement)
                break
            default:
                parsedColumns = columns.map(key => ({ key, name: getClearLabel(key) }))
                break
        }
        
        return parsedColumns
    }, [reportType, columns, unitsOfMeasurement])
}

export default useReportColumns
