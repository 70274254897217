import React, { useState } from 'react'
import amstedIndGray from '../../assets/images/footer/LOGO_AmstedIndustries_RGB_GRAY.svg'
import ConfirmModal from '../ConfirmModal'

import './style.scss'

const text = `The asset health data provided on the ConMet Digital Dashboard may not be available in "real time" nor will it reflect "real time" conditions and should not be relied upon as such. 
The data available on this site is for informational purposes only; it does not provide advice or direction about how the dashboard end user should use the data. 
Nor does the site rate the severity of the underlying conditions that gave rise to a warning or alert status. 
It is the responsibility of the dashboard end user to make all asset health determinations based on its own maintenance, inspection, and other asset management guidelines. 
The information available on this site does not replace the need for the dashboard end user to follow a regular program of physical inspection and maintenance of assets. 
ConMet disclaims all liability arising from, or related to, the use or non-use of the data available on this site. Nor does ConMet warrant that the data available on this site will be uninterrupted or error-free at all times. 
Use of this site is governed by an agreement between your organization and ConMet, a copy of which is available upon request.`;

const Footer: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <footer className="footer text-center">
            <ConfirmModal 
                open={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
            >
                {text}
            </ConfirmModal>
            <div className="m-2 p-2 legal-text">
            <p 
                onClick={() => setIsModalOpen(!isModalOpen)}
            >
                LEGAL
            </p>
            </div>
                <div className="m-2 p-2">
                    © Copyright Consolidated Metco, Inc. All rights reserved. ConMet is a
                    registered trademark of
                    <a href="https://conmet.com/"> Consolidated Metco, Inc</a>.
                    <div className="d-flex justify-content-end">
                        <img src={amstedIndGray} className="mt-3" height="40px" alt="logo" />
                    </div>
                </div>
        </footer>
    )
} 

export default Footer
