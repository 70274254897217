import {
    LOADING_PROVISION_INFO,
    SET_PROVISION_INFO,
    SET_LAST_PROVISION_TIMESTAMP,
    SET_PROVISION_INFO_ERROR,
    LOADING_SUBSCRIPTION_INFO,
    SET_SUBSCRIPTION_INFO,
    SET_SUBSCRIPTION_INFO_ERROR,
    LOADING_SENSOR_INFO_JSON,
    SET_SENSOR_INFO_JSON,
    SET_SENSOR_INFO_JSON_ERROR,
    SET_ASSET_INFO,
    LOADING_HARDWARE_COMMANDS,
    SET_HARDWARE_COMMANDS,
    SET_HARDWARE_COMMANDS_ERROR,
    SET_IS_LOADING_PROVISION_HISTORY,
    SET_PROVISION_HISTORY,
    SET_PROVISION_HISTORY_ERROR,
    SET_IS_LOADING_GEOFENCES_EVENTS,
    SET_GEOFENCES_EVENTS,
    SET_GEOFENCES_EVENTS_ERROR,
} from '../actionTypes'

const initialState = {
    assetInfo: {},
    subscription: {
        isLoading: false,
        data: {},
        error: {
            statusCode: 0,
            message: ""
        }
    },
    sensorInfo: {
        isLoading: false,
        data: {},
        error: {
            statusCode: 0,
            message: ""
        }
    },
    provisionInfo: {
        isLoading: false,
        data: {},
        lastProvisionTimestamp: null,
        error: {
            statusCode: 0,
            message: ""
        }
    },
    hardwareCommands: {
        isLoading: false,
        data: [],
        error: {
            statusCode: 0,
            message: ""
        }
    },
    provisionHistory: {
        isLoading: false,
        data: [],
        error: {
            statusCode: 0,
            message: ""
        }
    },
    geofencesEvents: {
        isLoading: false,
        data: [],
        error: {
            statusCode: 0,
            message: ""
        }
    }
}

const setField = (state, key, fieldName, payload) => ({ ...state, [key]: { ...state[key], [fieldName]: payload } })
const setAssetInfo = (state, payload) => ({ ...state, assetInfo: payload })

const assetAdminInfo = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_SUBSCRIPTION_INFO:
            return setField(state, "subscription", "isLoading", action.payload)
        case SET_SUBSCRIPTION_INFO:
            return setField(state, "subscription", "data", action.payload)
        case SET_SUBSCRIPTION_INFO_ERROR:
            return setField(state, "subscription", "error", action.payload)
        case LOADING_SENSOR_INFO_JSON:
            return setField(state, "sensorInfo", "isLoading", action.payload)
        case SET_SENSOR_INFO_JSON:
            return setField(state, "sensorInfo", "data", action.payload)
        case SET_SENSOR_INFO_JSON_ERROR:
            return setField(state, "sensorInfo", "error", action.payload)
        case LOADING_PROVISION_INFO:
            return setField(state, "provisionInfo", "isLoading", action.payload)
        case SET_PROVISION_INFO:
            return setField(state, "provisionInfo", "data", action.payload)
        case SET_LAST_PROVISION_TIMESTAMP:
            return setField(state, "provisionInfo", "lastProvisionTimestamp", action.payload)
        case SET_PROVISION_INFO_ERROR:
            return setField(state, "provisionInfo", "error", action.payload)
        case SET_ASSET_INFO:
            return setAssetInfo(state, action.payload)
        case LOADING_HARDWARE_COMMANDS:
            return setField(state, "hardwareCommands", "isLoading", action.payload)
        case SET_HARDWARE_COMMANDS:
            return setField(state, "hardwareCommands", "data", action.payload)
        case SET_HARDWARE_COMMANDS_ERROR:
            return setField(state, "hardwareCommands", "error", action.payload)
        case SET_IS_LOADING_PROVISION_HISTORY:
            return setField(state, "provisionHistory", "isLoading", action.payload)
        case SET_PROVISION_HISTORY:
            return setField(state, "provisionHistory", "data", action.payload)
        case SET_PROVISION_HISTORY_ERROR:
            return setField(state, "provisionHistory", "error", action.payload)

        case SET_IS_LOADING_GEOFENCES_EVENTS:
            return setField(state, "geofencesEvents", "isLoading", action.payload)
        case SET_GEOFENCES_EVENTS:
            return setField(state, "geofencesEvents", "data", action.payload)
        case SET_GEOFENCES_EVENTS_ERROR:
            return setField(state, "geofencesEvents", "error", action.payload)   
        default:
            return state
    }
}

export default assetAdminInfo