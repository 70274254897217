import React, { useState } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { Label, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from "reactstrap";
import JSONInput from "react-json-editor-ajrm";

export const Checkbox = ({ children, disabled, onCheck, data, checked }) => (
  <Label check>
    <Input
      type="checkbox"
      disabled={disabled}
      checked={checked}
      onChange={(evt) => onCheck(data, evt.target.checked)}
    />
    {children}
  </Label>
);

const Icon = ({
  src,
  name,
  label,
  daysCount,
  assetId,
  showIconTooltip
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  let tooltipText = ""

  switch (label) {
    case "smarthub":
      if (name === "filter-green") {
        tooltipText = "No active SmartHub warnings"
      } else if (name === "filter-yellow") {
        tooltipText = `SmartHub warning active in last ${daysCount}-days`
      } else if (name === "filter-grey") {
        tooltipText = `No status on one or more sensors`
      }
      break;
    case "tpms":
      if (name === "filter-green") {
        tooltipText = "No active TPMS warnings"
      } else if (name === "filter-yellow") {
        tooltipText = `Active TPMS warning`
      } else if (name === "filter-orange") {
        tooltipText = `Active TPMS critical warning`
      } else if (name === "filter-grey") {
        tooltipText = `No status on one or more sensors`
      }
      break;
    case "line_pressure":
      if (name === "filter-green") {
        tooltipText = "No active Line Pressure warnings"
      } else if (name === "filter-orange") {
        tooltipText = `Active Line Pressure warning`
      } else if (name === "filter-grey") {
        tooltipText = `No status on one or more sensors`
      }
      break;
    case "axle_load":
      if (name === "filter-green") {
        tooltipText = "No active Axle Load warnings"
      }
      break;
    default:
      break;
  }
  return (
    <>
      <img id={`icon-${label}-${assetId}`} className={name || ""} src={src} alt={label} />
      {showIconTooltip && (
        <Tooltip
          innerClassName={`status-tooltip ${name === "filter-orange" ? "tooltip-critical-warning"
            : name === "filter-yellow" ? "tooltip-warning"
              : name === "filter-green" ? "tooltip-ok"
                : name === "filter-grey" ? "tooltip-no-status"
                  : ""}
          `}
          hideArrow={true}
          placement="auto"
          isOpen={tooltipOpen}
          target={`icon-${label}-${assetId}`}
          toggle={toggle}
        >
          {tooltipText}
        </Tooltip>
      )}
    </>
  )
}

const LabelWithBreak = ({ label }) => {
  const splittedLabel = label.split("<br/>");
  return (
    <Label className="text-center">
      {splittedLabel[0].trim()} <br /> {splittedLabel[1].trim()}
    </Label>
  );
};

const Buttons = ({ buttons }) =>
  buttons.map((button, idx) => (
    <div key={idx}>
      <Button disabled={button.disabled} style={button.style} onClick={button.onClick}>
        {button.label}
      </Button>
      <br />
    </div>
  ));

const TableRow = ({
  id,
  selectedRow,
  columns,
  data,
  onEdit,
  onDelete,
  onSelectedRow,
  onCheckRowItem,
  editDisabled,
  deleteDisabled,
  tpmsProfiles,
  daysCount,
  onCustomAction,
  customActionIcon,
  customActionTooltipText,
  editTooltipText,
  deleteTooltipText,
  onRowClick,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);
  const [editTooltipOpen, setEditTooltipOpen] = useState(false);
  const [customTooltipOpen, setCustomTooltipOpen] = useState(false);

  const toggleDeleteTooltip = () => {
    setDeleteTooltipOpen(!deleteTooltipOpen);
  }
  const toggleEditTooltip = () => {
    setEditTooltipOpen(!editTooltipOpen);
  }
  const toggleCustomTooltip = () => {
    setCustomTooltipOpen(!customTooltipOpen);
  }

  const deleteIconButtonId = `delete_button_${id}`;
  const editIconButtonId = `edit_button_${id}`;
  const customIconButtonId = `custom_button_${id}`;

  return (
    <tr
      id={"asset-" + id}
      className="table-row"
      style={{ backgroundColor: selectedRow ? "rgba(0, 0, 0, 0.075)" : "white" }}
      onMouseEnter={() => onSelectedRow(id)}
      onMouseLeave={() => onSelectedRow(null)}
    >
      {onDelete && (
        <td key={`delete_row_${id}`}>
          <Button
            className="pl-2 pr-1"
            close
            disabled={deleteDisabled || !data}
            onClick={() => onDelete(data)}
            id={deleteIconButtonId}
          />
          {deleteTooltipText && <Tooltip
            hideArrow={true}
            placement="auto"
            isOpen={deleteTooltipOpen}
            target={deleteIconButtonId}
            toggle={toggleDeleteTooltip}
          >
            {deleteTooltipText}
          </Tooltip>}
        </td>
      )}
      {onEdit && (
        <td key={`edit_row_${id}`}>
          <Button
            className="pl-1 pr-2"
            close
            disabled={editDisabled || !data}
            onClick={() => onEdit(data)}
            id={editIconButtonId}
          >
            <i className="fa fa-edit font-16" />
          </Button>
          {editTooltipText && <Tooltip
            hideArrow={true}
            placement="auto"
            isOpen={editTooltipOpen}
            target={editIconButtonId}
            toggle={toggleEditTooltip}
          >
            {editTooltipText}
          </Tooltip>}

        </td>
      )}
      {onCustomAction && (
        <td key={`custom_action_${id}`}>
          <Button
            className="pl-1 pr-2"
            close
            disabled={editDisabled || !data}
            onClick={() => onCustomAction(data)}
            id={customIconButtonId}
          >
            <i className={customActionIcon} />
          </Button>
          {customActionTooltipText && <Tooltip
            hideArrow={true}
            placement="auto"
            isOpen={customTooltipOpen}
            target={customIconButtonId}
            toggle={toggleCustomTooltip}
          >
            {customActionTooltipText}
          </Tooltip>}
        </td>
      )}
      {columns.map((itemData) => {
        const {
          label,
          src,
          link,
          classNames,
          columnId,
          assetId,
          type,
          control,
          controlEnabled,
          checked,
          selected,
          onSelectProfile,
          error,
          onClick,
          onCheck,
          addBreak,
          buttons,
          width,
          showIconTooltip = false,
          showButton = true,
          btnColor = null
        } = itemData;
        let Wrapper;
        let wrapperProps;

        if (control === "checkbox") {
          Wrapper = Checkbox;
          wrapperProps = {
            onCheck: typeof onCheckRowItem === "function" ? onCheckRowItem : onCheck,
            checked,
            data: itemData,
            disabled: controlEnabled === false,
          };
        } else {
          Wrapper = React.Fragment;
          wrapperProps = {};
        }

        return (
          <td
            key={`${assetId}_${columnId}`}
            className={type === "button" ? "td_button" : ""}
            style={{ "width": type === "json" && width }}
            onClick={() => {
              if (!onRowClick) return;

              if (typeof onRowClick === "function") onRowClick(data);
            }}
          >
            <Wrapper {...wrapperProps}>
              {error ? (
                <i className="fas fa-exclamation-circle text-danger"></i>
              ) : type === "link" ? (
                <Link to={link}>{label}</Link>
              ) : type === "icon" ? (
                src ? (
                  <div className="d-flex">
                    {src.map((src, i) => (
                      <Icon
                        key={label[i] + assetId}
                        src={src}
                        name={classNames[i]}
                        label={label[i]}
                        daysCount={daysCount}
                        assetId={assetId}
                        showIconTooltip={showIconTooltip}
                      />
                    ))}
                  </div>
                ) : (
                  ""
                )
              ) : type === "dropdown" ?
                <Dropdown setActiveFromChild={true} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                  <DropdownToggle color="#525f7f" className="custom-rivata-dropdown" caret>
                    {selected}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {tpmsProfiles.map((el) => {
                      return <DropdownItem key={el.id} onClick={() => onSelectProfile(assetId, el.label)}>{el.label}</DropdownItem>
                    })}
                  </DropdownMenu>
                </Dropdown>
                : type === "button" ? (
                  <>
                    {showButton ? (
                      <Button
                        color={btnColor ? btnColor : "secondary"}
                        onClick={onClick}
                      >
                        {label}
                      </Button>
                    ) : "-"}
                  </>
                ) : type === "buttons" ? (
                  <Buttons buttons={buttons} />
                ) : type === "json" ? (
                  <JSONInput
                    id={`${assetId}_${columnId}`}
                    viewOnly={true}
                    placeholder={label}
                    confirmGood={false}
                    height="200px"
                    width={width}
                  />
                ) : type === "textarea" ? (
                  <textarea
                    value={label}
                    readOnly
                    style={{ width: width }}
                  />
                ) : addBreak ? (
                  <LabelWithBreak label={label} />
                ) : (
                  label
                )
              }
            </Wrapper>
          </td>
        );
      })}
    </tr>
  );
};

export const tableRowType = {
  id: PropTypes.number.isRequired,
  onSelectedRow: PropTypes.func,
  onCheckRowItem: PropTypes.func,
  tpmsProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      columnId: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "icon", "link", 'dropdown', 'button', 'json', "textarea", "buttons"]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf[PropTypes.string]
      ]).isRequired,
      link: PropTypes.string,
      src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf[PropTypes.string]
      ]),
      control: PropTypes.oneOf(["checkbox"]),
      controlEnabled: PropTypes.bool,
    })
  ).isRequired,
};

TableRow.propTypes = tableRowType;

TableRow.defaultProps = {
  onCheck: () => { },
  onSelectedRow: () => { },
};

export default TableRow;
