import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Pagination, PaginationLink, PaginationItem } from 'reactstrap';
import './style.scss'

const RivatePagination = ({ initialPage = 0, assetsCount, pageLimit, onPageChange, days = 7, filter, decreaseCurrentPageNumber, setDecreaseCurrentPageNumber }) => {
    const [currentPage, setCurrentPage] = useState(initialPage);
    useEffect(() => {
        if (decreaseCurrentPageNumber) {
            const selectedPage = currentPage
            setDecreaseCurrentPageNumber(false)
            setCurrentPage(selectedPage - 1)
        }
    }, [decreaseCurrentPageNumber, currentPage, setDecreaseCurrentPageNumber])

    useEffect(() => {
        setCurrentPage(0);
    }, [days, pageLimit, filter]);

    useEffect(() => {
        setCurrentPage(initialPage)
    }, [initialPage])

    const pagesCount = Math.ceil(assetsCount / pageLimit);

    const onPageClick = useCallback((page) => {
        if (page !== currentPage && page > - 1 && page < pagesCount) {
            setCurrentPage(page);
            const offset = page === 0 ? 0 : page * pageLimit;
            onPageChange(offset);
        }
    }, [currentPage, onPageChange, pageLimit, pagesCount])

    const pagination = useMemo(() => {
        let paginationRange = []
        let paginationVisibleRange = []
        const getRange = (from, to) => {
            let range = []
            for (let i = from; i <= to; i++) {
                range.push(i)
            }
            return range
        }
        paginationRange = getRange(1, pagesCount)

        if (pagesCount < 10) {
            paginationVisibleRange = paginationRange
        } else if (currentPage < 4) {
            paginationVisibleRange = getRange(1, 6)
            paginationVisibleRange = [...paginationVisibleRange, "...", pagesCount]
        } else if (currentPage >= 4 && currentPage < pagesCount - 4) {
            paginationVisibleRange = getRange(currentPage - 1, currentPage + 3)
            paginationVisibleRange = [1, "...", ...paginationVisibleRange, "...", pagesCount]
        } else if (currentPage >= pagesCount - 4) {
            paginationVisibleRange = getRange(currentPage - 2, pagesCount)
            paginationVisibleRange = [1, "...", ...paginationVisibleRange]
        }

        const getIndex = (elem) => {
            return paginationRange.indexOf(elem)
        }

        return paginationVisibleRange.map((item, index) => {
            if (typeof item === "number") {
                return (
                    <PaginationItem key={(index + 1).toString()} active={currentPage === getIndex(item)}>
                        <PaginationLink
                            onClick={() => {
                                onPageClick(getIndex(item))
                            }}>
                            {item}
                        </PaginationLink>
                    </PaginationItem>
                )
            } else if (item === "..." && index === 1) {
                return (
                    <PaginationItem key={(index + 1).toString()}>
                        <PaginationLink
                            onClick={() => onPageClick(getIndex(paginationVisibleRange[index + 1]))}>
                            {item}
                        </PaginationLink>
                    </PaginationItem>
                )
            } else if (item === "...") {
                return (
                    <PaginationItem key={(index + 1).toString()}>
                        <PaginationLink
                            onClick={() => onPageClick(getIndex(paginationVisibleRange[index - 1]))}>
                            {item}
                        </PaginationLink>
                    </PaginationItem>
                )
            } else {
                return null
            }
        })
    }, [pagesCount, currentPage, onPageClick]);

    return <>
        {pagesCount > 0 && <Pagination>
            <PaginationItem>
                <PaginationLink previous onClick={() => onPageClick(currentPage - 1)} />
            </PaginationItem>
            {pagination}
            <PaginationItem>
                <PaginationLink next onClick={() => onPageClick(currentPage + 1)} />
            </PaginationItem>
        </Pagination>}
    </>
}

export default RivatePagination;