import { useEffect, useState } from "react";

import { pick, isNil, isNumber } from "lodash";

import { getThresholdsByAssignedProfile, getTpmsTirePressure, psiToBar, convertDataEpochToDate } from "../../utils";

import { UnitsOfMeasurement } from "../../enums";

export const useDiagramData = ({ asset, assetTpmsStatus, fetchAssetTmpsStatus, preferences: { unitsOfMeasurement, timezone }, customerDefaults }) => {
  const [tpmsThresholds, setTpmsThresholds] = useState({});

  useEffect(() => {
    if (!asset.id) return;

    fetchAssetTmpsStatus(asset.id, asset.asset_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    if (!assetTpmsStatus.isLoading && assetTpmsStatus.data?.asset_id) {
      const defaultTpmsThresholds = pick(customerDefaults.tpms, [
        "critical_low_pressure_in_percent",
        "critical_over_pressure_in_percent",
        "low_pressure_in_percent",
        "over_pressure_in_percent",
      ]);

      if (Object.values(assetTpmsStatus.data.tpms_profile_thresholds_percents).some((i) => isNil(i))) {
        setTpmsThresholds(defaultTpmsThresholds);
      } else {
        setTpmsThresholds(assetTpmsStatus.data.tpms_profile_thresholds_percents);
      }
    }
  }, [assetTpmsStatus, customerDefaults]);

  const getDescription = (pressure, thresholdsByAssignedProfile, isNoStatusSensor) => {
    if (isNoStatusSensor) return "No Status";

    if (pressure < thresholdsByAssignedProfile.critical_low_pressure) return "Critical Low Pressure";

    if (pressure > thresholdsByAssignedProfile.critical_low_pressure && pressure < thresholdsByAssignedProfile.low_pressure) return "Low Pressure";

    if (pressure > thresholdsByAssignedProfile.over_pressure && pressure < thresholdsByAssignedProfile.critical_over_pressure) return "Over Pressure";

    if (pressure > thresholdsByAssignedProfile.critical_over_pressure) return "Critical Over Pressure";

    return "Normal";
  };

  const getTireData = (sensor) => {
    const thresholdsByAssignedProfile = getThresholdsByAssignedProfile(
      unitsOfMeasurement,
      sensor.cold_inflation_pressure_in_psi,
      tpmsThresholds,
      customerDefaults.tpms?.cold_inflation_pressure_in_psi
    );

    const isNoStatusSensor = sensor.no_status;

    const pressure = getTpmsTirePressure(sensor.value, unitsOfMeasurement);

    const data = {
      pressure,
      timestamp: sensor.timestamp ? convertDataEpochToDate(sensor.timestamp, null, null, true) : "-",
      coldInflationPressure:
          unitsOfMeasurement === UnitsOfMeasurement.imperial
            ? sensor.cold_inflation_pressure_in_psi
            : psiToBar(sensor.cold_inflation_pressure_in_psi).toFixed(2),
      description: getDescription(pressure, thresholdsByAssignedProfile, isNoStatusSensor),
      status: "",
      isNoStatusSensor
    };

    if (isNumber(data.pressure)) {
      if (isNoStatusSensor){
        data.pressure = "-";
        data.coldInflationPressure = "-";
        data.status = "noStatus";
      }
      else if (data.pressure < thresholdsByAssignedProfile.critical_low_pressure || data.pressure > thresholdsByAssignedProfile.critical_over_pressure) {
        data.status = "criticalWarning";
      } 
      else if (data.pressure < thresholdsByAssignedProfile.low_pressure || data.pressure > thresholdsByAssignedProfile.over_pressure) {
        data.status = "warning";
      } 
      else {
        data.status = "normal";
      }
    } 
    else {
      data.status = "unknown";
      data.pressure = "-";
    }

    return data;
  };

  return {
    pressureUnit: unitsOfMeasurement === UnitsOfMeasurement.imperial ? "PSI" : "Bar",
    timezone: timezone || "America/Los_Angeles",
    getTireData,
  };
};
