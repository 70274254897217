import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import authService from "../../services/auth"
import { convertDataEpochToDate, getCurrentEpoch } from '../../utils'
import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Collapse,
  Dropdown, 
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

interface IHeaderUser {
    email: string | null
    userName: string | null
}

interface IHeaderProps {
    whiteLabelUrl: string
    user: IHeaderUser
    settings: any
}

const showMobilemenu = () => {
    document.getElementById("main-wrapper")?.classList.toggle("show-sidebar");
}

let interval: NodeJS.Timeout


const Header: React.FC<IHeaderProps> = ({
    whiteLabelUrl,
    user,
    settings
}) => {
    const [currentDateTime, setCurrentDateTime] = useState(null)
    const [userDropdownOpen, setUserDropdownOpen] = useState(false)

    useEffect(() => {
        interval = setInterval(() => {
            const newDatetime = convertDataEpochToDate(getCurrentEpoch(), null, null, true)
            
            if (currentDateTime !== newDatetime) setCurrentDateTime(newDatetime)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [currentDateTime])

    const toggleUserDropdown = () => {
        setUserDropdownOpen(!userDropdownOpen)
    }

    const onSignOut = (e: any) => {
        e.preventDefault()
        authService.signOut()
    }

    const sidebarHandler = () => {
        let element = document.getElementById("main-wrapper")
        
        if (element) {
            switch (settings.activeSidebarType) {
                case "full":
                case "iconbar":
                  element.classList.toggle("mini-sidebar")
                  if (element.classList.contains("mini-sidebar")) {
                    element.setAttribute("data-sidebartype", "mini-sidebar")
                  } else {
                    element.setAttribute("data-sidebartype", settings.activeSidebarType)
                  }
                  break
          
                case "overlay":
                case "mini-sidebar":
                  element.classList.toggle("full")
                  if (element.classList.contains("full")) {
                    element.setAttribute("data-sidebartype", "full")
                  } else {
                    element.setAttribute("data-sidebartype", settings.activeSidebarType)
                  }
                  break
                default:
            }
        }
    }

    return (
        <header
            className="topbar navbarbg"
            data-navbarbg={settings.activeNavbarBg}
            style={{ zIndex: 1001 }}
        >
            <Navbar
                className={
                    "top-navbar " + (settings.activeNavbarBg === "skin6"
                        ? "navbar-light"
                        : "navbar-dark"
                    )
                }
                expand="md"
            >
                <div
                    className="navbar-header"
                    id="logobg"
                    data-logobg={settings.activeLogoBg}
                >
                    <span
                        className="nav-toggler d-block d-md-none"
                        onClick={showMobilemenu}
                    >
                        <i className="ti-menu ti-close" />
                    </span>
                    
                    <Link to="/" className="d-flex">
                        <b className="logo-icon">
                            <img
                                src={`${whiteLabelUrl}logo_icon.png?d=${getCurrentEpoch()}`}
                                style={{ width: 40, height: 40, objectFit: "contain" }}
                                alt="homepage"
                                className="dark-logo p-1 m-2"
                            />
                        </b>
                        <span className="logo-text">
                            <img
                                src={`${whiteLabelUrl}logo_full.png?d=${getCurrentEpoch()}`}
                                style={{ maxWidth: 160, height: 64, objectFit: "contain" }}
                                alt="homepage"
                                className="dark-logo"
                            />
                        </span>
                    </Link>
                </div>

                <Collapse
                    isOpen={false}
                    className="navbarbg"
                    navbar
                    data-navbarbg={settings.activeNavbarBg}
                >
                    <Nav className="float-left" navbar>
                        <NavItem>
                            <NavLink
                                href="#"
                                className="d-none d-md-block"
                                onClick={sidebarHandler}
                            >
                                <i className="icon-menu header-icon" style={{ fontSize: "16px" }} />
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <div 
                        className="w-100 d-flex justify-content-end mr-5"
                        style={{ color: "#fff", fontSize: "1rem" }}
                    >
                        <label className="mb-0">{currentDateTime}</label>
                    </div>

                    <Nav className="ml-auto float-right" navbar style={{ userSelect: "none" }}>
                        <div className="d-flex align-items-center" style={{ color: "#fff", fontWeight: "bold", fontSize: "1.2rem" }}>{user.userName}</div>
                        <Dropdown isOpen={userDropdownOpen} toggle={toggleUserDropdown}>
                            <DropdownToggle
                                tag="span"
                                data-toggle="dropdown"
                                aria-expanded={userDropdownOpen}
                            >
                                <i className="fas fa-user-circle header-icon"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <div 
                                    onClick={toggleUserDropdown}
                                >
                                    <Link 
                                        className="user-dropdown__item"
                                        to="/support"
                                    >
                                        <div className="d-flex align-items-center">
                                            <i className="fa fa-info-circle" style={{ padding: "5px"}} />
                                            <div>Support</div>
                                        </div>
                                    </Link>
                                </div>
                                <div 
                                    onClick={toggleUserDropdown}
                                >
                                    <Link 
                                        className="user-dropdown__item" 
                                        to="/settings"
                                    >
                                        <div className="d-flex align-items-center">
                                            <i className="fa fa-cog" style={{ padding: "5px"}}/>
                                            <div>User Profile</div>
                                        </div>
                                    </Link>
                                </div>
                                <div 
                                    onClick={onSignOut}
                                >
                                    <div className="user-dropdown__item" >
                                        <div className="d-flex align-items-center">
                                            <i className="fas fa-sign-out-alt" style={{ padding: "5px"}}/>
                                            <div>Logout</div>
                                        </div>
                                    </div>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    )
}

export default Header
