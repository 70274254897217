export const DatesEnum = {
    SEVEN_DAYS: 7,
    THIRTY_DAYS: 30,
    NINETY_DAYS: 90,
    SIX_MONTHS: 180,
};
  
export enum ReportTypes {
    USER_LOGINS               = "USER_LOGINS",
    HARDWARE_COMMANDS_HISTORY = "HARDWARE_COMMANDS_HISTORY",
    TPMS_REPORT               = "TPMS_REPORT",
    DEVICE_HEALTH_REPORT      = "DEVICE_HEALTH_REPORT",
    DAILY_MILEAGE_REPORT      = "DAILY_MILEAGE_REPORT",
    DISTANCE_TRAVELED_REPORT  = "DISTANCE_TRAVELED_REPORT",
    GEOFENCES_REPORT          = "GEOFENCES_REPORT"
};
  
export const ReportEndpoints = {
    USER_LOGINS: 'user_logins',
    HARDWARE_COMMANDS_HISTORY: "hardware_commands_history",
    TPMS_REPORT: "tpms_report",
    DEVICE_HEALTH_REPORT: "device_health_report",
    DAILY_MILEAGE_REPORT: "daily_mileage_report",
    DISTANCE_TRAVELED_REPORT: "distance_traveled_report",
    GEOFENCES_REPORT: "geofences_report"
};
  
export const ColumnsIds = {
    ACKNOWLEDGE: "ackowledge",
    STATUS: "status",
    VIN: "vin",
    CUSTOMER_NAME: "customer_name",
    ASSET_NAME: "asset_name",
    LAST_GPS_UPDATE: "last_gps_update",
    SUBSCRIPTION: "subscription",
    SENSOR_INFO: "sensor_info",
    SENSOR_ID: "mac",
    ASSET_TYPE: "asset_type",
    ACTUAL_ASSET_GEOFENCES: "actual_asset_geofences"
};
  
export const TpmsColumnsIds = {
    ALL: "all",
    ASSET_NAME: "asset_name",
    VIN: "vin",
    CUSTOMER: "customer",
    ASSET_TYPE: "asset_type",
    TPMS_PROFILE: "warning_setting_name",
    WARNINGS_SUBTYPE: "warnings_subtype"
};
  
export const SensorColumnsIds = {
    COMMAND: "command",
    SENSOR_ID: "sensor_id",
    RSSI: "rssi",
    CONFIG: "config_crc",
    FIRMWARE: "fw_Version",
    HARDWARE: "hw_Version",
    VOLTAGE: "battery_voltage",
    PACKETS: "num_rx_packets",
    FAILURES: "num_tx_failuers",
    GATEWAY_UID: "gateway_uid"
};
  
export const SensorCommandsIds = {
    TOGGLE_VERBOSE_LOGGING: "debug",
    REQUEST_GATEWAY_LOG: "gateway_status",
    REQUEST_SENSOR_LOG: "sensor_status",
    UPDATE_DEVICE: "ota",
    RESET_DEVICE: "reboot"
};
  
export const SortOrder = {
    ASC: "ASC",
    DESC: "DESC",
};
  
export const UserAttributes = {
    ADDRESS: "address",
    BIRTHDATE: "birthdate",
    EMAIL: "email",
    FAMILY_NAME: "family_name",
    GENDER: "gender",
    GIVEN_NAME: "given_name",
    LOCALE: "locale",
    MIDDLE_NAME: "middle_name",
    NAME: "name",
    NICKNAME: "nickname",
    PHONE_NUMBER: "phone_number",
    PICTURE: "picture",
    PREFERRED_USERNAME: "preferred_username",
    PROFILE: "profile",
    UPDATED_AT: "updated_at",
    WEBSITE: "website",
    ZONEINFO: "zoneinfo",
    NOTIF_EMAIL: "custom:notification_email",
    NOTIF_TEXT: "custom:notification_text",
    NOTIF_THRESHOLD: "custom:notification_level",
    UNITS_OF_MEASUREMENT: "custom:units_of_measurement"
};
  
export const Timezones = {
    PACIFIC_HONOLULU: "Pacific/Honolulu",
    PACIFIC_JUNEAU: "America/Juneau",
    AMERICA_LOS_ANGELES: "America/Los_Angeles",
    AMERICA_DENVER: "America/Denver",
    AMERICA_PHOENIX: "America/Phoenix",
    AMERICA_CHICAGO: "America/Chicago",
    AMERICA_NEW_YORK: "America/New_York",
    CANADA_ATLANTIC: "Canada/Atlantic",
    CANADA_CENTRAL: "Canada/Central",
    CANADA_EASTERN: "Canada/Eastern",
    CANADA_MOUNTAIN: "Canada/Mountain",
    CANADA_NEWFOUNDLAND: "Canada/Newfoundland",
    CANADA_PACIFIC: "Canada/Pacific",
    CANADA_SASKATCHEWAN: "Canada/Saskatchewan",
    CANADA_YUKON: "Canada/Yukon",
    UTC: "UTC",
    AUSTRALIA_MELBOURNE: "Australia/Melbourne"
}
  
export const RolePermission = {
    ADMIN: 'ADMIN',
    STANDARD: 'STANDARD',
};
  
export const TimezonAbbreviations = {
    [Timezones.PACIFIC_HONOLULU]: { std: "HST", dst: "HST" },
    [Timezones.PACIFIC_JUNEAU]: { std: "AKST", dst: "AKDT" },
    [Timezones.AMERICA_LOS_ANGELES]: { std: "PST", dst: "PDT" },
    [Timezones.AMERICA_DENVER]: { std: "MST", dst: "MDT" },
    [Timezones.AMERICA_PHOENIX]: { std: "MST", dst: "MDT" },
    [Timezones.AMERICA_CHICAGO]: { std: "CST", dst: "CDT" },
    [Timezones.AMERICA_NEW_YORK]: { std: "EST", dst: "EDT" },
    [Timezones.CANADA_ATLANTIC]: { std: "AST", dst: "ADT" },
    [Timezones.CANADA_CENTRAL]: { std: "CST", dst: "CDT" },
    [Timezones.CANADA_EASTERN]: { std: "EST", dst: "EDT" },
    [Timezones.CANADA_MOUNTAIN]: { std: "MST", dst: "MDT" },
    [Timezones.CANADA_NEWFOUNDLAND]: { std: "NDT", dst: "NDT" },
    [Timezones.CANADA_PACIFIC]: { std: "PDT", dst: "PDT" },
    [Timezones.CANADA_SASKATCHEWAN]: { std: "CST", dst: "CDT" },
    [Timezones.CANADA_YUKON]: { std: "MST", dst: "MDT" },
    [Timezones.UTC]: { std: "GMT", dst: "GMT" },
    [Timezones.AUSTRALIA_MELBOURNE]:{std: "AEDT", dst: "AEDT"}
};
  
export const Languages = {
    EN: "en",
    FR: "fr",
    GR: "gr",
};
  
export const NotificationThresholds = {
    INFORMATIONAL_AND_CRITICAL: "INFORMATIONAL_AND_CRITICAL",
    CRITICAL_ONLY: "CRITICAL_ONLY",
};
  
export const AssetTypes = {
    TRACTOR: "tractor",
    TRAILER: "trailer",
};
  
export const WheelOrder = {
    NONE: 99,
    UNKNOWN: 98,
    UNASSIGN: 97,
    
    STEER_LEFT: 0,
    STEER_RIGHT: 1,
  
    DRIVE_LEFT_1: 2,
    DRIVE_RIGHT_1: 3,
    DRIVE_LEFT_2: 4,
    DRIVE_RIGHT_2: 5,

  
    DRIVE_FRONT_LEFT: 6,
    DRIVE_FRONT_RIGHT: 7,
  
    DRIVE_REAR_LEFT: 8,
    DRIVE_REAR_RIGHT: 9,
  
    DRIVE_LEFT_1_OUTER: 10,
    DRIVE_LEFT_1_INNER: 11,
    DRIVE_RIGHT_1_OUTER: 12,
    DRIVE_RIGHT_1_INNER: 13,
    DRIVE_LEFT_2_OUTER: 14,
    DRIVE_LEFT_2_INNER: 15,
    DRIVE_RIGHT_2_OUTER: 16,
    DRIVE_RIGHT_2_INNER: 17,
  
    TRAILER_LEFT_1: 18,
    TRAILER_RIGHT_1: 19,
    TRAILER_LEFT_2: 20,
    TRAILER_RIGHT_2: 21,
    TRAILER_LEFT_3: 22,
    TRAILER_RIGHT_3: 23,
    TRAILER_LEFT_4: 24,
    TRAILER_RIGHT_4: 25,
    TRAILER_LEFT_5: 26,
    TRAILER_RIGHT_5: 27,
    TRAILER_LEFT_6: 28,
    TRAILER_RIGHT_6: 29,
  
    TRAILER_LEFT_1_OUTER: 30,
    TRAILER_LEFT_1_INNER: 31,
    TRAILER_RIGHT_1_OUTER: 32,
    TRAILER_RIGHT_1_INNER: 33,
    TRAILER_LEFT_2_OUTER: 34,
    TRAILER_LEFT_2_INNER: 35,
    TRAILER_RIGHT_2_OUTER: 36,
    TRAILER_RIGHT_2_INNER: 37,
    TRAILER_LEFT_3_OUTER: 38,
    TRAILER_LEFT_3_INNER: 39,
    TRAILER_RIGHT_3_OUTER: 40,
    TRAILER_RIGHT_3_INNER: 41,
    TRAILER_LEFT_4_OUTER: 42,
    TRAILER_LEFT_4_INNER: 43,
    TRAILER_RIGHT_4_OUTER: 44,
    TRAILER_RIGHT_4_INNER: 45,
    TRAILER_LEFT_5_OUTER: 46,
    TRAILER_LEFT_5_INNER: 47,
    TRAILER_RIGHT_5_OUTER: 48,
    TRAILER_RIGHT_5_INNER: 49
};
  
export const WhiteLabelFilenames = {
    smarthubHealthSvg: "smarthub_health.svg",
    tpmsHealthSvg: "tpms_health.svg",
    linePressureHealthSvg: "line_pressure_health.svg",
    axleLoadHealthSvg: "axle_load_health.svg",
    logoIconPng: "logo_icon.png",
    logoFullPng: "logo_full.png",
};
  
export const PressureWarningLevel = {
    lowPressure: "low_pressure",
    criticalLowPressure: "critical_low_pressure",
    overPressure: "over_pressure",
    criticalOverPressure: "critical_over_pressure"
}
  
export const WarningType = {
    hubTemperature: "hub_temperature",
    hubVibration: "hub_vibration",
    tirePressure: "tire_pressure",
    noStatus: "no_status",
    linePressure: "line_pressure"
}
  
export const UnitsOfMeasurement = {
    imperial: "imperial",
    metric: "metric"
}
  
export const HardwareType = {
    UNKNOWN: 1,
    TIRE_SENSOR: 2,
    GATEWAY: 5,
    HUB_SENSOR: 6,
    AKTV8: 9,
    AXLE_LOAD: 11,
    LINE_PRESSURE: 12
}
  
export const AxlesNames = {
    "Steer": "tractor_axle_1",
    "Drive 1": "tractor_axle_2",
    "Drive 2": "tractor_axle_3",
    "Drive 3": "tractor_axle_4",
    "Drive 4": "tractor_axle_5",
    "Trailer 1": "trailer_axle_1",
    "Trailer 2": "trailer_axle_2",
    "Trailer 3": "trailer_axle_3",
    "Trailer 4": "trailer_axle_4",
    "Trailer 5": "trailer_axle_5",
    "Trailer 6": "trailer_axle_6",
}
  
export const CountryCodes = [
    { label: "USA", code: "+1" },
    { label: "Canada", code: "+1" },
    { label: "Latvia", code: "+371" },
]
  
export const SubscriptionTypes = {
    smarthub: "HUB_SENSOR",
    tpms: "TIRE_SENSOR",
    manifold: "AKTV8",
    axleLoad: "AXLE_LOAD",
    linePressure: "LINE_PRESSURE",
}
  
export const WarningSearchColumnIds = {
    SENSOR_TYPE: "sensor_type",
    LOCATION: "sensor_position",
    WARNING_TYPE: "warning_type",
}
  
export const WarningSensorTypes = {
    hub_sensor: "Smarthub",
    tire_sensor: "TPMS",
    line_pressure: "Line Pressure"
}
  
export const TractorBubblePositions = ["S-1", "D-1", "D-2", "D-3", "D-4", "D-5"]
  
export const TrailerBubblePositions = ["T-1", "T-2", "T-3", "T-4", "T-5"]
  
export const DateLabels = {
    SEVEN_DAYS: "7 days",
    THIRTY_DAYS: "30 days",
    NINETY_DAYS: "90 days",
    SIX_MONTHS: "6 months",
}

export enum DetailsComponents {
    DetailsMap = "DetailsMap",
    HubVibration = "HubVibration",
    VehicleInfo = "VehicleInfo",
    SensorNode = "SensorInfo",
    RecentWarnings = "RecentWarnings",
    TemperatureHistory = "TemperatureHistory",
    TemperatureChart = "TemperatureChart",
    TirePressure = "TirePressure",
    AxleLoad = "AxleLoad",
    ChartsControl = "ChartsControl",
    LinePressure = "LinePressure",
    TpmsStatusDiagram = "TpmsStatusDiagram",
    LocationChart = "LocationChart",
    PowerInputsChart = "PowerInputsChart"
}

export enum AssociativeAxlesNames {
    TRACTOR_STEER   = "Steer",
    TRACTOR_FRONT   = "Drive 1",
    TRACTOR_REAR_1  = "Drive 2",
    TRACTOR_REAR_2  = "Drive 3",
    TRACTOR_REAR_3  = "Drive 4",
    TRAILER_1       = "Trailer 1",
    TRAILER_2       = "Trailer 2",
    TRAILER_3       = "Trailer 3",
    TRAILER_4       = "Trailer 4",
    TRAILER_5       = "Trailer 5",
    TRAILER_6       = "Trailer 6",
}