import React from 'react'
import DataGrid, { Column } from 'react-data-grid'
import './style.scss'

export interface IColumn extends Column<IRow> {
    name: string // name and key must not match!!! else some properties can not work
}

export interface IRow {
    [key: string]: string
}

interface IRivataGridProps {
    columns: Array<IColumn>,
    rows: Array<IRow>,
    height?: string
}

const RivataGrid: React.FC<IRivataGridProps> = ({
    columns,
    rows,
    height
}) => { 
    // height calculations based on rows and columns count or just pass required height
    const actualHeight = height ? height : rows.length > 22 ? "800px" : columns.length > 5 ? `${rows.length * 35 + 55}px` : `${rows.length * 35 + 35}px`
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            defaultColumnOptions={{
                sortable: false,
                resizable: true
            }}
            style={{ height: actualHeight }}
        />
    )
}

export default RivataGrid