import {
  LOADING_WARNING_LOCATION,
  SET_WARNING_LOCATION,
  SET_WARNING_LOCATION_ERROR,
  LOADING_RECENT_WARNINGS,
  SET_RECENT_WARNINGS,
  SET_RECENT_WARNINGS_ERROR,
  LOADING_VEHICLE_INFO,
  SET_VEHICLE_INFO,
  SET_VEHICLE_INFO_ERROR,
  LOADING_SENSOR_INFO,
  SET_SENSOR_INFO,
  SET_SENSOR_INFO_ERROR,
  LOADING_VIBRATIONS,
  SET_VIBRATIONS,
  SET_VIBRATIONS_ERROR,
  LOADING_TIRE_PRESSURE,
  SET_TIRE_PRESSURE,
  SET_TIRE_PRESSURE_ERROR,
  LOADING_TEMPERATURE,
  SET_TEMPERATURE,
  SET_TEMPERATURE_ERROR,
  LOADING_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS_ERROR,
  SET_ASSIGNED_TPMS_PROFILE,
  SET_ACKNOWLEDGMENT_WARNING_COUNT,
  ///refactor
  SET_RECENT_WARNINGS_LIMIT,
  SET_RECENT_WARNINGS_OFFSET,
  SET_RECENT_WARNINGS_SHOW_ALL,
  SET_RECENT_WARNINGS_FILTER,
  SET_RECENT_WARNINGS_COUNT,
  SET_WARNINGS_INTERVAL,
  RESET_WARNING_DETAILS_REDUCER
} from "../actionTypes";

const initialState = {
  assetInfo: {
    isLoading: false,
    data: [],
    error: { statusCode: 0, message: "" },
  },
  vibrations: {
    isLoading: false,
    data: [],
    error: { statusCode: 0, message: "" },
  },
  tirePressure: {
    isLoading: false,
    data: [],
    error: { statusCode: 0, message: "" },
  },
  temperatures: {
    isLoading: false,
    data: [],
    error: { statusCode: 0, message: "" },
  },
  locations: {
    isLoading: false,
    data: [],
    error: { statusCode: 0, message: "" },
  },
  recentWarnings: {
    isLoading: false,
    data: [],
    error: { statusCode: 0, message: "" },
    totalCount: 0,
    limit: 10,
    offset: 0,
    showAllWarnings: false,
    filter: {},
    intervals: [],
  },

  acknowledgements: {
    isLoading: false,
    data: [],
    error: { statusCode: 0, message: "" },
  },
  assignedTpmsProfile: {},
  acknowledgementWarningCount: 0,
};

const setLoading = (key, state, isLoading) => ({
  ...state,
  [key]: { ...state[key], isLoading },
});
const setData = (key, state, data) => ({
  ...state,
  [key]: { ...state[key], data },
});
const setError = (key, state, error) => ({
  ...state,
  [key]: { ...state[key], error: { ...error } },
});
const setTpmsProfile = (state, payload) => ({
  ...state,
  assignedTpmsProfile: payload,
});
const setField = (state, property, field, payload) => ({
  ...state,
  [property]: { ...state[property], [field]: payload },
});
const filterWarnings = (state) => {
  const { recentWarnings, acknowledgements } = state;
  const unacknowledgedWarnings = recentWarnings.data.filter((warning) => {
    return !acknowledgements.data.find(({ timestamp, position, type }) => {
      return (
        timestamp === warning.timestamp &&
        position === warning.position &&
        type === warning.type
      );
    });
  });

  return {
    ...state,
    recentWarnings: { ...recentWarnings, data: unacknowledgedWarnings },
  };
};

const setAcknowledgementWarningCount = (
  state,
  acknowledgementWarningCount
) => ({ ...state, acknowledgementWarningCount });

const details = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_VEHICLE_INFO:
      return setLoading("assetInfo", state, action.payload);
    case LOADING_WARNING_LOCATION:
      return setLoading("locations", state, action.payload);
    case LOADING_RECENT_WARNINGS:
      return setLoading("recentWarnings", state, action.payload);
    case LOADING_SENSOR_INFO:
      return setLoading("sensorNodeInfo", state, action.payload);
    case LOADING_VIBRATIONS:
      return setLoading("vibrations", state, action.payload);
    case LOADING_TIRE_PRESSURE:
      return setLoading("tirePressure", state, action.payload);
    case LOADING_TEMPERATURE:
      return setLoading("temperatures", state, action.payload);

    case SET_VEHICLE_INFO:
      return setData("assetInfo", state, action.payload);
    case SET_WARNING_LOCATION:
      return setData("locations", state, action.payload);
    case SET_RECENT_WARNINGS:
      const recentWarningsState = setData(
        "recentWarnings",
        state,
        action.payload
      );
      return filterWarnings(recentWarningsState);
    case SET_SENSOR_INFO:
      return setData("sensorNodeInfo", state, action.payload);
    case SET_VIBRATIONS:
      return setData("vibrations", state, action.payload.reverse());
    case SET_TIRE_PRESSURE:
      return setData("tirePressure", state, action.payload.reverse());
    case SET_TEMPERATURE:
      return setData("temperatures", state, action.payload.reverse());

    case SET_VEHICLE_INFO_ERROR:
      return setError("assetInfo", state, action.payload);
    case SET_WARNING_LOCATION_ERROR:
      return setError("locations", state, action.payload);
    case SET_RECENT_WARNINGS_ERROR:
      return setError("recentWarnings", state, action.payload);
    case SET_SENSOR_INFO_ERROR:
      return setError("sensorNodeInfo", state, action.payload);
    case SET_VIBRATIONS_ERROR:
      return setError("vibrations", state, action.payload);
    case SET_TIRE_PRESSURE_ERROR:
      return setError("tirePressure", state, action.payload);
    case SET_TEMPERATURE_ERROR:
      return setError("temperatures", state, action.payload);

    case LOADING_ACKNOWLEDGEMENTS:
      return setLoading("acknowledgements", state, action.payload);
    case SET_ACKNOWLEDGEMENTS:
      const acknowledgementsState = setData(
        "acknowledgements",
        state,
        action.payload
      );
      return filterWarnings(acknowledgementsState);
    case SET_ACKNOWLEDGEMENTS_ERROR:
      return setError("acknowledgements", state, action.payload);
    case SET_ASSIGNED_TPMS_PROFILE:
      return setTpmsProfile(state, action.payload);
    case SET_ACKNOWLEDGMENT_WARNING_COUNT:
      return setAcknowledgementWarningCount(state, action.payload);
    ///refactor
    case SET_RECENT_WARNINGS_LIMIT:
      return setField(state, "recentWarnings", "limit", action.payload);
    case SET_RECENT_WARNINGS_OFFSET:
      return setField(state, "recentWarnings", "offset", action.payload);
    case SET_RECENT_WARNINGS_SHOW_ALL:
      return setField(
        state,
        "recentWarnings",
        "showAllWarnings",
        action.payload
      );
    case SET_RECENT_WARNINGS_FILTER:
      return setField(state, "recentWarnings", "filter", action.payload);
    case SET_RECENT_WARNINGS_COUNT:
      return setField(state, "recentWarnings", "totalCount", action.payload);
    case SET_WARNINGS_INTERVAL:
      return setField(state, "recentWarnings", "intervals", action.payload)

    case RESET_WARNING_DETAILS_REDUCER:
      return initialState
    default:
      return state;
  }
};

export default details;
