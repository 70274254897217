import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import WhiteLabel from "../pages/WhiteLabel";
import Provision from "../pages/Provision";
import TpmsProfiles from "../pages/TpmsProfiles";
import Thresholds from "../pages/Thresholds";
import AssetAdminInfo from '../pages/AssetAdminInfo'
import CalibrateAxleLoad from '../pages/CalibrateAxleLoad'
import GeoLocations from '../pages/GeoLocations';
import GeoLocationsDetails from '../pages/GeoLocationsDetails';
import TagDetails from '../pages/TagDetails';
import ManageSupport from '../pages/ManageSupport';

import InitialLoader from "../components/InitialLoader";

import { initSession } from "../redux/auth/action";

const Components = {
  WhiteLabel,
  Provision,
  TpmsProfiles,
  Thresholds,
  AssetAdminInfo,
  CalibrateAxleLoad,
  GeoLocations,
  GeoLocationsDetails,
  TagDetails,
  ManageSupport
};

class AdminRoute extends Component {
  componentDidMount() {
    if (!this.props.auth.isLoggedIn) {
      this.props.initSession();
    }
  }

  render() {
    const {
      auth: { loginChecked, isLoggedIn, isAttributesLoaded, isAdmin, isSuperAdmin }, 
      componentName, 
      checkForSuperAdmin, 
      whitelabel: { hasCheckedForConfig },
      common: { customerDefaults, isDefaultsLoading },
      disabled
    } = this.props;
    
    const keys = Object.keys(customerDefaults)

    return !loginChecked || (isLoggedIn && !hasCheckedForConfig) || (!keys.length && isDefaultsLoading) ? (
      <InitialLoader/>
    ) : (
      <Route
        {...this.props}
        render={(props) => {
          if (isAttributesLoaded && (!isAdmin || !(componentName in Components) || disabled)) {
            // not logged in so redirect to login page with the return url
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            );
          }

          if (checkForSuperAdmin) {
            if (isAttributesLoaded && !isSuperAdmin) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: props.location },
                  }}
                />
              );
            }
          }

          const Component = Components[componentName];

          return <Component {...props} />;
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  whitelabel: state.whitelabel,
  common: state.common
})

const mapDispatchToProps = (dispatch) => {
  return {
    initSession: () => dispatch(initSession())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);
