import React from "react"
import { Row } from "reactstrap"
import { History, Location } from "history"

import Header from "../Header"
import Sidebar from "../Sidebar"
import Footer from "../Footer"
import ErrorJumbo from "../ErrorJumbo"
import ErrorBoundary from "../../utils/ErrorBoundary"

import homeIcon from '../../assets/images/nav_icons/Home.svg'
import reportIcon from '../../assets/images/nav_icons/Reports.svg'
import whitelabelIcon from '../../assets/images/nav_icons/Whitelabel.svg'
import geofencesIcon from '../../assets/images/nav_icons/Geofences.svg'

import { useTypedSelector } from "../../hooks/useTypedSelector"

import './styles.scss'


export interface INavRoute {
    path?: string
    name: string
    navlabel?: boolean
    icon?: string
    src?: any
    isCustomIcon?: boolean
    customStyles?: {}
}


const sidebarRoutes: Array<INavRoute> = [
    {
        path: "/dashboard",
        name: "Home",
        isCustomIcon: true,
        src: homeIcon,
    },
    {
        path: "/reports",
        name: "Reports",
        isCustomIcon: true,
        src: reportIcon,
    },
    {
        path: "/geofences",
        name: "Geofences",
        isCustomIcon: true,
        src: geofencesIcon,
        customStyles: { height: "28px", width: "28px", marginRight: "3px", marginLeft: "3px" }
    },
]

const sidebarAdminRoutes: Array<INavRoute> = [
    ...sidebarRoutes,
    {
        navlabel: true,
        name: "Admin",
        icon: "mdi mdi-dots-horizontal",
    },
    {
        path: "/provision",
        name: "Provision",
        icon: "fa fa-edit font-16",
    },
    {
        path: "/tpms-profiles",
        name: "TPMS Profiles",
        icon: "fa fa-list-alt font-16",
    },
    {
        path: "/calibrate-axle-load",
        name: "Calibrate Axle Load",
        icon: "fas fa-sliders-h font-16",
    }
]

const superAdminRoutes: Array<INavRoute> = [
    ...sidebarAdminRoutes,
    {
        path: "/thresholds",
        name: "Thresholds",
        icon: "fa fa-bookmark font-16"
    },
    {
        path: "/whitelabel",
        name: "Whitelabel",
        icon: "fa fa-chart-area font-16",
        isCustomIcon: true,
        src: whitelabelIcon,
        customStyles: { width: "18px", height: "18px" }
    },
    {
        path: "/manage-support",
        name: "Manage Support",
        icon: "fa fa-info-circle",
    }
]

if (process.env.REACT_APP_STAGE === "dev" || process.env.REACT_APP_STAGE === "staging" || !process.env.REACT_APP_STAGE) {
    superAdminRoutes.push(
        {
            path: "/geolocations",
            name: "Tag Locations",
            icon: "fa fa-map-marker font-16"
        }
    )
}

interface IRivataPageProps {
    history: History
    location: Location
    children: any
}

const RivataPage: React.FC<IRivataPageProps> = ({
    location,
    children
}) => {
    const {
        auth: { whiteLabel, whiteLabelUrl, isAdmin, isSuperAdmin, user, loginChecked, isLoggedIn },
        error,
        hasCheckedForConfig,
        common: { customerDefaults, versionInfo, isDefaultsLoading },
        settings
    } = useTypedSelector(state => ({
        auth: state.auth,
        error: state.whitelabelAdmin.error,
        hasCheckedForConfig: state.whitelabel.hasCheckedForConfig,
        common: state.common,
        settings: state.settings
    }))

    const keys = Object.keys(customerDefaults)
    const isNotReady = !loginChecked || (isLoggedIn && !hasCheckedForConfig) || (!keys.length && isDefaultsLoading)

    const { pathname } = location

    if (pathname.split("/").includes("authentication")) {
        return children
    }

    // return children anyway because from there we get session
    if (isNotReady) return children

    return (
        <>
            {whiteLabel && (
                <link href={`${whiteLabelUrl}style.css`} rel="stylesheet" />
            )}
            <div
                id="main-wrapper"
                dir={settings.activeDir}
                data-theme={settings.activeTheme}
                data-layout={settings.activeThemeLayout}
                data-sidebartype={settings.activeSidebarType}
                data-sidebar-position={settings.activeSidebarPos}
                data-header-position={settings.activeHeaderPos}
                data-boxed-layout={settings.activeLayout}
            >
                {error?.statusCode >= 400 ? (
                    <ErrorJumbo
                        statusCode={error.statusCode >= 400 ? error.statusCode : null}
                        statusText={error.message ? error.message : ""}
                    />
                ) : (
                    <>
                        <Header whiteLabelUrl={whiteLabelUrl} user={user} settings={settings} />
                        <Sidebar
                            subscriptions={customerDefaults.subscriptions}
                            navRoutes={isSuperAdmin ? superAdminRoutes : isAdmin ? sidebarAdminRoutes : sidebarRoutes}
                            isSuperAdmin={isSuperAdmin}
                            location={location}
                            versionInfo={versionInfo}
                            settings={settings}
                        />
                        <main className="page-wrapper d-block" style={{ paddingTop: "8rem" }}>
                            <div
                                className="page-content container-fluid"
                                style={{ maxWidth: 1600 }}
                            >
                                <ErrorBoundary>
                                    <Row error={error}>{children}</Row>
                                </ErrorBoundary>
                            </div>
                            <Footer />
                        </main>
                    </>
                )}
            </div>
        </>
    )
}

export default RivataPage