import { useEffect, useState } from "react";

const ColumnsIds = {
  Name: "name",
  Customer: "customer_name",
};

interface Row {
  data: IGeofence;
  columns: Array<{ columnId: string; label: string }>;
}

interface Column {
  label: string;
  id: string;
  control: string;
}

export const useColumns = () => {
  const [columns, setColumns] = useState<Array<Column>>([]);

  useEffect(() => {
    setColumns([
      {
        label: "Name",
        id: ColumnsIds.Name,
        control: "sort",
      },
      {
        label: "Customer",
        id: ColumnsIds.Customer,
        control: "sort",
      },
    ]);
  }, []);

  return columns;
};

export const useTableRows = (geofences: Array<IGeofence>) => {
  const [rows, setRows] = useState<Array<any>>([]);

  useEffect(() => {
    const dataRows: Array<Row> = [];

    geofences.forEach((g) => {
      if (g.geofence) {
        const row = {
          data: g,
          columns: [
            {
              columnId: ColumnsIds.Name,
              label: g.name,
            },
            {
              columnId: ColumnsIds.Customer,
              label: g.customer_name || "-",
            },
          ],
        };

        dataRows.push(row);
      }
    });

    setRows(dataRows);
  }, [geofences]);

  return rows;
};
