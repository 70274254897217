import React from 'react'
import { UnitsOfMeasurement } from '../../enums'
import './style.scss'

const UnitsOfMeasureToggle = ({ onChangeUnitsOfMeasure, unitsOfMeasure = UnitsOfMeasurement.imperial }) => {
    return (
        <div className="d-flex units-of-measure__wrapper">
            <button 
                type="button" 
                id="imperial-units__btn" 
                className={`units-of-measure__selector ${unitsOfMeasure === UnitsOfMeasurement.imperial ? "active__unit" : ""}`} 
                onClick={() => onChangeUnitsOfMeasure(UnitsOfMeasurement.imperial)}
            >
                Imperial
            </button>
            <button 
                type="button" 
                id="metric-units__btn" 
                className={`units-of-measure__selector ${unitsOfMeasure === UnitsOfMeasurement.metric ? "active__unit" : ""}`} 
                onClick={() => onChangeUnitsOfMeasure(UnitsOfMeasurement.metric)}
            >
                Metric
            </button>
        </div>
    )
}

export default UnitsOfMeasureToggle