import React from 'react'
import { CSVLink } from "react-csv"

interface ICsvButtonProps {
    data: Array<any>,
    filename: string
    locale: ILocale,
    disabled?: boolean
}

const CsvButton: React.FC<ICsvButtonProps> = ({
    data,
    filename,
    locale,
    disabled = false
}) => {
    return (
        <CSVLink
            data={data}
            filename={filename}
            target="_blank"
            aria-disabled={disabled}
        >
            {locale.download_todo || "Download CSV"}
        </CSVLink>
    )
}

export default CsvButton