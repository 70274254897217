import React from 'react'
import TagsInput from '../../../components/TagsInput'
import { DateInput } from '../../../components/DateRangeCalendar'

interface ITripReportFormProps {
    setVinList: (list: string[]) => void,
    onSelectDate: (from: string, to: string) => void,
    dateFrom: string,
    dateTo: string
    disabled: boolean
}

const TripReportForm: React.FC<ITripReportFormProps> = ({ setVinList, onSelectDate, dateFrom, dateTo, disabled }) => {
    return (
        <div className="d-flex">
            <TagsInput 
                title="VINs"
                md="6"
                disabled={disabled}
                setList={setVinList}
                text="Enter multiple VINs by hitting (Enter, Comma, Space) key. If no VINs entered, all available assets will be returned"
            />
            <DateInput
                onSelect={onSelectDate}
                from={dateFrom}
                to={dateTo}
                md={4}
                singleDateMd={4}
                disabled={disabled}
            >
            </DateInput>
        </div>
    )
}

export default TripReportForm