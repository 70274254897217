import React from "react";
import { ListGroupItem, Button } from "reactstrap";
import { getFileTitle } from "./utils";
import "./styles.scss";

interface Props {
  document: IDocument;
  onDelete: (document: IDocument) => void;
}

const Document: React.FC<Props> = ({ document, onDelete }) => {
  return (
    <ListGroupItem>
      <div className="d-flex align-items-center justify-content-between">
        {getFileTitle(document.file_name)}
        <Button
          onClick={() => {
            onDelete(document);
          }}
        >
          <i className="fas fa-trash"></i>
        </Button>
      </div>
    </ListGroupItem>
  );
};

export default Document;
