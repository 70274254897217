import React, { useState } from 'react'
import moment from 'moment';
import * as Yup from 'yup'
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik } from 'formik'
import RivataDropdown from '../../components/RivataDropdown';
import RivataFormField from '../../components/RivataFormField'
import { DateInput } from '../../components/DateRangeCalendar'
import { kgToLbs, lbsToKg, dateToEpoch, getCurrentEpoch } from '../../utils'
import { UnitsOfMeasurement } from '../../enums'

const CalibrationEditModal = ({
    disabled, 
    onEdit, 
    onCancel, 
    locale = {}, 
    editItem,
    setEditItem,
    selectedUnitsOfMeasurement,
    setStatusMessage,
    selectedAsset,
}) => {
    const [ errorMessage, setErrorMessage ] = useState(null)

    const schema = Yup.object({
        vehicle_weight: Yup.number().required("This field is required").positive("Value must be positive")
    })

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                vehicle_weight: selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial ? kgToLbs(editItem.vehicle_weight).toFixed(2) : editItem.vehicle_weight.toFixed(2) 
            }}
            validationSchema={schema}
            onSubmit={async (values) => {
                const body = {
                    asset_id: selectedAsset.id,
                    calibration_data: {
                        id: editItem.id,
                        is_loaded: editItem.is_loaded,
                        vehicle_weight: selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial ? lbsToKg(values.vehicle_weight) : values.vehicle_weight,
                        epoch_from: editItem.timestamp_from,
                        epoch_till: editItem.timestamp_till,
                    }
                }

                const res = await onEdit(body)
                if (res.statusCode >= 400) {
                    setErrorMessage(res.message)
                } else {
                    setEditItem(null)
                    setStatusMessage(res.message)
                }
            }}
        >
            {({ submitForm }) => {
                return (
                    <Modal isOpen={!!editItem && !disabled}>
                        <ModalHeader toggle={onCancel}>
                            Update This Calibration Data?
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <Col md="6" className="pl-0">
                                    <RivataDropdown
                                        items={[
                                        { id: "empty", label: "Empty" },
                                        { id: "loaded", label: "Loaded" },
                                        ]}
                                        selected={editItem.is_loaded ? "loaded" : "empty"}
                                        onSelect={(id) => {
                                            let item = { ...editItem }
                                            item.is_loaded = id === "loaded" ? true : false
                                            setEditItem(item)
                                        }}
                                    />
                                </Col>

                                <RivataFormField 
                                    className="mt-3 mb-3 pl-0 mr-1" 
                                    md={6} 
                                    name={"vehicle_weight"} 
                                    label={`Vehicle Weight (${selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial ? "lbs" : "kg"})`} 
                                />

                                <div className="d-flex">
                                    <DateInput
                                        onSelect={(dateStart) => {
                                            let editedItem = {...editItem}
                                            // get epoch based on user selected timezone
                                            const dates = dateToEpoch(dateStart)
                                            editedItem.timestamp_from = dates.startDate

                                            setEditItem(editedItem)
                                        }}
                                        to={new Date(moment.unix(editItem.timestamp_from).format("MM/DD/YYYY"))}
                                        title={"Select Vehicle Weight Date Period"}
                                        customClassNames={"calibration-edit-modal__date-range"}
                                        disabled={disabled}
                                        md={6}
                                        isSingleDate={true}
                                        singleDateTitle={"Select Date From"}
                                        singleDateMd={12}
                                    />
                                    <div className="mt-3"></div>
                                    <DateInput
                                        onSelect={(dateEnd) => {
                                            let editedItem = {...editItem}
                                            // get epoch based on user selected timezone
                                            const dates = dateToEpoch(null, dateEnd)
                                            editedItem.timestamp_till = dates.endDate

                                            setEditItem(editedItem)
                                        }}
                                        to={new Date(moment.unix(editItem.timestamp_till).subtract(12, "hours").format("MM/DD/YYYY"))}
                                        title={"Select Vehicle Weight Date Period"}
                                        customClassNames={"calibration-edit-modal__date-range"}
                                        disabled={disabled || editItem.timestamp_till > getCurrentEpoch()}
                                        md={6}
                                        isSingleDate={true}
                                        singleDateTitle={"Select Date To"}
                                        singleDateMd={12}
                                    />
                                </div>

                                <Col md={12} className="pl-0 mt-2">
                                    <div className="error">{errorMessage ? errorMessage : ""}</div>
                                </Col>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => submitForm()}
                            >
                                {locale["save"] || "Save"}
                            </Button>{" "}
                            <Button color="secondary" onClick={onCancel}>
                                {locale["cancel"] || "Cancel"}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            }}
        </Formik>
    )
}

export default CalibrationEditModal