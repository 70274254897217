import React, { useState, useEffect } from 'react'
import { Col, Label } from 'reactstrap'
import RivataModule from '../../components/RivataModule'
import TimezonePicker from '../../components/TimezonePicker'
import UnitsOfMeasureToggle from '../../components/UnitsOfMeasureToggle'

const CustomerDefaults = ({ title, locale, width, preferences, onUpdate }) => {
    const { locale: { timezone }, unitsOfMeasurement } = preferences

    const [zone, setZone] = useState(timezone)
    const [unitsOfMeasure, setUnitsOfMeasure] = useState(unitsOfMeasurement)

    useEffect(() => {
        setZone(timezone)
    }, [timezone])

    useEffect(() => {
        setUnitsOfMeasure(unitsOfMeasurement)
    }, [unitsOfMeasurement])

    return (
        <RivataModule
            title={title}
            locale={locale}
            width={width}
        >
            <div>
                <Col md="12" className="p-0">
                    <h3>{locale["Locale"]}</h3>
                    <hr/>
                    <Col md="3" className="p-0">
                        <Label>Timezone</Label>
                        <TimezonePicker
                            onSetTimezone={(z) => {
                                if (z !== zone) {
                                    setZone(z)

                                    if (z) {
                                        onUpdate({ ...preferences, locale: { ...preferences.locale, timezone: z } })
                                    }
                                }
                            }}
                            initialValue={zone}
                            locale={locale}
                            id="customer_default"
                        />
                    </Col>
                </Col>
                <Col className="mt-3 mb-3 p-0">
                    <h3>Units of Measure</h3>
                    <hr/>
                    <Label>Select Units of Measure:</Label>
                    <UnitsOfMeasureToggle
                        onChangeUnitsOfMeasure={(u) => {
                            if (u !== unitsOfMeasure) {
                                setUnitsOfMeasure(u)
                                onUpdate({ ...preferences, unitsOfMeasurement: u })
                            }
                        }}
                        unitsOfMeasure={unitsOfMeasure}
                    />
                </Col>
            </div>
        </RivataModule>
    )
}

export default CustomerDefaults