import methods from "./base";
//import { temps, test_month_week } from "./test";
import moment from "moment";
const { get, post, put, fetchPresignedUrl } = methods;

const getAssets = async (
  token,
  customerId,
  limit = 30,
  offset = 0,
  days,
  filterType,
  filterData,
  sortColumn,
  sortDirection,
  sensorType,
  skipActiveSubscriptionCheck,
  hideAutogeneratedAssets
) => {
  const params = new URLSearchParams();

  if (typeof customerId === "number") {
    params.append("customer_id", customerId);
  }
  if (typeof limit === "number") {
    params.append("limit", limit);
  }

  if (typeof offset === "number") {
    params.append("offset", offset);
  }

  if (typeof days === "number") {
    params.append("warning_days", days);
  }

  if (filterType) {
    params.append("filter_type", filterType);
  }

  if (filterData) {
    params.append("filter_data", filterData);
  }

  if (sortColumn) {
    params.append("sort_column", sortColumn);
  }

  if (sortDirection) {
    params.append("sort_direction", sortDirection);
  }

  if (sensorType) {
    params.append("sensor_type", sensorType);
  }

  if (skipActiveSubscriptionCheck) {
    params.append("skip_active_sub_check", true);
  }

  if (hideAutogeneratedAssets) params.append("hide_autogenerated", true);

  const queryString = params.toString();

  return get(`rivata/asset?${queryString}`, token);
};

const getGps = async (assetId, startDate, endDate, token) => {
  try {
    const response = await get(
      `rivata/location?asset_id=${assetId}&epoch_from=${startDate}&epoch_to=${endDate}`,
      token
    );

    return response;
  } catch (err) {
    throw new Error({ err, id: assetId });
  }
};
const getLatestGps = async (assetId, window, token) => {
  try {
    const response = await get(
      `rivata/location?asset_id=${assetId}&days=${window}&limit=1`,
      token
    );
    return response;
  } catch (err) {
    throw new Error({ err, id: assetId });
  }
};
const getLatestGpsBulk = async (
  token,
  customerId,
  days,
  filterType,
  filterData,
  hideAutogeneratedAssets
) => {
  const params = new URLSearchParams();
  if (customerId) {
    params.append("customer_id", customerId);
  }
  if (filterType) {
    params.append("filter_type", filterType);
  }
  if (filterData) {
    params.append("filter_data", filterData);
  }
  if (typeof days === "number") {
    params.append("warning_days", days);
  }
  if (hideAutogeneratedAssets) params.append("hide_autogenerated", true);

  const queryString = params.toString();

  try {
    const response = await get(`rivata/location?${queryString}`, token);
    return response;
  } catch (err) {
    throw new Error({ err });
  }
};
const postAcknowledgements = async (acknowledgements, token) => {
  return post(
    `rivata/warning/acknowledge`,
    JSON.stringify(acknowledgements),
    token
  );
};
const getAcknowledgements = async (assetIds, window, token) => {
  try {
    const response = await get(
      `rivata/warning/acknowledge?asset_ids=${assetIds.join(
        ","
      )}&days=${window}`,
      token
    );
    return response;
  } catch (err) {
    throw new Error({ err, assetIds });
  }
};
const getStatusAssets = async (
  token,
  customerId,
  limit = 30,
  offset = 0,
  type,
  filterType,
  filterData,
  sortColumn,
  sortDirection,
  assetId
) => {
  const params = new URLSearchParams();

  if (customerId) {
    params.append("customer_id", customerId);
  }

  if (typeof limit === "number") {
    params.append("limit", limit);
  }

  if (typeof offset === "number") {
    params.append("offset", offset);
  }

  if (typeof type === "string") {
    params.append("asset_type", type);
  }

  if (filterType) {
    params.append("filter_type", filterType);
  }

  if (filterData) {
    params.append("filter_data", filterData);
  }

  if (sortColumn) {
    params.append("sort_column", sortColumn);
  }

  if (sortDirection) {
    params.append("sort_direction", sortDirection);
  }

  if (typeof assetId === "number") {
    params.append("asset_id", assetId);
  }

  const queryString = params.toString();

  return get(`rivata/pressure/latest?${queryString}`, token);
};

const getAsset = async (vin, token) =>
  get(`rivata/asset?exactvin=${vin}`, token);
const getSensorNodeInfo = async (assetId, token) =>
  get(`rivata/hardware?asset_id=${assetId}`, token);
const getNodeInfo = async (assetId, token) =>
  get(`sensor_nodes/${assetId}`, token);

const postNodeCommand = async (gatewayUid, commandData, token) =>
  post(`gateways/request/${gatewayUid}`, JSON.stringify(commandData), token);

const getTemperatures = async (assetId, window, token) => {
  return get(`rivata/temperature?asset_id=${assetId}&days=${window}`, token);
  //return test_month_week;
};
const getVibrations = async (assetId, window, token) => {
  return get(`rivata/vibration?asset_id=${assetId}&days=${window}`, token);
};

const getLogsByKey = async (
  hardware_id,
  type,
  epoch,
  logs_from,
  logs_to,
  token,
  epoch_to
) => {
  let stamp = type === "sensors" ? moment(epoch * 1000).format("YYYYMMDD") : epoch;
  let logsCount = type === 'sensors' ? logs_to-1 : logs_to
  return get(
    `rivata/logs?hardware_id=${hardware_id}&type=${type}&epoch=${stamp}&epoch_to=${epoch_to}&logs_from=${logs_from}&logs_to=${logsCount}`,
    token
  );
};

const getRecentWarnings = async (
  assetId,
  window,
  offset,
  limit,
  token,
  allWarnings,
  filter
) => {
  let queryString = `rivata/warning?asset_id=${assetId}&days=${window}&limit=${limit}&offset=${offset}`;

  if (allWarnings) {
    queryString += "&all_warnings=true";
  }

  if (filter.filterType) {
    queryString += `&filter_type=${filter.filterType}&filter_data=${filter.filterData}`;
  }

  return get(queryString, token);
};

const getWarnings = async (assetId, token) => get(`warnings/${assetId}`, token);
const getConfig = async (token, customerId) => {
  const queryString =
    typeof customerId === "number" ? `?customer_id=${customerId}` : "";
  return get(`rivata/whitelabel${queryString}`, token, {
    "Cache-Control": "max-age=0",
  });
};
const getReportFromUrl = async (url) => fetchPresignedUrl(url);
const uploadImage = async (file, filename, token, customerId) => {
  const queryString =
    typeof customerId === "number" ? `&customer_id=${customerId}` : "";

  return post(
    `rivata/whitelabel/image?filename=${filename}${queryString}`,
    file,
    token,
    {}
  );
};

const getDocuments = async (token) => get(`rivata/document`, token);

const postDocument = async (document, title, token) => {
  return post(`rivata/document?filename=${title}`, document, token);
};

const deleteDocument = async (title, token) =>
  methods.delete(`rivata/document?filename=${title}`, token);

const postConfig = async (data, token) =>
  post(`rivata/whitelabel`, data, token);

const getTemperaturesRange = async (
  assetId,
  timeFrom,
  timeTo,
  interval,
  token
) =>
  get(
    `temperatures/${assetId}?time_from=${timeFrom}&time_to=${timeTo}&frequency=${interval}`,
    token
  );
const getTempDataRange = async (assetId, days, token, epochFrom, epochTo) => {
  let query = "";
  if (epochFrom && epochTo) {
    query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`;
  } else {
    query = `&days=${days}`;
  }
  return get(`rivata/temperature?asset_id=${assetId}${query}`, token);
};
const getVibrationsRange = async (assetId, days, token, epochFrom, epochTo) => {
  let query = "";
  if (epochFrom && epochTo) {
    query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`;
  } else {
    query = `&days=${days}`;
  }
  return get(`rivata/vibration?asset_id=${assetId}${query}`, token);
};

const getRecentWarningsRange = async (assetId, timeFrom, timeTo, token) =>
  get(`warnings/${assetId}?time_from=${timeFrom}&time_to=${timeTo}`, token);
const getGpsRange = async (assetId, startDate, endDate, token) => {
  try {
    const response = await get(
      `rivata/location?asset_id=${assetId}&epoch_from=${startDate}&epoch_to=${endDate}`,
      token
    );
    return response;
  } catch (err) {
    throw new Error({ err, id: assetId });
  }
};

const getReport = async (link, token) => get(`rivata/report${link}`, token);

const postCustomer = async (data, token) =>
  post(`rivata/company`, JSON.stringify(data), token);
const postUser = async (data, token) =>
  post(`rivata/user`, JSON.stringify(data), token);
const postAsset = async (data, token) =>
  post(`rivata/asset`, JSON.stringify(data), token);

const putCustomer = async (data, token) =>
  put(`rivata/company?customer_id=${data.id}`, JSON.stringify(data), token);
const putUser = async (data, token) =>
  put(`rivata/user?username=${data.username}`, JSON.stringify(data), token);
const putAsset = async (data, token, username) =>
  put(`rivata/asset?asset_id=${data.id}&user_name=${username}`, JSON.stringify(data), token);

const deleteCustomer = async (data, token) =>
  methods.delete(`rivata/company?customer_id=${data}`, token);
const deleteUser = async (username, token) =>
  methods.delete(`rivata/user?username=${username}`, token);
const deleteAsset = async (data, token) =>
  methods.delete(`rivata/asset?asset_id=${data}`, token);

const getCustomers = async (token) => get(`rivata/company`, token);

const getUsers = async (token, customerId, limit, offset, sortColumn, sortDirection) => {
  const params = new URLSearchParams();

  if (typeof customerId === "number") {
    params.append("customer_id", customerId);
  }

  if (typeof limit === "number") {
    params.append("limit", limit);
  }

  if (typeof offset === "number") {
    params.append("offset", offset);
  }

  if (sortColumn) {
    params.append("sort_column", sortColumn);
  }

  if (sortDirection) {
    params.append("sort_direction", sortDirection);
  }

  const queryString = params.toString();

  return get(`rivata/user?${queryString}`, token);
};

const getWarningsSettings = async (
  token,
  hardwareTypeId,
  customerId,
  profileId
) => {
  let queryString = `?warning_type_id=${hardwareTypeId}`;
  if (customerId) {
    queryString += `&customer_id=${customerId}`;
  }
  if (profileId) {
    queryString += `&profile_id=${profileId}`;
  }
  return get(`rivata/warningsetting${queryString}`, token);
};

const putWarningSettings = async (token, data) => {
  return put(`rivata/warningsetting`, JSON.stringify(data), token);
};

const postWarningSettings = async (token, data) => {
  return post(`rivata/warningsetting`, JSON.stringify(data), token);
};

const deleteWarningSettings = async (token, settingsId) => {
  return methods.delete(
    `rivata/warningsetting?settings_id=${settingsId}`,
    token
  );
};

const getSettingsDefaults = async (token, customerId = null) => {
  return get(
    `rivata/warningsetting/default${customerId ? "?customer_id=" + customerId : ""
    }`,
    token
  );
};

const assignWarningSettingsToAssets = async (token, data) => {
  return put(`rivata/warningsetting/assign`, JSON.stringify(data), token);
};

const getAxleLoad = async (token, assetId, days) => {
  return get(`rivata/axleload?asset_id=${assetId}&days=${days}`, token);
};

const getTirePressure = async (assetId, window, token, epochFrom, epochTo) => {
  let query = "";
  if (epochFrom && epochTo) {
    query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`;
  } else {
    query = `&days=${window}`;
  }
  return get(`rivata/pressure?asset_id=${assetId}${query}`, token);
}

const getLinePressure = async (token, assetId, days, epochFrom, epochTo) => {
  let query = "";
  if (epochFrom && epochTo) {
    query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`;
  }  else {
    query = `&days=${days}`;
  }
  return get(`rivata/linepressure?asset_id=${assetId}${query}`, token);
};

const getSpeedData = async (
  token,
  assetId,
  days,
  epochFrom,
  epochTo,
  calculateMotionTimespans
) => {
  let query = "";

  if (days) {
    query = `&days=${days}`;
  } else if (epochFrom && epochTo) {
    query = `&epoch_from=${epochFrom}&epoch_to=${epochTo}`;
  }

  if (calculateMotionTimespans) {
    query += `&calculate_motion_timespans=true`;
  }

  return get(`rivata/speed?asset_id=${assetId}${query}`, token);
};

const getDataAboutApi = async (token) => {
  return get(`about`, token);
};

const getProvisionJson = async (token, vin) => {
  return get(`v1/hardware/provision?vin=${vin}`, token);
};

const postProvisionJson = async (token, json) => {
  return post("v1/hardware/provision", JSON.stringify(json), token);
};

const getLatestProvisionTimestamp = async (token, vin) => {
  return get(`rivata/hardware/last_provision_timestamp?vin=${vin}`, token);
};

const postMigrateData = async (token, data) => {
  return post(`rivata/hardware/migratedata`, JSON.stringify(data), token);
};

const postRequestAccessData = async (data) => {
  return post("requestaccess", JSON.stringify(data));
};

const deleteHardwareData = async (token, assetId) => {
  return methods.delete(
    `rivata/hardware/deletedata?asset_id=${assetId}`,
    token
  );
};

const subscribeToAlerts = async (token, data) => {
  return post(`rivata/user/subscribealerts`, JSON.stringify(data), token);
};

const getUserAttributes = (token) => {
  return get("rivata/user/attributes", token);
};

const putUserAttributes = (token, data) => {
  return put("rivata/user/attributes", data, token);
};

const getAssetHardwareCommandsHistory = (token, id) => {
  return get(`rivata/hardware/commands_history?asset_id=${id}`, token);
};

const putHardwareCommand = (token, data) => {
  return put("rivata/hardware/commands_history", JSON.stringify(data), token);
};

const postAxleLoadCalibrationData = async (token, data) => {
  return post(
    `rivata/hardware/axleload_calibration_data`,
    JSON.stringify(data),
    token
  );
};

const getAxleLoadCalibrationData = async (token, id, offset, limit) => {
  return get(
    `rivata/hardware/axleload_calibration_data?asset_id=${id}&offset=${offset}&limit=${limit}`,
    token
  );
};

const putAxleLoadCalibrationData = (token, data) => {
  return put(
    "rivata/hardware/axleload_calibration_data",
    JSON.stringify(data),
    token
  );
};

const deleteAxleLoadCalibrationData = async (token, assetId, cDataId) => {
  return methods.delete(
    `rivata/hardware/axleload_calibration_data?asset_id=${assetId}&calibration_data_id=${cDataId}`,
    token
  );
};

const getReportStatusAndUrl = async (token, key) => {
  return get(`rivata/report_status?key=${key}`, token);
};

const getGeoLocations = async (
  token,
  customerId,
  limit,
  offset,
  fetchGeofences,
  sort_column,
  sort_direction
) => {
  let queryString = `?limit=${limit}&offset=${offset}`;
  if (customerId) {
    queryString += `&customer_id=${customerId}`;
  }

  if (fetchGeofences) {
    queryString += `&fetch_geofences=true`;
  }

  if(sort_column && sort_direction){
    queryString += `&sort_column=${sort_column}&sort_direction=${sort_direction}`
  }

  return get(`rivata/geolocation${queryString}`, token);
};

const getCustomerGeoLocations = async (token, customerId, fetchGeofences) => {
  let queryString = `?customer_id=${customerId}`;

  if (fetchGeofences) {
    queryString += `&fetch_geofences=true`;
  }

  return get(`rivata/geolocation${queryString}`, token);
};

const getCustomerGeofences = async (token, customerId) => {
  let queryString = "";

  if (customerId) {
    queryString += `&customer_id=${customerId}`;
  }

  return get(`rivata/geolocation?fetch_geofences=true${queryString}`, token);
};

const getGeofenceActivityEvents = async (token, geofenceId, epochFrom, epochTo, limit, offset, assetVin) => {
  let queryString = `?geofence_id=${geofenceId}&limit=${limit}&offset=${offset}`;

  if (epochFrom) queryString += `&epoch_from=${epochFrom}`;

  if (epochFrom) queryString += `&epoch_to=${epochTo}`;

  if (assetVin) queryString += `&asset_vin=${assetVin}`;

  return get(`rivata/geolocation/activity${queryString}`, token);
};

const postGeoLocation = async (token, data) => {
  return post(`rivata/geolocation`, JSON.stringify(data), token);
};

const deleteGeoLocation = async (token, id) => {
  return methods.delete(`rivata/geolocation?id=${id}`, token);
};

const putGeoLocation = async (token, id, data) => {
  return put(`rivata/geolocation?id=${id}`, JSON.stringify(data), token);
};

const deleteUnnasignGatewayFromLocation = async (token, gatewayEsn) => {
  return methods.delete(
    `rivata/geolocation/gateway?gateway_esn=${gatewayEsn}`,
    token
  );
};

const postAssignGatewayToLocation = async (token, data) => {
  return post(`rivata/geolocation/gateway`, JSON.stringify(data), token);
};

const getGeoLocationsCoordinates = async (token, customerId) => {
  let queryString = ``;
  if (customerId) {
    queryString += `?customer_id=${customerId}`;
  }
  return get(`rivata/geolocation/coordinates${queryString}`, token);
};

const getGeolocationDetails = async (token, locationId) => {
  return get(`rivata/geolocation?id=${locationId}`, token);
};

const getGeolocationTagsDetails = async (token, locationId) => {
  return get(`rivata/geolocation/tags?location_id=${locationId}`, token);
};

const getTagGpsDetails = async (
  token,
  mac,
  locationId,
  assetId,
  customerId,
  epochFrom,
  epochTo
) => {
  const params = new URLSearchParams();

  if (customerId) {
    params.append("customer_id", customerId);
  }
  if (mac) {
    params.append("mac", mac);
  }
  if (locationId) {
    params.append("location_id", locationId);
  }
  if (assetId) {
    params.append("asset_id", assetId);
  }

  if (epochFrom) {
    params.append("epoch_from", epochFrom);
  }

  if (epochTo) {
    params.append("epoch_to", epochTo);
  }

  return get(`rivata/geolocation/tags/gps?${params.toString()}`, token);
};

const getTagGpsFilters = async (token, customer_id) => {
  let query_params = "";
  if (customer_id) {
    query_params = `?customer_id=${customer_id}`
  };

  return get(
    `rivata/geolocation/tags/filters${query_params}`,
    token
  );
};

const getTagGpsDetailsDetailed = async (
  token,
  mac,
  locationId,
  assetId,
  customerId,
  limit,
  offset,
  epochFrom,
  epochTo
) => {
  const params = new URLSearchParams();

  if (customerId) {
    params.append("customer_id", customerId);
  }
  if (mac) {
    params.append("mac", mac);
  }
  if (locationId) {
    params.append("location_id", locationId);
  }
  if (assetId) {
    params.append("asset_id", assetId);
  }

  if (epochFrom) {
    params.append("epoch_from", epochFrom);
  }

  if (epochTo) {
    params.append("epoch_to", epochTo);
  }

  params.append("limit", limit);

  params.append("offset", offset);

  return get(`rivata/geolocation/tags/gpsdetailed?${params.toString()}`, token);
};

const getTagDetails = (token, mac) => {
  return get(`rivata/geolocation/tags?mac=${mac}`, token);
};

const putWhitelabelColors = (token, data) => {
  return put("rivata/whitelabel/reset", JSON.stringify(data), token);
};

const postResendTomporaryPassword = (token, data) => {
  return post("reset_temporary_password", JSON.stringify(data), token);
};

const getHardwareProvisionHistory = (
  token,
  assetId,
  epochFrom = null,
  epochTo = null
) => {
  const params = new URLSearchParams();
  if (assetId) {
    params.append("id", assetId);
  }
  if (epochFrom) {
    params.append("epoch_from", epochFrom);
  }
  if (epochTo) {
    params.append("epoch_to", epochTo);
  }

  return get(`rivata/hardware/provision/history?${params.toString()}`, token);
};

const postCopyProvisioningToEnviroments = (token, data) => {
  return post(
    "rivata/hardware/provision/copytoenviroments",
    JSON.stringify(data),
    token
  );
};

const getAssetGeofencesEvents = (token, assetId, epoch_from, epoch_to) => {
  const params = new URLSearchParams();
  if (assetId) {
    params.append("asset_id", assetId);
  }
  if (epoch_from) {
    params.append("epoch_from", epoch_from);
  }
  if (epoch_to) {
    params.append("epoch_to", epoch_to);
  }

  return get(`rivata/geolocation/event?${params.toString()}`, token)
}

const getGeoLocationCredentials = async (location_id, token) => {
  let queryString = `?location_id=${location_id}`;

  return get(`rivata/geolocation/credentials${queryString}`, token);
};

const migrateAssetBetweenCustomers = async(payload, token) => {
  return post(
    "rivata/asset/migrate",
    JSON.stringify(payload),
    token
  );
}

const getpowerInput = async (assetId, token) => {
  return get(`rivata/powerinputs?asset_id=${assetId}`, token)
}

export default {
  getReportFromUrl,
  postCustomer,
  postUser,
  postAsset,
  putCustomer,
  putUser,
  putAsset,
  deleteCustomer,
  deleteUser,
  deleteAsset,
  getUsers,
  getCustomers,
  getReport,
  getTempDataRange,
  getTemperaturesRange,
  getVibrationsRange,
  getRecentWarningsRange,
  getGpsRange,
  getWarnings,
  getAssets,
  getStatusAssets,
  getGps,
  getLatestGps,
  getLatestGpsBulk,
  getAsset,
  getSensorNodeInfo,
  getNodeInfo,
  postNodeCommand,
  getTemperatures,
  getVibrations,
  getTirePressure,
  getRecentWarnings,
  uploadImage,
  postConfig,
  getConfig,
  postAcknowledgements,
  getAcknowledgements,
  getWarningsSettings,
  putWarningSettings,
  postWarningSettings,
  deleteWarningSettings,
  getSettingsDefaults,
  assignWarningSettingsToAssets,
  getAxleLoad,
  getLinePressure,
  getSpeedData,
  getDataAboutApi,
  getProvisionJson,
  postProvisionJson,
  postRequestAccessData,
  getLatestProvisionTimestamp,
  postMigrateData,
  deleteHardwareData,
  subscribeToAlerts,
  getUserAttributes,
  putUserAttributes,
  getAssetHardwareCommandsHistory,
  putHardwareCommand,
  postAxleLoadCalibrationData,
  getAxleLoadCalibrationData,
  putAxleLoadCalibrationData,
  deleteAxleLoadCalibrationData,
  getReportStatusAndUrl,
  getDocuments,
  postDocument,
  deleteDocument,
  getGeoLocations,
  getCustomerGeoLocations,
  getCustomerGeofences,
  postGeoLocation,
  deleteGeoLocation,
  putGeoLocation,
  deleteUnnasignGatewayFromLocation,
  postAssignGatewayToLocation,
  getGeoLocationsCoordinates,
  getGeolocationDetails,
  getGeolocationTagsDetails,
  getTagGpsDetails,
  getTagGpsDetailsDetailed,
  putWhitelabelColors,
  getTagGpsFilters,
  getTagDetails,
  postResendTomporaryPassword,
  getHardwareProvisionHistory,
  postCopyProvisioningToEnviroments,
  getLogsByKey,
  getAssetGeofencesEvents,
  getGeofenceActivityEvents,
  getGeoLocationCredentials,
  migrateAssetBetweenCustomers,
  getpowerInput
};
