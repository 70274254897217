import React from 'react';
import moment from 'moment-timezone'
import RivataLineChart from "../RivataLineChart";

const LineChartGroup = ({ chartsData }) => {
  if (!chartsData) return null
  return chartsData.map((c) => {
    return (
      <div key={c.id}>
        <RivataLineChart
          height={c.height}
          id={c.id}
          data={c.data}
          legendPostion={c.legendPostion}
          legendLabel={c.legendLabel}
          annotations={c.annotations}
          xMin={c.xMin}
          xMax={c.xMax}
          setRef={c.setRef}
          setRef2={c.setRef2}
          onZoomUpdate={c.onZoomUpdate}
          onDoubleClick={c.onDoubleClick}
          xAxisLabel={`Date (${moment().tz(c.timezone).format("z")})`}
        />
      </div>
    )
  })
}

export default LineChartGroup;