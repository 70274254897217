import api from "../../services/api";
import { getToken } from "../../utils";
import {
  SET_TRESHOLDS_LOADING,
} from "../actionTypes";
import { fetchDefaults } from '../common/action'

export const setIsLoading = (payload) => {
  return {
    type: SET_TRESHOLDS_LOADING,
    payload
  }
}

export function postTresholds(tresholds) {
  return function (dispatch, getState) {
    dispatch(setIsLoading(true));
    const token = getToken(getState);
    const { id, name } = getState().common.customers.selectedCustomer
    return api.putWarningSettings(token, tresholds).then(async () => {
      await dispatch(fetchDefaults(id))
      return { statusCode: 200, message: `Thresholds for customer "${name}" successfully updated` }
    }).catch(err => {
      return { statusCode: 400, message: err.message ? err.message : "Something went wrong, please try again later" }
    }).finally(() => {
      dispatch(setIsLoading(false));
    })
  }
}