

export const assetRequest = (asset: any, customer_id: number) => {
    delete asset.customer_name
  
    const request = {
        customer_id,
        id: asset.id,
        vin: asset.vin.trim(),
        attributes: asset.attributes || {},
        name: asset.name,
        make: asset.make,
        model: asset.model,
        odometer_start: asset.odometer_start === "" ? null : +asset.odometer_start,
        year: asset.year === "" ? null : +asset.year,
        asset_type: asset.asset_type,
        hours_of_use: asset.hours_of_use
    }
  
    return request
}