const stage = process.env.REACT_APP_STAGE || "dev";
let clientId, userPoolId, mapApiKey;

// TODO: We need to get these values from terraform and added to app
//       via REACT_APP_<VAR> in build spec.
switch(stage) {
  default:
  case "dev":
    clientId = "64vmlv8go301m4n1vb55f2qtfk";
    userPoolId = "us-east-1_5BoppvUd8";
    mapApiKey = "Amf9BvoyhDUsZFZETMwAAi8VLADPWoWrsAwUrL2Hco0";
    break;
  case "staging":
    clientId = "6l0ffiks2k7fmboon2burmdu92";
    userPoolId = "us-east-1_zZfS2zaGS";
    mapApiKey = "Amf9BvoyhDUsZFZETMwAAi8VLADPWoWrsAwUrL2Hco0";
    break;
  case "sandbox":
    clientId = "7o0vuba3qv021t8g77b45dp2co";
    userPoolId = "us-east-1_2v2x1Vr5n";
    mapApiKey = "Amf9BvoyhDUsZFZETMwAAi8VLADPWoWrsAwUrL2Hco0";
    break;
  case "prod":
    clientId = "51apm6fv1ilokgaqql558ohu15";
    userPoolId = "us-east-1_O07i0MovB";
    mapApiKey = "SnEWrux2SeYUZ4karn5Gv6KejtEZn1pVJ50MUC4UIs0";
    break;
}

export default {
  "region": "us-east-1",
  "userPool": userPoolId,
  "userPoolBaseUri": `https://auth-rivata-${stage}.auth.us-east-1.amazoncognito.com`,
  "clientId": clientId,
  "callbackUri": `${window.location.origin}/callback`,
  "signoutUri": `${window.location.origin}/logout`,
  "tokenScopes": [
      "openid",
      "email",
      "profile",
      `${window.location.origin}/test_scope`             
  ],
  "apiUri": `${window.location.origin}`,
  "mapApiKey": mapApiKey,
  "build_branch": process.env.REACT_APP_BRANCH || "local",
  "build_fullhash": process.env.REACT_APP_CODEBUILD_RESOLVED_SOURCE_VERSION || "local"
}
