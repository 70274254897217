import React from "react";
import moment from "moment";
import { useState, useRef} from "react";
import { Calendar, DateRange } from "react-date-range";
import {Alert} from 'reactstrap'

const DateHandler = ({ handlerType, chosenDate, setDate, type}) => {
const max = useRef(chosenDate)
const d = new Date(max.current)


  const [ranges, setRanges] = useState([
    {
      startDate: new Date(chosenDate),
      endDate: new Date(chosenDate),
      key: "selection",
    },
  ]);
  const [tooltip, setTooltip] = useState(false)
  const minDateVariant = type === "gateway_translator" ? 6 : 90

  const handleChange = (item)=> {
    if (handlerType === "calendar") {
      setDate({
        fromDate: moment(item).format(),
        toDate: moment(item).format()
      })
    } 
    if(handlerType === 'dateRange'){
      const {startDate, endDate} = item.selection
      const deltaDays = Math.abs(moment(startDate).diff(moment(endDate), 'days'))
      if(deltaDays > 6){
        setTooltip(true)
        setTimeout(()=>{
          setTooltip(false)
        }, 3000)
        return
      }
      setRanges([item.selection])
      setDate({
        fromDate: moment(startDate).format(),
        toDate: moment(endDate).format()
      })
    }
  }

  switch (handlerType) {
    case "calendar":
      return (
        <Calendar
          onChange={handleChange} 
          maxDate={new Date(d.setDate(d.getDate(d.getDate())))}
          minDate={new Date(d.setDate(d.getDate()-90))}
          date={new Date(chosenDate)}
        />
      );
    case "dateRange":
      return (
        <>
        {tooltip && <Alert color='danger' style={{textAlign:'center', fontSize: "12px"}}>
          Day range should NOT exсeed 7 days
        </Alert>}

        <DateRange
          onChange={handleChange}
          ranges={ranges}
          showDateDisplay={false}
          minDate={new Date(d.setDate(d.getDate()- minDateVariant))}
          maxDate={new Date(d.setDate(d.getDate() + minDateVariant))}
        />
      </>
      );
    default: return <div></div>
  }
};
export default DateHandler;
