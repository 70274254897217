import React, { useEffect } from "react";

import Circle from "../shapes/Circle";
import Rectangle from "../shapes/Rectangle";
import Polygon from "../shapes/Polygon";

import { Geometry } from "../types";

interface Props {
  map: H.Map | null;
  selectedShape: string;
  geometry: Geometry;
  disabled: boolean;
  setGeometry: (geometry: Geometry) => void;
  onDrawingStarted: () => void;
  onDrawingFinished: (geofenceWKT: string) => void;
}

const GeofenceConstructor: React.FC<Props> = ({ map, geometry, selectedShape, disabled, setGeometry, onDrawingStarted, onDrawingFinished }) => {
  useEffect(() => {
    if (disabled && geometry) geometry.dispose();

    if (!map || !selectedShape || disabled) return;

    setGeometry(null);

    if (geometry) geometry.dispose();

    if (selectedShape === "circle") setGeometry(new Circle(map, onDrawingStarted, onDrawingFinished));

    if (selectedShape === "rectangle") setGeometry(new Rectangle(map, onDrawingStarted, onDrawingFinished));

    if (selectedShape === "polygon") setGeometry(new Polygon(map, onDrawingStarted, onDrawingFinished));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, selectedShape, disabled]);

  return <></>;
};

export default GeofenceConstructor;
