import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import JSONInput from 'react-json-editor-ajrm';
import JSONlocale from 'react-json-editor-ajrm/locale/en';
import RivataModule from '../../components/RivataModule'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'
import ConfirmModal from '../../components/ConfirmModal'
import InfoModal from '../../components/InfoModal'
import { getErrorObj } from '../../utils'
import CopyProvisionModal from './CopyProvisionModal';
import { findShortMacDuplicates, checkSensorsCount } from "./utils";
import './style.scss'

const composeCopyToEnviromentsBody = (enviromentsDetails, provisioning) => {
    return {
        ...enviromentsDetails,
        provisioning_data: provisioning
    }
}

const ProvisionAssetJson = ({
    isLoading,
    data,
    error,
    postProvision,
    locale = {},
    setProvisionInfoError,
    onCopyJsonToEnviroment
}) => {
    const [copyProvisionModalVisible, setCopyProvisionModalVisible] = useState(false);
    const [jsonInputValue, setJsonInputValue] = useState(null)
    const [jsonError, setJsonError] = useState(false)
    const [provisionStatus, setProvisionStatus] = useState(null)
    const [sensorsError, setSensorsError] = useState("")

    useEffect(() => {
        setJsonInputValue(data)
    }, [data])

    const submithandler = async () => {
        if (!jsonError) {
            const shortMacError = findShortMacDuplicates(jsonInputValue.asset?.hardware);

            if (shortMacError.value) {
                setSensorsError(shortMacError.text)
                return
            }

            const sensorsCountError = checkSensorsCount(jsonInputValue.asset?.hardware);

            if (sensorsCountError) {
                setSensorsError(sensorsCountError)
                return
            }
            const assetTypes = ['trailer', 'tractor'].some((el) => el.toLowerCase() === jsonInputValue.asset.type)
           
            if (!assetTypes) {
                setSensorsError('Unsupported asset type. Allowed types: tractor, trailer')
                return
            }

            const status = await postProvision(jsonInputValue)
            setProvisionStatus(status)
        }
    }

    const [copyButtonDisabled, setCopyButtonDisabled] = useState(false)

    const copyJson = () => {
        if (!data) return

        navigator.clipboard.writeText(JSON.stringify(data))

        setCopyButtonDisabled(true)

        setTimeout(() => {
            setCopyButtonDisabled(false)
        }, 500)
    }

    const copyJsonToEnviroment = (selectedEnviroments) => {
        if (!jsonError) {
            const shortMacError = findShortMacDuplicates(jsonInputValue.asset?.hardware);

            if (shortMacError.value) {
                setSensorsError(shortMacError)
                return
            }

            const sensorsCountError = checkSensorsCount(jsonInputValue.asset?.hardware);

            if (sensorsCountError) {
                setSensorsError(sensorsCountError)
                return
            }

            onCopyJsonToEnviroment(composeCopyToEnviromentsBody(selectedEnviroments, jsonInputValue));
            setCopyProvisionModalVisible(false);
        }
    }

    return (
        <RivataModule
            title={`Provision Info`}
            locale={locale}
            marginTop={0}
        >
            {!isLoading && !Object.keys(data).length ? (
                <StatusAlert customText="No Data" color="success" />
            ) : (
                <>
                    <ConfirmModal
                        open={!!provisionStatus}
                        onClose={() => setProvisionStatus(null)}
                        modalButtons={[
                            { id: 1, label: "Ok", color: "success", onClick: () => setProvisionStatus(null), }
                        ]}
                    >
                        {provisionStatus?.statusCode === 200 ? `Success Provisioning for asset vin: ${jsonInputValue.asset.vin}` : `Something went wrong: ${provisionStatus?.message}`}
                    </ConfirmModal>
                    <InfoModal
                        onConfirm={() => setProvisionInfoError(getErrorObj())}
                        locale={locale}
                        open={error.statusCode >= 400}
                        header={"Provisioning info"}
                        message={error.message}
                    />
                    <InfoModal
                        onConfirm={() => {
                            setSensorsError("")
                        }}
                        locale={locale}
                        open={!!sensorsError}
                        header={"Provisioning info"}
                        message={sensorsError}
                    />
                    <CopyProvisionModal
                        locale={locale}
                        open={copyProvisionModalVisible}
                        onClose={() => setCopyProvisionModalVisible(false)}
                        onConfirm={copyJsonToEnviroment}
                    />
                    {isLoading && <RivataLoader />}
                    <div>
                        <div className="d-flex justify-content-between">
                            <h3 className="mb-3" >Current Provision JSON</h3>

                            <div>
                                <Button
                                    onClick={copyJson}
                                    color="secondary"
                                    className="ml-3 mb-3"
                                    disabled={!data || copyButtonDisabled}
                                >
                                    Copy JSON
                                </Button>
                                
                                {(process.env.REACT_APP_STAGE === "prod" || process.env.REACT_APP_STAGE === "staging") && <Button
                                    onClick={() => setCopyProvisionModalVisible(true)}
                                    color="secondary"
                                    className="ml-3 mb-3"
                                    disabled={!data || copyButtonDisabled}
                                >
                                    Copy JSON To Enviroment
                                </Button>}

                            </div>

                        </div>

                        <JSONInput
                            id='json'
                            locale={JSONlocale}
                            placeholder={data}
                            width="100%"
                            viewOnly={true}
                            confirmGood={false}
                            style={{ body: { padding: "1rem" } }}
                        />
                    </div>
                    <div>
                        <h3 className="mt-3 mb-3">POST New Provisioning JSON</h3>
                        <JSONInput
                            id='json_input'
                            locale={JSONlocale}
                            placeholder={data}
                            width="100%"
                            waitAfterKeyPress={100}
                            style={{ body: { padding: "1rem" } }}
                            onChange={(data) => {
                                setJsonError(!!data.error)
                                setJsonInputValue(data.jsObject)
                            }}
                        />
                        <div className="do-provision__button">
                            <Button
                                onClick={submithandler}
                                color="success"
                                className="mt-3"
                                disabled={jsonError}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </RivataModule>
    )
}

export default ProvisionAssetJson