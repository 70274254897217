// if input value like mac str then return it else create string like a mac from input value
export const cpmposeMac = (inputValue) => {
    let mac = inputValue.trim().toUpperCase().split("")
    if(mac.some(el => el === "-")) return mac.join("")

    let symbols = inputValue.trim().toUpperCase().split("")
    
    return symbols.reduce((prev, current, idx) => {
        // create str like mac: 01-A2-07...
        if ((idx + 1) % 2 !== 0) {
            return prev + current
        } else {
            if (idx + 1 === symbols.length) {
                return prev + current
            }
            
            return prev + current + "-"
        }
    }, "")
}