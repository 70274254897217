import React from "react";
import { Row } from "reactstrap";
import { FormField } from "../../components/CreateModal";
import CreateModal from "../../components/CreateModal/children";
import * as Yup from "yup";

const ProfileSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
});

const ProfileModal = ({
    locale,
    onCreate,
    disabled,
    initialValues,
    isUpdate,
    title,
    open,
    onClose,
}) => {
    return (
        <CreateModal
            variant="primary"
            disabled={disabled}
            className="ml-3"
            btnClassName="ml-3"
            onCreate={onCreate}
            createBtnLabel={title}
            openBtnLabel={`${title} Profile`}
            header={`${title} Profile`}
            locale={locale}
            schema={ProfileSchema}
            initialValues={initialValues}
            onClose={onClose}
            open={open}
        >
            <Row className="p-t-0">
                <FormField name="name" label="Profile Name" disabled={isUpdate} />
            </Row>
        </CreateModal>
    );
};

export default ProfileModal;
