import React, { useState } from 'react'
import { Col } from 'reactstrap'
import './style.scss'

const TagsInput = ({
    title,
    list = [],
    text,
    setList,
    disabled=false,
    md = "12"
}) => {
    const [tagsList, setTagsList] = useState(list)
    const [currentInputValue, setCurrentInputValue] = useState("")
  
    return (
        <Col md={md} className="p-0">
          <label>{title}</label>
          <div 
            className="d-flex mb-2 flex-wrap"
            style={{ minHeight: "27px" }}
          >
            {tagsList.map((vin) => {
              return (
                <div 
                  key={vin} 
                  className="mr-2 mt-1 p-1 d-flex"
                  style={{ background: "#dcddde" }}
                >
                  <label className="mb-0">{vin}</label>
                  <span
                    className="ml-1"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      const idx = tagsList.findIndex((el) => el === vin ? true : false)
                      if (idx > -1) {
                        const newArray = [...tagsList]
                        newArray.splice(idx, 1)
                        if (typeof setList === "function") {
                          setList(newArray)
                        }
                        setTagsList(newArray)
                      }
                    }}
                  >
                    &#10006;</span>
                </div>
              )
            })}
          </div>
          <div className="d-flex">
            <input 
              type="text" 
              placeholder="ex: 5W2VG9EBZLN226923" 
              className="tag__input w-100"
              disabled={disabled}
              style={{ maxWidth: "400px" }}
              value={currentInputValue}
              onChange={(e) => {
                const inputValue = e.target.value.split('')
                if (
                  inputValue.includes("/") || 
                  inputValue.includes(":") || 
                  inputValue.includes(";") || 
                  inputValue.includes(",") || 
                  inputValue.includes(" ")
                ) return
                setCurrentInputValue(e.target.value.toUpperCase())
              }}
              onKeyPress={(e) => {
                if ([13, 44, 32].includes(e.which) && !tagsList.includes(e.target.value) && e.target.value !== "") {
                  if (typeof setList === "function") {
                    setList([...tagsList, currentInputValue])
                  }
                  setTagsList([...tagsList, currentInputValue])
                  setCurrentInputValue("")
                }
              }}
            />
          </div>
          <label 
            className="d-flex mt-2"
            style={{ fontStyle: "oblique", fontSize: "0.8rem" }}
          >
            {text}
          </label>
        </Col>
    )
}

export default TagsInput