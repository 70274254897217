import {
  LOADING_ASSET_LOCATION,
  SET_ASSET_LOCATION,
  SET_ASSET_LOCATION_ERROR,
  SET_RECENT_DETAILS,
  SET_RECENT_DETAILS_ERROR,
  LOADING_VEHICLE_INFO,
  SET_VEHICLE_INFO,
  SET_VEHICLE_INFO_ERROR,
  LOADING_SENSOR_INFO,
  SET_SENSOR_INFO,
  SET_SENSOR_INFO_ERROR,
  LOADING_VIBRATIONS,
  SET_VIBRATIONS,
  SET_VIBRATIONS_ERROR,
  LOADING_TIRE_PRESSURE,
  SET_TIRE_PRESSURE,
  SET_TIRE_PRESSURE_ERROR,
  LOADING_AXLE_LOAD,
  SET_AXLE_LOAD,
  SET_AXLE_LOAD_ERROR,
  LOADING_LINE_PRESSURE,
  SET_LINE_PRESSURE,
  SET_LINE_PRESSURE_ERROR,
  LOADING_SPEED_DATA,
  SET_SPEED_DATA,
  SET_SPEED_DATA_ERROR,
  LOADING_TEMPERATURE_HISTORY,
  SET_TEMPERATURE_HISTORY,
  SET_TEMPERATURE_HISTORY_ERROR,
  LOADING_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS_ERROR,
  SET_ASSIGNED_TPMS_PROFILE,
  SET_AXLES_COUNT,
  SET_ASSET_TPMS_STATUS,
  LOADING_ASSET_TPMS_STATUS,
  SET_ACKNOWLEDGMENT_WARNING_COUNT,
  SET_RECENT_DETAILS_COUNT,
  SET_RECENT_DETAILS_LIMIT,
  SET_RECENT_DETAILS_OFFSET,
  SET_RECENT_DETAILS_SHOW_ALL,
  SET_RECENT_DETAILS_FILTER,
  LOADING_RECENT_DETAILS,
  SET_WARNINGS_INTERVAL,
  RESET_DETAILS_REDUCER,
  LOADING_POWERINPUTS,
  SET_POWERINPUTS,
  SET_POWERINPUTS_ERROR
} from "../actionTypes";

// TODO: May want to do reducers by data, not pages.
// Many of the data below is copy pasta in warnings,
// and gets set by actions on that page (and vice versa).
const initialState = {
  vehicleInfo: { isLoading: false, data: {}, error: { statusCode: 0, message: "" } },
  sensorNodeInfo: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  vibrations: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  tirePressure: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  axleLoad: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  linePressure: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  speedData: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  temperature: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  detailsMap: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  acknowledgements: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  assetTpmsStatus: { isLoading: false, data: {}, error: { statusCode: 0, message: "" } },
  powerInputs: { isLoading: false, data: [], error: { statusCode: 0, message: "" } },
  assignedTpmsProfile: {},
  recentDetails: {
    totalCount: 0,
    limit: 10,
    offset: 0,
    showAllWarnings: false, 
    filter: {},
    isLoading: false, 
    data: [],
    intervals: [],
    error: { statusCode: 0, message: "" }
},
  axlesCount: 0,
  acknowledgementWarningCount: 0,
};
const setLoading = (key, state, isLoading) => ({
  ...state,
  [key]: { ...state[key], isLoading },
});
const setData = (key, state, data) => {
return {...state, [key]:{ ...state[key], data },
}
};
const setError = (key, state, error) => ({
  ...state,
  [key]: { ...state[key], error: { ...error } },
});
const setParameter = (state, key, payload) => ({ ...state, [key]: payload })

const setField = (state, property, field, payload) => ({ ...state, [property]: { ...state[property], [field]: payload } })

const filterWarnings = state => {
  const { recentDetails, acknowledgements } = state;
  const unacknowledgedWarnings = recentDetails.data.filter(warning => {
    return !acknowledgements.data.find(({ timestamp, position, type }) => {
      return timestamp === warning.timestamp && position === warning.position && type === warning.type;
    });
  });

  return { ...state, recentDetails: { ...recentDetails, data: unacknowledgedWarnings } };
}

const setAcknowledgementWarningCount = (state, acknowledgementWarningCount)=> ({...state, acknowledgementWarningCount})

const details = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_VEHICLE_INFO:
      return setLoading("vehicleInfo", state, action.payload);
    case LOADING_ASSET_LOCATION:
      return setLoading("detailsMap", state, action.payload);
    case LOADING_RECENT_DETAILS:
      return setLoading("recentDetails", state, action.payload);
    case LOADING_SENSOR_INFO:
      return setLoading("sensorNodeInfo", state, action.payload);
    case LOADING_VIBRATIONS:
      return setLoading("vibrations", state, action.payload);
    case LOADING_TIRE_PRESSURE:
      return setLoading("tirePressure", state, action.payload);
    case LOADING_AXLE_LOAD:
      return setLoading("axleLoad", state, action.payload);
    case LOADING_LINE_PRESSURE:
      return setLoading("linePressure", state, action.payload);
    case LOADING_SPEED_DATA:
      return setLoading("speedData", state, action.payload);
    case LOADING_TEMPERATURE_HISTORY:
      return setLoading("temperature", state, action.payload);
    case LOADING_ASSET_TPMS_STATUS:
      return setLoading("assetTpmsStatus", state, action.payload);
    case LOADING_POWERINPUTS:
      return setLoading("powerInputs", state, action.payload);

      
    case SET_VEHICLE_INFO:
      return setData("vehicleInfo", state, action.payload);
    case SET_ASSET_LOCATION:
      return setData("detailsMap", state, action.payload);
    case SET_RECENT_DETAILS:
      const recentWarningsState = setData("recentDetails", state, action.payload);
      return filterWarnings(recentWarningsState);
    case SET_ASSET_TPMS_STATUS:
      return setData("assetTpmsStatus", state, action.payload);
    case SET_SENSOR_INFO:
      return setData("sensorNodeInfo", state, action.payload);
    case SET_VIBRATIONS:
      return setData("vibrations", state, action.payload.reverse());
    case SET_TIRE_PRESSURE:
      return setData("tirePressure", state, action.payload.reverse());
    case SET_AXLE_LOAD:
      return setData("axleLoad", state, action.payload.reverse());
    case SET_LINE_PRESSURE:
      return setData("linePressure", state, action.payload.reverse());
    case SET_SPEED_DATA:
      return setData("speedData", state, action.payload);
    case SET_TEMPERATURE_HISTORY:
      return setData("temperature", state, action.payload.reverse());
    case SET_POWERINPUTS:
      return setData("powerInputs", state, action.payload.reverse());


    case SET_VEHICLE_INFO_ERROR:
      return setError("vehicleInfo", state, action.payload);
    case SET_ASSET_LOCATION_ERROR:
      return setError("detailsMap", state, action.payload);
    case SET_RECENT_DETAILS_ERROR:
      return setError("recentDetails", state, action.payload);
    case SET_SENSOR_INFO_ERROR:
      return setError("sensorNodeInfo", state, action.payload);
    case SET_VIBRATIONS_ERROR:
      return setError("vibrations", state, action.payload);
    case SET_TIRE_PRESSURE_ERROR:
      return setError("tirePressure", state, action.payload);
    case SET_AXLE_LOAD_ERROR:
      return setError("axleLoad", state, action.payload);
    case SET_LINE_PRESSURE_ERROR:
      return setError("linePressure", state, action.payload);
    case SET_SPEED_DATA_ERROR:
      return setError("speedData", state, action.payload);
    case SET_TEMPERATURE_HISTORY_ERROR:
      return setError("temperature", state, action.payload);
    case SET_POWERINPUTS_ERROR:
      return setError("powerInputs", state, action.payload);

    case LOADING_ACKNOWLEDGEMENTS:
      return setLoading("acknowledgements", state, action.payload);
    case SET_ACKNOWLEDGEMENTS:
      const acknowledgementsState = setData("acknowledgements", state, action.payload);
      return filterWarnings(acknowledgementsState);
    case SET_ACKNOWLEDGEMENTS_ERROR:
      return setError("acknowledgements", state, action.payload);
    
    case SET_ASSIGNED_TPMS_PROFILE:
      return setParameter(state, "assignedTpmsProfile", action.payload);

    case SET_AXLES_COUNT:
      return setParameter(state, "axlesCount", action.payload);
    case SET_ACKNOWLEDGMENT_WARNING_COUNT:
      return setAcknowledgementWarningCount(state, action.payload)
    ///refactor
  case SET_RECENT_DETAILS_COUNT:
      return setField(state, "recentDetails", "totalCount", action.payload)
  case SET_RECENT_DETAILS_LIMIT:
      return setField(state, "recentDetails", "limit", action.payload)
  case SET_RECENT_DETAILS_OFFSET:
      return setField(state, "recentDetails", "offset", action.payload)
  case SET_RECENT_DETAILS_SHOW_ALL:
      return setField(state, "recentDetails", "showAllWarnings", action.payload)
  case SET_RECENT_DETAILS_FILTER:
      return setField(state, "recentDetails", "filter", action.payload)
  case SET_WARNINGS_INTERVAL:
    return setField(state, "recentDetails", "intervals", action.payload)
  case RESET_DETAILS_REDUCER:
    return initialState
  default:
    return state;
  }
};

export default details;
