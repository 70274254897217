import { useMemo } from 'react'


interface IuseCustomers<T> {
    (
        customers: Array<T> | null,
        selectedReport: string
    ): Array<ICustomer> | null
}

export const useCustomers: IuseCustomers<ICustomer> = (customers, selectedReport) => {
    return useMemo(() => {
        const array: Array<ICustomer> = []
        
        if (!customers) return null

        customers.forEach((item) => {
            if ( item.id === null) return 
            array.push(item)
        })

        return array
    }, [customers, selectedReport])
}