import React from "react";
import { connect } from "react-redux";
import SubNav from '../../components/SubNav'
import Preferences from "../../modules/Preferences";
import { updatePreferences } from "../../redux/auth/action";

const Settings = ({
  preferences,
  onUpdatePreferences,
  locale = {},
  preferenceDefaults
}) => {
  return (
    <>
      <SubNav
        title="User Profile"
        breadcrumbs={[
          { label: "Home", path: "/dashboard" },
          { label: "User Profile" }
        ]}
      />
      <Preferences
        preferences={preferences}
        preferenceDefaults={preferenceDefaults}
        locale={locale}
        onUpdatePreferences={onUpdatePreferences}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  preferences: state.auth.preferences,
  preferenceDefaults: state.whitelabel.preferenceDefaults,
  locale: state.whitelabel.locale,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdatePreferences: (preferences, changePhone) =>
    dispatch(updatePreferences(preferences, changePhone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
