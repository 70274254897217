import { convertDataEpochToDate, getClearLabel } from "../../utils";
import { DatasetsList, getDateRange } from "../../utils/chartUtils";
import { IcomposeChartData } from "./types";

const colors = ["#297568", "#40beac", "#12284b", "#3ab0c7", "#007497", "#85d4ec", "#749799", "#914921"]

export const composeChartData: IcomposeChartData = (data) => {
    if (!data.length) return null
    const template: { [key: string]: Array<any> } = {
        accelerometer_motion: [],
        brake_circuit: [],
        external_power: [],
        external_voltage: [],
        ignition: [],
        internal_battery_charge_is_low: [],
        internal_battery_voltage: [],
        powered_by_internal_battery: []
    }

    const keys = Object.keys(template)

    data.forEach((item) => {
        const x = convertDataEpochToDate(item.epoch, null, null, false)
        
        keys.forEach((key) => {
            if (key === "internal_battery_voltage" || key === "external_voltage") {
                if (item[key] !== null) {
                    item[key] = +(item[key] / 1000).toFixed(3)
                }
            }

            template[key].push({
                x,
                y: item[key],
                displayFooter: `Date: ${convertDataEpochToDate(item.epoch, null, null, true)}`,
                displayValue: `Voltage: ${item[key]}`
            })
        })
    })

    const datasets: DatasetsList = []
    const dropdownItems: Array<any> = [{ id: "all", label: "All" }]

    keys.forEach((key, idx) => {
        const label = getClearLabel(key)

        const dataset = {
            backgroundColor: colors[idx],
            borderColor: colors[idx],
            borderWidth: 3,
            fill: false,
            label,
            id: key,
            data: template[key]
        }

        datasets.push(dataset)

        dropdownItems.push({
            id: key,
            label
        })
    })

    const range = getDateRange(datasets)
    
    return { datasets, xMin: range.min, xMax: range.max, dropdownItems }
}
