import { getColor, getClearLabel, psiToBar } from '../../utils'
import { getDateRange } from '../../utils/chartUtils'
import { UnitsOfMeasurement } from '../../enums'

export const composeTireChartData = (data, graphColors, unitsOfMeasurement) => {
  const pressureUnits = unitsOfMeasurement === UnitsOfMeasurement.imperial ? " PSI" : " Bar"
  const datasets = []

  data.forEach((line) => {
    let row = {
      data: []
    }
    line.data.forEach((pressureData) => {
      const level = unitsOfMeasurement === UnitsOfMeasurement.imperial ? pressureData.avg : psiToBar(pressureData.avg).toFixed(2)

      let dataRow = {
        y: level,
        displayValue: `Pressure: ${level + pressureUnits}`,
        displayFooter: pressureData.displayDate,
        x: pressureData.chartTAxis,
      }

      row.data.push(dataRow)
    })

    row.data = row.data.sort((d1, d2) => +new Date(d1.displayDate) - +new Date(d2.displayDate))
    row.label = line.label !== "NONE" && line.label !== "UNKNOWN" ? getClearLabel(line.label) : `Sensor: ${line.id}`
    const currentColor = getColor(row.label, graphColors)
    row.fill = false
    row.backgroundColor = currentColor
    row.borderColor = currentColor
    row.borderWidth = 2
    datasets.push(row)
  })

  const range = getDateRange(datasets)

  return { datasets, xMax: range.max, xMin: range.min, drawTime: "" };
};

export const drawPressureLinesLegend = (chartLinesLegend) => {
  const canvas = chartLinesLegend.current

  if (canvas) {
    if (canvas.getContext) {
      const ctx = canvas.getContext('2d')
      const critText = "Critical Threshold"

      ctx.canvas.width = 400
      ctx.canvas.height = 30

      // draw rectangle line
      for (let i = 5; i < 50; i += 4) {
        ctx.beginPath()
        ctx.strokeStyle = "#FED925"
        ctx.rect(i, 15, 0.5, 3)
        ctx.stroke()
      }

      // draw crit threshold label
      ctx.font = "16px myriad-pro-condensed"
      ctx.fillStyle = '#858585'
      const critTextWidth = ctx.measureText(critText).width

      ctx.fillText(critText, 55, 20)

      // draw dots line
      ctx.beginPath();
      ctx.strokeStyle = "#FED925"
      ctx.setLineDash([2, 2]);
      ctx.moveTo(55 + critTextWidth + 10, 15);
      ctx.lineTo(55 + critTextWidth + 60, 15);
      ctx.stroke();

      // draw threshold label
      ctx.fillText("Threshold", 55 + critTextWidth + 70, 20)
    }
  }
}

