import React from "react";
import RivataDropdown from "../RivataDropdown";
import { Languages } from "../../enums"

const dropdownItems = [
  {
    id: Languages.EN,
    label: "English",
  },
  {
    id: Languages.FR,
    label: "French",
  },
  {
    id: Languages.GR,
    label: "German",
  },
];

const LanguageDropdown = ({ onSelect, selected, locale }) => {
  return (
    <RivataDropdown
      items={dropdownItems}
      onSelect={onSelect}
      selected={selected}
      locale={locale}
    />
  );
};

export default LanguageDropdown;
