import React from "react"
import { Label } from "reactstrap"
import { NotificationThresholds } from "../../../enums"

interface IAlertConfirmationSectionProps {
    threshold: string,
    criticalAlertsTopicSubscriptionConfirmed: boolean,
    nonCriticalAlertsTopicSubscriptionConfirmed: boolean,
}

const AlertConfirmationSection: React.FC<IAlertConfirmationSectionProps> = ({
    threshold,
    criticalAlertsTopicSubscriptionConfirmed,
    nonCriticalAlertsTopicSubscriptionConfirmed
}) => {
    return (
        <>
            <Label>Confirmation status for Email</Label>
            {threshold === NotificationThresholds.INFORMATIONAL_AND_CRITICAL ? (
                <ul className="confirmation-status-list">
                    <li>
                        {criticalAlertsTopicSubscriptionConfirmed === false ? (
                            <>
                                <i className="far fa-times-circle fa-lg conf-error" />
                                <Label>
                                    Critical alerts subscription needs
                                    verification. Search your email for a
                                    message from "AWS Notifications"
                                </Label>
                            </>
                        ) : criticalAlertsTopicSubscriptionConfirmed === true ? (
                            <>
                                <i className="far fa-check-circle fa-lg conf-success" />
                                <Label>
                                    Critical alerts subscription validated and
                                    active
                                </Label>
                            </>
                        ) : null}
                    </li>
                    <li>
                        {nonCriticalAlertsTopicSubscriptionConfirmed === false ? (
                            <>
                                <i className="far fa-times-circle fa-lg conf-error" />
                                <Label>
                                    Informational alerts subscription needs
                                    verification. Search your email for a
                                    message from "AWS Notifications"
                                </Label>
                            </>
                        ) : nonCriticalAlertsTopicSubscriptionConfirmed === true ? (
                            <>
                                <i className="far fa-check-circle fa-lg conf-success" />
                                <Label>
                                    Informational alerts subscription validated
                                    and active
                                </Label>
                            </>
                        ) : null}
                    </li>
                </ul>
            ) : (
                <ul className="confirmation-status-list">
                    <li>
                        {criticalAlertsTopicSubscriptionConfirmed === false ? (
                            <>
                                <i className="far fa-times-circle fa-lg conf-error" />
                                <Label>
                                    Critical alerts subscription needs
                                    verification. Search your email for a
                                    message from "AWS Notifications"
                                </Label>
                            </>
                        ) : criticalAlertsTopicSubscriptionConfirmed === true ? (
                            <>
                                <i className="far fa-check-circle fa-lg conf-success" />
                                <Label>
                                    Critical alerts subscription validated and
                                    active
                                </Label>
                            </>
                        ) : null}
                    </li>
                </ul>
            )}
        </>
    )
}

export default AlertConfirmationSection
