import moment from 'moment'
import { isPlainObject } from 'lodash'
import {
  LOADING_WARNING_LOCATION,
  SET_WARNING_LOCATION,
  SET_WARNING_LOCATION_ERROR,
  LOADING_RECENT_WARNINGS,
  SET_RECENT_WARNINGS,
  SET_RECENT_WARNINGS_ERROR,
  LOADING_VIBRATIONS,
  SET_VIBRATIONS,
  SET_VIBRATIONS_ERROR,
  LOADING_TIRE_PRESSURE,
  SET_TIRE_PRESSURE,
  SET_TIRE_PRESSURE_ERROR,
  LOADING_SPEED_DATA,
  SET_SPEED_DATA,
  SET_SPEED_DATA_ERROR,
  LOADING_TEMPERATURE,
  SET_TEMPERATURE,
  SET_TEMPERATURE_ERROR,
  LOADING_VEHICLE_INFO,
  SET_VEHICLE_INFO,
  SET_VEHICLE_INFO_ERROR,
  LOADING_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS_ERROR,
  SET_ASSIGNED_TPMS_PROFILE,
  SET_ACKNOWLEDGMENT_WARNING_COUNT,
  ///refactor
  SET_RECENT_WARNINGS_LIMIT,
  SET_RECENT_WARNINGS_OFFSET,
  SET_RECENT_WARNINGS_SHOW_ALL,
  SET_RECENT_WARNINGS_FILTER,
  SET_RECENT_WARNINGS_COUNT,
  RESET_WARNING_DETAILS_REDUCER,
  SET_LINE_PRESSURE,
  LOADING_LINE_PRESSURE,
  SET_LINE_PRESSURE_ERROR
} from "../actionTypes";
import api from "../../services/api";
import {
  handleTimestampsRoot,
  getToken,
  getFormattedDataForLineChart,
  getFormattedDataForVibrationChart,
  convertDataEpochToDate,
  getErrorObj,
  composeTpmsProfiles,
  getColdInflationFromPercent
} from "../../utils";
import {
  fetchDefaults,
} from '../common/action'
import {setWarningsIntervals} from '../details/action'
import { WARNING_TYPE } from '../../constants'

const oneDayInMs = 86400000;
// const oneHourInMs = 3600000;

// details/{id}warnings/{timestamp}
// get the warnings and display the one with the timestamp
const action = (type, payload) => ({ type, payload });
const getAssetId = (getState) => getState().details.vehicleInfo.data.id;

export const resetWarningDetailsReducer = () => ({
  type: RESET_WARNING_DETAILS_REDUCER,
  payload: true
})

export const loadingAcknowledgements = (isPosting) => ({
  type: LOADING_ACKNOWLEDGEMENTS,
  payload: isPosting,
});

export const setAcknowledgementsError = (error) => ({
  type: SET_ACKNOWLEDGEMENTS_ERROR,
  payload: error,
});

export const setAcknowledgements = (acknowledgements) => ({
  type: SET_ACKNOWLEDGEMENTS,
  payload: acknowledgements,
});

export const setAcknowledgmentCount = (ackCount)=>({
  type: SET_ACKNOWLEDGMENT_WARNING_COUNT,
  payload: ackCount
})
///// refactor
export const setRecentWarningsCount = (count) => ({
  type: SET_RECENT_WARNINGS_COUNT,
  payload: count,
})

export const setRecentWarningsLimit = (limit) => ({
  type: SET_RECENT_WARNINGS_LIMIT,
  payload: limit,
})

export const setRecentWarningsOffset = (offset) => {
  return {
      type: SET_RECENT_WARNINGS_OFFSET,
      payload: offset,
  }
}
export const setRecentWarningsShowAll = (showAllWarnings) => ({
  type: SET_RECENT_WARNINGS_SHOW_ALL,
  payload: showAllWarnings,
})

export const setRecentWarningsFilter = (payload) => ({
  type: SET_RECENT_WARNINGS_FILTER,
  payload
})

export const setRecentWarningsloading = (payload) => ({
  type: LOADING_RECENT_WARNINGS,
  payload
})

export function postAcknowledgements(acknowledgements, window) {
  return function (dispatch, getState) {
    dispatch(loadingAcknowledgements(true));
    return api
      .postAcknowledgements(acknowledgements, getToken(getState))
      .then(() => {
        dispatch(fetchRecentWarnings(window));
        dispatch(setAcknowledgementsError(getErrorObj()));
      })
      .catch((error) => {
        dispatch(setAcknowledgementsError(getErrorObj(error)));
      })
      .finally(() => dispatch(loadingAcknowledgements(false)));
  };
}

export function refreshWarningDetails(days = 7) {
  return function (dispatch) {
    dispatch(fetchRecentWarnings(days));
  };
}

export function fetchWarningDetails(timestamp, vin) {
  return function (dispatch, getState) {
    const epochFromWarning = (parseInt(timestamp) - oneDayInMs) / 1000;
    const epochToWarning = (parseInt(timestamp) + oneDayInMs) / 1000;
    const mapDataFrom = (parseInt(timestamp) - (oneDayInMs / 6)) / 1000; // 4 hours before warning
    const mapDataTo = (parseInt(timestamp) + (oneDayInMs / 6)) / 1000 // 4 hours after warning

    const token = getToken(getState);
    const selectedCustomer = getState().common.customers.selectedCustomer
    dispatch(action(LOADING_VEHICLE_INFO, true));
    return api
      .getAsset(vin, token)
      .then((asset) => {
        asset = convertDataEpochToDate(asset, "last_gps_update_epoch", "last_gps_update");
        const validSubscription = {}
        const currentDate = moment(new Date()).unix()
        
        if (asset.subscription) {
          Object.keys(asset.subscription).map((subKey) => {
            const { valid_from, valid_to } = asset.subscription[subKey]
            if (currentDate >= valid_from && currentDate <= valid_to) {
              validSubscription[subKey] = asset.subscription[subKey]
            }
            return subKey
          })
        }
        asset.subscription = validSubscription

        dispatch(action(SET_VEHICLE_INFO, asset));
        if (asset.tpms_profile_id) dispatch(fetchTpmsProfile(asset.tpms_profile_id))
        if (selectedCustomer?.id !== asset.customer_id) dispatch(fetchDefaults(asset.customer_id))

        dispatch(fetchSpeedData(null, epochFromWarning, epochToWarning));
        dispatch(fetchWarningsGps(mapDataFrom, mapDataTo));
        dispatch(fetchVibrations(null, epochFromWarning, epochToWarning));
        dispatch(fetchTirePressure(null, epochFromWarning, epochToWarning))
        dispatch(fetchLinePressure(null, epochFromWarning, epochToWarning))
        dispatch(fetchTemperaturesChunky(null, epochFromWarning, epochToWarning));
        dispatch(action(SET_VEHICLE_INFO_ERROR, getErrorObj()));
      })
      .catch((error) => dispatch(action(SET_VEHICLE_INFO_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_VEHICLE_INFO, false)));
  };
}

export function fetchRecentWarnings(window) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getState().warnings.assetInfo.data.id
    const { limit, offset, showAllWarnings, filter } = getState().warnings.recentWarnings
    dispatch(setRecentWarningsloading(true));
    return api
      .getRecentWarnings(id, window, offset, limit, token, showAllWarnings, filter)
      .then((data) => {
        let pagedData = convertDataEpochToDate(data.paged_data, "epoch");
        pagedData = convertDataEpochToDate(pagedData, "recovered_to_normal_at_epoch", "recovered_to_normal_at_datetime");
        let acknowledged = data.acknowledged_count; 
        let intervals = data.intervals
        dispatch(action(SET_RECENT_WARNINGS, pagedData));
        dispatch(setWarningsIntervals(intervals))
        dispatch(setRecentWarningsCount(data.total_count));
        dispatch(action(SET_RECENT_WARNINGS_ERROR, getErrorObj()));
        dispatch(setAcknowledgmentCount(acknowledged))
      })
      .catch((error) => dispatch(action(SET_RECENT_WARNINGS_ERROR, getErrorObj(error))))
      .finally(() => dispatch(setRecentWarningsloading(false)));
  };
}

export function fetchWarningsGps(startDate, endDate) {
  return async function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_WARNING_LOCATION, true));
    try {
      let data = await api.getGpsRange(id, startDate, endDate, token);
      data.sort((a, b) => a.epoch - b.epoch)
      data = convertDataEpochToDate(data, "epoch");
      dispatch(action(SET_WARNING_LOCATION, data));
      dispatch(action(SET_WARNING_LOCATION_ERROR, getErrorObj()));
    } catch (error) {
      dispatch(action(SET_WARNING_LOCATION_ERROR, getErrorObj(error)));
    } finally {
      return dispatch(action(LOADING_WARNING_LOCATION, false));
    }
  };
}

export function fetchVibrations(days, epochFrom, epochTo) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_VIBRATIONS, true));
    return api
      .getVibrationsRange(id, days, token, epochFrom, epochTo)
      .then((data) => {
        data = getFormattedDataForVibrationChart(data, true)
        dispatch(action(SET_VIBRATIONS, data));
        dispatch(action(SET_VIBRATIONS_ERROR, getErrorObj()))
      })
      .catch((error) => dispatch(action(SET_VIBRATIONS_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_VIBRATIONS, false)));
  };
}

export function fetchTirePressure(window, epochFrom, epochTo) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_TIRE_PRESSURE, true));
    return api
      .getTirePressure(id, window, token, epochFrom, epochTo)
      .then((data) => {
        const formattedData = getFormattedDataForLineChart(data)
        dispatch(action(SET_TIRE_PRESSURE, formattedData));
        dispatch(action(SET_TIRE_PRESSURE_ERROR, getErrorObj()))
      })
      .catch((error) => dispatch(action(SET_TIRE_PRESSURE_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_TIRE_PRESSURE, false)));
  };
}

export function fetchLinePressure(window, epochFrom, epochTo) {
  return function (dispatch, getState) {
    const token = getToken(getState)
    const id = getAssetId(getState)
    dispatch(action(LOADING_LINE_PRESSURE, true))
    return api
      .getLinePressure(token, id, window, epochFrom, epochTo)
      .then((data) => {
        dispatch(action(SET_LINE_PRESSURE, data))
        dispatch(action(SET_LINE_PRESSURE_ERROR, getErrorObj()))
      })
      .catch(error => dispatch(action(SET_LINE_PRESSURE_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_LINE_PRESSURE, false)))
  }
}

export function fetchSpeedData(window, epochFrom, epochTo) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_SPEED_DATA, true));
    return api
      .getSpeedData(token, id, null, epochFrom, epochTo, true)
      .then((data) => {
        dispatch(action(SET_SPEED_DATA, data))
      })
      .catch((error) => dispatch(action(SET_SPEED_DATA_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_SPEED_DATA, false)));
  };
}

export function fetchTemperatures(days) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_TEMPERATURE, true));
    return api
      .getTempDataRange(id, days, token)
      .then((data) => {
        data = handleTimestampsRoot(getState().auth.preferences, data);
        dispatch(action(SET_TEMPERATURE, data));
        dispatch(action(SET_TEMPERATURE_ERROR, getErrorObj()))
      })
      .catch((error) => dispatch(action(SET_TEMPERATURE_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_TEMPERATURE, false)));
  };
}

export function fetchTemperaturesChunky(days, epochFrom, epochTo) {
  return async function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_TEMPERATURE, true));

    return api.getTempDataRange(id, days, token, epochFrom, epochTo).then(data => {
      const formattedData = getFormattedDataForLineChart(data, true)
      dispatch(action(SET_TEMPERATURE, formattedData));
      dispatch(action(SET_TEMPERATURE_ERROR, getErrorObj()))
    })
      .catch((error) => dispatch(action(SET_TEMPERATURE_ERROR, getErrorObj(error))))
      .finally(() => {
        dispatch(action(LOADING_TEMPERATURE, false));
      })
  };
}

export function fetchTpmsProfile(id) {
  return function (dispatch, getState) {
    const token = getToken(getState)
    return api
      .getWarningsSettings(token, WARNING_TYPE.TIRE_PRESSURE_SENSOR, null, id)
      .then((warningSettings) => {
        const profiles = composeTpmsProfiles(warningSettings)
        if (profiles.length) {
          let profile = profiles[0]
          profile.thresholds = {}
          const { low_pressure_in_percent, critical_low_pressure_in_percent, over_pressure_in_percent, critical_over_pressure_in_percent } = profile

          Object.keys(profile.axels).map(typeKey => {
            if (!isPlainObject(profile.axels[typeKey])) return null
            const thresholds = Object.keys(profile.axels[typeKey]).reduce((prev, current) => {
              const cold_inflation_pressure_in_psi = +profile.axels[typeKey][current]
              const axleThresholds = {
                low_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, low_pressure_in_percent),
                critical_low_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, critical_low_pressure_in_percent),
                over_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, over_pressure_in_percent),
                critical_over_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, critical_over_pressure_in_percent),
              }

              const obj = { ...prev, [current]: axleThresholds }

              return obj
            }, {})
            return profile.thresholds[typeKey] = thresholds
          })
          return dispatch(action(SET_ASSIGNED_TPMS_PROFILE, profile))
        }
      })
      .catch((err) => console.log(err))
  }
}