import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

interface Props {
    open: boolean
    locale: Record<string, string>
    header: string
    recipients: Array<AlertRecipient>
    message: string
    onConfirm: () => void
}

const AlertInfoModal: React.FC<Props> = ({ onConfirm, locale, header, open, message, recipients }: Props) => {
    return (
        <Modal isOpen={open}>
            <ModalHeader toggle={onConfirm}>
                {header}
            </ModalHeader>
            <ModalBody>
                <div><b>Alert text: </b></div>
                {message}
                <br /> <br />
                <div>
                    {recipients ? <>
                        <div><b>Recipients:</b></div>
                        {recipients.map((r) => r.endpoint).join(", ")}
                    </> : <b>No recipients info</b>}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={onConfirm}
                >
                    {locale["ok"] || "Ok"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AlertInfoModal;
