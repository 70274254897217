import { LOADING_DOCUMENTS, SET_DOCUMENTS, SET_DOCUMENTS_ERROR } from "../actionTypes";

const initialState = {
  isLoading: false,
  documents: [],
  error: false,
};

const documents = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_DOCUMENTS:
      return { ...state, isLoading: action.payload };
    case SET_DOCUMENTS:
      return { ...state, documents: action.payload };
    case SET_DOCUMENTS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default documents;
