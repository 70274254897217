import React, { useMemo } from "react"
import { Link } from 'react-router-dom'

import { Location } from "history"
import { INavRoute } from "../RivataPage"
import { ISubscriptions } from "./index"
import { getCurrentEpoch } from "../../utils"


export interface IuseNavList {
    (
        isSuperAdmin: boolean, 
        navRoutes: Array<INavRoute>, 
        location: Location, 
        subscriptions: ISubscriptions
    ): any
}

export const useNavList: IuseNavList = (isSuperAdmin, navRoutes, location, subscriptions) => {
    return useMemo(() => {
        const path = location.pathname.split("/")
        return navRoutes.map((route, i) => {
            const key = route.name + i
            const curentNavPathPart = route.path ? route.path.split("/")[1] : ""
            
            if (route.navlabel) {
                return (
                    <li className="nav-small-cap" key={key}>
                        <i className={route.icon}></i>
                        <span className="hide-menu">{route.name}</span>
                    </li>
                )
            } else if (route.isCustomIcon && route.path) {
                return (
                    <li
                        key={key}
                        className={"sidebar-item " + (path.includes(curentNavPathPart) || (path[1] === "" && route.name === "Home") ? "active active-pro" : "")}
                    >
                        <Link
                            to={route.path}
                            className="sidebar-link"
                        >
                            <div className="nav-icon__wrapper">
                                <img src={route.src} alt="nav_icon" style={route.customStyles ? route.customStyles : { width: "18px" }} />
                            </div>
                            <span className="hide-menu">{route.name}</span>
                        </Link>
                    </li>
                )
            } 
    
            const currentEpoch = getCurrentEpoch()
            
            if (route.name === "TPMS Profiles" && !isSuperAdmin && (!subscriptions.TIRE_SENSOR || subscriptions.TIRE_SENSOR.valid_to < currentEpoch)) return null
            else if (route.name === "Calibrate Axle Load" && !isSuperAdmin && (!subscriptions.AXLE_LOAD || subscriptions.AXLE_LOAD.valid_to < currentEpoch)) return null
            
            return (
                <li
                    key={key}
                    className={"sidebar-item " + (path.includes(curentNavPathPart) ? "active active-pro" : "")}
                >
                    <Link
                        to={route.path || "/"}
                        className="sidebar-link"
                    >
                        <i className={route.icon} />
                        <span className="hide-menu">{route.name}</span>
                    </Link>
                </li>
            )
        })
    }, [isSuperAdmin, navRoutes, location.pathname, subscriptions])
}