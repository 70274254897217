import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  CustomInput,
  Col,
  Row,
  Button,
  Input,
} from "reactstrap";
import TimezonePicker from "../../components/TimezonePicker";
import LanguageDropdown from "../../components/LanguageDropdown";
import AlertConfirmationSection from "./AlertConfirmationSection";
import UnitsOfMeasureToggle from "../UnitsOfMeasureToggle";
import { NotificationThresholds } from "../../enums";
import "./style.scss";

const PreferencesForm = ({
  onSubmit,
  onTimezoneChange,
  onLanguageChange,
  onReceiveEmailChange,
  onReceiveTextChange,
  onThresholdChange,
  phone,
  email,
  timezone,
  language,
  receiveEmail,
  receiveText,
  threshold,
  isSaving,
  hasLanguage = false,
  locale,
  unitsOfMeasure,
  onChangeUnitsOfMeasure,
  showUnitsOfMeasureSelector,
  onPhoneChange,
  initialValues = {},
  setInitialValues,
  isUserProfile,
  nonCriticalAlertsTopicSubscriptionConfirmed,
  criticalAlertsTopicSubscriptionConfirmed,
}) => {
  const [changesWereMade, setChangesWereMade] = useState(false);

  const formValues = {
    phone,
    timezone,
    receiveEmail,
    receiveText,
    threshold,
    unitsOfMeasure,
  };

  const getSerializedValues = (values) => JSON.stringify({ ...values });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      !Object.values(formValues).some((v) => v === undefined) &&
      !Object.keys(initialValues).length
    ) {
      setInitialValues({ ...formValues });
    }

    if (Object.keys(initialValues).length) {
      if (
        getSerializedValues(initialValues) !==
        getSerializedValues({ ...formValues })
      ) {
        setChangesWereMade(true);
      } else {
        setChangesWereMade(false);
      }
    }
  });

  return (
    <Form className="w-100" onSubmit={onSubmit}>
      {isUserProfile && (
        <Row>
          <Col md="6">
            <FormGroup className="w-100">
              <Label>Email</Label>
              <Input
                disabled
                type="text"
                name="email"
                id="textemail"
                placeholder=""
                value={email || ""}
                onChange={() => {}}
              />
            </FormGroup>
            <FormGroup className="w-100">
              <Label>Phone</Label>
              <Input
                type="text"
                name="phone"
                id="textphone"
                placeholder="Enter Phone Here"
                value={phone || ""}
                onChange={(e) => onPhoneChange(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <h3 className="card-title">{locale.locale || "Locale"}</h3>
      <hr />
      <Row>
        <Col md="6">
          <FormGroup className="w-100">
            <Label>{locale.timezone || "Timezone"}</Label>
            <TimezonePicker
              onSetTimezone={onTimezoneChange}
              initialValue={timezone}
              locale={locale}
              id="user_profile"
            />
          </FormGroup>
          {hasLanguage && (
            <FormGroup className="w-100">
              <Label className="d-block">{locale.language || "Language"}</Label>
              <LanguageDropdown
                onSelect={onLanguageChange}
                selected={language}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      {showUnitsOfMeasureSelector ? (
        <>
          <h3>Units of Measure</h3>
          <hr />
          <Row className="mb-4">
            <Col md="6">
              <Label>Select Units of Measure:</Label>
              <UnitsOfMeasureToggle
                onChangeUnitsOfMeasure={onChangeUnitsOfMeasure}
                unitsOfMeasure={unitsOfMeasure}
              />
            </Col>
          </Row>
        </>
      ) : null}
      <h3 className="card-title">{locale.notifications || "Notifications"}</h3>
      <hr />
      <Row className="p-t-0">
          <Col md="12">
            <Label>
              {locale.receiveNotifications ||
                "Activate notifications for tire pressure warnings"}
            </Label>
            <FormGroup className="w-100">
              <CustomInput
                type="switch"
                id="emailSwitch"
                name="notificationSwitch"
                label={locale.email || "Email"}
                checked={receiveEmail}
                onChange={(e) => onReceiveEmailChange(e.target.checked)}
              />
              <CustomInput
                type="switch"
                id="textSwitch"
                name="notificationSwitch"
                label={locale.email || "Text"}
                checked={receiveText}
                onChange={(e) => onReceiveTextChange(e.target.checked)}
              />
            </FormGroup>
            {receiveText || receiveEmail ? (
              <>
                <Label>
                  {locale.notificationThresholds || "Notification Levels"}
                </Label>
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Label
                      check
                      className="d-inline-flex align-items-center justify-content-between radio__input"
                    >
                      <Input
                        type="radio"
                        name="threshold"
                        checked={
                          threshold ===
                          NotificationThresholds.INFORMATIONAL_AND_CRITICAL
                        }
                        style={{ marginTop: 0 }}
                        onChange={() =>
                          onThresholdChange(
                            NotificationThresholds.INFORMATIONAL_AND_CRITICAL
                          )
                        }
                      />
                      <span>{locale.INFORMATIONAL_AND_CRITICAL}</span>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label
                      check
                      className="d-inline-flex align-items-center justify-content-between radio__input"
                    >
                      <Input
                        type="radio"
                        name="threshold"
                        checked={
                          threshold === NotificationThresholds.CRITICAL_ONLY
                        }
                        style={{ marginTop: 0 }}
                        onChange={() =>
                          onThresholdChange(
                            NotificationThresholds.CRITICAL_ONLY
                          )
                        }
                      />
                      <span>{locale.CRITICAL_ONLY}</span>
                    </Label>
                  </FormGroup>
                </FormGroup>
                {receiveEmail &&
                  (nonCriticalAlertsTopicSubscriptionConfirmed !== null ||
                    criticalAlertsTopicSubscriptionConfirmed !== null) && (
                    <AlertConfirmationSection
                      threshold={threshold}
                      nonCriticalAlertsTopicSubscriptionConfirmed={
                        nonCriticalAlertsTopicSubscriptionConfirmed
                      }
                      criticalAlertsTopicSubscriptionConfirmed={
                        criticalAlertsTopicSubscriptionConfirmed
                      }
                    />
                  )}
              </>
            ) : null}
          </Col>
        </Row>
      {onSubmit && (
        <>
          <hr />
          <div className="form-actions">
            <Row>
              <Col md="6">
                <div className="d-flex align-items-center">
                  <Button
                    type="submit"
                    disabled={!changesWereMade || !timezone || isSaving}
                  >
                    {locale.submit || "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Form>
  );
};

export default PreferencesForm;
