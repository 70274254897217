import { SET_GEOLOCATION_TAGS_DETAILS, SET_GEOLOCATION_DETAILS, SET_IS_LOADING_DETAILS, SET_IS_LOADING_TAGS_DETAILS } from "../actionTypes";

const initialState = {
  details: {},
  tagsDetails: [],
  error: false,
  isLoadingDetails: false,
  isLoadingTagsDetails: false
};

const geolocationDetails = (state = initialState, action) => {
  switch (action.type) {
    case SET_GEOLOCATION_TAGS_DETAILS:
      return { ...state, tagsDetails: action.payload };
    case SET_GEOLOCATION_DETAILS:
      return { ...state, details: action.payload };
    case SET_IS_LOADING_DETAILS:
      return { ...state, isLoadingDetails: action.payload };
    case SET_IS_LOADING_TAGS_DETAILS:
      return { ...state, isLoadingTagsDetails: action.payload };
    default:
      return state;
  }
};

export default geolocationDetails;
