import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import SubNav from "../../components/SubNav";
import ReactPdfViewer from "./ReactPdfViewer";

const DocumentPage = ({ locale = {}, ...pageProps }) => {
  return (
    <>
      <SubNav title="Legal" breadcrumbs={[{ label: "Support", path: "/support" }, { label: pageProps.location.fileName }]} />
      {pageProps.location.url ? <ReactPdfViewer file={pageProps.location.url} /> : <Redirect to="/support" />}
    </>
  );
};

const mapStateToProps = (state) => ({
  locale: state.whitelabel.locale,
});

export default connect(mapStateToProps)(DocumentPage);
