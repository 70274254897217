import React from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import SubNav from "../../components/SubNav";
import RivataLoader from "../../components/RivataLoader"
import ModuleOrder from "../../modules/ModuleOrder";
import ColorPicker from "../../modules/ColorPicker";
import FileUploader from "../../modules/FileUploader";
import ResetWhitelabelStyles from "../../modules/ResetWhitelabelStyles";
import {
  fetchWhitelabel,
  postWhitelabel,
  setWhitelabel,
  setWhitelabelDirty,
  setSmarthubHealthSvg,
  setTpmsHealthSvg,
  setLinePressureHealthSvg,
  setAxleLoadHealthSvg,
  setLogoIconPng,
  setLogoFullPng,
  resetWhitelabelColorsToDefault,
} from "../../redux/whitelabelAdmin/action";
import { setSelectedCustomer } from '../../redux/common/action'
import { WhiteLabelFilenames } from "../../enums";
import CustomersDropdown from "../../components/CustomersDropdown";
import CustomerDefaults from "../../modules/CustomerDefaults";
import ErrorJumbo from "../../components/ErrorJumbo";

const parseWhitelabelAccountName = (name) => {
  const regex = /[^0-9a-zA-Z]+/g;
  name = name.trim().toLowerCase();
  name = name.replaceAll(regex, "_");
  if (name.startsWith("_")) {
    name = name.substring(1);
  }
  if (name.endsWith("_")) {
    name = name.substring(0, name.length - 1)
  }

  return name;
}

const WhiteLabel = ({
  fetchConfig,
  setConfig,
  postConfig,
  setDirty,
  setSmarthubHealthSvg,
  setTpmsHealthSvg,
  setLinePressureHealthSvg,
  setAxleLoadHealthSvg,
  setLogoIconPng,
  setLogoFullPng,
  setSelectedCustomer,
  resetWhitelabelColorsToDefault,
  whitelabel: {
    isLoading,
    isPosting,
    dirty,
    postedConfig,
    locale = {},
    config,
  },
  customers: {
    data,
    selectedCustomer,
    error
  }
}) => {
  const handleUpdate = (newItems) => {
    setConfig(newItems, true);
    setDirty(true);
  };

  const show = !isLoading && config;

  const whiteLabel = (
    (selectedCustomer?.key && parseWhitelabelAccountName(selectedCustomer.key)) ||
    ""
  ).toLowerCase();

  return (
    <>
      {error.statusCode >= 400 ? (
        <ErrorJumbo
          statusCode={error.statusCode >= 400 ? error.statusCode : null}
          statusText={error.message ? error.message : ""}
        />
      ) : (
        <>
          <SubNav
            title="Whitelabel"
            breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Whitelabel" }]}
          >
            {data.length && (
              <>
                <CustomersDropdown
                  locale={locale}
                  label={locale.Customer || "Customer"}
                  onChange={(customer) => {
                    setSelectedCustomer(customer);
                    fetchConfig(customer.id);
                  }}
                />
                <Button
                  variant="primary"
                  disabled={!dirty || isPosting || !selectedCustomer}
                  className="ml-3"
                  onClick={async () => {
                    postConfig(selectedCustomer.id);
                    setDirty(false);
                  }}
                >
                  {isPosting
                    ? locale.savingEllipsis || "Saving..."
                    : dirty
                    ? locale.Save || "Save"
                    : postedConfig
                    ? locale.Saved || "Saved"
                    : locale.Save || "Save"}
                </Button>
              </>
            )}
          </SubNav>
          {show ? (
            <>
              <CustomerDefaults 
                title={"Defaults Settings"}
                locale={locale}
                width={12}
                preferences={config.preferenceDefaults}
                onUpdate={(preferenceDefaults) => handleUpdate({ ...config, preferenceDefaults })}
              />
              <ModuleOrder
                initialItems={config.dash}
                title="Dashboard Page"
                width={4}
                locale={locale}
                onUpdate={(dash) => handleUpdate({ ...config, dash })}
              />
              <ModuleOrder
                initialItems={config.details}
                title="Details Page"
                width={4}
                locale={locale}
                onUpdate={(details) => handleUpdate({ ...config, details })}
              />
              <ModuleOrder
                initialItems={config.warnings}
                title="Warning Details Page"
                width={4}
                locale={locale}
                onUpdate={(warnings) => handleUpdate({ ...config, warnings })}
              />
              <FileUploader
                title="Health Icons"
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelFilenames.smarthubHealthSvg,
                    label: "SmartHub Health Symbol",
                    text: "SmartHubHealthSymbolText",
                    id: "SmartHubHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setSmarthubHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomer.id)
                  },
                  {
                    filename: WhiteLabelFilenames.tpmsHealthSvg,
                    label: "TPMS Health Symbol",
                    text: "TpmsHealthSymbolText",
                    id: "TpmsHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setTpmsHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomer.id)
                  },
                  {
                    filename: WhiteLabelFilenames.linePressureHealthSvg,
                    label: "Line Pressure Health Symbol",
                    text: "LinePressureHealthSymbolText",
                    id: "LinePressureHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setLinePressureHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomer.id)
                  },
                  {
                    filename: WhiteLabelFilenames.axleLoadHealthSvg,
                    label: "Axle Load Health Symbol",
                    text: "AxleLoadHealthSymbolText",
                    id: "AxleLoadHealthSymbol",
                    accept: "image/svg+xml",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setAxleLoadHealthSvg(file);
                    },
                    uploadImage: () => postConfig(selectedCustomer.id)
                  }
                ]}
              />
              <FileUploader
                title="Brand Logos"
                locale={locale}
                whiteLabel={whiteLabel}
                items={[
                  {
                    filename: WhiteLabelFilenames.logoFullPng,
                    text: "LogoFullText",
                    id: "LogoFull",
                    accept: "image/png",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setLogoFullPng(file);
                    },
                    uploadImage: () => postConfig(selectedCustomer.id)
                  },
                  {
                    filename: WhiteLabelFilenames.logoIconPng,
                    label: "Logo Icon",
                    text: "LogoIconText",
                    id: "LogoIcon",
                    accept: "image/png",
                    onUpdate: (file, _filename) => {
                      setDirty(true);
                      setLogoIconPng(file);
                    },
                    uploadImage: () => postConfig(selectedCustomer.id)
                  },
                ]}
              />
              <ResetWhitelabelStyles
                resetStyles={resetWhitelabelColorsToDefault}
              />
              <ColorPicker
                title="Theme Colors"
                locale={locale}
                colorsData={config.themeColors}
                onUpdate={(themeColors) => handleUpdate({ ...config, themeColors })}
              />
              <ColorPicker
                title="Health Colors"
                locale={locale}
                colorsData={config.healthColors}
                onUpdate={(healthColors) =>
                  handleUpdate({ ...config, healthColors })
                }
              />
              <ColorPicker
                title="Graph Palette"
                locale={locale}
                colorsData={config.wheelColors}
                onUpdate={(wheelColors) => handleUpdate({ ...config, wheelColors })}
                showControlButtons={true}
                onAddAxles={(wheelColors) => handleUpdate({ ...config, wheelColors })}
                onRemoveAxles={(wheelColors) => handleUpdate({ ...config, wheelColors })}
              />
              <ColorPicker
                title="Gps Palette"
                locale={locale}
                colorsData={config.gpsColors}
                onUpdate={(gpsColors) => handleUpdate({ ...config, gpsColors })}
              />
            </>
          ) : isLoading ? (
            <RivataLoader/>
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  whitelabel: state.whitelabelAdmin,
  customers: state.common.customers
});

const mapDispatchToProps = (dispatch) => ({
  fetchConfig: (customerId) => dispatch(fetchWhitelabel(customerId)),
  postConfig: (customerId) => dispatch(postWhitelabel(customerId)),
  setConfig: (config, isUpdate) => dispatch(setWhitelabel(config, isUpdate)),
  setDirty: (dirty) => dispatch(setWhitelabelDirty(dirty)),
  setSmarthubHealthSvg: (file) => dispatch(setSmarthubHealthSvg(file)),
  setTpmsHealthSvg: (file) => dispatch(setTpmsHealthSvg(file)),
  setLinePressureHealthSvg: (file) => dispatch(setLinePressureHealthSvg(file)),
  setAxleLoadHealthSvg: (file) => dispatch(setAxleLoadHealthSvg(file)),
  setLogoIconPng: (file) => dispatch(setLogoIconPng(file)),
  setLogoFullPng: (file) => dispatch(setLogoFullPng(file)),
  setSelectedCustomer: (customer) => dispatch(setSelectedCustomer(customer)),
  resetWhitelabelColorsToDefault: () => dispatch(resetWhitelabelColorsToDefault())
});

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabel);
