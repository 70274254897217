import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Collapse, Modal, ModalBody } from "reactstrap";
import ErrorBoundry from "../../components/ErrorBoundry";
import "./styles.scss";

const RivataModule = ({
  title,
  width = 12,
  children,
  filters,
  locale,
  error = {
    statusCode: 0,
    message: "",
  },
  paddingMobile = true,
  bg = "light",
  text = "dark",
  marginTop = -1,
  customClass = "",
  collapsible = false,
  fullScreenModalModeEnabled = false,
  customFullScreenAction = () => {}
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const toggleFullScreenMode = () => {
    customFullScreenAction();
    setFullScreenMode(!fullScreenMode);
  }
  const onHeaderClick = () => {
    if (collapsible) {
      setCollapsed(!collapsed);
    }
  };
  
  const renderModule = () => (
    <Col
    id="rivata-module-component"
    lg={width}
    data-component="fleet-map"
    className={`mt-${marginTop > -1 ? marginTop : 4} ${customClass}`}
  >
    <Card>
      <CardHeader
        className={`border-bottom p-3 mb-0 d-inline-flex d-flex flex-column flex-md-row flex-wrap`}
        style={{ background: "#dcddde" }}
      >
        <div
          onClick={onHeaderClick}
          className={`collapsed-header-style ${collapsible && "collapsed-header-cursor"
            }`}
        >
          {collapsible && (
            <i
              className={`fa fa-arrow-down collapse-arrow-style ${collapsed && "collapse-arrow-expanded"
                }`}
            ></i>
          )}
          <h4
            className={`d-block mb-0 mr-2`}
            style={{ fontWeight: 400, color: "#414042" }}
          >
            {locale[title] || title}
          </h4>
        </div>

        <div className="d-inline-flex mt-2 ml-md-2 justify-content-end flex-grow-1 flex-wrap">
          {filters}      
        </div>
        {fullScreenModalModeEnabled && <img onClick={toggleFullScreenMode} className="expand-icon" src={require('../../assets/images/expand.png')} alt=""/>}
      </CardHeader>
      <Collapse isOpen={!collapsed}>
        <CardBody
          className={`position-relative${!paddingMobile ? " no-pad-mobile" : ""
            }`}
        >
          <ErrorBoundry error={error}>{children}</ErrorBoundry>
        </CardBody>
      </Collapse>
    </Card>
  </Col>
  )


  return (<>
    {!fullScreenMode && renderModule()}
    {(fullScreenModalModeEnabled && fullScreenMode) && <Modal modalTransition={{timeout: 0}} isOpen={fullScreenMode} className="rivata-module-fullscreen-modal">
      <ModalBody>
        {renderModule()}
      </ModalBody>
    </Modal>}
  </>
  );
};

export default RivataModule;
