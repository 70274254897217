import {
    convertDataEpochToDate,  
    kgToLbs,
    kpaToLbs,
    kpaToKg
} from '../../utils';
import { getDateRange } from '../../utils/chartUtils'
import { UnitsOfMeasurement } from "../../enums"

export const composeChartData = (data, unitsOfMeasurement, axlesCount) => {
    const parsedData = {
        datasets: [
            { label: "Axle Load", data: [], fill: false, backgroundColor: "green", borderColor: "green", borderWidth: 2 }
        ]
    }

    if (axlesCount <= 0) axlesCount = 1

    data.map((item) => {
        const value = item.avg_value 
        const weightForOneAxle = item.vehicle_weight ? item.vehicle_weight / axlesCount : -1

        const weight = weightForOneAxle > -1 ? unitsOfMeasurement === UnitsOfMeasurement.imperial ? 
            kgToLbs(weightForOneAxle).toFixed(2) + " Lbs" : 
            weightForOneAxle.toFixed(2) + " Kg" : "-"
        
        const pressure = parseFloat(unitsOfMeasurement === UnitsOfMeasurement.imperial ? kpaToLbs(value).toFixed(2)  : kpaToKg(value).toFixed(2))
        const pressureUnits = unitsOfMeasurement === UnitsOfMeasurement.imperial ? " PSI"  : " Bar"

        const obj = {
            x: convertDataEpochToDate(item.bucket_epoch, null, null, false),
            y: pressure,
            displayBeforeLabel:  `Weight: ${weight}`,
            displayValue: `Axle Load: ${pressure > -1 ? pressure + pressureUnits : "-"}`, 
            displayAfterLabel: `Date: ${convertDataEpochToDate(item.bucket_epoch, null, null, true)}`
        }
        return parsedData.datasets[0].data.push(obj)
    })

    parsedData.datasets[0].data.sort((d1, d2) => +new Date(d1.displayDate) - +new Date(d2.displayDate))
    const range = getDateRange(parsedData.datasets)
    parsedData.xMin = range.min
    parsedData.xMax = range.max

    return parsedData
}

export const composeAnnotations = (id) => {
    const annotations = {}

    annotations.bg = {
        type: "box",
        id: `box-plot-top-${id}`,
        xScaleID: "x",
        yScaleID: "y",
        yMax: Infinity,
        yMin: -Infinity,
        xMin: -Infinity,
        xMax: Infinity,
        backgroundColor: '#fff',
        drawTime: "beforeDatasetsDraw",
    }
    return annotations
}
