import { IColumn, IRow } from "../../../components/RivataGrid"
import { convertDataEpochToDate, psiToBar, getClearLabel } from '../../../utils'
import { UnitsOfMeasurement } from '../../../enums'

interface IcomposeTpmsReportColumns {
    (
        columns: Array<string>
    ): Array<IColumn>
}

export const tpmsReportColumns: IcomposeTpmsReportColumns = (columns) => {
    const parsedColumns: Array<IColumn> = []
    columns.forEach(key => {
        const name = getClearLabel(key) 
        if (key === "asset_name") {
            parsedColumns.push({ key, name, minWidth: 100, width: 250, frozen: true })
            return
        } else if (key === "timezone") { // put units after timezone
            parsedColumns.push({ key, name: getClearLabel(key), minWidth: 100, width: 200 })
            parsedColumns.push({ key: "units", name: "Units", minWidth: 100, width: 200 })
            return
        } else if (key === "vin") {
            parsedColumns.push({ key, name: key.toUpperCase(), minWidth: 100, width: 200 })
            return
        }
        parsedColumns.push({ key, name: getClearLabel(key), minWidth: 100, width: 200 })
    })

    return parsedColumns
}

interface IcomposeTpmsReportData {
    (
        columns: Array<string>,
        rows: Array<IRow>,
        timezone: string,
        unitsOfMeasurement: string
    ): Array<IRow>
}

export const tpmsReportRows: IcomposeTpmsReportData = (columns, rows, timezone, unitsOfMeasurement) => {
    const parsedRows = rows.map(item => {
        const lastComm = item.last_gateway_communication
        const row: IRow = { 
            ...item, 
            timezone,
            units: unitsOfMeasurement === UnitsOfMeasurement.imperial ? "PSI" : "BAR",
            asset_type: item.asset_type ? item.asset_type: "---",
            report_date_time: convertDataEpochToDate(item.report_date_time, null, null, true),
            last_gateway_communication: lastComm ? convertDataEpochToDate(lastComm, null, null, true) : "---"
        }

        for (let i = 7; i < columns.length; i++) {
            const key = columns[i]
            const value = row[key]

            if (i % 2 === 0) {
                if (!value) {
                    row[key] = "---"
                } else {
                    row[key] = convertDataEpochToDate(+value, null, null, true)
                }
            } else {
                row[key] = value && !["-", ""].includes(value) ? 
                    unitsOfMeasurement === UnitsOfMeasurement.imperial 
                        ? value
                        : parseFloat(psiToBar(+value)).toFixed(2)
                    : "---"
            }
        }

        return row
    })

    return parsedRows
}