import React from "react";
import ChartLegend from "../../components/ChartLegend";
import pinOk from "../../assets/images/icons/svg/pin_ok.svg";
import pinWarn from "../../assets/images/icons/svg/pin_warn.svg";
import pinCritWarn from "../../assets/images/icons/svg/pin_crit_warn.svg";

const items = [
  {
    icon: pinCritWarn,
    label: "Critical Warnings",
  },
  {
    icon: pinWarn,
    label: "Warnings",
  },
  {
    icon: pinOk,
    label: "Normal",
  },
];

const MapLegend = ({ locale }) => {
  return (
    <ChartLegend
      items={items}
      zIndex={9}
      title="Legend"
      locale={locale}
    />
  );
};

export default MapLegend;
