import React from 'react'
import DatesDropdown from '../../components/DatesDropdown'
import './style.scss'

const ChartsControl = ({ onSelectDate }) => {
    return (
        <div data-component="fleet-map" className="charts-control mt-4" >
            <div>
                <h4 className="charts-control__title" >Charts Control</h4>
            </div>
            <div className="mr-2">
                <DatesDropdown
                    onSelect={(window) => onSelectDate(window)}
                />
            </div>
        </div>
    )
}

export default ChartsControl