import React, { useState, useEffect } from 'react'
import { TabContent, TabPane, Row } from "reactstrap"

import ProvisionSubNav from './components/ProvisionSubNav'
import AssetsTable from '../../modules/AssetsProvisionTable'
import AssetTpmsTable from '../../modules/AssetTpmsTable'
import Users from '../../modules/Users'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import { useProvisionTabs } from './hooks'

import './style.scss'

const Provision = () => {
    // redux hooks
    const {
        auth: { isSuperAdmin, isAdmin }, 
        customers: { error, selectedCustomer }, 
        locale, 
        customerDefaults
    } = useTypedSelector(state => ({
        auth: state.auth,
        customers: state.common.customers,
        locale: state.whitelabelAdmin.locale,
        customerDefaults: state.common.customerDefaults
    }))

    const { resetProvisionPageReducer } = useActions()

    // state
    const [activeTab, setActiveTab] = useState('assets')

    useEffect(() => {
        return () => {
            // reset data from store when user leave this page
            resetProvisionPageReducer()
        }
    }, [resetProvisionPageReducer])

    const toggleTab = tab => {
        if(activeTab !== tab) setActiveTab(tab)
    }

    // custom hooks
    const tabs = useProvisionTabs(isSuperAdmin, activeTab, customerDefaults, toggleTab)

    return (
        <>
            <ProvisionSubNav
                isSuperAdmin={isSuperAdmin}
                isAdmin={isAdmin}
                locale={locale}
                selectedCustomer={selectedCustomer}
                error={error}
            />
            <div className="tabs-wrapper provision">
                {tabs}
                <TabContent activeTab={activeTab} className="ml-3 mr-3">
                    <TabPane tabId="assets">
                        <Row>
                            {activeTab === "assets" && (
                                <AssetsTable
                                    width={12}
                                    locale={locale}
                                    selectedCustomer={selectedCustomer}
                                />
                            )}
                        </Row>
                    </TabPane>
                    <TabPane tabId="tpmsAssets">
                        <Row>
                            {activeTab === "tpmsAssets" && (
                                <AssetTpmsTable
                                    locale={locale}
                                    width={12}
                                    selectedCustomer={selectedCustomer}
                                />
                            )}
                        </Row>
                    </TabPane>
                    <TabPane tabId="users">
                        <Row>
                            {activeTab === "users" && (
                                <Users
                                    locale={locale}
                                    width={12}
                                />
                            )}
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        </>
    )
}

export default Provision