import React, { useState, useEffect } from 'react';
import { Field, Formik, Form, useField } from 'formik';
import { Col, Label, FormGroup, Input, FormFeedback, Row, Button } from 'reactstrap';
import * as Yup from "yup";

import './styles.scss';
import { Prompt } from 'react-router-dom';
import { WARNING_TYPE } from '../../constants';
import { UnitsOfMeasurement } from '../../enums'
import { fahrToCelsius } from '../../utils'

const schema = Yup.object().shape({
    vibration_treshold: Yup.number().required("Required").positive("Vibration threshold can't be under 0"),
    temperature_treshold: Yup.number().required("Required").positive("Temperature threshold can't be under 0"),
});

export const FormField = ({
    className,
    md = "6",
    type = "text",
    children,
    label,
    units,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <Col md={md} className={className}>
            <Label>{label}</Label>
            <FormGroup className="w-100">
                <div className="d-flex align-items-center">
                    <Input
                        type={type}
                        {...field}
                        {...props}
                        tag={Field}
                        invalid={!!(meta.touched && meta.error)}
                    >
                        {children}
                    </Input>
                    <Label className="mb-0 ml-2" >{units}</Label>
                </div>
                <FormFeedback>{meta.error}</FormFeedback>
            </FormGroup>
        </Col>
    );
};

const format = (formDetails, customerId) => {
    const { vibration_treshold, temperature_treshold } = formDetails;
    return {
        customer_id: customerId,
        name: "",
        settings: [
            {
                warning_type_id: WARNING_TYPE.HUB_TEMPERATURE_SENSOR,
                profile: {
                    temperature_treshold
                }
            },
            {
                warning_type_id: WARNING_TYPE.HUB_VIBRATION_SENSOR,
                profile: {
                    vibration_treshold
                }
            },
        ]
    }
}

const TresholdsForm = ({
    initialValues,
    disabled,
    onSave,
    unitsOfMeasurement,
    customerId,
}) => {
    const [formChanged, setFormChanged] = useState(false)

    useEffect(() => {
        if (formChanged) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }, [formChanged]);

    return (
        <>
            <Prompt when={formChanged} message={"You have unsaved changes. Do you want to leave page?"} />
            <div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        const thresholds = values
                        thresholds.temperature_treshold = unitsOfMeasurement === UnitsOfMeasurement.metric 
                        ? parseFloat(values.temperature_treshold) 
                        : fahrToCelsius(values.temperature_treshold)
                        onSave(format(thresholds, customerId));
                    }}
                >
                    {(props) => {
                        const { submitForm, handleReset } = props;
                        return <Form className="tresholds-form" onChange={() => { if (!formChanged) setFormChanged(true) }}>
                            <Row className="col-wrapper" md={12}>
                                <Col md={4}>
                                    <h6 className="ml-3 mb-2">Set Thresholds</h6>
                                    <FormField 
                                        md={6} 
                                        disabled={disabled} 
                                        name="vibration_treshold" 
                                        label="Vibration threshold" 
                                        type="number" 
                                        step="1" 
                                    />
                                    <FormField 
                                        md={6} 
                                        disabled={disabled}
                                        name="temperature_treshold" 
                                        label="Temperature threshold" 
                                        type="number" 
                                        step="1" 
                                        units={unitsOfMeasurement === UnitsOfMeasurement.metric ? "°C" : "°F"} 
                                    />
                                </Col>
                            </Row>
                            {formChanged && <Button
                                className="btn"
                                onClick={() => {
                                    handleReset();
                                    setFormChanged(false);
                                }}>
                                Reset
                            </Button>}
                            <Button
                                disabled={disabled}
                                className="btn"
                                color="success"
                                onClick={() => {
                                    submitForm();
                                    setFormChanged(false);
                                }}>
                                Save
                            </Button>
                        </Form>
                    }}
                </Formik>
            </div>
        </>
    )
}

export default TresholdsForm;