import React, { useMemo, useEffect } from "react";
import RecentWarnings from "../../modules/RecentWarnings";
import TemperatureChart from "../../modules/TemperatureChart";
import LocationChart from "../../modules/LocationChart";
import DetailsMap from "../../modules/DetailsMap";
import LinePressureChart from "../../modules/LinePressureChart";
import TirePressureChart from "../../modules/TirePressureChart";
import HubVibration from "../../modules/HubVibration";

import { composeLocationFilterOptions } from "../../utils";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useChartsCommonRange, useChartComponentsData } from '../../hooks/useChartHooks'
import { DetailsComponents } from '../../enums'

const WarningsBody = ({
	components,
	graphColors,
	locale,
	timestamp,
	days,
	vin,
  warningKey
}) => {
	const {
		postAcknowledgements,
		fetchRecentWarnings,
		setRecentWarningsLimit,
		setRecentWarningsOffset,
		setRecentWarningsShowAll,
		fetchWarningsGps,
		setRecentWarningsFilter,
		resetWarningDetailsReducer
	} = useActions()

	const {
		warnings: { assetInfo, recentWarnings, acknowledgementWarningCount, vibrations, tirePressure },
		warnings: { temperatures, locations, assignedTpmsProfile },
		preferences,
		healthColors,
		gpsColors,
		auth: { isSuperAdmin, isAdmin },
		systemDefaults,
		customerDefaults,
		speedData,
		linePressure
	} = useTypedSelector((state) => ({
		warnings: state.warnings,
		preferences: state.auth.preferences,
		healthColors: state.whitelabel.healthColors,
		gpsColors: state.whitelabel.gpsColors,
		auth: state.auth,
		systemDefaults: state.common.systemDefaults,
		customerDefaults: state.common.customerDefaults,
		speedData: state.details.speedData,
		linePressure: state.details.linePressure
	}))
	const chartsData = useChartComponentsData(
		graphColors,
		locale,
		preferences,
		speedData
		)
	const chartsCommonRange = useChartsCommonRange(chartsData)

  const selectedWarning = useMemo(() => {
    return recentWarnings.data.find(el => el.epoch === timestamp / 1000) || 
    { epoch: timestamp / 1000, key: warningKey }
  }, [recentWarnings.data, timestamp, warningKey])

	useEffect(() => {
		return () => {
			// reset warning details reducer data on unmount (issue with common x range, tpms profile)
			resetWarningDetailsReducer()
		}
	}, [resetWarningDetailsReducer])

	if (assetInfo.error.statusCode >= 400) {
		return (
			<div data-component="fleet-map" className="charts-control mt-3 p-2">
				<div className="alert-danger w-100 p-3">
				<h4 className="charts-control__title">{`Asset with VIN ${vin} was not found`}</h4>
				</div>
			</div>
		)
	}

	return components.map(({ component, width }) => {
		switch (component) {
      case DetailsComponents.HubVibration:
        if (!assetInfo.data.subscription?.HUB_SENSOR && !isSuperAdmin)
          return null;
        const vibChart = chartsData.find((el) => el.key === component);

        return (
          <HubVibration
            {...vibChart}
            {...vibrations}
            {...chartsCommonRange}
            width={width}
            vibrationThreshold={customerDefaults?.smarthub?.vibration_treshold}
            timestamp={+timestamp}
            isCustomLabelFormat={true}
            healthColors={healthColors}
          />
        );
      case DetailsComponents.TirePressure:
        if (!assetInfo.data.subscription?.TIRE_SENSOR && !isSuperAdmin)
          return null;
        const pressureChart = chartsData.find((el) => el.key === component);
        return (
          <TirePressureChart
            {...pressureChart}
            {...tirePressure}
            {...chartsCommonRange}
            width={width}
            assetType={assetInfo.data.asset_type}
            assignedTpmsProfile={assignedTpmsProfile}
            timestamp={+timestamp}
            thresholds={customerDefaults?.tpms}
            healthColors={healthColors}
          />
        );
      case DetailsComponents.TemperatureChart:
        if (!assetInfo.data.subscription?.HUB_SENSOR && !isSuperAdmin)
          return null;
        const temperatureChart = chartsData.find((el) => el.key === component);

        return (
          <TemperatureChart
            {...temperatureChart}
            {...temperatures}
            {...chartsCommonRange}
            width={width}
            timestamp={+timestamp}
            temperatureTreshold={
              customerDefaults?.smarthub?.temperature_treshold ||
              systemDefaults?.smarthub?.temperature_treshold
            }
            healthColors={healthColors}
            assetType={assetInfo.data.asset_type}
          />
        );
	 case DetailsComponents.LinePressure:
		
        if (
          (!assetInfo.data.subscription?.LINE_PRESSURE && !isSuperAdmin) ||
          assetInfo.data.asset_type !== "trailer"
        )
          return null;
        const linePChart = chartsData.find((el) => el.key === component);
        return (
          <LinePressureChart
            {...linePChart}
            {...linePressure}
            {...chartsCommonRange}
            width={width}
			timestamp={+timestamp}
			healthColors={healthColors}
          />
        );

      case DetailsComponents.LocationChart:
        const elevationSpeedCharts = chartsData.find(
          (el) => el.key === component
        );

        return (
          <LocationChart
            {...elevationSpeedCharts}
            {...locations}
            {...chartsCommonRange}
            width={width}
            gpsColors={gpsColors}
            timestamp={+timestamp}
            healthColors={healthColors}
          />
        );
      case DetailsComponents.DetailsMap:
        return (
          <DetailsMap
            key={component}
            selectedWarning={selectedWarning}
            {...locations}
            width={width}
            locale={locale}
            hasFilters={false}
            preferences={preferences}
            vehicleInfo={assetInfo.data}
            onSelectDateRange={fetchWarningsGps}
          />
        );
      case DetailsComponents.RecentWarnings:
        let sensorInfo = assetInfo.data?.sensor_info
          ? composeLocationFilterOptions(assetInfo.data?.sensor_info)
          : [];

        return (
          <RecentWarnings
            key={component}
            {...recentWarnings}
            isAdmin={isAdmin}
            width={width}
            locale={locale}
            vin={assetInfo.data && assetInfo.data.vin}
            assetId={assetInfo.data && assetInfo.data.id}
            assetHeaderTitle={
              assetInfo.data.name ? assetInfo.data.name : assetInfo.data.vin
            }
            preferences={preferences}
            setRecentWarningsLimit={setRecentWarningsLimit}
            setRecentWarningsOffset={setRecentWarningsOffset}
            setRecentWarningsShowAll={setRecentWarningsShowAll}
            fetchRecentWarnings={fetchRecentWarnings}
            warningsCount={recentWarnings.totalCount}
            warningsLimit={recentWarnings.limit}
            warningsOffset={recentWarnings.offset}
            warningsShowAll={recentWarnings.showAllWarnings}
            postAcknowledgements={postAcknowledgements}
            days={days}
            customPageLimit={10}
            healthColors={healthColors}
            customerDefaults={customerDefaults}
            sensorInfo={sensorInfo}
            setRecentWarningsFilter={setRecentWarningsFilter}
            acknowledgementWarningCount={acknowledgementWarningCount}
          />
        );
      default:
        return null;
    }
	});
};

export default WarningsBody;
