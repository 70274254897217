import React, { useMemo, useState, useEffect } from "react";
import RivataModule from '../../components/RivataModule'
import ReportsFormSwitch from '../ReportsFormSwitch'
import RivataDropdown from '../../components/RivataDropdown'
import { ReportTypes } from '../../enums'
import { Button, CustomInput } from "reactstrap";
import { getClearLabel, getCurrentEpoch } from "../../utils";

const ReportControls = ({
    isSuperAdmin = false,
    locale = {},
    fetchReport,
    selectedReport,
    setSelectedReport,
    isLoading,
    subscriptions,
    cancelReportAction,
    hideAutogeneratedAssets,
    setHideAutogeneratedAssets
}) => {
    const [reportLink, setReportLink] = useState(null)

    useEffect(()=>{
        return(()=> cancelReportAction())
    },[cancelReportAction])

    const reportsDropdownItems = useMemo(() => {
        return Object.keys(ReportTypes).reduce((prev, current) => {
            if (
                (current === ReportTypes.HARDWARE_COMMANDS_HISTORY ||
                        current === ReportTypes.DAILY_MILEAGE_REPORT ||
                            (current === ReportTypes.TPMS_REPORT && (!subscriptions.TIRE_SENSOR || subscriptions.TIRE_SENSOR.valid_to < getCurrentEpoch()))) &&
                !isSuperAdmin
            ) {
                return prev;
            }

            return [ ...prev, { id: current, label: getClearLabel(ReportTypes[current]) } ]
        }, []).sort((a,b)=> a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
    }, [isSuperAdmin, subscriptions])

    const handleView = () => {
        if (reportLink) {
            fetchReport(reportLink)
        }
    }

    const cancelReport = ()=>{
        cancelReportAction()
    }
    return (
        <RivataModule
            title="Report Controls"
            locale={locale}
            filters={
                <RivataDropdown
                    selected={selectedReport}
                    items={reportsDropdownItems}
                    onSelect={(id) => setSelectedReport(id)}
                />
            }
        >
            <div className="report">
                <fieldset>
                    {isSuperAdmin && selectedReport !== ReportTypes.USER_LOGINS ?  (
                        <CustomInput
                            id="hideAutogenerated"
                            className="d-flex align-items-center mb-3"
                            type="checkbox"
                            label="Hide Autogenerated Assets"
                            checked={hideAutogeneratedAssets}
                            onChange={() => setHideAutogeneratedAssets(!hideAutogeneratedAssets)}
                        />
                    ) : null}

                    <ReportsFormSwitch
                        selectedReport={selectedReport}
                        setReportLink={(link) => setReportLink(link)}
                        disabled={isLoading}
                    />
                    
                    <div className="d-flex justify-content-end">
                        <Button
                            className="border-0" 
                            color="primary"
                            onClick={handleView}
                            disabled={isLoading}
                        >
                            View
                        </Button>
                        <Button
                            className="border-0 ml-4 mr-2" 
                            color="danger"
                            onClick={cancelReport}
                            disabled={!isLoading}
                        >
                            Cancel
                        </Button>
                    </div>
                </fieldset>
            </div>
        </RivataModule>
    )
}

export default ReportControls