import { useMemo } from 'react';
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as assetAdminInfoActions from '../redux/assetAdminInfo/action'
import * as authActions from '../redux/auth/action'
import * as axleLoadCalibrationActions from '../redux/axleLoadCalibration/action'
import * as commonActions from '../redux/common/action'
import * as dashboardActions from '../redux/dashboard/action'
import * as detailsActions from '../redux/details/action'
import * as documentsActions from '../redux/documents/action'
import * as geofencesActions from '../redux/geofences/action'
import * as geolocationDetailsActions from '../redux/geolocationDetails/action'
import * as geolocationsActions from '../redux/geolocations/action'
import * as provisionActions from '../redux/provision/action'
import * as reportsActions from '../redux/reports/action'
import * as settingsActions from '../redux/settings/action'
import * as warningsActions from '../redux/warnings/action'
import * as adminLogsActions from '../redux/adminLogs/actions'
import * as tpmsPageActions from '../redux/tpmsProfiles/action'
import * as tagDetailsActions from '../redux/tagDetails/action'


const actions = {
    ...assetAdminInfoActions,
    ...authActions,
    ...axleLoadCalibrationActions,
    ...commonActions,
    ...dashboardActions,
    ...detailsActions,
    ...documentsActions,
    ...geofencesActions,
    ...geolocationDetailsActions,
    ...geolocationsActions,
    ...provisionActions,
    ...reportsActions,
    ...settingsActions,
    ...warningsActions,
    ...adminLogsActions,
    ...tpmsPageActions,
    ...tagDetailsActions
}

export const useActions = () => {
    const dispatch = useDispatch()
    
    return useMemo(() => {
        return bindActionCreators(actions, dispatch)
    }, [dispatch])
}