import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import brandBackground from "../../assets/images/riv_brand/image--013.png";
import RequestAccessForm from '../../modules/RequestAccessForm'
import InfoModal from '../../components/InfoModal'
import RivataLoader from '../../components/RivataLoader'
import api from '../../services/api'
import './style.scss'

const RequestAccess = () => {
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const sidebarBackground = {
        backgroundImage: "url(" + brandBackground + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    }

    const submitDataHandler = async (data, resetForm) => {
        try {
            setLoading(true)
            const res = await api.postRequestAccessData(data)
            
            if (res.status === "ok") {
                setMessage("Success")
                resetForm()
            } else {
                setMessage("Something went wrong, please try again later")
            }
        } catch (err) {
            console.log(err)
            setMessage("Something went wrong, please try again later")
        } finally {
            setLoading(false)
        }
    }
    
    return (
        <div
            className="request-access"
            style={sidebarBackground}
        >
            <div className="w-100 d-flex justify-content-end">
                <Link className="login_link" to="/authentication/login" >LOGIN PAGE</Link>
            </div>
            { loading && <RivataLoader/> }
            <div className="container pt-5">
                <RequestAccessForm
                    onSubmit={submitDataHandler}
                />
            </div>
            <div className="d-flex w-100 justify-content-end align-items-end request-access-footer">
                <a href="https://conmet.com/innovation/digital/" target="_blank" rel="noreferrer noopener">Learn More About Our Products</a>
            </div>
            <InfoModal open={!!message} onConfirm={() => setMessage("")} header={"Request Access"} message={message} />
        </div>
    )
}

export default RequestAccess