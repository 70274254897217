import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import TableRow, { tableRowType } from "./TableRow";
import Th from "./Th";
import RivatePagination from '../RivatePagination';
import LimitDropdown from '../LimitDropdown';
import RivataLoader from '../RivataLoader'
import StatusAlert from '../StatusAlert'
import "./styles.scss";

const RivataTable = ({
  columns,
  rows,
  onSelectedRow = undefined,
  onCheckRowItem = undefined,
  onCheckRowColumn = undefined,
  onEdit,
  onDelete,
  editDisabled,
  deleteDisabled,
  assetsCount = 0,
  pageLimit = 0,
  daysCount = 0,
  onSelectLimit = undefined,
  isLoading,
  onPageChange = undefined,
  isShowingLimit = false,
  limitDropdownItems = undefined,
  tpmsProfiles = undefined,
  showPagination = true,
  setSortOptions,
  onCustomAction = undefined,
  customDeleteStyle = {},
  customEditStyle = {},
  filter = "",
  onRowClick = undefined,
  initialPage = 0,
  customActionIcon = "fa fa-plus-square font-16",
  customActionTooltipText = "",
  editTooltipText= "",
  deleteTooltipText = ""
}) => {
  const [sortColumn, setSortColumn] = useState(null)

  const sortHandler = useCallback((columnId, direction) => {
    setSortOptions({ column: columnId, direction })
  }, [setSortOptions])

  return (
    <>
      {isLoading && <RivataLoader/>}
      {showPagination && rows.length ? (
        <div className="d-flex">
          <RivatePagination 
            assetsCount={assetsCount} 
            onPageChange={onPageChange} 
            pageLimit={pageLimit} 
            days={daysCount}
            filter={filter}
            initialPage={initialPage}
          />
          {isShowingLimit ?
            <LimitDropdown
              setPageLimit={onSelectLimit}
              pageLimit={pageLimit}
              limitDropdownItems={limitDropdownItems}
            />
            : null}
        </div>
      ) : null}
      {rows.length ? (
        <Table hover size="sm" responsive>
          <thead>
            <tr>
              {onDelete && <Th customInlineStyle={customDeleteStyle} key={`table_header_delete`} label="" />}
              {onEdit && <Th customInlineStyle={customEditStyle} key={`table_header_edit`} label="" />}
              {onCustomAction && <Th key={`table_header_custom_action`} label=""/>}
              {columns.map((column) => (
                <Th
                  key={`table_header_${column.id}`}
                  onCheck={onCheckRowColumn}
                  sortColumn={sortColumn}
                  setSortColumn={(id) => setSortColumn(id)}
                  sortHandler={sortHandler}
                  customStyle={column.customStyle}
                  {...column}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <TableRow
                key={`table_rows_${i}`}
                id={row.id}
                selectedRow={row.selectedRow}
                columns={row.columns}
                data={row.data}
                onEdit={onEdit}
                onDelete={onDelete}
                onSelectedRow={onSelectedRow}
                onCheckRowItem={onCheckRowItem}
                editDisabled={editDisabled}
                deleteDisabled={deleteDisabled}
                tpmsProfiles={tpmsProfiles}
                daysCount={daysCount}
                onCustomAction={onCustomAction}
                onRowClick={onRowClick}
                customActionIcon={customActionIcon}
                customActionTooltipText={customActionTooltipText}
                editTooltipText={editTooltipText}
                deleteTooltipText={deleteTooltipText}
              />
            ))}
          </tbody>
        </Table>
      ) : !rows.length && !isLoading ? (
        <StatusAlert customText="No Data" color="success"/>
      ) : null}
    </>
  );
};

export const tableType = {
  id: PropTypes.string,
  onSelected: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired })
  ),
  rows: PropTypes.arrayOf(PropTypes.shape(tableRowType)).isRequired,
  onCheckRowItem: PropTypes.func,
  onCheckRowColumn: PropTypes.func,
};

RivataTable.propTypes = tableType;

export default RivataTable;
