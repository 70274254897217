import React, { useEffect, useState, useMemo } from 'react'
import { Button } from 'reactstrap'
import CustomersDropdown from '../../../components/CustomersDropdown'
import DeleteModal from '../../../components/DeleteModal'
import InfoModal from '../../../components/InfoModal'
import SubNav from '../../../components/SubNav'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { getErrorObj } from '../../../utils'
import CustomerModal from '../CustomerModal'
import { ICustomerFormValues } from '../types'
import { getCustomerReqBody, someAssetIsReporting } from '../utils'

interface IProvisionSubNavProps {
    isSuperAdmin: boolean
    isAdmin: boolean
    locale: ILocale
    selectedCustomer: ICustomer
    error: IError
}

const ProvisionSubNav: React.FC<IProvisionSubNavProps> = ({
    locale,
    isSuperAdmin,
    isAdmin,
    selectedCustomer,
    error
}) => {
    const {
        loading,
        data,
        assets
    } = useTypedSelector(state => ({
        ...state.common.customers,
        assets: state.provision.assets.data
    }))

    const {
        setSelectedCustomer,
        deleteCustomer,
        addCustomer,
        updateCustomer
    } = useActions()

    const [modalUpdate, setModalUpdate] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [btnsEnabled, setBtnsEnabled] = useState(false)

    const warningMessage = useMemo(() => {
        return someAssetIsReporting(assets)
          ? "There are assets reporting for this customer. Are you sure you want to delete customer and all it's assets and users?"
          : "";
    }, [assets])

    useEffect(() => {
        setBtnsEnabled(loading || !selectedCustomer || !selectedCustomer.id)
    }, [loading, selectedCustomer])

    return (
        <SubNav
            title="Provision"
            breadcrumbs={[{ label: "Home", path: "/dashboard" }, { label: "Provision" }]}
        >
            {isAdmin ? (
                <>
                    <CustomersDropdown
                        locale={locale}
                        label={locale.Customer || "Customer"}
                        onChange={(customer: ICustomer) => {
                            setSelectedCustomer(customer)
                        }}
                    />
                    {isSuperAdmin ? (
                        <>
                            <InfoModal
                                message={error.message} 
                                header="Customer Info"
                            />
                            <CustomerModal
                                isEdit={false}
                                disabled={loading}
                                title="Create"
                                locale={locale}
                                isUpdate={false}
                                initialValues={{
                                    name: "",
                                    description: "",
                                    parent_id: ""
                                }} 
                                onCreate={async (values: ICustomerFormValues) => {
                                    if (values) {
                                        const alreadyExist = data.find((el: { name: any} ) => el.name === values.name ? true : false)
                                        if (alreadyExist) {
                                            const err = getErrorObj({ statusCode: 400, message: "" }, `Customer with name: ${values.name} already exist`)
                                            return err
                                        }
                                        const valid_request = getCustomerReqBody(values)
                                        const res = await addCustomer(valid_request)

                                        return res
                                    }
                                } }
                                customers={data} 
                                open={undefined} 
                                onClose={undefined}
                            />
                            <Button
                                disabled={btnsEnabled}
                                size="md"
                                className="ml-3"
                                onClick={() => setModalUpdate(true)}
                            >
                                <i className="mr-2 fa fa-edit"></i>
                                {locale["Update"] || "Update"}
                            </Button>
                            {modalUpdate && ( // rerender so new initialValues show up
                                <CustomerModal
                                    isEdit
                                    disabled={btnsEnabled}
                                    onCreate={(values: ICustomerFormValues) => {
                                        if (values && selectedCustomer) {
                                            const valid_request = getCustomerReqBody(values)
                                            updateCustomer(valid_request)
                                        }
                                        setModalUpdate(false)
                                    } }
                                    onClose={() => setModalUpdate(false)}
                                    title="Update"
                                    locale={locale}
                                    isUpdate={true}
                                    open={modalUpdate}
                                    initialValues={{
                                        id: selectedCustomer.id,
                                        name: selectedCustomer.name,
                                        description: selectedCustomer.description
                                    }} 
                                    customers={data}
                                />
                            )}
                            <Button
                                disabled={btnsEnabled}
                                size="md"
                                className="ml-3"
                                onClick={() => setModalDelete(true)}
                            >
                                <i className="mr-2 fa fa-minus-circle"></i>
                                {locale["Delete"] || "Delete"}
                            </Button>
                            {modalDelete && (
                                <>
                                    {!selectedCustomer?.children?.length ? (
                                        <DeleteModal
                                            open={true}
                                            disabled={false}
                                            header={modalDelete ? `Delete company ${selectedCustomer.name}?` : ""}
                                            warningMessage={warningMessage}
                                            message={"All assets and users will be deleted! This cannot be undone!"}
                                            onDelete={() => {
                                                if (selectedCustomer?.id) {
                                                    deleteCustomer(selectedCustomer.id)
                                                }
                                                setModalDelete(false)
                                            }}
                                            onCancel={() => setModalDelete(false)}
                                            locale={locale}
                                        />
                                    ) : (
                                        <InfoModal
                                            open={true}
                                            header={"This cannot be done!"}
                                            message={"This customer have child customers and it connot be deleted!"}
                                            onConfirm={() => setModalDelete(false)}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : null}
                </>
            ) : null}
        </SubNav>
    )
}

export default ProvisionSubNav