import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
//import { createLogger } from 'redux-logger';

//const logger = createLogger();

export const store = configureStore();

function configureStore(initialState) {
    //const middlewares = [logger, thunk];
    const middlewares = [thunk];
    const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducers,
        initialState,
        storeEnhancers(applyMiddleware(...middlewares))
    );

    return store;
}
