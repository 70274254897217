import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { initSession } from "../redux/auth/action";
import InitialLoader from "../components/InitialLoader";

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = { apiStatus: "Not called" };
  }

  componentDidMount() {
    if (!this.props.auth.isLoggedIn) {
      this.props.initSession();
    }
  }

  render() {
    const { component: RouteComponent, ...rest } = this.props;
    const {
      auth: { loginChecked, isLoggedIn },
      whitelabel: { hasCheckedForConfig },
      common: { customerDefaults, isDefaultsLoading }
    } = this.props;

    const keys = Object.keys(customerDefaults)
    // !loginChecked || (isLoggedIn && !hasCheckedForConfig) || (!keys.length && isDefaultsLoading)
    return !loginChecked || (isLoggedIn && !hasCheckedForConfig) || (!keys.length && isDefaultsLoading) ? (
      <InitialLoader/>
    ) : (
      <Route
        {...rest}
        render={(props) => {
          if (!isLoggedIn) {
            // not logged in so redirect to login page with the return url
            return (
              <Redirect
                to={{
                  pathname: "/authentication/login",
                  state: { from: props.location },
                }}
              />
            );
          }

          // authorised so return component
          return <RouteComponent {...props} />;
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, whitelabel: state.whitelabel, common: state.common };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initSession: () => dispatch(initSession())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
