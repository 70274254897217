import React from "react";
import { convertDataEpochToDate } from "../../utils";
import classes from "./styles.module.scss";
import moment from "moment";
import { Button } from "reactstrap";

const AssetTable = ({
  position,
  mac,
  formatted_last_update,
  prev_update_at_epoch,
  locale,
  hardware_type,
  onAdminLogs,
  isSuperAdmin,
  id,
  lastUpdated,
}) => {
  const currentDate = +(+new Date() / 1000).toFixed(0);

  const onGetLogs = () => {
    const epochNow = moment().unix();
    let dateEpoch = lastUpdated ? lastUpdated : epochNow;
    const date = convertDataEpochToDate(dateEpoch, null, null, true);
    onAdminLogs(id, date, "sensors");
  };

  return (
    <tr className={classes.assetRow}>
      <td>
        {hardware_type === "axle_load" ? "Axle Load"
          : hardware_type === "line_pressure" ? "Line Pressure"
            : position ? locale[position] || position
              : "---"}
      </td>
      <td>{mac || "-"}</td>
      <td>
        {formatted_last_update !== "-" &&
        +(+new Date(formatted_last_update) / 1000).toFixed(0) <= currentDate
          ? formatted_last_update
          : prev_update_at_epoch
          ? convertDataEpochToDate(prev_update_at_epoch, null, null, true)
          : "-"}
      </td>
      {isSuperAdmin && (
        <td>
          <Button outline color="secondary" size="sm" onClick={onGetLogs}>
            Logs
          </Button>
        </td>
      )}
    </tr>
  );
};

export default AssetTable;
