import React, { useState, useCallback } from 'react';
import { Table } from 'reactstrap';
import RivatePagination from '../RivatePagination';
import LimitDropdown from '../LimitDropdown';
import RivataLoader from '../RivataLoader'
import StatusAlert from '../StatusAlert'
import Thead from './Thead';
import Tr from './Tr';
import './styles.scss'

const RivataTpmsTable = ({
    tpmsStatusAssets,
    defaults,
    preferences,
    isShowingLimit,
    onPageChange,
    onSelectLimit,
    onSort,
}) => {
    const [sortColumn, setSortColumn] = useState(null)

    const limitItems = [
        { item: 30 },
        { item: 60 },
        { item: 90 },
    ]

    const sortHandler = useCallback((columnId, direction) => {
        onSort(columnId, direction)
    }, [onSort])
    
    return (
        <div className="tpms-table">
            {tpmsStatusAssets.isLoading && <RivataLoader/>}
            {isShowingLimit && tpmsStatusAssets.totalCount > 0 && tpmsStatusAssets.data.length > 0 ? (
                <div className="d-flex">
                    <RivatePagination 
                        assetsCount={tpmsStatusAssets.totalCount} 
                        onPageChange={onPageChange} 
                        pageLimit={tpmsStatusAssets.limit} 
                        initialPage={tpmsStatusAssets.offset / tpmsStatusAssets.limit}
                    />
                    <LimitDropdown
                        setPageLimit={onSelectLimit}
                        pageLimit={tpmsStatusAssets.limit}
                        limitDropdownItems={limitItems}
                    />
                </div>
            ) : null}
            {tpmsStatusAssets.data.length > 0 ? (
                <Table>
                    <Thead 
                        columns={tpmsStatusAssets.columns} 
                        onSort={sortHandler}
                        sortColumn={sortColumn}
                        setSortColumn={(id) => setSortColumn(id)}
                        preferences={preferences}
                    />
                    <tbody>
                        {Object.keys(tpmsStatusAssets.data).map((key) => {
                            const asset = tpmsStatusAssets.data[key]
                            return (
                                <Tr 
                                    key={asset.vin} 
                                    defaults={defaults} 
                                    asset={asset}
                                    columns={tpmsStatusAssets.columns}
                                    preferences={preferences} 
                                />
                            )
                        })}
                    </tbody>
                </Table>
            ) : tpmsStatusAssets.error.statusCode === 200 ? (
                <StatusAlert customText={tpmsStatusAssets.error.message} color="success"/>
            ) : (
                <StatusAlert customText="No Data" color="success"/>
            )}
        </div>
    )
}

export default RivataTpmsTable