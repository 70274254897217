export enum GeofencesActionTypes {
  LOADING_GEOFENCES = "LOADING_GEOFENCES",
  SET_PAGED_GEOFENCES = "SET_PAGED_GEOFENCES",
  SET_CUSTOMER_GEOFENCES = "SET_CUSTOMER_GEOFENCES",
  SET_GEOFENCES_ERROR = "SET_GEOFENCES_ERROR",
  SET_GEOFENCES_TOTAL_COUNT = "SET_GEOFENCES_TOTAL_COUNT",
  SET_GEOFENCES_OFFSET = "SET_GEOFENCES_OFFSET",
  SET_ACTIVITY_EVENTS = "SET_ACTIVITY_EVENTS",
  SET_GEOFENCES_EVENTS_TOTAL_COUNT = "SET_GEOFENCES_EVENTS_TOTAL_COUNT",
  SET_EVENTS_FILTER = "SET_EVENTS_FILTER",
  SET_GEOFENCES_LIMIT = "SET_GEOFENCES_LIMIT",
  SET_EVENTS_LIMIT = "SET_EVENTS_LIMIT",
  SET_EVENTS_OFFSET = "SET_EVENTS_OFFSET",
  SET_RESET_EVENTS_OFFSET = "SET_RESET_EVENTS_OFFSET",
  SET_GEOFENCES_SORT_OPTIONS = "SET_GEOFENCES_SORT_OPTIONS",
  SET_CUSTOMER_ID = "SET_CUSTOMER_ID",
}

export interface EventsFilter {
  selectedGeofenceId: number | null;
  startDate: number | null;
  endDate: number | null;
  assetVin: string;
}

export interface GeofencesSortPayload {
  column: string;
  direction: "asc" | "desc";
}
