import React from "react";
import {
  H,
  bboxAll,
  bboxLast24,
  getMarkerPinMap,
  behaviorUi,
  LayerTypes,
  getTooltipCoordinates,
  getPinData
} from "./utils";
import { getGpsDirectinFromDeg } from '../../utils/utils'
import { getWhitelabelColors, kmhToMph, metersToFt } from "../../utils"; // kpaToLbs, kpaToKg
import "./styles.scss";
import { connect } from "react-redux";
import { history } from '../../jwt/_helpers';
import config from '../../config/appConfig';
import { UnitsOfMeasurement } from "../../enums"
import GeofenceRenderer from "../../modules/Geofences/renderer/GeofenceRenderer";

const isValidCoord = function (value) {
  if (typeof value !== "number" || Math.floor(value) === value) return false;
  const decimals = value.toString().split(".")[1].length || 0;
  return decimals > 2;
}

class HereMap extends React.PureComponent {
  mapRef = React.createRef();
  state = {
    hMap: null,
    markersGroup: null,
    markersMap: {},
    layerChoices: null,
    bubble: null,
    whiteLabelColors: getWhitelabelColors(),
    clusteringLayerRef: null,
    unitsOfMeasurement: "imperial",
    ui: null
  };

  handleMapLeave = (e) => {
    this.state.bubble.close()
  }

  resize = () => {
    const { hMap } = this.state;

    if (hMap) {
      hMap.getViewPort().resize();
    }
    this.mapRef.current.removeEventListener("mouseleave", this.handleMapLeave)
  };

  componentWillUnmount() {
    const { hMap } = this.state;

    if (hMap) {
      hMap.dispose();
    }
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    const platform = new H.service.Platform({
      apikey: config.mapApiKey,
    });
    const defaultLayers = platform.createDefaultLayers();

    const hMap = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 41.850033, lng: -87.6500523 }, // center of U.S. default
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    const markersGroup = new H.map.Group();
    //hMap.addObject(markersGroup);

    window.addEventListener("resize", this.resize);
    const { ui } = behaviorUi(hMap, defaultLayers, this.state.unitsOfMeasurement);

    const bubble = new H.ui.InfoBubble(
      { lng: 13.4, lat: 52.51 },
      {
        content: "",
      }
    );
    bubble.addClass("info-bubble");
    ui.addBubble(bubble);

    ui.getControl("zoom").setVisibility(true);
    ui.getControl("mapsettings").setVisibility(false);
    ui.getControl()

    const layerChoices = {
      [LayerTypes.NORMAL]: defaultLayers.vector.normal.map,
      [LayerTypes.SATELLITE]: defaultLayers.raster.satellite.map,
    };

    const whiteLabelColors = getWhitelabelColors(this.props.healthColors);

    this.setState({ hMap, markersGroup, layerChoices, bubble, whiteLabelColors, ui });
    this.resize();
    this.mapRef.current.addEventListener("mouseleave", this.handleMapLeave)
  }

  selectMarkers = () => {
    const { markersGroup } = this.state;
    const { selected } = this.props;
    if (!markersGroup) return;

    const markerIconMap = getMarkerPinMap(this.props.markerType);

    markersGroup.getObjects().forEach((marker) => {
      const { pinKey, zIndex } = getPinData(marker, selected)
      // Yes, zIndex here is bottom to top, so backwards of normal html!
      marker.setZIndex(zIndex);
      if (pinKey in markerIconMap) {
        marker.setIcon(markerIconMap[pinKey]);
      }
    });
  };

  addInfoBubble = (e) => {
    const { bubble, whiteLabelColors } = this.state;
    const { preferences: { unitsOfMeasurement = UnitsOfMeasurement.imperial } } = this.props;

    if (!bubble) return;
    const data = e.target.getData();
    const { name, vin, formatted_datetime, speed, elevation, heading, latitude, longitude } = data
    
    const { hasCriticalWarning, hasWarning } = data
    const { healthCriticalWarning, healthWarning, healthGood } = whiteLabelColors

    const formattedSpeed = isNaN(speed) ? "NA" : unitsOfMeasurement === UnitsOfMeasurement.imperial ? kmhToMph(speed).toFixed(1) + "mph" : speed.toFixed(1) + "kmh";
    const formattedElevation = unitsOfMeasurement === UnitsOfMeasurement.imperial ? metersToFt(elevation).toFixed(1) + "ft" : elevation + "m";
    const labelColor = hasCriticalWarning ? healthCriticalWarning : hasWarning ? healthWarning : healthGood;

    const map = document.getElementById("rivata-map-cluster");
    const { top, left } = getTooltipCoordinates(map, data, e, -25, -173);
    
    bubble.setContent(`
      <div id="dashboard-buble" style="position: absolute; top: ${top}px; left: ${left}px; padding: 20px">
        <div class="alert-primary show" role="alert" aria-live="assertive" aria-atomic="true" style="background-color: ${labelColor}; border-color: ${labelColor};">
          <div class="label-line"></div>
          <div class="bubble-body">
            <div class="row-wrapper">
              <div class="key">${name ? 'Name:' : 'VIN'}</div>
              <div class="value">${name ? name : vin}</div>
            </div>
            <div class="row-wrapper">
              <div class="key">Date:</div>
              <div class="value">${formatted_datetime}</div>
            </div>
            <div class="row-wrapper">
              <div class="key">Heading:</div>
              <div class="value">${heading ? getGpsDirectinFromDeg(heading) : "-"}</div>
            </div>
            <div class="row-wrapper">
              <div class="key">Speed:</div>
              <div class="value">${formattedSpeed}</div>
            </div>
            <div class="row-wrapper">
              <div class="key">Elevation:</div>
              <div class="value">${formattedElevation}</div>
            </div>
              <div class="row-wrapper">
              <div class="key">Location:</div>
              <div class="value">${latitude}, ${longitude}</div>
               <div id="details" class="aligned-cell">Details</div>
            </div>
          </div>
        </div>
      </div>`);

    bubble.setPosition(e.target.getGeometry());
    bubble.open();

    const el = document.getElementById('details');
    el.addEventListener('click', () => {
      history.push(`/details/${data.vin}`)
    })

    document.querySelector("#dashboard-buble").addEventListener("pointerleave", this.removeInfoBubble);
  };

  removeInfoBubble = () => {
    const { bubble } = this.state;
    if (!bubble) return;
    bubble.close();
  };

  updateMarkers = async (reset) => {
    const { hMap, markersMap, markersGroup } = this.state;
    const { locations, bboxLast24hrs, markerType } = this.props;

    if (!hMap || !Array.isArray(locations) || !markersGroup) return;

    if (reset) {
      markersGroup.removeAll();
    }

    const newMarkersMap = reset ? {} : { ...markersMap };
    const markersToAdd = [];
    locations.forEach((data, i) => {
      if (newMarkersMap[data.id]) return;
      newMarkersMap[data.id] = data;
      const markerIconMap = getMarkerPinMap(markerType);
      let icon;
      if ("ok" in markerIconMap) {
        icon = markerIconMap.ok;
      }
      // this check removes 0, 0 coords, which are almost ALWAYS bad data
      if (isValidCoord(data.latitude) && isValidCoord(data.longitude)) {
        const marker = new H.map.Marker(
          { lat: data.latitude, lng: data.longitude },
          { icon, data: { ...data } }
        );
        marker.addEventListener("pointerenter", this.addInfoBubble);
        markersToAdd.push(marker);
      }
    });
    // update the data that was not just added above.
    this.updateExistingData();

    if (markersToAdd.length) {
      this.setState({ markersMap: newMarkersMap });
      // add markers to the group

      markersGroup.addObjects(markersToAdd);
      const bbox = bboxLast24hrs
        ? bboxLast24(markersGroup.getObjects(), 0.5)
        : bboxAll(markersGroup.getBoundingBox(), 0.5);
      if (bbox) {
        // get geo bounding box for the group and set it to the map
        hMap.getViewModel().setLookAtData(
          {
            bounds: bbox,
            zoom: markersToAdd.length === 1 ? 16 : null
          }
        );
      }
    }
  };

  startClustering = (isListNew) => {
    const { hMap, markersGroup, clusteringLayerRef } = this.state;
    const { locations } = this.props;
    if (!hMap || !Array.isArray(locations) || !isListNew) return;

    if (clusteringLayerRef) {
      //remove layer if it already exists
      hMap.removeLayer(clusteringLayerRef);
      this.setState({ clusteringLayerRef: null })
    }


    var dataPoints = new Array(markersGroup.length);
    markersGroup.getObjects().forEach((marker) => {
      let data = marker.getData();
      if (marker && data) {
        dataPoints.push(new H.clustering.DataPoint(data.latitude, data.longitude, 1, data));
      }
    });

    dataPoints.forEach((marker) => {
      if (!marker) {
        dataPoints.shift();
      }
    });

    var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 32, // Max radius of cluster
        minWeight: 2 // Min markers in a cluster
      }
    });

    let defaultTheme = clusteredDataProvider.getTheme();
    clusteredDataProvider.setTheme(this.clusterTheme(defaultTheme));

    var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
      hMap.getLayers().add(clusteringLayer);
      this.setState({ clusteringLayerRef: clusteringLayer })

  }

  clusterTheme = (defaultTheme) => {
    const { whiteLabelColors } = this.state;
    const { markerType, selected } = this.props;
    const markerIconMap = getMarkerPinMap(markerType);
    const parent = this;
    return {
      getClusterPresentation: function (cluster) {
        let clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);
        const dataPoints = [];
        cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

        const clusterWarnings = dataPoints.reduce(
          (points, marker) => {
            const { hasCriticalWarning, hasWarning } = marker.getData();
            if (hasCriticalWarning === true) {
              points[0].count++;
            } else if (hasWarning === true) {
              points[1].count++;
            } else {
              points[2].count++;
            }
            return points;
          },
          [
            {
              type: "criticalWarning",
              color: whiteLabelColors.healthCriticalWarning,
              count: 0,
            },
            {
              type: "warning",
              color: whiteLabelColors.healthWarning,
              count: 0,
            },
            {
              type: "normal",
              color: whiteLabelColors.healthGood,
              count: 0,
            },
          ]
        );

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const radius = 38;

        canvas.width = radius * 2;
        canvas.height = radius * 2;

        const cursor = { end: 0, start: 0 };

        clusterWarnings.forEach(item => {
          const ratio = item.count / dataPoints.length;
          cursor.end = 2 * ratio * Math.PI;
          context.beginPath();
          context.moveTo(radius, radius);
          context.arc(radius, radius, radius, cursor.start, cursor.end + cursor.start, false);
          context.fillStyle = item.color;
          context.globalAlpha = 0.8;
          cursor.start += cursor.end;
          context.fill();
        });

        context.fillStyle = '#6d6e71';
        context.globalAlpha = 1;
        context.beginPath();
        context.arc(radius, radius, 25, 0, 2 * Math.PI);
        context.fill();
        context.fillStyle = 'white';
        context.font = 'bold 22px Arial';
        context.textAlign = "center";
        context.fillText(dataPoints.length, radius, 45);

        clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: new H.map.Icon(canvas, { size: { w: 38, h: 38 }, anchor: { x: 19, y: 19 } }),
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom()
        });

        return clusterMarker;
      },
      getNoisePresentation: function (noisePoint) {
        let data = noisePoint.getData();
        const { pinKey, zIndex } = getPinData(noisePoint, selected)

        var noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
          icon: markerIconMap[pinKey],
          data: { ...data },
          min: noisePoint.getMinZoom()
        });

        // Yes, zIndex here is bottom to top, so backwards of normal html!
        noiseMarker.setZIndex(zIndex);
        noiseMarker.setData(data);

        noiseMarker.addEventListener("pointerenter", parent.addInfoBubble);

        return noiseMarker;
      }
    }
  };

  updateExistingData = () => {
    const { markersGroup, markersMap } = this.state;
    if (!markersGroup) return;

    markersGroup.getObjects().forEach((marker) => {
      const data = marker.getData();
      const id = data.id;
      const location = markersMap[id];
      if (location) {
        marker.setData(location);
      }
    });
  }

  updateBaseLayer = () => {
    // layerType, hMap
    const { hMap, layerChoices } = this.state;
    const { layerType } = this.props;
    if (!layerType || !hMap) return;

    if (layerType in layerChoices) {
      hMap.setBaseLayer(layerChoices[layerType]);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldBaseLayerUpdate(prevProps, prevState)) {
      this.updateBaseLayer();
    }
    if (this.shouldMarkersUpdate(prevProps, prevState)) {
      this.updateMarkers(
        this.props.locations.length !== prevProps.locations.length
      );
      this.startClustering(
        this.props.locations.length !== prevProps.locations.length
      );
    }
    if (this.shouldSelectMarkersUpdate(prevProps, prevState)) {
      this.selectMarkers();
    }

    if (this.props.preferences && this.props.preferences.unitsOfMeasurement !== this.state.unitsOfMeasurement) {
      if (this.state.ui) {
        this.state.ui.setUnitSystem(this.props.preferences.unitsOfMeasurement === "metric" ? H.ui.UnitSystem.METRIC : H.ui.UnitSystem.IMPERIAL);
      }
    }
  }

  shouldMarkersReset = (pp) => {
    const { locations } = this.props;

    return locations.length !== pp.locations.length;
  };

  shouldBaseLayerUpdate = (pp, ps) => {
    const { hMap } = this.state;
    const { layerType } = this.props;

    return hMap !== ps.hMap || layerType !== pp.layerType;
  };

  shouldMarkersUpdate = (pp, ps) => {
    const { markersGroup, markersMap } = this.state;
    const { locations, selectable } = this.props;

    const shouldUpdate = (
      selectable !== false ||
      locations !== pp.locations ||
      markersGroup !== ps.markersGroup ||
      markersMap !== ps.markersMap
    );

    return shouldUpdate;
  };

  shouldSelectMarkersUpdate = (pp, ps) => {
    const { hMap, markersGroup, markersMap } = this.state;
    const { locations, selected } = this.props;

    return (
      hMap !== ps.hMap ||
      locations !== pp.locations ||
      markersGroup !== ps.markersGroup ||
      markersMap !== ps.markersMap ||
      selected !== pp.selected
    );
  };

  render() {
    return (
      <div
        className="map"
        id="rivata-map-cluster"
        ref={this.mapRef}
        style={{ height: "400px", width: "100%"}}
      >
        <GeofenceRenderer map={this.state.hMap} ui={this.state.ui} geofences={this.props.geofences || []} geofencesVisible={this.props.geofencesVisible} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  healthColors: state.whitelabel.healthColors,
});

export default connect(mapStateToProps)(HereMap);
