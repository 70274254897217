import { useMemo } from "react";
import moment from "moment"
import { WhiteLabelFilenames, SubscriptionTypes, ColumnsIds } from "../../enums"


export const useTableRows = (data, columns, whiteLabelUrl, customerIds, isSuperAdmin) => {
  return useMemo(() => {
    const smartHubSrc = `${whiteLabelUrl}${WhiteLabelFilenames.smarthubHealthSvg}?r=${+new Date()}`;
    const tpmsSrc = `${whiteLabelUrl}${WhiteLabelFilenames.tpmsHealthSvg}?r=${+new Date()}`;
    const linePressureSrc = `${whiteLabelUrl}${WhiteLabelFilenames.linePressureHealthSvg}?r=${+new Date()}`;
    const axleLoadSrc = `${whiteLabelUrl}${WhiteLabelFilenames.axleLoadHealthSvg}?r=${+new Date()}`;

    return data.map((datum, rowIndex) => {
      const obj = { ...datum, asset_type: datum.asset_type === "tractor" ? "Tractor" : datum.asset_type === "trailer" ? "Trailer" : "" }
      
      const row =  {
        id: rowIndex,
        data: datum,
        columns: columns.map((col, i) => {
          if (col.id === "subscription" && obj.subscription) {
            
            const alt = []
            const src = []
            Object.keys(obj?.subscription).map(key => {
              const { valid_from, valid_to } = obj.subscription[key]
              const currentDate = moment(new Date()).unix()
              if (currentDate < valid_from || currentDate > valid_to) return null
              switch (key) {
                case SubscriptionTypes.smarthub:
                  src[0] = smartHubSrc
                  alt[0] = "hub"
                  break;
                case SubscriptionTypes.tpms:
                  src[1] = tpmsSrc
                  alt[1] = "tire"
                  break;
                case SubscriptionTypes.axleLoad:
                  src[2] = axleLoadSrc
                  alt[2] = "axle"
                  break;
                case SubscriptionTypes.linePressure:
                  src[3] = linePressureSrc
                  alt[3] = "line"
                  break;
                default:
                  break;
              }
              return key
            })

            return {
              columnId: columns[i].id,
              type: "icon",
              label: alt,
              alt,
              src,
              classNames: [],
            }
          }
          return {
            columnId: col.id,
            controlEnabled: false,
            type: "text",
            label: `${obj[col.id] || "-"}`,
          }
        })
      }

      if (customerIds?.length < 2 && !isSuperAdmin) {
        const idx = row.columns.findIndex(el => el.columnId === ColumnsIds.CUSTOMER_NAME)
        if (idx > -1) row.columns.splice(idx, 1)
      }

      return row
    });  
  }, [data, columns, whiteLabelUrl, customerIds, isSuperAdmin]);
};

export const useColumns = (locale, customerIds, isSuperAdmin) => {
  return useMemo(() => {
    const columns = [
      { label: locale["Asset Name"] || "Name", id: "name", control: "sort" },
      { label: locale["Customer"] || "Child Customer", id: ColumnsIds.CUSTOMER_NAME, control: "sort" },
      { label: locale["VIN"] || "VIN", id: ColumnsIds.VIN, control: "sort" },
      { label: "Subscription", id: ColumnsIds.SUBSCRIPTION, control: "sort" },
      { label: locale["Year"] || "Year", id: "year", control: "sort" },
      { label: locale["Make"] || "Make", id: "make", control: "sort" },
      { label: locale["Model"] || "Model", id: "model", control: "sort" },
      { label: locale["Odometer Start"] || "Odometer Start", id: "odometer_start", control: "sort" },
      { label: locale["Asset Type"] || "Asset Type", id: "asset_type", control: "sort" },
    ];

    if (customerIds?.length < 2 && !isSuperAdmin) {
      const idx = columns.findIndex(el => el.id === ColumnsIds.CUSTOMER_NAME)
      if (idx > -1) columns.splice(idx, 1)
    }

    return columns
  }, [locale, customerIds, isSuperAdmin]);
}