import React, { useState } from "react";
import { CustomInput } from 'reactstrap'
import RivataModule from "../../components/RivataModule";
import RivataSearch from "../../components/RivataSearch";
import RivataTable from "../../components/RivataTable";
import { ColumnsIds } from "../../enums";
import {
  useColumns,
  useTableRows,
  useSearchFields,
} from "./hooks";
import './styles.scss'

const setAcknowledgement = (acknowledgements, assetId, data, checked) => {
  if (checked) {
    acknowledgements[assetId] = {
      checked,
      ...data,
    };
  } else {
    delete acknowledgements[assetId];
  }
};

const composeAcknowledgements = (
  statusData,
  checked,
  currentAcknowledgements
) => {
  const { data, assetId } = statusData;
  if (!data || !data.type || !data.ids.length) return currentAcknowledgements;

  const newAcknowledgements = { ...currentAcknowledgements };
  setAcknowledgement(newAcknowledgements, assetId, data, checked);

  return newAcknowledgements;
};

const selectAllAcknowledgements = (rows, checked) => {
  return rows.reduce((acknowledgements, row) => {
    const { assetId, data, controlEnabled } = row.columns[0];

    if (controlEnabled) {
      setAcknowledgement(acknowledgements, assetId, data, checked);
    }

    return acknowledgements;
  }, {});
};

const AssetTable = ({
  isSuperAdmin,
  assets,
  assetsError,
  isLoading,
  onSelected,
  gps,
  warnings,
  width,
  whiteLabelUrl,
  locale,
  preferences,
  assetsCount,
  onFilterChange,
  pageLimit,
  daysCount,
  onSelectLimit,
  onPageChange,
  setSortOptions,
  defaults: { subscriptions = {} },
  setHideAutogeneratedAssets,
  hideAutogeneratedAssets
}) => {

  const [searchColumn, setSearchColumn] = useState(ColumnsIds.STATUS);
  const [selectAll, setSelectAll] = useState(false);
  const [pendingAcknowledgements, setPendingAcknowledgements] = useState({});
  // custom hooks
  const columns = useColumns(locale, selectAll, preferences.customer_ids, isSuperAdmin);
  const searchFields = useSearchFields(locale);

  const tableRows = useTableRows(
    assets,
    warnings,
    gps,
    whiteLabelUrl,
    preferences.timezone,
    isSuperAdmin,
    preferences.customer_ids
  );

  return (
    <RivataModule
      title="Assets"
      width={width}
      marginTop={0}
      locale={locale}
      error={assetsError}
      filters={
        <>
          {isSuperAdmin && (
            <CustomInput
              id="hideAutogenerated"
              className="d-flex align-items-center mr-3"
              type="checkbox"
              label="Hide Autogenerated Assets"
              checked={hideAutogeneratedAssets}
              onChange={() => setHideAutogeneratedAssets(!hideAutogeneratedAssets)}
            />
          )}
          <RivataSearch
            searchFields={searchFields}
            searchColumn={searchColumn}
            id="assets_table"
            onFilter={onFilterChange}
            onSelectSearchField={setSearchColumn}
            subscriptions={subscriptions}
            isSuperAdmin={isSuperAdmin}
          />
        </>
      }
    >
      <>
        <RivataTable
          title="Assets"
          width={width}
          locale={locale}
          isLoading={isLoading}
          onSelectedRow={onSelected}
          columns={columns}
          rows={tableRows}
          onCheckRowColumn={(_columnId, checked) => {
            setSelectAll(checked);
            setPendingAcknowledgements(
              selectAllAcknowledgements(tableRows, checked)
            );
          }}
          onCheckRowItem={(data, checked) => {
            setSelectAll(false);
            const pending = composeAcknowledgements(
              data,
              checked,
              pendingAcknowledgements
            );
            setPendingAcknowledgements(pending);
          }}
          isShowingLimit={true}
          assetsCount={assetsCount}
          onPageChange={onPageChange}
          onSelectLimit={onSelectLimit}
          pageLimit={pageLimit}
          setSortOptions={setSortOptions}
          daysCount={daysCount}
        />
      </>

    </RivataModule>
  );
};

export default AssetTable;
