import {
    SET_TAG_GPS_DATA,
    SET_IS_LOADING_TAG_GPS_DATA,
    SET_IS_LOADING_TAG_GPS_DATA_DETAILED,
    SET_TAG_GPS_DATA_DETAILED,
    SET_TAG_GPS_DATA_DETAILED_OFFSET,
    SET_TAG_GPS_DATA_DETAILED_TOTAL_COUNT,
    SET_TAG_GPS_FILTERS,
    SET_IS_LOADING_FILTERS
} from "../actionTypes";

const initialState = {
    gpsData: [],
    gpsDataDetailed: [],
    isLoadingGpsData: false,
    isLoadingGpsDataDetailed: false,
    isLoadingFilters: false,
    limit: 10,
    offset: 0,
    totalCount: 0,
    filters: {}
};

const tagDetails = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAG_GPS_DATA:
            return { ...state, gpsData: action.payload };
        case SET_IS_LOADING_TAG_GPS_DATA:
            return { ...state, isLoadingGpsData: action.payload };
        case SET_TAG_GPS_DATA_DETAILED:
            return { ...state, gpsDataDetailed: action.payload };
        case SET_IS_LOADING_TAG_GPS_DATA_DETAILED:
            return { ...state, isLoadingGpsDataDetailed: action.payload };
        case SET_TAG_GPS_DATA_DETAILED_OFFSET:
            return { ...state, offset: action.payload }
        case SET_TAG_GPS_DATA_DETAILED_TOTAL_COUNT:
            return { ...state, totalCount: action.payload }
        case SET_TAG_GPS_FILTERS:
            return { ...state, filters: action.payload }
        case SET_IS_LOADING_FILTERS:
            return { ...state, isLoadingFilters: action.payload }
        default:
            return state;
    }
};

export default tagDetails;
