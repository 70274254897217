import React from 'react'
import DeleteModal from '../../components/DeleteModal'
import { UnitsOfMeasurement } from '../../enums'
import { kgToLbs, convertDataEpochToDate, getCurrentEpoch } from '../../utils'

const CalibrationDeleteModal = ({
    deleteItem: { id, is_loaded, vehicle_weight, timestamp_from, timestamp_till },
    onCancel,
    onDelete,
    selectedUnitsOfMeasurement,
}) => {
    return (
        <DeleteModal 
            header={"Delete This Calibration Data?"}
            open={!!id}
            onCancel={onCancel}
            onDelete={onDelete}
        >
            <div>
                <label>State: {is_loaded ? "Loaded" : "Empty"}</label>
                <br/>
                <label>Weight: {selectedUnitsOfMeasurement === UnitsOfMeasurement.imperial ? kgToLbs(vehicle_weight).toFixed(2) + " lbs" : vehicle_weight.toFixed(2) + " kg"}</label>
                <br/>
                <label>Datetime Range: {
                    convertDataEpochToDate(timestamp_from, null, null, true) + 
                    " - " + (timestamp_till > getCurrentEpoch() ? "n/a" : convertDataEpochToDate(timestamp_till, null, null, true))
                }</label>
            </div>
        </DeleteModal>
    )
}

export default CalibrationDeleteModal