import { PressureWarningLevel } from '../../enums'

export interface IGpsWarningDetails {
    position: string,
    key: string,
    cause: string,
}

export const getElemIdxByKeyInArr = (
    details: Array<IGpsWarningDetails>,
    key: string ,
    searchElem: string
): number => {
    if (key === "key") {
        return details.findIndex(el => el[key] === searchElem ? true : false)
    }

    return -1
}

export const hasCriticalWarning = (
    details: Array<IGpsWarningDetails>,
    key: string,
    searchElem: string
): boolean => {
    if (key === "key") {
        return details.some(el => {
            if ((el[key] === searchElem) && [PressureWarningLevel.criticalLowPressure, PressureWarningLevel.criticalOverPressure].includes(el.cause)) {
                
                return true
            }

            return false
        })
    }

    return false
}