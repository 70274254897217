import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import { SensorLogsTable, SensorLogsPagination } from "./sensorLogs";
import {
  GatewayLogsTranslator,
  GatewayLogsRivata,
  GatewayLogsPagination,
  TimePickerGates,
  TranslatorHeader,
  RivataHeader,
} from "./gatewayLogs";
import DateHandler from "./dateHandler";
import moment from "moment";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Fade,
  Input,
} from "reactstrap";
import RivataLoader from "../../components/RivataLoader";

const ModalLogInfo = ({
  onClose,
  isOpen,
  logsData,
  date,
  setDate,
  type,
  time,
  offset,
  setTime,
  setType,
  logsCount,
  onSubmit,
  onSelectLimit,
  onLogsPageChange,
  onSetDefaultData,
  resetTable,
  formReset
}) => {
  const { error, isLoading, sensorLogs, gatewayLogs } = logsData;
  const { logs, length } = sensorLogs;
  const { gateLogs } = gatewayLogs;
  const [showCalendar, setShowCalendar] = useState(false);
  const [isPicker, setIsPicker] = useState(false);
  const [noFoundDate, setNoFoundDate] = useState();

  const gateLogsVariant =
    type === "gateway_translator" || type === "gateway_rivata";
  const isLogs = logs.length !== 0 || gateLogs.length !== 0;
  const noLogsInfo = [classes.noLogsMsg];
  const headerCls = [classes.header];
  gateLogsVariant && headerCls.push(classes.gateLogsOpen);

  const calendarCls = [classes.calendar];
  gateLogsVariant && calendarCls.push(classes.gatewayCalendar);
  !showCalendar && calendarCls.push(classes.isClose);
  const calendarHeader = gateLogsVariant ? "date range" : "date";
  const handlerType = gateLogsVariant ? "dateRange" : "calendar";
  const inputValue = gateLogsVariant
    ? `${moment(date.fromDate).format("YYYY MMM Do")} - ${moment(
        date.toDate
      ).format("YYYY MMM Do")} `
    : `${moment(date.fromDate).format("YYYY MMM Do")} `;

  useEffect(() => {
    !isLogs && isLoading && setNoFoundDate({ ...date, ...time });
  }, [isLogs, showCalendar, date, time, isLoading]);

  const toggle = () => {
    isOpen && onSetDefaultData() && resetTable();
    onClose(!isOpen);
  };

  useEffect(() => {
    isPicker === true && setShowCalendar(false);
    setTimeout(() => {
      setIsPicker(false);
    }, 10);
  }, [isPicker, showCalendar]);

  const handleCalendar = (isShown) => {
    setShowCalendar(isShown);
  };

  const onResetForm = ()=> {
    handleCalendar(false)
    formReset(noFoundDate)
  }

  const onDataSet = () => {
    onSubmit(date);
    handleCalendar(false);
  };

  const onGatewayLogsPageChange = (delta) => {
    onLogsPageChange(delta);
    handleCalendar(false);
  };

  const contentType = () => {
    switch (type) {
      case "sensors":
        return <SensorLogsTable logs={logs} />;
      case "gateway_translator":
        return <GatewayLogsTranslator logs={gateLogs} />;
      case "gateway_rivata":
        return <GatewayLogsRivata logs={gateLogs} />;
      default:
        return null;
    }
  };
  const headerType = () => {
    switch (type) {
      case "gateway_translator":
        return <TranslatorHeader />;
      case "gateway_rivata":
        return <RivataHeader />;
      default:
        return null;
    }
  };

  const isLogsEmpty = isLogs ? (
    contentType()
  ) : (
    <span className={noLogsInfo.join(" ")}>
      <p>{`No log records found for ${moment(
        noFoundDate && noFoundDate.fromDate
      ).format("MMMM Do YYYY")} ${moment(noFoundDate && noFoundDate.from, [
        "HH.mm",
      ]).format("hh:mm A")} - ${
        gateLogsVariant
          ? moment(noFoundDate && noFoundDate.toDate).format("MMMM Do YYYY")
          : ""
      } ${moment(noFoundDate && noFoundDate.to, ["HH.mm"]).format(
        "hh:mm A"
      )}`}</p>
    </span>
  );
  const isError = error ? (
    <span className={classes.noLogsMsg}>{error}</span>
  ) : (
    isLogsEmpty
  );
  const onGateLogTypeSet = (choise) => {
    handleCalendar(false);
    setType(choise);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable={true}
        fade={true}
        className={classes.modal}
        modalClassName={classes.behind}
        contentClassName={classes.contentWrap}
        backdropTransition={{
          timeout: 700,
        }}
        modalTransition={{
          timeout: 500,
        }}
        unmountOnClose={false}
      >
        <ModalHeader toggle={toggle} className={headerCls.join(" ")}>
          <div className={classes.paginationWrapper}>
            {!gateLogsVariant && logs.length ? (
              <SensorLogsPagination
                logs={logs}
                length={length}
                logsCount={logsCount}
                isLoading={isLoading}
                onLogsPageChange={onLogsPageChange}
                onSelectLimit={onSelectLimit}
              />
            ) : (
              ""
            )}
            {gateLogsVariant && (
              <GatewayLogsPagination
                offset={offset}
                logsCount={logsCount}
                logsLength={gateLogs.length}
                type={type}
                onGatewayLogsPageChange={onGatewayLogsPageChange}
                setType={(choise) => onGateLogTypeSet(choise)}
              />
            )}
            <div className={classes.inputWrap}>
              <Input
                className={classes.formInput}
                onClick={() => handleCalendar("open")}
                placeholder={"Select Date"}
                value={inputValue}
                readOnly={true}
              />
              {gateLogsVariant && (
                <TimePickerGates
                  t={time}
                  setTime={setTime}
                  setIsPicker={setIsPicker}
                />
              )}
              <Button color={"secondary"} size="sm" onClick={onDataSet}>
                {"Submit"}
              </Button>
            </div>
          </div>
        </ModalHeader>
        {gateLogsVariant && headerType()}
        <ModalBody className={classes.modalBody}>
          <ul>{isLoading ? <RivataLoader /> : isError}</ul>
        </ModalBody>
        <ModalFooter></ModalFooter>
        <Fade in={showCalendar} className={calendarCls.join(" ")}>
          <ModalHeader
            tag="h5"
            toggle={onResetForm}
            className={classes.calendarHeader}
          >
            {`Pick your ${calendarHeader}`}
          </ModalHeader>
          <ModalBody>
            <DateHandler
              handlerType={handlerType}
              chosenDate={date.fromDate}
              setDate={setDate}
              type={type}
            />
          </ModalBody>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalLogInfo;
