import {
    LOADING_CUSTOMERS,
    SET_CUSTOMERS,
    SET_SELECTED_CUSTOMER,
    SET_CUSTOMERS_ERROR,
    SET_SYSTEM_DEFAULTS,
    SET_CUSTOMER_DEFAULTS,
    SET_VERSION_INFO,
    LOADING_DEFAULTS,
    SET_RECENT_WARNINGS_FILTER,
    SET_RECENT_DETAILS_OFFSET,
    SET_HIDE_AUTOGENERATED_FILTER
} from '../actionTypes'
import api from '../../services/api'
import { getToken, getErrorObj, getCurrentEpoch, sortByAlphabet } from "../../utils"
import appConfig from '../../config/appConfig';
import {setRecentWarningsOffset} from '../warnings/action'
import { store } from '../store'
import { AssociativeAxlesNames } from '../../enums';

export const loadingDefaults = (isLoading) => ({
    type: LOADING_DEFAULTS,
    payload: isLoading,
});

export const loadingCustomers = (isLoading) => ({
    type: LOADING_CUSTOMERS,
    payload: isLoading,
});

export const setCustomers = (config) => ({
    type: SET_CUSTOMERS,
    payload: config,
});

export const setSelectedCustomer = (customer) => ({
    type: SET_SELECTED_CUSTOMER,
    payload: customer
})

export const setCustomersError = (error) => ({
    type: SET_CUSTOMERS_ERROR,
    payload: error,
});


export const setSystemDefaults = (defaults) => ({
    type: SET_SYSTEM_DEFAULTS,
    payload: defaults,
})

export const setCustomerDefaults = (defaults) => ({
    type: SET_CUSTOMER_DEFAULTS,
    payload: defaults,
})

export const setVersionInfo = (versionInfo) => ({
    type: SET_VERSION_INFO,
    payload: versionInfo
})

export const setRecentDetailsOffset = (offset) => ({
    type: SET_RECENT_DETAILS_OFFSET,
    payload: offset
})

export const setHideAutogeneratedAssets = (payload) => ({
    type: SET_HIDE_AUTOGENERATED_FILTER,
    payload
})

export const fetchVersionInfo = () => {
    return function (dispatch, getState) {
        return api
            .getDataAboutApi(getToken(getState))
            .then((data = {}) => {
                if (data) {
                    data.branch = data.branch === "not set" ? "" : data.branch;
                    data.commit_uid = data.commit_uid === "not set" ? "" : data.commit_uid.substring(0, 8);
                }
                data.build_branch = appConfig.build_branch;
                data.build_fullhash = (appConfig.build_fullhash).substring(0, 8);
                dispatch(setVersionInfo(data))
            }).catch(err => {
            }).finally(() => { })
    }
}

const parseDefaults = (data) => {
    const { cold_inflation_pressure_in_psi, low_pressure_in_percent, critical_low_pressure_in_percent, over_pressure_in_percent, critical_over_pressure_in_percent } = data
    if (data.default_profile_axles_values) {
        const thresholdsPerAxle = data.default_profile_axles_values.reduce((accum, curr) => {
            const { axle, pressure_value } = curr
            accum.push({
                axle: AssociativeAxlesNames[axle],
                pressure_value
            })
            return accum
        }, [])
        
        return {
            cold_inflation_pressure_in_psi,
            low_pressure_in_percent,
            critical_low_pressure_in_percent,
            over_pressure_in_percent,
            critical_over_pressure_in_percent,
            thresholdsPerAxle
        }
    }
    
    return data
}

export const fetchDefaults = (customerId) => {
    return function (dispatch, getState) {
        dispatch(loadingDefaults(true))
        return api
            .getSettingsDefaults(getToken(getState), customerId)
            .then((res) => {
                const systemDefaults = res.system_defaults
                const customerDefaults = res.customer_defaults

                systemDefaults.tpms = parseDefaults(systemDefaults.tpms)
                customerDefaults.tpms = parseDefaults(customerDefaults.tpms)

                const validSubscriptions = {}
                const currentDate = getCurrentEpoch()

                Object.keys(customerDefaults.subscriptions).map((subKey) => {
                    const { valid_to } = customerDefaults.subscriptions[subKey]
                    if (currentDate <= valid_to) {
                        validSubscriptions[subKey] = customerDefaults.subscriptions[subKey]
                    }
                    return subKey
                })

                customerDefaults.subscriptions = validSubscriptions
                dispatch(setSystemDefaults(systemDefaults))
                dispatch(setCustomerDefaults(customerDefaults))
            })
            .catch((err) => console.log(err))
            .finally(() => dispatch(loadingDefaults(false)))
    }
}

const allOption = { id: null, name: "All" }

export function fetchCustomers(shouldPreselectRivata = false) {
    return function (dispatch, getState) {
        dispatch(loadingCustomers(true));
        return api
            .getCustomers(getToken(getState))
            .then((resCustomers) => {
                const selectedCustomer = getState().common.customers.selectedCustomer
                let sortedCustomers = sortByAlphabet(resCustomers, "name")
                let initialCustomer = resCustomers.find(el => el.id !== null) || resCustomers[0];
                const path = window.location.pathname.split("/")

                const addAllOption = !path.includes("tpms-profiles") && !path.includes("whitelabel") && !path.includes("thresholds") && !path.includes("calibrate-axle-load")

                if (shouldPreselectRivata) {
                    const rivataCustomer = resCustomers.find((c) => c.name === "Rivata");
                    initialCustomer = rivataCustomer || resCustomers.find(el => el.parent_id === null) || resCustomers[0];
                }
                else if (addAllOption && resCustomers.length > 1) {
                    initialCustomer = allOption;
                    sortedCustomers = [initialCustomer, ...resCustomers]
                }

                if (selectedCustomer && resCustomers.find((c) => c.id === selectedCustomer.id)) {
                    initialCustomer = selectedCustomer
                }

                dispatch(setSelectedCustomer(initialCustomer));
                dispatch(setCustomers(sortedCustomers));
                dispatch(setCustomersError(getErrorObj()))
            })
            .catch((err) => dispatch(setCustomersError(getErrorObj(err))))
            .finally(() => dispatch(loadingCustomers(false)));
    };
}

const routesWithBlockedAllOption = ["tpms-profiles", "whitelabel", "thresholds", "calibrate-axle-load"]

export const handleAppLocationChange = (pathname) => {
    return (dispatch, getState) => {
        const pathParts = pathname.split("/")
        const { data, selectedCustomer } = getState().common.customers
        let customersList = [ ...data ]
        let newSelectedCustomer = selectedCustomer
        let shouldContainsAllOption = true

        for (let i = 0; i < routesWithBlockedAllOption.length; i++) {
            if (pathParts.includes(routesWithBlockedAllOption[i])) {
                shouldContainsAllOption = false
                break
            }
        }

        const idx = customersList.findIndex(el => el.id === null && el.name === "All")

        if (shouldContainsAllOption && customersList.length > 1) {
            if (idx > -1) {
                return
            } else {
                customersList = [ allOption, ...customersList ]
            }
        } else {
            if (idx > -1) {
                customersList.splice(idx, 1)
                if (newSelectedCustomer && newSelectedCustomer.id === null && newSelectedCustomer.name === "All") {
                    const rivataCustomer = customersList.find((c) => c.name === "Rivata")
                    newSelectedCustomer = rivataCustomer || customersList.find(el => el.parent_id === null) || customersList[0]
                }
            } else {
                return
            }
        }
        
        dispatch(setCustomers(customersList))
        dispatch(setSelectedCustomer(newSelectedCustomer))
    }
}

store.subscribe(async () => {
    const lastAction = store.getState().lastAction
    const detailsOffset = store.getState().details.recentDetails.offset

    if (lastAction.type === SET_SELECTED_CUSTOMER) {
        const customer = lastAction?.payload
        if (customer?.id) {
            await store.dispatch(fetchDefaults(customer.id))
        }
    } 
   
    else if (lastAction.type === SET_RECENT_WARNINGS_FILTER) {
        store.dispatch(setRecentWarningsOffset(detailsOffset))
        store.dispatch(setRecentDetailsOffset(0))
    }
})