import React from 'react'
import { useField, Field } from 'formik'
import { Col, Input, FormFeedback, FormGroup, Label } from 'reactstrap'

const RivataFormField = ({
    className,
    md = "6",
    type = "text",
    children,
    label,
    ...props
}) => {
    const [field, meta] = useField(props)
    return (
        <Col md={md} className={className}>
            <Label>{label}</Label>
            <FormGroup className="mb-0">
                <Input
                    type={type}
                    {...field}
                    {...props}
                    tag={Field}
                    invalid={!!(meta.touched && meta.error)}
                >
                    {children}
                </Input>
                <FormFeedback>{meta.error}</FormFeedback>
            </FormGroup>
        </Col>
    )
}

export default RivataFormField