import React, { useMemo, useEffect, useState, useRef } from "react";
import moment from "moment";

import RivataLoader from "../../components/RivataLoader";
import RivataModule from "../../components/RivataModule";
import RivataLineChart from "../../components/RivataLineChart";
import RivataDropdown from "../../components/RivataDropdown";
import StatusAlert from "../../components/StatusAlert";

import { UnitsOfMeasurement, AxlesNames } from "../../enums";

import { composeTireChartData, drawPressureLinesLegend } from "./utils";
import composePressureChartAnnotations from "./annotations";
import { getThresholdsByAssignedProfile, psiToBar } from "../../utils";
import {
  getMotionAnnotations,
  composeCustomTooltip,
} from "../../utils/chartUtils";
import { useChartDataWithAxlesFilter } from "../../hooks/useChartHooks";

const id = "pressure";
const customTooltip = composeCustomTooltip({
  displayColors: true,
  borderColor: true,
  footer: true,
});

const TirePressureChart = ({
  isLoading,
  data,
  error,
  width,
  graphColors,
  locale,
  onZoomUpdate,
  setRef,
  onDoubleClick,
  timestamp,
  thresholds,
  setRange,
  xMin,
  xMax,
  preferences: { unitsOfMeasurement = UnitsOfMeasurement.imperial, timezone },
  healthColors,
  assetType,
  assignedTpmsProfile,
  speedData,
}) => {
  const [pressureThresholds, setPressureThresholds] = useState(null);
  const [selectedAxle, setSelectedAxle] = useState("all");

  const chartLinesLegend = useRef({ current: {} });

  useEffect(() => {
    drawPressureLinesLegend(chartLinesLegend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartLinesLegend.current, isLoading, selectedAxle]);

  const chartData = useMemo(() => {
    if (data && data.length && graphColors && graphColors.length) {
      return composeTireChartData(data, graphColors, unitsOfMeasurement);
    }
    return null;
  }, [data, graphColors, unitsOfMeasurement]);

  const filtredData = useChartDataWithAxlesFilter(
    chartData,
    xMax,
    xMin,
    selectedAxle
  );

  useEffect(() => {
    if (chartData) setRange({ min: chartData.xMin, max: chartData.xMax });
    else setRange({ min: null, max: null });
  }, [chartData, setRange]);

  useEffect(() => {
    if (!thresholds || selectedAxle === "all")
      return setPressureThresholds(null);

    let obj = {};

    if (
      Object.keys(assignedTpmsProfile).length &&
      assignedTpmsProfile.thresholds &&
      assetType &&
      assignedTpmsProfile.thresholds[assetType] &&
      assignedTpmsProfile.thresholds[assetType][AxlesNames[selectedAxle]]
    ) {
      obj = {
        ...assignedTpmsProfile.thresholds[assetType][AxlesNames[selectedAxle]],
      };
    } else {
      const percentThresholds = {
        critical_low_pressure_in_percent:
          thresholds.critical_low_pressure_in_percent,
        critical_over_pressure_in_percent:
          thresholds.critical_over_pressure_in_percent,
        low_pressure_in_percent: thresholds.low_pressure_in_percent,
        over_pressure_in_percent: thresholds.over_pressure_in_percent,
      };

      const pressureValue = thresholds.thresholdsPerAxle
        ? thresholds.thresholdsPerAxle.find((el) => el.axle === selectedAxle)
            ?.pressure_value
        : thresholds.cold_inflation_pressure_in_psi;

      obj = getThresholdsByAssignedProfile(
        UnitsOfMeasurement.imperial,
        pressureValue,
        percentThresholds,
        thresholds.cold_inflation_pressure_in_psi
      );
    }

    if (unitsOfMeasurement === UnitsOfMeasurement.metric) {
      Object.keys(obj).map((key) => {
        return (obj[key] = psiToBar(obj[key]));
      });
    }
    setPressureThresholds(obj);
  }, [
    thresholds,
    unitsOfMeasurement,
    assignedTpmsProfile,
    selectedAxle,
    assetType,
  ]);

  const assetMotionAnnotations = useMemo(() => {
    return getMotionAnnotations(speedData, "pressure");
  }, [speedData]);

  const annotations = useMemo(() => {
    if (pressureThresholds) {
      return composePressureChartAnnotations(
        id,
        pressureThresholds?.critical_over_pressure,
        pressureThresholds?.over_pressure,
        pressureThresholds?.low_pressure,
        pressureThresholds?.critical_low_pressure,
        timestamp,
        healthColors
      );
    }

    return composePressureChartAnnotations(
      id,
      null,
      null,
      null,
      null,
      timestamp,
      healthColors,
      true
    );
  }, [pressureThresholds, healthColors, timestamp]);

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title="Tire Pressure"
      width={width}
      locale={locale}
      error={error}
      filters={
        <div>
          <label className="mr-2">Select Axle:</label>
          <RivataDropdown
            items={filtredData?.dropdownIntems}
            selected={selectedAxle}
            onSelect={(id) => {
              if (id !== selectedAxle) {
                setSelectedAxle(id);
                onDoubleClick(); // reset zoom
              }
            }}
          />
        </div>
      }
      collapsible
    >
      {isLoading ? (
        <RivataLoader />
      ) : filtredData.chartData.datasets.length ? (
        <div className="position-relative" style={{ height: "420px" }}>
          <RivataLineChart
            id={id}
            legendPostion={"top"}
            data={filtredData.chartData}
            height={400}
            threshold={pressureThresholds?.critical_over_pressure}
            xMin={xMin}
            xMax={xMax}
            onDoubleClick={onDoubleClick}
            customTooltip={customTooltip}
            annotations={annotations}
            onZoomUpdate={onZoomUpdate}
            setRef={setRef}
            xAxisLabel={`Date (${moment().tz(timezone).format("z")})`}
            assetMotionAnnotations={assetMotionAnnotations}
            legendLabel={`Tire Pressure (${
              unitsOfMeasurement === UnitsOfMeasurement.imperial ? "psi" : "bar"
            })`}
          />
          <canvas
            ref={chartLinesLegend}
            className={`position-absolute ${
              selectedAxle === "all" ? "d-none" : ""
            }`}
            style={{ left: "5%" }}
          />
        </div>
      ) : (
        <StatusAlert customText="No Data" color="success" />
      )}
    </RivataModule>
  );
};

export default TirePressureChart;
