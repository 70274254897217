import { debounce, isPlainObject, isInteger, isNumber } from "lodash";
import moment from "moment-timezone";
import { store } from '../redux/store'
import {
  Timezones,
  WheelOrder,
  UnitsOfMeasurement,
  HardwareType
} from "../enums";
import { INSTALLMENT_POSITION_TYPE } from '../constants'
import { AxlesGroups } from '../constants/constants'
import defaultWhiteLabelConfig from '../redux/whitelabel/defaultConfig';

export const TIME_FORMAT = "YYYY MM DD";
export const LABEL_TIME_FORMAT = "MMM D";
export const DATE_FORMAT = "MM/DD/YYYY hh:mm A";
export const ONLY_DATE = "MM/DD/YYYY";
export const ONLY_TIME = "hh:mm A";

export const dateFormat = (date, format = LABEL_TIME_FORMAT) =>
  moment(date).format(format);

export const sortPositions = positions => {
  return positions.sort((d1, d2) => {
    return WheelOrder[d1] - WheelOrder[d2];
  })
};

export const fahrToCelsius = (fahrein) => ((fahrein - 32) * 5.0) / 9;
export const celsiusToFahr = (celsius) => (celsius * 9) / 5.0 + 32;
export const psiToBar = (psi) => {
  if (psi === 0) return psi
  const bar = psi * 0.0689475729
  if (Number.isNaN(bar) || bar === 0) return ""
  return bar
}
export const barToPsi = (bar) => bar / 0.0689475729
export const kmhToMph = (kmh) => kmh * 0.621371
export const metersToFt = (m) => m * 3.2808
// export const getAxleLoad = (kpa) => (kpa * 0.145 / 2.205).toFixed(2)
export const kpaToLbs = (kpa) => kpa * 0.145
export const kpaToKg = (kpa) => kpa * 0.0102
export const kgToLbs = (kg) => kg * 2.2046226218
export const lbsToKg = (lbs) => lbs * 0.45359237
export const metersToKmh = (m) => m / 1000
export const metersToMph = (m) => m * 0.00062137

export const getMileage = (mileage, unitsOfMeasurement) => {
  let result = 0;

  if (unitsOfMeasurement === "metric") {
    result = metersToKmh(mileage);
  } else {
    result = metersToMph(mileage);
  }

  return result.toFixed(0);
};

const convertDataArrEpochToDate = (
  rootData,
  epochKey,
  outputDateKey = "datetime"
) => {
  return rootData.map((data) => {
    return {
      ...convertDataObjEpochToDate(data, epochKey, outputDateKey)
    }
  })
}

const convertDataObjEpochToDate = (
  data,
  epochKey,
  outputDateKey = "datetime"
) => {
  const datetime = data[epochKey] ? moment.unix(data[epochKey]).tz(store.getState().auth.preferences.timezone || "America/Los_Angeles") : null;
  return {
    ...data,
    [outputDateKey]: datetime,
    [`formatted_${outputDateKey}`]: datetime ? datetime.format(DATE_FORMAT) : "-"
  }
}

const convertEpochToDate = (epoch, shouldFormat, addBreak, convertToLocalDate = false, showOnlyDate) => {
  let datetime;
  if (convertToLocalDate) {
    datetime = moment.unix(epoch).local();
  }
  else {
    datetime = moment.unix(epoch).tz(store.getState().auth.preferences.timezone || "America/Los_Angeles");
  }

  if (addBreak) {
    return `${datetime.format(ONLY_DATE)} <br/> ${datetime.format(ONLY_TIME)}`
  }

  if (shouldFormat) {
    return datetime.format(DATE_FORMAT);
  }

  if (showOnlyDate) {
    return datetime.format(ONLY_DATE);
  }

  return datetime;
}

export const convertDataEpochToDate = (data, epochKey, outputDateKey, shouldFormat, addBreak, toLocalDate = false, showOnlyDate) => {
  if (Array.isArray(data)) {
    return convertDataArrEpochToDate(data, epochKey, outputDateKey);
  }
  else if (typeof data === "object") {
    return convertDataObjEpochToDate(data, epochKey, outputDateKey);
  }
  else if (typeof data === "number") {
    return convertEpochToDate(data, shouldFormat, addBreak, toLocalDate, showOnlyDate);
  }
}

export const dateToTimezoneString = (date, timezone) => {
  let timezoneName = Timezones.AMERICA_LOS_ANGELES
  if (timezone in Timezones) {
    timezoneName = timezone
  }

  var timestamp = Date.parse(date);
  // validate using timestamp
  if (isNaN(timestamp) === false) {
    const d = new Date(date).toLocaleString("en-US", {
      timeZone: timezoneName,
    });
    return d;
  }
  // no data
  return null;
};

export const dateToEpoch = (dateFrom = new Date(), dateTo = new Date(), startTime = "00:00", endTime = "23:59:59") => {
  const timezone = store.getState().auth.preferences.timezone || "America/Los_Angeles"
  const startDate = moment.tz(`${moment(dateFrom).format("YYYY-MM-DD")} ${startTime}`, timezone).unix()
  const endDate = moment.tz(`${moment(dateTo).format("YYYY-MM-DD")} ${endTime}`, timezone).unix()

  return {
    startDate,
    endDate
  }
}

export const handleTimestampsRoot = (
  preferences,
  rootData,
  key = "timestamp"
) => {
  return rootData.map((data) => ({
    ...data,
    [key]: dateToTimezoneString(data[key], preferences.timezone),
    [`original_${key}`]: data[key]
  }));
};

const sortDataByPositions = (data) => {
  if (Array.isArray(data)) {
    return [...data].sort((d1, d2) => {
      return WheelOrder[d1.label] - WheelOrder[d2.label];
    })
  }
  return data
}

export const getFormattedDataForVibrationChart = (data, isReversed = false) => {
  const formattedData = []
  const keys = Object.keys(data)
  keys.map((key) => {
    const line = { label: key, data: [] }
    data[key].map((itemData) => {
      const data = {
        value: itemData[1],
        displayDate: convertDataEpochToDate(itemData[0], null, null, true),
        chartTAxis: convertDataEpochToDate(itemData[0], null, null, false),
      }
      return line.data.push(data)
    })
    return formattedData.push(line)
  })

  const sortedData = sortDataByPositions(formattedData)
  if (isReversed) return sortedData.reverse()
  return sortedData
}

export const getFormattedDataForLineChart = (data, isReversed = false) => {
  let formattedData = []

  data.map((item) => {
    const lineObj = { id: item[0], label: item[1], data: [] }

    item[2].map((itemData) => {
      let obj = {
        id: item[0],
        displayDate: convertDataEpochToDate(itemData[0], null, null, true),
        chartTAxis: convertDataEpochToDate(itemData[0], null, null, false),
        avg: itemData[1],
      }
      return lineObj.data.push(obj)
    })

    return formattedData.push(lineObj)
  })

  const uniqueDataArray = formattedData[0] ? [formattedData[0]] : []
  for (let i = 1; i < formattedData.length; i++) {
    const idx = uniqueDataArray.findIndex((el) => el.label === formattedData[i].label ? true : false)
    if (idx > -1) {
      uniqueDataArray[idx].data = uniqueDataArray[idx].data.concat(formattedData[i].data)
    } else {
      uniqueDataArray.push(formattedData[i])
    }
  }

  const sortedData = sortDataByPositions(uniqueDataArray)

  if (isReversed) return sortedData.reverse()
  return sortedData
}

export const getClearLabel = (str) => {
  let label = str.toLowerCase().split("_").join(" ")
  label = label.split("")

  label[0] = label[0].toUpperCase()

  label.map((char, i) => {
    if (char === " ") {
      label[i + 1] = label[i + 1].toUpperCase()
    }
    return char
  })
  let res = label.join("")
  
  if(res.includes("Tpms")){
    res= res.replace("Tpms", "TPMS")
  }
  return res
}

export const getColor = (label, graphColors) => {
  const el = graphColors.find((item) => {
    if (item.label === label) {
      return true
    }
    return false
  })

  if (el) {
    return el.color
  } else { // random color
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}

export const hasData = (data) => !!(data && data.length);

export const compare = (v1, v2) => {
  return v1 > v2 ? 1 : v2 > v1 ? -1 : 0;
};

export const getToken = (getState) => getState().auth.credentials.idToken;

export const debouncer = debounce(f => f(), 125, { leading: true });

export const getWhitelabelColors = (healthColors = []) => healthColors.length ? {
  healthGood: healthColors[0].color,
  healthWarning: healthColors[1].color,
  healthCriticalWarning: healthColors[2].color
} : {}

export const getColdInflationFromPercent = (coldInflation, percent) => {
  return coldInflation + ((coldInflation * percent) / 100);
}

export const getThresholdsByAssignedProfile = (unitsOfMeasurement, cold_inflation_pressure_in_psi, tpmsThresholds, profileDefaultPressureInPsi) => {
  let cold_presure_in_psi = cold_inflation_pressure_in_psi || profileDefaultPressureInPsi
  let cold_presure_in_bar = psiToBar(cold_inflation_pressure_in_psi) || psiToBar(profileDefaultPressureInPsi)

  return {
    critical_low_pressure:
      unitsOfMeasurement === UnitsOfMeasurement.imperial
        ? getColdInflationFromPercent(cold_presure_in_psi, tpmsThresholds.critical_low_pressure_in_percent)
        : getColdInflationFromPercent(cold_presure_in_bar, tpmsThresholds.critical_low_pressure_in_percent),
    critical_over_pressure:
      unitsOfMeasurement === UnitsOfMeasurement.imperial
        ? getColdInflationFromPercent(cold_presure_in_psi, tpmsThresholds.critical_over_pressure_in_percent)
        : getColdInflationFromPercent(cold_presure_in_bar, tpmsThresholds.critical_over_pressure_in_percent),
    low_pressure:
      unitsOfMeasurement === UnitsOfMeasurement.imperial
        ? getColdInflationFromPercent(cold_presure_in_psi, tpmsThresholds.low_pressure_in_percent)
        : getColdInflationFromPercent(cold_presure_in_bar, tpmsThresholds.low_pressure_in_percent),
    over_pressure:
      unitsOfMeasurement === UnitsOfMeasurement.imperial
        ? getColdInflationFromPercent(cold_presure_in_psi, tpmsThresholds.over_pressure_in_percent)
        : getColdInflationFromPercent(cold_presure_in_bar, tpmsThresholds.over_pressure_in_percent),
  }
}

export const getTpmsTirePressure = (value, unitsOfMeasurement) => {
  let label = ""
  if (value === "" || value < 0) {
    label = "-"
  } else if (unitsOfMeasurement === UnitsOfMeasurement.imperial) {
    if (isInteger(value)) {
      label = value
    } else if (isNumber(value)) {
      label = value.toFixed(2)
    } else {
      label = value
    }
  } else if (unitsOfMeasurement === UnitsOfMeasurement.metric) {
    if (value === 0) {
      label = value
    } else if (isNumber(value)) {
      label = parseFloat(psiToBar(value).toFixed(2))
    } else {
      label = value
    }
  } else {
    label = "-"
  }

  return label
}

export const getSensorFromSensorType = (sensorType) => {
  if (!sensorType){
    return "-";
  }

  if (sensorType === "gateway") {
    return "Gateway"
  }
  else if (sensorType === "tire_sensor") {
    return "TPMS"
  }
  else if (sensorType === "hub_sensor") {
    return "SmartHub"
  }
  else if (sensorType === "axle_load") {
    return "Axle load"
  }
  else if (sensorType === "line_pressure") {
    return "Line Pressure"
  }
  return "Others";
}

export const getClearWheelPosition = (wheelData) => {
  const clearData = []
  wheelData.map((item) => {
    const positions = item.label.split(" OR ")
    const clearObj1 = { ...item, label: positions[0] }
    const clearObj2 = { ...item, label: positions[1] }
    if (positions[1]) {
      clearData.push(clearObj2)
    }
    return clearData.push(clearObj1)
  })

  return clearData
}

export const upperCaseFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const getErrorObj = (err = { statusCode: 0, message: "" }, customMessage) => ({
  statusCode: err.statusCode,
  message: customMessage ? customMessage : err.message.toUpperCase(),
  ok: false
})

export const getActualConfig = (data) => {
  if (!data) return defaultWhiteLabelConfig;
  const config = {}
  const configKeys = Object.keys(defaultWhiteLabelConfig)

  configKeys.map((key) => {
    if (isPlainObject(defaultWhiteLabelConfig[key])) {
      const newObj = {}
      if (data[key] !== defaultWhiteLabelConfig[key]) {
        const preferenceKeys = Object.keys(defaultWhiteLabelConfig[key])
        preferenceKeys.map((prefKey) => {
          if (data[key][prefKey]) {
            newObj[prefKey] = data[key][prefKey]
          } else {
            newObj[prefKey] = defaultWhiteLabelConfig[key][prefKey]
          }
          return prefKey
        })
      }
      return config[key] = newObj
    }
    if (Array.isArray(defaultWhiteLabelConfig[key])) {
      const newComponents = []
      config[key] = []
      if (key === "wheelColors") {
        config[key] = data[key]
        return key
      }
      defaultWhiteLabelConfig[key].map((item) => {
        const elementIdx = data[key].findIndex((el) => el.id === item.id ? true : false)
        if (elementIdx > -1) {
          config[key][elementIdx] = data[key][elementIdx]
        } else {
          newComponents.push(item)
        }
        return item
      })

      if (newComponents.length) {
        config[key] = [ ...config[key], ...newComponents ]
      }
    }
    return key
  })

  return config
}

export const composeTpmsProfiles = (warningSettings) => {
  const tractorValidAxles = [
    INSTALLMENT_POSITION_TYPE.TRACTOR_STEER,
    INSTALLMENT_POSITION_TYPE.TRACTOR_FRONT,
    INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_1,
    INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_2,
    INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_3
  ]

  const trailerValidAxles = [
    INSTALLMENT_POSITION_TYPE.TRAILER_1,
    INSTALLMENT_POSITION_TYPE.TRAILER_2,
    INSTALLMENT_POSITION_TYPE.TRAILER_3,
    INSTALLMENT_POSITION_TYPE.TRAILER_4,
    INSTALLMENT_POSITION_TYPE.TRAILER_5,
    INSTALLMENT_POSITION_TYPE.TRAILER_6,
  ]

  const profiles = []
  Object.keys(warningSettings).map(key => {
    const s = warningSettings[key];
    const tpmsProfile = s.settings.find(c => c.installment_position_type_id == null)?.profile;
    const axlesSettings = s.settings.filter(c => c.installment_position_type_id !== null).sort(c => c.installment_position_type_id).reverse()

    const tractorAxles = axlesSettings.filter(c => tractorValidAxles.includes(c.installment_position_type_id));
    const trailerAxles = axlesSettings.filter(c => trailerValidAxles.includes(c.installment_position_type_id));

    let profile = {
      id: key,
      settings_id: key,
      label: s.name,
      name: s.name,
      low_pressure_in_percent: tpmsProfile.low_pressure_in_percent,
      critical_low_pressure_in_percent: tpmsProfile.critical_low_pressure_in_percent,
      over_pressure_in_percent: tpmsProfile.over_pressure_in_percent,
      critical_over_pressure_in_percent: tpmsProfile.critical_over_pressure_in_percent,
      over_temperature_degrees_in_f: tpmsProfile.over_temperature_degrees_in_f,
      axels: {
        tractor: {
          tractor_axle_1: "",
          tractor_axle_2: "",
          tractor_axle_3: "",
          tractor_axle_4: "",
          tractor_axle_5: ""
        },
        trailer: {
          trailer_axle_1: "",
          trailer_axle_2: "",
          trailer_axle_3: "",
          trailer_axle_4: "",
          trailer_axle_5: "",
          trailer_axle_6: "",
        },
        tractorViewAxlesCount: tractorAxles.length,
        trailerViewAxlesCount: trailerAxles.length,
        hasTractor: tractorAxles.length > 0 ? true : false,
        hasTrailer: trailerAxles.length > 0 ? true : false,
      },
    }

    tractorAxles.forEach(element => {
      const coldInflationPressure = element?.profile.cold_inflation_pressure_in_psi || 0;
      switch (element.installment_position_type_id) {
        case INSTALLMENT_POSITION_TYPE.TRACTOR_STEER:
          profile.axels.tractor.tractor_axle_1 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRACTOR_FRONT:
          profile.axels.tractor.tractor_axle_2 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_1:
          profile.axels.tractor.tractor_axle_3 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_2:
          profile.axels.tractor.tractor_axle_4 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRACTOR_REAR_3:
          profile.axels.tractor.tractor_axle_5 = coldInflationPressure
          break;
        default:
          break;
      }
    });

    trailerAxles.forEach(element => {
      const coldInflationPressure = element?.profile.cold_inflation_pressure_in_psi || 0;
      switch (element.installment_position_type_id) {
        case INSTALLMENT_POSITION_TYPE.TRAILER_1:
          profile.axels.trailer.trailer_axle_1 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRAILER_2:
          profile.axels.trailer.trailer_axle_2 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRAILER_3:
          profile.axels.trailer.trailer_axle_3 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRAILER_4:
          profile.axels.trailer.trailer_axle_4 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRAILER_5:
          profile.axels.trailer.trailer_axle_5 = coldInflationPressure
          break;
        case INSTALLMENT_POSITION_TYPE.TRAILER_6:
          profile.axels.trailer.trailer_axle_6 = coldInflationPressure
          break;
        default:
          break;
      }
    })
    return profiles.push(profile)
  })

  return profiles
}

export const validateDatetimeRange = (existingCalibrationData, formValues) => {
  const { dateStart } = formValues

  // get epochs based on user selected timezone 
  const dates = dateToEpoch(dateStart)
  const { startDate, endDate } = dates

  const validationResult = existingCalibrationData.map((data) => {
    const alreadyExistingStart = data.timestamp_from
    const alreadyExistingEnd = data.timestamp_till
    const currentEpoch = getCurrentEpoch()

    if (alreadyExistingEnd > currentEpoch) {
      return false
    }

    if (
      (alreadyExistingStart >= startDate && alreadyExistingEnd <= endDate) ||
      (alreadyExistingEnd >= startDate && alreadyExistingStart <= startDate) ||
      (alreadyExistingEnd >= endDate && alreadyExistingStart <= endDate) ||
      (alreadyExistingStart <= startDate && alreadyExistingEnd >= endDate)
    ) {
      return true
    }

    return false
  })

  if (validationResult.includes(true)) {
    return {}
  }

  return {
    rangeStart: startDate
  }
}

export const getCurrentEpoch = () => {
  return moment().tz(store.getState().auth.preferences.timezone || "America/Los_Angeles").unix()
}

export const getEpochWithEndDay = (epoch) => {
  const timezone = store.getState().auth.preferences.timezone || "America/Los_Angeles"
  if (epoch) {
    return moment.unix(epoch).tz(timezone).endOf('day').unix()
  }
  return moment().tz(timezone).endOf('day').unix()
}

export const addDaysToEpoch = (epoch, days = 1) => {
  return moment.unix(epoch).tz(store.getState().auth.preferences.timezone || "America/Los_Angeles").add(days, "days").unix()
}

export const addHoursToEpoch = (epoch, hours = 1) => {
  return moment.unix(epoch).tz(store.getState().auth.preferences.timezone || "America/Los_Angeles").add(hours, "hours").unix()
}

export const getEpochsDifferenceInDays = (epoch1, epoch2) => {
  const admission = moment.unix(epoch1).tz(store.getState().auth.preferences.timezone || "America/Los_Angeles")
  const discharge = moment.unix(epoch2).tz(store.getState().auth.preferences.timezone || "America/Los_Angeles")
  const days = discharge.diff(admission, 'days')

  return days
}

export const sortByAlphabet = (data, sortKey) => data.sort((a, b) => a[sortKey].localeCompare(b[sortKey], 'en', { sensitivity: 'base' }))

export const containsOnlyNumbers = (string) => /^\d+$/.test(string);

export const removeSpecialCharacters = (string = "") => string.replace(/[^a-zA-Z0-9]/g, "");

export const composeLocationFilterOptions = (sensor_info) => {
  const { smarthub_sensors, line_pressure, tpms } = sensor_info
  let sensorInfo = []

  if (smarthub_sensors) {
    if (isPlainObject(smarthub_sensors)) {
      Object.keys(smarthub_sensors).forEach(key => {
        sensorInfo.push({ mac: smarthub_sensors[key], position: key })
      }) 
    } else {
      sensorInfo = [ ...smarthub_sensors ]
    }
  }
  if (tpms) {
    if (isPlainObject(tpms)) {
      Object.keys(tpms).forEach(key => {
        sensorInfo.push({ mac: tpms[key], position: key })
      }) 
    } else {
      sensorInfo = [ ...sensorInfo, ...tpms ]
    }
  }
  if (line_pressure) {
    if (isPlainObject(line_pressure)) {
      Object.keys(line_pressure).forEach(key => {
        sensorInfo.push({ mac: line_pressure[key], position: "CHASSIS" })
      }) 
    } else {
      sensorInfo = [ ...sensorInfo, ...tpms, ...line_pressure ]
    }
  }
  return sensorInfo
}

export const getTimeLabelFromSeconds = (seconds) => {
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;

  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let timeLabel = "";

  if (seconds) {
    let secondsText = seconds === 1 ? " second" : " seconds";
    timeLabel = seconds + secondsText;
  }

  if (minutes) {
    let minutesText = minutes === 1 ? " minute, " : " minutes, ";
    timeLabel = minutes + minutesText + timeLabel;
  }

  if (hours) {
    let hoursText = hours === 1 ? " hour, " : " hours, ";
    timeLabel = hours + hoursText + timeLabel;
  }

  if (days) {
    let daysText = days === 1 ? " day, " : " days, ";
    timeLabel = days + daysText + timeLabel;
  }

  return timeLabel.replace(/,\s*$/, "").trim();
};

export const checkIfSensorInNoStatus = (epoch, hardwareType) => {
  let interval = 0;
  if (hardwareType === HardwareType.TIRE_SENSOR){
    interval = 60 * 60 * 24; //24 hours
  }
  else if (hardwareType === HardwareType.HUB_SENSOR){
    interval = 60 * 60 * 24 * 7; //7 days
  }
  else if (hardwareType === HardwareType.GATEWAY){
    interval = 60 * 60 * 24 * 2; // 48 hours
  }

  return epoch <= moment().unix() - interval;
}

export const getProfilePsiLevel = (position) => {
  const tpms = store.getState().common.customerDefaults.tpms
  if (tpms) {
    const { cold_inflation_pressure_in_psi, thresholdsPerAxle } = tpms
    
    if (thresholdsPerAxle) {
      for (const key in AxlesGroups) {
        if (AxlesGroups[key].includes(getClearLabel(position))) {
  
            return thresholdsPerAxle.find(el => el.axle === key).pressure_value
        }
      }
    }
    
    return cold_inflation_pressure_in_psi
  }
}

export const parseSecondsToDateParts = (seconds) => {
  const dayInSeconds = 86400;
  const hourInSeconds = 3600;
  const minuteInSeconds = 60;

  const days = Math.floor(seconds / dayInSeconds);
  seconds  -= days * dayInSeconds;
  const hours   = Math.floor(seconds / hourInSeconds);
  seconds  -= hours * hourInSeconds;
  const minutes = Math.floor(seconds / minuteInSeconds);
  seconds  -= minutes * minuteInSeconds;

  const fDays    = `${days} ${days > 1 ? 'days': 'day'}`
  const fHours   = String(hours).padStart(2, '0');
  const fMinutes = String(minutes).padStart(2, '0');
  const fSeconds = String(seconds).padStart(2, '0');

  return {formatted: {fDays, fHours, fMinutes, fSeconds}, parsed: {days, hours, minutes, seconds}}
}