import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CustomersDropdown from "../../components/CustomersDropdown";
import SubNav from "../../components/SubNav";
import { useActions } from "../../hooks/useActions";
import GeoLocations from "../../modules/GeoLocations";
import { setSelectedCustomer } from "../../redux/common/action";
import {
  assignGatewayToLocation,
  createLocation,
  deleteLocation,
  fetchGeolocations,
  fetchGeolocationsCoordinates,
  setLocationsOffset,
  unnasignGatewayFromlocation,
  updateLocation,
  setLocationsOrder
} from "../../redux/geolocations/action";

const GeoLocationsPage = ({
  locale = {},
  fetchGeolocations,
  geolocations,
  onLocationCreate,
  onLocationDelete,
  onLocationUpdate,
  onGatewayUnnasign,
  setSelectedCustomer,
  onGatewayAssign,
  setLocationsOrder,
  auth: { isSuperAdmin = false },
  common: { customers },
  setLocationsOffset,
  fetchGeolocationsCoordinates,
  preferences
}) => {
  const { fetchGeolocationsPage } = useActions() 

  useEffect(() => {
    fetchGeolocationsPage()
  }, [fetchGeolocationsPage])

  return (
    <>
      <SubNav
        title="Tag Locations"
        breadcrumbs={[
          { label: "Home", path: "/dashboard" },
          { label: "Tag Locations" },
        ]}
      >
        {isSuperAdmin && customers.selectedCustomer && customers.data ? (
          <CustomersDropdown
            isLoading={customers.loading}
            customers={customers.data}
            locale={locale}
            initial={customers.selectedCustomer}
            label={locale.Customer || "Customer"}
            onChange={(customer) => {
              setSelectedCustomer(customer);
              fetchGeolocations();
              fetchGeolocationsCoordinates();
              setLocationsOffset(0);
            }}
          />
        ) : null}
      </SubNav>
      <Link
        className="btn btn-primary mt-2"
        style={{ marginLeft: 16 }}
        to={`/geolocations/tags`}
      >
        Show Tag Details
      </Link>
      <GeoLocations
        setLocationsOffset={setLocationsOffset}
        locationsCount={geolocations.totalCount}
        limit={geolocations.limit}
        locale={locale}
        data={geolocations.data}
        geolocationsCoordinatesData={geolocations.coordinatesData}
        width={12}
        setLocationsOrder={setLocationsOrder}
        onCreate={onLocationCreate}
        onDelete={onLocationDelete}
        onUpdate={onLocationUpdate}
        onGatewayUnnasign={onGatewayUnnasign}
        onGatewayAssign={onGatewayAssign}
        isLoading={geolocations.isLoading}
        selectedCustomer={customers.selectedCustomer}
        preferences={preferences}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  locale: state.whitelabel.locale,
  geolocations: state.geolocations,
  preferences: state.auth.preferences,
  auth: state.auth,
  common: state.common,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGeolocations: () => dispatch(fetchGeolocations()),
  fetchGeolocationsCoordinates: () => dispatch(fetchGeolocationsCoordinates()),
  onLocationCreate: (data) => dispatch(createLocation(data)),
  onLocationDelete: (id) => dispatch(deleteLocation(id)),
  onLocationUpdate: (id, data) => dispatch(updateLocation(id, data)),
  onGatewayUnnasign: (gatewayEsn) =>
    dispatch(unnasignGatewayFromlocation(gatewayEsn)),
  onGatewayAssign: (data) => dispatch(assignGatewayToLocation(data)),
  setSelectedCustomer: (c) => dispatch(setSelectedCustomer(c)),
  setLocationsOffset: (offset) => dispatch(setLocationsOffset(offset)),
  setLocationsOrder: (order)=>dispatch(setLocationsOrder(order))
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoLocationsPage);
