import React, { useState } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataLoader from '../../components/RivataLoader'
import StatusAlert from '../../components/StatusAlert'
import AxleLoadCalibrationForm from '../../components/AxleLoadCalibrationForm'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Col } from 'reactstrap'
import { getCurrentEpoch } from '../../utils'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const CalibrateAxleLoadModule = ({
    isSuperAdmin,
    subscriptions,
    isDefaultsLoading,
    axleLoadAssets: { isLoading, data, error },
    existingCalibrationData,
    locale = {},
    onSubmit,
    setSelectedAsset,
    selectedAsset,
    setSelectedUnitsOfMeasurement,
    selectedUnitsOfMeasurement,
}) => {
    const [ formValues, setFormValues ] = useState({
        value: null,
        isLoaded: 0,
        dateStart: new Date()
    })

    return (
        <RivataModule
            title="Calibrate Axle Load"
            locale={locale}
            filters={
                <Col md="4">
                    <Typeahead
                        id="manofold_assets_search"
                        onChange={(selected) => {
                            setSelectedAsset(selected[0])
                        }}
                        labelKey="vin"
                        options={data}
                        placeholder={"Select VIN"}
                        disabled={isLoading}
                    />
                </Col>
            }
        >
            {isLoading && <RivataLoader />}
            {(!subscriptions || !subscriptions.AXLE_LOAD || subscriptions.AXLE_LOAD.valid_to < getCurrentEpoch()) && !isSuperAdmin ? (
                <>
                    {isDefaultsLoading ? 
                        <RivataLoader />
                    : (
                        <StatusAlert customText="No Active Subscription Found" color="success" />
                    )}
                </>
            ) : !isLoading && !data.length ? (
                <StatusAlert color="success" customText="No Data" />
            ) : (
                <AxleLoadCalibrationForm 
                    data={data}
                    existingCalibrationData={existingCalibrationData}
                    unitsOfMeasure={selectedUnitsOfMeasurement}
                    onChangeUnitsOfMeasure={(units) => setSelectedUnitsOfMeasurement(units)}
                    formValues={formValues}
                    setFormValues={(values) => setFormValues(values)}
                    onSubmit={onSubmit}
                    selectedAsset={selectedAsset}
                    error={error}
                />
            )}
        </RivataModule>
    )
}

export default CalibrateAxleLoadModule