import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import './style.scss'


const LimitDropdown = ({
  dateWindow, 
  updateTable, 
  pageLimit, 
  setPageLimit, 
  limitDropdownItems = [
    { item: 10 },
    { item: 30 },
    { item: 90 },
  ]
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

    const handleSelectLimit = (num) => {
        if (pageLimit !== num) {
          setPageLimit(num);
          if (updateTable) {
            updateTable(dateWindow, 0, num);
          }
        }
    }

    return (
        <Dropdown setActiveFromChild={true} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <DropdownToggle color="#525f7f" className="page-limit__dropdown" caret>
              { pageLimit }
            </DropdownToggle>
            <DropdownMenu right>
              {limitDropdownItems.map((el) => {
                return <DropdownItem key={el.item} onClick={() => handleSelectLimit(el.item)}>{el.item}</DropdownItem>
              })}
            </DropdownMenu>
        </Dropdown>
    )
}

export default LimitDropdown