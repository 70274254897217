import { SET_GEOLOCATIONS, SET_GEOLOCATIONS_ORDER, SET_GEOLOCATIONS_TOTAL_COUNT, SET_GEOLOCATIONS_OFFSET, SET_GEOLOCATIONS_COORDINATES_DATA, SET_GEOLOCATIONS_LOADING } from "../actionTypes";

const initialState = {
  data: [],
  coordinatesData: [],
  limit: 10,
  offset: 0,
  totalCount: 0,
  error: false,
  isLoading: false,
  sortOptions: {
    column: null,
    direction: null
  },
};

const geolocations = (state = initialState, action) => {
  switch (action.type) {
    case SET_GEOLOCATIONS:
      return { ...state, data: action.payload };
    case SET_GEOLOCATIONS_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case SET_GEOLOCATIONS_OFFSET:
      return { ...state, offset: action.payload };
    case SET_GEOLOCATIONS_COORDINATES_DATA:
      return { ...state, coordinatesData: action.payload };
    case SET_GEOLOCATIONS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_GEOLOCATIONS_ORDER:
      return { ...state, sortOptions: action.payload };
    default:
      return state;
  }
};

export default geolocations;
