import React, { useMemo } from 'react'
import moment from 'moment-timezone'

import CsvButton from '../../components/CsvButton'
import RivataModule from '../../components/RivataModule'
import RivataGrid, { IRow } from "../../components/RivataGrid"
import StatusAlert from '../../components/StatusAlert'
import RivataLoader from '../../components/RivataLoader'

import { useTypedSelector } from '../../hooks/useTypedSelector'
import useCsvData from './useCsvData' 
import useReportColumns from './useReportColumns'
import useReportRows from './useReportRows'

import { getClearLabel } from '../../utils'
import { UnitsOfMeasurement } from '../../enums'

interface IReactGridProps {
    report: {
        isLoading: boolean,
        selectedReportType: string,
        columns: Array<string>,
        rows: Array<IRow>,
        error: IError
    },
    selectedReport: string,
    locale: {
        [key: string]: string
    },
    preferences: {
        unitsOfMeasurement: string,
        timezone: string
    }
}

const ReportGrid: React.FC<IReactGridProps> = ({
    selectedReport,
    locale
}) => {
    const {
        report: { rows, columns, isLoading, error },
        preferences
    } = useTypedSelector(state => ({
        report: state.reports.report,
        preferences: state.auth.preferences
    }))

    const { unitsOfMeasurement = UnitsOfMeasurement.imperial, timezone } = preferences
    const reportColumns = useReportColumns(selectedReport, columns, unitsOfMeasurement)
    const reportRows = useReportRows(selectedReport, columns, rows, moment().tz(timezone).format("z"), unitsOfMeasurement)
    const csvData = useCsvData(reportColumns, reportRows, selectedReport)
    
    const filter = useMemo(() => {
        if (!csvData.length) return null
        
        return (
            <CsvButton
                disabled={isLoading}
                locale={locale}
                data={csvData}
                filename={`${selectedReport.toLowerCase()}.csv`}
            />
        )
    }, [csvData, isLoading, locale, selectedReport])

    return (
        <RivataModule
            title={getClearLabel(selectedReport)}
            locale={locale}
            marginTop={-1}
            filters={filter}
        >
            <>
                {isLoading ? (
                    <RivataLoader/>
                ) : !reportColumns.length || !reportRows.length || error.statusCode >= 400 ? (
                    <StatusAlert
                        color={error.statusCode <= 200 ? "success" : "danger"}
                        customText={null}
                        statusCode={null}
                        statusText={error.statusCode === 0 ? "No Data" : error.message}
                    />
                ) : (
                    <RivataGrid
                        rows={reportRows}
                        columns={reportColumns}
                    />
                )}
            </>
        </RivataModule>
    )
}

export default ReportGrid