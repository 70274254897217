import React, { useEffect, useMemo } from "react";
import ChartLegend from "../../components/ChartLegend";
import pinDefault from "../../assets/images/icons/svg/pin_default.svg";
import pinWarn from "../../assets/images/icons/svg/circle_warn.svg";
import pinCritWarn from "../../assets/images/icons/svg/circle_crit_warn.svg";
import pinGps from '../../assets/images/icons/svg/circle_gps.svg'

const MapLegend = ({ locale, isWarningDetailsPage }) => {
  const items = useMemo(() => {
    return [
      {
        icon: pinCritWarn,
        label: "Critical Warning",
      },
      {
        icon: pinWarn,
        label: "Warning",
      },
      {
        icon: pinGps,
        label: "GPS Data",
      },
      {
        icon: pinDefault,
        label: "Latest GPS Data",
      },
    ];
  },[])

  useEffect(() => {
    if (!isWarningDetailsPage) {
      items.splice(0, 2)
    }
  }, [isWarningDetailsPage, items])
  
  return (
    <ChartLegend
      items={items}
      zIndex={9}
      locale={locale}
      title="Legend"
    />
  );
};

export default MapLegend;
