import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";
import RivataLoader from "../../components/RivataLoader";
import RivataModule from "../../components/RivataModule";
import RivataLineChart from "../../components/RivataLineChart";
import RivataDropdown from "../../components/RivataDropdown";
import StatusAlert from "../../components/StatusAlert";

import { UnitsOfMeasurement } from "../../enums";

import { celsiusToFahr } from "../../utils";
import { useChartDataWithAxlesFilter } from "../../hooks/useChartHooks";
import {
  chartThresholdAnnotations,
  getMotionAnnotations,
  composeCustomTooltip,
} from "../../utils/chartUtils";
import {
  composeLineChartData,
  getDropdownItems
} from "./utils";

const id = "temperature";
const customTooltip = composeCustomTooltip({
  displayColors: false,
  bgColor: true,
  footer: true,
})

const TemperatureChart = ({
  isLoading,
  data,
  error,
  width,
  graphColors,
  locale,
  timestamp,
  temperatureTreshold,
  setRef,
  onDoubleClick,
  onZoomUpdate,
  setRange,
  xMax,
  xMin,
  preferences: { unitsOfMeasurement = UnitsOfMeasurement.imperial, timezone },
  healthColors,
  assetType,
  speedData,
}) => {
  const [selectedAxle, setSelectedAxle] = useState("all");
  const tempThreshold =
    unitsOfMeasurement === UnitsOfMeasurement.imperial
      ? celsiusToFahr(temperatureTreshold)
      : temperatureTreshold;
  const chartData = useMemo(() => {
    if (data && data.length && graphColors && graphColors.length) {
      return composeLineChartData(data, graphColors, unitsOfMeasurement);
    }
    return null;
  }, [data, graphColors, unitsOfMeasurement]);

  const filtredData = useChartDataWithAxlesFilter(
    chartData,
    xMax,
    xMin,
    selectedAxle
  );
  const dropdownItems = useMemo(() => {
    return getDropdownItems(filtredData, assetType);
  }, [filtredData, assetType]);

  useEffect(() => {
    if (chartData) setRange({ min: chartData.xMin, max: chartData.xMax });
    else setRange({ min: null, max: null });
  }, [chartData, setRange]);

  const assetMotionAnnotations = useMemo(() => {
    return getMotionAnnotations(speedData, id);
  }, [speedData]);

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title="Hub Temperature"
      width={width}
      locale={locale}
      error={error}
      filters={
        <div>
          <label className="mr-2">Select Axle:</label>
          <RivataDropdown
            items={dropdownItems}
            selected={selectedAxle}
            onSelect={(id) => {
              if (id !== selectedAxle) {
                setSelectedAxle(id);
                onDoubleClick(); // reset zoom
              }
            }}
          />
        </div>
      }
      collapsible
    >
      {isLoading ? (
        <RivataLoader />
      ) : filtredData.chartData.datasets.length ? (
        <RivataLineChart
          height={400}
          data={filtredData.chartData}
          legendLabel={`Temperature (${
            unitsOfMeasurement === UnitsOfMeasurement.imperial ? "°F" : "°C"
          })`}
          id={id}
          xMin={xMin}
          xMax={xMax}
          xAxisLabel={`Date (${moment().tz(timezone).format("z")})`}
          threshold={tempThreshold}
          setRef={setRef}
          onZoomUpdate={onZoomUpdate}
          onDoubleClick={onDoubleClick}
          assetMotionAnnotations={assetMotionAnnotations}
          customTooltip={customTooltip}
          annotations={chartThresholdAnnotations(
            id,
            tempThreshold,
            timestamp,
            healthColors
          )}
        />
      ) : (
        <StatusAlert customText="No Data" color="success" />
      )}
    </RivataModule>
  );
};

export default TemperatureChart;
