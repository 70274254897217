import {
  getMarkerPinMap,
  getCircleIcon,
  LayerTypes,
  behaviorUi,
  healthColors,
} from "./utils";
import config from "../../config/appConfig";

import { UnitsOfMeasurement } from "../../enums";

import { kmhToMph, metersToFt } from "../../utils";
import { getTooltipCoordinates } from "../RivataMapCluster/utils";
import { getGpsDirectinFromDeg } from "../../utils/utils";

export const H = window.H || {
  map: {
    Icon: () => {},
  },
};

const handleLatestLocationZoom = (hMap, latestLocation) => {
  if (!hMap || !latestLocation) return;
  hMap.getViewModel().setLookAtData({
    zoom: 5,
    position: { lat: latestLocation.latitude, lng: latestLocation.longitude },
  });
};

export const startMarkersClustering = (
  locations,
  hMap,
  isWarningDetailsPage,
  addInfoBubble
) => {
  const markersMap = getMarkerPinMap();
  const currentVehicleLocation = locations[locations.length - 1];

  const dataPoints = locations.map((item) => {
    return new H.clustering.DataPoint(
      item.latitude,
      item.longitude,
      null,
      item
    );
  });

  const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
    theme: {
      getClusterPresentation: (cluster) => {
        const latestPoint = { data: null, epoch: 0 };
        const latestWarningPoint = { data: null, epoch: 0 };
        let pinkey = "default";
        let zIndex = 10;
        let showPin = false;

        cluster.forEachDataPoint((markerData) => {
          if (isWarningDetailsPage && markerData.a.data.showAsWarning) {
            if (markerData.a.data.epoch > latestWarningPoint.epoch) {
              latestWarningPoint.data = markerData.a.data;
              latestWarningPoint.epoch = markerData.a.data.epoch;
            }

            if (pinkey !== "critWarn") {
              if (markerData.a.data.has_critical_warning) {
                pinkey = "critWarn";
              } else {
                pinkey = "warn";
              }
            }
          }

          if (markerData.a.data.epoch > latestPoint.epoch) {
            latestPoint.data = markerData.a.data;
            latestPoint.epoch = markerData.a.data.epoch;
          }
        });

        if (latestPoint.data.id === currentVehicleLocation.id) showPin = true;

        if (showPin) zIndex = 40;
        else if (pinkey === "critWarn") zIndex = 30;
        else if (pinkey === "warn") zIndex = 20;

        // Create a marker for the cluster
        const clusterMarker = new H.map.Marker(cluster.getPosition(), {
          icon: showPin
            ? markersMap[pinkey]
            : getCircleIcon(
                latestWarningPoint.data
                  ? latestWarningPoint.data.heading
                  : latestPoint.data.heading,
                pinkey
              ),
          // Set min/max zoom with values from the cluster, otherwise
          // clusters will be shown at all zoom levels
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom(),
        });

        // Bind cluster data to the marker
        clusterMarker.setData({
          latestPoint: latestWarningPoint.data
            ? latestWarningPoint.data
            : latestPoint.data,
        });
        clusterMarker.setZIndex(zIndex);
        clusterMarker.addEventListener("pointerenter", addInfoBubble);

        return clusterMarker;
      }, // https://developer.here.com/documentation/maps/3.1.30.7/dev_guide/topics/clustering.html
      getNoisePresentation: function (noisePoint) {
        let showPin = false;
        const pinkey = noisePoint.a.data.has_critical_warning
          ? "critWarn"
          : noisePoint.a.data.showAsWarning
          ? "warn"
          : "default";

        if (noisePoint.a.data.id === currentVehicleLocation.id) showPin = true;

        // Create a marker for noise points:
        const noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
          icon: showPin
            ? markersMap[pinkey]
            : getCircleIcon(noisePoint.a.data.heading, pinkey),

          // Use min zoom from a noise point to show it correctly at certain zoom levels
          min: noisePoint.getMinZoom(),
        });

        // Bind noise point data to the marker:
        noiseMarker.setData({ latestPoint: noisePoint.a.data });

        noiseMarker.addEventListener("pointerenter", addInfoBubble);

        return noiseMarker;
      },
    },
    clusteringOptions: {
      // strategy of cluster displaying: https://stackoverflow.com/questions/48265696/here-maps-clustering-is-not-accurate-unexpected-behaviour
      // without that clusters can be too close to each other
      strategy: H.clustering.Provider.Strategy.GRID,
      // Maximum radius of the neighbourhood
      eps: 11,
      // minimum weight of points required to form a cluster
      minWeight: 2,
    },
  });

  // Create a layer that will consume objects from our clustering provider
  const clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

  hMap.addLayer(clusteringLayer);

  // update zoom after new clustering
  handleLatestLocationZoom(hMap, currentVehicleLocation);

  return clusteringLayer;
};

const resize = (hMap) => {
  if (hMap) {
    hMap.getViewPort().resize();
  }
};

export const updateBaseLayer = (layerType, hMap, defaultLayers) => {
  if (!layerType || !hMap || !defaultLayers) return;

  const layerChoices = {
    [LayerTypes.NORMAL]: defaultLayers.vector.normal.map,
    [LayerTypes.SATELLITE]: defaultLayers.raster.satellite.map,
  };

  if (layerType in layerChoices) {
    hMap.setBaseLayer(layerChoices[layerType]);
  }
};

export const initMap = (mapRef, unitsOfMeasurement) => {
  const platform = new H.service.Platform({
    apikey: config.mapApiKey,
  });
  const defaultLayers = platform.createDefaultLayers();

  const hMap = new H.Map(mapRef, defaultLayers.vector.normal.map, {
    center: { lat: 41.850033, lng: -87.6500523 }, // center of U.S. default
    pixelRatio: window.devicePixelRatio || 1,
  });

  window.addEventListener("resize", () => resize(hMap));
  const { ui } = behaviorUi(hMap, defaultLayers, unitsOfMeasurement);

  const bubble = new H.ui.InfoBubble(
    { lng: 13.4, lat: 52.51 },
    {
      content: "",
    }
  );
  bubble.addClass("info-bubble");
  ui.addBubble(bubble);

  ui.getControl("zoom").setVisibility(true);
  ui.getControl("mapsettings").setVisibility(false);

  return { hMap, defaultLayers, ui, bubble };
};

const removeInfoBubble = (bubble) => {
  if (!bubble) return;
  bubble.close();
};

export const composeBubble = (e, data, bubble, unitsOfMeasurement) => {
  if (!data) return;

  const {
    name,
    vin,
    formatted_datetime,
    speed,
    elevation,
    showAsWarning,
    has_critical_warning,
    heading,
    latitude,
    longitude,
  } = data;

  const map = document.getElementById("rivata-map");
  const { top, left } = getTooltipCoordinates(map, data, e, 25, -145);

  const formattedSpeed = isNaN(speed)
    ? "NA"
    : unitsOfMeasurement === UnitsOfMeasurement.imperial
    ? kmhToMph(speed).toFixed(1) + "mph"
    : speed.toFixed(1) + "kmh";
  const formattedElevation =
    unitsOfMeasurement === UnitsOfMeasurement.imperial
      ? metersToFt(elevation).toFixed(1) + "ft"
      : elevation + "m";
  const labelColor = has_critical_warning
    ? healthColors[2].color
    : showAsWarning
    ? healthColors[1].color
    : "#6fbdf1";

  bubble.setContent(`
  <div id="details-buble" style="position: absolute; top: ${top}px; left: ${left}px; padding: 15px 10px 10px 15px; margin-top: 10px; margin-left: 10px">
    <div class="alert-primary show" role="alert" aria-live="assertive" aria-atomic="true" style="background-color: ${labelColor}; border-color: ${labelColor};">
      <div class="label-line"></div>
      <div class="bubble-body">
        <div class="row-wrapper">
          <div class="key">${name ? "Name" : "VIN"}</div>
          <div class="value">${name ? name : vin}</div>
        </div>
        <div class="row-wrapper">
          <div class="key">Date</div>
          <div class="value">${formatted_datetime}</div>
        </div>
        <div class="row-wrapper">
          <div class="key">Heading</div>
          <div class="value">${getGpsDirectinFromDeg(heading)}</div>
        </div>
        <div class="row-wrapper">
          <div class="key">Speed</div>
          <div class="value">${formattedSpeed}</div>
        </div>
        <div class="row-wrapper">
          <div class="key">Elevation</div>
          <div class="value">${formattedElevation}</div>
        </div>
        <div class="row-wrapper">
          <div class="key">Location:</div>
          <div class="value">${latitude}, ${longitude}</div>
        </div>
      </div>
    </div>
  </div>`);

  bubble.setPosition(e.target.getGeometry());
  document
    .querySelector("#details-buble")
    .addEventListener("pointerleave", () => removeInfoBubble(bubble));

  bubble.open();
};

export const addLocationButton = (hMap, ui, latestLocation) => {
  const elem = document.querySelector(".latest_location");

  if (elem) ui.removeControl("latest_location");

  const control = new H.ui.Control();

  const btn = new H.ui.base.Button({
    label: `<?xml version="1.0" encoding="utf-8"?>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <style type="text/css">
      .st0{fill:#414042;}
      .st1{fill:none;stroke:#414042;stroke-width:2;stroke-miterlimit:10;}
    </style>
    <circle class="st0" cx="49.86" cy="47.71" r="11.88"/>
    <circle class="st1" cx="49.86" cy="47.71" r="15.83"/>
    <line class="st1" x1="49.86" y1="27.26" x2="49.86" y2="31.09"/>
    <line class="st1" x1="49.86" y1="64.33" x2="49.86" y2="68.16"/>
    <line class="st1" x1="29.41" y1="47.71" x2="33.24" y2="47.71"/>
    <line class="st1" x1="66.48" y1="47.71" x2="70.31" y2="47.71"/>
    </svg>`,
  });
  btn.addClass("latest_location");

  control.addChild(btn);
  control.setAlignment("right-middle");

  ui.addControl("latest_location", control);
  const newElem = btn.getElement();
  newElem.addEventListener("click", () =>
    handleLatestLocationZoom(hMap, latestLocation)
  );
};
