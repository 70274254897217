import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { convertDataEpochToDate, getTpmsTirePressure, getThresholdsByAssignedProfile, getProfilePsiLevel } from '../../utils';
import { isNumber } from 'lodash';
import { Tooltip } from 'reactstrap';

const SensorTooltip = ({ id, timestamp, status }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const measurementTime = timestamp ? convertDataEpochToDate(timestamp, null, null, true) : "---"
    return <Tooltip
        innerClassName={`tire-tooltip ${status}`}
        placement="top"
        target={id}
        isOpen={tooltipOpen}

        hideArrow={true}
        toggle={toggle}
    >
        Latest Measurement: {measurementTime} 
    </Tooltip>
}

const Tr = ({ asset, defaults, columns, preferences: { unitsOfMeasurement } }) => {
    const [tpmsThresholds, setTpmsThresholds] = useState({})

    useEffect(() => {
        if (!defaults || !defaults.tpms) return
        const { critical_low_pressure_in_percent, critical_over_pressure_in_percent, low_pressure_in_percent, over_pressure_in_percent } = defaults.tpms
        let obj = {
            critical_low_pressure_in_percent,
            critical_over_pressure_in_percent,
            low_pressure_in_percent,
            over_pressure_in_percent
        }
        let isValid = true

        Object.values(asset.tpms_profile_thresholds_percents).map((item) => {
            if (!item) {
                isValid = false
            }
            return isValid
        })

        if (asset.tpms_profile_thresholds_percents && isValid) {
            obj = { ...asset.tpms_profile_thresholds_percents }
        }

        setTpmsThresholds(obj)
    }, [defaults, asset.tpms_profile_thresholds_percents])

    if (!defaults.tpms && !Object.keys(tpmsThresholds).length) return null

    return (
        <tr key={asset.id} >
            <td><Link to={`/details/${asset.vin}`}>{asset.asset_name}</Link></td>
            <td><Link to={`/details/${asset.vin}`}>{asset.vin}</Link></td>
            <td><Link to={`/details/${asset.vin}`}>{asset.latest_update_at_epoch && asset.latest_update_at_epoch > 0 ? convertDataEpochToDate(asset.latest_update_at_epoch, null, null, true) : "---"}</Link></td> 
            <td><Link to={`/details/${asset.vin}`}>{asset.tpms_profile ? asset.tpms_profile : "Default"}</Link></td>
            {columns.map((col, i) => {

                const td = asset.sensors.find((el) => el.key === col ? true : false)
                if (columns.length > 1 && columns[i] === "NODATA") return null

                let statusClassName = "";
                let label = "-";

                if (td) {
                    const { key, value, cold_inflation_pressure_in_psi, no_status } = td   
                    if (no_status) {
                        statusClassName = "noStatus";
                    }
                    else if (value) {
                        label = getTpmsTirePressure(value, unitsOfMeasurement)
                        const profilePsiLevel = cold_inflation_pressure_in_psi || getProfilePsiLevel(key)

                        const thresholdsByAssignedProfile = getThresholdsByAssignedProfile(
                            unitsOfMeasurement,
                            profilePsiLevel,
                            tpmsThresholds,
                            defaults.tpms?.cold_inflation_pressure_in_psi
                        )

                        statusClassName = isNumber(label) ? label < thresholdsByAssignedProfile.critical_low_pressure || label > thresholdsByAssignedProfile.critical_over_pressure
                            ? "criticalWarning"
                            : label < thresholdsByAssignedProfile.low_pressure || label > thresholdsByAssignedProfile.over_pressure
                                ? "warning" : "" : ""

                    }
                }

                const id = col + i + asset.asset_id;

                return <td 
                    key={i} 
                    id={id} 
                    className={statusClassName}
                >
                    {label} 
                    {td && <SensorTooltip id={id} timestamp={td.timestamp} status={statusClassName}/>}
                </td>
            })}
        </tr>
    )
}

export default Tr