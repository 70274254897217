import React, { useState } from "react";
import { isInteger } from "lodash";
import { Link } from 'react-router-dom';
import "./styles.scss";
import { celsiusToFahr, getSensorFromSensorType, getClearLabel, parseSecondsToDateParts } from "../../utils";
import { Checkbox } from "../../components/RivataTable/TableRow";
import { PressureWarningLevel, UnitsOfMeasurement, WarningType } from "../../enums";
import AlertInfoModal from "./AlertInfoModal";

const getWarningDescription = (warning_type, warning_subtype, duration, level, is_recovered_to_normal, formatted_datetime, locale, unitsOfMeasurement, formatted_recovered_to_normal_at_datetime, max_warning_value) => {

  if (isNaN(level) && !level) return "-";

  const isImperial = unitsOfMeasurement === UnitsOfMeasurement.imperial;

  try {
    let formattedDuration = ""
    let recoveredText = '';
    if (is_recovered_to_normal) {
      recoveredText = ` recovered to normal at ${formatted_recovered_to_normal_at_datetime}`;

      if (isInteger(duration)) {
        const {parsed: {days}, formatted: {fDays, fHours, fMinutes, fSeconds}} = parseSecondsToDateParts(duration);
        if (warning_type === WarningType.noStatus){ 
          if (days === 0) {
            formattedDuration = ` for ${fHours}:${fMinutes}`;
          }
          else {
            formattedDuration = ` for ${fDays} ${fHours}:${fMinutes}`;
          }
        }
        else {
          if (days === 0) {
            formattedDuration = ` for ${fHours}:${fMinutes}:${fSeconds}`;
          }
          else {
            formattedDuration = ` for ${fDays} ${fHours}:${fMinutes}:${fSeconds}`;
          }
        }
      }
    }

    if (warning_type === WarningType.hubTemperature) {
      let degreesText = "";
      if (max_warning_value) {
        degreesText = locale.tempMsgBody + (isImperial ? ` ${celsiusToFahr(max_warning_value).toFixed(0)} °F` : ` ${max_warning_value.toFixed(0)} °C`);
      }

      return `${locale.tempMsgStart} ${formattedDuration} ${degreesText} ${recoveredText}`;
    }
    else if (warning_type === WarningType.hubVibration) {
      return `${locale.vibMsgStart} ${level.toFixed(1)} ${locale.vibMsgEnd} ${recoveredText}`;
    }
    else if (warning_type === WarningType.tirePressure) {
      let tpmsWarningText = "";
      let warningLevel = "";
      if (warning_subtype === PressureWarningLevel.lowPressure) {
        warningLevel = "Low Pressure";
      }
      else if (warning_subtype === PressureWarningLevel.criticalLowPressure) {
        warningLevel = "Critical Low Pressure";
      }
      else if (warning_subtype === PressureWarningLevel.overPressure) {
        warningLevel = "Over Pressure";
      }
      else if (warning_subtype === PressureWarningLevel.criticalOverPressure) {
        warningLevel = "Critical Over Pressure";
      }

      if (warningLevel) {
        tpmsWarningText = warningLevel;
        if (formattedDuration) {
          tpmsWarningText += formattedDuration;
        }
        if (recoveredText) {
          tpmsWarningText += recoveredText;
        }
      }

      return tpmsWarningText;
    }
    else if (warning_type === WarningType.noStatus) {
      return `In no-status state ${formattedDuration} ${recoveredText}`;
    }
    else if (warning_type === WarningType.linePressure) {
      return `Check Line Pressure ${formattedDuration} ${recoveredText}`;
    }

  } catch (error) {
    return locale["Error Parsing Data"];
  }

  return "-";
}

const getWarningPosition = (locale, position, sensor_type) => {
  if (sensor_type === "line_pressure") {
    return locale["chassis"];
  }
  else if (sensor_type === "gateway") {
    return locale["gateway"]
  }

  return position ? locale[position] || getClearLabel(position) : "-";
}

const WarningRow = ({ locale, isAdmin, isSuperAdmin, onCheckRowItem, vin, timezone, days, healthColors, unitsOfMeasurement, checkedItemsList, ...data }) => {
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const {
    warning_type,
    warning_subtype,
    datetime,
    formatted_datetime,
    position,
    duration_in_seconds,
    warning_value,
    acknowledged,
    sensor_type,
    is_recovered_to_normal,
    rowId,
    formatted_recovered_to_normal_at_datetime,
    alert_message,
    alert_recipients,
    max_warning_value
  } = data;
  const timestamp = +datetime;
  const highlight = window.location.href.includes(rowId);

  const isChecked = () => {
    const idx = checkedItemsList.findIndex(el => el === rowId ? true : false)
    if (idx > -1) {
      return true
    }
    return false
  }

  return (
    <tr style={{ backgroundColor: highlight ? healthColors[1].color : null }} className={acknowledged ? `table-row acknowledged` : `table-row`}>
      {isAdmin && (
        <td>
          {!Boolean(acknowledged) && <Checkbox
            disabled={false}
            onCheck={onCheckRowItem}
            data={data}
            checked={isChecked()}
          />}
        </td>
      )}
      <td>
        <Link to={`/details/${vin}/warnings/${timestamp}/${days}/${rowId}`}>
          {getSensorFromSensorType(sensor_type)}
        </Link>
      </td>
      <td>
        <Link to={`/details/${vin}/warnings/${timestamp}/${days}/${rowId}`}>
          {formatted_datetime}
        </Link>
      </td>
      <td>
        <Link to={`/details/${vin}/warnings/${timestamp}/${days}/${rowId}`}>
          {getWarningPosition(locale, position, sensor_type)}
        </Link>
      </td>
      <td>
        <Link to={`/details/${vin}/warnings/${timestamp}/${days}/${rowId}`}>
          {warning_type ? locale[warning_type] : "-"}
        </Link>

      </td>
      <td>
        <div className="d-flex">
          {(alert_message) && isSuperAdmin && <>
            <i className="fa fa-exclamation-circle font-16 alert-icon" style={{ cursor: "pointer" }} onClick={() => setAlertModalOpen(true)} />
            <AlertInfoModal locale={locale} open={alertModalOpen} header="Alert info" message={alert_message} onConfirm={() => setAlertModalOpen(!alertModalOpen)} recipients={alert_recipients} />
          </>}
          <Link to={`/details/${vin}/warnings/${timestamp}/${days}/${rowId}`}>
            {getWarningDescription(warning_type, warning_subtype, duration_in_seconds, warning_value, is_recovered_to_normal, formatted_datetime, locale, unitsOfMeasurement, formatted_recovered_to_normal_at_datetime, max_warning_value)}
          </Link>
        </div>

      </td>
    </tr>
  );
};

export default WarningRow;
