import React, { useCallback, useState } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import { useTableColumns, useTableRows } from './hooks'
import { LogModal } from './logModal'
import './style.scss'
import { parseLog } from './utils'

const HardwareCommandsTable = ({
    isLoading,
    data,
    error,
    locale = {},
    putHardwareCommand,
}) => {
    const onLogButtonPress = useCallback((log) => {
        setLogModalVisible(true);
        setLogModalText(parseLog(log))
    }, []);

    const [logModalVisible, setLogModalVisible] = useState(false)
    const [logModalText, setLogModalText] = useState("")
    const columns = useTableColumns()
    const rows = useTableRows(data, putHardwareCommand, onLogButtonPress)

    return (
        <RivataModule
            title="Hardware Commands"
            locale={locale}
            marginTop={0}
            error={error}
        >
            <RivataTable
                isLoading={isLoading}
                columns={columns}
                rows={rows}
            />
            <LogModal isOpen={logModalVisible} text={logModalText} onLogModalClose={() => { setLogModalVisible(false) }} />
        </RivataModule>
    )
}

export default HardwareCommandsTable