import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import moment from "moment";
import { DateRangeSelector } from "../../components/DateRangeCalendar";
import { convertDataEpochToDate } from "../../utils";

interface Props {
  onDateSelected: (startDate: Date, endDate: Date) => void;
  start?: number | null,
  end?: number | null
}

const DayPicker: React.FC<Props> = ({ onDateSelected, start, end }) => {
  const [calendarVisible, setCalendarVisible] = useState(false);

  const [dateRangeObj, setDateRangeObj] = useState({
    inputValue: ``,
    dayStart: moment().toDate(),
    dayEnd: moment().toDate(),
  });

  const dateRangeHandler = (date: {
    selection: {
      startDate: Date;
      endDate: Date;
    };
  }) => {
    const { startDate, endDate } = date.selection;

    setDateRangeObj({
      inputValue: `${moment(startDate).format("MM/DD/YYYY")} ${endDate ? "- " + moment(endDate).format("MM/DD/YYYY") : ""}`,
      dayStart: startDate,
      dayEnd: endDate,
    });

    onDateSelected(startDate, endDate);
  };

  const toggleCalendar = (value = !calendarVisible) => {
    setCalendarVisible(value);
  };

  useEffect(() => {
    const strDate = start ? convertDataEpochToDate(start, null, null, false).format(
        "MM/DD/YYYY"
    ) : moment().format("MM/DD/YYYY")
    const endDate = end ? convertDataEpochToDate(end, null, null, false).format(
        "MM/DD/YYYY"
      ) : moment().format("MM/DD/YYYY")
    setDateRangeObj({
      ...dateRangeObj,
      inputValue: `${strDate} - ${endDate}`,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, setDateRangeObj]);

  return (
    <div className="position-relative d-flex range-selector__wrapper">
      <Input
        id="range_input"
        placeholder="Please choose your dates"
        onClick={(e: React.FormEvent<EventTarget>) => {
          e.persist();
          toggleCalendar();
        }}
        value={dateRangeObj.inputValue}
        readOnly
      />

      <DateRangeSelector
        selectionRange={{ startDate: dateRangeObj.dayStart, endDate: dateRangeObj.dayEnd, key: "selection" }}
        handleSelect={dateRangeHandler}
        toggleCalendar={toggleCalendar}
        visible={calendarVisible}
      />
    </div>
  );
};

export default DayPicker;
