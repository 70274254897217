import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Button } from 'reactstrap'

import RivataModule from "../../components/RivataModule"
import RivataTable from "../../components/RivataTable"
import DeleteModal from "../../components/DeleteModal"
import ConfirmModal from "../../components/ConfirmModal"

import CreateUserModal from "./modals/CreateUserModal"
import UpdateUserModal from "./modals/UpdateUserModal"
import { ICountryCode } from "./components/CountryCodeDropdown"

import { IHandleUserUpdate, IUserFormValues, IValidate, IUserComponentProps, IConfirmModalButton } from "./types"
import { useTableRows, useColumns } from "./hooks"
import { handleCreateUser, ensureUserHaveAccess } from "./handlers"

import { RolePermission, Timezones, NotificationThresholds } from "../../enums"

import { useTypedSelector } from "../../hooks/useTypedSelector"
import { useActions } from "../../hooks/useActions"

import { ICreateUserRequest, IUpdateUserRequest } from "../../redux/provision/action"
import InfoModal from "../../components/InfoModal"

import "./style.scss"

// Fixes input values
const fixInValid = (value: string) => (!value ? "" : value)

const Users: React.FC<IUserComponentProps> = ({
    width,
    locale = {}
}) => {
    const {
        loading, 
        data, 
        customers, 
        totalCount,
        pageLimit,
        pageOffset,
        auth: {
            isSuperAdmin, 
            isAdmin, 
            preferences: { customer_ids },
            credentials: { idToken },
            user: { userName }
        }
    } = useTypedSelector((state) => ({
        ...state.provision.users,
        customers: state.common.customers,
        auth: state.auth
    }))
    const { selectedCustomer } = customers
    const {
        setUsersPageSortOptions,
        fetchUsers,
        addUser,
        deleteUser,
        updateUser,
        setUsersPageOffset,
        setUsersPageLimit
    } = useActions()

    const columns = useColumns(locale)
    const rows = useTableRows(idToken, data)
    const [modalUpdate, setModalUpdate] = useState<IUserFormValues | null>(null)
    const [modalDelete, setModalDelete] = useState<IUser | null>(null)
    const [countryCode, setCountryCode] = useState({ label: "USA",code: "+1" })
    const [formValues, setFormValues] = useState<IUserFormValues>({
        first_name: "",
        last_name: "",
        username: "",
        phone_number: countryCode.code,
        email_address: "",
        timezone: Timezones.AMERICA_LOS_ANGELES,
        role: RolePermission.ADMIN,
        customerIds: [selectedCustomer?.id]
    })
    const [updatePhoneModal, setUpdatePhoneModal] = useState<string | null>(null)
    const [updateUserValues, setUpdateUserValues] = useState<IUserFormValues | null>(null)
    const [createOpen, setCreateOpen] = useState(false)


    useEffect(() => {
        if (selectedCustomer) fetchUsers()
    }, [fetchUsers, selectedCustomer])


    useEffect(() => {
        setFormValues((prev) => {
            return { ...prev, phone_number: countryCode.code }
        })
    }, [countryCode])

    useEffect(() => {
        if (!selectedCustomer?.id) return
        setFormValues(prev => ({ ...prev, customerIds: [selectedCustomer.id] }))
    }, [selectedCustomer])

    const validate: IValidate = (values, formProps) => {
        let usernameValue = values.username

        if (values.username.indexOf(" ") > -1) {
            usernameValue = values.username.split(" ").join("")
        }

        if (
            values.username
                .split("")
                .findIndex((el) =>
                    el === el.toUpperCase() && el !== " " ? true : false
                ) > -1
        ) {
            usernameValue = usernameValue.toLowerCase()
        }

        formProps.setFieldValue("username", usernameValue, false)
        setFormValues((prev: IUserFormValues): IUserFormValues => ({ ...prev, ...values }))
    }

    const handleUserUpdate: IHandleUserUpdate = useCallback((
        removeTextSubscription = false
    ) => {
        const notification_text = modalUpdate?.notification_text
        const notification_thresholds = modalUpdate?.notification_thresholds

        if (updateUserValues) {
            const reqBody: IUpdateUserRequest = {
                email_address: updateUserValues.email_address,
                first_name: updateUserValues.first_name,
                last_name: updateUserValues.last_name,
                phone_number: updateUserValues.phone_number,
                role: updateUserValues.role,
                time_zone: updateUserValues.timezone,
                username: updateUserValues.username.toLowerCase(),
                customer_ids: updateUserValues.customerIds,
                old_phone_number: updateUserValues.old_phone_number,
                notification_text: removeTextSubscription
                    ? false
                    : notification_text,
                notification_thresholds
            }
            updateUser(reqBody)
            setModalUpdate(null)
            setCountryCode({ label: "USA", code: "+1" })
            setUpdateUserValues(null)
        }
    }, [modalUpdate, updateUserValues, updateUser])

    useEffect(() => {
        if (updateUserValues && Object.keys(updateUserValues).length) {
            handleUserUpdate()
        }
    }, [updateUserValues, handleUserUpdate])

    const confirmModalButtons: Array<IConfirmModalButton> = useMemo(() => {
        return [
            {
                id: 1,
                label: "Yes",
                color: "success",
                onClick: () => {
                    setUpdatePhoneModal(null)
                    handleUserUpdate()
                }
            },
            {
                id: 2,
                label: "No",
                color: "danger",
                onClick: () => {
                    setUpdatePhoneModal(null)
                    handleUserUpdate(true)
                }
            }
        ]
    }, [handleUserUpdate])

    const toggleCreateModal = () => {
        setCreateOpen(!createOpen)
    }

    const handleAddUser = async (values: ICreateUserRequest) => {
        const res = await addUser(values)
        fetchUsers()

        return res
    }

    return (
        <RivataModule
            title="Users"
            width={width}
            locale={locale}
            marginTop={0}
            filters={
                <>
                    <Button
                        size="md"
                        onClick={toggleCreateModal}
                        disabled={!selectedCustomer?.id}
                    >
                        <i className="mr-2 fa fa-plus-circle"></i>
                        Create User
                    </Button>
                    <CreateUserModal
                        open={createOpen}
                        onClose={() => setCreateOpen(false)}
                        onSubmit={(values) => handleCreateUser(handleAddUser, values)}
                        title="Create"
                        locale={locale}
                        validate={validate}
                        initialValues={formValues}
                        countryCode={countryCode}
                        selectedCustomer={selectedCustomer}
                        customersList={customers.data}
                        isSuperAdmin={isSuperAdmin}
                        isAdmin={isAdmin}
                        userCustomerIds={customer_ids}
                        setCountryCode={(code) => setCountryCode(code)}
                        setFormValues={setFormValues}
                        resetFormValues={() => {
                            setCountryCode({ label: "USA", code: "+1" })
                            setFormValues({
                                first_name: "",
                                last_name: "",
                                username: "",
                                phone_number: "+1",
                                email_address: "",
                                timezone: Timezones.AMERICA_LOS_ANGELES,
                                role: RolePermission.ADMIN,
                                customerIds: [selectedCustomer.id]
                            })
                        }}
                    />
                </>
            }
        >
            {updatePhoneModal && (
                <ConfirmModal
                    open={!!updatePhoneModal}
                    header={"Change Phone Number"}
                    onClose={() => setUpdatePhoneModal(null)}
                    locale={locale}
                    modalButtons={confirmModalButtons}
                >
                    <h6>{updatePhoneModal}</h6>
                    {modalUpdate?.notification_text ? (
                        <>
                            <div>Receive sms: true</div>
                            <div className="mt-2">
                                {modalUpdate["notification_thresholds"] ===
                                NotificationThresholds.CRITICAL_ONLY
                                    ? "Threshold: receive notifications only about critical warnings"
                                    : "Threshold: receive notifications about all warnings"}
                            </div>
                        </>
                    ) : null}
                </ConfirmModal>
            )}
            {modalDelete && (
                <>
                    {(ensureUserHaveAccess(customer_ids, modalDelete.customers) && modalDelete.username !== userName) || isSuperAdmin ? (
                        <DeleteModal
                            open={true}
                            disabled={false}
                            header={
                                modalDelete
                                    ? `Delete user ${modalDelete.username}?`
                                    : ""
                            }
                            message={"This cannot be undone!"}
                            onDelete={() => {
                                deleteUser(modalDelete.username)
                                setModalDelete(null)
                            }}
                            onCancel={() => setModalDelete(null)}
                            locale={locale}
                        />
                    ) : modalDelete.username === userName ? (
                        <InfoModal
                            open={true}
                            header={"This can not be done!"}
                            message={"You cannot delete yourself!"}
                            onConfirm={() => setModalDelete(null)}
                        />
                    ) : (
                        <InfoModal
                            open={true}
                            header={"This can not be done!"}
                            message={`User "${modalDelete.username}" has references to other customers which you don't have access to. To delete this user you must contact your Admin!`}
                            onConfirm={() => setModalDelete(null)}
                        />
                    )}
                </>
            )}
            {modalUpdate && (
                <UpdateUserModal
                    title="Update"
                    locale={locale}
                    open={!!modalUpdate}
                    formValues={modalUpdate}
                    selectedCustomer={selectedCustomer}
                    setFormValues={setModalUpdate}
                    validate={null}
                    isSuperAdmin={isSuperAdmin}
                    isAdmin={isAdmin}
                    userCustomerIds={customer_ids}
                    onSubmit={(val: IUserFormValues): void | IError => {
                        if (val.role === "") return { statusCode: 400, message: "This user doesn't have any role!" } // show error in modal if there is no valid role
                        const updatedUserData = {
                            ...val,
                            old_phone_number: modalUpdate.phone_number
                        }
                        setUpdateUserValues(updatedUserData)

                        if (
                            modalUpdate.phone_number !== val.phone_number &&
                            modalUpdate.notification_text
                        ) {
                            setUpdatePhoneModal(
                                `Do you want to apply sms subscription settings of user ${val.username} to his new phone number?`
                            )
                        }
                    }}
                    onClose={() => {
                        setModalUpdate(null)
                        setCountryCode({ label: "USA", code: "+1" })
                    }}
                    countryCode={countryCode}
                    setCountryCode={(obj: ICountryCode) => {
                        setCountryCode(obj)
                        setModalUpdate((prev) =>
                            prev ? { ...prev, phone_number: obj.code } : null
                        )
                    }}
                    resetFormValues={() => {
                        setModalUpdate(null)
                        setCountryCode({ label: "USA", code: "+1" })
                    }}
                />
            )}
            <RivataTable
                columns={columns}
                rows={rows}
                isLoading={loading}
                editDisabled={!selectedCustomer?.id}
                setSortOptions={setUsersPageSortOptions}
                deleteDisabled={false}
                showPagination={true}
                assetsCount={totalCount}
                // @ts-ignore component expect undefined cause of default value
                onPageChange={setUsersPageOffset}
                pageLimit={pageLimit}
                initialPage={pageOffset / pageLimit}
                // @ts-ignore component expect undefined cause of default value
                onSelectLimit={setUsersPageLimit}
                onEdit={(rowData: IUser) => {
                    let customersList = data.find((el: { username: string }) => el.username === rowData.username ? true : false)
                    let idsList = []
                    
                    if (customersList) {
                        idsList = customersList.customers.map((c: { id: number, name: string }) => {
                            return c.id
                        })
                    }
                    
                    const role = rowData.roles ? rowData.roles[0].name : ""
                    
                    setModalUpdate({
                        first_name: fixInValid(rowData.name),
                        last_name: fixInValid(rowData.family_name),
                        phone_number: fixInValid(rowData.phone_number),
                        timezone: fixInValid(rowData.timezone),
                        role: fixInValid(role),
                        customerIds: idsList,
                        // if change phone number then ask about notifications
                        notification_text: rowData["notification_text"],
                        notification_thresholds: rowData["notification_thresholds"],
                        // values below not update-able 
                        email_address: fixInValid(rowData.email),
                        username: fixInValid(rowData.username),
                        refCustomers: customersList.customers
                    })
                }} 
                onDelete={(data: IUser) => setModalDelete(data)}
            />
        </RivataModule>
    )
}

export default Users
