import React from "react";
import { Row } from "reactstrap";
import CreateModal, {
  SelectFormField,
} from "../../components/CreateModal/children";
import {FormField} from "../../components/CreateModal"
import { AssetTypes } from "../../enums";
import * as Yup from "yup";

const AssetSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  vin: Yup.string().required("Required"),
  // hours_of_use: Yup.string(),
  // license: Yup.string(),
  // license_state: Yup.string(),
  year: Yup.number(),
  make: Yup.string(),
  model: Yup.string(),
  odometer_start: Yup.number(),
  // odometer_current: Yup.number(),
  asset_type: Yup.string(),
});


const options = [
  { value: AssetTypes.TRACTOR, label: "Tractor" },
  { value: AssetTypes.TRAILER, label: "Trailer" }
];

const AssetModal = ({
  locale = {},
  onCreate,
  disabled,
  initialValues,
  title,
  //isUpdate,
  open,
  onClose,
}) => {
  return (
    <CreateModal
      variant="primary"
      disabled={disabled}
      className="ml-3"
      btnClassName="ml-3"
      onCreate={onCreate}
      createBtnLabel={title}
      openBtnLabel={`${title} Asset`}
      header={`${title} Asset`}
      locale={locale}
      schema={AssetSchema}
      initialValues={initialValues}
      onClose={onClose}
      open={open}
    >
      <Row className="p-t-0">
        <FormField name="name" label="Name" />
        <FormField name="vin" label="VIN" />
        <FormField name="make" label="Make" />
        <FormField name="model" label="Model" />
        <FormField name="year" type="number" label="Year" />
        <FormField name="odometer_start" type="number" label="Odometer Start" />
        <SelectFormField name="asset_type" label="Asset Type" options={options} />
      </Row>
    </CreateModal>
  );
};

export default AssetModal;
