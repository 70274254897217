import React, { useState } from "react";
import { ListGroup } from "reactstrap";
import Document from "./Document";
import DeleteModal from "../../components/DeleteModal";
import { getFileTitle } from "./utils";
import "./styles.scss";

interface Props {
  documents: Array<IDocument>;
  locale: Record<string, string>;
  removeDocument: (title: string) => void;
}

const DocumentsList: React.FC<Props> = ({ documents, locale, removeDocument }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(null);

  const onDelete = (document: IDocument) => {
    setModalOpen(true);
    setSelectedDocument(document);
  };

  const deleteDocument = () => {
    if (selectedDocument) {
      removeDocument(selectedDocument.file_name);
      setModalOpen(false);
    }
  };

  return (
    <>
      {documents.length ? (
        <div className="d-flex flex-column mt-3">
          <h4>Documents List</h4>

          <ListGroup>
            {documents.map((document, index) => (
              <Document key={index} document={document} onDelete={onDelete} />
            ))}
          </ListGroup>

          <DeleteModal
            open={modalOpen}
            disabled={false}
            header={`Delete document ${getFileTitle(selectedDocument?.file_name)} ?`}
            message={"This cannot be undone!"}
            onDelete={deleteDocument}
            onCancel={() => {
              setModalOpen(false);
              setSelectedDocument(null);
            }}
            locale={locale}
          />
        </div>
      ) : null}
    </>
  );
};

export default DocumentsList;
