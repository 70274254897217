import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import JSONInput from 'react-json-editor-ajrm';
import JSONlocale from 'react-json-editor-ajrm/locale/en';
import SubNav from '../../components/SubNav'
import RivataModule from '../../components/RivataModule'
import ProvisionAssetJson from '../../modules/ProvisionAssetJson'
import StatusAlert from '../../components/StatusAlert'
import HardwareCommandsTable from '../../modules/HardwareCommandsTable'
import RivataLoader from "../../components/RivataLoader";
import DataMigration from "../../modules/DataMigration";

import ProvisionHistory from "../../modules/ProvisionHistory";
import AdminGeofencesEvents from "../../modules/AdminGeofencesEvents";
import { useActions } from "../../hooks/useActions";

import {
    postProvision,
    postMigrateHardwareData,
    deleteHardwareData,
    setAssetInfo,
    putHardwareCommand,
    setProvisionInfoError,
    fetchProvisionHistory,
    postCopyToEnviroments
} from '../../redux/assetAdminInfo/action'

import './style.scss'

const tabs = [
    { id: "1", label: "Subscriptions" },
    { id: "2", label: "Sensor Info" },
    { id: "3", label: "Provision Asset" },
    { id: "4", label: "Data migration tool" },
    { id: "5", label: "Hardware Commands" },
    { id: "6", label: "Provision History" },
    { id: "7", label: "Geofences events" }
]

const AssetAdminInfo = ({
    assetAdminInfo: { subscription, sensorInfo, provisionInfo, assetInfo, hardwareCommands, provisionHistory, geofencesEvents },
    locale,
    postProvision,
    postMigrateHardwareData,
    setAssetInfo,
    deleteHardwareData,
    putHardwareCommand,
    setProvisionInfoError,
    fetchProvisionHistory,
    match: { params },
    postCopyToEnviroments
}) => {
    const [activeTab, setActiveTab] = useState('1');

    const { fetchAdminInfoPage } = useActions()

    useEffect(() => {
        fetchAdminInfoPage(params.vin)
    }, [fetchAdminInfoPage, params.vin])


    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        return () => {
            setAssetInfo({}) // clear info
        }
    }, [setAssetInfo])

    return (
        <>
            <SubNav
                title="Asset Admin Info"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Asset Details", path: `/details/${params.vin}` },
                    { label: "Asset Admin Info" }
                ]}
            />
            <div className="w-100">
                <div className="ml-3">
                    <h4>Selected Asset Info</h4>
                    <ul className="asset-info__list">
                        <li>Asset Id: <span>{assetInfo && assetInfo.id ? assetInfo.id : "-"}</span></li>
                        <li>Asset Name: <span>{assetInfo && assetInfo.name ? assetInfo.name : "-"}</span></li>
                        <li>Asset Vin: <span>{params.vin}</span></li>
                        <li>Asset Customer Group: <span>{assetInfo && assetInfo.customerGroup ? assetInfo.customerGroup : "-"}</span></li>
                        <li>Last Provisioning Timestamp: <span>{provisionInfo && provisionInfo.lastProvisionTimestamp ? provisionInfo.lastProvisionTimestamp : "-"}</span></li>
                    </ul>
                </div>
                <div className="col-lg-12">
                    <Nav tabs>
                        {tabs.map(tab => {
                            return (
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.id, "active-tab__style": activeTab === tab.id })}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { toggle(tab.id); }}
                                    >
                                        {tab.label}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <RivataModule
                            title={`Current Subscriptions`}
                            locale={locale}
                            marginTop={0}
                        >
                            {subscription?.isLoading ? (
                                <RivataLoader />
                            ) : !subscription?.isLoading && !Object.keys(subscription?.data).length ? (
                                <StatusAlert customText="No Data" color="success" />
                            ) : (
                                <JSONInput
                                    id='json'
                                    locale={JSONlocale}
                                    placeholder={subscription.data}
                                    width="100%"
                                    viewOnly={true}
                                    confirmGood={false}
                                    style={{ body: { padding: "1rem" } }}
                                />
                            )}
                        </RivataModule>
                    </TabPane>
                    <TabPane tabId="2">
                        <RivataModule
                            title={`Installed Sensors`}
                            locale={locale}
                            marginTop={0}
                        >
                            {sensorInfo?.isLoading ? (
                                <RivataLoader />
                            ) : sensorInfo && sensorInfo.data && !Object.keys(sensorInfo.data).length ? (
                                <StatusAlert customText="No Data" color="success" />
                            ) : (
                                <JSONInput
                                    id='json'
                                    locale={JSONlocale}
                                    placeholder={sensorInfo.data}
                                    width="100%"
                                    viewOnly={true}
                                    confirmGood={false}
                                    style={{ body: { padding: "1rem" } }}
                                />
                            )}
                        </RivataModule>
                    </TabPane>
                    <TabPane tabId="3">
                        <ProvisionAssetJson
                            {...provisionInfo}
                            vin={params.vin}
                            postProvision={postProvision}
                            locale={locale}
                            setProvisionInfoError={setProvisionInfoError}
                            onCopyJsonToEnviroment={postCopyToEnviroments}
                        />
                    </TabPane>
                    <TabPane tabId="4">
                        <DataMigration
                            postMigrateHardwareData={postMigrateHardwareData}
                            deleteHardwareData={deleteHardwareData}
                            locale={locale}
                            assetId={assetInfo.id}
                        />
                    </TabPane>
                    <TabPane tabId="5">
                        <HardwareCommandsTable
                            locale={locale}
                            putHardwareCommand={putHardwareCommand}
                            {...hardwareCommands}
                        />
                    </TabPane>
                    <TabPane tabId="6">
                      
                        <ProvisionHistory
                            locale={locale}
                            isLoading={provisionHistory.isLoading}
                            data={provisionHistory.data}
                            error={provisionHistory.error}
                            fetchProvisionHistory={fetchProvisionHistory}
                        />
                    </TabPane>
                    <TabPane tabId="7">
                        {activeTab === "7" && <AdminGeofencesEvents />}
                    </TabPane>
                </TabContent>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    locale: state.whitelabel.locale,
    assetAdminInfo: state.assetAdminInfo,
})

const mapDispatchToProps = (dispatch) => ({
    postProvision: (json) => dispatch(postProvision(json)),
    postMigrateHardwareData: (data) => dispatch(postMigrateHardwareData(data)),
    deleteHardwareData: (assetId) => dispatch(deleteHardwareData(assetId)),
    setAssetInfo: (info) => dispatch(setAssetInfo(info)),
    putHardwareCommand: (data) => dispatch(putHardwareCommand(data)),
    setProvisionInfoError: (err) => dispatch(setProvisionInfoError(err)),
    fetchProvisionHistory: (from, to) => dispatch(fetchProvisionHistory(from, to)),
    postCopyToEnviroments: (data) => dispatch(postCopyToEnviroments(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetAdminInfo);
