import {
    LOADING_AXLE_LOAD_ASSETS,
    SET_AXLE_LOAD_ASSETS,
    SET_AXLE_LOAD_ASSETS_ERROR,
    SET_SELECTED_CALIBRATION_ASSET,
    LOADING_AXLE_LOAD_CALIBRATION_DATA,
    SET_AXLE_LOAD_CALIBRATION_DATA,
    SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT,
    SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET,
    SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT,
    SET_AXLE_LOAD_CALIBRATION_DATA_ERROR
} from '../actionTypes'

const initialState = {
    axleLoadAssets: {
        isLoading: false,
        data: [],
        error: {
            statusCode: 0,
            message: ""
        }
    },
    selectedAsset: {},
    calibrationData: {
        isLoading: false,
        data: [],
        totalCount: 0,
        offset: 0,
        limit: 10,
        error: {
            statusCode: 0,
            message: ""
        }
    }
}

const setField = (state, key, field, payload) => ({ ...state, [key]: { ...state[key], [field]: payload } })
const setSelectedAsset = (state, payload) => ({ ...state, selectedAsset: payload })
 
const axleLoadCalibration = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_AXLE_LOAD_ASSETS:
            return setField(state, "axleLoadAssets", "isLoading", action.payload)
        case SET_AXLE_LOAD_ASSETS:
            return setField(state, "axleLoadAssets", "data", action.payload)
        case SET_AXLE_LOAD_ASSETS_ERROR:
            return setField(state, "axleLoadAssets", "error", action.payload)
        
        case SET_SELECTED_CALIBRATION_ASSET:
            return setSelectedAsset(state, action.payload)
        
        case LOADING_AXLE_LOAD_CALIBRATION_DATA:
            return setField(state, "calibrationData", "isLoading", action.payload)
        case SET_AXLE_LOAD_CALIBRATION_DATA:
            return setField(state, "calibrationData", "data", action.payload)
        case SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT:
            return setField(state, "calibrationData", "totalCount", action.payload)
        case SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET:
            return setField(state, "calibrationData", "offset", action.payload)
        case SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT:
            return setField(state, "calibrationData", "limit", action.payload)
        case SET_AXLE_LOAD_CALIBRATION_DATA_ERROR:
            return setField(state, "calibrationData", "error", action.payload)
        default:
            return state
    }
}

export default axleLoadCalibration