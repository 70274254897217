import React, { useState, useEffect } from "react";
import RivataModule from "../../components/RivataModule";
import RivataLoader from "../../components/RivataLoader"
import PreferencesForm from "../../components/PreferencesForm";
import InfoModal from '../../components/InfoModal'
import ConfirmModal from "../../components/ConfirmModal";
import { UnitsOfMeasurement, NotificationThresholds } from '../../enums'

const Preferences = ({
  preferences,
  isSaving,
  onUpdatePreferences,
  locale,
  width,
}) => {
  const [phone, setPhone] = useState(preferences.phone);
  const [timezone, setTimezone] = useState(preferences.timezone);
  const [emailNotificationOn, setEmailNotificationOn] = useState(
    preferences.notifications.receiveEmail
  );
  const [textNotificationOn, setTextNotificationOn] = useState(
    preferences.notifications.receiveText
  );
  const [notificationThreshold, setNotificationThreshold] = useState(
    preferences.notifications.threshold
  );
  const [unitsOfMeasure, setUnitsOfMeasure] = useState(
    preferences.unitsOfMeasurement || UnitsOfMeasurement.imperial
  )
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [canhangePhoneMessage, setCanhangePhoneMessage] = useState(null)
  const [changePhoneModal, setChangePhoneModal] = useState(false)

  useEffect(() => {
    setPhone(preferences.phone)
    setTimezone(preferences.timezone)
    setEmailNotificationOn(preferences.notifications.receiveEmail)
    setTextNotificationOn(preferences.notifications.receiveText)
    setNotificationThreshold(preferences.notifications.threshold)
    setUnitsOfMeasure(preferences.unitsOfMeasurement)
  }, [preferences])

  useEffect(() => {
    if (phone !== preferences.phone) {
      if (textNotificationOn) {
        setCanhangePhoneMessage("Do you want to apply your sms subscription settings to your new phone number?")
      }
    }
  }, [phone, preferences.phone, textNotificationOn])

  const handleUpdateUserAttributes = async (id, changePhone = true) => {
    setLoading(true)

    let threshold = notificationThreshold

    if (!emailNotificationOn && !textNotificationOn) threshold = null

    const res = await onUpdatePreferences({
      phone,
      oldPhone: preferences.phone,
      timezone,
      notifications: {
        receiveEmail: emailNotificationOn,
        receiveText: id === 1? textNotificationOn : false,
        threshold,
      },
      unitsOfMeasurement: unitsOfMeasure
    }, changePhone);

    setInitialValues({
      phone,
      timezone,
      receiveEmail: emailNotificationOn,
      receiveText: textNotificationOn,
      threshold,
      unitsOfMeasure,
    })

    setLoading(false)

    if (res && res.status === "ok") {
      setMessage("Settings successfully saved")
    }
  }

  const handleDenyTextNotification = () => {
    setTextNotificationOn(false)
    handleUpdateUserAttributes(2)
  }

  return (
    <RivataModule
      title={locale.settings || "User Profile"}
      width={width}
      locale={locale}
    >
      <InfoModal
        header={"Settings info"}
        open={!!message}
        message={message || ""}
        onConfirm={() => setMessage(null)}
      />
      <ConfirmModal
        header={"Change Phone Number"}
        open={changePhoneModal}
        onClose={() => setChangePhoneModal(false)}
        modalButtons={[
          {id: 1, label: "Yes", color: "success", onClick: () => {
            setChangePhoneModal(false)
            handleUpdateUserAttributes(1)
          }},
          { id: 2, label: "No", color: "danger", onClick: () => {
            setChangePhoneModal(false)
            handleDenyTextNotification()
          }}
        ]}
      >
        <h6>{canhangePhoneMessage}</h6>
        <div>{textNotificationOn ? "Receive sms: true" : "Receive sms: false"}</div>
        {textNotificationOn ? (
          <div
            className="mt-2"
          >
            {notificationThreshold === NotificationThresholds.CRITICAL_ONLY 
              ? "Threshold: receive notifications only about critical warnings" 
              : "Threshold: receive notifications about all warnings"
            }
          </div>
        ) : null}
      </ConfirmModal>
      {loading && <RivataLoader/>}
      <PreferencesForm
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (preferences.phone !== phone && textNotificationOn) {
            setChangePhoneModal(true)
          } else {
            handleUpdateUserAttributes(1, false)
          }
        }}
        onTimezoneChange={setTimezone}
        onReceiveEmailChange={setEmailNotificationOn}
        onReceiveTextChange={setTextNotificationOn}
        onThresholdChange={setNotificationThreshold}
        onChangeUnitsOfMeasure={(units) => setUnitsOfMeasure(units)}
        unitsOfMeasure={unitsOfMeasure}
        phone={phone}
        onPhoneChange={setPhone}
        email={preferences.email}
        timezone={timezone}
        receiveEmail={emailNotificationOn}
        receiveText={textNotificationOn}
        threshold={notificationThreshold}
        isSaving={isSaving}
        hasLanguage={false}
        locale={locale}
        showUnitsOfMeasureSelector={true}
        clearMessage={() => setMessage(null)}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        isUserProfile={true}
        criticalAlertsTopicSubscriptionConfirmed={preferences.notifications.criticalAlertsTopicSubscriptionConfirmed}
        nonCriticalAlertsTopicSubscriptionConfirmed={preferences.notifications.nonCriticalAlertsTopicSubscriptionConfirmed}
      />
    </RivataModule>
  );
};

export default Preferences;
