import { isPlainObject, maxBy } from 'lodash'
import {
  LOADING_ASSET_LOCATION,
  SET_ASSET_LOCATION,
  SET_ASSET_LOCATION_ERROR,
  LOADING_VEHICLE_INFO,
  SET_VEHICLE_INFO,
  SET_VEHICLE_INFO_ERROR,
  LOADING_SENSOR_INFO,
  SET_SENSOR_INFO,
  SET_SENSOR_INFO_ERROR,
  LOADING_VIBRATIONS,
  SET_VIBRATIONS,
  SET_VIBRATIONS_ERROR,
  LOADING_TIRE_PRESSURE,
  SET_TIRE_PRESSURE,
  SET_TIRE_PRESSURE_ERROR,
  LOADING_AXLE_LOAD,
  SET_AXLE_LOAD,
  SET_AXLE_LOAD_ERROR,
  LOADING_LINE_PRESSURE,
  SET_LINE_PRESSURE,
  SET_LINE_PRESSURE_ERROR,
  LOADING_SPEED_DATA,
  SET_SPEED_DATA,
  SET_SPEED_DATA_ERROR,
  LOADING_TEMPERATURE_HISTORY,
  SET_TEMPERATURE_HISTORY,
  SET_TEMPERATURE_HISTORY_ERROR,
  LOADING_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS_ERROR,
  SET_ASSIGNED_TPMS_PROFILE,
  SET_AXLES_COUNT,
  SET_ASSET_TPMS_STATUS,
  LOADING_ASSET_TPMS_STATUS,
  SET_ACKNOWLEDGMENT_WARNING_COUNT,
  SET_RECENT_DETAILS_ERROR,
  SET_RECENT_DETAILS_COUNT,
  LOADING_RECENT_DETAILS,
  SET_RECENT_DETAILS,
  SET_RECENT_DETAILS_LIMIT,
  SET_RECENT_DETAILS_OFFSET,
  SET_RECENT_DETAILS_SHOW_ALL,
  SET_RECENT_DETAILS_FILTER,
  SET_WARNINGS_INTERVAL,
  RESET_DETAILS_REDUCER,
  LOADING_POWERINPUTS,
  SET_POWERINPUTS,
  SET_POWERINPUTS_ERROR
} from "../actionTypes";
import { DEFAULT_WINDOW, WARNING_TYPE } from "../../constants";
import {
  fetchDefaults,
} from '../common/action'
import api from "../../services/api";
import {
  getFormattedDataForLineChart,
  getToken,
  getFormattedDataForVibrationChart,
  convertDataEpochToDate,
  getErrorObj,
  composeTpmsProfiles,
  getColdInflationFromPercent,
  dateToEpoch,
  getCurrentEpoch
} from "../../utils";
import {setRecentWarningsOffset, setRecentWarningsLimit} from "../warnings/action";

const action = (type, payload) => ({ type, payload });

const getAssetId = (getState) => getState().details.vehicleInfo.data.id;

export const loadingAcknowledgements = (isPosting) => ({
  type: LOADING_ACKNOWLEDGEMENTS,
  payload: isPosting,
});

export const setAcknowledgementsError = (error) => ({
  type: SET_ACKNOWLEDGEMENTS_ERROR,
  payload: error,
});

export const setAcknowledgements = (acknowledgements) => ({
  type: SET_ACKNOWLEDGEMENTS,
  payload: acknowledgements,
});

export const setAssignedTpmsProfile = (profile) => ({
  type: SET_ASSIGNED_TPMS_PROFILE,
  payload: profile
})

export const setAcknowledgmentCount = (ackCount)=>({
  type: SET_ACKNOWLEDGMENT_WARNING_COUNT,
  payload: ackCount
})

///refactor
export const setRecentDetailsCount = (count) => ({
  type: SET_RECENT_DETAILS_COUNT,
  payload: count,
})

export const setRecentDetailsLimit = (limit) => ({
  type: SET_RECENT_DETAILS_LIMIT,
  payload: limit,
})

export const setRecentDetailsOffset = (offset) => {
  return {
      type: SET_RECENT_DETAILS_OFFSET,
      payload: offset,
  }
}

export const setRecentDetailsShowAll = (showAllWarnings) => ({
  type: SET_RECENT_DETAILS_SHOW_ALL,
  payload: showAllWarnings,
})

export const setRecentDetailsFilter = (payload) => ({
  type: SET_RECENT_DETAILS_FILTER,
  payload
})

export const setWarningsIntervals = (intervals)=> ({
  type: SET_WARNINGS_INTERVAL,
  payload:intervals})

export const resetDetailsReducer = () => ({
  type: RESET_DETAILS_REDUCER,
  payload: true
})

export function postAcknowledgements(acknowledgements, window) {
  return function (dispatch, getState) {
    dispatch(loadingAcknowledgements(true));
    return api
      .postAcknowledgements(acknowledgements, getToken(getState))
      .then(() => {
        dispatch(fetchRecentDetails(window));
        dispatch(setAcknowledgementsError(getErrorObj()));
      })
      .catch((error) => {
        dispatch(setAcknowledgementsError(getErrorObj(error)));
      })
      .finally(() => dispatch(loadingAcknowledgements(false)));
  };
}

export function fetchDetails(vin) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    dispatch(setRecentWarningsOffset(0));
    dispatch(setRecentWarningsLimit(10))
    dispatch(setRecentDetailsOffset(0));
    dispatch(setRecentDetailsLimit(10))
    dispatch(action(LOADING_VEHICLE_INFO, true));
    const selectedCustomer = getState().common.customers.selectedCustomer
    return api
      .getAsset(vin, token)
      .then((asset) => {
        const currentEpoch = getCurrentEpoch()
        let { startDate, endDate } = dateToEpoch()

        if (asset.gateways) {
          asset.gateways = convertDataEpochToDate(asset.gateways, "last_updated_at_epoch", "last_updated_at_datetime")

          const lastLocationEpoch = maxBy(asset.gateways, "last_updated_at_epoch")?.last_updated_at_epoch;

          if (lastLocationEpoch) {
            asset.lastLocationEpoch = lastLocationEpoch;
            startDate = lastLocationEpoch - 24 * 3600;
            endDate = lastLocationEpoch;
          }
        }
        else {
          // add fake gateway data, to display missing gateway in vehicle info
          asset.gateways = [{"mac": "-", "formatted_last_updated_at_datetime": "-"}]
        }

        const validSubscription = {}

        if (asset.subscription) {
          Object.keys(asset.subscription).map((subKey) => {
            const { valid_from, valid_to } = asset.subscription[subKey]
            if (currentEpoch >= valid_from && currentEpoch <= valid_to) {
              validSubscription[subKey] = asset.subscription[subKey]
            }
            return subKey
          })
        }
        asset.subscription = validSubscription

        dispatch(action(SET_VEHICLE_INFO, asset));
        if (asset.tpms_profile_id) dispatch(fetchTpmsProfile(asset.tpms_profile_id))
        if (selectedCustomer?.id !== asset.customer_id) dispatch(fetchDefaults(asset.customer_id))

        dispatch(fetchSensorNodeInfo());
        dispatch(fetchDetailsGps(startDate, endDate));
        dispatch(fetchRecentDetails(DEFAULT_WINDOW));
        dispatch(fetchVibrations(DEFAULT_WINDOW));
        dispatch(fetchTirePressure(DEFAULT_WINDOW));
        dispatch(fetchPowerInputs());
        if (asset.asset_type === "trailer") {
          dispatch(fetchAxleLoad(DEFAULT_WINDOW));
          dispatch(fetchLinePressure(DEFAULT_WINDOW));
        } else {
          dispatch(fetchAxleLoad(DEFAULT_WINDOW));
        }
        dispatch(fetchDetailsTemperatures(DEFAULT_WINDOW));
        dispatch(action(SET_VEHICLE_INFO_ERROR, getErrorObj()))
      })
      .catch((error) => {
        let message = `Asset with VIN ${vin} was not found`
        dispatch(action(SET_VEHICLE_INFO_ERROR, getErrorObj(error, message)))
      })
      .finally(() => dispatch(action(LOADING_VEHICLE_INFO, false)));
  };
}

export function fetchSensorNodeInfo() {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_SENSOR_INFO, true));
    return api
      .getSensorNodeInfo(id, token)
      .then((data) => {
        data = convertDataEpochToDate(data, "last_updated_at_epoch", "last_update");
        dispatch(action(SET_SENSOR_INFO, data));
        dispatch(action(SET_SENSOR_INFO_ERROR, getErrorObj()))
      })
      .catch((error) => dispatch(action(SET_SENSOR_INFO_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_SENSOR_INFO, false)));
  };
}

export function fetchDetailsGps(startDate, endDate) {
  return async function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_ASSET_LOCATION, true));
    let alldata = [];
    try {
      const data = await api.getGpsRange(id, startDate, endDate, token);
      const hasData = Array.isArray(data) && data.length > 0;
      if (hasData) {
        alldata = alldata.concat(data);
      }
      alldata = convertDataEpochToDate(alldata, "epoch");
      alldata.sort((a, b) => a.epoch > b.epoch ? 1 : -1)
      dispatch(action(SET_ASSET_LOCATION, alldata));
      dispatch(action(SET_ASSET_LOCATION_ERROR, getErrorObj()));
    } catch (error) {
      dispatch(action(SET_ASSET_LOCATION_ERROR, getErrorObj(error)));
    } finally {
      return dispatch(action(LOADING_ASSET_LOCATION, false));
    }
  };
}

export function fetchRecentDetails(window) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    const { limit, offset, showAllWarnings, filter } = getState().details.recentDetails
    dispatch(action(LOADING_RECENT_DETAILS, true));
    return api
      .getRecentWarnings(id, window, offset, limit, token, showAllWarnings, filter)
      .then((data) => {
        let intervals = data.intervals
        let pagedData = convertDataEpochToDate(data.paged_data, "epoch");
        pagedData = convertDataEpochToDate(pagedData, "recovered_to_normal_at_epoch", "recovered_to_normal_at_datetime");
        let acknowledged = data.acknowledged_count;
        dispatch(setRecentWarningsOffset(offset));
        dispatch(setWarningsIntervals(intervals))
        dispatch(setRecentWarningsLimit(limit));  
        dispatch(action(SET_RECENT_DETAILS, pagedData));
        dispatch(setRecentDetailsCount(data.total_count));
        dispatch(action(SET_RECENT_DETAILS_ERROR, getErrorObj()));
        dispatch(setAcknowledgmentCount(acknowledged))
      })
      .catch((error) => dispatch(action(SET_RECENT_DETAILS_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_RECENT_DETAILS, false)));
  };
}

export function fetchAssetTmpsStatus(assetId, type) {
  return async function (dispatch, getState) {
    const { selectedCustomer } = getState().common.customers
    const limit = 30
    const offset = 0

    dispatch(action(LOADING_ASSET_TPMS_STATUS, true));

    return api
      .getStatusAssets(getToken(getState), selectedCustomer?.id, limit, offset, type, null, null, null, null, assetId)
      .then((data) => {
        dispatch(action(SET_ASSET_TPMS_STATUS, data.paged_data?.data[0] || {}))
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(action(LOADING_ASSET_TPMS_STATUS, false)))
  }
}

export function fetchVibrations(window) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_VIBRATIONS, true));
    return api
      .getVibrations(id, window, token)
      .then((data) => {
        data = getFormattedDataForVibrationChart(data, true)
        dispatch(action(SET_VIBRATIONS, data));
        dispatch(action(SET_VIBRATIONS_ERROR, getErrorObj()));
      })
      .catch((error) => dispatch(action(SET_VIBRATIONS_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_VIBRATIONS, false)));
  };
}

export function fetchTirePressure(window) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_TIRE_PRESSURE, true));
    return api
      .getTirePressure(id, window, token)
      .then((data) => {
        const formattedData = getFormattedDataForLineChart(data);
        dispatch(action(SET_TIRE_PRESSURE, formattedData));
        dispatch(action(SET_TIRE_PRESSURE_ERROR, getErrorObj()));
      })
      .catch((error) => dispatch(action(SET_TIRE_PRESSURE_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_TIRE_PRESSURE, false)));
  };
}

export function fetchAxleLoad(window) {
  return function (dispatch, getState) {
    const token = getToken(getState)
    const id = getAssetId(getState)
    dispatch(action(LOADING_AXLE_LOAD, true))
    return api
      .getAxleLoad(token, id, window)
      .then((res) => {
        const { data, axles_count } = res
        dispatch(action(SET_AXLE_LOAD, data))
        dispatch(action(SET_AXLES_COUNT, axles_count))
        dispatch(action(SET_AXLE_LOAD_ERROR, getErrorObj()))
      })
      .catch(error => dispatch(action(SET_AXLE_LOAD_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_AXLE_LOAD, false)))
  }
}

export function fetchLinePressure(window) {
  return function (dispatch, getState) {
    const token = getToken(getState)
    const id = getAssetId(getState)
    dispatch(action(LOADING_LINE_PRESSURE, true))
    return api
      .getLinePressure(token, id, window)
      .then((data) => {
        dispatch(action(SET_LINE_PRESSURE, data))
        dispatch(action(SET_LINE_PRESSURE_ERROR, getErrorObj()))
      })
      .catch(error => dispatch(action(SET_LINE_PRESSURE_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_LINE_PRESSURE, false)))
  }
}

export function fetchSpeedData(window) {
  return function (dispatch, getState) {
    const token = getToken(getState)
    const id = getAssetId(getState)
    dispatch(action(LOADING_SPEED_DATA, true))
    return api
      .getSpeedData(token, id, window, null, null, true)
      .then((data) => {
        dispatch(action(SET_SPEED_DATA, data))
      })
      .catch(error => dispatch(action(SET_SPEED_DATA_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_SPEED_DATA, false)))
  }
}

export function fetchDetailsTemperatures(window) {
  return function (dispatch, getState) {
    const token = getToken(getState);
    const id = getAssetId(getState);
    dispatch(action(LOADING_TEMPERATURE_HISTORY, true));
    
    return api
      .getTemperatures(id, window, token)
      .then((data) => {
        data = getFormattedDataForLineChart(data, true);
        dispatch(action(SET_TEMPERATURE_HISTORY, data));
        dispatch(action(SET_TEMPERATURE_HISTORY_ERROR, getErrorObj()));
      })
      .catch((error) => dispatch(action(SET_TEMPERATURE_HISTORY_ERROR, getErrorObj(error))))
      .finally(() => dispatch(action(LOADING_TEMPERATURE_HISTORY, false)));
  };
}

export function fetchTpmsProfile(id) {
  return function (dispatch, getState) {
    const token = getToken(getState)
    return api
      .getWarningsSettings(token, WARNING_TYPE.TIRE_PRESSURE_SENSOR, null, id)
      .then((warningSettings) => {
        const profiles = composeTpmsProfiles(warningSettings)
        if (profiles.length) {
          let profile = profiles[0]
          profile.thresholds = {}
          const { low_pressure_in_percent, critical_low_pressure_in_percent, over_pressure_in_percent, critical_over_pressure_in_percent } = profile

          Object.keys(profile.axels).map(typeKey => {
            if (!isPlainObject(profile.axels[typeKey])) return null
            const thresholds = Object.keys(profile.axels[typeKey]).reduce((prev, current) => {
              const cold_inflation_pressure_in_psi = +profile.axels[typeKey][current]
              const axleThresholds = {
                low_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, low_pressure_in_percent),
                critical_low_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, critical_low_pressure_in_percent),
                over_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, over_pressure_in_percent),
                critical_over_pressure: +getColdInflationFromPercent(cold_inflation_pressure_in_psi, critical_over_pressure_in_percent),
              }

              const obj = { ...prev, [current]: axleThresholds }

              return obj
            }, {})
            return profile.thresholds[typeKey] = thresholds
          })
          return dispatch(setAssignedTpmsProfile(profile))
        }
      })
      .catch((err) => console.log(err))
  }
}

export function fetchPowerInputs() {
  return function (dispatch, getState) {
    const token = getToken(getState)
    const assetId = getAssetId(getState)
    const isSuperAdmin = getState().auth.isSuperAdmin

    if (!isSuperAdmin) return
    
    dispatch(action(LOADING_POWERINPUTS, true))
    return api
      .getpowerInput(assetId, token)
      .then(res => {
        dispatch(action(SET_POWERINPUTS, res ))
        dispatch(action(SET_POWERINPUTS_ERROR, getErrorObj() ))
      })
      .catch(err => {
        dispatch(action(SET_POWERINPUTS_ERROR, getErrorObj(err) ))
      })
      .finally(() => dispatch(action(LOADING_POWERINPUTS, false)))
  }
}
