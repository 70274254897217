import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import RivataModule from "../../components/RivataModule";
import OrderItem from "./OrderItem";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (_isDraggingOver) => ({
  //background: isDraggingOver ? "lightblue" : "grey",
  //padding: grid,
  // width: 250,
  overflow: "visible",
});

const ModuleOrder = ({ title, initialItems = [], onUpdate, locale, width = 6 }) => {
  const [items, setItems] = useState(initialItems);
  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);
    onUpdate(newItems);
    setItems(newItems);
  };

  const onItemUpdated = (item) => {
    const newItems = items.map((curr) => (curr.id === item.id ? item : curr));
    onUpdate(newItems);
    setItems(newItems);
  };

  return (
    <RivataModule title={title} width={width} locale={locale}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <table className="table-responsive" style={{ overflow: "visible" }}>
              <tbody
                ref={droppableProvided.innerRef}
                style={getListStyle(droppableSnapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <OrderItem key={item.id} item={item} locale={locale} index={index} onItemUpdated={onItemUpdated} />
                  ))}
                {droppableProvided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </RivataModule>
  );
};

export default ModuleOrder;
