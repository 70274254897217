import { useEffect, useState } from 'react'
import { convertDataEpochToDate } from '../../utils'

export const useTableRows = (data, putHardwareCommand, onLogButtonPress) => {
    const [body, setBody] = useState([])

    useEffect(() => {
      const sendHardwareCommand = (id, value) => {
        const body = {
          "hardware_command_id": id,
          "ready_to_be_sent": `${value}`,
        }
        putHardwareCommand(body)
      }

      const isHidden = (row) => row.status === "processed"
  
      const tableBody = data.map((row) => {
          return {
              columns: [
                {
                  type: "text",
                  label: row.hardware_command_id,
                  columnId: "hardwareCommandId"
                },
                {
                  type: "text",
                  label: convertDataEpochToDate(row.created, null, null, true, true, true) || "-",
                  columnId: "Created",
                  assetId: row.hardware_command_id,
                  addBreak: true
                },
                {
                  type: "text",
                  label: row.command_type || "-",
                  columnId: "CommandType",
                  data: "",
                  assetId: row.hardware_command_id,
                },
                {
                  type: "text",
                  label: row.status,
                  columnId: "Status",
                  assetId: row.hardware_command_id,
                },
                {
                  type: "json",
                  label: row.payload,
                  columnId: "json",
                  assetId: row.hardware_command_id,
                  width: "400px"
                },
                {
                  type: "json",
                  label:  row.payloadDetails,
                  columnId: "jsonDetails",
                  assetId: row.hardware_command_id,
                  width: "300px"
                },
                {
                  type: "text",
                  label:  (row.delivery_confirmed_at_epoch && convertDataEpochToDate(row.delivery_confirmed_at_epoch, null, null, true, false, true)) || "-",
                  columnId: "deliveryConfirmedAt",
                  data: "date",
                  assetId: row.hardware_command_id
                },
                {
                  type: "text",
                  label:  (row.delivery_last_attempt_at_epoch && convertDataEpochToDate(row.delivery_last_attempt_at_epoch, null, null, true, false, true)) || "-",
                  columnId: "deliveryLastAttempt",
                  data: "date",
                  assetId: row.hardware_command_id
                },
                {
                  type: "text",
                  label: row.delivery_attempts_count || "-",
                  columnId: "deliveryAttemptsCount",
                  assetId: row.hardware_command_id,
                },
                {
                  type: "buttons",
                  columnId: "MarkAsReadyToBeSent",
                  assetId: row.hardware_command_id,
                  buttons: [
                    {
                      type: "button",
                      label: "Show log",
                      disabled: !row.log,
                      onClick: () => onLogButtonPress(row.log, row.hardware_command_id),
                      style: { width: "80px", marginBottom: "10px" }
                    },
                    {
                      type: "button",
                      label: "Mark",
                      onClick: () => sendHardwareCommand(row.hardware_command_id, true),
                      disabled: row.status === "ready to be sent" || row.status === "command_sending_in_progress",
                      style: isHidden(row) ? { display: "none" } : { width: "80px", marginBottom: "10px" }
                    },
                    {
                      type: "button",
                      label: "Cancel sending",
                      onClick: () => sendHardwareCommand(row.hardware_command_id, false),
                      disabled: row.status === "generated",
                      style: isHidden(row) ? { display: "none" } : { width: "80px" }
                    }
                  ]
                },
              ],
              id: row.hardware_command_id
          }
      })

      setBody(tableBody)
    }, [data, putHardwareCommand, onLogButtonPress])

    return body
}

export const useTableColumns = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        const columns = [
            {
                label: "Hardware Command Id",
                id: "hardwareCommandId"
            },
            {
                label: "Created",
                id: "Created",
            },
            {
                label: "Command Type",
                id: "CommandType",
            },
            {
                label: "Status",
                id: "Status",
            },
            {
                label: "Payload",
                id: "Payload",
            },
            {
                label: "Payload details",
                id: "PayloadDetails",
            },
            {
                label: "Delivery Confirmed At",
                id: "deliveryConfirmedAt"
            },
            {
                label: "Delivery Last Attempt At",
                id: "deliveryLastAttemptAt"
            },
            { 
                label: "Delivery Attempts Count",
                id: "deliveryAttemptsCount"
            },
            {
                label: "",
                id: "MarkAsReadyToBeSent",
                customStyle: "th_button"
            }
        ]

        setData(columns)
    }, [])

    return data
}