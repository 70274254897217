import React, { useState, useEffect} from "react";
import moment from "moment";
import TripReport from "./Forms/TripReportForm";
import SensorReportsForm from "./Forms/SensorReportsForm";
import { ReportEndpoints, ReportTypes } from "../../enums";
import { DateInput } from "../../components/DateRangeCalendar";
import { dateToEpoch } from "../../utils";
import { getStrFromArray } from "./utils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import "./style.scss";

const ReportsFormSwitch = ({ selectedReport, setReportLink, disabled}) => {
  const [dateFrom, setDateFrom] = useState(
    new Date(moment().clone().startOf("month").unix() * 1000)
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [assetType, setAssetType] = useState("all");
  const [vinList, setVinList] = useState([]);
  const {selectedCustomer} = useTypedSelector(
    (state) => ({
      selectedCustomer: state.common.customers.selectedCustomer
    })
  );

  useEffect(() => {
    const { startDate, endDate } = dateToEpoch(dateFrom, dateTo);
    const reportType = ReportEndpoints[selectedReport];
    let link = `?type=${reportType}`;
    let vinsStr = getStrFromArray(vinList, ":", "all");
    if (selectedCustomer?.id) {
      link += `&customer_id=${selectedCustomer.id}`
  }
    switch (selectedReport) {
      case ReportTypes.USER_LOGINS:
      case ReportTypes.HARDWARE_COMMANDS_HISTORY:
        link += `&epoch_from=${startDate}&epoch_to=${endDate}`;
        break;
      case ReportTypes.TPMS_REPORT:
      case ReportTypes.DEVICE_HEALTH_REPORT:
        link += `&vins=${vinsStr}`;
        if (assetType && assetType !== "all") {
          link += `&asset_type=${assetType}`;
        }
        break;
      case ReportTypes.DAILY_MILEAGE_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`;
        break;
      case ReportTypes.DISTANCE_TRAVELED_REPORT:
      case ReportTypes.GEOFENCES_REPORT:
        link += `&vins=${vinsStr}&epoch_from=${startDate}&epoch_to=${endDate}`;
        if (assetType && assetType !== "all") {
          link += `&asset_type=${assetType}`;
        }
        break;
      default:
        break;
    }
    setReportLink(link);
  }, [dateFrom, dateTo, selectedReport, setReportLink, assetType, vinList, selectedCustomer]);

  useEffect(() => {
    if (selectedReport === ReportTypes.TRIP_REPORT || selectedReport === ReportTypes.DISTANCE_TRAVELED_REPORT) {
      setDateFrom(new Date(moment().subtract(3, "days").unix() * 1000));
      setDateTo(new Date());
    } else if (
      selectedReport === ReportTypes.USER_LOGINS ||
      selectedReport === ReportTypes.HARDWARE_COMMANDS_HISTORY
    ) {
      setDateFrom(new Date(moment().startOf("month").unix() * 1000));
      setDateTo(new Date());
    }
  }, [selectedReport]);

  switch (selectedReport) {
    case ReportTypes.USER_LOGINS:
    case ReportTypes.HARDWARE_COMMANDS_HISTORY:
      return (
        <DateInput
          onSelect={(from, to) => {
            setDateFrom(from);
            setDateTo(to);
          }}
          from={dateFrom}
          to={dateTo}
          disabled={disabled}
        />
      );
    case ReportTypes.TPMS_REPORT:
    case ReportTypes.DEVICE_HEALTH_REPORT:
      return (
        <SensorReportsForm
          setVinList={(list) => setVinList(list)}
          selected={assetType}
          disabled={disabled}
          setSelected={(id) =>
            id === "all" ? setAssetType(null) : setAssetType(id)
          }
        />
      );
    case ReportTypes.DAILY_MILEAGE_REPORT:
      return (
        <TripReport
          onSelectDate={(from, to) => {
            setDateFrom(from);
            setDateTo(to);
          }}
          setVinList={(list) => setVinList(list)}
          dateFrom={dateFrom}
          dateTo={dateTo}
          disabled={disabled}
        />
      );
    case ReportTypes.GEOFENCES_REPORT:
    case ReportTypes.DISTANCE_TRAVELED_REPORT:
      const minDate = selectedReport === ReportTypes.DISTANCE_TRAVELED_REPORT ? new Date(2022, 0, 1) : null
      return (
        <>
          <DateInput
                onSelect={(from, to) => {
                if (from < new Date(2022, 0, 1)) {
                setDateFrom(new Date(2022, 0, 1));
                setDateTo(to);
                }
                else {
                setDateFrom(from);
                setDateTo(to);
                }
              }}
            from={dateFrom}
            to={dateTo}
            disabled={disabled}
            minDate={minDate}
          />
          <div style={{marginTop: "30px"}}>
          <SensorReportsForm
            setVinList={(list) => setVinList(list)}
            selected={assetType}
            disabled={disabled}
            setSelected={(id) =>
              id === "all" ? setAssetType(null) : setAssetType(id)
            }
          />
          </div>
          
        </>
      );
    default:
      return null;
  }
};

export default ReportsFormSwitch;
