import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row } from "reactstrap";
import AssetTable from "../../modules/AssetTable";
import TpmsStatusTable from '../../modules/TpmsStatusTable';
import classnames from 'classnames';
import './tables.scss'

const TablesWrapper = ({
    isSuperAdmin,
    assets,
    tpmsStatusTractorAssets,
    tpmsStatusTrailerAssets,
    customerDefaults,
    fetchPagedTpmsStatusTractorAssets,
    fetchPagedTpmsStatusTrailerAssets,
    assetsError,
    isLoading,
    isLoadingStatus,
    isLoadingMap,
    isLoadingAcknowledgements,
    postAcknowledgements,
    onSelected,
    gps,
    locations,
    warnings,
    width,
    whiteLabelUrl,
    locale,
    preferences,
    assetsCount,
    onFilterChange,
    pageLimit,
    daysCount,
    onSelectLimit,
    onPageChange,
    setAssetsSortOptions,
    setTpmsStatusTractorAssetsSortOptions,
    setTpmsStatusTrailerAssetsSortOptions,
    setTpmsStatusTractorAssetsFilter,
    setTpmsStatusTrailerAssetsFilter,
    setTpmsStatusTractorAssetsLimit,
    setTpmsStatusTrailerAssetsLimit,
    setTpmsStatusTractorAssetsOffset,
    setTpmsStatusTrailerAssetsOffset,
    setHideAutogeneratedAssets,
    hideAutogeneratedAssets
}) => {
    const [activeTab, setActiveTab] = useState('assets');

    const toggleTab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
        // reset filter after tab change
        onFilterChange({})
    }

    const currentDate = new Date()
    
    return (
        <div className="tables-wrapper mt-4">
            <Nav tabs className="ml-3 mr-3">
                <NavItem className="tab-item">
                    <NavLink
                        className={"assets_tab " + classnames({ "active-table_tab": activeTab === 'assets' })}
                            onClick={() => { toggleTab('assets') }}
                        >
                        Assets
                    </NavLink>
                </NavItem>
                {(customerDefaults.subscriptions?.TIRE_SENSOR && customerDefaults.subscriptions?.TIRE_SENSOR?.valid_to >= +(+currentDate / 1000).toFixed(0)) || isSuperAdmin ? (
                    <NavItem className="tab-item">
                        <NavLink
                            className={"assets_tab " + classnames({ "active-table_tab": activeTab === 'tpmsStatus' })}
                            onClick={() => { 
                                toggleTab('tpmsStatus')
                                fetchPagedTpmsStatusTractorAssets()
                                fetchPagedTpmsStatusTrailerAssets()
                            }}
                        >
                            TPMS Status
                        </NavLink>
                    </NavItem>
                ) :  null}
            </Nav>
            <TabContent activeTab={activeTab} className="ml-3 mr-3">
                <TabPane tabId="assets">
                    {activeTab === 'assets' ? (
                        <Row>
                            <AssetTable
                                isSuperAdmin={isSuperAdmin}
                                assets={assets}
                                assetsError={assetsError}
                                warnings={warnings}
                                gps={gps}
                                isLoading={isLoading}
                                isLoadingMap={isLoadingMap}
                                isLoadingStatus={isLoadingStatus}
                                onSelected={onSelected}
                                width={width}
                                whiteLabelUrl={whiteLabelUrl}
                                locale={locale}
                                isLoadingAcknowledgements={isLoadingAcknowledgements}
                                postAcknowledgements={postAcknowledgements}
                                preferences={preferences}
                                locations={locations}
                                assetsCount={assetsCount}
                                onFilterChange={onFilterChange}
                                pageLimit={pageLimit}
                                daysCount={daysCount}
                                onSelectLimit={onSelectLimit}
                                onPageChange={onPageChange}
                                setSortOptions={setAssetsSortOptions}
                                defaults={customerDefaults}
                                setHideAutogeneratedAssets={setHideAutogeneratedAssets}
                                hideAutogeneratedAssets={hideAutogeneratedAssets}
                            />
                        </Row>
                    ) : null}
                </TabPane>
                {customerDefaults.subscriptions?.TIRE_SENSOR || isSuperAdmin ? (
                    <TabPane tabId="tpmsStatus">
                        {activeTab === "tpmsStatus" ? (
                            <>
                                <Row>
                                    <TpmsStatusTable
                                        title={"Tractor Tire Status"}
                                        tpmsStatusAssets={tpmsStatusTractorAssets}
                                        defaults={customerDefaults}
                                        width={width}
                                        whiteLabelUrl={whiteLabelUrl}
                                        preferences={preferences}
                                        locale={locale}
                                        isShowingLimit={true}
                                        setSortOptions={setTpmsStatusTractorAssetsSortOptions}
                                        setFilter={setTpmsStatusTractorAssetsFilter}
                                        setLimit={setTpmsStatusTractorAssetsLimit}
                                        setOffset={setTpmsStatusTractorAssetsOffset}
                                    />
                                </Row>
                                <Row>
                                    <TpmsStatusTable
                                        title={"Trailer Tire Status"}
                                        tpmsStatusAssets={tpmsStatusTrailerAssets}
                                        defaults={customerDefaults}
                                        width={width}
                                        whiteLabelUrl={whiteLabelUrl}
                                        preferences={preferences}
                                        locale={locale}
                                        isShowingLimit={true}
                                        setSortOptions={setTpmsStatusTrailerAssetsSortOptions}
                                        setFilter={setTpmsStatusTrailerAssetsFilter}
                                        setLimit={setTpmsStatusTrailerAssetsLimit}
                                        setOffset={setTpmsStatusTrailerAssetsOffset}
                                    />
                                </Row>
                            </>
                        ) : null}
                    </TabPane>
                ) : null}
            </TabContent>
        </div>
    )
}

export default TablesWrapper