import React, { useState, useEffect } from 'react';
import { UnitsOfMeasurement } from '../../enums'

const Thead = ({ columns, onSort, sortColumn, setSortColumn, preferences: { unitsOfMeasurement } }) => {
    const [sortDirection, setSortDirection] = useState(null)
    const sortColumns = [
        { id: "asset_name", label: "Asset Name" },
        { id: "vin", label: "VIN" },
        { id: "latest_update_at_epoch", label: "Last Gateway Msg" },
        { id: "warning_setting_name", label: "TPMS Profile" }
    ]

    useEffect(() => {
        if (!sortDirection || !sortColumn || !onSort) return
        onSort(sortColumn, sortDirection)
    }, [sortDirection, sortColumn, onSort])

    const getClearLabel = (th) => {
        let label = th
        label = label.toLowerCase().split("_").join(" ").split("")
        label[0] = label[0].toUpperCase()
        label.map((char, i) => {
            if (char === " ") {
                label[i + 1] = label[i + 1].toUpperCase()
            }
            return true
        })
        return label.join("") + ` (${unitsOfMeasurement === UnitsOfMeasurement.imperial ? "PSI" : "BAR"})`
    }

    const sortHandler = (id) => {
        let currentDirection = sortDirection

        if (sortColumn !== id) {
            setSortColumn(id)
            currentDirection = null
        }

        if (!currentDirection) {
            currentDirection = "desc"
        } else if (currentDirection === "desc") {
            currentDirection = "asc"
        } else {
            currentDirection = "desc"
        }
        setSortDirection(currentDirection)
    }

    return (
        <thead>
            <tr>
                {sortColumns.map((item, i) => (
                    <th 
                        key={i}
                        onClick={() => sortHandler(item.id)} 
                    >
                        <div className="th-items__wrapper">
                            {item.label}
                            {!sortDirection || sortColumn !== item.id ? (
                                <i className="fas fa-sort" />
                            ) : sortDirection === "desc" ? (
                                <i className="fas fa-sort-down" />
                            ) : (
                                <i className="fas fa-sort-up" />
                            )}
                        </div>
                    </th>
                ))}
                {columns.map((th, i) => {
                    if (columns.length > 1 && th === "NODATA") return null
                    return <th key={i} className="tpms-column">{getClearLabel(th)}</th>
                })}
            </tr>
        </thead>
    )
}

export default Thead