import React from 'react';
import { Alert } from 'reactstrap'

const ErrorIndicator = ({ error, color = 'danger' }) => {
    const { statusCode, message } = error;
    let title = `ERROR ${statusCode}: ${message}`;
    let desc = '';

    if (statusCode >= 500 && statusCode <= 526) {
        desc = `Please try again later.`
    } else if (statusCode === 404) {
        desc = `Please try again later.`
    } else {
        title = `Oops we've encountered an issue.`
        desc = `Please try again later.`
    }

    return (
        <Alert color={color}>
            <div className="error-indicator">
                <h6>{title}</h6>
                <p>{desc}</p>
            </div>
        </Alert>
    )
}

export default ErrorIndicator;