import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Formik } from 'formik'
import { Form, Row, Col, Button } from 'reactstrap'
import * as Yup from 'yup'
import RivataDropdown from '../RivataDropdown'
import UnitsOfMeasureToggle from '../UnitsOfMeasureToggle'
import RivataFormField from '../../components/RivataFormField'
import InfoModal from '../../components/InfoModal'
import { DateInput } from '../../components/DateRangeCalendar'
import { UnitsOfMeasurement } from '../../enums'
import {
    validateDatetimeRange,
    getCurrentEpoch,
    getEpochWithEndDay, 
    addDaysToEpoch, 
    getEpochsDifferenceInDays,
    lbsToKg,
    addHoursToEpoch
} from '../../utils'
import './style.scss'

const dropdownItems = [
    { id: 0, label: "Empty" },
    { id: 1, label: "Loaded" }
]

const AxleLoadCalibrationForm = ({
    existingCalibrationData,
    unitsOfMeasure,
    onChangeUnitsOfMeasure,
    formValues,
    setFormValues,
    onSubmit,
    selectedAsset,
    error
}) => {
    const [ dateError, setdateError ] = useState(null)
    const [ dateRangeEpochs, setDateRangeEpochs ] = useState(null)
    const [ statusMessage, setStatusMessage ] = useState(null)
    const [ minCalendarDate, setMinCalendarDate ] = useState(null)

    const schema = Yup.object({
        vehicle_weight: Yup.number().required("This field is required").positive("Value must be positive")
    })

    useEffect(() => {
        if (error.statusCode >= 400) {
            setdateError(error.message)
            setTimeout(() => {
                setdateError(null)
            }, 10000)
        }
    }, [error])

    useEffect(() => {
        if (!existingCalibrationData.length) return setMinCalendarDate(946688400)
        const currentEpoch = getCurrentEpoch()
        let initialEpoch = existingCalibrationData[0].timestamp_from

        if (existingCalibrationData[0].timestamp_till < currentEpoch && existingCalibrationData[0].timestamp_till > initialEpoch) {
            initialEpoch = existingCalibrationData[0].timestamp_till
        }
        
        const initialWithEndOfDay = getEpochWithEndDay(initialEpoch)
        
        if (existingCalibrationData.length === 1 && initialWithEndOfDay < currentEpoch) {
            let endOfDayWithAdditionalHours = addHoursToEpoch(initialWithEndOfDay, 12)
            return setMinCalendarDate(endOfDayWithAdditionalHours)
        }

        let epoch = initialEpoch
        existingCalibrationData.map((item) => {
            if (item.timestamp_till < currentEpoch && epoch < item.timestamp_till) {
                epoch = item.timestamp_till
            }
            if (epoch < item.timestamp_from) {
                epoch = item.timestamp_from
            }
            return item
        })

        if (epoch) {
            const epochWithEndOfDay = getEpochWithEndDay(epoch)
            if (epochWithEndOfDay < currentEpoch) {
                return setMinCalendarDate(epochWithEndOfDay)
            }
            
            return setMinCalendarDate(epoch)
        }
    }, [existingCalibrationData])

    useEffect(() => {
        getEpochsDifferenceInDays(minCalendarDate, getEpochWithEndDay())
        if (getEpochsDifferenceInDays(minCalendarDate, getEpochWithEndDay()) < 1) {
            setdateError("You already have calibration data with current date, you can not add calibration data with future date")
        } else {
            setdateError(null)
        }
    }, [minCalendarDate])

    return (
        <div className="axle-load-calibration-form" >
            <InfoModal header={"Calibration Info"} message={statusMessage || ""} open={!!statusMessage} onConfirm={() => setStatusMessage(null)} />
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={{
                    vehicle_weight: ""
                }}
                onSubmit={async (values, { resetForm }) => {
                    const body = {
                        asset_id: selectedAsset.id,
                        calibration_data: {
                            is_loaded: formValues.isLoaded === 0 ? false : true,
                            vehicle_weight: unitsOfMeasure === UnitsOfMeasurement.imperial ? lbsToKg(values.vehicle_weight) : values.vehicle_weight,
                            epoch_from: dateRangeEpochs.rangeStart
                        }
                    }
                    const res = await onSubmit(body)

                    if (res.statusCode === 201) { // add success message
                        setStatusMessage(res.message)
                        resetForm()
                        const minEpoch = addDaysToEpoch(minCalendarDate, 1)
                        const currentEpoch = getCurrentEpoch()
                        if (minEpoch > currentEpoch) {
                            return
                        } 

                        const epochWithAdditionalDay = addDaysToEpoch(dateRangeEpochs.rangeStart, 1)
                        
                        if (epochWithAdditionalDay < currentEpoch) {
                            return setFormValues({ ...formValues, dateStart: new Date(epochWithAdditionalDay * 1000)})
                        }

                        setFormValues({ ...formValues, dateStart: new Date(dateRangeEpochs.rangeStart * 1000)})
                        setMinCalendarDate(dateRangeEpochs.rangeStart)
                    }
                }}
            >
                {(props) => {
                    const { submitForm } = props

                    return (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                const validRanges = validateDatetimeRange(existingCalibrationData, formValues) 

                                if (moment(formValues.dateStart).unix() > moment().unix()) {
                                    setdateError("Date can not be more than current date!")
                                    setTimeout(() => {
                                        setdateError(null)
                                    }, 10000)
                                    return 
                                }
                                
                                if (Object.keys(validRanges).length) {
                                    submitForm()
                                    setdateError(null)
                                    setDateRangeEpochs(validRanges)
                                } else {
                                    setdateError("Datetime range error, date ranges must not match!")
                                    setTimeout(() => {
                                        setdateError(null)
                                    }, 10000)
                                }
                            }}  
                        >
                            <fieldset disabled={!selectedAsset?.id || getEpochsDifferenceInDays(minCalendarDate, getEpochWithEndDay()) < 1 ? true : false}>
                                <Row className="mb-3 d-flex justify-content-between">
                                    <Col>
                                        <RivataDropdown
                                            items={dropdownItems}
                                            onSelect={(id) => {
                                                setFormValues({ ...formValues, isLoaded: id })
                                            }}
                                            selected={formValues.isLoaded}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <UnitsOfMeasureToggle 
                                            unitsOfMeasure={unitsOfMeasure} 
                                            onChangeUnitsOfMeasure={onChangeUnitsOfMeasure}
                                        />
                                    </Col>
                                </Row>

                                <Row className="d-flex mb-3 calibration-row" >
                                    <RivataFormField type="number" md="2" name="vehicle_weight" label={"Vehicle Weight"} />
                                    <label 
                                        className="mr-5 units-of-measure__label"
                                    >
                                        {unitsOfMeasure === UnitsOfMeasurement.imperial ? "lbs" : "kg"}
                                    </label>

                                    <DateInput
                                        onSelect={(dateStart) => {
                                            setFormValues({
                                                ...formValues,
                                                dateStart
                                            })
                                        }}
                                        isSingleDate={true}
                                        to={formValues.dateStart}
                                        title={"Select Vehicle Weight Date Period"}
                                        customClassNames={"calibration-form__date-range"}
                                        disabled={!selectedAsset?.id || getEpochsDifferenceInDays(minCalendarDate, getEpochWithEndDay()) < 1 ? true : false}
                                        minDate={minCalendarDate && new Date(minCalendarDate * 1000)}
                                    />
                                </Row>

                                <Row className="d-flex justify-content-between">
                                    <Col>
                                        {dateError ? (
                                            <div className="error">{dateError}</div>
                                        ) : null}
                                    </Col>
                                    <Col md="2" className="d-flex justify-content-end">
                                        <Button className="mr-3" type="submit" >Submit</Button>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default AxleLoadCalibrationForm