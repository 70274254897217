import api from "../../services/api";
import { store } from "../store";
import {Dispatch } from "redux";
import { API_OK_RESULT } from "../../constants";
import { IgetState } from "../types";
import {
  GeofencesActionTypes,
  EventsFilter,
  GeofencesSortPayload,
} from "./types";
import {
  getToken,
  convertDataEpochToDate,
  getErrorObj,
  dateToEpoch,
} from "../../utils";
import { ActivityEvent } from "../../modules/Geofences/types";
import { setSelectedCustomer } from "../common/action";

export const setGeofencesLoading = (isLoading: boolean) => ({
  type: GeofencesActionTypes.LOADING_GEOFENCES,
  payload: isLoading,
});

export const setPagedGeofences = (geofences: Array<IGeofence>) => ({
  type: GeofencesActionTypes.SET_PAGED_GEOFENCES,
  payload: geofences,
});

export const setCustomerGeofences = (geofences: Array<IGeofence> | []) => {
  return {
    type: GeofencesActionTypes.SET_CUSTOMER_GEOFENCES,
    payload: geofences,
  };
};

export const setActivityEvents = (
  activityEvents: Array<ActivityEvent> | []
) => ({
  type: GeofencesActionTypes.SET_ACTIVITY_EVENTS,
  payload: activityEvents,
});

export const setGeofencesError = (error: IError) => ({
  type: GeofencesActionTypes.SET_GEOFENCES_ERROR,
  payload: error,
});

export const setGeofencesOffset = (offset: number) => ({
  type: GeofencesActionTypes.SET_GEOFENCES_OFFSET,
  payload: offset,
});

export const setGeofencesLimit = (limit: number) => ({
  type: GeofencesActionTypes.SET_GEOFENCES_LIMIT,
  payload: limit,
});

const setTotalCount = (count: number) => ({
  type: GeofencesActionTypes.SET_GEOFENCES_TOTAL_COUNT,
  payload: count,
});

export const setEventsOffset = (offset: number) => ({
  type: GeofencesActionTypes.SET_EVENTS_OFFSET,
  payload: offset,
});

const setEventsTotalCount = (count: number) => ({
  type: GeofencesActionTypes.SET_GEOFENCES_EVENTS_TOTAL_COUNT,
  payload: count,
});

export const setEventsFilter = (filter: EventsFilter) => ({
  type: GeofencesActionTypes.SET_EVENTS_FILTER,
  payload: filter,
});

export const setEventsLimit = (limit: number) => ({
  type: GeofencesActionTypes.SET_EVENTS_LIMIT,
  payload: limit,
});

export const setGeofencesSortOptions = (sort: GeofencesSortPayload) => {
  return {
    type: GeofencesActionTypes.SET_GEOFENCES_SORT_OPTIONS,
    payload: sort,
  };
};
export const setCustomerId = (id: number) => {
    return {
      type: GeofencesActionTypes.SET_CUSTOMER_ID,
      payload: id,
    };
}

export function fetchGeofences() {
  return function (dispatch: Dispatch, getState: IgetState) {
    dispatch(setGeofencesLoading(true));

    const state = getState();

    const { selectedCustomer } = state.common.customers;

    const {
      limit,
      offset,
      sortOptions: { column, direction },
    } = state.geofences;

    return api
      .getGeoLocations(
        getToken(getState),
        selectedCustomer?.id,
        limit,
        offset,
        true,
        column,
        direction
      )
      .then((geofences) => {
        const totalCount = geofences.total_count;

        geofences = convertDataEpochToDate(
          geofences.paged_data,
          "created_at_epoch",
          "created_at_datetime"
        );

        dispatch(setPagedGeofences(geofences));
        dispatch(setTotalCount(totalCount));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setGeofencesLoading(false));
      });
  };
}

export function fetchGeofenceGredentials(
  location_id: number,
  start: Date | null,
  end: Date | null,
  vin: string | null
) {
  return function (dispatch: Dispatch<any>, getState: IgetState) {
    const token = getToken(getState);
    const state = getState();
    return api
      .getGeoLocationCredentials(location_id, token)
      .then((data) => {
        const customerId = data["customer_id"]
        const lastActivityEpoch = data["last_event_epoch"];
        const customer = state.common.customers.data.find((el: any) => el.id === customerId)
        
        dispatch(setSelectedCustomer(customer));

        const dateToFetch = lastActivityEpoch
          ? new Date(lastActivityEpoch * 1000)
          : new Date();
        const { startDate, endDate } = dateToEpoch(dateToFetch, dateToFetch);
        const isVin = vin ? vin : "";

        dispatch(setCustomerId(customerId));
        
        if (start && end) {
          const { startDate, endDate } = dateToEpoch(start, end);
          dispatch(
            setEventsFilter({
              selectedGeofenceId: location_id,
              startDate,
              endDate,
              assetVin: isVin,
            })
          );
        }
        if (!start && !end) {
          dispatch(
            setEventsFilter({
              selectedGeofenceId: location_id,
              startDate,
              endDate,
              assetVin: isVin,
            })
          );
        }
        dispatch(fetchActivityEvents());
      })
      .catch((e) => console.log(e));
  };
}

export function fetchCustomerGeofences() {
  return function (dispatch: Dispatch, getState: IgetState) {
    const {
      common,
      auth: { isAdmin },
    } = getState();
    const { selectedCustomer } = common.customers;

    const id = selectedCustomer?.id;

    if (isAdmin && !id) {
      dispatch(setCustomerGeofences([]));
      return;
    }

    dispatch(setGeofencesLoading(true));
    return api
      .getCustomerGeofences(getToken(getState), id)
      .then((geofences) => {
        geofences = convertDataEpochToDate(
          geofences.paged_data,
          "created_at_epoch",
          "created_at_datetime"
        );

        dispatch(setCustomerGeofences(geofences));
      })
      .catch((err) => {
        return getErrorObj(err);
      })
      .finally(() => {
        dispatch(setGeofencesLoading(false));
      });
  };
}

export function fetchActivityEvents() {
  return function (dispatch: Dispatch, getState: IgetState) {
    const state = getState();
    const {
      eventsLimit,
      eventsOffset,
      eventsFilter: { selectedGeofenceId, startDate, endDate, assetVin },
    } = state.geofences;

    if (!selectedGeofenceId) return;

    dispatch(setGeofencesLoading(true));

    return api
      .getGeofenceActivityEvents(
        getToken(getState),
        selectedGeofenceId,
        startDate,
        endDate,
        eventsLimit,
        eventsOffset,
        assetVin
      )
      .then(
        (events: { paged_data: Array<ActivityEvent>; total_count: number }) => {
          dispatch(setActivityEvents(events.paged_data));
          dispatch(setEventsTotalCount(events.total_count));
        }
      )
      .catch((err) => {
        return getErrorObj(err);
      })
      .finally(() => {
        dispatch(setGeofencesLoading(false));
      });
  };
}

export function createGeofence(data: IGeofence) {
  return function (dispatch: Dispatch<any>, getState: IgetState) {
    dispatch(setGeofencesLoading(true));

    const { selectedCustomer } = getState().common.customers;

    const id = selectedCustomer?.id;

    data["customer_id"] = id;

    return api
      .postGeoLocation(getToken(getState), data)
      .then(() => {
        dispatch(fetchGeofences());
        return API_OK_RESULT;
      })
      .catch((err) => {
        return getErrorObj(err);
      })
      .finally(() => {
        dispatch(setGeofencesLoading(false));
      });
  };
}

export function updateGeofence(id: number, data: IGeofence) {
  return function (dispatch: Dispatch<any>, getState: IgetState) {
    dispatch(setGeofencesLoading(true));

    return api
      .putGeoLocation(getToken(getState), id, data)
      .then(() => {
        dispatch(fetchGeofences());
        return API_OK_RESULT;
      })
      .catch((err) => {
        return getErrorObj(err);
      })
      .finally(() => {
        dispatch(setGeofencesLoading(false));
      });
  };
}

export function deleteGeofence(id: number) {
  return function (dispatch: Dispatch<any>, getState: IgetState) {
    dispatch(setGeofencesLoading(true));

    return api
      .deleteGeoLocation(getToken(getState), id)
      .then(() => {
        dispatch(fetchGeofences());
        return API_OK_RESULT;
      })
      .catch((err) => {
        return getErrorObj(err);
      })
      .finally(() => {
        dispatch(setGeofencesLoading(false));
      });
  };
}

store.subscribe(() => {
  const lastAction = store.getState().lastAction;

  if (lastAction.type === GeofencesActionTypes.SET_GEOFENCES_LIMIT) {
    store.dispatch({
      type: GeofencesActionTypes.SET_GEOFENCES_OFFSET,
      payload: 0,
    });
  }

  if (
    lastAction.type === GeofencesActionTypes.SET_GEOFENCES_OFFSET ||
    lastAction.type === GeofencesActionTypes.SET_GEOFENCES_SORT_OPTIONS
  ) {

    store.dispatch(fetchGeofences());
  }

  if (lastAction.type === GeofencesActionTypes.SET_EVENTS_LIMIT) {
    store.dispatch({
      type: GeofencesActionTypes.SET_EVENTS_OFFSET,
      payload: 0,
    });
  }

  if (lastAction.type === GeofencesActionTypes.SET_EVENTS_OFFSET) {
    console.log("2");
    store.dispatch(fetchActivityEvents());
  }

  if (lastAction.type === GeofencesActionTypes.SET_EVENTS_FILTER) {
    store.dispatch({ type: GeofencesActionTypes.SET_RESET_EVENTS_OFFSET });
  }
});
