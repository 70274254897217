import React, { useEffect } from "react";
import { connect } from "react-redux";
import SubNav from "../../components/SubNav";
import { useActions } from "../../hooks/useActions";
import GeoLocationsDetails from "../../modules/GeoLocationsDetails";

const GeoLocationsDetailsPage = ({
    locale = {},
    fetchPage,
    geolocationDetails: { isLoadingDetails, isLoadingTagsDetails, tagsDetails, details },
    preferences,
    ...pageProps
}) => {
    const { id } = pageProps.match.params;

    const { fetchGeolocationDetailsPage } = useActions()

    useEffect(() => {
        fetchGeolocationDetailsPage(id)
    }, [fetchGeolocationDetailsPage, id])

    return (
        <>
            <SubNav
                title="Tag Locations Details"
                breadcrumbs={[
                    { label: "Home", path: "/dashboard" },
                    { label: "Tag Locations", path: "/geolocations" },
                    { label: "Details" }
                ]}
            />
            <GeoLocationsDetails
                locale={locale}
                isLoadingDetails={isLoadingDetails}
                isLoadingTagsDetails={isLoadingTagsDetails}
                tagsDetails={tagsDetails}
                details={details} 
                locationId={id}
                preferences={preferences}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    locale: state.whitelabel.locale,
    auth: state.auth,
    common: state.common,
    geolocationDetails: state.geolocationDetails,
    preferences: state.auth.preferences,
});

export default connect(mapStateToProps)(GeoLocationsDetailsPage);

