import React from "react";
import RivataModule from "../../components/RivataModule";

const SupportInfo = ({
  locale,
  width,
  children,
}) => {
  return (
    <RivataModule
      title={locale.support || "Support"}
      width={width}
      locale={locale}
    >
    {children}
    </RivataModule>
  );
};

export default SupportInfo;