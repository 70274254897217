import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import './style.scss'

const ResetWhitelabelStyles = ({ resetStyles }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div data-component="fleet-map" className="mt-4 reset-config" >
            <h4>Reset Colors To Default</h4>
            <div>
                <Tooltip 
                    className="reset-styles__warning"
                    placement="top"
                    isOpen={tooltipOpen}
                    target={"resetStyles"}
                    toggle={toggle}
                >
                    This will restore all colors back to ConMet Digital defaults
                </Tooltip>
                <button 
                    id="resetStyles" 
                    className="btn btn-danger"
                    onClick={resetStyles}
                >
                    Reset Colors
                </button>
            </div>
        </div>
    )
}

export default ResetWhitelabelStyles