
import { convertDataEpochToDate, getClearLabel, getMileage } from "../../../utils";
import { IColumn, IRow } from "../../../components/RivataGrid";

const getMileageLabel = (column: string, unitsOfMeasurement: string) => {
    let label = column;
  
    if (column === "mileage") {
      if (unitsOfMeasurement === "metric") {
        label += " (km)";
      } else {
        label += " (mi)";
      }
    }
    return getClearLabel(label);
  };


export const distanceReportColumns = (columns: Array<string>, unitsOfMeasurement: string): Array<IColumn> =>
  columns.map((column) => {
    if (column === "asset_type") {
      return { key: column, name: getMileageLabel(column, unitsOfMeasurement),minWidth: 90, width: 100, }
      }
    if(column === "vin"){
      return { key: column, name: "VIN", width: 200}
    }
    if(column === "distance_units"){
      return { key: column, name: "Distance Units", minWidth: 90, width: 150}
    }
    if(column === "first_gw_communication"){
      return { key: "first_gw_communication", name: "First GW Communication", width: 200}
    }
    if(column === "last_gw_communication"){
      return { key: "last_gw_communication", name: "Last GW Communication", width: 200}
    }
      return { key: column, name: getMileageLabel(column, unitsOfMeasurement), width: 200}
    });

export const distanceReportRows = (rows: Array<IRow>, unitsOfMeasurement: string) =>
    rows.map((row) => ({
      ...row,
      parent_customer_name: row.parent_customer_name ? row.parent_customer_name : "---",
      asset_type: row.asset_type ? row.asset_type : "---",
      vin: row.vin ? row.vin : "---",
      period_distance_traveled: getMileage(+row.period_distance_traveled, unitsOfMeasurement).toString(),
      report_date_time: row.report_date_time ? convertDataEpochToDate(+row.report_date_time, null,null,true) : "---",
      distance_units: unitsOfMeasurement === 'metric' ? "Kilometers" : "Miles",
      first_gw_communication: row.first_gw_communication ? convertDataEpochToDate(+row.first_gw_communication,null,null,true) : "---",
      last_gw_communication: row.last_gw_communication ? convertDataEpochToDate(+row.last_gw_communication,null,null,true) : "---",
    }));