import React, { useEffect, useMemo } from "react";
import moment from "moment";
import RivataModule from "../../components/RivataModule";
import RivataLineChart from "../../components/RivataLineChart";
import RivataLoader from '../../components/RivataLoader'
import { composeLineChartData } from "./utils";
import { chartThresholdAnnotations, getMotionAnnotations } from '../../utils/chartUtils'
import StatusAlert from "../../components/StatusAlert";

const HubVibration = ({
  isLoading,
  data,
  error,
  width,
  graphColors,
  locale,
  timestamp,
  vibrationThreshold,
  isCustomLabelFormat = false,
  onZoomUpdate,
  setRef,
  onDoubleClick,
  setRange,
  xMax,
  xMin,
  healthColors,
  preferences: { timezone },
  speedData
}) => {
  const chartData = useMemo(() => {
    if (data && data.length && graphColors && graphColors.length) {
      return composeLineChartData(
        [...data].reverse(),
        graphColors,
        locale,
        isCustomLabelFormat
      );
    }
    return null;
  }, [data, graphColors, locale, isCustomLabelFormat]);

  useEffect(() => {
    if (chartData) setRange({ min: chartData.xMin, max: chartData.xMax })
    else setRange({ min: null, max: null })
  }, [chartData, setRange])

  const assetMotionAnnotations = useMemo(() => {
    return getMotionAnnotations(speedData, "vibrations");
  }, [speedData]);

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title="Hub Vibration"
      width={width}
      locale={locale}
      error={error}
      collapsible
    >
      {isLoading ? (
        <RivataLoader/>
      ) : chartData ? (
        <RivataLineChart
          height={400}
          id="vibrations"
          data={chartData}
          legendLabel="Level (x Nominal)"
          xMin={xMin}
          xMax={xMax}
          threshold={vibrationThreshold}
          onZoomUpdate={onZoomUpdate}
          setRef={setRef}
          onDoubleClick={onDoubleClick}
          xAxisLabel={`Date (${moment().tz(timezone).format("z")})`}
          annotations={chartThresholdAnnotations(
            "vibrations",
            vibrationThreshold,
            timestamp,
            healthColors,
          )}
          assetMotionAnnotations={assetMotionAnnotations}
        />
      ) : (
        <StatusAlert customText="No Data" color="success" />
      )}
    </RivataModule>
  );
};

export default HubVibration
