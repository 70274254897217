import { convertDataEpochToDate, getClearLabel, getMileage } from "../../../utils";
import { IColumn, IRow } from "../../../components/RivataGrid";

const getColumnName = (column: string, unitsOfMeasurement: string) => {
  let label = column;

  if (column === "mileage" || column === "start_odometer_value" || column === "end_odometer_value") {
    if (unitsOfMeasurement === "metric") {
      label += " (km)";
    } else {
      label += " (mi)";
    }
  }

  if (column === "timestamp") label = "Date";

  return getClearLabel(label);
};

export const dailyReportColumns = (columns: Array<string>, unitsOfMeasurement: string): Array<IColumn> =>
  columns.map((column) => ({ key: column, name: getColumnName(column, unitsOfMeasurement) }));

export const dailyReportRows = (rows: Array<IRow>, unitsOfMeasurement: string) =>
  rows.map((row) => ({
    ...row,
    timestamp: convertDataEpochToDate(+row.timestamp, null, null, null, null, false, true),
    start_odometer_value: getMileage(+row.start_odometer_value, unitsOfMeasurement).toString(),
    end_odometer_value: getMileage(+row.end_odometer_value, unitsOfMeasurement).toString(),
    mileage: getMileage(+row.mileage, unitsOfMeasurement).toString(),
  }));
