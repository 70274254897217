import { reduce } from "lodash";
import { useState, useEffect, useMemo } from "react";

import defaultWhiteLabelConfig from '../../redux/whitelabel/defaultConfig';

const getColor = (colors, id) => {
  const color = colors.find((c) => c.id === id);
  return color && color.color;
};

export const useChartColors = (healthColors) => {
  return useMemo(() => {
    const colors = { healthGood: defaultWhiteLabelConfig.healthColors[0], healthWarning: defaultWhiteLabelConfig.healthColors[1] };
    if (Array.isArray(healthColors) && healthColors.length > 1) {
      colors.healthGood =
        getColor(healthColors, "HealthGood") || colors.healthGood;
      colors.healthWarning =
        getColor(healthColors, "HealthWarning") || colors.healthWarning;
    }
    return colors;
  }, [healthColors]);
};

export const useChartData = (healthGood, healthWarning, locale, isLoading, locations) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (isLoading) return;

    const chartData = reduce(locations, (accum, value) => {
      value.status === 'warning' ? accum.warning++ : accum.ok++;
      return accum;
    }, { ok: 0, warning: 0 });

    setData({
      labels: ["Normal", "Warnings"],
      datasets: [
        {
          data: [chartData.ok, chartData.warning],
          backgroundColor: [healthGood, healthWarning],
          hoverBackgroundColor: [healthGood, healthWarning], // TODO!
        },
      ],
    });
  }, [healthGood, healthWarning, locale, isLoading, locations]);

  return data;
}