import React from "react";
import RivataModule from "../../components/RivataModule";

const SupportFAQs = ({
  locale,
  width,
  children,
}) => {
  return (
    <RivataModule
      title={locale.faqs || "FAQs"}
      width={width}
      locale={locale}
    >
    {children}
    </RivataModule>
  );
};

export default SupportFAQs;