export const localeText = {
  "en": {
    "English": "English",
    "French": "French",
    "7 days": "7 days",
    "30 days": "30 days",
    "90 days": "90 days",
    "6 months": "6 months",
    "Daily": "Daily",
    "Weekly": "Weekly",
    "Monthly": "Monthly",
    "None": "None",
    "Steer Left": "Steer Left",
    "Steer Right": "Steer Right",
    "Drive Front Left": "Drive Front Left",
    "Drive Front Right": "Drive Front Right",
    "Drive Rear Left": "Drive Rear Left",
    "Drive Rear Right": "Drive Rear Right",
    "Trailer Right 1": "Trailer Right 1",
    "Trailer Right 2": "Trailer Right 2",
    "Trailer Right 3": "Trailer Right 3",
    "Trailer Right 4": "Trailer Right 4",
    "Trailer Right 5": "Trailer Right 5",
    "Trailer Right 6": "Trailer Right 6",
    "Trailer Left 1": "Trailer Left 1",
    "Trailer Left 2": "Trailer Left 2",
    "Trailer Left 3": "Trailer Left 3",
    "Trailer Left 4": "Trailer Left 4",
    "Trailer Left 5": "Trailer Left 5",
    "Trailer Left 6": "Trailer Left 6",
    "Dashboard Page": "Dashboard Page",
    "Details Page": "Details Page",
    "Health Icons": "Health Icons",
    "Brand Logos": "Brand Logos",
    "Theme Colors": "Theme Colors",
    "Health Colors": "Health Colors",
    "Graph Palette": "Graph Palette",
    "SmartHubHealthSymbol": "SmartHub Health Symbol",
    "TpmsHealthSymbol": "Tpms Health Symbol",
    "LinePressureHealthSymbol": "Line Pressure Health Symbol",
    "AxleLoadHealthSymbol": "Axle Load Health Symbol",
    "HealthGoodSymbol": "Health Symbol Good",
    "HealthWarningSymbol": "Health Symbol Warning",
    "TemperatureWarningSymbol": "Temperature Symbol Warning",
    "TireHealthGoodSymbol": "Tire Health Symbol Good",
    "TireHealthWarningSymbol": "Tire Health Symbol Warning",
    "TireHealthCriticalWarningSymbol": "Tire Health Symbol Critical Warning",
    "VibrationWarningSymbol": "Vibration Symbol Warning",
    "SmartHubHealthSymbolText": "Symbol to be used in the Asset Table indicating the SmartHub sensors health",
    "TpmsHealthSymbolText": "Symbol to be used in the Asset Table indicating the TPMS sensors health",
    "LinePressureHealthSymbolText": "Symbol to be used in the Asset Table indicating the Line Pressure health for trailer",
    "AxleLoadHealthSymbolText": "Symbol to be used in the Asset Table indicating the Axle Load health for tractor",
    "HealthGoodSymbolText": "Symbol to be used in the Asset Table indicating the health is good",
    "HealthWarningSymbolText": "Symbol to be used in the Asset Table indicating there is a health warning.",
    "TemperatureWarningSymbolText": "Symbol to be used in the Asset Table indicating there is a temperature warning.",
    "TireHealthGoodSymbolText": "Symbol to be used in the Asset Table indicating tire health is good.",
    "TireHealthWarningSymbolText": "Symbol to be used in the Asset Table indicating there is a tire health warning.",
    "TireHealthCriticalWarningSymbolText": "Symbol to be used in the Asset Table indicating there is a tire health critical warning.",
    "VibrationWarningSymbolText": "Symbol to be used in the Asset Table indicating there is a vibration warning.",
    "LogoFull": "Logo Brand",
    "LogoFullText": "Brand Logo in the header, should show name of brand. PNG Format only.",
    "LogoIcon": "Logo Icon",
    "LogoIconText": "Brand Icon in the header, should be an icon only. PNG Format only.",
    "Status": "Status",
    "VIN": "VIN",
    "Customer": "Customer",
    "Asset Name": "Asset Name",
    "Last GPS Update": "Last GPS Update",
    "Actual Asset Geofences": "Actual Asset Geofences",
    "Last Update": "Last Update",
    "Sensor Type": "Sensor Type",
    "All": "All",
    "Groups": "Groups",
    "TPMS Profile": "TPMS Profile",
    "Toggle Verbose Logging": "Toggle Verbose Logging",
    "Request Gateway Log": "Request Gateway Log",
    "Request Sensor Log": "Request Sensor Log",
    "Update Device": "Update Device",
    "Reset Device": "Reset Device",
    "Send Command": "Send Command",
    "footer": "This site is a testing environment. The wheel end health data provided on the Rivata Dashboard represents readings taken over the course of the previous day. Data is not in real time. The data available on this site is for informational purposes only; it does not provide advice or direction about how the dashboard end user should use the data. Nor does the site rate the severity of the underlying wheel end conditions that gave rise to a warning or alert status. It is the responsibility of the dashboard end user to make all wheel end health determinations based on its own maintenance, inspection, and other asset management guidelines. The information available on this site does not replace the need for the dashboard end user to follow a regular program of physical inspection and maintenance of wheel ends. ConMet disclaims all liability arising from, or related to, the use or non-use of the data available on this site. Nor does ConMet warrant that the data available on this site will be uninterrupted or error-free at all times. Use of this site is governed by an agreement between your organization and ConMet, a copy of which is available upon request.",
    "copyright": "© Copyright Consolidated Metco, Inc. All rights reserved. ConMet is a registered trademark of Consolidated Metco, Inc.",
    "Map": "Map",
    "Satellite View": "Satellite View",
    "Ok": "Ok",
    "Warnings": "Warnings",
    "Legend": "Legend",
    "Fleet Map": "Fleet Map",
    "Assets": "Assets",
    "Name": "Name",
    "Gateway ID": "Gateway ID",
    "Gateway ESN": "Gateway ESN",
    "Vehicle Info": "Vehicle Info",
    "Position": "Position",
    "UNKNOWN": "Unknown",
    "Axle": "Axle",
    "Sensor ID": "Sensor ID",
    "Hub PN": "Hub PN",
    "Last Temperature Reading": "Last Temperature Update",
    "Sensor Node Info": "Sensor Node Info",
    "Admin Panel": "Admin Panel",
    "RSSI": "RSSI",
    "Configuration Rev.": "Configuration Rev.",
    "Firmware Rev.": "Firmware Rev.",
    "Hardware Rev.": "Hardware Rev.",
    "Last Report": "Last Report",
    "Voltage": "Voltage",
    "Packets": "Packets",
    "Failed Packets": "Failed Packets",
    "Time": "Time",
    "Location": "Location",
    "Warning Type": "Warning Type",
    "Type": "Type",
    "Description": "Description",
    "Recent Warnings": "Recent Warnings",
    "Vehicle Location": "Vehicle Location",
    "Temperature History": "Temperature History",
    "Hub Vibration": "Hub Vibration",
    "NONE": "None",
    "STEER_LEFT": "Steer Left",
    "STEER_RIGHT": "Steer Right",
    "TRACTOR_STEER": "Tractor Steer",
    "DRIVE_FRONT_LEFT": "Drive Front Left",
    "DRIVE_FRONT_RIGHT": "Drive Front Right",
    "DRIVE_REAR_LEFT": "Drive Rear Left",
    "DRIVE_REAR_RIGHT": "Drive Rear Right",
    "TRAILER_RIGHT_1": "Trailer Right 1",
    "TRAILER_RIGHT_2": "Trailer Right 2",
    "TRAILER_RIGHT_3": "Trailer Right 3",
    "TRAILER_RIGHT_4": "Trailer Right 4",
    "TRAILER_RIGHT_5": "Trailer Right 5",
    "TRAILER_RIGHT_6": "Trailer Right 6",
    "TRAILER_LEFT_1": "Trailer Left 1",
    "TRAILER_LEFT_2": "Trailer Left 2",
    "TRAILER_LEFT_3": "Trailer Left 3",
    "TRAILER_LEFT_4": "Trailer Left 4",
    "TRAILER_LEFT_5": "Trailer Left 5",
    "TRAILER_LEFT_6": "Trailer Left 6",
    "TRAILER_LEFT_1_OUTER": "Trailer Left 1 Outer",
    "TRAILER_LEFT_1_INNER": "Trailer Left 1 Inner",
    "TRAILER_LEFT_2_OUTER" : "Trailer Left 2 Outer",
    "TRAILER_LEFT_2_INNER": "Trailer Left 2 Inner",
    "TRAILER_LEFT_3_OUTER" : "Trailer Left 3 Outer",
    "TRAILER_LEFT_3_INNER": "Trailer Left 3 Inner",
    "TRAILER_LEFT_4_OUTER" : "Trailer Left 4 Outer",
    "TRAILER_LEFT_4_INNER": "Trailer Left 4 Inner",
    "TRAILER_LEFT_5_OUTER" : "Trailer Left 5 Outer",
    "TRAILER_LEFT_5_INNER": "Trailer Left 5 Inner",
    "TRAILER_RIGHT_1_OUTER": "Trailer Right 1 Outer",
    "TRAILER_RIGHT_1_INNER": "Trailer Right 1 Inner",
    "TRAILER_RIGHT_2_OUTER": "Trailer Right 2 Outer",
    "TRAILER_RIGHT_2_INNER": "Trailer Right 2 Inner",
    "TRAILER_RIGHT_3_OUTER": "Trailer Right 3 Outer",
    "TRAILER_RIGHT_3_INNER": "Trailer Right 3 Inner",
    "TRAILER_RIGHT_4_OUTER": "Trailer Right 4 Outer",
    "TRAILER_RIGHT_4_INNER": "Trailer Right 4 Inner",
    "TRAILER_RIGHT_5_OUTER": "Trailer Right 5 Outer",
    "TRAILER_RIGHT_5_INNER": "Trailer Right 5 Inner",
    "DRIVE_LEFT_1_OUTER": "Drive Left 1 Outer",
    "DRIVE_LEFT_1_INNER": "Drive Left 1 Inner",
    "DRIVE_LEFT_2_OUTER": "Drive Left 2 Outer",
    "DRIVE_LEFT_2_INNER": "Drive Left 2 Inner",
    "DRIVE_LEFT_3_OUTER": "Drive Left 3 Outer",
    "DRIVE_LEFT_3_INNER": "Drive Left 3 Inner",
    "DRIVE_LEFT_4_OUTER": "Drive Left 4 Outer",
    "DRIVE_LEFT_4_INNER": "Drive Left 4 Inner",
    "DRIVE_LEFT_5_OUTER": "Drive Left 5 Outer",
    "DRIVE_LEFT_5_INNER": "Drive Left 5 Inner",
    "DRIVE_LEFT_6_OUTER": "Drive Left 6 Outer",
    "DRIVE_LEFT_6_INNER": "Drive Left 6 Inner",
    "DRIVE_LEFT_7_OUTER": "Drive Left 7 Outer",
    "DRIVE_LEFT_7_INNER": "Drive Left 7 Inner",
    "DRIVE_RIGHT_1_OUTER": "Drive Right 1 Outer",
    "DRIVE_RIGHT_1_INNER": "Drive Right 1 Inner",
    "DRIVE_RIGHT_2_OUTER": "Drive Right 2 Outer",
    "DRIVE_RIGHT_2_INNER": "Drive Right 2 Inner",
    "DRIVE_RIGHT_3_OUTER": "Drive Right 3 Outer",
    "DRIVE_RIGHT_3_INNER": "Drive Right 3 Inner",
    "DRIVE_RIGHT_4_OUTER": "Drive Right 4 Outer",
    "DRIVE_RIGHT_4_INNER": "Drive Right 4 Inner",
    "DRIVE_RIGHT_5_OUTER": "Drive Right 5 Outer",
    "DRIVE_RIGHT_5_INNER": "Drive Right 5 Inner",
    "DRIVE_RIGHT_6_OUTER": "Drive Right 6 Outer",
    "DRIVE_RIGHT_6_INNER": "Drive Right 6 Inner",
    "DRIVE_RIGHT_7_OUTER": "Drive Right 7 Outer",
    "DRIVE_RIGHT_7_INNER": "Drive Right 7 Inner",
    "DRIVE_RIGHT_1": "Drive Right 1",
    "DRIVE_RIGHT_2": "Drive Right 2",
    "DRIVE_RIGHT_3": "Drive Right 3",
    "DRIVE_RIGHT_4": "Drive Right 4",
    "DRIVE_RIGHT_5": "Drive Right 5",
    "DRIVE_RIGHT_6": "Drive Right 6",
    "DRIVE_RIGHT_7": "Drive Right 7",
    "DRIVE_LEFT_1": "Drive Left 1",
    "DRIVE_LEFT_2": "Drive Left 2",
    "DRIVE_LEFT_3": "Drive Left 3",
    "DRIVE_LEFT_4": "Drive Left 4",
    "DRIVE_LEFT_5": "Drive Left 5",
    "DRIVE_LEFT_6": "Drive Left 6",
    "DRIVE_LEFT_7": "Drive Left 7",
    "STEER_LEFT_10": "Steer Left 10",
    "STEER_RIGHT_11": "Steer Right 11",
    "hub_temperature": "Hub Temperature",
    "tire_pressure":"Tire Pressure",
    "hub_vibration": "Hub Vibration",
    "hour": "hour",
    "tempMsgStart": "Over threshold",
    "tempMsgBody": "with peak of",
    "Error Parsing Data": "Error Parsing Data",
    "vibMsgStart": "Level of",
    "vibMsgEnd": "is above threshold",
    "TPMS": "TPMS",
    "TPMSConstruction": "Under Construction",
    "TPMSComingSoon": "Coming soon there will be a page here for Tire Pressure Monitoring System related data.",
    "TPMSComingAvailability": "Available only for customers who have the TPMS feature enabled for their account.",
    "EnabledPages": "Enabled Pages",
    "Locale": "Locale",
    "tmpsModuleMsg": "TPMS page is enabled",
    "Settings": "Settings",
    "WarningType": "Warning Type",
    "SensorType": "Sensor Type",
    "INFORMATIONAL_AND_CRITICAL": "Warnings and Critical Warnings",
    "CRITICAL_ONLY": "Critical Warnings Only",
    "Resend Temporary Password": "Resend Temporary Password",
    "Resend": "Resend",
    "receiveNotifications": "Activate notifications for tire pressure warnings",
    "no_status": "No Status",
    "line_pressure": "Line Pressure",
    "chassis": "Chassis",
    "gateway": "Gateway"
  },
  "fr": {
    "English": "Anglais",
    "French": "français",
    "7 days": "7 jours",
    "30 days": "30 jours",
    "90 days": "90 jours",
    "6 months": "6 mois",
    "Daily": "du quotidien",
    "Weekly": "Hebdomadaire",
    "Monthly": "Mensuel",
    "None": "Aucun",
    "Steer Left": "Diriger à gauche",
    "Steer Right": "Tourne à droite",
    "Drive Front Left": "Conduire avant gauche",
    "Drive Front Right": "Conduire avant droit",
    "Drive Rear Left": "Conduire arrière gauche",
    "Drive Rear Right": "Conduire arrière droit",
    "Trailer Right 1": "Remorque droite 1",
    "Trailer Right 2": "Remorque droite 2",
    "Trailer Right 3": "Remorque droite 3",
    "Trailer Right 4": "Remorque droite 4",
    "Trailer Right 5": "Remorque droite 5",
    "Trailer Right 6": "Remorque droite 6",
    "Trailer Left 1": "Remorque gauche 1",
    "Trailer Left 2": "Remorque gauche 2",
    "Trailer Left 3": "Remorque gauche 3",
    "Trailer Left 4": "Remorque gauche 4",
    "Trailer Left 5": "Remorque gauche 5",
    "Trailer Left 6": "Remorque gauche 6",
    "Dashboard Page": "Page du tableau de bord",
    "Details Page": "Page de détails",
    "Health Icons": "Icônes de santé",
    "Brand Logos": "Logos de marque",
    "Theme Colors": "Couleurs du thème",
    "Health Colors": "Couleurs de santé",
    "Graph Palette": "Palette graphique",
    "HealthGoodSymbol": "Symbole de santé bon",
    "HealthWarningSymbol": "Avertissement de symbole de santé",
    "HealthGoodSymbolText": "Symbole à utiliser dans le tableau des actifs indiquant que la santé est bonne",
    "HealthWarningSymbolText": "Symbole à utiliser dans le tableau des actifs indiquant qu'il y a un avertissement d'intégrité.",
    "LogoFull": "Marque de logo",
    "LogoFullText": "Le logo de la marque dans l'en-tête doit indiquer le nom de la marque. Format PNG uniquement.",
    "LogoIcon": "Icône du logo",
    "LogoIconText": "Icône de marque dans l'en-tête, ne doit être qu'une icône. Format PNG uniquement.",
    "Status": "Statut",
    "VIN": "VIN",
    "Customer": "Client",
    "Asset Name": "Nom de l'élément",
    "Last GPS Update": "Dernière mise à jour GPS",
    "Actual Asset Geofences": "Actual Asset Geofences",
    "footer": "Ce site est un environnement de test. Les données de santé des extrémités de roue fournies sur le tableau de bord Rivata représentent les lectures prises au cours de la journée précédente. Les données ne sont pas en temps réel. Les données disponibles sur ce site sont à titre informatif uniquement; il ne fournit ni conseils ni directives sur la manière dont l'utilisateur final du tableau de bord doit utiliser les données. Le site n'évalue pas non plus la gravité des conditions d'extrémité de roue sous-jacentes qui ont donné lieu à un état d'avertissement ou d'alerte. Il est de la responsabilité de l'utilisateur final du tableau de bord d'effectuer toutes les déterminations de l'état de santé des extrémités de roue en fonction de ses propres directives de maintenance, d'inspection et de gestion des actifs. Les informations disponibles sur ce site ne remplacent pas la nécessité pour l'utilisateur final du tableau de bord de suivre un programme régulier d'inspection physique et d'entretien des embouts de roues. ConMet décline toute responsabilité découlant de, ou liée à, l'utilisation ou la non-utilisation des données disponibles sur ce site. ConMet ne garantit pas non plus que les données disponibles sur ce site seront ininterrompues ou sans erreur à tout moment. L'utilisation de ce site est régie par un accord entre votre organisation et ConMet, dont une copie est disponible sur demande.",
    "copyright": "© Copyright Consolidated Metco, Inc. Tous droits réservés. ConMet est une marque déposée de Consolidated Metco, Inc.",
    "Map": "Vue Rue",
    "Satellite View": "Vue Satellite",
    "Ok": "D'accord",
    "Warnings": "Avertissements",
    "Legend": "Légende",
    "Fleet Map": "Carte de la Flotte",
    "Assets": "Les Atouts",
    "Name": "Nom",
    "Gateway ID": "ID de la Passerelle",
    "Vehicle Info": "Info Véhicule",
    "Position": "Position",
    "Axle": "Essieu",
    "Sensor ID": "ID du Capteur",
    "Hub PN": "Hub PN",
    "Last Temperature Reading": "Dernière lecture de température",
    "Sensor Node Info": "Informations sur le Nœud de Capteur",
    "Time": "Temps",
    "Location": "Emplacement",
    "Type": "Type",
    "Description": "La description",
    "Recent Warnings": "Avertissements Récents",
    "Vehicle Location": "Emplacement du Véhicule",
    "Temperature History": "Historique des Températures",
    "Hub Vibration": "Vibration du Moyeu",
    "NONE": "Aucun",
    "STEER_LEFT": "Diriger à gauche",
    "STEER_RIGHT": "Tourne à droite",
    "DRIVE_FRONT_LEFT": "Conduire avant gauche",
    "DRIVE_FRONT_RIGHT": "Conduire avant droit",
    "DRIVE_REAR_LEFT": "Conduire arrière gauche",
    "DRIVE_REAR_RIGHT": "Conduire arrière droit",
    "TRAILER_RIGHT_1": "Remorque droite 1",
    "TRAILER_RIGHT_2": "Remorque droite 2",
    "TRAILER_RIGHT_3": "Remorque droite 3",
    "TRAILER_RIGHT_4": "Remorque droite 4",
    "TRAILER_RIGHT_5": "Remorque droite 5",
    "TRAILER_RIGHT_6": "Remorque droite 6",
    "TRAILER_LEFT_1": "Remorque gauche 1",
    "TRAILER_LEFT_2": "Remorque gauche 2",
    "TRAILER_LEFT_3": "Remorque gauche 3",
    "TRAILER_LEFT_4": "Remorque gauche 4",
    "TRAILER_LEFT_5": "Remorque gauche 5",
    "TRAILER_LEFT_6": "Remorque gauche 6",
    "hub_temperature": "Hub Temperature",
    "tire_pressure":"Tire Pressure",
    "hub_vibration": "Hub Vibration",
    "hour": "heure",
    "tempMsgStart": "Au-dessus du seuil pour",
    "tempMsgBody": "avec un pic de",
    "Error Parsing Data": "Erreur d'analyse des données",
    "vibMsgStart": "Le niveau",
    "vibMsgEnd": "est supérieur au seuil",
    "TPMS": "TPMS",
    "TPMSConstruction": "En Construction",
    "TPMSComingSoon": "Bientôt, il y aura une page ici pour les données relatives au système de surveillance de la pression des pneus.",
    "TPMSComingAvailability": "Cette page ne sera disponible que pour les clients qui ont activé la fonction TPMS pour leur compte.",
    "EnabledPages": "Pages activées",
    "Locale": "Lieu",
    "tmpsModuleMsg": "La page TPMS est activée",
    "Settings": "Réglages",
    "WarningType": "Warning Type",
    "SensorType": "Sensor Type",
    "INFORMATIONAL_AND_CRITICAL": "Informational and critical",
    "CRITICAL_ONLY": "Critical only",
    "no_status": "No Status",
    "line_pressure": "Line Pressure",
    "chassis": "Chassis",
    "gateway": "Gateway"
  },
  "gr": {
    "English": "Englisch",
    "French": "French",
    "7 days": "7 Tage",
    "30 days": "30 Tage",
    "90 days": "90 Tage",
    "6 months": "6 Monate",
    "Daily": "Täglich",
    "Weekly": "Wöchentlich",
    "Monthly": "Monatlich",
    "None": "Keiner",
    "Steer Left": "Lenken Links",
    "Steer Right": "Lenken Rechts",
    "Drive Front Left": "Vorne Fahren Links",
    "Drive Front Right": "Vorne Fahren Rechts",
    "Drive Rear Left": "Hinten Fahren Links",
    "Drive Rear Right": "Hinten Fahren Rechts",
    "Trailer Right 1": "Trailer Rechts 1",
    "Trailer Right 2": "Trailer Rechts 2",
    "Trailer Right 3": "Trailer Rechts 3",
    "Trailer Right 4": "Trailer Rechts 4",
    "Trailer Right 5": "Trailer Rechts 5",
    "Trailer Right 6": "Trailer Rechts 6",
    "Trailer Left 1": "Trailer Links 1",
    "Trailer Left 2": "Trailer Links 2",
    "Trailer Left 3": "Trailer Links 3",
    "Trailer Left 4": "Trailer Links 4",
    "Trailer Left 5": "Trailer Links 5",
    "Trailer Left 6": "Trailer Links 6",
    "Dashboard Page": "Dashboard-Seite",
    "Details Page": "Detailseite",
    "Health Icons": "Gesundheitssymbole",
    "Brand Logos": "Markenlogos",
    "Theme Colors": "Themenfarben",
    "Health Colors": "Gesundheitsfarben",
    "Graph Palette": "Diagrammpalette",
    "HealthGoodSymbol": "Gesundheitssymbol Gut",
    "HealthWarningSymbol": "Gesundheitssymbol Warning",
    "HealthGoodSymbolText": "Symbol to be used in the Asset Table indicating the health is good",
    "HealthWarningSymbolText": "Symbol to be used in the Asset Table indicating there is a health warning.",
    "LogoFull": "Logo Brand",
    "LogoFullText": "Brand Logo in the header, should show name of brand. PNG Format only.",
    "LogoIcon": "Logo Icon",
    "LogoIconText": "Brand Icon in the header, should be an icon only. PNG Format only.",
    "Status": "Status",
    "VIN": "VIN",
    "Customer": "Kunde",
    "Asset Name": "Asset Name",
    "Last GPS Update": "Last GPS Update",
    "Actual Asset Geofences": "Actual Asset Geofences",
    "footer": "Diese Site ist eine Testumgebung. Die im Rivata-Dashboard bereitgestellten Radendzustandsdaten stellen Messwerte dar, die im Verlauf des Vortages gemessen wurden. Daten sind nicht in Echtzeit. Die auf dieser Website verfügbaren Daten dienen nur zu Informationszwecken; es enthält keine Ratschläge oder Anweisungen dazu, wie der Dashboard-Endbenutzer die Daten verwenden soll. Der Standort bewertet auch nicht den Schweregrad der zugrunde liegenden Radendbedingungen, die zu einem Warn- oder Alarmstatus geführt haben. Es liegt in der Verantwortung des Dashboard-Endbenutzers, alle Radzustandszustandsbestimmungen auf der Grundlage seiner eigenen Wartungs, Inspektions und anderen Asset-Management-Richtlinien vorzunehmen. Die auf dieser Website verfügbaren Informationen ersetzen nicht die Notwendigkeit, dass der Endbenutzer des Armaturenbretts ein regelmäßiges Programm zur physischen Inspektion und Wartung der Radenden befolgt. ConMet lehnt jede Haftung ab, die sich aus der Verwendung oder Nichtverwendung der auf dieser Website verfügbaren Daten ergibt oder damit zusammenhängt. ConMet garantiert auch nicht, dass die auf dieser Website verfügbaren Daten jederzeit ununterbrochen oder fehlerfrei sind. Die Nutzung dieser Website unterliegt einer Vereinbarung zwischen Ihrer Organisation und ConMet, von der eine Kopie auf Anfrage erhältlich ist.",
    "copyright": "© Urheberrechte Consolidated Metco, Inc. Alle Rechte vorbehalten. ConMet ist eine eingetragene Marke von Consolidated Metco, Inc.",
    "Map": "Straßenansicht",
    "Satellite View": "Satellitenansicht",
    "Ok": "OK",
    "Warnings": "Warnungen",
    "Legend": "Legende",
    "Fleet Map": "Flottenkarte",
    "Assets": "Vermögenswerte",
    "Name": "Name",
    "Gateway ID": "Tor ID",
    "Vehicle Info": "Fahrzeuginfo",
    "Position": "Position",
    "Axle": "Achse",
    "Sensor ID": "Sensor ID",
    "Hub PN": "Nabe PN",
    "Last Temperature Reading": "Letzte Temperaturmessung",
    "Sensor Node Info": "Sensorknoteninfo",
    "Time": "Zeit",
    "Location": "Ort",
    "Type": "Type",
    "Description": "Beschreibung",
    "Recent Warnings": "Aktuelle Warnungen",
    "Vehicle Location": "Fahrzeugstandort",
    "Temperature History": "Temperaturverlauf",
    "Hub Vibration": "Nabenvibration",
    "NONE": "Keiner",
    "STEER_LEFT": "Lenken Links",
    "STEER_RIGHT": "Lenken Rechts",
    "DRIVE_FRONT_LEFT": "Vorne Fahren Links",
    "DRIVE_FRONT_RIGHT": "Vorne Fahren Rechts",
    "DRIVE_REAR_LEFT": "Hinten Fahren Links",
    "DRIVE_REAR_RIGHT": "Hinten Fahren Rechts",
    "TRAILER_RIGHT_1": "Trailer Rechts 1",
    "TRAILER_RIGHT_2": "Trailer Rechts 2",
    "TRAILER_RIGHT_3": "Trailer Rechts 3",
    "TRAILER_RIGHT_4": "Trailer Rechts 4",
    "TRAILER_RIGHT_5": "Trailer Rechts 5",
    "TRAILER_RIGHT_6": "Trailer Rechts 6",
    "TRAILER_LEFT_1": "Trailer Links 1",
    "TRAILER_LEFT_2": "Trailer Links 2",
    "TRAILER_LEFT_3": "Trailer Links 3",
    "TRAILER_LEFT_4": "Trailer Links 4",
    "TRAILER_LEFT_5": "Trailer Links 5",
    "TRAILER_LEFT_6": "Trailer Links 6",
    "hub_temperature": "Hub Temperature",
    "tire_pressure":"Tire Pressure",
    "hub_vibration": "Hub Vibration",
    "hour": "Stunde",
    "tempMsgStart": "Über der Schwelle für",
    "tempMsgBody": "mit Spitze von",
    "Error Parsing Data": "Fehler beim Analysieren von Date",
    "vibMsgStart": "Level von",
    "vibMsgEnd": "ist über der Schwelle",
    "TPMS": "TPMS",
    "TPMSConstruction": "Bauarbeiten im Gange",
    "TPMSComingSoon": "In Kürze wird es hier eine Seite mit Daten zum Reifendruckkontrollsystem geben.",
    "TPMSComingAvailability": "Nur für Kunden verfügbar, für deren Konto die TPMS-Funktion aktiviert ist.",
    "EnabledPages": "Aktivierte Seiten",
    "Locale": "Gebietsschema",
    "tmpsModuleMsg": "TPMS seite ist aktiviert",
    "Settings": "Einstellungen",
    "WarningType": "Warning Type",
    "SensorType": "Sensor Type",
    "INFORMATIONAL_AND_CRITICAL": "Informational and critical",
    "CRITICAL_ONLY": "Critical only",
    "no_status": "No Status",
    "line_pressure": "Line Pressure",
    "chassis": "Chassis",
    "gateway": "Gateway"
  }
}