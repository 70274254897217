import React from "react";
import { Label } from 'reactstrap'

const DetailsRow = ({
  label, value
}) => {
  return (
    <div key={value} className="asset-row d-flex">
      <b><Label>{label}</Label>:</b>&nbsp;
      <Label> {value}</Label>
    </div>
  );
};

export default DetailsRow;