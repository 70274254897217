import {
  CLEAR_SESSION,
  SET_SESSION,
  SET_TOKEN_LIFE_TIME,
  SET_REFRESHED_TOKEN,
  CHECK_SESSION,
  SAVING_SESSION,
  SET_SESSION_ERROR,
  SET_PREFERENCES,
} from "../actionTypes";
import { Timezones, NotificationThresholds, UnitsOfMeasurement } from "../../enums";
import { baseUrl } from "../../services/api/base";

const setCachedTimezone = (timezone) => {
  localStorage.setItem("timezone", timezone)
}

const getCachedTimezone = () => localStorage.getItem("timezone") || Timezones.AMERICA_LOS_ANGELES

const baseWhiteLabelUrl = baseUrl

const initialState = {
  isLoggedIn: false,
  loginChecked: false,
  isAttributesLoaded: false,
  user: {
    email: null,
    userName: null,
  },
  expiresIn: 0,
  credentials: {},
  isSavingUser: true,
  error: false,
  preferences: {
    timezone: getCachedTimezone(),
    notifications: {
      receiveEmail: false,
      receiveText: false,
      threshold: NotificationThresholds.WARNING,
      critical_alerts_topic_subscription_confirmed: null,
      non_critical_alerts_topic_subscription_confirmed: null
    },
    unitsOfMeasurement: UnitsOfMeasurement.imperial
  },
  isSuperAdmin: false,
  isAdmin: false,
  whiteLabel: "rivata",
  whiteLabelUrl: `${baseWhiteLabelUrl}/config/rivata/`,
}

const setUserAttributes = (state, payload) => {
  document.title = payload.customers[0].name
  setCachedTimezone(payload.timezone)
  
  return {
    ...state,
    isAttributesLoaded: true,
    isAdmin: payload.is_admin,
    isSuperAdmin: payload.is_super_admin,
    whiteLabel: payload.parent_customer_key,
    whiteLabelUrl: `${baseWhiteLabelUrl}/config/${payload.parent_customer_key.toLowerCase()}/`,
    preferences: {
      email: payload.email,
      phone: payload.phone,
      timezone: payload.timezone,
      unitsOfMeasurement: payload.units_of_measurement,
      customer_ids: payload.customer_ids,
      customers: payload.customers,
      roles: payload.roles,
      username: payload.username,
      notifications: {
        receiveEmail: payload.notification_email,
        receiveText: payload.notification_text,
        threshold: payload.notification_thresholds,
        criticalAlertsTopicSubscriptionConfirmed: payload.critical_alerts_topic_subscription_confirmed,
        nonCriticalAlertsTopicSubscriptionConfirmed: payload.non_critical_alerts_topic_subscription_confirmed
      }
    }
  }
};

const setSession = (session, state) => {
  const { user: { userName, email } } = session;
  return {
    ...state,
    ...session,
    isLoggedIn: true,
    loginChecked: true,
    user: { userName, email }
  };
};

const setTokenLifeEndTime = (state, payload) => ({ ...state, expiresIn: payload })
const setRefreshedToken = (state, payload) => ({ ...state, credentials: { ...state.credentials, idToken: payload } })

const session = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return setSession(action.payload, state);
    case SET_PREFERENCES:
      return setUserAttributes(state, action.payload);
    case SET_TOKEN_LIFE_TIME:
      return setTokenLifeEndTime(state, action.payload);
    case SET_REFRESHED_TOKEN:
      return setRefreshedToken(state, action.payload);
    case CHECK_SESSION:
      return { ...state, loginChecked: true };
    case CLEAR_SESSION:
      return initialState;
    case SAVING_SESSION:
      return {
        ...state,
        isSavingUser: action.payload,
      };
    case SET_SESSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default session;
