import React from "react";
import { connect } from "react-redux";
import SubNav from '../../components/SubNav'
import SupportFAQs from "../../modules/SupportFAQs";
import faqs from "./faqs.json";

const FAQs = ({
  locale = {}
}) => {
  return (
    <>
      <SubNav
        title="FAQs"
        breadcrumbs={[
          { label: "Home", path: "/dashboard" },
          { label: "FAQs" }
        ]}
      />
      <SupportFAQs locale={locale}>
        {faqs.map((faq, i)=> <div key={i}><h5>{faq.question}</h5><p>{faq.answer}</p></div>)}
      </SupportFAQs>
    </>
  );
};

const mapStateToProps = (state) => ({
  locale: state.whitelabel.locale,
});


export default connect(mapStateToProps)(FAQs);