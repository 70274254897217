import React from "react";
import { Container, Row } from "reactstrap";

import './styles.scss';

const ChartLegend = ({ items, title, locale }) => {
  return (
    <div
      className="position-absolute m-4 p-2 border rounded bg-white legend-container"
    >
      <Container>
        <Row className="border-bottom mb-1 pb-1">
          <strong>{locale[title] || title}</strong>
        </Row>
        <Row>
          <div className="legend-items-container">
            {items.map(({ icon, label }, i) => (
              <div key={label + i} className="legend-item">
                <img src={icon} alt={locale[label] || label} />
                <span>{locale[label] || label}</span>
              </div>
            ))}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ChartLegend;
