import { SET_GEOLOCATION_DETAILS, SET_GEOLOCATION_TAGS_DETAILS, SET_IS_LOADING_DETAILS, SET_IS_LOADING_TAGS_DETAILS } from "../actionTypes";
import api from "../../services/api";
import { convertDataEpochToDate, getToken } from "../../utils";

const setGeolocationTagsDetails = (tags) => ({
    type: SET_GEOLOCATION_TAGS_DETAILS,
    payload: tags,
});

const setGeolocationDetails = (details) => ({
    type: SET_GEOLOCATION_DETAILS,
    payload: details
})

const setIsLoadingDetails = (isLoading) => ({
    type: SET_IS_LOADING_DETAILS,
    payload: isLoading
})

const setIsLoadingTagsDetails = (isLoading) => ({
    type: SET_IS_LOADING_TAGS_DETAILS,
    payload: isLoading
})

export function fetchGeolocationDetailsPage(locationId) {
    return function (dispatch, getState) {
        dispatch(fetchGeolocationTagsDetails(locationId));
        dispatch(fetchGeolocationDetails(locationId));
    }
}

export function fetchGeolocationTagsDetails(locationId) {
    return function (dispatch, getState) {
        dispatch(setIsLoadingTagsDetails(true));
        return api.getGeolocationTagsDetails(getToken(getState), locationId).then(data => {
            data = convertDataEpochToDate(data, 'last_communication_at_epoch', 'last_communication_at_datetime');
            dispatch(setGeolocationTagsDetails(data));
        }).catch((err) => {

        }).finally(() => {
            dispatch(setIsLoadingTagsDetails(false));
        })
    }
}

export function fetchGeolocationDetails(locationId) {
    return function (dispatch, getState) {
        dispatch(setIsLoadingDetails(true));
        return api.getGeolocationDetails(getToken(getState), locationId).then(data => {
            data = convertDataEpochToDate(data, "created_at_epoch", "created_at_datetime");
            data.gateways = convertDataEpochToDate(data.gateways, "last_updated_at_epoch", "last_updated_at_datetime")
            data.gateways_str = data.gateways.map(g => g.mac).join(", ") || "-";
            dispatch(setGeolocationDetails(data));
        }).catch((err) => {

        }).finally(() => {
            dispatch(setIsLoadingDetails(false));
        })
    }
}