export const baseUrl =  window.location.origin;

class ApiError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = "ValidationError";
    this.statusCode = statusCode;
  }
}

export const fetchPresignedUrl = async (url) => {
  try {
    const response = await fetch(url);

    if (response.ok) {
      const json = await response.json();

      return json;
    } else {
      throw new ApiError(response.status, response.statusText);
    }
  } catch (e) {
    // Throw the error status from above
    if (e instanceof ApiError) {
      throw e;
    }
    // Throw an internal server error as it is an unknown problem.
    throw new ApiError(500, "Internal Server Error");
  }
};

const fetchOrDelete = async (method, endpoint, token, headers = {}) => {
  try {
    const response = await fetch(`${baseUrl}/api/${endpoint}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        ...headers,
      },
    });

    if (response.ok) {
      const json = await response.json();

      return json;
    } else {
      throw new ApiError(response.status, response.statusText);
    }
  } catch (e) {
    // Throw the error status from above
    if (e instanceof ApiError) {
      throw e;
    }
    // Throw an internal server error as it is an unknown problem.
    throw new ApiError(500, "Internal Server Error");
  }
};

const updateOrCreate = async (method, endpoint, body, token, headers = { "Content-Type": "application/json" }) => {
  try {
    const response = await fetch(`${baseUrl}/api/${endpoint}`, {
      method,
      body,
      headers: {
        Authorization: token,
        ...headers,
      },
    });
    
    const json = await response.json();

    if (response.ok) {
      return json;
    } else {
      throw new ApiError(response.status, json.errorMessage);
    }
  } catch (e) {
    // Throw the error status from above
    if (e instanceof ApiError) {
      throw e;
    }
    // Throw an internal server error as it is an unknown problem.
    throw new ApiError(500, "Internal Server Error");
  }
};

const getMethod = async (endpoint, token, headers) => fetchOrDelete("GET", endpoint, token, headers);
const deleteMethod = async (endpoint, token, headers) => fetchOrDelete("DELETE", endpoint, token, headers);
const postMethod = async (endpoint, data, token, headers) => updateOrCreate("POST", endpoint, data, token, headers);
const putMethod = async (endpoint, data, token, headers) => updateOrCreate("PUT", endpoint, data, token, headers);

export default {
  fetchPresignedUrl,
  get: getMethod,
  delete: deleteMethod,
  post: postMethod,
  put: putMethod,
};

