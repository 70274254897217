import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Input, Tooltip, CustomInput } from "reactstrap";

import RivataModule from "../../components/RivataModule";
import HEREMap from "../../components/RivataMap";
import { LayerTypes } from "../../components/RivataMap/utils";
import RivataLoader from "../../components/RivataLoader";
import LayersDropdown from "../../components/LayersDropdown";
import { DateRangeSelector } from "../../components/DateRangeCalendar";
import Legend from "./Legend";
import PickTime from "../../components/TimePicker";

import { dateToEpoch, convertDataEpochToDate } from "../../utils";
import { useParsedData } from "./hooks";
import "./style.scss";

const DetailsMap = ({
  isLoading,
  data,
  error,
  selectedWarning,
  width,
  locale,
  preferences,
  onSelectDateRange,
  vehicleInfo,
  healthColors,
  geofences
}) => {
  const [formValues, setFormValues] = useState({
    inputValue:
      moment(new Date()).format("MM/DD/YYYY") +
      " - " +
      moment(new Date()).format("MM/DD/YYYY"),
    startTime: "00:00",
    endTime: "23:59",
    dayStart: new Date(),
    dayEnd: new Date(),
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRangeError, setDateRangeError] = useState(null);
  const [selectedLayerOption, setSelectedLayerOption] = useState(
    LayerTypes.NORMAL
  );
  const [forceMapRerender, setForceMapRerender] = useState(false);
  const [geofencesVisible, setGeofencesVisible] = useState(false);

  // custom hook
  const parsedLocations = useParsedData(data, vehicleInfo, selectedWarning, setForceMapRerender)

  useEffect(() => {
    if (selectedWarning?.epoch) {
      const composedFrom = moment
        .unix(selectedWarning.epoch)
        .subtract(4, "hours")
        .unix();
      const composedTo = moment
        .unix(selectedWarning.epoch)
        .add(4, "hours")
        .unix();

      const filterDateStart = convertDataEpochToDate(
        composedFrom,
        null,
        null,
        true
      ).split(" ");
      const filterDateEnd = convertDataEpochToDate(
        composedTo,
        null,
        null,
        true
      ).split(" ");

      const beforeWarningtime = moment(
        [filterDateStart[1], filterDateStart[2]].join(" "),
        ["HH:mm A"]
      ).format("HH:mm");
      const afterWarningtime = moment(
        [filterDateEnd[1], filterDateEnd[2]].join(" "),
        ["HH:mm A"]
      ).format("HH:mm");

      const beforeWarningDate = filterDateStart[0];
      const afterWarningDate = filterDateEnd[0];

      setFormValues({
        inputValue: `${beforeWarningDate} ${
          afterWarningDate ? "- " + afterWarningDate : ""
        }`,
        startTime: beforeWarningtime,
        endTime: afterWarningtime,
        dayStart: new Date(beforeWarningDate),
        dayEnd: new Date(afterWarningDate),
      });
    }
  }, [selectedWarning]);

  useEffect(() => {
    if (vehicleInfo?.lastLocationEpoch && !selectedWarning) {
      const start = convertDataEpochToDate(
        vehicleInfo.lastLocationEpoch - 24 * 3600,
        null,
        null,
        false
      );
      const end = convertDataEpochToDate(
        vehicleInfo.lastLocationEpoch,
        null,
        null,
        false
      );

      setFormValues({
        inputValue:
          start.format("MM/DD/YYYY") + " - " + end.format("MM/DD/YYYY"),
        startTime: start.format("HH:mm"),
        endTime: end.format("HH:mm"),
        dayStart: new Date(start),
        dayEnd: new Date(end),
      });

      setForceMapRerender(true);
    }
  }, [vehicleInfo, selectedWarning]);

  const toggleCalendar = (value = !showCalendar) => {
    setShowCalendar(value);
  };

  const dateRangeHandler = (date) => {
    const { startDate, endDate } = date.selection;
    setFormValues({
      ...formValues,
      inputValue: `${moment(startDate).format("MM/DD/YYYY")} ${
        endDate ? "- " + moment(endDate).format("MM/DD/YYYY") : ""
      }`,
      dayStart: startDate,
      dayEnd: endDate,
    });

    const selectedDays = moment
      .duration(moment(endDate).diff(moment(startDate)))
      .as("days");
    if (selectedDays > 2) {
      return setDateRangeError("You can't select more than 3 days");
    }
    setDateRangeError(null);
  };

  const handleSubmit = () => {
    const { startDate, endDate } = dateToEpoch(
      formValues.dayStart,
      formValues.dayEnd,
      formValues.startTime,
      formValues.endTime
    );
    onSelectDateRange(startDate, endDate);
  };

  return (
    <RivataModule
      fullScreenModalModeEnabled
      title="Vehicle Location"
      width={width}
      locale={locale}
      paddingMobile={false}
      error={error}
      filters={
        <>
          {geofences?.length ? (
            <div className="d-flex align-items-center mr-3">
              <CustomInput
                type="switch"
                id="geofencesSwitch"
                label={"Show Geofences"}
                checked={geofencesVisible}
                onChange={(e) => setGeofencesVisible(e.target.checked)}
              />
            </div>
          ) : null}
          <LayersDropdown
            left={0}
            top={0}
            zIndex={1}
            selected={selectedLayerOption}
            onSelect={(type) => setSelectedLayerOption(type)}
            locale={locale}
          />
          <div className="position-relative d-flex range-selector__wrapper">
            <Input
              className="map-date-range"
              id="range_input"
              onClick={(e) => {
                e.persist();
                toggleCalendar();
              }}
              value={formValues.inputValue}
              readOnly
            />
            <DateRangeSelector
              selectionRange={{
                startDate: formValues.dayStart,
                endDate: formValues.dayEnd,
                key: "selection",
              }}
              handleSelect={dateRangeHandler}
              toggleCalendar={toggleCalendar}
              visible={showCalendar}
            />
            <form
              className="d-flex"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <label>From:</label>
              <PickTime
                t={formValues.startTime}
                onChange={(val) =>
                  setFormValues({ ...formValues, startTime: val })
                }
              />
              <label>To:</label>
              <PickTime
                t={formValues.endTime}
                onChange={(val) =>
                  setFormValues({ ...formValues, endTime: val })
                }
              />
              <button
                className="btn btn-secondary ml-2"
                type="submit"
                disabled={!!dateRangeError}
              >
                Submit
              </button>
              <Tooltip
                placement="top"
                isOpen={!!dateRangeError}
                target="range_input"
                style={{ zIndex: 100 }}
              >
                <p>{dateRangeError}</p>
              </Tooltip>
            </form>
          </div>
        </>
      }
      collapsible
    >
      {window.H && <Legend locale={locale} isWarningDetailsPage={!!selectedWarning} />}
      {window.H && (
        <HEREMap
          locations={parsedLocations.data}
          layerType={selectedLayerOption}
          closestTimestamp={parsedLocations.warningTimestamp}
          selectable={false}
          bboxLast24hrs={false}
          locale={locale}
          preferences={preferences}
          unitsOfMeasurement={preferences.unitsOfMeasurement}
          healthColors={healthColors}
          showLastLocationAsPin={true}
          forceMapRerender={forceMapRerender}
          setForceMapRerender={setForceMapRerender}
          geofences={geofences}
          vin={vehicleInfo.vin}
          geofencesVisible={geofencesVisible}
          isWarningDetailsPage={!!selectedWarning}
        />
      )}
      {isLoading && (
        <div
          className="position-absolute w-100 h-100"
          style={{
            backgroundColor: "rgba(0,0,0,.1)",
            left: 0,
            top: 0,
            display: "flex",
            zIndex: 1,
            justifyContent: "center",
          }}
        >
          <RivataLoader />
        </div>
      )}
    </RivataModule>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  healthColors: state.whitelabel.healthColors,
});

export default connect(mapStateToProps)(DetailsMap);
