import React from 'react';
import RivataLoader from '../../components/RivataLoader';
import RivataModule from '../../components/RivataModule';
import RivataTable from '../../components/RivataTable';
import LocationMap from '../LocationMap/LocationMap';
import DetailsRow from './DetailsRow';
import { useColumns, useMapData, useTableRows } from './hooks';

const GeoLocationsDetails = ({ locale, tagsDetails, locationId, details, isLoadingDetails, isLoadingTagsDetails, preferences }) => {
    const columns = useColumns();
    const rows = useTableRows(tagsDetails, locationId);
    const mapData = useMapData(details);

    return <>
        <RivataModule
            title="Location Details"
            width={4}
            locale={locale}
        >
            {isLoadingDetails && <RivataLoader />}
            <DetailsRow value={details.name} label="Name" />
            <DetailsRow value={details.lat} label="Latitude" />
            <DetailsRow value={details.lon} label="Longitude" />
            <DetailsRow value={details.customer_id} label="Customer Id" />
            <DetailsRow value={details.formatted_created_at_datetime} label="Created At" />

            {details.gateways && details.gateways.map((gw, i) => {
            return (
              <div key={i}>
                <DetailsRow 
                    label={locale["Gateway ESN"]}
                    value={gw.mac}         
                 />
                <DetailsRow 
                    label={locale["Last GPS Update"]}
                    value={gw.formatted_last_updated_at_datetime}/> 
              </div>
            )
          })}
        
        </RivataModule>
        <RivataModule
            title="Location Map"
            width={8}
            locale={locale}
        >
            {isLoadingDetails && <RivataLoader />}
            <LocationMap
                locations={mapData}
                locale={locale}
                preferences={preferences}
            />

        </RivataModule>
        <RivataModule
            title="Location Tags Details"
            width={12}
            locale={locale}

        >
            <RivataTable
                columns={columns}
                rows={rows}
                isLoading={isLoadingTagsDetails}
            />
        </RivataModule>
    </>

}

export default GeoLocationsDetails;
