import { SET_GEOLOCATIONS, SET_GEOLOCATIONS_COORDINATES_DATA, SET_GEOLOCATIONS_LOADING, SET_GEOLOCATIONS_ORDER, SET_GEOLOCATIONS_OFFSET, SET_GEOLOCATIONS_TOTAL_COUNT } from "../actionTypes";
import api from "../../services/api";
import { convertDataEpochToDate, getErrorObj, getToken } from "../../utils";
import { API_OK_RESULT } from "../../constants";
import { store } from "../store";

const setGeolocations = (geolocations) => ({
    type: SET_GEOLOCATIONS,
    payload: geolocations,
});

const setTotalCount = (count) => ({
    type: SET_GEOLOCATIONS_TOTAL_COUNT,
    payload: count
})

const setGeolocationsCoordinates = (data) => ({
    type: SET_GEOLOCATIONS_COORDINATES_DATA,
    payload: data
})

const setGeolocationsLoading = (loading) => ({
    type: SET_GEOLOCATIONS_LOADING,
    payload: loading
})

export const setLocationsOffset = (offset) => ({
    type: SET_GEOLOCATIONS_OFFSET,
    payload: offset
})

export const setLocationsOrder = (order) => ({
    type: SET_GEOLOCATIONS_ORDER,
    payload: order
})


export function fetchGeolocationsPage() {
    return function (dispatch) {
        dispatch(fetchGeolocationsDetails())
    }
}

export function fetchGeolocationsDetails() {
    return function (dispatch, getState) {
        dispatch(fetchGeolocations());
        dispatch(fetchGeolocationsCoordinates());
    }
}

export function fetchGeolocationsCoordinates() {
    return function (dispatch, getState) {
        const state = getState();
        const { selectedCustomer } = state.common.customers;
        const id = selectedCustomer?.id
        return api.getGeoLocationsCoordinates(getToken(getState), id).then(data => {
            dispatch(setGeolocationsCoordinates(data));
        }).catch((err) => {

        }).finally(() => {

        })
    }
}

export function fetchGeolocations() {
    return function (dispatch, getState) {
        dispatch(setGeolocationsLoading(true));
        const state = getState();
        const { selectedCustomer } = state.common.customers;
        const id = selectedCustomer?.id
        const { limit, offset, sortOptions: {column, direction} } = state.geolocations;
        return api
            .getGeoLocations(getToken(getState), id, limit, offset, false, column, direction)
            .then((geolocations) => {
                const totalCount = geolocations.total_count;
                geolocations = convertDataEpochToDate(geolocations.paged_data, "created_at_epoch", "created_at_datetime")
                dispatch(setGeolocations(geolocations));
                dispatch(setTotalCount(totalCount))
            })
            .catch(() => {

            })
            .finally(() => {
                dispatch(setGeolocationsLoading(false));
            });
    };
}

export function createLocation(data) {
    return function (dispatch, getState) {
        dispatch(setGeolocationsLoading(true));
        const { selectedCustomer } = getState().common.customers
        const id = selectedCustomer?.id;
        data["customer_id"] = id;

        return api.postGeoLocation(getToken(getState), data).then(response => {
            dispatch(fetchGeolocationsDetails());
            return API_OK_RESULT;
        }).catch((err) => {
            dispatch(setGeolocationsLoading(false));
            return getErrorObj(err);
        }).finally(() => {

        })
    }
}

export function deleteLocation(id) {
    return function (dispatch, getState) {
        dispatch(setGeolocationsLoading(true));
        return api.deleteGeoLocation(getToken(getState), id).then(response => {
            dispatch(fetchGeolocationsDetails());
        }).catch(() => {
            dispatch(setGeolocationsLoading(false));
        }).finally(() => {

        })
    }
}

export function updateLocation(id, data) {
    return function (dispatch, getState) {
        dispatch(setGeolocationsLoading(true));
        return api.putGeoLocation(getToken(getState), id, data).then(response => {
            dispatch(fetchGeolocationsDetails());
            return API_OK_RESULT;
        }).catch((err) => {
            dispatch(setGeolocationsLoading(false));
            return getErrorObj(err);
        }).finally(() => {

        })
    }
}

export function unnasignGatewayFromlocation(gatewayEsn) {
    return function (dispatch, getState) {
        dispatch(setGeolocationsLoading(true));
        return api.deleteUnnasignGatewayFromLocation(getToken(getState), gatewayEsn).then(response => {
            dispatch(fetchGeolocations());
            return API_OK_RESULT;
        }).catch((err) => {
            dispatch(setGeolocationsLoading(false));
            return getErrorObj(err);
        }).finally(() => {

        })
    }
}

export function assignGatewayToLocation(data) {
    return function (dispatch, getState) {
        dispatch(setGeolocationsLoading(true));
        return api.postAssignGatewayToLocation(getToken(getState), data).then(response => {
            if (response.ok){
                dispatch(fetchGeolocations());
            }
            else {
                dispatch(setGeolocationsLoading(false));
            }
            return response;
        }).catch((err) => {
            dispatch(setGeolocationsLoading(false));
            return getErrorObj(err);
        }).finally(() => {

        })
    }
}

store.subscribe(() => {
    const lastAction = store.getState().lastAction;
    if (lastAction.type === SET_GEOLOCATIONS_OFFSET || lastAction.type === SET_GEOLOCATIONS_ORDER) {
        store.dispatch(fetchGeolocations())
    }
})