import React from "react";
import { Link } from "react-router-dom";
import RivataLoader from '../../components/RivataLoader'
import RivataModule from "../../components/RivataModule";
import StatusAlert from "../../components/StatusAlert";
import AssetRow from "./AssetRow";

const VehicleInfo = ({
  data,
  isSuperAdmin,
  isLoading,
  width,
  locale,
  error,
  tpmsProfile,
  gatewaysData,
  onGatewayLogsGet,
}) => {
  return (
    <RivataModule title="Vehicle Info" width={width} locale={locale} collapsible>
      {isLoading ? (
        <RivataLoader />
      ) : !data.id ? (
        <StatusAlert customText={error.message} />
      ) : (
        <div>
          <AssetRow label={locale["Name"]} value={data.name} />
          <AssetRow label={locale["VIN"]} value={data.vin} />
          {data.gateways && data.gateways.map((gw, i) => {
            return (
              <div key={i}>
                <AssetRow 
                label={locale["Gateway ESN"]}
                 value={gw.mac}
                 isSuperAdmin={isSuperAdmin}
                 onGatewayLogsGet={onGatewayLogsGet}
                 data={data.gateways}
                 gatewaysData={gatewaysData}
                 />
                <AssetRow 
                label={locale["Last GPS Update"]}
                 value={gw.formatted_last_updated_at_datetime}/> 
              </div>
            )
          })}
          {data.sensor_info?.tpms ? (
            <AssetRow
              label={"TPMS Profile"}
              value={tpmsProfile ? tpmsProfile : "Default"}
            />
          ) : null}
          {isSuperAdmin && (
            <Link
              className="btn btn-primary mt-2"
              to={`/asset-admin-info/${data.vin}`}
            >
              Asset Admin Info
            </Link>
          )}
        </div>
      )}
    </RivataModule>
  );
};

export default VehicleInfo;