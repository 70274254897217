import React, { useState } from 'react'
import RivataModule from '../../components/RivataModule'
import RivataTable from '../../components/RivataTable'
import CalibrationEditModal from './calibrationEditModal'
import CalibrationDeleteModal from './calibrationDeleteModal'
import InfoModal from "../../components/InfoModal"
import { useColumns, useTableRows } from './hooks'
import './style.scss'

const AxleLoadCalibrationTable = ({
    data,
    isLoading,
    totalCount,
    limit,
    selectedUnitsOfMeasurement,
    selectedAsset,
    locale={},
    onEdit,
    onDelete,
    setCalibrationDataOffset,
    setCalibrationDataLimit,
}) => {
    const [ deleteItem, setDeleteItem ] = useState(null)
    const [ editItem, setEditItem ] = useState(null)
    const [ statusMessage, setStatusMessage ] = useState(null)

    const columns = useColumns()
    const rows = useTableRows(data, selectedUnitsOfMeasurement)

    return (
        <RivataModule
            title={`Axle load Calibration Data For Asset: ${selectedAsset && selectedAsset.id ? selectedAsset.name + "(" + selectedAsset.vin + ")" : ""}`}
            locale={locale}
        >
            <div className="axle-load-calibration-table">
                {editItem && (
                    <CalibrationEditModal
                        onCancel={() => setEditItem(null)}
                        editItem={editItem}
                        setEditItem={(data) => setEditItem(data)}
                        selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                        setStatusMessage={(message) => setStatusMessage(message)}
                        onEdit={onEdit}
                        selectedAsset={selectedAsset}
                    />
                )}
                {deleteItem && (
                    <CalibrationDeleteModal 
                        deleteItem={deleteItem}
                        selectedUnitsOfMeasurement={selectedUnitsOfMeasurement}
                        onCancel={() => setDeleteItem(null)}
                        onDelete={async () => {
                            const res = await onDelete(selectedAsset.id, deleteItem?.id)
                            setDeleteItem(null)
                            setStatusMessage(res.message)
                        }}
                    />
                )}
                <InfoModal 
                    header={"Calibration info"}
                    open={!!statusMessage} 
                    onConfirm={() => setStatusMessage(null)}
                    message={statusMessage || ""}
                />
                <RivataTable
                    isLoading={isLoading}
                    columns={columns}
                    rows={rows}
                    assetsCount={totalCount}
                    onSelectLimit={setCalibrationDataLimit}
                    onPageChange={setCalibrationDataOffset}
                    pageLimit={limit}
                    isShowingLimit={true}
                    onDelete={(data) => setDeleteItem(data)}
                    onEdit={(data) => setEditItem(data)}
                />
            </div>
        </RivataModule>
    )
}

export default AxleLoadCalibrationTable