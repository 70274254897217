import React, { useEffect, useMemo } from "react";
import Subnav from '../../components/SubNav'
import DashboardBody from "./DashBody";
import CustomersDropdown from '../../components/CustomersDropdown'
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const breadcrumbs = [{ label: "Home" }]

const Dashboard = () => {
  const {
    dash,
    whiteLabelConfig,
    locale,
    common: {
      customers,
      hideAutogeneratedAssets
    }
  } = useTypedSelector(state => ({
    dash: state.dash,
    whiteLabelConfig: state.whitelabel.dash,
    auth: state.auth,
    locale: state.whitelabel.locale,
    common: state.common,
  }))

  const {
    fetchAssetsPage,
    fetchCustomerGeofences,
    fetchPagedAssets,
    fetchLocations,
    fetchPagedTpmsStatusTractorAssets,
    fetchPagedTpmsStatusTrailerAssets,
    setSelectedCustomer
  } = useActions()

  useEffect(() => {
    fetchAssetsPage()
  }, [fetchAssetsPage])

  const components = useMemo(() => {
    if (Array.isArray(whiteLabelConfig)) {
      return whiteLabelConfig
        .filter((component) => component.data.enabled)
        .map(({ id, data }) => ({ component: id, width: data.width }));
    }
    return [];
  }, [whiteLabelConfig]);

  return (
    <>
      <Subnav 
        title="Dashboard"
        breadcrumbs={breadcrumbs}
      >
        {customers.data.length > 1 && (
          <CustomersDropdown
            locale={locale}
            label={locale.Customer || "Customer"}
            onChange={(customer) => {
              setSelectedCustomer(customer);
              fetchPagedAssets()
              fetchLocations(customer.id)
              fetchCustomerGeofences()
              fetchPagedTpmsStatusTractorAssets()
              fetchPagedTpmsStatusTrailerAssets()
            }}
          />
        )}
      </Subnav>
      <DashboardBody
        {...dash}
        components={components}
        locale={locale}
        fetchPagedTpmsStatusTractorAssets={fetchPagedTpmsStatusTractorAssets}
        fetchPagedTpmsStatusTrailerAssets={fetchPagedTpmsStatusTrailerAssets}
        hideAutogeneratedAssets={hideAutogeneratedAssets}
      />
    </>
  );
};

export default Dashboard;
