import React, { useState } from "react";
import InfoModal from '../../components/InfoModal'
import RivataModule from "../../components/RivataModule";
import RivataLoader from "../../components/RivataLoader"
import TresholdsForm from "../../components/TresholdsForm";
import { UnitsOfMeasurement } from '../../enums'
import { celsiusToFahr } from '../../utils'

const TresholdsModule = ({
    width,
    locale,
    vibrationTreshold,
    temperatureTreshold,
    onSave,
    isLoading,
    unitsOfMeasurement = UnitsOfMeasurement.imperial,
    customerId,
}) => {
    const [status, setStatus] = useState({})

    return (
        <RivataModule title="Thresholds" width={width} locale={locale}>
            <InfoModal
                header="Thresholds info"
                open={status.statusCode >= 200}
                message={status.message || ""}
                onConfirm={() => setStatus({})}
            />
            {!vibrationTreshold || !temperatureTreshold || isLoading ? (
                <RivataLoader/>
            ) : (
                <TresholdsForm 
                    initialValues={{
                        vibration_treshold: vibrationTreshold || "",
                        temperature_treshold: unitsOfMeasurement === UnitsOfMeasurement.imperial 
                        ? celsiusToFahr(temperatureTreshold).toFixed(1) || "" : typeof temperatureTreshold === "number" 
                        ? temperatureTreshold.toFixed(2) || "" : ""
                    }} 
                    onSave={async (values) => {
                        const res = await onSave(values)
                        setStatus(res)
                    }}
                    unitsOfMeasurement={unitsOfMeasurement} 
                    customerId={customerId}
                />
            )}
        </RivataModule>
    );
};

export default TresholdsModule;
