import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, Fade } from "reactstrap";
import GeofenceConstructor from "./GeofenceConstructor";
import GeofenceRenderer from "../renderer/GeofenceRenderer";
import ShapeSelector from "../shapes/ShapeSelector";
import { Geometry } from "../types";
import "../styles.scss";

interface Props {
  map: H.Map | null;
  ui: H.ui.UI | null;
  geofences: Array<IGeofence>;
  zoomTo: number | null;
  selectedGeofence: IGeofence | null;
  resetEditorState?: number;
  selectedCustomerId: number | null;
  setSelectedGeofence: (data: IGeofence | null) => void;
  createGeofence: (geofence: IGeofence) => Promise<ApiResult>;
  updateGeofence: (id: number, geofence: IGeofence) => Promise<ApiResult>;
  setActiveTab: ()=>void
}

const GeofenceEditor: React.FC<Props> = ({
  map,
  ui,
  zoomTo,
  geofences,
  selectedGeofence,
  resetEditorState,
  selectedCustomerId,
  setSelectedGeofence,
  createGeofence,
  updateGeofence,
  setActiveTab
}) => {
  const [showToolbar, setShowToolbar] = useState(false);
  const [geofencesVisible, setGeofencesVisible] = useState(true);
  const [error, setError] = useState("");

  const [geofenceWKT, setGeofenceWKT] = useState("");
  const [geofenceName, setGeofenceName] = useState("");

  const [selectedShape, setSelectedShape] = useState("");
  const [geometry, setGeometry] = useState<Geometry>(null);
  const [drawingStarted, setDrawingStarted] = useState(false);
  const [edit, setEdit] = useState<boolean>(false)
  const onDrawingStarted = () => {
    setDrawingStarted(true);
  };

  const onDrawingFinished = (geofenceWKT: string) => {
    setGeofenceWKT(geofenceWKT);
  };

  const disposeGeometry = () => {
    if (geometry) {
      geometry.dispose(true);
      setGeometry(null);
    }
  };

  const onClear = useCallback(() => {
      if (selectedGeofence) {
      setGeofencesVisible(false);
    };
    setEdit(true)
    setSelectedShape("");
    setGeofenceWKT("");
    disposeGeometry();

    setDrawingStarted(false);
  },[selectedGeofence])

  const onReset = () => {
    if (selectedGeofence) setSelectedGeofence(null);

    setShowToolbar(false);
    setError("");
    setEdit(false)
    setGeofenceName("");
    setSelectedShape("");
    setGeofenceWKT("");

    disposeGeometry();
    setGeofencesVisible(true);

    setDrawingStarted(false);
  };

  const onSave = async () => {
    setError("");

    const name = geofenceName.trim();

    if (!name || !geofenceWKT || !selectedShape) {
      setError("Oops we've encountered an issue. Please try again later.");
      return;
    }

    const geofence: IGeofence = { name, lat: null, lon: null, geofence: geofenceWKT, shape: selectedShape };

    let result: ApiResult;

    if (selectedGeofence?.id) {
      result = await updateGeofence(selectedGeofence.id, geofence);
    } else {
      result = await createGeofence(geofence);
    }

    if (result.ok) {
      onReset();
    } else {
      setError(result.message);
    }
  };

  useEffect(() => {
    if (!selectedGeofence || !selectedGeofence.geofence || !selectedGeofence.shape) return;

    setGeofencesVisible(true);

    setGeofenceWKT(selectedGeofence.geofence);
    setGeofenceName(selectedGeofence.name);
    setSelectedShape(selectedGeofence.shape);

    setShowToolbar(true);
  }, [selectedGeofence]);

  useEffect(() => {
    if (resetEditorState || selectedCustomerId) onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetEditorState, selectedCustomerId]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex" style={selectedCustomerId ? { minHeight: "50px" } : {}}>
        {!selectedGeofence && selectedCustomerId && (
          <Button
            className="mb-3 mr-3"
            color="primary"
            onClick={() => {
              setGeofencesVisible(!geofencesVisible);
              setShowToolbar(!showToolbar);

              if (showToolbar) onReset();
            }}
          >
            {showToolbar ? "Cancel" : "Add Geofence"}
          </Button>
        )}

        {showToolbar && (
          <Fade className="d-flex geofence-toolbar mb-3">
            {selectedGeofence && (
              <Button className="mr-3" color="primary" onClick={onReset}>
                Cancel
              </Button>
            )}

            <ShapeSelector
              selectedShape={selectedShape}
              disabled={drawingStarted || !!(selectedGeofence && geofencesVisible)}
              setSelectedShape={setSelectedShape}
            />

            <Button className="clear-button ml-3" disabled={selectedGeofence ? false : !drawingStarted} onClick={onClear}>
              Clear Geofence
            </Button>

            <Input
              className="name-input ml-3"
              placeholder="Enter name"
              value={geofenceName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGeofenceName(e.target.value)}
            />

            <Button className="ml-3" disabled={!geofenceWKT || !geofenceName.trim().length} onClick={onSave}>
              Save
            </Button>
          </Fade>
        )}

        <GeofenceConstructor
          map={map}
          geometry={geometry}
          selectedShape={selectedShape}
          disabled={!!geofenceWKT}
          setGeometry={setGeometry}
          onDrawingStarted={onDrawingStarted}
          onDrawingFinished={onDrawingFinished}
        />

        <GeofenceRenderer
          map={map}
          ui={ui}
          geofences={selectedGeofence ? [selectedGeofence] : geofences}
          geofencesVisible={geofencesVisible}
          zoomTo={zoomTo}
          edit={edit}
          setBounds={true}
          setActiveTab={setActiveTab}
        />
      </div>

      {error && <span className="error-message mb-3">{error}</span>}
    </div>
  );
};

export default GeofenceEditor;
